import {
  IBookingActivity,
  IRecentSalesChartDataSource,
  ITopServices,
  ITopStaffs,
  IUpComingBookingsChartDataSource,
} from "models";
import {
  IGetRequest,
  IPaginateRequest,
  ITodayNextBookingActivityRequest,
  ITopServicesRequest,
} from "models/RequestModels";
import { IPaginateResponse } from "models/ResponseModels";
import { BaseApiService } from "./BaseApiService";

const url = "AdminDashboard";

class AdminDashboardApiService extends BaseApiService {
  public getRecentSalesChart = (request: IGetRequest) =>
    this.get<IRecentSalesChartDataSource>(`${url}/recent-sales`, {
      params: request,
    });
  public getUpComingBookingsChart = (request: IGetRequest) =>
    this.get<IUpComingBookingsChartDataSource>(`${url}/upcoming-appointments`, {
      params: request,
    });
  public getBookingActivity = (request: IPaginateRequest) =>
    this.get<IPaginateResponse<IBookingActivity[]>>(
      `${url}/appointments-activity`,
      {
        params: request,
      }
    );
  public getTodayNextBookingActivity = (
    request: ITodayNextBookingActivityRequest
  ) =>
    this.get<IPaginateResponse<IBookingActivity[]>>(
      `${url}/today-appointments`,
      {
        params: request,
      }
    );
  public getTopServices = (request: ITopServicesRequest) =>
    this.get<ITopServices[]>(`${url}/top-services`, {
      params: request,
    });
  public getTopStaffs = (request: ITopServicesRequest) =>
    this.get<ITopStaffs[]>(`${url}/top-staffs`, {
      params: request,
    });
}

export default new AdminDashboardApiService(true);
