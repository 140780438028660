import { IBranch } from 'models/IBranch';
import {} from 'models/RequestModels';
import { BaseApiService } from './BaseApiService';

const url = '/Branch';

class BranchApiService extends BaseApiService {
	public getBranchesByShopId = (id: string) =>
		this.get<IBranch[]>(`${url}/get-all-branches`, {
			params: {
				shopId: id,
			},
		});
	public getBranchById = (id: string) =>
		this.get<IBranch>(`${url}/get-branch/${id}`);
	public createBranch = (request: Partial<IBranch>) =>
		this.post<Partial<IBranch>, IBranch>(`${url}/create-branch`, request);
	public updateBranch = (request: Partial<IBranch>) =>
		this.put<Partial<IBranch>, IBranch>(`${url}/update-branch`, request);
	public deleteBranch = (id: string) =>
		this.delete<string>(`${url}/delete-branch/${id}`);
}

export default new BranchApiService(true);
