import {
	CheckOutlined,
	CloseCircleOutlined,
	CloseOutlined,
	CopyOutlined,
	DeleteOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { Row, Space, Table, Button, Input } from 'antd';
import { Modal } from 'components';
import { AlertHelper } from 'helpers';
import _ from 'lodash';
import { IApiResponse, IErrorResponse } from 'models';
import { IPromotionCoupon, IPromotionCouponRequest } from 'models/IPromotion';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PromotionApiService from 'services/PromotionApiService';
import { I18n, _t, translations } from 'utils';

const CouponTable = (props: {
	dataSource: IPromotionCoupon[];
	setData: (value: IPromotionCoupon[]) => void;
	editable: boolean;
	setShowModalDelete: (value: boolean) => void;
	setCouponDelete: (value: IPromotionCoupon) => void;
}) => {
	const handleChange = (value: string) => {
		const newListCoupons = props.dataSource.map((coupon) =>
			coupon.id === `tempCoupon${props.dataSource.length - 1}`
				? { ...coupon, couponCode: value }
				: coupon
		);
		props.setData(newListCoupons);
	};

	return (
		<Table<IPromotionCoupon> dataSource={props.dataSource} rowKey="id">
			<Table.Column<IPromotionCoupon>
				key="couponCode"
				title={I18n.t(_t(translations.promotionDetail.coupon))}
				dataIndex="couponCode"
				render={(text, record, index) => {
					return index === props.dataSource.length - 1 && props.editable ? (
						<Input
							name="couponCode"
							defaultValue=""
							onChange={(e) => {
								handleChange(e.target.value);
							}}
							autoFocus
							placeholder="Please enter coupon code"
						></Input>
					) : (
						text
					);
				}}
				width="50%"
			/>
			<Table.Column<IPromotionCoupon>
				key="isUsed"
				dataIndex="used"
				title={I18n.t(_t(translations.promotionDetail.used))}
				render={(used, record, index) => {
					return index === props.dataSource.length - 1 &&
						props.editable ? null : used ? (
						<CheckOutlined />
					) : (
						<CloseOutlined />
					);
				}}
			/>
			<Table.Column<IPromotionCoupon>
				key="action"
				dataIndex="couponCode"
				render={(text, record, index) => {
					return index === props.dataSource.length - 1 &&
						props.editable ? null : (
						<Space>
							<Button
								icon={<CopyOutlined />}
								onClick={() => {
									navigator.clipboard.writeText(text);
									AlertHelper.showSuccess(
										I18n.t(_t(translations.promotionDetail.copiedCode))
									);
								}}
							></Button>
							<Button
								icon={<DeleteOutlined />}
								onClick={() => {
									props.setCouponDelete(record);
									props.setShowModalDelete(true);
								}}
							></Button>
						</Space>
					);
				}}
			/>
		</Table>
	);
};

export const CouponCode = (props: {
	isAdd: boolean;
	setCoupons: (value: string[]) => void;
}) => {
	const [coupons, setCoupons] = useState<IPromotionCoupon[]>([]);
	const [editable, setEditable] = useState<boolean>(false);
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [couponDelete, setCouponDelete] = useState<IPromotionCoupon>();
	const { id } = useParams() as { id: string };

	const onAddNew = () => {
		setCoupons([
			...coupons,
			{
				id: `tempCoupon${coupons.length}`,
				promotionId: '',
				couponCode: '',
				used: false,
				quantity: 0,
				shopId: '',
			},
		]);
		setEditable(true);
	};

	const fetchCoupons = async () => {
		try {
			const result = (await PromotionApiService.getPromotionCoupon({
				promotionId: id,
			})) as IApiResponse<IPromotionCoupon[]>;
			if (result.succeeded) {
				setCoupons(result.data || []);
			}
		} catch (error) {}
	};

	const onUpdateCoupon = async () => {
		const codes = coupons.map((e) => e.couponCode);
		const data: Partial<IPromotionCouponRequest> = {
			promotionId: id,
			couponCodes: codes,
		};
		try {
			const result = (await PromotionApiService.updatePromotionCoupon(
				data
			)) as IApiResponse<IPromotionCoupon>;
			if (result.succeeded) {
				AlertHelper.showSuccess(
					I18n.t(_t(translations.promotionDetail.messageAddedCoupon))
				);
				setEditable(false);
			} else {
				const error = result as IErrorResponse;
				AlertHelper.showError(error, 5);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const onDelete = async (value: IPromotionCoupon) => {
		const newData = coupons.filter((e) => e.id !== value.id);
		setCoupons(newData);
		const codes = coupons.map((e) => e.couponCode);
		if (props.isAdd) {
			props.setCoupons(codes);
		} else {
			try {
				const result = (await PromotionApiService.removePromotionCoupon({
					promotionId: id,
					couponCodes: [value.couponCode],
				})) as IApiResponse;
				if (result.succeeded) {
					AlertHelper.showSuccess(
						I18n.t(_t(translations.promotionDetail.messageDeletedCoupon))
					);
					fetchCoupons();
				} else {
					const error = result as IErrorResponse;
					AlertHelper.showError(error);
				}
			} catch (error) {}
		}
	};

	useEffect(() => {
		const ac = new AbortController();
		if (!props.isAdd) {
			fetchCoupons();
		}
		return () => {
			ac.abort();
		};
	}, []);
	return (
		<>
			<Row style={{ marginBottom: '1rem' }}>
				<Space>
					<Button
						onClick={() => {
							onAddNew();
						}}
						type="primary"
						icon={<PlusOutlined />}
						disabled={editable}
					>
						{I18n.t(_t(translations.addNew))}
					</Button>
					<Button
						onClick={(e) => {
							const codes = coupons.map((e) => e.couponCode);
							if (props.isAdd) {
								props.setCoupons(codes);
								setEditable(false);
							} else {
								onUpdateCoupon();
							}
						}}
						icon={<CheckOutlined />}
						disabled={!editable}
					>
						{I18n.t(_t(translations.save))}
					</Button>
					<Button
						onClick={(e) => {
							if (editable) {
								const newCoupons = coupons.slice(0, coupons.length - 1);
								setCoupons(newCoupons);
								setEditable(false);
							}
						}}
						icon={<CloseCircleOutlined />}
						disabled={!editable}
					>
						{I18n.t(_t(translations.cancel))}
					</Button>
				</Space>
			</Row>

			<CouponTable
				dataSource={coupons}
				setData={(value) => {
					setCoupons(value);
				}}
				editable={editable}
				setShowModalDelete={(value) => setShowModalDelete(value)}
				setCouponDelete={(value) => setCouponDelete(value)}
			/>
			<Modal
				visible={showModalDelete}
				title={I18n.t(_t(translations.delete))}
				handleOk={() => {
					onDelete(couponDelete!);
					setShowModalDelete(false);
				}}
				handleCancel={() => {
					setShowModalDelete(false);
				}}
			>
				{I18n.t(_t(translations.promotionDetail.deleteCouponConfirmContent))}
			</Modal>
		</>
	);
};
