import { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { IApiResponse, IErrorResponse } from 'models';
import { IBranch } from 'models/IBranch';
import { yelp } from 'pages/Rating/images';
import { put, takeEvery, call, delay } from 'redux-saga/effects';
import {
	AdminDashboardActions,
	BranchActions,
	EmployeeActions,
} from 'redux/actions';
import configureStore from 'redux/configuration/configureStore';
import BranchApiService from 'services/BranchApiService';

function* getBranchesByShopId(action: PayloadAction<string>) {
	try {
		const result: IApiResponse<IBranch[]> = yield call(
			BranchApiService.getBranchesByShopId,
			action.payload
		);
		if (result.succeeded) {
			yield put(BranchActions.getBranchesByShopId.success(result.data!));
			const store = configureStore().store;
			const branchReducer = store.getState().BranchReducer;
			if (
				_.isEmpty(branchReducer.currentBranch) ||
				_.isEmpty(branchReducer.currentBranch?.name)
			) {
				yield put(
					BranchActions.selectBranch.request(
						_.find(result.data!, (branch) => branch.isPrimary === true)!
					)
				);
			}
		} else {
			const errorResult = result as IErrorResponse;
			yield put(BranchActions.getBranchesByShopId.failed(errorResult));
		}
	} catch (error) {
		yield put(BranchActions.getBranchesByShopId.failed(error));
	}
}

function* getBranchById(action: PayloadAction<string>) {
	try {
		const result: IApiResponse<IBranch> = yield call(
			BranchApiService.getBranchById,
			action.payload
		);
		if (result.succeeded) {
			yield put(AdminDashboardActions.clearData.request());
			yield put(BranchActions.getBranchById.success(result.data!));
		} else {
			const errorResult = result as IErrorResponse;
			yield put(BranchActions.getBranchById.failed(errorResult));
		}
	} catch (error) {
		yield put(BranchActions.getBranchById.failed(error));
	}
}

function* updateBranch(action: PayloadAction<Partial<IBranch>>) {
	try {
		const result: IApiResponse<IBranch> = yield call(
			BranchApiService.updateBranch,
			action.payload
		);
		if (result.succeeded) {
			yield put(BranchActions.updateBranch.success(result.data!));
		} else {
			const errorResult = result as IErrorResponse;
			yield put(BranchActions.updateBranch.failed(errorResult));
		}
	} catch (error) {
		yield put(BranchActions.updateBranch.failed(error));
	}
}

function* selectBranch(action: PayloadAction<Partial<IBranch>>) {
	yield put(BranchActions.getBranchById.request(action.payload.id!));
	yield delay(500);
	yield put(EmployeeActions.getEmployeesByBranch.request());
}

export function* BranchWatcher() {
	yield takeEvery(
		BranchActions.getBranchesByShopId.requestName,
		getBranchesByShopId
	);

	yield takeEvery(BranchActions.updateBranch.requestName, updateBranch);
	yield takeEvery(BranchActions.getBranchById.requestName, getBranchById);
	yield takeEvery(BranchActions.selectBranch.requestName, selectBranch);
}
