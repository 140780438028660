import { Input, InputProps } from 'antd';
import { PasswordProps } from 'antd/lib/input';
import React, { createRef } from 'react';
import { Type } from 'typescript';

// interface IProps extends InputProps {}

const withInput = (WrappedComponent: any) => {
	class NailInput extends React.Component<InputProps> {
		static Password = Input.Password;
		static Group = Input.Group;
		static TextArea = Input.TextArea;
		static Search = Input.Search;
		render() {
			return (
				<WrappedComponent
					ref={this.inputRef}
					{...this.props}
					style={{ ...styles, ...this.props.style }}
				/>
			);
		}
		inputRef = createRef<HTMLInputElement>();
		focus() {
			if (this.inputRef) {
				this.inputRef.current?.focus();
			}
		}
	}
	return NailInput;
};

const styles = {
	// height: '50px',
	margin: '0 0 8px 0',
};

export default withInput(Input);
