/* eslint-disable react-hooks/exhaustive-deps */
import { CalendarOutlined } from '@ant-design/icons';
import { Form, FormInstance } from 'antd';
import { ConfirmModal, DatePickerModal } from 'components';
import { FormHelper } from 'helpers';
import ModalHelper from 'helpers/modalHelper';
import { IExpense, IExpenseType } from 'models';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { translations, _t, I18n } from 'utils';
import { ExpenseTypePickerModal } from '.';
import _ from 'lodash';

interface IExpenseDetailModalProps {
	onCancel: () => void;
	onDelete: (id: string) => void;
	onFormFinish: (value: ExpenseFormData) => void;
	isLoading?: boolean;
	expense?: IExpense;
	isShowModal: boolean;
	selectedExpense?: IExpense;
}
const ExpenseDetailModal = ({
	isShowModal,
	onCancel,
	onFormFinish,
	onDelete,
	expense,
	isLoading,
	selectedExpense,
}: IExpenseDetailModalProps) => {
	const [formRef, setFormRef] = useState<FormInstance<ExpenseFormData>>();
	const expenseTypes = useSelector(
		(state: RootState) => state.ExpenseTypeReducer.types
	);
	const initialState = useMemo(
		() => ({
			selectedDate: moment(),
			selectedEmployeeId: '',
			selectedExpenseType: expenseTypes[0],
			isOtherExpenseType: false,
		}),
		[expenseTypes]
	);
	const [isShowDatePicker, setIsShowDatePicker] = useState<boolean>(false);
	const [selectedDate, setSelectedDate] = useState<moment.Moment>(
		initialState.selectedDate
	);
	// const [isShowEmployeePicker, setIsShowEmployeePicker] =
	// 	useState<boolean>(false);
	// const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>(
	// 	initialState.selectedEmployeeId
	// );
	const [selectedExpenseType, setSelectedExpenseType] = useState<IExpenseType>(
		initialState.selectedExpenseType
	);
	const [isShowExpenseTypePicker, setIsShowExpenseTypePicker] =
		useState<boolean>(false);
	const [isOtherExpenseType, setIsOtherExpenseType] = useState<boolean>(
		initialState.isOtherExpenseType
	);
	const [isShowDeleteConfirmModal, setIsShowDeleteConfirmModal] =
		useState<boolean>(false);

	useEffect(() => {
		if (isShowModal) {
			if (isAdd()) {
				setFieldsForCreateExpense();
			} else {
				setFieldsForUpdateExpense();
			}
		}
	}, [isShowModal, formRef]);

	const renderInputs = () => {
		return (
			<>
				<Form.Item
					name={['date', 'value']}
					initialValue={initialState.selectedDate}
					noStyle
				></Form.Item>
				{FormHelper.renderFormInput(
					['date', 'displayValue'],
					[
						{
							required: true,
							message: I18n.t(_t(translations.expenses.paymentDateRequired)),
						},
					],
					I18n.t(_t(translations.expenses.paymentDate)),
					selectedDate.format('MM-DD-YYYY'),
					undefined,
					{
						contentEditable: false,
						disabled: isLoading,
						className: 'uneditable-input-with-icon',
						suffix: <CalendarOutlined />,
						onClick: () => setIsShowDatePicker(true),
					}
				)}
				<Form.Item
					name={['expenseType', 'code']}
					initialValue={
						initialState.selectedExpenseType
							? initialState.selectedExpenseType.code
							: ''
					}
					noStyle
				/>
				{FormHelper.renderFormInput(
					['expenseType', isOtherExpenseType ? 'code' : 'description'],
					[
						{
							required: true,
							message: I18n.t(_t(translations.expenses.expenseTypeRequired)),
						},
					],
					I18n.t(_t(translations.expenses.expenseType)),
					expenseTypes[0] ? expenseTypes[0].description : '',
					undefined,
					{
						contentEditable: false,
						disabled: isLoading,
						className: 'uneditable-input',
						onClick: () => setIsShowExpenseTypePicker(true),
					}
				)}
				{isOtherExpenseType &&
					FormHelper.renderFormInput(
						['expenseType', 'description'],
						[
							{
								required: true,
								message: I18n.t(
									_t(translations.expenses.otherExpenseTypeRequired)
								),
							},
						],
						I18n.t(_t(translations.expenses.otherExpenseType)),
						_.isEmpty(expenseTypes)
							? ''
							: expenseTypes[expenseTypes.length - 1].description,
						undefined,
						{
							disabled: isLoading,
						}
					)}
				{FormHelper.renderFormInputNumber(
					'amount',
					[
						{
							required: true,
							message: I18n.t(_t(translations.expenses.paymentAmountRequired)),
						},
						() => ({
							validator: (_, value) => {
								if (value <= 0) {
									return Promise.reject(
										new Error(I18n.t(_t(translations.validate.lessThanZero)))
									);
								}
								return Promise.resolve();
							},
						}),
					],
					I18n.t(_t(translations.expenses.paymentAmount)),
					0,
					undefined,
					{ disabled: isLoading, min: 0 }
				)}
				{/* <Form.Item name={'employee'} noStyle>
					{FormHelper.renderFormInput(
						['employee', 'displayValue'],
						[],
						I18n.t(_t(translations.expenses.paymentResponsibility)),
						'',
						undefined,
						{
							contentEditable: false,
							disabled: isLoading,
							className: 'uneditable-input',
							onClick: () => setIsShowEmployeePicker(true),
						}
					)}
				</Form.Item> */}

				{FormHelper.renderFormTextArea(
					'note',
					[],
					I18n.t(_t(translations.expenses.note)),
					'',
					'',
					undefined,
					{
						contentEditable: false,
						disabled: isLoading,
					}
				)}
			</>
		);
	};

	const isAdd = () => (selectedExpense ? false : true);

	const setFieldsForCreateExpense = () => {
		formRef?.resetFields();
		setSelectedDate(initialState.selectedDate);
		// setSelectedEmployeeId(initialState.selectedEmployeeId);
		setSelectedExpenseType(initialState.selectedExpenseType);
		setIsOtherExpenseType(initialState.isOtherExpenseType);
	};

	const setFieldsForUpdateExpense = () => {
		setSelectedDate(moment(selectedExpense!.date));
		// setSelectedEmployeeId(selectedExpense!.employeeId || '');
		setSelectedExpenseType(selectedExpense!.expenseType!);
		setIsOtherExpenseType(
			selectedExpense!.expenseType!.code.toLowerCase().includes('other')
		);
		formRef?.setFields([
			{
				name: 'expenseType',
				value: {
					code: selectedExpense!.expenseType?.code,
					description: selectedExpense!.description,
				},
			},
			{ name: 'amount', value: selectedExpense!.amount },
			// {
			// 	name: 'employee',
			// 	value: {
			// 		id: selectedExpense!.employeeId,
			// 		displayValue: `${selectedExpense!.employee?.firstName || ''} ${
			// 			selectedExpense!.employee?.lastName || ''
			// 		}`,
			// 	},
			// },
			{
				name: 'note',
				value: selectedExpense!.note,
			},
		]);
	};

	return (
		<>
			<ModalHelper
				visible={isShowModal}
				centered
				title={
					isAdd()
						? I18n.t(_t(translations.expenses.addNewExpense))
						: I18n.t(_t(translations.expenses.editExpense))
				}
				onCancel={onCancel}
				onOk={() => {
					formRef?.submit();
				}}
				btnDelete={!isAdd() ? true : false}
				onDelete={() => setIsShowDeleteConfirmModal(true)}
				disableDelete={isAdd()}
				isLoading={isLoading}
			>
				<Form.Provider
					onFormFinish={(_, form) => {
						onFormFinish(form.values as ExpenseFormData);
					}}
				>
					<Form
						ref={(ref: FormInstance<ExpenseFormData>) => {
							setFormRef(ref);
						}}
						layout="vertical"
						name="expense-detail-form"
					>
						{renderInputs()}
					</Form>
				</Form.Provider>
			</ModalHelper>
			{isShowDatePicker && (
				<DatePickerModal
					onPickedDate={(date) => {
						setSelectedDate(date);
						formRef?.setFields([
							{
								name: 'date',
								value: { value: date, displayValue: date.format('MM-DD-YYYY') },
							},
						]);
						setIsShowDatePicker(false);
					}}
					onCancel={() => setIsShowDatePicker(false)}
					selectedDate={selectedDate}
				/>
			)}
			{/* {isShowEmployeePicker && (
				<EmployeePickerModal
					onCancel={() => setIsShowEmployeePicker(false)}
					onSelectEmployee={(employee) => {
						setSelectedEmployeeId(employee!.id);
						formRef?.setFields([
							{
								name: 'employee',
								value: {
									id: employee?.id,
									displayValue:
										employee?.firstName || ' ' + employee?.lastName || '',
								},
							},
						]);
						setIsShowEmployeePicker(false);
					}}
					selectedEmployeeId={selectedEmployeeId}
				/>
			)} */}
			{isShowExpenseTypePicker && (
				<ExpenseTypePickerModal
					isShowModal={isShowExpenseTypePicker}
					onCancel={() => setIsShowExpenseTypePicker(false)}
					onChooseType={(type) => {
						setSelectedExpenseType(type);
						formRef?.setFields([
							{
								name: 'expenseType',
								value: { code: type.code, description: type.description },
							},
						]);
						if (type.code.includes('OTHER')) {
							setIsOtherExpenseType(true);
						} else {
							setIsOtherExpenseType(false);
						}
						setIsShowExpenseTypePicker(false);
					}}
					selectedType={selectedExpenseType}
				/>
			)}
			{isShowDeleteConfirmModal && (
				<ConfirmModal
					visible={isShowDeleteConfirmModal}
					onCancel={() => setIsShowDeleteConfirmModal(false)}
					onOk={() => {
						setIsShowDeleteConfirmModal(false);
						onDelete(selectedExpense!.id);
					}}
				/>
			)}
		</>
	);
};

export type ExpenseFormData = {
	date: {
		value: moment.Moment;
		displayValue: string;
	};
	expenseType: IExpenseType;
	amount: number;
	// employee: { id: string; displayValue: string };
	note?: string;
};

export default ExpenseDetailModal;
