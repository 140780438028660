import {Space, Typography} from "antd";
import _ from "lodash";
import React from "react";

interface ILabelProps {
  title?: string;
  value?: string | number;
}
const {Title, Text} = Typography;
export default class AppAvatar extends React.Component<ILabelProps> {
  render() {
    return (
        <Space style={{ justifyContent: "center", width: "100%", textAlign: "center"}} direction="vertical" size="small">
            <Title style={styles.noPadding} level={4}>{this.props.value}</Title>
            <Title style={styles.noPadding} level={5}>{this.props.title}</Title>
        </Space> 
    );
  }
}

const styles={
    noPadding: {
        padding: 0,
        margin: 0,
      },
}