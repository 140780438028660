import React from 'react';
import { Col, Row, Space, Spin } from 'antd';
import GlobalStyles, { kStyles } from 'GlobalStyles';
import { I18n, translations, _t } from 'utils';
import _ from 'lodash';
import { connect } from 'react-redux';
import { BookingActions, CustomerActions, ErrorActions } from 'redux/actions';
import { Dispatch } from 'redux';
import { RootState } from 'redux/configuration/rootReducer';
import { AlertHelper, StringHelper, ValidationHelper } from 'helpers';
import { history } from 'routers';
import { IAddCustomerRequest } from 'models/RequestModels';
import CustomerInput from './components/CustomerInput';
import { ICustomer } from 'models';

enum CustomerBookingStep {
	ENTER_PHONE,
	ENTER_NAME,
	ENTER_EMAIL,
}

interface ICustomerInfoProps
	extends ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps> {}
interface ICustomerInfoStates {
	name: string;
	error?: string;
	phoneNumber: string;
	email?: string;
	step: number;
	canSkip: boolean;
}
class CustomerInfo extends React.Component<
	ICustomerInfoProps,
	ICustomerInfoStates
> {
	state = {
		phoneNumber: '',
		// email: "",
		name: '',
		error: undefined,
		step: CustomerBookingStep.ENTER_PHONE,
		canSkip: true,
	};
	componentDidUpdate(prevProp: ICustomerInfoProps) {
		const { currentAction } = this.props;
		if (prevProp.currentAction !== this.props.currentAction) {
			if (
				currentAction === BookingActions.getCustomerByPhone.successName &&
				_.isEmpty(this.props.customer)
			) {
				this.setState({ step: CustomerBookingStep.ENTER_NAME });
				return;
			}
		}
	}

	render() {
		return (
			<Row>
				<Col lg={2} />
				<Col sm={24} lg={20}>
					<Space size="large" direction="vertical" style={styles.container}>
						{this.state.step === CustomerBookingStep.ENTER_PHONE
							? this.renderPhoneInput()
							: null}
						{this.state.step === CustomerBookingStep.ENTER_NAME
							? this.renderNameInput()
							: null}
						{this.state.step === CustomerBookingStep.ENTER_EMAIL
							? this.renderEmailInput()
							: null}
						<Spin
							tip={`${I18n.t(_t(translations.loading))}...`}
							spinning={
								this.props.currentAction ===
								BookingActions.getCustomerByPhone.requestName
							}
							style={styles.loading}
						/>
					</Space>
				</Col>
				<Col lg={2} />
			</Row>
		);
	}

	renderPhoneInput = () => {
		return (
			<CustomerInput
				type="number"
				title={I18n.t(_t(translations.customerInfo.phone))}
				handelButtonName={() => `${I18n.t(_t(translations.next))}`}
				onFocus={() => this.setState({ error: undefined })}
				onOk={this.onInputPhoneOk}
				error={this.state.error}
				phoneInput={true}
			/>
		);
	};

	renderNameInput = () => {
		return (
			<CustomerInput
				title={I18n.t(_t(translations.customerInfo.yourName))}
				handelButtonName={() => `${I18n.t(_t(translations.next))}`}
				onOk={this.onInputNameOk}
				onCancel={() =>
					this.setState({ step: CustomerBookingStep.ENTER_PHONE })
				}
				error={this.state.error}
			/>
		);
	};

	renderEmailInput = () => {
		return (
			<CustomerInput
				title={I18n.t(_t(translations.customerInfo.email))}
				handelButtonName={(inputValue: string) =>
					_.isEmpty(inputValue)
						? `${I18n.t(_t(translations.skip))}`
						: `${I18n.t(_t(translations.next))}`
				}
				onFocus={() => this.setState({ error: undefined })}
				onOk={this.onInputEmailOk}
				onCancel={() => this.setState({ step: CustomerBookingStep.ENTER_NAME })}
				error={this.state.error}
			/>
		);
	};

	onInputPhoneOk = (value: string) => {
		if (StringHelper.checkPhoneNumber(value)) {
			this.setState({
				error: undefined,
				phoneNumber: StringHelper.convertToRawPhone(value)!,
			});
			this.props.getCustomer(StringHelper.convertToRawPhone(value)!);
		} else {
			this.setState({ error: I18n.t(_t(translations.validate.phone)) });
			return;
		}
	};

	onInputNameOk = (value: string) => {
		if (!_.isEmpty(value)) {
			this.setState({ step: CustomerBookingStep.ENTER_EMAIL, name: value });
		} else {
			this.setState({ error: I18n.t(_t(translations.validate.name)) });
		}
	};

	onInputEmailOk = (value: string) => {
		const firstName = this.state.name!.split(' ').slice(0, 1).toString();
		const lastName =
			this.state.name!.split(' ').length !== 1
				? this.state.name!.split(' ').slice(1).join(' ').toString()
				: '';
		// const firstName =
		// 	this.state.name!.split(' ').length !== 1
		// 		? this.state.name!.split(' ').slice(0, 1).toString()
		// 		: '';
		// const lastName =
		// 	this.state.name!.split(' ').length !== 1
		// 		? this.state.name!.split(' ').slice(1).join(' ').toString()
		// 		: this.state.name!.split(' ').slice(0, 1).toString();

		const customer: Partial<ICustomer> = {
			phone: this.state.phoneNumber,
			phoneNumberConfirmed: true,
			firstName,
			lastName,
			shopId: this.props.shopId,
			address: {
				countryCode: this.props.currentBranch!.address?.countryCode,
			},
		};

		if (value) {
			if (ValidationHelper.validateEmail(value)) {
				this.setState({ error: undefined });
				this.props.setSelectedCustomer(
					Object.assign(
						{
							email: value,
						},
						customer
					)
				);

				return;
			} else {
				this.setState({ error: I18n.t(_t(translations.validate.email)) });
				return;
			}
		} else {
			this.props.setSelectedCustomer(customer);
		}
		this.props.setIsNewCustomer(true);
	};
}

const mapStateToProps = (state: RootState) => ({
	customer: state.BookingReducer.selectedCustomer,
	shopId: state.BranchReducer.currentBranch?.shopId,
	currentBranch: state.BranchReducer.currentBranch,
	currentAction: state.ReduxActionReducer['BOOKING'],
	error: state.ErrorReducer.error?.errors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	getCustomer: (request: string) => {
		dispatch(BookingActions.getCustomerByPhone.request(request));
	},
	setSelectedCustomer: (request: Partial<ICustomer>) => {
		dispatch(BookingActions.selectCustomer.request(request));
	},
	addNewCustomer: (request: IAddCustomerRequest) => {
		dispatch(BookingActions.addCustomer.request(request));
	},
	clearError: () => {
		dispatch(ErrorActions.clearError.request());
	},
	setIsNewCustomer: (request: boolean) => {
		dispatch(BookingActions.setIsNewCustomer.request(request));
	},
});

const styles: kStyles = {
	container: {
		backgroundColor: 'white',
		padding: 10,
		width: '100%',
		overflow: 'hidden',
	},
	maxWidth: { width: '100%' },
	itemContainer: {
		...GlobalStyles.boxShadow,
		marginInline: 8,
		padding: '16px 4px',
	},
	loading: {
		margin: '10% 50%',
	},
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfo);
