const styles: any = {
  nailForm: {
    backgroundColor: "white",
    display: "block",
    padding: "24px",
    marginTop: "24px",
  },
  alert: {
    margin: "16px 0",
  },
  layout: {
    height: "100vh",
  },
  input: {
    height: "50px",
    margin: "0 0 16px 0",
  },
  forgotText: {
    color: "#1890ff",
    float: "right",
  },
  nailHeader: {
    height: "250px",
    background: "none",
    textAlign: "center",
  },
  nailHeaderLogo: {
    margin: "0 auto",
    width: "200px",
  },
  nailHeaderSlogan: {
    lineHeight: 1.6,
    margin: "0 auto",
  },
  nailTitle: {
    fontWeight: "bold",
  },
  row: { margin: "0 auto" },
};

export default styles;
