import { Col, Input, Row, Typography, Button } from 'antd';
import { IPaymentMethod } from 'models';
import React from 'react';
import { translations, _t, I18n } from 'utils';
import CheckOutBaseModal from './CheckOutBaseModal';
import styles from './styles';

interface IAddOtherReferenceModalProps {
	onOk(reference: string): void;
	onCancel(): void;
	selectedPaymentMethod: IPaymentMethod;
}

interface IAddOtherReferenceModalState {
	reference: string;
}

export default class AddOtherReferenceModal extends React.Component<
	IAddOtherReferenceModalProps,
	IAddOtherReferenceModalState
> {
	constructor(props: IAddOtherReferenceModalProps) {
		super(props);
		this.state = {
			reference: '',
		};
	}
	inputRef: any = React.createRef();
	render() {
		return (
			<CheckOutBaseModal
				title={this.props.selectedPaymentMethod.name.substr(0, 20)}
				onOk={() => this.props.onOk(this.state.reference)}
				onCancel={this.props.onCancel}
				disableOk={!this.state.reference}
			>
				<Typography.Title level={4}>
					{I18n.t(_t(translations.checkout.otherReference))}
				</Typography.Title>
				<Row gutter={8}>
					<Col md={20}>
						<Input
							ref={this.inputRef}
							style={styles.inputNumber}
							onPressEnter={() => this.props.onOk(this.state.reference)}
							autoFocus={true}
							value={this.state.reference}
							onChange={(event) =>
								this.setState({ reference: event.target.value })
							}
						/>
					</Col>
					<Col md={4}>
						<Button
							onClick={() => {
								this.inputRef.current?.focus();
								this.setState({ reference: '' });
							}}
							danger
						>
							{I18n.t(_t(translations.clear))}
						</Button>
					</Col>
				</Row>
			</CheckOutBaseModal>
		);
	}
}
