import { IGiftCard } from "models/IGiftCard";
import { BaseApiService } from "services/BaseApiService";
class GiftCardApiService extends BaseApiService {
  public getAllGiftCard = (params?: any) =>
    this.get<IGiftCard>(`/GiftCard/get-all-giftcards`, { params });

  public getGiftCardByCode = (giftCode: string) =>
    this.get<IGiftCard>(`/GiftCard/get-giftcard-by-code?GiftCode=${giftCode}`);

  public addGiftCard = (request: Partial<IGiftCard>) =>
    this.post<Partial<IGiftCard>, any>(`/GiftCard/create-giftcard`, request);

  public deleteGiftCard = (id: string) =>
    this.delete<string>(`/GiftCard/delete-giftcard/${id}`);

  public updateGiftCard = (giftCard: Partial<IGiftCard>) =>
    this.put<Partial<IGiftCard>>(`/GiftCard/update-giftcard`, giftCard);
}

export default new GiftCardApiService();
