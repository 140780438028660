import { DetailPageLayout_V2 } from 'components';
import _ from 'lodash';
import { IApiResponse, IErrorResponse } from 'models';
import { ITextMessage, ITextMessageSendRequest } from 'models/ITextMessage';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { I18n, _t, translations } from 'utils';
import TextMessageApiService from 'services/TextMessageApiService';
import styles from '../../EmailBlast/style';
import {
	Button,
	Card,
	Checkbox,
	Col,
	Divider,
	Form,
	Layout,
	Radio,
	Row,
	Space,
	Spin,
	Typography,
} from 'antd';
import { AlertHelper, FormHelper } from 'helpers';
import TextArea from 'antd/lib/input/TextArea';
import TextTemplateApiService from 'services/TextTemplateApiService';
import { ITextTemplate } from 'models/ITextTemplate';
import PreviewContentModal from '../components/PreviewContentModal';

const HaveNotVisited = (props: {
	isAdd: boolean;
	isAllCustomer: boolean;
	textMessage?: ITextMessage;
}) => {
	return (
		<Col span={24}>
			<Form.Item
				label={
					<Typography.Title level={5}>
						{I18n.t(_t(translations.textMessageDetail.haveNotVisited))}
					</Typography.Title>
				}
				name="haveNotVisitedIn"
				initialValue={
					props.isAdd ? undefined : props.textMessage?.haveNotVisitedIn
				}
			>
				<Radio.Group
					disabled={props.isAllCustomer}
					buttonStyle="solid"
					style={{ width: '100%' }}
				>
					<Space size={'middle'}>
						<Radio.Button value={14}>
							14 {I18n.t(_t(translations.textMessageDetail.days))}
						</Radio.Button>
						<Radio.Button value={21}>
							21 {I18n.t(_t(translations.textMessageDetail.days))}
						</Radio.Button>
						<Radio.Button value={30}>
							30 {I18n.t(_t(translations.textMessageDetail.days))}
						</Radio.Button>
						<Radio.Button value={60}>
							60 {I18n.t(_t(translations.textMessageDetail.days))}
						</Radio.Button>
						<Radio.Button value={90}>
							180 {I18n.t(_t(translations.textMessageDetail.days))}
						</Radio.Button>
						<Radio.Button value={360}>
							360 {I18n.t(_t(translations.textMessageDetail.days))}
						</Radio.Button>
					</Space>
				</Radio.Group>
			</Form.Item>
		</Col>
	);
};

const BirthdayMonth = (props: {
	isAdd: boolean;
	textMessage?: ITextMessage;
	isAllCustomer: boolean;
}) => {
	return (
		<Col span={24}>
			<Form.Item
				label={
					<Typography.Title level={5}>
						{I18n.t(_t(translations.textMessageDetail.birthdayMonth))}
					</Typography.Title>
				}
				name="birthdayMonth"
				initialValue={
					props.isAdd ? undefined : props.textMessage?.birthdayMonth
				}
			>
				<Radio.Group
					disabled={props.isAllCustomer}
					buttonStyle="solid"
					style={{ width: '100%' }}
				>
					<Space size={'middle'} wrap>
						<Radio.Button value={1}>
							{I18n.t(_t(translations.month.january))}
						</Radio.Button>
						<Radio.Button value={2}>
							{I18n.t(_t(translations.month.february))}
						</Radio.Button>
						<Radio.Button value={3}>
							{I18n.t(_t(translations.month.march))}
						</Radio.Button>
						<Radio.Button value={4}>
							{I18n.t(_t(translations.month.april))}
						</Radio.Button>
						<Radio.Button value={5}>
							{I18n.t(_t(translations.month.may))}
						</Radio.Button>
						<Radio.Button value={6}>
							{I18n.t(_t(translations.month.june))}
						</Radio.Button>
						<Radio.Button value={7}>
							{I18n.t(_t(translations.month.july))}
						</Radio.Button>
						<Radio.Button value={8}>
							{I18n.t(_t(translations.month.august))}
						</Radio.Button>
						<Radio.Button value={9}>
							{I18n.t(_t(translations.month.september))}
						</Radio.Button>
						<Radio.Button value={10}>
							{I18n.t(_t(translations.month.october))}
						</Radio.Button>
						<Radio.Button value={11}>
							{I18n.t(_t(translations.month.november))}
						</Radio.Button>
						<Radio.Button value={12}>
							{I18n.t(_t(translations.month.december))}
						</Radio.Button>
					</Space>
				</Radio.Group>
			</Form.Item>
		</Col>
	);
};
const ReviewGroup = (props: {
	isAdd: boolean;
	textMessage?: ITextMessage;
	isAllCustomer: boolean;
}) => {
	return (
		<Col span={24}>
			<Form.Item
				label={
					<Typography.Title level={5}>
						{I18n.t(_t(translations.textMessageDetail.reviewGroup))}
					</Typography.Title>
				}
				name="reviewGroup"
				initialValue={props.isAdd ? undefined : props.textMessage?.reviewGroup}
			>
				<Radio.Group
					disabled={props.isAllCustomer}
					buttonStyle="solid"
					style={{ width: '100%' }}
				>
					<Space size={'middle'}>
						<Radio.Button value={0}>1 - 3 star</Radio.Button>
						<Radio.Button value={1}>4 - 5 star</Radio.Button>
					</Space>
				</Radio.Group>
			</Form.Item>
		</Col>
	);
};
const CustomerRank = (props: {
	isAdd: boolean;
	textMessage?: ITextMessage;
	isAllCustomer: boolean;
}) => {
	return (
		<Col span={24}>
			<Form.Item
				label={
					<Typography.Title level={5}>
						{I18n.t(_t(translations.textMessageDetail.customerLoyaltyRank))}
					</Typography.Title>
				}
				name="loyaltyRank"
				// initialValue={isAdd() ? undefined : textMessage?.reviewGroup}
			>
				<Radio.Group buttonStyle="solid" style={{ width: '100%' }}>
					<Space size={'middle'}>
						<Radio.Button value={0}>
							{I18n.t(_t(translations.textMessageDetail.silver))}
						</Radio.Button>
						<Radio.Button value={1}>
							{I18n.t(_t(translations.textMessageDetail.gold))}
						</Radio.Button>
						<Radio.Button value={2}>
							{I18n.t(_t(translations.textMessageDetail.platinum))}
						</Radio.Button>
					</Space>
				</Radio.Group>
			</Form.Item>
		</Col>
	);
};
const FormSelectCustomer = (props: {
	isAdd: boolean;
	textMessage?: ITextMessage;
}) => {
	const [isAllCustomer, setIsAllCustomer] = useState<boolean>(
		props.isAdd ? false : props.textMessage!.sendToAllCustomers
	);

	return (
		<Card>
			<Card.Meta
				title={I18n.t(_t(translations.textMessageDetail.metaGroupCustomer))}
			/>
			<Divider style={styles.divider} />
			<Form.Item
				name="sendToAllCustomers"
				valuePropName="checked"
				initialValue={isAllCustomer}
			>
				<Checkbox onChange={(e) => setIsAllCustomer(e.target.checked)}>
					<Typography.Title level={5}>
						{I18n.t(_t(translations.textMessageDetail.allCustomer))}
					</Typography.Title>
				</Checkbox>
			</Form.Item>

			<HaveNotVisited
				isAdd={props.isAdd}
				textMessage={props.textMessage}
				isAllCustomer={isAllCustomer}
			/>
			<BirthdayMonth
				isAdd={props.isAdd}
				textMessage={props.textMessage}
				isAllCustomer={isAllCustomer}
			/>
			<ReviewGroup
				isAdd={props.isAdd}
				textMessage={props.textMessage}
				isAllCustomer={isAllCustomer}
			/>
		</Card>
	);
};

const FormTextMessage = (props: {
	isAdd: boolean;
	textMessage?: ITextMessage;
	setTextMessage: (value: string) => void;
	setLoading: (isLoading: boolean) => void;
}) => {
	const [characterCount, setCharacterCount] = useState(
		props.isAdd ? 0 : props.textMessage!.content.length
	);
	const [templates, setTemplates] = useState<ITextTemplate[] | undefined>([]);
	const fetchTextMessageTemplates = async () => {
		try {
			const result =
				(await TextTemplateApiService.getTemplates()) as IApiResponse<
					ITextTemplate[]
				>;
			if (result.succeeded) {
				setTemplates(result.data);
			}
		} catch (error) {}
	};
	useEffect(() => {
		props.setLoading(true);
		const ac = new AbortController();
		fetchTextMessageTemplates();
		props.setLoading(false);
		return () => {
			ac.abort();
		};
	}, []);
	return (
		<Card>
			<Row>
				<Col span={24}>
					{FormHelper.renderFormInput(
						'title',
						[
							{
								required: true,
								message: I18n.t(
									_t(translations.textMessageDetail.messageInputTitle)
								),
							},
						],
						I18n.t(_t(translations.textMessageDetail.textMessageTitle)),
						props.isAdd ? '' : props.textMessage!.title,
						{},
						{
							placeholder: I18n.t(
								_t(translations.textMessageDetail.inputTitle)
							),
							maxLength: 256,
						}
					)}
				</Col>
				<Col span={24}>
					{FormHelper.renderFormSelect<ITextTemplate>(
						'marketingTextMessageTemplateId',
						I18n.t(_t(translations.textMessageDetail.textMessageTemplate)),
						templates!,
						'name',
						'id',
						props.textMessage!
							? props.textMessage.marketingTextMessageTemplateId
							: undefined,
						{},
						{
							placeholder: I18n.t(
								_t(translations.textMessageDetail.selectTemplate)
							),
						}
					)}
				</Col>
				<Col span={24}>
					<Form.Item
						label={
							<Typography.Title level={5}>
								{I18n.t(_t(translations.textMessageDetail.messageContent))}
								<div
									style={{
										color: 'red',
										display: 'inline-block',
										marginLeft: '1rem',
									}}
								>
									Character count: {characterCount}/
									{Math.ceil(characterCount / 160)} SMS
								</div>
							</Typography.Title>
						}
						name={'content'}
						rules={[
							{
								required: true,
								message: I18n.t(
									_t(translations.textMessageDetail.messageInputContent)
								),
							},
						]}
						initialValue={props.isAdd ? '' : props.textMessage!.content}
					>
						<TextArea
							placeholder={I18n.t(
								_t(translations.textMessageDetail.inputContent)
							)}
							onChange={(e) => {
								setCharacterCount(e.target.value.length);
								props.setTextMessage(e.target.value);
							}}
							style={{ height: '30vh' }}
						/>
					</Form.Item>
				</Col>
			</Row>
		</Card>
	);
};

const TextMessageDetailPage = () => {
	const location = useLocation<any>();
	const history = useHistory();
	const { id } = useParams() as { id: string };

	const [textMessage, setTextMessage] = useState<ITextMessage | undefined>();
	const [isLoading, setLoading] = useState<boolean>(false);

	const [preview, setPreview] = useState<boolean>(false);

	const isAdd = () => {
		return location.pathname.includes('add-new');
	};
	const goBack = () => {
		history.push('/text-message');
	};

	const fetchTextMessageDetail = async (id: string) => {
		setLoading(true);
		try {
			const result = (await TextMessageApiService.getTextMessageById(
				id
			)) as IApiResponse<ITextMessage>;
			if (result.succeeded) {
				setTextMessage(result.data);
			}
			setLoading(false);
		} catch (error) {}
	};

	const handleSubmit = async (name: string, { values, forms }: any) => {
		const data: Partial<ITextMessage> = {
			id: isAdd() ? undefined : id,
			title: values.title,
			marketingTextMessageTemplateId: values.marketingTextMessageTemplateId,
			content: values.content,
			sendToAllCustomers: values?.sendToAllCustomers,
			haveNotVisitedIn: values?.haveNotVisitedIn,
			birthdayMonth: values?.birthdayMonth,
			reviewGroup: values?.reviewGroup,
		};

		let response = isAdd()
			? ((await TextMessageApiService.addTextMessage(data)) as IApiResponse)
			: ((await TextMessageApiService.editTextMessage(data)) as IApiResponse);
		if (response?.succeeded) {
			setLoading(false);
			AlertHelper.showSuccess(
				isAdd()
					? I18n.t(
							_t(translations.textMessageDetail.messageAddTextMessageSuccess)
					  )
					: I18n.t(
							_t(translations.textMessageDetail.messageUpdateTextMessageSuccess)
					  )
			);
			goBack();
		} else {
			setLoading(false);
			const error = response as IErrorResponse;
			AlertHelper.showError(error);
		}
	};
	const handleSend = async () => {
		const data: Partial<ITextMessageSendRequest> = {
			marketingTextMessageIds: [id],
		};
		setLoading(true);
		try {
			const result = (await TextMessageApiService.sendTextMessage(
				data
			)) as IApiResponse<ITextTemplate[]>;
			if (result.succeeded) {
				setLoading(false);
				AlertHelper.showSuccess(
					I18n.t(_t(translations.textMessageDetail.messageSendSuccess))
				);
				goBack();
			} else {
				setLoading(false);
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
			setLoading(false);
		} catch (error) {}
	};
	useEffect(() => {
		const ac = new AbortController();
		if (!isAdd()) {
			fetchTextMessageDetail(id);
		}
		return () => {
			ac.abort();
		};
	}, []);

	const btnStyle: CSSProperties =
		window.innerWidth <= 768
			? { ...styles.buttonContainer, paddingRight: 16 }
			: styles.buttonContainer;

	return isAdd() || !_.isEmpty(textMessage) ? (
		<DetailPageLayout_V2
			title={I18n.t(
				_t(
					isAdd()
						? translations.textMessageDetail.addNew
						: translations.textMessageDetail.editTextMessage
				)
			)}
			positiveButtonName={I18n.t(_t(translations.save))}
			negativeButtonName={I18n.t(_t(translations.templateDetail.preview))}
			isLoading={isLoading}
			goBack={() => goBack()}
			onFormFinish={handleSubmit}
		>
			<FormSelectCustomer isAdd={isAdd()} textMessage={textMessage} />
			<FormTextMessage
				isAdd={isAdd()}
				textMessage={textMessage}
				setTextMessage={(value) =>
					setTextMessage({
						...textMessage!,
						content: value,
					})
				}
				setLoading={(isLoading) => {
					setLoading(isLoading);
				}}
			/>

			<Form.Item style={btnStyle}>
				<Space direction="horizontal" size="middle">
					<Button size="large" onClick={() => setPreview(true)}>
						{I18n.t(_t(translations.templateDetail.preview))}
					</Button>
					<Button
						type={isAdd() ? 'primary' : undefined}
						size="large"
						htmlType="submit"
						loading={isLoading}
					>
						{I18n.t(_t(translations.save))}
					</Button>
					{isAdd() ? null : (
						<Button
							type="primary"
							size="large"
							loading={isLoading}
							onClick={handleSend}
						>
							{I18n.t(_t(translations.textMessageDetail.send))}
						</Button>
					)}
				</Space>
			</Form.Item>

			<PreviewContentModal
				isModalVisible={preview}
				handleCancel={() => {
					setPreview(false);
				}}
				content={textMessage?.content}
			/>
		</DetailPageLayout_V2>
	) : (
		<Layout style={styles.spinLayout}>
			<Spin tip="Loading..." spinning={isLoading} style={styles.loading} />
		</Layout>
	);
};

export default TextMessageDetailPage;
