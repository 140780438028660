import { CalendarOutlined } from '@ant-design/icons';
import { Typography, Button } from 'antd';
import { kStyles } from 'GlobalStyles';
import moment, {
	Moment,
	MomentFormatSpecification,
	MomentBuiltinFormat,
} from 'moment';
import { useEffect, useState } from 'react';
import { CalendarModal } from './CalendarModal';

interface IDatePickerProps {
	selectedDate?: Moment;
	onSelectDate?: (date: Moment) => void;
	format?: MomentFormatSpecification;
	containerStyle?: any;
	disabled?: boolean;
	disabledBefore?: boolean;
}

export const DatePicker = (props: IDatePickerProps) => {
	const { format = 'dddd DD MMM,YYYY' } = props;
	const [showModal, setShowModal] = useState(false);
	const [selectedDate, setSelectedDate] = useState(
		props.selectedDate || moment()
	);
	const onSelectDate = (date: Moment) => {
		setSelectedDate(date);
		props.onSelectDate && props.onSelectDate(date);
		setShowModal(false);
	};
	useEffect(() => {
		setSelectedDate(props.selectedDate || moment());
	}, [props.selectedDate]);
	return (
		<>
			<Button
				style={{ borderRadius: 20, minWidth: 210, ...props.containerStyle }}
				onClick={() => setShowModal(true)}
			>
				<Typography.Text>{selectedDate!.format(`${format}`)}</Typography.Text>
				<CalendarOutlined style={{ marginLeft: 20 }} />
			</Button>
			{showModal && (
				<CalendarModal
					disabledBefore={props.disabledBefore}
					selectedDate={selectedDate}
					onSelectDate={(date) => onSelectDate(date)}
					onCancel={() => setShowModal(false)}
				/>
			)}
		</>
	);
};
