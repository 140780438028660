import React, { Component } from 'react';
import {
	Layout,
	notification,
	Row,
	Spin,
	Card,
	Divider,
	FormInstance,
	Col,
	Form,
	Typography,
	Input,
	message,
	Select,
} from 'antd';
import { IComponentProps, DetailPageLayout, Button } from 'components';
import { I18n, _t, translations, Const } from 'utils';
import { Dispatch } from 'redux';
import { BranchActions, ErrorActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import { FormHelper } from 'helpers';
import styles from './styles';
import moment, { Moment } from 'moment';
import { StringHelper } from 'helpers';
import { IBranch, OnlineWorkingHour, WorkingHour } from 'models/IBranch';
import { WeekDays } from 'utils/Consts';
import {
	PaymentMethod,
	WorkingTimeOfDay,
	WorkingTimeOnline,
} from './component';
import { getTimeFieldData } from './component/WorkingTimeOfDay';
import { getOnlineTimeFieldData } from './component/WorkingTimeOnline';
import _ from 'lodash';
import { getDayOfWeek } from 'helpers/StringHelper';
import momentTz from 'moment-timezone';
import {
	getAllCity,
	getAllStates,
	getLocationFromZip,
	getZipFromLocation,
	ICity,
	IState,
} from 'helpers/geolocation';

// import { convertTimeRequest } from 'helpers/bookingHelper';

interface IBranchSettingRouteState {
	fromOurWeb: boolean;
}

interface IBranchSettingProps
	extends IComponentProps<void, IBranchSettingRouteState>,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}
interface IBranchSettingState {
	wholeWeekChecked?: boolean;
	wholeWeekOnlineChecked?: boolean;
	state?: IState;
	city?: string;
	zipCode?: string;
	cities?: ICity[];
	states?: IState[];
	calculateTurnByValue: boolean;
}

class BranchSettingPage extends Component<
	IBranchSettingProps,
	IBranchSettingState
> {
	formRef?: FormInstance = undefined;

	constructor(props: IBranchSettingProps) {
		super(props);
		this.state = {
			city: this.props.currentBranch?.address?.city || '',
			zipCode: this.props.currentBranch?.address?.zipCode || '',
			calculateTurnByValue:
				props.currentBranch?.calculateTurnMethod === 0 ? false : true,
		};
		this.onChangeDebounce = _.debounce(this.onChangeDebounce, 1000);
	}
	onChangeDebounce = () => {
		if (!_.isEmpty(this.state.zipCode)) {
			const location = getLocationFromZip(this.state.zipCode!);
			if (!_.isEmpty(location)) {
				this.setState({ city: location?.city }, () => {
					this.onSelectState(location?.state || '');
				});
			}
		}

		// this.onTableChange(1, 'ascend');
	};
	onSelectState = (code: string) => {
		const cities = getAllCity(code);
		const currentState = this.state.states?.find((x) => x.isoCode === code);
		if (currentState) {
			this.setState({ state: currentState });
		}
		if (cities) {
			this.setState({ cities });
			const currentCity = cities.find((x) => x.name === this.state.city);
			if (!currentCity) {
				this.setState({ city: '' });
			}
		}
	};
	onSelectCity = (name: string) => {
		this.setState({ city: name });
		const zipCodes = getZipFromLocation(this.state.state?.isoCode, name);
		if (!_.isEmpty(zipCodes)) {
			this.setState({ zipCode: zipCodes[0].zip });
		}
	};

	componentDidMount() {
		this.props.getBranchById(this.props.currentBranch?.id!);
		const states = getAllStates();
		this.setState({ states }, () => {
			if (this.props.currentBranch?.address?.state) {
				const currentState = states.find(
					(x) => x.name === this.props.currentBranch?.address?.state
				);
				console.log(
					'SELECT STATE',
					currentState,
					this.props.currentBranch?.address?.state
				);
				if (currentState) {
					this.onSelectState(currentState.isoCode);
				} else {
					this.setState({
						state: {
							name: this.props.currentBranch?.address?.state,
							isoCode: '',
						},
					});
				}
			}
		});
	}

	componentDidUpdate(prevProps: IBranchSettingProps) {
		if (prevProps.branchAction !== this.props.branchAction) {
			if (this.props.branchAction! === BranchActions.updateBranch.successName) {
				this.onSuccess();
				this.props.history.goBack();
				return;
			}
			if (this.props.branchAction === BranchActions.getBranchById.successName) {
				return this.wholeWeekChecked();
			}
			if (this.props.branchAction?.includes('SUCCESS')) {
				return this.onSuccess();
			} else {
				if (this.props.branchAction?.includes('FAILED')) {
					return this.onError();
				}
			}
		}
	}

	render() {
		return (
			<>
				{this.props.branchAction === BranchActions.getBranchById.requestName
					? this.renderLoading()
					: this.renderContainer()}
			</>
		);
	}

	renderLoading() {
		return (
			<Layout style={styles.spinLayout}>
				<Spin style={styles.spin} size="large" />
			</Layout>
		);
	}

	renderContainer() {
		return (
			<DetailPageLayout
				title={I18n.t(_t(translations.branchSetting.title))}
				positiveButtonName={I18n.t(_t(translations.save))}
				showDelete={false}
				isLoading={this.props.isLoading}
				goBack={() => this.goBack()}
				onFormFinish={(name, info) => this.onFormFinish(name, info)}
				formName="updateBranchForm"
				formRef={(form) => (this.formRef = form)}
			>
				{this.renderBranchInput()}
				{this.renderAddressInput()}
				{this.renderCommissionInput()}
				{this.renderTaxInput()}
				{this.renderTimeInput()}
				{this.renderOnlineTimeInput()}
				{this.renderLocationInput()}
				{this.renderCardChargeInput()}
				{this.renderReviewUrlInput()}
				{this.renderPaymentMethod()}
			</DetailPageLayout>
		);
	}

	renderBranchInput() {
		return (
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.branchSetting.branch))}
					description={I18n.t(_t(translations.branchSetting.subBranch))}
				/>
				<Divider style={styles.divider} />
				{FormHelper.renderFormInput(
					'branchName',
					[
						{
							required: true,
							message: I18n.t(_t(translations.branchSetting.branchNameMessage)),
						},
					],
					I18n.t(_t(translations.branchSetting.branchName)),
					this.props.currentBranch?.name!,
					{},
					{ inputMode: 'text', maxLength: 256 }
				)}
				<Row gutter={16}>
					{FormHelper.renderFormPhoneInput(
						'phone',
						[
							{
								required: true,
								message: I18n.t(_t(translations.branchSetting.phoneMessage)),
							},
							({ getFieldValue }) => ({
								validator(robj, value) {
									if (value) {
										if (StringHelper.checkPhoneNumber(value)) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error(
												I18n.t(_t(translations.staffDetail.messagePhone))
											)
										);
									}
									return Promise.resolve();
								},
							}),
						],
						I18n.t(_t(translations.branchSetting.phone)),
						this.props.currentBranch?.phone || '',
						{ span: 12 },
						{}
					)}
					{FormHelper.renderFormInput(
						'fax',
						[
							// {
							// 	required: true,
							// 	message: I18n.t(_t(translations.branchSetting.faxMessage)),
							// },
						],
						I18n.t(_t(translations.branchSetting.fax)),
						this.props.currentBranch?.fax || '',
						{ span: 12 },
						{ maxLength: 256 }
					)}
				</Row>
				<Row gutter={16}>
					{FormHelper.renderFormInput(
						'street',
						[
							// {
							// 	required: true,
							// 	message: I18n.t(_t(translations.branchSetting.streetMessage)),
							// },
						],
						I18n.t(_t(translations.branchSetting.street)),
						this.props.currentBranch?.address?.street || '',
						{ span: 24 },
						{ inputMode: 'text', maxLength: 256 }
					)}
					<Col span={8}>
						<Form.Item
							label={<Typography.Title level={5}>{'City'}</Typography.Title>}
						>
							{/* <Input
								value={this.state.city}
								onChange={(e) => this.setState({ city: e.target.value })}
							/> */}
							<Select
								showSearch
								optionFilterProp="children"
								value={this.state.city}
								onSelect={(code) => {
									this.onSelectCity(code);
								}}
							>
								{this.state.cities?.map((x) => {
									return <Select.Option value={x.name}>{x.name}</Select.Option>;
								})}
							</Select>
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item
							label={<Typography.Title level={5}>{'State'}</Typography.Title>}
						>
							<Select
								showSearch
								optionFilterProp="children"
								value={this.state.state?.isoCode}
								onSelect={(code) => {
									this.onSelectState(code);
								}}
							>
								{this.state.states?.map((x) => {
									return (
										<Select.Option value={x.isoCode}>{x.name}</Select.Option>
									);
								})}
							</Select>
							{/* <Input
								value={this.state.state}
								onChange={(e) => this.setState({ state: e.target.value })}
							/> */}
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={
								<Typography.Title level={5}>{'Zip code'}</Typography.Title>
							}
						>
							<Input
								value={this.state.zipCode}
								onChange={(e) =>
									this.setState({ zipCode: e.target.value }, () => {
										this.onChangeDebounce();
									})
								}
							/>
						</Form.Item>
					</Col>

					{/* {FormHelper.renderFormInput(
						'city',
						[
							// {
							// 	required: true,
							// 	message: I18n.t(_t(translations.branchSetting.cityMessage)),
							// },
						],
						I18n.t(_t(translations.branchSetting.city)),
						this.props.currentBranch?.address?.city || '',
						{ span: 12 },
						{ inputMode: 'text', maxLength: 256 }
					)}
					{FormHelper.renderFormInput(
						'state',
						[
							// {
							// 	required: true,
							// 	message: I18n.t(_t(translations.branchSetting.stateMessage)),
							// },
						],
						I18n.t(_t(translations.branchSetting.state)),
						this.props.currentBranch?.address?.state || '',
						{ span: 12 },
						{ inputMode: 'text', maxLength: 256 }
					)} */}
				</Row>
			</Card>
		);
	}

	renderAddressInput() {
		return (
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.branchSetting.bookingConfig))}
					description={I18n.t(_t(translations.branchSetting.subBookingConfig))}
				/>
				<Divider style={styles.divider} />
				<Form.Item
					label={
						<Typography.Title level={5}>
							{I18n.t(_t(translations.branchSetting.onlineBookingUrl))}
						</Typography.Title>
					}
				>
					<Row gutter={8} justify="space-between">
						<Col sm={16} md={18}>
							<Input
								value={this.props.bookingOnlineUrl}
								contentEditable={false}
								disabled
							/>
						</Col>
						<Col sm={8} md={6}>
							<Button type={'primary'} onClick={this.onCopyOnlineBookingUrl}>
								{I18n.t(_t(translations.copy))}
							</Button>
						</Col>
					</Row>
				</Form.Item>
				<Row gutter={8} justify="space-between">
					{FormHelper.renderFormSwitch(
						'showStylistTablet',
						I18n.t(_t(translations.branchSetting.showStylistTablet)),
						this.props.currentBranch?.technicianSelectionWhenCheckInByTablet ??
							false,
						{ span: 12 }
					)}
					{FormHelper.renderFormSwitch(
						'chooseStylistOnlineBooking',
						I18n.t(_t(translations.branchSetting.chooseStylistOnlineBooking)),
						this.props.currentBranch?.technicianSelectionWhenBookingOnline ??
							false,
						{ span: 12 }
					)}
					{FormHelper.renderFormSwitch(
						'showServicePriceOnlineBooking',
						I18n.t(
							_t(translations.branchSetting.showServicePriceOnlineBooking)
						),
						this.props.currentBranch?.showServicePriceWhenBookingOnline ??
							false,
						{ span: 12 }
					)}
					{FormHelper.renderFormSwitch(
						'assignTechnicianToBooking',
						I18n.t(_t(translations.branchSetting.assignTechnicianToBooking)),
						this.props.currentBranch?.assignTechnicianToBooking ?? false,
						{ span: 12 }
					)}
				</Row>
				{FormHelper.renderFormSwitch(
					'enableBookingInStylistApp',
					I18n.t(_t(translations.branchSetting.enableBookingInStylistApp)),
					this.props.currentBranch?.enableBookingInStylistApp ?? false,
					{ span: 12 }
				)}
				{/* {FormHelper.renderFormInput(
						'onlineBookingUrl',
						[],
						I18n.t(_t(translations.branchSetting.onlineBookingUrl)),
						Const.OnlineBookingUrl,
						{ span: 15 },
						{ contentEditable: false }
					)} */}
				{FormHelper.renderFormSwitch(
					'calculateTurnMethod',
					'Turn caculating by value',
					this.props.currentBranch?.calculateTurnMethod === 0 ? false : true,
					{ span: 12 },
					{
						onChange: (value) => {
							this.formRef?.setFields([
								{ name: 'calculateTurnMethod', value: value ? 1 : 0 },
							]);
							this.setState({ calculateTurnByValue: value });
						},
					}
				)}
				{this.state.calculateTurnByValue &&
					FormHelper.renderFormInput(
						'valuePerTurn',
						[
							// {
							// 	required: true,
							// 	message: I18n.t(_t(translations.branchSetting.cityMessage)),
							// },
							() => ({
								validator: (rule, value) => {
									if (isNaN(Number.parseFloat(value))) {
										return Promise.reject(
											new Error(I18n.t(_t(translations.validate.lessThanZero)))
										);
									} else if (Number.parseFloat(value) < 0) {
										return Promise.reject(
											new Error(I18n.t(_t(translations.validate.lessThanZero)))
										);
									} else {
										return Promise.resolve();
									}
								},
							}),
						],
						'',
						this.props.currentBranch?.valuePerTurn?.toString() || '0',
						{ span: 12 },
						{
							inputMode: 'numeric',
							maxLength: 256,
							suffix: I18n.t(_t(translations.currency)),
						}
					)}
			</Card>
		);
	}

	renderTaxInput() {
		return (
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.branchSetting.tax))}
					description={I18n.t(_t(translations.branchSetting.subTax))}
				/>
				<Divider style={styles.divider} />
				<Row gutter={[16, 16]}>
					{FormHelper.renderFormInput(
						'taxRate',
						[
							{
								required: true,
								message: I18n.t(_t(translations.branchSetting.taxRateMessage)),
							},
							() => ({
								validator: (_, value) => {
									if (value < 0) {
										return Promise.reject(
											new Error(I18n.t(_t(translations.validate.lessThanZero)))
										);
									}
									if (Number.parseFloat(value) > 100) {
										return Promise.reject(
											new Error(
												I18n.t(
													_t(translations.validate.greaterThanHundredPercent)
												)
											)
										);
									}
									if (
										Number.parseFloat(value) !==
										Math.round(value * 1e2) / 1e2
									) {
										return Promise.reject(
											new Error('Tax can only enter 2 decimal places')
										);
									}
									return Promise.resolve();
								},
							}),
						],
						I18n.t(_t(translations.branchSetting.taxRate)),
						this.props.currentBranch?.taxPercent?.toString() || '',
						{ span: 12 },
						{
							inputMode: 'numeric',
							type: 'number',
							addonAfter: '%',
							style: { textAlign: 'right' },
						}
					)}
					{FormHelper.renderFormInput(
						'taxCode',
						[
							// {
							// 	required: true,
							// 	message: I18n.t(_t(translations.branchSetting.taxCodeMessage)),
							// },
							() => ({
								validator: (_, value) => {
									if (value < 0) {
										return Promise.reject(
											new Error(I18n.t(_t(translations.validate.lessThanZero)))
										);
									}
									return Promise.resolve();
								},
							}),
						],
						I18n.t(_t(translations.branchSetting.taxCode)),
						this.props.currentBranch?.taxCode?.toString() || '',
						{ span: 12 },
						{ inputMode: 'numeric', type: 'number' }
					)}
					{FormHelper.renderFormSwitch(
						'discountBeforeTax',
						I18n.t(_t(translations.branchSetting.discountBeforeTax)),
						this.props.currentBranch?.discountBeforeTax ?? false,
						{ span: 12 }
					)}
				</Row>
			</Card>
		);
	}

	renderCommissionInput() {
		return (
			<Card>
				<Card.Meta title={I18n.t(_t(translations.branchSetting.commission))} />
				<Divider style={styles.divider} />
				<Row gutter={[16, 16]}>
					{FormHelper.renderFormInput(
						'defaultEmployeeCommission',
						[
							{
								required: true,
								message: I18n.t(
									_t(translations.branchSetting.defaultCommissionMessage)
								),
							},
							() => ({
								validator: (_, value) => {
									if (value < 0) {
										return Promise.reject(
											new Error(I18n.t(_t(translations.validate.lessThanZero)))
										);
									}
									if (Number.parseFloat(value) > 100) {
										return Promise.reject(
											new Error(
												I18n.t(
													_t(translations.validate.greaterThanHundredPercent)
												)
											)
										);
									}
									return Promise.resolve();
								},
							}),
						],
						I18n.t(_t(translations.branchSetting.defaultCommission)),
						this.props.currentBranch?.defaultEmployeeCommission?.toString() ||
							'',
						{ sm: 8, md: 6 },
						{
							inputMode: 'numeric',
							type: 'number',
							addonAfter: '%',
							style: { textAlign: 'right' },
						}
					)}
				</Row>
			</Card>
		);
	}

	renderTimeInput() {
		return (
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.branchSetting.workingTime))}
					description={I18n.t(_t(translations.branchSetting.subWorkingTime))}
				/>
				<Divider style={styles.divider} />
				{this.renderWorkingTimeInputs()}
				{/* <Row gutter={[16, 16]}>
          {FormHelper.renderFormTimePicker(
            "workingHourStart",
            I18n.t(_t(translations.branchSetting.openedAt)),
            this.getInitialWorkingTime(workingTimeStartAt as string),
            [
              {
                required: true,
                message: I18n.t(_t(translations.branchSetting.openedAtMessage)),
              },
            ],
            { span: 12 },
            { format: "h:mm A" }
          )}
          {FormHelper.renderFormTimePicker(
            "workingHourEnd",
            I18n.t(_t(translations.branchSetting.closedAt)),
            this.getInitialWorkingTime(workingTimeEndAt as string),
            [
              {
                required: true,
                message: I18n.t(_t(translations.branchSetting.closedAtMessage)),
              },
            ],
            { span: 12 },
            {
              format: "h:mm A",
              disabledHours: this.getDisabledHours,
            }
          )}
        </Row> */}
				<Row gutter={[16, 16]}>
					{FormHelper.renderFormInput(
						'timeBlockInterval',
						[
							{
								required: true,
								message: I18n.t(
									_t(translations.branchSetting.timeBlockIntervalMessage)
								),
							},
							() => ({
								validator: (_, value) => {
									if (value < 0) {
										return Promise.reject(
											new Error(I18n.t(_t(translations.validate.lessThanZero)))
										);
									}
									return Promise.resolve();
								},
							}),
						],
						I18n.t(_t(translations.branchSetting.timeBlockInterval)),
						this.props.currentBranch?.timeBlockInterval?.toString() || '',
						{ span: 12 },
						{
							inputMode: 'numeric',
							type: 'number',
							addonAfter: I18n.t(_t(translations.branchSetting.minutes)),
						}
					)}
					{FormHelper.renderFormInput(
						'lateArrivalAllowed',
						[
							{
								required: true,
								message: I18n.t(
									_t(translations.branchSetting.lateArrivalAllowedMessage)
								),
							},
							() => ({
								validator: (_, value) => {
									if (value < 0) {
										return Promise.reject(
											new Error(I18n.t(_t(translations.validate.lessThanZero)))
										);
									}
									return Promise.resolve();
								},
							}),
						],
						I18n.t(_t(translations.branchSetting.lateArrivalAllowed)),
						this.props.currentBranch?.lateArrivalTimeAllowed!.toString(),
						{ span: 12 },
						{
							inputMode: 'numeric',
							type: 'number',
							addonAfter: I18n.t(_t(translations.branchSetting.minutes)),
						}
					)}
				</Row>
			</Card>
		);
	}

	renderOnlineTimeInput() {
		return (
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.branchSetting.onlineWorkingTime))}
					description={I18n.t(
						_t(translations.branchSetting.subOnlineWorkingTime)
					)}
				/>
				<Divider style={styles.divider} />
				{this.renderOnlineWorkingTimeInputs()}
				{FormHelper.renderFormInput(
					'allowTimeBeforeBookingOnline',
					[
						{
							required: true,
							message: I18n.t(
								_t(
									translations.branchSetting.allowTimeBeforeBookingOnlineMessage
								)
							),
						},
						() => ({
							validator: (_, value) => {
								if (value < 0) {
									return Promise.reject(
										new Error(I18n.t(_t(translations.validate.lessThanZero)))
									);
								}
								return Promise.resolve();
							},
						}),
					],
					I18n.t(_t(translations.branchSetting.allowTimeBeforeBookingOnline)),
					this.props.currentBranch?.allowTimeBeforeBookingOnline!.toString(),
					{ span: 12 },
					{
						inputMode: 'numeric',
						type: 'number',
						addonAfter: I18n.t(_t(translations.branchSetting.hour)),
					}
				)}
			</Card>
		);
	}

	renderWorkingTimeInputs() {
		return (
			<>
				{this.renderDayWorkingTime(
					WeekDays.WHOLEWEEK,
					(value) =>
						this.setState({ wholeWeekChecked: value }, () => {
							const fieldsKeys = Object.keys(this.formRef?.getFieldsValue());
							const fieldData = getTimeFieldData(fieldsKeys, value);
							this.formRef?.setFields(fieldData);
							this.formRef?.validateFields().then();
						}),
					true
				)}
				{this.renderDayWorkingTime(WeekDays.SUNDAY)}
				{this.renderDayWorkingTime(WeekDays.MONDAY)}
				{this.renderDayWorkingTime(WeekDays.TUESDAY)}
				{this.renderDayWorkingTime(WeekDays.WEDNESDAY)}
				{this.renderDayWorkingTime(WeekDays.THURSDAY)}
				{this.renderDayWorkingTime(WeekDays.FRIDAY)}
				{this.renderDayWorkingTime(WeekDays.SATURDAY)}
			</>
		);
	}

	renderDayWorkingTime = (
		day: number,
		onValueChange?: (value: boolean) => void,
		isWholeWeek = false
	) => {
		const { currentBranch } = this.props;
		const initialValue = !isWholeWeek
			? _.find(currentBranch?.workingHours, (element) => {
					return element.days! === day.toString();
			  })
			: undefined;
		const initialWholeWeekCheck =
			currentBranch?.workingHours?.length === 7 ? true : false;
		const initialWholeWeekValue =
			initialWholeWeekCheck &&
			currentBranch?.workingHours?.every(
				(element) =>
					element.workingTimeStartAt ===
						currentBranch.workingHours![0].workingTimeStartAt &&
					element.workingTimeEndAt ===
						currentBranch.workingHours![0].workingTimeEndAt
			)
				? [
						currentBranch.workingHours![0].workingTimeStartAt!,
						currentBranch.workingHours![0].workingTimeEndAt!,
				  ]
				: undefined;
		return (
			<WorkingTimeOfDay
				day={day}
				wholeWeekChecked={this.state.wholeWeekChecked || false}
				onValueChange={onValueChange}
				defaultChecked={
					!isWholeWeek
						? _.isEmpty(initialValue)
							? false
							: true
						: initialWholeWeekCheck
				}
				initialValue={
					isWholeWeek
						? initialWholeWeekValue
						: !_.isEmpty(initialValue)
						? [
								initialValue?.workingTimeStartAt!,
								initialValue?.workingTimeEndAt!,
						  ]
						: undefined
				}
				formRef={this.formRef}
			/>
		);
	};

	renderOnlineWorkingTimeInputs() {
		return (
			<>
				{this.renderDayOnlineWorkingTime(
					WeekDays.WHOLEWEEK,
					(value) => {
						this.setState({ wholeWeekOnlineChecked: value }, () => {
							const fieldsKeys = Object.keys(this.formRef?.getFieldsValue());
							const fieldData = getOnlineTimeFieldData(fieldsKeys, value);
							this.formRef?.setFields(fieldData);
						});
					},
					true
				)}
				{this.renderDayOnlineWorkingTime(WeekDays.SUNDAY)}
				{this.renderDayOnlineWorkingTime(WeekDays.MONDAY)}
				{this.renderDayOnlineWorkingTime(WeekDays.TUESDAY)}
				{this.renderDayOnlineWorkingTime(WeekDays.WEDNESDAY)}
				{this.renderDayOnlineWorkingTime(WeekDays.THURSDAY)}
				{this.renderDayOnlineWorkingTime(WeekDays.FRIDAY)}
				{this.renderDayOnlineWorkingTime(WeekDays.SATURDAY)}
			</>
		);
	}

	renderDayOnlineWorkingTime = (
		day: number,
		onValueChange?: (value: boolean) => void,
		isWholeWeek = false
	) => {
		const { currentBranch } = this.props;
		const initialValue = !isWholeWeek
			? _.find(currentBranch?.onlineBookingTimes, (element) => {
					return element.days! === day.toString();
			  })
			: undefined;
		const initialWholeWeekCheck =
			currentBranch?.onlineBookingTimes?.length === 7 ? true : false;
		const initialWholeWeekValue =
			initialWholeWeekCheck &&
			currentBranch?.onlineBookingTimes?.every(
				(element) =>
					element.onlineBookingTimeStartAt ===
						currentBranch.onlineBookingTimes![0].onlineBookingTimeStartAt &&
					element.onlineBookingTimeEndAt ===
						currentBranch.onlineBookingTimes![0].onlineBookingTimeEndAt
			)
				? [
						currentBranch.onlineBookingTimes![0].onlineBookingTimeStartAt!,
						currentBranch.onlineBookingTimes![0].onlineBookingTimeEndAt!,
				  ]
				: undefined;
		return (
			<WorkingTimeOnline
				day={day}
				wholeWeekChecked={this.state.wholeWeekOnlineChecked || false}
				onValueChange={onValueChange}
				defaultChecked={
					!isWholeWeek
						? _.isEmpty(initialValue)
							? false
							: true
						: initialWholeWeekCheck
				}
				initialValue={
					isWholeWeek
						? initialWholeWeekValue
						: !_.isEmpty(initialValue)
						? [
								initialValue?.onlineBookingTimeStartAt!,
								initialValue?.onlineBookingTimeEndAt!,
						  ]
						: undefined
				}
				formRef={this.formRef}
			/>
		);
	};

	renderLocationInput() {
		return (
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.branchSetting.location))}
					description={I18n.t(_t(translations.branchSetting.subLocation))}
				/>
				<Divider style={styles.divider} />
				<Row gutter={[16, 16]}>
					{FormHelper.renderFormInput(
						'latitude',
						[
							({ getFieldValue }) => ({
								validator: (_, value) => {
									if (
										!isNaN(Number.parseFloat(getFieldValue('longitude'))) &&
										isNaN(Number.parseFloat(value))
									) {
										return Promise.reject(
											new Error(
												`${I18n.t(
													_t(translations.branchSetting.locationMessage)
												)} ${I18n.t(_t(translations.branchSetting.latitude))}`
											)
										);
									}
									if (
										Number.parseFloat(value) > 90 ||
										Number.parseFloat(value) < -90
									) {
										return Promise.reject(
											new Error(
												I18n.t(_t(translations.branchSetting.latitudeLimit))
											)
										);
									}
									return Promise.resolve();
								},
							}),
						],
						I18n.t(_t(translations.branchSetting.latitude)),
						this.props.currentBranch?.location?.latitude?.toString() || '',
						{ span: 12 },
						{
							inputMode: 'numeric',
							type: 'number',
							placeholder: I18n.t(
								_t(translations.branchSetting.latitudePlaceholder)
							),
						},
						{
							dependencies: ['longitude'],
						}
					)}
					{FormHelper.renderFormInput(
						'longitude',
						[
							({ getFieldValue }) => ({
								validator: (_, value) => {
									if (
										!isNaN(Number.parseFloat(getFieldValue('latitude'))) &&
										isNaN(Number.parseFloat(value))
									) {
										return Promise.reject(
											new Error(
												`${I18n.t(
													_t(translations.branchSetting.locationMessage)
												)} ${I18n.t(_t(translations.branchSetting.longitude))}`
											)
										);
									}
									if (
										Number.parseFloat(value) > 180 ||
										Number.parseFloat(value) < -180
									) {
										return Promise.reject(
											new Error(
												I18n.t(_t(translations.branchSetting.longitudeLimit))
											)
										);
									}
									return Promise.resolve();
								},
							}),
						],
						I18n.t(_t(translations.branchSetting.longitude)),
						this.props.currentBranch?.location?.longitude?.toString() || '',
						{ span: 12 },
						{
							inputMode: 'numeric',
							type: 'number',
							placeholder: I18n.t(
								_t(translations.branchSetting.longitudePlaceholder)
							),
						},
						{
							dependencies: ['latitude'],
						}
					)}
				</Row>
			</Card>
		);
	}

	renderCardChargeInput() {
		return (
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.branchSetting.cardCharge))}
					description={I18n.t(_t(translations.branchSetting.subCardCharge))}
				/>
				<Divider style={styles.divider} />
				<Row gutter={[16, 16]}>
					{FormHelper.renderFormInput(
						'cardChargePercent',
						[
							{
								required: true,
								message: I18n.t(
									_t(translations.branchSetting.cardChargeByPercentMessage)
								),
							},
							({ getFieldValue }) => ({
								validator: (_, value) => {
									if (
										Number.parseFloat(value) !== 0 &&
										Number.parseFloat(getFieldValue('cardChargeFlatRate')) !== 0
									) {
										return Promise.reject(
											new Error(
												I18n.t(
													_t(
														translations.branchSetting
															.cardChargeByPercentNotPermit
													)
												)
											)
										);
									}
									if (Number.parseFloat(value) < 0) {
										return Promise.reject(
											new Error(I18n.t(_t(translations.validate.lessThanZero)))
										);
									}
									if (Number.parseFloat(value) > 100) {
										return Promise.reject(
											new Error(
												I18n.t(
													_t(translations.validate.greaterThanHundredPercent)
												)
											)
										);
									}
									return Promise.resolve();
								},
							}),
						],
						I18n.t(_t(translations.branchSetting.cardChargeByPercent)),
						this.props.currentBranch?.cardChargePercent?.toString() || '',
						{ span: 12 },
						{
							inputMode: 'numeric',
							type: 'number',
							addonAfter: '%',
							style: { textAlign: 'right' },
						},
						{
							dependencies: ['cardChargeFlatRate'],
						}
					)}
					{FormHelper.renderFormInput(
						'cardChargeFlatRate',
						[
							{
								required: true,
								message: I18n.t(
									_t(translations.branchSetting.cardChargeByValueMessage)
								),
							},
							({ getFieldValue }) => ({
								validator: (_, value) => {
									if (
										Number.parseFloat(value) !== 0 &&
										Number.parseFloat(getFieldValue('cardChargePercent')) !== 0
									) {
										return Promise.reject(
											new Error(
												I18n.t(
													_t(
														translations.branchSetting
															.cardChargeByValueNotPermit
													)
												)
											)
										);
									}
									if (Number.parseFloat(value) < 0) {
										return Promise.reject(
											new Error(I18n.t(_t(translations.validate.lessThanZero)))
										);
									}
									return Promise.resolve();
								},
							}),
						],
						I18n.t(_t(translations.branchSetting.cardChargeByValue)),
						this.props.currentBranch?.cardChargeFlatRate?.toString() || '',
						{ span: 12 },
						{
							inputMode: 'numeric',
							type: 'number',
							style: { textAlign: 'right' },
							addonAfter: '$',
						},
						{
							dependencies: ['cardChargePercent'],
						}
					)}
				</Row>
			</Card>
		);
	}

	renderReviewUrlInput() {
		return (
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.branchSetting.reviewUrl))}
					description={I18n.t(_t(translations.branchSetting.subReviewUrl))}
				/>
				<Divider style={styles.divider} />
				<Row gutter={[16, 16]}>
					{FormHelper.renderFormInput(
						'googleReviewUrl',
						[],
						I18n.t(_t(translations.branchSetting.googleUrl)),
						this.props.currentBranch?.googleReviewUrl || '',
						{ span: 12 },
						{
							inputMode: 'url',
							autoCapitalize: 'none',
							type: 'url',
						}
					)}
					{FormHelper.renderFormInput(
						'yelpReviewUrl',
						[],
						I18n.t(_t(translations.branchSetting.yelpUrl)),
						this.props.currentBranch?.yelpReviewlUrl || '',
						{ span: 12 },
						{
							inputMode: 'url',
							autoCapitalize: 'none',
							type: 'url',
						}
					)}
				</Row>
			</Card>
		);
	}

	renderPaymentMethod() {
		return <PaymentMethod formRef={this.formRef} />;
	}

	onSuccess() {
		if (this.props.branchAction === BranchActions.updateBranch.successName) {
			notification['success']({
				message: 'Success',
				description: I18n.t(_t(translations.branchSetting.updateBranchSuccess)),
			});
		}
	}

	onError() {
		if (this.props.branchAction === BranchActions.updateBranch.failedName) {
			notification['error']({
				message: 'Error',
				description:
					this.props.error?.Text ||
					I18n.t(_t(translations.branchSetting.updateBranchFail)),
				onClose: this.props.clearError,
			});
		}
		if (this.props.branchAction === BranchActions.getBranchById.failedName) {
			notification['error']({
				message: 'Error',
				description:
					this.props.error?.Text ||
					I18n.t(_t(translations.branchSetting.getBranchByIdFailed)),
				onClose: this.props.clearError,
			});
		}
	}

	onFormFinish(formName: string, form: any) {
		if (formName === 'updateBranchForm') {
			// let workingHourStart = form.values.workingHourStart;
			// let workingHourEnd = form.values.workingHourEnd;
			// let nextDay = false;
			// if (moment(workingHourEnd).utc().hour() < moment(workingHourStart).utc().hour()) {
			//   nextDay = true;
			// }
			console.log('SADSAD', form);
			const formData: FormData = {
				...form.values,
				taxCode: form.values.taxCode,
				taxRate: Number.parseFloat(form.values.taxRate),
				defaultEmployeeCommission: Number.parseFloat(
					form.values.defaultEmployeeCommission
				),
				timeBlockInterval: Number.parseInt(form.values.timeBlockInterval),
				latitude: Number.parseFloat(form.values.latitude),
				longitude: Number.parseFloat(form.values.longitude),
				lateArrivalAllowed: Number.parseInt(form.values.lateArrivalAllowed),
				cardChargePercent: Number.parseFloat(form.values.cardChargePercent),
				cardChargeFlatRate: Number.parseFloat(form.values.cardChargeFlatRate),
				technicianSelectionWhenBookingOnline:
					form.values.chooseStylistOnlineBooking,
				technicianSelectionWhenCheckInByTablet: form.values.showStylistTablet,
				showServicePriceWhenBookingOnline:
					form.values.showServicePriceOnlineBooking,
				discountBeforeTax: form.values.discountBeforeTax,
				assignTechnicianToBooking: form.values.assignTechnicianToBooking,
				allowTimeBeforeBookingOnline: Number.parseInt(
					form.values.allowTimeBeforeBookingOnline
				),
				enableBookingInStylistApp: form.values.enableBookingInStylistApp,
			};
			const workingHours = this.getWorkingTimes(formData);
			const onlineBookingTimes = this.getOnlineWorkingTimes(formData);
			const branchData: Partial<IBranch> = {
				...this.props.currentBranch,
				name: formData.branchName,
				address: {
					...this.props.currentBranch?.address,
					state: this.state.state?.name,
					city: this.state.city,
					street: formData.street,
					zipCode: this.state.zipCode,
				},
				phone: formData.phone,
				fax: formData.fax,
				taxCode: formData.taxCode,
				taxPercent: formData.taxRate,
				// workingHours: [
				//   // {
				//   //   id: _.isEmpty(this.props.currentBranch?.workingHours)
				//   //     ? undefined
				//   //     : this.props.currentBranch?.workingHours![0].id,
				//   //   days: "0, 1, 2, 3, 4, 5, 6",
				//   //   workingTimeEndAt: convertTimeRequest(formData.workingHourEnd, nextDay),
				//   //   workingTimeStartAt: convertTimeRequest(formData.workingHourStart),
				//   // },
				// ],
				timeBlockInterval: formData.timeBlockInterval,
				defaultEmployeeCommission: formData.defaultEmployeeCommission,
				location: {
					latitude: !isNaN(formData.latitude) ? formData.latitude : undefined,
					longitude: !isNaN(formData.longitude)
						? formData.longitude
						: undefined,
				},
				lateArrivalTimeAllowed: formData.lateArrivalAllowed,
				cardChargeFlatRate: formData.cardChargeFlatRate,
				cardChargePercent: formData.cardChargePercent,
				googleReviewUrl: formData.googleReviewUrl,
				yelpReviewlUrl: formData.yelpReviewUrl,
				workingHours: workingHours,
				onlineBookingTimes: onlineBookingTimes,
				technicianSelectionWhenBookingOnline:
					formData.technicianSelectionWhenBookingOnline,
				technicianSelectionWhenCheckInByTablet:
					formData.technicianSelectionWhenCheckInByTablet,
				showServicePriceWhenBookingOnline:
					formData.showServicePriceWhenBookingOnline,
				discountBeforeTax: formData.discountBeforeTax,
				assignTechnicianToBooking: formData.assignTechnicianToBooking,
				paymentReferenceCodeRequiring: formData.paymentReferenceCodeRequiring,
				allowTimeBeforeBookingOnline: formData.allowTimeBeforeBookingOnline,
				enableBookingInStylistApp: formData.enableBookingInStylistApp,
				calculateTurnMethod: formData.calculateTurnMethod,
				valuePerTurn: formData.valuePerTurn,
			};
			this.props.updateBranch(branchData);
		}
	}

	onCopyOnlineBookingUrl = () => {
		navigator.clipboard
			.writeText(this.props.bookingOnlineUrl)
			.then(() => {
				message.success(
					I18n.t(_t(translations.branchSetting.copyOnlineBookingUrlSuccess)),
					2
				);
			})
			.catch(() => {
				message.success(
					I18n.t(_t(translations.branchSetting.copyOnlineBookingUrlFailed)),
					2
				);
			});
	};

	wholeWeekChecked = () => {
		this.setState({
			wholeWeekChecked:
				this.props.currentBranch?.workingHours?.length === 7 || false,
			wholeWeekOnlineChecked:
				this.props.currentBranch?.onlineBookingTimes?.length === 7 || false,
		});
	};

	isNextDate = (dates: Moment[]) => {
		let nextDay = false;
		const startTimeOnUTC = moment.utc(
			moment(dates[0]).utc().format('YYYY-MM-DDTHH:mm')
		);
		const endTimeOnUTC = moment.utc(
			moment(dates[1]).utc().format('YYYY-MM-DDTHH:mm')
		);
		if (endTimeOnUTC.isAfter(startTimeOnUTC, 'date')) {
			nextDay = true;
		}
		return nextDay;
	};

	// getInitialWorkingTime = (time: string) => {
	//   const splited = time.slice().split("T");
	//   const spitedTime = splited[1];
	//   const date = splited[0];
	//   const niceTime = moment.utc(`${moment().format("YYYY-MM-DD")}T${spitedTime}`).local();
	//   if (date.includes("2")) {
	//     niceTime.add(1, "day");
	//   }
	//   return niceTime;
	// };

	// getDisabledHours = (): number[] => {
	// 	const disabledNumber: number[] = [];
	// 	for (let i = 1; i <= 12; ++i) {
	// 		if (
	// 			i <=
	// 			(
	// 				this.formRef?.getFieldValue('workingHourStart') as moment.Moment
	// 			).hour()
	// 		) {
	// 			disabledNumber.push(i);
	// 		}
	// 	}	// 	return disabledNumber;
	// };
	getWorkingTimes(formData: FormData): WorkingHour[] {
		//clone the current working times
		const currentWorkingTimes = Object.assign(
			[],
			this.props.currentBranch?.workingHours
		) as WorkingHour[];

		//----------------------No need whole week time anymore-----------------------------------------------
		// //if WholeWeek is checked
		// if (this.state.wholeWeekChecked) {
		// 	//check if the endTime is sooner than the startTime (Just for the reason of differences of timezone)
		// 	const wholeWeekNextDate = this.isNextDate(formData.wholeweek);
		// 	if (
		// 		currentWorkingTimes.findIndex((element) => element.days!.length > 1) !==
		// 		-1
		// 	) {
		// 		const newWholeWeek = currentWorkingTimes.filter(
		// 			(element) => element.days!.length > 1
		// 		);
		// 		return newWholeWeek.map((element) => ({
		// 			...element,
		// 			workingTimeStartAt: convertTimeRequest(
		// 				momentTz.tz(formData.wholeweek[0], this.props.timezone!)
		// 			),
		// 			workingTimeEndAt: convertTimeRequest(
		// 				momentTz.tz(formData.wholeweek[1], this.props.timezone!),
		// 				wholeWeekNextDate
		// 			),
		// 			breakingTimeEndAt: undefined,
		// 			breakingTimeStartAt: undefined,
		// 		}));
		// 	} else {
		// 		return [
		// 			{
		// 				days: '0,1,2,3,4,5,6',
		// 				workingTimeStartAt: convertTimeRequest(
		// 					momentTz.tz(formData.wholeweek[0], this.props.timezone!)
		// 				),
		// 				workingTimeEndAt: convertTimeRequest(
		// 					momentTz.tz(formData.wholeweek[1], this.props.timezone!),
		// 					wholeWeekNextDate
		// 				),
		// 				breakingTimeEndAt: undefined,
		// 				breakingTimeStartAt: undefined,
		// 			},
		// 		];
		// 	}
		// }
		//----------------------No need whole week time anymore-----------------------------------------------

		//If whole week is unchecked
		//Get the new "checked" working times
		const newWorkingTimes: Partial<WorkingHour>[] = [];
		for (let i = 0; i < 7; ++i) {
			const day = getDayOfWeek(i).replaceAll(' ', '').toLowerCase();
			if (formData[`${day}Check` as keyof FormData]) {
				const workingTime = formData[day as keyof FormData] as Moment[];
				// const nextDate = this.isNextDate(workingTime);
				newWorkingTimes.push({
					days: i.toString(),
					// workingTimeStartAt: convertTimeRequest(
					// 	momentTz.tz(workingTime[0], this.props.timezone!)
					// ),
					// workingTimeEndAt: convertTimeRequest(
					// 	momentTz.tz(workingTime[1], this.props.timezone!),
					// 	nextDate
					// ),
					workingTimeStartAt: momentTz
						.tz(workingTime[0], this.props.timezone!)
						.utc()
						.format('YYYY-MM-DDTHH:mm'),
					workingTimeEndAt: momentTz
						.tz(workingTime[1], this.props.timezone!)
						.utc()
						.format('YYYY-MM-DDTHH:mm'),
					breakingTimeEndAt: undefined,
					breakingTimeStartAt: undefined,
				});
			}
		}
		//remove the "unchecked" times from current
		const cleanCurrentWorkingTimes = currentWorkingTimes.filter((element) => {
			//----------------------No need whole week time anymore-----------------------------------------------
			// if (element.days!.length > 1 && !formData.wholeweekCheck) {
			// 	return false;
			// }
			//----------------------No need whole week time anymore-----------------------------------------------

			const day = getDayOfWeek(Number.parseInt(element.days!))
				.replaceAll(' ', '')
				.toLowerCase();
			if (formData[`${day}Check` as keyof FormData]) {
				return true;
			}
			return false;
		});
		//update or add to current working times
		for (let i = 0; i < 7; ++i) {
			//if the day exist
			const currentIndex = cleanCurrentWorkingTimes.findIndex(
				(element) => element.days === i.toString()
			);
			const newIndex = newWorkingTimes.findIndex(
				(element) => element.days === i.toString()
			);
			if (currentIndex !== -1) {
				//update the working time;
				cleanCurrentWorkingTimes[currentIndex] = {
					...cleanCurrentWorkingTimes[currentIndex],
					...newWorkingTimes[newIndex],
				};
			} else if (newIndex !== -1) {
				//add new working time;
				cleanCurrentWorkingTimes.push(newWorkingTimes[newIndex]);
			}
		}
		return cleanCurrentWorkingTimes;
	}

	getOnlineWorkingTimes(formData: FormData): WorkingHour[] {
		//clone the current working times
		const currentWorkingTimes = Object.assign(
			[],
			this.props.currentBranch?.onlineBookingTimes
		) as WorkingHour[];

		//----------------------No need whole week time anymore-----------------------------------------------
		// //if WholeWeek is checked
		// if (this.state.wholeWeekChecked) {
		// 	//check if the endTime is sooner than the startTime (Just for the reason of differences of timezone)
		// 	const wholeWeekNextDate = this.isNextDate(formData.wholeweek);
		// 	if (
		// 		currentWorkingTimes.findIndex((element) => element.days!.length > 1) !==
		// 		-1
		// 	) {
		// 		const newWholeWeek = currentWorkingTimes.filter(
		// 			(element) => element.days!.length > 1
		// 		);
		// 		return newWholeWeek.map((element) => ({
		// 			...element,
		// 			workingTimeStartAt: convertTimeRequest(
		// 				momentTz.tz(formData.wholeweek[0], this.props.timezone!)
		// 			),
		// 			workingTimeEndAt: convertTimeRequest(
		// 				momentTz.tz(formData.wholeweek[1], this.props.timezone!),
		// 				wholeWeekNextDate
		// 			),
		// 			breakingTimeEndAt: undefined,
		// 			breakingTimeStartAt: undefined,
		// 		}));
		// 	} else {
		// 		return [
		// 			{
		// 				days: '0,1,2,3,4,5,6',
		// 				workingTimeStartAt: convertTimeRequest(
		// 					momentTz.tz(formData.wholeweek[0], this.props.timezone!)
		// 				),
		// 				workingTimeEndAt: convertTimeRequest(
		// 					momentTz.tz(formData.wholeweek[1], this.props.timezone!),
		// 					wholeWeekNextDate
		// 				),
		// 				breakingTimeEndAt: undefined,
		// 				breakingTimeStartAt: undefined,
		// 			},
		// 		];
		// 	}
		// }
		//----------------------No need whole week time anymore-----------------------------------------------

		//If whole week is unchecked
		//Get the new "checked" working times
		const newWorkingTimes: Partial<OnlineWorkingHour>[] = [];
		for (let i = 0; i < 7; ++i) {
			const day = getDayOfWeek(i).replaceAll(' ', '').toLowerCase();
			if (formData[`${day}OnlineCheck` as keyof FormData]) {
				const workingTime = formData[
					`${day}Online` as keyof FormData
				] as Moment[];
				// const nextDate = this.isNextDate(workingTime);
				newWorkingTimes.push({
					days: i.toString(),
					// onlineBookingTimeStartAt: convertTimeRequest(
					// 	momentTz.tz(workingTime[0], this.props.timezone!)
					// ),
					// onlineBookingTimeEndAt: convertTimeRequest(
					// 	momentTz.tz(workingTime[1], this.props.timezone!),
					// 	nextDate
					// ),
					onlineBookingTimeStartAt: momentTz
						.tz(workingTime[0], this.props.timezone!)
						.utc()
						.format('YYYY-MM-DDTHH:mm'),
					onlineBookingTimeEndAt: momentTz
						.tz(workingTime[1], this.props.timezone!)
						.utc()
						.format('YYYY-MM-DDTHH:mm'),
					breakingTimeEndAt: undefined,
					breakingTimeStartAt: undefined,
				});
			}
		}
		//remove the "unchecked" times from current
		const cleanCurrentWorkingTimes = currentWorkingTimes.filter((element) => {
			//----------------------No need whole week time anymore-----------------------------------------------
			// if (element.days!.length > 1 && !formData.wholeweekCheck) {
			// 	return false;
			// }
			//----------------------No need whole week time anymore-----------------------------------------------

			const day = getDayOfWeek(Number.parseInt(element.days!))
				.replaceAll(' ', '')
				.toLowerCase();
			if (formData[`${day}OnlineCheck` as keyof FormData]) {
				return true;
			}
			return false;
		});
		//update or add to current working times
		for (let i = 0; i < 7; ++i) {
			//if the day exist
			const currentIndex = cleanCurrentWorkingTimes.findIndex(
				(element) => element.days === i.toString()
			);
			const newIndex = newWorkingTimes.findIndex(
				(element) => element.days === i.toString()
			);
			if (currentIndex !== -1) {
				//update the working time;
				cleanCurrentWorkingTimes[currentIndex] = {
					...cleanCurrentWorkingTimes[currentIndex],
					...newWorkingTimes[newIndex],
				};
			} else if (newIndex !== -1) {
				//add new working time;
				cleanCurrentWorkingTimes.push(newWorkingTimes[newIndex]);
			}
		}
		return cleanCurrentWorkingTimes;
	}

	goBack() {
		const { state } = this.props.location;
		if (state && state.fromOurWeb) {
			this.props.history.goBack();
		} else {
			this.props.history.push('/today');
		}
	}
}

type FormData = {
	branchName: string;
	city: string;
	fax: string;
	phone: string;
	state: string;
	street: string;
	taxCode: string;
	taxRate: number;
	timeBlockInterval: number;
	defaultEmployeeCommission: number;
	latitude: number;
	longitude: number;
	lateArrivalAllowed: number;
	cardChargePercent: number;
	cardChargeFlatRate: number;
	googleReviewUrl: string;
	yelpReviewUrl: string;
	wholeweek: Moment[];
	sunday: Moment[];
	monday: Moment[];
	tuesday: Moment[];
	wednesday: Moment[];
	thursday: Moment[];
	friday: Moment[];
	saturday: Moment[];
	wholeweekCheck: boolean;
	sundayCheck: boolean;
	mondayCheck: boolean;
	tuesdayCheck: boolean;
	wednesdayCheck: boolean;
	thursdayCheck: boolean;
	fridayCheck: boolean;
	saturdayCheck: boolean;
	wholeweekOnline: Moment[];
	sundayOnline: Moment[];
	mondayOnline: Moment[];
	tuesdayOnline: Moment[];
	wednesdayOnline: Moment[];
	thursdayOnline: Moment[];
	fridayOnline: Moment[];
	saturdayOnline: Moment[];
	wholeweekOnlineCheck: boolean;
	sundayOnlineCheck: boolean;
	mondayOnlineCheck: boolean;
	tuesdayOnlineCheck: boolean;
	wednesdayOnlineCheck: boolean;
	thursdayOnlineCheck: boolean;
	fridayOnlineCheck: boolean;
	saturdayOnlineCheck: boolean;
	technicianSelectionWhenBookingOnline?: boolean;
	technicianSelectionWhenCheckInByTablet?: boolean;
	showServicePriceWhenBookingOnline?: boolean;
	discountBeforeTax?: boolean;
	assignTechnicianToBooking?: boolean;
	paymentReferenceCodeRequiring: boolean;
	allowTimeBeforeBookingOnline: number;
	enableBookingInStylistApp: boolean;
	calculateTurnMethod: number;
	valuePerTurn: number;
};

const mapStateToProps = (state: RootState) => ({
	isLoading: state.AppConfigReducer.showLoading,
	error: state.ErrorReducer.error?.message[0],
	currentBranch: state.BranchReducer.currentBranch,
	branchAction: state.ReduxActionReducer.BRANCH,
	shopId: state.UserReducer.user?.shopId,
	timezone: state.BranchReducer.currentBranch?.localTimeZone,
	bookingOnlineUrl: `https://${state.ShopReducer.shop?.adminUrl}.smartsalon.live${Const.OnlineBookingUrl}`,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	clearError: () => {
		dispatch(ErrorActions.clearError.request());
	},
	updateBranch: (request: Partial<IBranch>) => {
		dispatch(BranchActions.updateBranch.request(request));
	},
	getBranchById: (id: string) => {
		dispatch(BranchActions.getBranchById.request(id));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(BranchSettingPage);
