import {
	Col,
	Modal,
	ModalProps,
	Typography,
	Button,
	Calendar,
	CalendarProps,
} from 'antd';
import React from 'react';
import { translations, _t, I18n } from 'utils';
import moment, { Moment } from 'moment';
import { kStyles } from 'GlobalStyles';

interface IDatePickerModalProps extends ModalProps {
	onCancel(): void;
	onPickedDate(date: moment.Moment): void;
	selectedDate: moment.Moment;
	calendarProps?: CalendarProps<Moment>;
}

interface IDatePickerModalState {}

class DatePickerModal extends React.Component<
	IDatePickerModalProps,
	IDatePickerModalState
> {
	render() {
		return (
			<Modal
				style={styles.modalStyle}
				className="date-picker-modal"
				{...this.props}
				visible={true}
				title={
					<Typography.Title level={3} style={styles.title}>
						{I18n.t(_t(translations.mainBooking.selectDate))}
					</Typography.Title>
				}
				width={1000}
				onCancel={() => this.props.onCancel()}
				footer={
					<Button onClick={() => this.props.onCancel()}>
						{I18n.t(_t(translations.cancel))}
					</Button>
				}
			>
				{this.renderCalendar()}
			</Modal>
		);
	}

	renderCalendar = () => {
		return (
			<Col style={styles.colCalendar}>
				<Calendar
					value={this.props.selectedDate}
					onSelect={(date) => this.props.onPickedDate(date)}
					{...this.props.calendarProps}
				/>
			</Col>
		);
	};
}

const styles: kStyles = {
	modalStyle: { top: 50 },
	title: { textAlign: 'center' },
	colCalendar: { height: 500, overflowY: 'auto', overflowX: 'hidden' },
};

export default DatePickerModal;
