import { Card, Layout, Spin, Tabs } from 'antd';
import { DetailPageLayout, Modal } from 'components';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { I18n, _t, translations } from 'utils';
import { AppliedToCategories } from './components/AppliedToCategories';
import { CouponCode } from './components/CouponCode';
import { Info } from './components/Info';
import { Requirements } from './components/Requirements';
import styles from '../../EmailBlast/style';
import _, { isEmpty } from 'lodash';
import PromotionApiService from 'services/PromotionApiService';
import { IApiResponse, IErrorResponse } from 'models';
import {
	IPromotion,
	IPromotionCouponRequest,
	IPromotionItemRequest,
	IPromotionItemCategoryRequest,
	IPromotionType,
	IRequirementsRequest,
	IRequirement,
} from 'models/IPromotion';
import { AlertHelper } from 'helpers';
import { AppliedToServices } from './components/AppliedToServices';
import { IFileUploadResponse } from 'models/ResponseModels';
import UploadBaseService from 'services/UploadBaseService';
import { useSelector } from 'react-redux';
import { UserReducer } from 'redux/reducers';
import { RootState } from 'redux/configuration/rootReducer';

export const PromotionDetailPage = () => {
	const location = useLocation();
	const history = useHistory();
	const { id } = useParams() as { id: string };

	const [requiredCoupon, setRequiredCoupon] = useState<boolean>(false);
	const [discountType, setDiscountType] = useState<string>('');
	const [discountTypes, setDiscountTypes] = useState<
		IPromotionType[] | undefined
	>([]);

	const [isLoading, setLoading] = useState<boolean>(false);
	const [promotion, setPromotion] = useState<IPromotion>();

	const [description, setDescription] = useState<string>();
	const [imageFiles, setImageFiles] = useState<(File | undefined)[]>();
	const [isHaveInitialImageUrl, setIsHaveInitialImageUrl] = useState<boolean>();
	const [coupons, setCoupons] = useState<string[]>([]);
	const [categories, setCategories] = useState<string[]>([]);
	const [services, setServices] = useState<string[]>([]);
	const [isVisitRequirement, setIsVisitRequirement] = useState<boolean>(false);
	const [requirement, setRequirement] = useState<IRequirement[]>([]);

	const [requirementAllOfServices, setRequirementAllOfServices] = useState<
		string[]
	>([]);
	const [requirementOneOfServices, setRequirementOneOfServices] = useState<
		string[]
	>([]);
	const [requirementReviewGroup, setRequirementReviewGroup] =
		useState<string>();
	const [requirementHaveBirthdayIn, setRequirementHaveBirthdayIn] =
		useState<string>();
	const [requirementCustomerRank, setRequirementCustomerRank] =
		useState<string>();

	const [showModal, setShowModal] = useState<boolean>(false);
	const currentBranch = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);
	const isAdd = () => {
		return location.pathname.includes('add-new');
	};
	const goBack = () => {
		history.push('/promotions');
	};
	const getCodeOfPromotionType = (id: string) => {
		const type = discountTypes!.find((e) => e.id === id);
		return type?.code;
	};

	const fetchPromotionDetail = async (id: string) => {
		try {
			const result = (await PromotionApiService.getPromotionById(
				id
			)) as IApiResponse<IPromotion>;
			if (result.succeeded) {
				setPromotion(result.data);
				setIsHaveInitialImageUrl(!_.isEmpty(result.data?.bannerUrl));
				setDescription(result.data?.description || '');
				setRequiredCoupon(result.data!.requiresCouponCode);
				setDiscountType(result.data!.promotionTypeId);
			}
			const resultRequirement =
				(await PromotionApiService.getPromotionRequirement({
					promotionId: id,
				})) as IApiResponse<IRequirement[]>;
			if (resultRequirement.succeeded) {
				setRequirement(resultRequirement.data || []);
			}
		} catch (error) {}
	};
	const fetchDiscountTypes = async () => {
		try {
			const result =
				(await PromotionApiService.getPromotionType()) as IApiResponse<
					IPromotionType[]
				>;
			setLoading(false);
			if (result.succeeded) {
				setDiscountTypes(result.data);
			}
		} catch (error) {}
	};

	const onDeletePromotion = async () => {
		setLoading(true);
		try {
			(await PromotionApiService.updatePromotionRequirement({
				promotionId: id,
				requirements: [],
			})) as IApiResponse;
			(await PromotionApiService.updatePromotionItemCategory({
				promotionId: id,
				itemCategoryIds: [],
			})) as IApiResponse;
			(await PromotionApiService.updatePromotionItem({
				promotionId: id,
				itemIds: [],
			})) as IApiResponse;
			(await PromotionApiService.updatePromotionCoupon({
				promotionId: id,
				couponCodes: [],
			})) as IApiResponse;

			const result = (await PromotionApiService.deletePromotion(
				id
			)) as IApiResponse;
			if (result.succeeded) {
				setLoading(false);
				AlertHelper.showSuccess(
					I18n.t(_t(translations.promotionDetail.messageDeletePromotionSuccess))
				);
				goBack();
			} else {
				setLoading(false);
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const getRequirementId = (type: number) => {
		let id = requirement.find((element) => element.type === type)?.id;
		return id;
	};

	const handleSubmit = async (name: string, { values, forms }: any) => {
		setLoading(true);
		let bannerUrl;
		if (imageFiles) {
			const fileResult = (await UploadBaseService.uploadImage(
				imageFiles
			)) as IApiResponse<IFileUploadResponse[]>;
			if (fileResult.succeeded) {
				bannerUrl = (fileResult.data as IFileUploadResponse[])[0].imageUrl;
			}
		}

		const data: Partial<IPromotion> = {
			id: isAdd() ? undefined : id,
			name: values.name,
			description: description || '',
			promotionTypeId: values.promotionTypeId,
			usePercentage: values.usePercentage,
			discountPercentage: values.discountPercentage || undefined,
			discountAmount: values.discountAmount || undefined,
			maximumDiscountAmount: values.maximumDiscountAmount || undefined,
			startDateUtc: values.discountTime[0],
			endDateUtc: values.discountTime[1],
			requiresCouponCode: values.requiresCouponCode,
			isCumulative: values.isCumulative,
			limitationType: parseInt(values.limitationType),
			limitationTimes:
				parseInt(values.limitationType) === 0
					? undefined
					: parseInt(values.limitationTimes),
			isEnabled: values.isEnabled,
			bannerUrl: bannerUrl
				? bannerUrl
				: isHaveInitialImageUrl
				? promotion?.bannerUrl
				: '',
			branchId: currentBranch?.id,
		};

		const requirementData: IRequirementsRequest = {
			promotionId: isAdd() ? undefined : id,
			requirements: isAdd() ? [] : isVisitRequirement ? [] : requirement,
		};
		if (values.AssignedToCustomerGroup) {
			values.haveNotVisitedIn &&
				requirementData.requirements.push({
					type: 1,
					value: values.haveNotVisitedIn.toString(),
					id: getRequirementId(1),
				});
			requirementHaveBirthdayIn &&
				requirementData.requirements.push({
					type: 2,
					value: requirementHaveBirthdayIn,
					id: getRequirementId(2),
				});
			requirementReviewGroup &&
				requirementData.requirements.push({
					type: 3,
					value: requirementReviewGroup,
					id: getRequirementId(3),
				});
			requirementCustomerRank &&
				requirementData.requirements.push({
					type: 4,
					value: requirementCustomerRank,
					id: getRequirementId(4),
				});
		}
		if (values.HadSpentAnAmount) {
			requirementData.requirements.push({
				type: 5,
				value: values.requiredSpentAmount,
				id: getRequirementId(5),
			});
		}
		if (values.isReturnMoreThan) {
			requirementData.requirements.push({
				type: 6,
				value: values.nTimesReturn,
				id: getRequirementId(6),
			});
		}
		if (values.isAllOfTheseServices && !_.isEmpty(requirementAllOfServices)) {
			requirementData.requirements.push({
				type: 10,
				value: requirementAllOfServices.toString(),
				id: getRequirementId(10),
			});
		}
		if (values.isOneOfTheseServices && !_.isEmpty(requirementOneOfServices)) {
			requirementData.requirements.push({
				type: 11,
				value: requirementOneOfServices.toString(),
				id: getRequirementId(11),
			});
		}

		let response = isAdd()
			? ((await PromotionApiService.addPromotion(
					data
			  )) as IApiResponse<IPromotion>)
			: ((await PromotionApiService.editPromotion(
					data
			  )) as IApiResponse<IPromotion>);

		if (response?.succeeded) {
			let response2 = (await PromotionApiService.updatePromotionRequirement({
				...requirementData,
				promotionId: response.data!.id,
			})) as IApiResponse;
			let couponRes;
			let itemRes;
			let itemCategoryRes;
			if (isAdd()) {
				const promotionCoupon: Partial<IPromotionCouponRequest> = {
					promotionId: response.data!.id,
					couponCodes: coupons,
				};
				const promotionItem: Partial<IPromotionItemRequest> = {
					promotionId: response.data!.id,
					itemIds: services,
				};
				const promotionItemCategory: Partial<IPromotionItemCategoryRequest> = {
					promotionId: response.data!.id,
					itemCategoryIds: categories,
				};
				if (!_.isEmpty(promotionCoupon.couponCodes)) {
					couponRes = (await PromotionApiService.updatePromotionCoupon(
						promotionCoupon
					)) as IApiResponse;
				}
				if (!_.isEmpty(promotionItem.itemIds)) {
					itemRes = (await PromotionApiService.updatePromotionItem(
						promotionItem
					)) as IApiResponse;
				}
				if (!_.isEmpty(promotionItemCategory.itemCategoryIds)) {
					itemCategoryRes =
						(await PromotionApiService.updatePromotionItemCategory(
							promotionItemCategory
						)) as IApiResponse;
				}
			}
			if (response2.succeeded) {
				AlertHelper.showSuccess(
					isAdd()
						? I18n.t(
								_t(translations.promotionDetail.messageAddPromotionSuccess)
						  )
						: I18n.t(
								_t(translations.promotionDetail.messageUpdatePromotionSuccess)
						  )
				);
				setLoading(false);
				goBack();
			} else {
				setLoading(false);
				const error = response2 as IErrorResponse;
				AlertHelper.showError(error);
				history.push(`/promotions/edit/${response.data!.id}`);
			}
			if (couponRes && !couponRes?.succeeded) {
				const error = couponRes as IErrorResponse;
				AlertHelper.showError(error);
				history.push(`/promotions/edit/${response.data!.id}`);
			}
			if (itemRes && !itemRes?.succeeded) {
				const error = itemRes as IErrorResponse;
				AlertHelper.showError(error);
				history.push(`/promotions/edit/${response.data!.id}`);
			}
			if (itemCategoryRes && !itemCategoryRes?.succeeded) {
				const error = itemCategoryRes as IErrorResponse;
				AlertHelper.showError(error);
				history.push(`/promotions/edit/${response.data!.id}`);
			}
		} else {
			setLoading(false);
			const error = response as IErrorResponse;
			AlertHelper.showError(error);
		}
	};

	useEffect(() => {
		setLoading(true);
		const ac = new AbortController();
		fetchDiscountTypes();
		if (!isAdd()) {
			fetchPromotionDetail(id);
		}
		return () => {
			setLoading(false);
			ac.abort();
		};
	}, []);

	return isAdd() || !_.isEmpty(promotion) ? (
		<DetailPageLayout
			title={I18n.t(
				_t(
					isAdd()
						? translations.promotionDetail.add
						: translations.promotionDetail.edit
				)
			)}
			positiveButtonName={I18n.t(_t(translations.save))}
			negativeButtonName={I18n.t(_t(translations.delete))}
			showDelete={isAdd()}
			isLoading={isLoading}
			goBack={() => goBack()}
			onFormFinish={handleSubmit}
			onDeleteClick={() => setShowModal(true)}
		>
			<Card>
				<Tabs type="card">
					<Tabs.TabPane
						tab={I18n.t(_t(translations.promotionDetail.info))}
						key="info"
					>
						<Info
							setRequiredCoupon={(value) => {
								setRequiredCoupon(value);
							}}
							requiredCoupon={requiredCoupon}
							discountType={discountType}
							setDiscountType={(value) => {
								setDiscountType(value);
							}}
							discountTypes={discountTypes!}
							currentPromotion={promotion}
							isAdd={isAdd()}
							setImageFiles={(value) => setImageFiles(value)}
							setIsHaveInitialImageUrl={(value) =>
								setIsHaveInitialImageUrl(value)
							}
							description={description}
							setDescription={(value) => setDescription(value)}
						/>
					</Tabs.TabPane>

					<Tabs.TabPane
						tab={I18n.t(_t(translations.promotionDetail.requirements))}
						key="requirements"
					>
						<Requirements
							isAdd={isAdd()}
							setRequirementAllOfServices={(value) =>
								setRequirementAllOfServices(value)
							}
							setRequirementOneOfServices={(value) =>
								setRequirementOneOfServices(value)
							}
							setRequirementHaveBirthdayIn={(value) =>
								setRequirementHaveBirthdayIn(value)
							}
							setRequirementReviewGroup={(value) =>
								setRequirementReviewGroup(value)
							}
							setRequirementCustomerRank={(value) =>
								setRequirementCustomerRank(value)
							}
							setIsVisitRequirement={(value) => setIsVisitRequirement(value)}
							requirements={isAdd() ? undefined : requirement}
						/>
					</Tabs.TabPane>

					{getCodeOfPromotionType(discountType) === 'AssignedToServices' && (
						<Tabs.TabPane
							tab={I18n.t(_t(translations.promotionDetail.appliedToServices))}
							key="appliedToServices"
						>
							<AppliedToServices
								isAdd={isAdd()}
								setServices={(value) => setServices(value)}
							/>
						</Tabs.TabPane>
					)}

					{getCodeOfPromotionType(discountType) === 'AssignedToCategories' && (
						<Tabs.TabPane
							tab={I18n.t(_t(translations.promotionDetail.appliedToCategories))}
							key="appliedToCategories"
						>
							<AppliedToCategories
								isAdd={isAdd()}
								setCategories={(value) => setCategories(value)}
							/>
						</Tabs.TabPane>
					)}

					{requiredCoupon && (
						<Tabs.TabPane
							tab={I18n.t(_t(translations.promotionDetail.coupon))}
							key="coupon"
						>
							<CouponCode
								isAdd={isAdd()}
								setCoupons={(value) => setCoupons(value)}
							/>
						</Tabs.TabPane>
					)}
				</Tabs>
			</Card>
			<Modal
				visible={showModal}
				title={I18n.t(_t(translations.delete))}
				handleOk={() => {
					onDeletePromotion();
					setShowModal(false);
				}}
				handleCancel={() => {
					setShowModal(false);
				}}
			>
				{I18n.t(_t(translations.promotionDetail.deleteConfirmPromotion))}
			</Modal>
		</DetailPageLayout>
	) : (
		<Layout style={styles.spinLayout}>
			<Spin tip="Loading..." spinning={isLoading} style={styles.loading} />
		</Layout>
	);
};
