/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Line } from '@ant-design/charts';
import { Typography, Row } from 'antd';
import { I18n, _t, translations, Const } from 'utils';
import styles from './styles';
import GlobalStyles from 'GlobalStyles';
import { IGetRequest } from 'models/RequestModels';
import moment from 'moment';
import { AlertHelper, CurrencyHelper } from 'helpers';
import { lineChartConfig } from 'utils/configurations';
import RangeSelect from './RangeSelect';
import {
	ChartDataType,
	IApiResponse,
	IErrorResponse,
	IRecentSalesChartDataSource,
} from 'models';
import AdminDashboardApiService from 'services/AdminDashboardApiService';
import { useMemo } from 'react';
import _ from 'lodash';
import { Spin } from 'components';

const { Text, Title } = Typography;
const { ChartRangeType } = Const;

interface ISaleChartProps {}

const SaleChart = (props: ISaleChartProps) => {
	const [mode, setMode] = useState(ChartRangeType.WEEK);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isHover, setIsHover] = useState<boolean>(false);
	const [recentSalesChartData, setRecentSaleChart] =
		useState<IRecentSalesChartDataSource>();

	useEffect(() => {
		const fromDate =
			mode === ChartRangeType.MONTH
				? moment().startOf('month').toDate()
				: moment().startOf('week').toDate();
		const toDate =
			mode === ChartRangeType.MONTH
				? moment().endOf('month').toDate()
				: moment().endOf('week').toDate();
		getRecentSalesChart({
			fromDate: fromDate,
			toDate: toDate,
		});
	}, [mode]);

	const renderSubTitle = (mode: number) => (
		<Text strong style={styles.chartSub}>
			{ChartRangeType.MONTH === mode
				? I18n.t(_t(translations.dashboard.lineChartSaleSubMonth))
				: I18n.t(_t(translations.dashboard.lineChartSaleSubWeek))}
		</Text>
	);

	const renderTotalBookingValue = (value: number) => {
		return (
			<Text style={{ ...styles.chartSub, ...styles.chartTotalAppointment }}>
				<strong>{`${I18n.t(
					_t(translations.dashboard.lineChartBookingValue)
				)} ${CurrencyHelper.formatPrice(value)}`}</strong>
			</Text>
		);
	};

	const renderTotalBookingCount = (value: number) => {
		return (
			<Text style={{ ...styles.chartSub, ...styles.chartTotalAppointment }}>
				<strong>{`${I18n.t(
					_t(translations.dashboard.lineChartBookingCount)
				)} ${value}`}</strong>
			</Text>
		);
	};

	const onSelectChangeHandle = (value: number) => {
		setMode(value);
	};

	const renderTotalSale = (value: number) => {
		return (
			<Title level={1} style={styles.chartTotalSale}>
				{CurrencyHelper.formatPrice(value)}
			</Title>
		);
	};

	const getRecentSalesChart = async (request: IGetRequest) => {
		setIsLoading(true);
		const result = (await AdminDashboardApiService.getRecentSalesChart(
			request
		)) as IApiResponse<IRecentSalesChartDataSource>;
		setIsLoading(false);
		if (result.succeeded) {
			setRecentSaleChart(result.data);
		} else {
			const error = result as IErrorResponse;
			AlertHelper.showError(error);
		}
	};

	const saleChartData = useMemo(() => {
		const data: ChartDataType[] = [];
		let dates = 0;
		let endDate: moment.Moment;
		switch (mode) {
			case ChartRangeType.WEEK: {
				dates = 7;
				endDate = moment().endOf('week');
				break;
			}
			case ChartRangeType.MONTH: {
				endDate = moment().endOf('month');
				dates = endDate.daysInMonth();
				break;
			}
		}
		for (let i = dates - 1; i >= 0; --i) {
			const thisDay = endDate!.clone().startOf('day').subtract(i, 'day');
			const thisDayDataIndex = _.findIndex(
				recentSalesChartData?.data,
				(element) => thisDay.date() === moment(element.date).date()
			);
			const sale: ChartDataType = {
				day: thisDay.format('ddd DD'),
				count: 0,
				value:
					thisDayDataIndex === -1
						? 0
						: recentSalesChartData?.data[thisDayDataIndex].salesValue!,
				category: I18n.t(_t(translations.dashboard.categorySale)),
			};

			const booking: ChartDataType = {
				day: thisDay.format('ddd DD'),
				count:
					thisDayDataIndex === -1
						? 0
						: recentSalesChartData?.data[thisDayDataIndex].appointmentCount!,
				value:
					thisDayDataIndex === -1
						? 0
						: recentSalesChartData?.data[thisDayDataIndex].appointmentValue!,
				category: I18n.t(_t(translations.dashboard.categoryBooking)),
			};
			data.push(sale, booking);
		}
		return data;
	}, [recentSalesChartData]);

	return (
		<div
			style={isHover ? styles.chartContainerHover : styles.chartContainer}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<div>
				<Title level={4} style={{ ...styles.chartTitle, ...styles.textBold }}>
					{I18n.t(_t(translations.dashboard.lineChartSaleTitle))}
				</Title>
				<RangeSelect defaultMode={mode} onValueChange={onSelectChangeHandle} />
			</div>
			{isLoading ? (
				<Row style={GlobalStyles.dashboardView}>
					<Spin />
				</Row>
			) : (
				<>
					{renderSubTitle(mode)}
					{renderTotalSale(recentSalesChartData?.totalSalesValue!)}
					{renderTotalBookingValue(
						recentSalesChartData?.totalAppointmentValue!
					)}
					{renderTotalBookingCount(
						recentSalesChartData?.totalAppointmentCount!
					)}
					<Line
						style={GlobalStyles.dashboardView}
						data={saleChartData}
						{...lineChartConfig}
					/>
				</>
			)}
		</div>
	);
};

export default SaleChart;
