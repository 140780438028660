import { ReduxHelper } from 'helpers';
import { IService } from 'models';
import {
	IEditServiceRequest,
	IServiceRequest,
	IGetAllServiceRequest,
	IGetServiceByBranchRequest,
} from 'models/RequestModels';

export const prefix = 'SERVICE';

const getAllServices = ReduxHelper.generateActions<
	IGetAllServiceRequest | undefined,
	IService[]
>(`${prefix}/GET_ALL_SERVICE`);

const addNewService = ReduxHelper.generateActions<
	Partial<IServiceRequest>,
	IService
>(`${prefix}/ADD_NEW_SERVICE`);

const selectCurrentService = ReduxHelper.generateLocalAction<IService>(
	`${prefix}/SELECT_CURRENT_SERVICE`
);

const editService = ReduxHelper.generateActions<
	Partial<IEditServiceRequest>,
	IService
>(`${prefix}/EDIT_SERVICE`);

const deleteService = ReduxHelper.generateActions<string, string>(
	`${prefix}/DELETE_SERVICE`
);

const getServiceById = ReduxHelper.generateActions<string, IService | null>(
	`${prefix}/GET_SERVICE_BY_ID`
);
const reset = ReduxHelper.generateLocalAction(`${prefix}/RESET`);
const updateServices = ReduxHelper.generateLocalAction<IService[]>(
	`${prefix}/UPDATE_SERVICES`
);

export {
	getAllServices,
	getServiceById,
	addNewService,
	editService,
	deleteService,
	selectCurrentService,
	reset,
	updateServices,
};
