import { createReducer, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { AppConfigActions } from '../actions';

export interface IAppConfigReducer {
	showLoading: boolean;
	showNotification: boolean;
	language: string;
	uuid?: string;
	calling?: boolean;
	useV2?: boolean;
}

const initialState: IAppConfigReducer = {
	showLoading: false,
	showNotification: false,
	language: 'en',
};

function isRequest(action: AnyAction) {
	return action.type.endsWith('_REQUEST') && !action.type.includes('LOCAL');
}
function notRequest(action: AnyAction) {
	return !action.type.endsWith('_REQUEST');
}
const AppConfigReducer = createReducer(
	initialState,
	(builder) =>
		builder
			.addCase(
				AppConfigActions.useV2.request,
				(state, action: PayloadAction<boolean>) => {
					state.useV2 = action.payload;
				}
			)
			.addCase(
				AppConfigActions.showLoading.request,
				(state, action: PayloadAction<boolean>) => {
					state.showLoading = action.payload;
				}
			)
			.addCase(
				AppConfigActions.setCalling.request,
				(state, action: PayloadAction<boolean>) => {
					state.calling = action.payload;
				}
			)
			.addCase(
				AppConfigActions.showNotification.request,
				(state, action: PayloadAction<boolean>) => {
					state.showNotification = action.payload;
				}
			)
			.addCase(
				AppConfigActions.chooseLanguage.request,
				(state, action: PayloadAction<string>) => {
					state.language = action.payload;
				}
			)
			.addCase(
				AppConfigActions.setUuid.request,
				(state, action: PayloadAction<string>) => {
					state.uuid = action.payload;
				}
			)
			.addMatcher(isRequest, (state) => {
				state.showLoading = true;
			})
			.addMatcher(notRequest, (state) => {
				state.showLoading = false;
			}).addMatcher
);

export default AppConfigReducer;
