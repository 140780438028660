import { BookingHelper } from 'helpers';
import { priceFixed } from 'helpers/currencyHelper';
import _, { sumBy } from 'lodash';
import { IBookingDetail } from 'models';
import { RootState } from 'redux/configuration/rootReducer';
import { createSelector } from 'reselect';
import { PaymentType, PriceType } from 'utils/Consts';

const getBookingDetails = (state: RootState) =>
	state.BookingReducer.currentBooking?.bookingDetails;

const getCurrentBranch = (state: RootState) =>
	state.BranchReducer.currentBranch;

const cardChargeFlatRate = (state: RootState) =>
	state.BranchReducer.currentBranch?.cardChargeFlatRate;

const cardChargePercent = (state: RootState) =>
	state.BranchReducer.currentBranch?.cardChargePercent;

const getListBookingCombined = (state: RootState) =>
	state.CheckoutReducer.listBookingCombine;

const listPaymentType = (state: RootState) =>
	state.CheckoutReducer.listPaymentType;

const discount = (state: RootState) =>
	state.CheckoutReducer.discount ? state.CheckoutReducer.discount : 0;

const tip = (state: RootState) =>
	state.CheckoutReducer.tip?.totalTip
		? state.CheckoutReducer.tip.totalTip
		: _.sumBy(state.CheckoutReducer.tip?.tips, (e) => e.tipAmount);

const taxOnBill = (state: RootState) =>
	state.BranchReducer.currentBranch!.taxPercent
		? state.BranchReducer.currentBranch!.taxPercent
		: 0;

const giftCards = (state: RootState) => state.CheckoutReducer.listGiftCard;

export const price = createSelector(
	[
		getBookingDetails,
		getListBookingCombined,
		taxOnBill,
		discount,
		tip,
		giftCards,
		cardChargeFlatRate,
		cardChargePercent,
		listPaymentType,
		getCurrentBranch,
		(state: RootState, priceType: number) => priceType,
	],
	(
		bookingDetails,
		listBookingCombined,
		taxPercent,
		discount,
		tip,
		giftCards,
		cardChargeFlatRate,
		cardChargePercent,
		listPaymentType,
		currentBranch,
		priceType
	) => {
		let listAllBookingDetails = Array.from(bookingDetails || []);
		if (!_.isEmpty(listBookingCombined)) {
			listBookingCombined.forEach((booking) => {
				booking.bookingDetails.map((bookingDetail) =>
					listAllBookingDetails.push(bookingDetail)
				);
			});
		}
		const listBookingDetailCanDiscount = listAllBookingDetails.filter(
			(bookingDetail) => bookingDetail.discount === 0
		);
		const subTotal = (bookingDetails: Partial<IBookingDetail>[]) => {
			return _.sumBy(
				bookingDetails,
				(bookingDetail) =>
					BookingHelper.calculateBookingDetailAmount(bookingDetail) +
					(bookingDetail.extraAmount || 0) +
					(bookingDetail.discount || 0) +
					(bookingDetail.promotionDiscount || 0)
			);
		};
		const discountByStylist = _.sumBy(
			listAllBookingDetails,
			(x) => x.discount || 0
		);

		const tax = (bookingDetails: Partial<IBookingDetail>[]) => {
			const taxx =
				_.sumBy(
					bookingDetails,
					(bookingDetail) =>
						(((bookingDetail.item?.price || 0) +
							(bookingDetail.extraAmount || 0) -
							(currentBranch?.discountBeforeTax
								? bookingDetail.discount || 0
								: 0) -
							(currentBranch?.discountBeforeTax
								? bookingDetail.promotionDiscount || 0
								: 0)) *
							taxPercent) /
						100
				) -
				((currentBranch?.discountBeforeTax ? discount : 0) * taxPercent) / 100;
			return taxx < 0 ? 0 : priceFixed(taxx);
		};

		const totalPromotionDiscount = _.sumBy(
			listAllBookingDetails,
			(bookingDetail) => bookingDetail.promotionDiscount || 0
		);
		const total = subTotal(listAllBookingDetails) + tax(listAllBookingDetails);
		const giftCardPrice = _.sumBy(giftCards, (giftCard) => giftCard.balance!);

		const cardCharge =
			listPaymentType.find((x) => x.paymentType === PaymentType.CREDIT_CARD)
				?.cardCharge || 0;

		const balance =
			total +
			tip -
			discount -
			discountByStylist -
			totalPromotionDiscount +
			giftCardPrice +
			cardCharge;
		const maxDiscount =
			subTotal(listBookingDetailCanDiscount) +
			(!currentBranch?.discountBeforeTax
				? tax(listBookingDetailCanDiscount)
				: 0) -
			totalPromotionDiscount;
		switch (priceType) {
			case PriceType.SUB_TOTAL:
				return subTotal(listAllBookingDetails);
			case PriceType.TAX:
				return tax(listAllBookingDetails);
			case PriceType.TOTAL:
				return total;
			case PriceType.BALANCE:
				return balance < 0 ? 0 : balance;
			case PriceType.TIP:
				return tip;
			case PriceType.GIFT_CARD:
				return giftCardPrice;
			case PriceType.MAX_DISCOUNT:
				return maxDiscount;
			case PriceType.CARD_CHARGE:
				return cardCharge;
			default:
				return;
		}
	}
);
