import { ContentAdminContainer } from 'components';
import React from 'react';
import ServiceMenu from './components/ServiceMenu';
import { Services } from './components/ServiceMenuNew';
class ServicesPage extends React.Component {
	render() {
		return (
			<ContentAdminContainer>
				<Services />
			</ContentAdminContainer>
		);
	}
}

export default ServicesPage;
