import React, { ReactNode } from 'react';
import { Col, Layout, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { history } from 'routers';
import { BasicProps, Content } from 'antd/lib/layout/layout';
import _ from 'lodash';
import { connect } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { CloseOutlined } from '@ant-design/icons';

interface IContentAdminContainerProps
	extends BasicProps,
		ReturnType<typeof mapStateToProps> {
	extraHeader?: ReactNode;
	isIcon?: boolean;
	onClose?(): void;
	hidePathName?: boolean;
	customPageName?: string;
}
class ContentAdminContainer extends React.Component<IContentAdminContainerProps> {
	state = {
		reload: false,
	};
	// componentDidUpdate(prev: IContentAdminContainerProps) {
	//   if (!_.isEmpty(this.props.currentBranch)) {
	//     if (prev.currentBranch?.id !== this.props.currentBranch?.id) {
	//       if (this.props.branchAction?.includes(BranchActions.selectBranch.actionName)) {
	//         this.setState({ reload: true }, () => {
	//           setTimeout(() => {
	//             this.setState({ reload: false });
	//           }, 200);
	//         });
	//       }
	//     }
	//   }
	// }

	render() {
		return (
			<Layout {...this.props} style={{ backgroundColor: 'white' }}>
				{this.renderHeader()}
				{!this.state.reload ? (
					<Content
						style={
							!_.isEmpty(this.props.style)
								? this.props.style
								: styles.contentContainer
						}
					>
						{this.props.children}
					</Content>
				) : null}
			</Layout>
		);
	}

	renderHeaderTitle = (text: string) => {
		return (
			<Col>
				<Title level={2} style={{ marginBottom: 0 }}>
					{text}
				</Title>
			</Col>
		);
	};

	getPathName() {
		const pathname = history.location.pathname;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const text = _.last(pathname.split('/')) || '';
		return text.substring(0, 1).toUpperCase() + text.substring(1);
	}
	renderHeader() {
		return (
			<>
				<Row style={styles.rowContainer} justify="space-between">
					{this.props.customPageName
						? this.renderHeaderTitle(this.props.customPageName)
						: !this.props.hidePathName &&
						  this.renderHeaderTitle(this.getPathName())}
					{this.props.isIcon && (
						<Col onClick={this.props.onClose} style={styles.iconContainer}>
							<CloseOutlined style={styles.icClose} />
						</Col>
					)}
				</Row>
				{!_.isEmpty(this.props.extraHeader) ? (
					<Row style={styles.rowContainer}>{this.props.extraHeader}</Row>
				) : null}
			</>
		);
	}
}

const styles = {
	rowContainer: { backgroundColor: 'white', padding: '8px 36px 8px 16px' },
	contentContainer: { padding: 16, height: '100%' },
	icClose: { fontSize: 18, cursor: 'pointer' },
	iconContainer: { alignSelf: 'center' },
};

const mapStateToProps = (state: RootState) => ({
	currentBranch: state.BranchReducer.currentBranch,
	branchAction: state.ReduxActionReducer['BRANCH'],
});
export default connect(mapStateToProps)(ContentAdminContainer);
