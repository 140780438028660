import GlobalStyles, { kStyles } from 'GlobalStyles';

const styles: kStyles = {
	layout: {
		minHeight: '100vh',
	},
	formContainer: { width: '100%' },
	headerContainer: {
		zIndex: 9999,
		width: '100%',
		backgroundColor: 'white',
		position: 'fixed',
		...GlobalStyles.boxShadow,
	},
	noPaddingCol: {
		paddingInline: 0,
		width: '100%',
	},
	iconContainer: { textAlign: 'end', paddingRight: 35 } as React.CSSProperties,

	title: {
		color: 'white',
		textAlign: 'center',
		marginBottom: 0,
		justifyContent: 'center',
	} as React.CSSProperties,
	header: {
		position: 'fixed',
		zIndex: 9999,
		width: '100%',
		alignItems: 'center',
		padding: 0,
		...GlobalStyles.boxShadow,
	} as React.CSSProperties,
	rowHeader: {
		paddingInline: 36,
		alignItems: 'center',
		paddingTop: 16,
		paddingBottom: 16,
	},
	icClose: {
		cursor: 'pointer',
	} as React.CSSProperties,
	headerTitle: {
		textAlign: 'center',
		zIndex: 9999,
		marginBottom: 'auto',
	},
	buttonContainer: { textAlign: 'right', marginBottom: 10 },
	// rowForm: { paddingTop: "120px" }
	spinLayout: {
		height: '100vh',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	spin: { margin: '0 auto' },
	space: { width: '100%' },
	contentContainer: { marginTop: 100 },
};

export default styles;
