import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { IService } from 'models/IServices';
import { ServiceActions } from '../actions';

export interface IServiceReducer {
	services: IService[];
	currentService?: IService;
}

const initialState: IServiceReducer = {
	services: [],
};

// Get Services

function getAllServiceRequest(state: IServiceReducer) {
	state.services = [];
}

function getAllServiceSuccess(
	state: IServiceReducer,
	action: PayloadAction<IService[]>
) {
	state.services = action.payload;
}

function getServiceByIdSuccess(
	state: IServiceReducer,
	action: PayloadAction<IService | null>
) {
	state.currentService = action.payload!;
}

const reset = (state: IServiceReducer) => {
	state.currentService = undefined;
};

const selectCurrentService = (
	state: IServiceReducer,
	action: PayloadAction<IService>
) => {
	state.currentService = action.payload;
};

const createServiceSuccess = (
	state: IServiceReducer,
	action: PayloadAction<IService>
) => {
	if (action.payload) {
		state.services.push(action.payload);
	}
};

const editServiceSuccess = (
	state: IServiceReducer,
	action: PayloadAction<IService>
) => {
	if (action.payload && action.payload.id) {
		const index = state.services.findIndex((x) => x.id === action.payload.id);
		if (index >= 0) {
			state.services[index] = action.payload;
		}
	}
};

const deleteServiceSuccess = (
	state: IServiceReducer,
	action: PayloadAction<string>
) => {
	if (action.payload) {
		const index = state.services.findIndex((x) => x.id === action.payload);
		if (index >= 0) {
			state.services.splice(index, 1);
		}
	}
};
const updateServices = (
	state: IServiceReducer,
	action: PayloadAction<IService[]>
) => {
	state.services = _.unionBy(action.payload, state.services, 'id');
};

const ServiceReducer = createReducer(initialState, (builder) =>
	builder
		.addCase(ServiceActions.getAllServices.request, getAllServiceRequest)
		.addCase(ServiceActions.getAllServices.success, getAllServiceSuccess)
		.addCase(ServiceActions.getServiceById.success, getServiceByIdSuccess)
		.addCase(ServiceActions.reset.request, reset)
		.addCase(ServiceActions.selectCurrentService.request, selectCurrentService)
		.addCase(ServiceActions.addNewService.success, createServiceSuccess)
		.addCase(ServiceActions.editService.success, editServiceSuccess)
		.addCase(ServiceActions.deleteService.success, deleteServiceSuccess)
		.addCase(ServiceActions.updateServices.request, updateServices)
);

export default ServiceReducer;
