import { Col, Row } from 'antd/lib/grid';
import _ from 'lodash';
import { IBookingDetail, IService } from 'models';
import { useDispatch, useSelector } from 'react-redux';
import {
	CategoryActions,
	CustomerBookingActions,
	ServiceActions,
} from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import { CurrencyHelper, TimeHelper, checkIsMobile } from 'helpers';
import InfiniteScroll from 'react-infinite-scroller';
import { Typography, List, Checkbox, Button, Tabs } from 'antd';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

enum button {
	LEFT,
	RIGHT,
}

interface ICategoryComponent {
	activeKey: number;
	onChangeTabCategory: (id: number) => void;
	handleChangeButtonCategory: (type: button) => void;
}
interface IServiceComponent {
	activeKey: number;
	onChangeCheckedService: (checked: boolean, service: IService) => void;
}
interface ILayout {
	activeKey: number;
	onChangeTabCategory: (id: number) => void;
	onChangeCheckedService: (checked: boolean, service: IService) => void;
	handleChangeCategoryIndex: (type: button) => void;
}

const useHandleCategory = () => {
	const [startIndex, setStartIndex] = useState<number>(0);
	const [endIndex, setEndIndex] = useState<number>(checkIsMobile() ? 2 : 4);
	const categories = useSelector(
		(state: RootState) => state.CategoryReducer.categories
	);
	const handleLeftButtonItemCategory = () => {
		let endIndexUpdate = endIndex - 1;
		let startIndexUpdate = startIndex - 1;
		if (startIndex !== 0) {
			setEndIndex(endIndexUpdate);
			setStartIndex(startIndexUpdate);
		}
	};
	const handleRightButtonItemCategory = () => {
		let endIndexUpdate = endIndex + 1;
		let startIndexUpdate = startIndex + 1;
		if (endIndex < categories.length) {
			setEndIndex(endIndexUpdate);
			setStartIndex(startIndexUpdate);
		}
	};
	return {
		endIndex,
		startIndex,
		handleLeftButtonItemCategory,
		handleRightButtonItemCategory,
	};
};
const getDimensions = (ele: HTMLDivElement) => {
	const { height } = ele.getBoundingClientRect();
	const offsetTop = ele.offsetTop;
	const offsetBottom = offsetTop + height;

	return {
		height,
		offsetTop,
		offsetBottom,
	};
};
const scrollTo = (ele: Element) => {
	ele.scrollIntoView({
		behavior: 'smooth',
		block: 'start',
	});
};
const WebLayOut = (props: ILayout) => {
	const { activeKey, onChangeTabCategory } = props;
	const categories = useSelector((state: RootState) =>
		state.CategoryReducer.categories
			.slice()
			.sort((cateA, cateB) => cateA.indexNumber! - cateB.indexNumber!)
	);
	const [categoriesRef, setCategoriesRef] = useState<
		React.RefObject<HTMLDivElement>[]
	>([]);

	useEffect(() => {
		setCategoriesRef((categoriesRef) =>
			Array(categories.length)
				.fill(null)
				.map((_, i) => categoriesRef[i] || createRef())
		);
		onChangeTabCategory(1);
	}, [categories.length]);

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY + 120;
			const selected = categoriesRef.findIndex((ref) => {
				const ele = ref.current;
				if (ele) {
					const { offsetBottom, offsetTop } = getDimensions(ele);
					return scrollPosition > offsetTop && scrollPosition < offsetBottom;
				}
			});
			if (selected !== -1 && selected !== activeKey) {
				onChangeTabCategory(selected);
			}
		};
		handleScroll();
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [activeKey]);

	return (
		<div style={{ paddingBottom: '20vh' }}>
			{!_.isEmpty(categories) && (
				<CategoryWebLayout
					activeKey={activeKey}
					onChangeTabCategory={(id) => {
						scrollTo(categoriesRef[id].current!);
					}}
					handleChangeButtonCategory={(buttonType) => {
						if (buttonType === button.LEFT) {
							scrollTo(
								categoriesRef[activeKey === 0 ? 0 : activeKey - 1].current!
							);
						} else {
							scrollTo(
								categoriesRef[
									categories.length > activeKey + 1 ? activeKey + 1 : 0
								].current!
							);
						}
					}}
				/>
			)}
			<div style={{ marginBottom: '16px' }}>
				{categories.map((item, index) => {
					return (
						<div
							ref={categoriesRef[index]}
							id={`index`}
							className="category-div"
						>
							<div className="name-itemcategory">
								<Typography.Title level={4}>{item.name}</Typography.Title>
							</div>
							<ServiceWebLayout
								activeKey={index}
								onChangeCheckedService={props.onChangeCheckedService}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

const MobileLayout = (props: ILayout) => {
	const { activeKey, onChangeTabCategory } = props;
	const categories = useSelector(
		(state: RootState) => state.CategoryReducer.categories
	);
	const [categoriesRef, setCategoriesRef] = useState<
		React.RefObject<HTMLDivElement>[]
	>([]);

	useEffect(() => {
		setCategoriesRef((categoriesRef) =>
			Array(categories.length)
				.fill(null)
				.map((_, i) => categoriesRef[i] || createRef())
		);
		onChangeTabCategory(1);
	}, [categories.length]);

	const handleScroll = () => {
		const scrollPosition = window.scrollY + 202;
		const selected = categoriesRef.findIndex((ref) => {
			const ele = ref.current;
			if (ele) {
				const { offsetBottom, offsetTop } = getDimensions(ele);
				return scrollPosition > offsetTop && scrollPosition < offsetBottom;
			}
		});
		if (selected !== -1 && selected !== activeKey) {
			onChangeTabCategory(selected);
		}
	};
	useEffect(() => {
		handleScroll();
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [activeKey]);

	return (
		<div>
			{!_.isEmpty(categories) && (
				<CategoryMobileLayout
					activeKey={activeKey}
					onChangeTabCategory={(id) => {
						scrollTo(categoriesRef[id].current!);
					}}
					handleChangeButtonCategory={(buttonType) => {
						if (buttonType === button.LEFT) {
							scrollTo(
								categoriesRef[activeKey === 0 ? 0 : activeKey - 1].current!
							);
						} else {
							scrollTo(
								categoriesRef[
									categories.length > activeKey + 1 ? activeKey + 1 : 0
								].current!
							);
						}
					}}
				/>
			)}
			<div style={{ marginBottom: '16px' }}>
				{categories.map((item, index) => {
					return (
						<div
							ref={categoriesRef[index]}
							id={`index`}
							className="category-div"
						>
							<div className="name-itemcategory">
								<Typography.Title level={4}>{item.name}</Typography.Title>
							</div>
							<ServiceMobileLayout
								activeKey={index}
								onChangeCheckedService={props.onChangeCheckedService}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

const CategoryMobileLayout = (props: ICategoryComponent) => {
	const categories = useSelector((state: RootState) =>
		state.CategoryReducer.categories
			.slice()
			.sort((cateA, cateB) => cateA.indexNumber! - cateB.indexNumber!)
	);

	const handleCategories = useHandleCategory();
	return (
		<div className="background-select-service-item-category">
			<div className="background-item-category">
				<Row style={{ textAlign: 'center' }}>
					<Col span={2} className="icon-next-pre-mobile">
						<LeftOutlined
							onClick={() => props.handleChangeButtonCategory(button.LEFT)}
						/>
					</Col>
					<Col span={20}>
						<Row>
							<Tabs
								activeKey={`${props.activeKey}`}
								className="custom-tab"
								onChange={(x) => props.onChangeTabCategory(_.toNumber(x))}
							>
								{[
									...Array.from(
										categories
											.slice()
											.sort(
												(categoryA, categoryB) =>
													categoryA.indexNumber! - categoryB.indexNumber!
											)
									),
								].map((i, index) => (
									<TabPane tab={i.name} key={index}></TabPane>
								))}
							</Tabs>
						</Row>
					</Col>
					<Col span={2} className="icon-next-pre-mobile">
						<RightOutlined
							onClick={() => props.handleChangeButtonCategory(button.RIGHT)}
						/>
					</Col>
				</Row>
			</div>
		</div>
	);
};
const CategoryWebLayout = (props: ICategoryComponent) => {
	const categories = useSelector((state: RootState) =>
		state.CategoryReducer.categories
			.slice()
			.sort((cateA, cateB) => cateA.indexNumber! - cateB.indexNumber!)
	);
	const handleCategories = useHandleCategory();

	return (
		<div className="background-select-service-item-category">
			<div className="background-item-category">
				<Row style={{ textAlign: 'center' }}>
					<Col span={2} className="icon-next-pre">
						<LeftOutlined
							onClick={() => props.handleChangeButtonCategory(button.LEFT)}
						/>
					</Col>
					<Col span={20}>
						<Row>
							<Tabs
								activeKey={`${props.activeKey}`}
								className="custom-tab"
								onChange={(x) => props.onChangeTabCategory(_.toNumber(x))}
							>
								{[...Array.from(categories)].map((i, index) => (
									<TabPane tab={i.name} key={index}></TabPane>
								))}
							</Tabs>
						</Row>
					</Col>
					<Col span={2} className="icon-next-pre">
						<RightOutlined
							onClick={() => props.handleChangeButtonCategory(button.RIGHT)}
						/>
					</Col>
				</Row>
			</div>
		</div>
	);
};
const ServiceWebLayout = (props: IServiceComponent) => {
	const services = useSelector((state: RootState) =>
		state.ServiceReducer.services
			.slice()
			.sort(
				(serviceA, serviceB) => serviceA.indexNumber! - serviceB.indexNumber!
			)
	);
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	)!;
	const categories = useSelector(
		(state: RootState) => state.CategoryReducer.categories
	);
	return (
		<div className="background-select-service-item">
			<div className="background-item">
				<div>
					<InfiniteScroll
						initialLoad={true}
						pageStart={0}
						loadMore={() => {}}
						useWindow={false}
					>
						<List
							dataSource={services.filter(
								(e: IService, index: number) =>
									e.itemCategoryId === categories[props.activeKey].id
							)}
							rowKey={(e: any) => e.id!.toString()}
							renderItem={(service: IService) => (
								<List.Item key={service.id}>
									<Row gutter={16} className="list-item-service full-width">
										<Col span={2}>
											<Checkbox
												onChange={(e) =>
													props.onChangeCheckedService!(
														e.target.checked,
														service
													)
												}
												checked={booking?.bookingDetails
													?.map((x) => x.itemId)
													.includes(service.id)}
												className="checkbox-round"
											></Checkbox>
										</Col>
										<Col span={16}>
											<Typography.Title
												className="select-service-item-title"
												level={5}
											>
												{service.name}
											</Typography.Title>
											<div>
												<Typography.Text className="select-service-item-time">
													{TimeHelper.convertDurationToStringDetail(
														service.duration
													)}
												</Typography.Text>
											</div>
											{!_.isEmpty(service.description) && (
												<Row className="select-service-item-content">
													{service.description}
												</Row>
											)}
										</Col>
										<Col
											style={{ justifyContent: 'flex-end', display: 'flex' }}
											span={6}
										>
											<Typography.Title level={5}>
												{CurrencyHelper.formatPrice(service.price)}
											</Typography.Title>
										</Col>
									</Row>
								</List.Item>
							)}
						></List>
					</InfiniteScroll>
				</div>
			</div>
		</div>
	);
};
const ServiceMobileLayout = (props: IServiceComponent) => {
	const services = useSelector((state: RootState) =>
		state.ServiceReducer.services
			.slice()
			.sort(
				(serviceA, serviceB) => serviceA.indexNumber! - serviceB.indexNumber!
			)
	);
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	)!;
	const categories = useSelector((state: RootState) =>
		state.CategoryReducer.categories
			.slice()
			.sort(
				(categoryA, categoryB) =>
					categoryA.indexNumber! - categoryB.indexNumber!
			)
	);
	return (
		<div className="background-select-service-item">
			<div className="background-item-mobile">
				<div>
					<InfiniteScroll
						initialLoad={true}
						pageStart={0}
						loadMore={() => {}}
						useWindow={false}
					>
						<List
							dataSource={services.filter(
								(e: IService, index: number) =>
									e.itemCategoryId === categories[props.activeKey].id
							)}
							rowKey={(e: any) => e.id!.toString()}
							renderItem={(service: IService) => (
								<List.Item key={service.id}>
									<Row className="list-item-service">
										<Col span={4}>
											<Checkbox
												onChange={(e) =>
													props.onChangeCheckedService!(
														e.target.checked,
														service
													)
												}
												checked={booking?.bookingDetails
													?.map((x) => x.itemId)
													.includes(service.id)}
												className="checkbox-round-mobile"
											></Checkbox>
										</Col>
										<Col span={15}>
											<Typography.Title
												className="select-service-item-title"
												level={5}
											>
												{service.name}
											</Typography.Title>
											<div>
												<Typography.Text className="select-service-item-time">
													{TimeHelper.convertDurationToStringDetail(
														service.duration
													)}
												</Typography.Text>
											</div>
											<Row className="select-service-item-content">
												{service.description}
											</Row>
										</Col>
										<Col span={5}>
											<Typography.Title level={5}>
												{CurrencyHelper.formatPrice(service.price)}
											</Typography.Title>
										</Col>
									</Row>
								</List.Item>
							)}
						></List>
					</InfiniteScroll>
				</div>
			</div>
		</div>
	);
};

export const SelectService = () => {
	const categories = useSelector((state: RootState) =>
		state.CategoryReducer.categories
			.slice()
			.sort((cateA, cateB) => cateA.indexNumber! - cateB.indexNumber!)
	);
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	)!;
	const bookingDetails = booking?.bookingDetails || [];
	const [activeKey, setActiveKey] = useState<number>(0);
	const branchId = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch?.id
	);
	// useEffect(() => {
	// 	setActiveKey(!_.isEmpty(categories) ? activeKey : '');
	// }, [categories]);

	useEffect(() => {
		dispatch(CategoryActions.getAllCategory.request());
		dispatch(ServiceActions.getAllServices.request());
	}, [branchId]);

	const onChangeTabCategory = (key: number) => {
		setActiveKey(key);
	};

	const updateBookingDetails = (checked: boolean, service: IService) => {
		// check if booking.BookingDetail includes service
		if (checked) {
			const newBookingDetail: Partial<IBookingDetail> = {
				itemId: service.id,
				item: service,
				amount: service.price,
				branchId: branchId,
			};
			newBookingDetails.push(newBookingDetail);
		} else {
			_.remove(
				newBookingDetails,
				(bookingDetail) => bookingDetail.itemId === service.id
			);
		}

		dispatch(
			CustomerBookingActions.updateBooking.request({
				...booking,
				bookingDetails: newBookingDetails,
			})
		);
	};

	const onChangeCheckedService = (checked: boolean, service: IService) => {
		updateBookingDetails(checked, service);
	};

	const handleChangeCategoryIndex = (buttonType: button) => {
		if (buttonType === button.LEFT) {
			setActiveKey(activeKey === 0 ? 0 : activeKey - 1);
		} else {
			setActiveKey(categories.length > activeKey + 1 ? activeKey + 1 : 0);
		}
	};

	const dispatch = useDispatch();
	const newBookingDetails = Array.from(bookingDetails || []);

	if (checkIsMobile()) {
		return (
			<MobileLayout
				activeKey={activeKey}
				onChangeTabCategory={onChangeTabCategory}
				onChangeCheckedService={onChangeCheckedService}
				handleChangeCategoryIndex={handleChangeCategoryIndex}
			/>
		);
	}
	return (
		<WebLayOut
			activeKey={activeKey}
			onChangeTabCategory={onChangeTabCategory}
			onChangeCheckedService={onChangeCheckedService}
			handleChangeCategoryIndex={handleChangeCategoryIndex}
		/>
	);
};
