import { useMemo, useRef } from 'react';
import { Col, Row, Space, Button, Typography, Switch } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { I18n, _t, translations } from 'utils';
import { Avatar } from 'components';
import _ from 'lodash';
import { IApiResponse, IEmployee, IErrorResponse } from 'models';
import { AlertHelper, StringHelper } from 'helpers';
import styles from './styles';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import { history } from 'routers';
import AppTable, { IColumnProps, ITableRef } from 'components/Table';
import EmployeeApiService from 'services/EmployeeApiService';
import moment from 'moment';

interface IListStaffProps extends ReturnType<typeof mapStateToProps> {}

const Header = () => {
	return (
		<Row gutter={16} style={{ alignItems: 'center' }}>
			<Col md={4}></Col>
			<Col md={20}>
				<Space
					style={{ justifyContent: 'flex-end', width: '100%' }}
					size="small"
				>
					<Button
						onClick={() => history.push('/staffs/add-new')}
						icon={<PlusOutlined />}
						type="primary"
					>
						{I18n.t(_t(translations.addNew))}
					</Button>
				</Space>
			</Col>
		</Row>
	);
};

export const StaffMembers = (props: IListStaffProps) => {
	const tableRef = useRef<ITableRef>();

	const columns = useMemo<IColumnProps<IEmployee>[]>(() => {
		return [
			{
				header: I18n.t(_t(translations.customerList.name)),
				key: 'id',
				renderCell: (data) => {
					const staff = data as IEmployee;
					const staffName = `${staff.firstName} ${staff.lastName || ''}`;
					return (
						<Space>
							<Avatar
								size="large"
								src={staff.imageUrl}
								name={staffName}
							></Avatar>
							<Typography.Text>{staffName}</Typography.Text>
						</Space>
					);
				},
			},
			{
				header: I18n.t(_t(translations.customerList.role)),
				key: 'employeeTypeCode',
				renderCell: (data) => {
					return (
						<Typography.Text>
							{_.join(
								data!.employeeTypes?.map((x) => x.name),
								','
							)}
						</Typography.Text>
					);
				},
			},
			{
				header: I18n.t(_t(translations.customerList.startDate)),
				key: 'hireDate',
				type: 'date',
			},
			{
				header: I18n.t(_t(translations.customerList.mobile)),
				key: 'phone',
				renderCell: (data) => {
					return (
						<Button
							type="link"
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<Typography.Link
								style={styles.link}
								href={`callto:${data!.phone}`}
							>
								{StringHelper.formatPhoneNumber(data!.phone)}
							</Typography.Link>
						</Button>
					);
				},
			},
			{
				header: I18n.t(_t(translations.email)),
				key: 'email',
				width: 200,
				renderCell: (data) => {
					return (
						<Button
							type="link"
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<Typography.Link
								style={styles.link}
								href={`mailto:${data!.email}`}
							>
								{data!.email}
							</Typography.Link>
						</Button>
					);
				},
			},
			{
				header: I18n.t(_t(translations.customerList.dateOfBirth)),
				key: 'dateOfBirth',
				renderCell: (data) => {
					return data?.dateOfBirth ? (
						<Typography.Text>
							{moment(data?.dateOfBirth).format('MM/DD/YYYY')}
						</Typography.Text>
					) : (
						<></>
					);
				},
			},
			{
				header: I18n.t(_t(translations.status)),
				key: 'status',
				renderCell: (data) => {
					let isChecked = data?.status! === 0 ? false : true;
					return (
						<Switch
							checked={isChecked}
							onChange={() => onChangeStatus(data!)}
						></Switch>
					);
				},
			},
			{
				header: I18n.t(_t(translations.staff.bookedOnlineStatus)),
				key: 'canBookedOnline',
				renderCell: (data) => {
					return (
						<Switch
							checked={data?.canBookedOnline}
							onChange={() => onChangeBookedOnlineStatus(data!)}
						></Switch>
					);
				},
			},
			{
				header: '',
				key: 'id',
				renderCell: (data) => {
					return (
						<Space>
							<Button
								icon={<EditOutlined />}
								onClick={(e) => {
									e.stopPropagation();
									history.push(`staffs/edit/${data!.id}`);
								}}
							></Button>
						</Space>
					);
				},
			},
		];
	}, []);

	const onChangeStatus = async (data: IEmployee) => {
		const updateRequest: Partial<IEmployee> = {
			id: data.id,
			status: data.status === 0 ? 1 : 0,
		};
		const updateCanBookedOnlineRequest: Partial<IEmployee> = {
			id: data.id,
			canBookedOnline: updateRequest.status === 0 ? false : true,
		};
		await EmployeeApiService.updateBookedOnlineStatus(
			updateCanBookedOnlineRequest
		);
		let response = (await EmployeeApiService.updateEmployeeStatus(
			updateRequest
		)) as IApiResponse;
		if (response?.succeeded) {
			AlertHelper.showSuccess(
				I18n.t(_t(translations.staffDetail.messageEditSuccess))
			);
			tableRef.current?.refresh();
		} else {
			const error = response as IErrorResponse;
			AlertHelper.showError(error);
		}
	};

	const onChangeBookedOnlineStatus = async (data: IEmployee) => {
		const updateRequest: Partial<IEmployee> = {
			id: data.id,
			canBookedOnline: !data.canBookedOnline,
		};
		let response = (await EmployeeApiService.updateBookedOnlineStatus(
			updateRequest
		)) as IApiResponse;
		if (response?.succeeded) {
			AlertHelper.showSuccess(
				I18n.t(_t(translations.staffDetail.messageEditSuccess))
			);
			tableRef.current?.refresh();
		} else {
			const error = response as IErrorResponse;
			AlertHelper.showError(error);
		}
	};

	return (
		<Space direction="vertical" style={styles.container} size="large">
			<Row>
				<Col style={{ width: '100%' }}>
					<AppTable<IEmployee>
						ref={(ref) => {
							if (ref) {
								tableRef.current = ref;
							}
						}}
						columns={columns}
						queryUrl={'/Employee/get-all-employees'}
						// onClickRow={(data) => history.push(`staffs/edit/${data.id}`)}
						showSearch
						renderHeader={<Header />}
						additionalParams={{
							orderBy: 'firstName,lastName',
						}}
					/>
				</Col>
			</Row>
		</Space>
	);
};

const mapStateToProps = (state: RootState) => ({
	currentAction: state.ReduxActionReducer['BRANCH'],
});

export default connect(mapStateToProps)(StaffMembers);
