import GlobalStyles from 'GlobalStyles';

const styles = {
	layoutContainer: { backgroundColor: 'white' },
	header: {
		position: 'fixed',
		zIndex: 1,
		width: '100%',
		alignItems: 'center',
		backgroundColor: 'white',
		padding: 0,
		...GlobalStyles.boxShadow,
	} as React.CSSProperties,
	contentContainer: { marginTop: 64, height: '100vh' },
	btnConfirm: {
		width: '100%',
		fontSize: 18,
	},
	icClose: {
		color: 'black',
		fontSize: 18,
		cursor: 'pointer',
		right: 32,
	} as React.CSSProperties,
	iconContainer: { textAlign: 'end', paddingRight: 35 } as React.CSSProperties,
	noPaddingCol: {
		paddingInline: 0,
		width: '100%',
	},
	maxWidth: { width: '100%' },

	title: {
		color: 'black',
		textAlign: 'center',
		marginBottom: 0,
		justifyContent: 'center',
	} as React.CSSProperties,
	textCenter: { textAlign: 'center' } as React.CSSProperties,
	textRight: { textAlign: 'right' } as React.CSSProperties,
	buttonTipTypeContainer: {
		width: '33%',
		textAlign: 'center',
	} as React.CSSProperties,
	inputNumber: { width: '100%', textAlign: 'right' } as React.CSSProperties,
};

export default styles;
