import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Input, ModalProps, Row, Typography } from 'antd';
import { TimeHelper } from 'helpers';
import ModalHeper from 'helpers/modalHelper';
import { IBookingDetail, IService } from 'models';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { getActiveCategory } from 'redux/selectors/category';
import { searchServices } from 'redux/selectors/service';
import { I18n, translations, _t } from 'utils';

interface ISelectServiceModalProps
	extends ModalProps,
		ReturnType<typeof mapStateToProps> {
	onCancel(): void;
	bookingIndex: number;
	onSelectService(serviceId: string, bookingIndex: number): void;
	bookingDetails: Partial<IBookingDetail>[];
}

interface ISelectServiceModalState {
	searchTerm?: string;
}

class SelectServiceModal extends React.Component<
	ISelectServiceModalProps,
	ISelectServiceModalState
> {
	constructor(props: ISelectServiceModalProps) {
		super(props);
		this.state = {
			searchTerm: '',
		};
	}
	render() {
		return (
			<ModalHeper
				visible={true}
				title={I18n.t(_t(translations.mainBooking.selectService))}
				width={'65%'}
				onCancel={() => this.props.onCancel()}
				onOk={() => null}
				btnOkHidden={true}
				// footer={
				// 	<Button onClick={() => this.props.onCancel()}>
				// 		{I18n.t(_t(translations.cancel))}
				// 	</Button>
				// }
			>
				{this.renderSearchInput()}
				{this.renderContent()}
			</ModalHeper>
		);
	}

	renderSearchInput = () => {
		return (
			<Input
				allowClear
				prefix={<SearchOutlined />}
				placeholder={I18n.t(_t(translations.mainBooking.searchByService))}
				value={this.state.searchTerm}
				onChange={(e) => this.setState({ searchTerm: e.target.value })}
				autoFocus={true}
			/>
		);
	};

	renderContent = () => {
		return (
			<Col
				style={{
					height: window.innerHeight * 0.6,
					overflowY: 'auto',
					overflowX: 'hidden',
					paddingBottom: 10,
				}}
			>
				{this.getNiceData().map((services, index) => {
					return services.length ? (
						<>
							<Divider key={this.props.categories[index].id} orientation="left">
								<Typography.Text style={{ fontSize: 20 }}>
									{this.props.categories[index].name}
								</Typography.Text>
							</Divider>
							<Row gutter={[8, 8]}>
								{services.map((service) =>
									this.renderItemService(service, this.props.bookingIndex)
								)}
							</Row>
						</>
					) : null;
				})}
			</Col>
		);
	};

	renderItemService = (service: IService, bookingIndex = 0) => {
		// const serviceCanChoose = this.checkServiceCanChoose(
		// 	service.id,
		// 	bookingIndex
		// );
		// if (!serviceCanChoose) {
		// 	return null;
		// }
		return (
			<Col span={6}>
				<Button
					key={service.id}
					// disabled={!serviceCanChoose}
					block
					style={{ height: 150, whiteSpace: 'normal' }}
					size="large"
					onClick={() => {
						this.props.onSelectService(service.id, this.props.bookingIndex);
					}}
				>
					{/* <Typography.Text style={{ width: '100%' }}> */}
					<Typography.Text style={{ fontSize: 20 }}>
						{service.name}
					</Typography.Text>
					<br />{' '}
					<Typography.Text style={{ fontSize: 18 }} strong>
						(${service.price} -{' '}
						{TimeHelper.convertDurationToStringDetail(service.duration)}
					</Typography.Text>
					){/* </Typography.Text> */}
				</Button>
			</Col>
		);
	};

	checkServiceCanChoose(serviceId: string, bookingIndex: number): boolean {
		let serviceCanChoose = true;
		for (let i = 0; i < this.props.bookingDetails.length; i++) {
			if (
				i !== bookingIndex &&
				this.props.bookingDetails[i].itemId === serviceId
			) {
				serviceCanChoose = false;
				return false;
			}
		}
		return serviceCanChoose;
	}

	getNiceData = (): [IService[]] => {
		const { categories } = this.props;
		const filteredArr = categories
			.slice()
			.sort(
				(categoryA, categoryB) =>
					categoryA.indexNumber! - categoryB.indexNumber!
			)
			.map(() => [] as IService[]) as [IService[]];
		this.props.searchService(this.state.searchTerm!).forEach((service) => {
			const categoryIndex = categories.findIndex(
				(category) => category.id === service.itemCategoryId
			);
			if (categoryIndex !== -1) {
				filteredArr[categoryIndex].push(service);
			}
		});
		return filteredArr;
	};
}

const mapStateToProps = (state: RootState) => ({
	categories: getActiveCategory(state),
	searchService: (textInput: string) => searchServices(state, textInput),
});

export default connect(mapStateToProps)(SelectServiceModal);
