import {
	Button,
	Card,
	Col,
	Descriptions,
	Divider,
	Row,
	Typography,
} from 'antd';
import React from 'react';
import CustomerInfo from 'components/CustomerInfo';
import {
	IAddRefund,
	IApiResponse,
	ICustomer,
	IErrorResponse,
	IPaymentMethod,
	IRefund,
} from 'models';
import { Colors, I18n, translations, _t } from 'utils';
import { IBilling } from 'models/IBilling';
import CustomerApiService from 'services/CustomerApiService';
import { AlertHelper, BookingHelper } from 'helpers';
import { Spin } from 'components';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import { DiscounterType, PaymentType } from 'utils/Consts';
import { formatBalance } from 'helpers/currencyHelper';
import { ITransaction } from 'models/ITransaction';
import { RefundApiSerivce, TransactionApiService } from 'services';
import ConfirmModal from './ConfirmModal';
import momentTz from 'moment-timezone';
import _ from 'lodash';
const { Title } = Typography;

interface IPaymentProps extends ReturnType<typeof mapStateToProps> {
	// refund?: IRefund;
	bill: IBilling;
	onPrint?(): void;
	onCreateRefundSuccess?(): void;
}
interface IPaymentStates {
	customer?: ICustomer;
	isLoading?: boolean;
	isPrinting?: boolean;
	transaction?: ITransaction;
	isShowConfirmModal?: boolean;
	// isShowAddRefundModal?: boolean;
	isAddRefundLoading?: boolean;
}
class Payment extends React.Component<IPaymentProps, IPaymentStates> {
	constructor(props: IPaymentProps) {
		super(props);
		this.state = {
			isLoading: true,
		};
	}
	componentDidMount() {
		this.getData();
	}
	render() {
		return this.state.isLoading ? (
			<Spin />
		) : (
			<>
				{this.state.customer && this.renderContainer()}
				{/* {this.state.isShowAddRefundModal && (
					<AddRefundModal
						onCancel={() => this.setState({ isShowAddRefundModal: false })}
						onOk={this.onSubmitRefund}
						bill={this.props.bill}
						isLoading={this.state.isAddRefundLoading}
						// refund={this.props.refund}
					/>
				)} */}
				{this.state.isShowConfirmModal && (
					<ConfirmModal
						onCancel={() => {
							this.setState({ isShowConfirmModal: false });
						}}
						onOk={this.onConfirmRefund}
					/>
				)}
			</>
		);
	}

	renderContainer() {
		return (
			<>
				<CustomerInfo customer={this.state.customer} />
				<Divider />
				{this.renderContent()}
			</>
		);
	}

	renderContent = () => {
		return (
			<>
				{this.renderPaymentInformation()}
				{this.renderFooterButton()}
			</>
		);
	};

	renderPaymentInformation = () => {
		const { bill } = this.props;
		return (
			<Card
				title={
					<Title level={5}>
						{I18n.t(_t(translations.checkout.paymentInfo))}
					</Title>
				}
				bordered={false}
				style={{ backgroundColor: '#F7F7F8' }}
			>
				{bill.transactions?.map((element, index) => {
					const paymentType = element.paymentType;
					// if (paymentType === PaymentType.CASH) {
					return (
						<>
							{this.renderItemInfo(
								I18n.t(_t(translations.checkout.paymentType)),
								this.getPaymentName(paymentType, element.paymentMethod),
								element.transactionType === 1
									? I18n.t(_t(translations.billing.refund))
									: undefined
							)}
							{paymentType === PaymentType.CREDIT_CARD &&
								this.renderItemInfo(
									I18n.t(_t(translations.checkout.reference)),
									element.transactionReferenceNumber || 'N/A'
								)}
							{paymentType === PaymentType.CASH &&
								this.renderItemInfo(
									I18n.t(_t(translations.checkout.cash)),
									`${formatBalance(element.amount)}`
								)}
							{paymentType === PaymentType.GIFT_CARD &&
								this.renderItemInfo(
									I18n.t(_t(translations.billing.giftCardValue)),
									`${formatBalance(element.amount)}`
								)}
							{paymentType === PaymentType.OTHER &&
								this.renderItemInfo(
									I18n.t(_t(translations.billing.giftCardValue)),
									`${formatBalance(element.amount)}`
								)}
							{/* {paymentType === PaymentType.CREDIT_CARD &&
									this.renderItemInfo(
										I18n.t(_t(translations.checkout.cardCharge)),
										`${formatBalance(bill.cardCharge)}`
									)} */}
							{index !== bill.transactions?.length! - 1 && <Divider />}
						</>
					);
					// }
					// return null;
				})}
			</Card>
		);
	};

	renderItemInfo(title: string, value: string, specialText?: string) {
		return (
			<Descriptions
				style={{
					width: '100%',
					alignContent: 'baseline',
					alignItems: 'baseline',
				}}
				labelStyle={{ fontSize: 14 }}
				contentStyle={{
					justifyContent: 'flex-end',
					alignContent: 'baseline',
					fontSize: 14,
					fontWeight: 600,
				}}
				column={1}
			>
				<Descriptions.Item
					style={{ paddingBottom: 1 }}
					label={
						<span>
							<Typography.Text>{title}</Typography.Text>
							{specialText && (
								<Typography.Text strong style={{ color: Colors.RED_BERRY }}>
									{' '}
									({specialText})
								</Typography.Text>
							)}
						</span>
					}
				>
					{value}
				</Descriptions.Item>
			</Descriptions>
		);
	}

	getPaymentName = (
		paymentType: PaymentType,
		paymentMethod?: IPaymentMethod
	) => {
		switch (paymentType) {
			case PaymentType.CASH:
				return I18n.t(_t(translations.checkout.cash));
			case PaymentType.GIFT_CARD:
				return I18n.t(_t(translations.checkout.giftCard));
			case PaymentType.CREDIT_CARD:
				return I18n.t(_t(translations.checkout.creditCard));
			case PaymentType.APP:
				return `App`;
			case PaymentType.OTHER:
				return paymentMethod?.name || '';
			default:
				return '';
		}
	};

	// renderTipDiscountButton = () => {
	//   return (
	//     <Row gutter={[16, 16]} align="top">
	//       {this.TIP_DISCOUNT_BUTTON.map((button) => {
	//         return (
	//           <Col key={button.key} sm={24} md={24} lg={12}>
	//             <Button
	//               id={`${button.id}`}
	//               icon={button.icon}
	//               type="dashed"
	//               size="large"
	//               style={styles.maxWidth}
	//               disabled={button.disable}
	//               onClick={button.callBack}
	//             >
	//               {this.getTitle(button)}
	//             </Button>
	//           </Col>
	//         );
	//       })}
	//     </Row>
	//   );
	// };
	// renderPaymentButton = () => {
	//   return (
	//     <Row gutter={[16, 16]}>
	//       {this.PAYMENT_BUTTON.map((button) => {
	//         return (
	//           <Col key={button.key} sm={24} md={24} lg={12}>
	//             <Button
	//               icon={button.icon}
	//               size="large"
	//               style={styles.maxWidth}
	//               onClick={button.callBack}
	//               type={this.props.paymentType === button.type ? "primary" : "default"}
	//             >
	//               {button.title}
	//             </Button>
	//           </Col>
	//         );
	//       })}
	//     </Row>
	//   );
	// };

	renderFooterButton = () => {
		return (
			<Row style={styles.footerContainer} gutter={[16, 16]}>
				<Col span={12}>
					<Button
						onClick={() => this.onPrint()}
						type="primary"
						size="large"
						style={styles.maxWidth}
					>
						{I18n.t(_t(translations.checkout.reprintBill))}
					</Button>
				</Col>
				<Col span={12}>
					<Button
						onClick={this.onRefund}
						size="large"
						style={styles.maxWidth}
						disabled={
							_.isEmpty(this.props.localRefundDetails) ||
							this.props.localRefundDetails?.some((element) => element.id)
						}
						loading={this.state.isAddRefundLoading}
					>
						{I18n.t(_t(translations.billing.refund))}
					</Button>
				</Col>
			</Row>
		);
	};
	// getTitle = (item: any) => {
	//   let isChange = false;
	//   if (item.id === ModalType.DISCOUNT) {
	//     isChange = this.props.discountPercent !== 0 || !_.isEmpty(this.props.discountPercent);
	//   }
	//   if (item.id === ModalType.TIP) {
	//     if (this.props.tip?.totalTip) {
	//       isChange = this.props.tip?.totalTip !== 0;
	//     } else {
	//       isChange = _.sumBy(this.props.tip?.tips, (tip) => tip.tipAmount) !== 0;
	//     }
	//   }
	//   return isChange ? item.changeTitle : item.title;
	// };

	onPrint = async () => {
		if (this.props.onPrint) {
			this.props.onPrint();
		}
	};

	onRefund = () => {
		this.setState({
			isShowConfirmModal: true,
		});
	};

	onConfirmRefund = (
		refundSharingType: DiscounterType,
		description: string,
		employeeCode: string
	) => {
		const request: IAddRefund = {
			date: BookingHelper.convertDateRequest(momentTz().startOf('date')),
			billId: this.props.bill.id,
			customerId: this.props.bill.customerId,
			refundSharingType: refundSharingType,
			paymentType: PaymentType.CASH,
			description: description,
			employeeCode: employeeCode,
			cardCharge: 0,
			billDetails: this.props.localRefundDetails,
			branchId: this.props.branchId
		};
		this.createRefund(request);
	};

	getData = async () => {
		try {
			const customer = await this.getCustomerById(this.props.bill.customerId);
			if (customer) {
				this.setState({
					isLoading: false,
					customer: customer,
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	getCustomerById = async (id: string): Promise<ICustomer | undefined> => {
		try {
			const result = (await CustomerApiService.getCustomerById(
				id
			)) as IApiResponse<ICustomer>;
			if (result.succeeded) {
				return result.data;
			} else {
				AlertHelper.showError(result as IErrorResponse);
				return undefined;
			}
		} catch (error) {
			console.error(error);
		}
	};

	getTransactionById = async (
		id: string
	): Promise<ITransaction | undefined> => {
		try {
			const result = (await TransactionApiService.getTransactionById(
				id
			)) as IApiResponse<ITransaction>;
			if (result.succeeded) {
				return result.data;
			} else {
				AlertHelper.showError(result as IErrorResponse);
				return undefined;
			}
		} catch (error) {
			console.error(error);
		}
	};

	createRefund = async (refund: IAddRefund) => {
		this.setState({ isAddRefundLoading: true, isShowConfirmModal: false });
		try {
			const result = (await RefundApiSerivce.createRefund(
				refund
			)) as IApiResponse<IRefund>;
			if (result.succeeded) {
				this.setState(
					{
						isAddRefundLoading: false,
						// isShowAddRefundModal: false,
					},
					() => {
						this.props.onCreateRefundSuccess &&
							this.props.onCreateRefundSuccess();
					}
				);
				AlertHelper.showSuccess(
					I18n.t(_t(translations.billing.createRefundSuccess))
				);
			} else {
				this.setState({ isAddRefundLoading: false });
				AlertHelper.showError(result as IErrorResponse);
			}
		} catch (error) {
			console.error(error);
		}
	};
}

const mapStateToProps = (state: RootState) => ({
	settingReducer: state.SettingReducer,
	localRefundDetails: state.BillingReducer.localRefundDetails,
	branchId: state.BranchReducer.currentBranch!.id,
});

export default connect(mapStateToProps)(Payment);

const styles = {
	colContainer: {
		backgroundColor: 'white',
		padding: 16,
		overflow: 'hidden',
		height: 'calc(100vh - 64px)',
	} as React.CSSProperties,
	maxWidth: { width: '100%' },
	footerContainer: {
		width: '100%',
		paddingRight: 16,
		bottom: 16,
		position: 'absolute',
	} as React.CSSProperties,
};
