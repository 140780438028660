import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { BookingActions, CheckoutActions } from '../actions';
import _ from 'lodash';
import { ITip } from 'models/ITip';
import { IGiftCard, IBooking, IBookingDetail } from 'models';
import { IBilling } from 'models/IBilling';
import {
	DiscounterType,
	DiscountType,
	PaymentType,
	TipType,
} from 'utils/Consts';
import { IPaymentType } from 'models/ITransaction';
import { IPromotion } from 'models/IPromotion';
import { priceFixed } from 'helpers/currencyHelper';
import configureStore from 'redux/configuration/configureStore';
import { IBranch } from 'models/IBranch';
export interface ICheckoutReducer {
	tip?: ITip;
	tipType: TipType;
	discount?: number;
	discountPercent?: number;
	discounterType?: DiscounterType;
	discountType?: DiscountType;
	listGiftCard: Partial<IGiftCard>[];
	listBookingCombine: IBooking[];
	listBilling: Partial<IBilling>[];
	listPaymentType: Partial<IPaymentType>[];
	promotion?: Partial<IPromotion>;
	billId?: string;
}

const initialState: ICheckoutReducer = {
	tip: {},
	tipType: TipType.AUTO,
	listGiftCard: [],
	listBookingCombine: [],
	listBilling: [],
	listPaymentType: [],
};

const cardCharge = (amount: number, branch: IBranch) => {
	const cardCharge =
		(branch.cardChargeFlatRate || 0) +
		((branch.cardChargePercent || 0) / 100) * amount;
	return priceFixed(cardCharge);
};

function addTipLocal(
	state: ICheckoutReducer,
	action: PayloadAction<{
		tip: ITip;
		tipValue: number;
		branch: IBranch;
		isChangePayment: boolean;
	}>
) {
	state.tip = action.payload.tip;
	if (!action.payload.isChangePayment) {
		return;
	}
	let newPayments = _.clone(state.listPaymentType);
	if (newPayments) {
		const cashIndex = _.findIndex(
			newPayments,
			(x) => x.paymentType === PaymentType.CASH
		);
		const creditIndex = _.findIndex(
			newPayments,
			(x) => x.paymentType === PaymentType.CREDIT_CARD
		);
		const appIndex = _.findIndex(
			newPayments,
			(x) => x.paymentType === PaymentType.APP
		);
		if (creditIndex > -1) {
			const oldCardCharge = newPayments[creditIndex].cardCharge || 0;
			const newAmount =
				(newPayments[creditIndex].amount || 0) -
				oldCardCharge +
				action.payload.tipValue;

			newPayments[creditIndex].amount = priceFixed(
				newAmount + cardCharge(newAmount, action.payload.branch)
			);
			newPayments[creditIndex].cardCharge = cardCharge(
				newAmount,
				action.payload.branch
			);
			return;
		}
		if (appIndex > -1) {
			newPayments[appIndex].amount = priceFixed(
				(newPayments[appIndex].amount || 0) + action.payload.tipValue
			);
			return;
		}
		if (cashIndex > -1) {
			newPayments[cashIndex].amount = priceFixed(
				(newPayments[cashIndex].amount || 0) + action.payload.tipValue
			);
			return;
		}
	}
	state.listPaymentType = newPayments;
}

function deleteTipLocal(
	state: ICheckoutReducer,
	action: PayloadAction<{
		tip: ITip;
		tipValue: number;
		branch: IBranch;
		amountNeedToPay: number;
	}>
) {
	state.tip = action.payload.tip;
	let newPayments = _.clone(state.listPaymentType);
	if (newPayments && action.payload.amountNeedToPay <= 0) {
		const cashIndex = _.findIndex(
			newPayments,
			(x) => x.paymentType === PaymentType.CASH
		);
		const creditIndex = _.findIndex(
			newPayments,
			(x) => x.paymentType === PaymentType.CREDIT_CARD
		);
		const appIndex = _.findIndex(
			newPayments,
			(x) => x.paymentType === PaymentType.APP
		);
		if (creditIndex > -1) {
			const oldCardCharge = newPayments[creditIndex].cardCharge || 0;
			const newAmount =
				(newPayments[creditIndex].amount || 0) -
				oldCardCharge -
				action.payload.tipValue;

			newPayments[creditIndex].amount = priceFixed(
				newAmount + cardCharge(newAmount, action.payload.branch)
			);
			newPayments[creditIndex].cardCharge = cardCharge(
				newAmount,
				action.payload.branch
			);
			return;
		}
		if (appIndex > -1) {
			newPayments[appIndex].amount = priceFixed(
				(newPayments[appIndex].amount || 0) - action.payload.tipValue
			);
			return;
		}
		if (cashIndex > -1) {
			newPayments[cashIndex].amount = priceFixed(
				(newPayments[cashIndex].amount || 0) - action.payload.tipValue
			);
			return;
		}
	}
	state.listPaymentType = newPayments;
}
function addTipTypeLocal(
	state: ICheckoutReducer,
	action: PayloadAction<TipType>
) {
	state.tipType = action.payload;
}
function addDiscount(
	state: ICheckoutReducer,
	action: PayloadAction<{
		discount: number;
		discountPercent: number;
		discounterType: DiscounterType;
		discountType: DiscountType;
	}>
) {
	state.discount = action.payload.discount;
	state.discountPercent = action.payload.discountPercent;
	state.discounterType = action.payload.discounterType;
	state.discountType = action.payload.discountType;
}

function reset(state: ICheckoutReducer) {
	state.tip = undefined;
	state.discount = undefined;
	state.discountPercent = undefined;
	state.listGiftCard = [];
	state.listBookingCombine = [];
	state.listBilling = [];
	state.listPaymentType = [];
}

const addGiftCardSuccess = (
	state: ICheckoutReducer,
	action: PayloadAction<Partial<IGiftCard>>
) => {
	const list = Array.from(state.listGiftCard);
	_.remove(list, (x) => x.id === action.payload.id);
	state.listGiftCard = [...list, action.payload];
};
const deleteGiftCardSuccess = (
	state: ICheckoutReducer,
	action: PayloadAction<string>
) => {
	state.listGiftCard = _.filter(
		state.listGiftCard,
		(e) => e.id !== action.payload
	);
};
const addPaymentType = (
	state: ICheckoutReducer,
	action: PayloadAction<Partial<IPaymentType>>
) => {
	let list = Array.from(state.listPaymentType);
	if (
		action.payload.paymentType === PaymentType.GIFT_CARD &&
		!list.map((x) => x.giftCode).includes(action.payload.giftCode)
	) {
		list.push(action.payload!);
	} else {
		list = state.listPaymentType.filter(
			(x) => x.paymentType !== action.payload.paymentType
		);
		list.push(action.payload!);
	}
	state.listPaymentType = list;
};
const deletePaymentType = (
	state: ICheckoutReducer,
	action: PayloadAction<{
		paymentType: number;
		giftCode?: string;
	}>
) => {
	let list = Array.from(state.listPaymentType);

	if (
		list.map((x) => x.paymentType).includes(action.payload.paymentType) &&
		_.isEmpty(action.payload.giftCode)
	) {
		list = state.listPaymentType.filter(
			(x) => x.paymentType !== action.payload.paymentType
		);
	} else {
		list = state.listPaymentType.filter(
			(x) => x.giftCode !== action.payload.giftCode
		);
	}
	state.listPaymentType = list;
};
const removeAllPaymentType = (state: ICheckoutReducer) => {
	state.listPaymentType = [];
};
const removeDiscount = (
	state: ICheckoutReducer,
	action: PayloadAction<any>
) => {
	state.discount = undefined;
	state.discountPercent = undefined;
};
const deleteExtraAmount = (
	state: ICheckoutReducer,
	action: PayloadAction<{
		billIndex: number;
		bookingItemIndex: number;
	}>
) => {
	state.discount = undefined;
	state.discountPercent = undefined;
	//update to list booking
	const listBooking = state.listBookingCombine;
	let bookingDetail =
		listBooking[action.payload.billIndex].bookingDetails[
			action.payload.bookingItemIndex
		];
	bookingDetail.extraAmount = 0;
	bookingDetail.note = '';
	listBooking[action.payload.billIndex].bookingDetails[
		action.payload.bookingItemIndex
	] = bookingDetail;
	state.listBookingCombine = listBooking;
	//update to list billing
	let listBill = Array.from(state.listBilling);
	let billDetail =
		listBill[action.payload.billIndex].billDetails![
			action.payload.bookingItemIndex
		];
	billDetail.extraAmount = 0;
	billDetail.note = '';
	listBill[action.payload.billIndex].billDetails![
		action.payload.bookingItemIndex
	] = billDetail;
	state.listBilling = listBill;
};

const addExtraAmount = (
	state: ICheckoutReducer,
	action: PayloadAction<{
		billIndex: number;
		bookingItemIndex: number;
		amount: number;
		note: string;
	}>
) => {
	state.discount = undefined;
	state.discountPercent = undefined;
	//update to list booking
	const listBooking = state.listBookingCombine;
	let bookingDetail =
		listBooking[action.payload.billIndex].bookingDetails[
			action.payload.bookingItemIndex
		];
	bookingDetail.extraAmount = action.payload.amount;
	bookingDetail.note = action.payload.note;
	listBooking[action.payload.billIndex].bookingDetails[
		action.payload.bookingItemIndex
	] = bookingDetail;

	state.listBookingCombine = listBooking;

	//update to list billing
	let listBill = Array.from(state.listBilling);
	let billDetail =
		listBill[action.payload.billIndex].billDetails![
			action.payload.bookingItemIndex
		];
	billDetail.extraAmount = action.payload.amount;
	billDetail.note = action.payload.note;
	listBill[action.payload.billIndex].billDetails![
		action.payload.bookingItemIndex
	] = billDetail;
	state.listBilling = listBill;
};

const addBookingCombine = (
	state: ICheckoutReducer,
	action: PayloadAction<IBooking>
) => {
	let booking = Array.from(state.listBookingCombine!);
	booking.push(action.payload);
	state.listBookingCombine = booking;
	state.discount = undefined;
	state.discountPercent = undefined;
};

const updateBookingToListCombine = (
	state: ICheckoutReducer,
	action: PayloadAction<IBooking>
) => {
	const index = _.findIndex(
		state.listBookingCombine,
		(x) => x.id === action.payload.id
	);
	const listBooking = Array.from(state.listBookingCombine);
	listBooking.splice(index, 1, action.payload);
	state.listBookingCombine = listBooking;
};

const deleteBookingCombine = (
	state: ICheckoutReducer,
	action: PayloadAction<string>
) => {
	_.remove(
		state.listBookingCombine,
		(booking) => booking.id === action.payload
	);

	state.listBookingCombine = Array.from(state.listBookingCombine).filter(
		(x) => x.id !== action.payload
	);
	state.listBilling = Array.from(state.listBilling).filter(
		(x) => x.bookingId !== action.payload
	);
	state.discount = undefined;
	state.discountPercent = undefined;
};

const createBillSuccess = (
	state: ICheckoutReducer,
	action: PayloadAction<Partial<IBilling>>
) => {
	let billing = Array.from(state.listBilling!);
	billing.push(action.payload);

	state.listBilling = billing;
};

const applyPromotion = (
	state: ICheckoutReducer,
	action: PayloadAction<Partial<IPromotion> | undefined>
) => {
	state.promotion = action.payload;
};

const updatePromotionDiscount = (
	state: ICheckoutReducer,
	action: PayloadAction<{ id: string; promotionDiscount: number }[]>
) => {
	let newListBookingCombine = Array.from(state.listBookingCombine);
	newListBookingCombine.forEach((x) => {
		x.bookingDetails.forEach((y) => {
			action.payload.forEach((z) => {
				if (z.id === y.id) {
					y.promotionDiscount = z.promotionDiscount;
				}
			});
		});
	});
	state.listBookingCombine = newListBookingCombine;
};

const setBillId = (state: ICheckoutReducer, action: PayloadAction<string>) => {
	state.billId = action.payload;
};

const setTipIds = (
	state: ICheckoutReducer,
	action: PayloadAction<{ employeeId: string; id: string }[]>
) => {
	let newTip = Object.assign({}, state.tip);
	if (newTip.tips) {
		newTip.tips = newTip.tips?.map((x) => {
			const id = _.find(
				action.payload,
				(y) => y.employeeId === x.employeeId
			)?.id;
			return {
				...x,
				id: id || '',
			};
		});
	} else {
		newTip.tips = action.payload.map((x) => {
			return {
				id: x.id,
				employeeId: x.employeeId,
				tipAmount: 0,
			};
		});
	}
	state.tip = newTip;
};

const BranchReducer = createReducer(initialState, (builder) =>
	builder
		.addCase(CheckoutActions.addTipLocal.request, addTipLocal)
		.addCase(CheckoutActions.deleteTipLocal.request, deleteTipLocal)
		.addCase(CheckoutActions.addDiscount.request, addDiscount)
		.addCase(CheckoutActions.addToListGiftCard.request, addGiftCardSuccess)
		.addCase(CheckoutActions.deleteGiftCard.success, deleteGiftCardSuccess)
		.addCase(CheckoutActions.resetAll.request, reset)
		.addCase(BookingActions.addExtraAmount.request, removeDiscount)
		.addCase(CheckoutActions.addPaymentType.request, addPaymentType)
		.addCase(CheckoutActions.deletePaymentType.request, deletePaymentType)
		.addCase(CheckoutActions.removeAllPaymentType.request, removeAllPaymentType)
		.addCase(CheckoutActions.addBookingCombine.request, addBookingCombine)
		.addCase(CheckoutActions.createBill.success, createBillSuccess)
		.addCase(CheckoutActions.deleteBookingCombine.request, deleteBookingCombine)
		.addCase(CheckoutActions.addExtraAmount.request, addExtraAmount)
		.addCase(CheckoutActions.addTipTypeLocal.request, addTipTypeLocal)
		.addCase(CheckoutActions.deleteExtraAmount.request, deleteExtraAmount)
		.addCase(
			CheckoutActions.updateBookingToListCombine.request,
			updateBookingToListCombine
		)
		.addCase(CheckoutActions.applyPromotion.request, applyPromotion)
		.addCase(
			CheckoutActions.updatePromotionDiscount.request,
			updatePromotionDiscount
		)
		.addCase(CheckoutActions.setBillId.request, setBillId)
		.addCase(CheckoutActions.setTipIds.request, setTipIds)
);

export default BranchReducer;
