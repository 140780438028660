import React, { useState } from 'react';
import { Modal, Typography, Button, Row, Col, ModalProps } from 'antd';
import { I18n, _t, translations, Colors } from 'utils';
import { RootState } from 'redux/configuration/rootReducer';
import { connect, useSelector } from 'react-redux';
import GlobalStyles, { kStyles } from 'GlobalStyles';
import { IPaymentMethod } from 'models';
import AddOtherReferenceModal from './AddOtherReferenceModal';
import { GenerateHelper } from 'helpers';

interface IOtherPaymentModalProps
	extends ModalProps,
		ReturnType<typeof mapStateToProps> {
	onCancel: () => void;
	onSelectPaymentMethod: (
		paymentMethod: IPaymentMethod,
		reference: string
	) => void;
	selectedPaymentMethodId?: string;
}

const OtherPaymentModal = (props: IOtherPaymentModalProps) => {
	const [isShowReferenceModal, setIsShowReferenceModal] =
		useState<boolean>(false);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
		IPaymentMethod | undefined
	>(
		props.paymentMethods.find(
			(element) => props.selectedPaymentMethodId === element.id
		)
	);
	const paymentReferenceCodeRequiring = useSelector(
		(state: RootState) =>
			state.BranchReducer.currentBranch?.paymentReferenceCodeRequiring
	);
	const renderContent = () => {
		return (
			<Row style={styles.contentContainer} gutter={[16, 16]}>
				{props.paymentMethods.map((paymentMethod) => {
					return (
						<Col
							key={paymentMethod.id}
							onClick={() => {
								if (paymentReferenceCodeRequiring) {
									setIsShowReferenceModal(true);
									setSelectedPaymentMethod(paymentMethod);
								} else {
									setSelectedPaymentMethod(paymentMethod);
									props.onSelectPaymentMethod(
										paymentMethod!,
										GenerateHelper.generateFourDigitsNumber().toString()
									);
								}
							}}
							style={{
								...styles.contentCol,
								backgroundColor:
									props.selectedPaymentMethodId === paymentMethod.id
										? Colors.PRIMARY_COLOR
										: 'white',
							}}
						>
							<Typography.Text
								style={{
									color:
										props.selectedPaymentMethodId === paymentMethod.id
											? 'white'
											: 'black',
									fontWeight: 600,
								}}
								ellipsis={true}
							>
								{paymentMethod.name}
							</Typography.Text>
						</Col>
					);
				})}
			</Row>
		);
	};

	const onReferenceEntered = (reference: string) => {
		props.onSelectPaymentMethod(selectedPaymentMethod!, reference);
	};

	return (
		<Modal
			visible={true}
			title={
				<Typography.Title
					level={3}
					style={{ textAlign: 'center', margin: 0, color: '#fff' }}
				>
					{I18n.t(_t(translations.checkout.otherPaymentMethods))}
				</Typography.Title>
			}
			width={700}
			footer={
				<Button onClick={() => props.onCancel()}>
					{I18n.t(_t(translations.cancel))}
				</Button>
			}
			{...props}
		>
			{renderContent()}
			{isShowReferenceModal && (
				<AddOtherReferenceModal
					selectedPaymentMethod={selectedPaymentMethod!}
					onCancel={() => setIsShowReferenceModal(false)}
					onOk={onReferenceEntered}
				/>
			)}
		</Modal>
	);
};

const styles: kStyles = {
	contentContainer: { height: 300, overflowY: 'auto', overflowX: 'hidden' },
	contentCol: {
		...GlobalStyles.boxShadow,
		borderColor: Colors.BLACK,
		borderWidth: 0.5,
		borderStyle: 'solid',
		cursor: 'pointer',
		borderRadius: 4,
		textAlign: 'center',
		maxHeight: 140,
		// height: 'fit-content',
		marginRight: '1%',
		width: '24%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
};

const mapStateToProps = (state: RootState) => ({
	paymentMethods: state.PaymentMethodReducer.paymentMethods,
});

export default connect(mapStateToProps)(OtherPaymentModal);
