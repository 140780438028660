import * as ReduxHelper from './reduxHelper';
import { electron } from 'ipc';
import * as ValidationHelper from './validationHelper';
import * as StringHelper from './StringHelper';
import * as CalendarHelper from './calendarHelper';
import * as TimeHelper from './timeHelper';
import * as AlertHelper from './alertHelper';
import * as CurrencyHelper from './currencyHelper';
import FormHelper from './formHelper';
import _ from 'lodash';
export * as DownloadHelper from './downloadHelper';
export * as ColorHelper from './colorHelper';
export * as BookingHelper from './bookingHelper';
export * as GenerateHelper from './generateHelper';

const isPos = () => {
	return !_.isEmpty(electron);
};
const checkIsMobile = () => {
	return window.innerWidth <= 768;
};
export {
	ReduxHelper,
	ValidationHelper,
	StringHelper,
	CalendarHelper,
	FormHelper,
	TimeHelper,
	AlertHelper,
	CurrencyHelper,
	isPos,
	checkIsMobile,
};
