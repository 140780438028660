import React from 'react';
import { Link } from 'react-router-dom';
import H from 'history';
import { Menu, Typography } from 'antd';
import { HomeRoute } from 'routers/routes';
import _ from 'lodash';
import SubMenu from 'antd/lib/menu/SubMenu';
import { MenuOutlined } from '@ant-design/icons';
import { EEmployeeTypeCode } from 'models';
interface IMenuTabProps {
	location: H.Location;
	roles?: string[];
	role?: EEmployeeTypeCode;
}

export default class MenuTab extends React.Component<IMenuTabProps> {
	render() {
		return (
			<Menu
				theme="dark"
				defaultSelectedKeys={[`${this.props.location.pathname}`]}
				mode="horizontal"
				style={{
					width: '100%',
					position: 'fixed',
					bottom: 0,
					left: 0,
					zIndex: 99,
					height: 80,
					// background: 'linear-gradient(to right, #323dad, #2d73d2)',
					textAlign: 'center',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				{HomeRoute.filter((r) => r.mainMobile === true).map((route) => {
					if (
						!_.isEmpty(route.roles) &&
						_.isEmpty(_.intersection(route.roles, this.props.roles)) &&
						!_.includes(route.roles, this.props.role)
					) {
						return null;
					}
					if (route.mainMobile) {
						return (
							<Menu.Item
								style={styles.item}
								key={route.path}
								// icon={route.icon}
							>
								<div
									style={{
										fontSize: 30,
										textAlign: 'center',
										marginBottom: -15,
									}}
								>
									{route.icon}
								</div>
								<strong>{route.name}</strong>
								{/* <strong>{route.name}</strong> */}
								<Link to={route.path!} />
							</Menu.Item>
						);
					}
				})}
			</Menu>
		);
	}
}

const styles = {
	item: {
		height: '100%',
		textAlign: 'center',
		padding: 0,
		paddingTop: 7.5,
		paddingLeft: 16,
		paddingRight: 16,
		width: 200,
		display: 'grid',
		'&:hover': {
			backgroundColor: 'red',
		},
	} as React.CSSProperties,
};
