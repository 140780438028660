import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";
import H from "history";
import { Layout, Menu } from "antd";
import { HomeRoute } from "routers/routes";
import _ from "lodash";
import SubMenu from "antd/lib/menu/SubMenu";
const { Sider } = Layout;
interface IMenuSiderProps {
  collapsed: boolean;
  onCollapse?: (collapsed: boolean) => void;
  location: H.Location;
  roles?: string[];
}

export default class MenuSider extends React.Component<IMenuSiderProps> {
  render() {
    return (
      <Sider style={siderStyle} collapsible collapsed={this.props.collapsed} onCollapse={this.props.onCollapse}>
        <div style={logoStyle} />
        <Menu theme="dark" defaultSelectedKeys={[`${this.props.location.pathname}`]} mode="inline">
          {HomeRoute.filter((r) => r.showInMenu).map((route) => {
            if (!_.isEmpty(route.roles) && _.isEmpty(_.intersection(route.roles, this.props.roles))) {
              return null;
            }
            if (!_.isEmpty(route.subMenu)) {
              return (
                <SubMenu key={route.key} icon={route.icon} title={route.name}>
                  {route.subMenu?.map((subRoute) => (
                    <Menu.Item key={subRoute.path} icon={subRoute.icon}>
                      <span>{subRoute.name}</span>
                      <Link to={subRoute.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              );
            }
            return (
              <Menu.Item key={route.path} icon={route.icon}>
                <span>{route.name}</span>
                <Link to={route.path!} />
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
    );
  }
}

const logoStyle = {
  height: "32px",
  margin: "16px",
  background: "rgba(255, 255, 255, 0.3)",
};
const siderStyle: CSSProperties = {
  // position: 'fixed',
  // height: '100vh'
  overflow: "auto",
};
