import React, { useEffect, useRef, useState } from 'react';
import { Menu, Space, Spin, Typography, Input } from 'antd';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { I18n, _t, translations } from 'utils';
import styles from './styles';
import { RootState } from 'redux/configuration/rootReducer';
import { AppConfigActions, AuthenticationActions } from 'redux/actions';
import { Link, useHistory } from 'react-router-dom';
import { electron } from 'ipc';
import ModalHelper from 'helpers/modalHelper';
import _, { isEmpty } from 'lodash';
import { userInfo } from 'os';
import { ILoginRequest } from 'models/RequestModels';
import { GenerateHelper } from 'helpers';
import { showError } from 'helpers/alertHelper';
import './styles.css';
interface IProfileMenuProps
	extends ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps> {}

const ProfileMenu = (props: IProfileMenuProps) => {
	let history = useHistory();
	const [showSwitchAccountModal, setShowSwitchAccountModal] = useState(false);
	const [password, setPassword] = useState<string>('');

	useEffect(() => {
		if (props.authenticateAction?.includes('AUTHENTICATE_FAILED')) {
			showError(props.appError!);
		}
	}, [props.authenticateAction]);

	const setLanguage = (language: string) => {
		props.setLanguage(language);
		I18n.locale = language;
		history.push('/temp');
		history.goBack();
	};

	const onSwitchAccount = () => {
		setShowSwitchAccountModal(false);
		setPassword('');
		props.login({
			userNameOrEmail: props.adminAccount!,
			password: password!,
			rememberMe: true,
			deviceId: props.deviceId || GenerateHelper.uuid(),
		});
	};

	const onShowSwitchAccountModal = () => {
		setShowSwitchAccountModal(true);
	};

	const onModalCancel = () => {
		setShowSwitchAccountModal(false);
		setPassword('');
	};

	return (
		<Spin
			spinning={
				props.authenticateAction?.includes('AUTHENTICATE_REQUEST')
					? true
					: false
			}
		>
			<Menu style={styles.menu}>
				<Menu.Item key={'menu_user'} style={{ fontWeight: 'bold' }}>
					{`${props.user?.firstName} ${props.user?.lastName}`}
				</Menu.Item>
				<Menu.ItemGroup title={I18n.t(_t(translations.profileMenu.settings))}>
					{/* <Menu.Item key={"menu_noti_setting"}>{I18n.t(_t(translations.profileMenu.notiSetting))}</Menu.Item> */}
					{/* <Menu.Item key={"menu_contact"}>{I18n.t(_t(translations.profileMenu.contactSupport))}</Menu.Item> */}
					<Menu.Item key={'menu_help'}>
						<Link to={'help-center'}>
							{I18n.t(_t(translations.profileMenu.helpCenter))}
						</Link>
					</Menu.Item>
					<Menu.SubMenu
						key="language"
						title={I18n.t(_t(translations.profileMenu.language))}
					>
						<Menu.Item
							style={{
								backgroundColor: props.language === 'en' ? 'black' : 'white',
								color: props.language === 'en' ? 'white' : 'black',
							}}
							onClick={() => {
								setLanguage('en');
							}}
							key="English"
						>
							EN
						</Menu.Item>
						<Menu.Item
							style={{
								backgroundColor: props.language === 'vi' ? 'black' : 'white',
								color: props.language === 'vi' ? 'white' : 'black',
							}}
							key="VietNam"
							onClick={() => {
								setLanguage('vi');
							}}
						>
							VI
						</Menu.Item>
					</Menu.SubMenu>

					<Menu.Item
						onClick={onShowSwitchAccountModal}
						key={'menu_switch_account'}
						disabled={
							_.isEmpty(props.adminAccount) ||
							props.adminAccount === props.user?.userName
						}
					>
						{I18n.t(_t(translations.profileMenu.switchAccount))}
					</Menu.Item>
					<Menu.Item
						style={{ fontWeight: 'bold' }}
						onClick={() => {
							props.switchV2();
							window.location.replace(
								`https://${props.shop!.adminUrl}.v2.smartsalon.live?switchBack`
							);
						}}
						key={'menu_switch_v2'}
					>
						{I18n.t(_t(translations.profileMenu.switchV2))}
					</Menu.Item>

					<Menu.Item onClick={() => props.logout()} key={'menu_logout'}>
						{I18n.t(_t(translations.profileMenu.logout))}
					</Menu.Item>
					{electron ? (
						<Menu.Item
							onClick={() => electron.ipcRenderer.minimize()}
							key={'menu_minimize'}
						>
							{I18n.t(_t(translations.profileMenu.minimize))}
						</Menu.Item>
					) : null}
					{electron ? (
						<Menu.Item
							danger
							onClick={() => electron.ipcRenderer.quit()}
							key={'menu_quit'}
						>
							{I18n.t(_t(translations.profileMenu.quit))}
						</Menu.Item>
					) : null}
				</Menu.ItemGroup>
			</Menu>
			<ModalHelper
				visible={showSwitchAccountModal}
				title={'Switch Account'}
				onOk={onSwitchAccount}
				onCancel={onModalCancel}
				disableOk={_.isEmpty(password) || isEmpty(props.adminAccount)}
				OKString={'Switch'}
				// footer={this.renderModalFooter()}
			>
				{/* <Space size="small" direction="vertical" style={styles.maxWidth}> */}
				<Typography.Title level={4}>
					User name: {props.adminAccount}
				</Typography.Title>
				<Typography.Title level={4}>Password</Typography.Title>

				<Input
					placeholder="Input your password"
					value={password}
					onChange={(e) => {
						setPassword(e.target.value);
					}}
					className="inputPass"
					// type={'password'}
					// autoComplete={'new-password'}
					// autoComplete={'off'}
					autoFocus
					// readOnly
					// onFocus={(event) => {
					// 	event.target.removeAttribute('readonly');
					// }}
					// autoComplete={'nope'}
				/>
				{/* </Space> */}
			</ModalHelper>
		</Spin>
	);
};

const mapStateToProps = (state: RootState) => ({
	isLoading: state.AppConfigReducer.showLoading,
	language: state.AppConfigReducer.language,
	user: state.UserReducer.user,
	error: state.UserReducer.error,
	appError: state.ErrorReducer.error,
	adminAccount: state.AuthenticationReducer.adminAccount,
	deviceId: state.AuthenticationReducer.deviceId,
	shop: state.ShopReducer.shop,
	authenticateAction: state.ReduxActionReducer['AUTHENTICATION'],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	logout: () => {
		dispatch(AuthenticationActions.logOut.request());
	},
	setLanguage: (language: string) => {
		dispatch(AppConfigActions.chooseLanguage.request(language));
	},
	login: (data: ILoginRequest) => {
		dispatch(AuthenticationActions.authenticate.request(data));
	},
	switchV2: () => {
		dispatch(AppConfigActions.useV2.request(true));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
