import React, { Component } from "react";
import {
  ContentAdminContainer,
  IComponentProps,
} from "components";
import ReactToPrint from "react-to-print";
import { MainView } from "./components";

interface IPayrollDetailReportProps extends IComponentProps {}
interface IPayrollDetailReportStates {
}

// enum PrintType {
//   ONE,
//   ALL,
// }

export class PayrollDetailReport extends Component<
  IPayrollDetailReportProps,
  IPayrollDetailReportStates
> {

  printer?: ReactToPrint | null;

  render() {
    return (
      <ContentAdminContainer>
        <MainView />
      </ContentAdminContainer>
    );
  }
}

export default PayrollDetailReport;
