import { Col, Divider, Row, Typography } from 'antd';
import { IPayrollDetail, IPayrollOverview } from 'models';
import React, { Component } from 'react';
import { Colors, I18n, translations, _t } from 'utils';
import { CurrencyHelper } from 'helpers';
import { kStyles } from 'GlobalStyles';
const { Title, Text } = Typography;

interface IFeeAndPaymentProps {
	payrollData: IPayrollDetail | IPayrollOverview;
}

interface IFeeAndPaymentState {}

class FeeAndPayment extends Component<
	IFeeAndPaymentProps,
	IFeeAndPaymentState
> {
	render() {
		return (
			<>
				{/* <Row>
          <Col span={12}>
            <Title level={5}>
              {I18n.t(_t(translations.payrollDetailReport.supplyFee))}
            </Title>
          </Col>
          <Col span={12} style={styles.alignLeft}>
            <Text>
              {CurrencyHelper.formatPrice(
                this.props.payrollData?.totalSupplyFee
              )}
            </Text>
          </Col>
        </Row> */}
				<Row>
					<Col span={12}>
						<Title level={5}>
							{I18n.t(_t(translations.payrollDetailReport.paymentTypes))}
						</Title>
					</Col>
					<Col span={12}></Col>
				</Row>
				<Row>
					<Col span={12}>
						<Text>
							{I18n.t(_t(translations.payrollDetailReport.totalCash))}
						</Text>
					</Col>
					<Col span={12} style={styles.alignLeft}>
						<Text>
							{CurrencyHelper.formatPrice(
								this.props.payrollData?.billPaymentTypes?.cash
							)}
						</Text>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<Text>
							{I18n.t(_t(translations.payrollDetailReport.totalCreditCard))}
						</Text>
					</Col>
					<Col span={12} style={styles.alignLeft}>
						<Text>
							{CurrencyHelper.formatPrice(
								this.props.payrollData?.billPaymentTypes?.card
							)}
						</Text>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<Text>
							{I18n.t(_t(translations.payrollDetailReport.totalByApp))}
						</Text>
					</Col>
					<Col span={12} style={styles.alignLeft}>
						<Text>
							{CurrencyHelper.formatPrice(
								this.props.payrollData?.billPaymentTypes?.payByApps
							)}
						</Text>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<Text>
							{I18n.t(_t(translations.payrollDetailReport.totalGiftcard))}
						</Text>
					</Col>
					<Col span={12} style={styles.alignLeft}>
						<Text>
							{CurrencyHelper.formatPrice(
								this.props.payrollData?.billPaymentTypes?.giftCard
							)}
						</Text>
					</Col>
				</Row>
				<Divider style={{ borderColor: Colors.GREY }} />
				<Row>
					<Col span={12}>
						<Title level={5}>
							{I18n.t(_t(translations.payrollDetailReport.total))}
						</Title>
					</Col>
					<Col span={12} style={styles.alignLeft}>
						<Title level={5}>
							{CurrencyHelper.formatPrice(
								this.props.payrollData?.billPaymentTypes?.total
							)}
						</Title>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<Title level={5}>
							{I18n.t(_t(translations.payrollDetailReport.totalTips))}
						</Title>
					</Col>
					<Col span={12} style={styles.alignLeft}>
						<Title level={5}>
							{CurrencyHelper.formatPrice(this.props.payrollData?.totalTip)}
						</Title>
					</Col>
				</Row>
			</>
		);
	}
}

const styles: kStyles = {
	alignLeft: {
		textAlign: 'right',
	},
};

export default FeeAndPayment;
