import { PlusOutlined } from '@ant-design/icons';
import {
	Avatar,
	Col,
	Form,
	Image,
	Input,
	Row,
	Space,
	Typography,
	Upload,
} from 'antd';
import { ImageUpload } from 'components';
import { FormHelper, TimeHelper } from 'helpers';
import formHelper from 'helpers/formHelper';
import _, { isEmpty } from 'lodash';
import { IPromotion, IPromotionType } from 'models/IPromotion';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { I18n, _t, translations } from 'utils';

const DiscountAmount = (props: {
	isAdd: boolean;
	currentPromotion?: IPromotion;
}) => {
	const [usePercentage, setUsePercentage] = useState(
		props.isAdd ? false : props.currentPromotion!.usePercentage
	);

	return (
		<Col xs={24} md={16}>
			<Row gutter={[8, 8]} justify={'space-around'}>
				{formHelper.renderFormSwitch(
					'usePercentage',
					I18n.t(_t(translations.promotionDetail.usePercentage)),
					usePercentage,
					{},
					{ onChange: () => setUsePercentage(!usePercentage) }
				)}

				{usePercentage ? (
					<>
						<Col xs={8}>
							<Form.Item
								label={
									<Typography.Title level={5}>
										{I18n.t(_t(translations.promotionDetail.discountAmount))}
									</Typography.Title>
								}
								name="discountPercentage"
								initialValue={
									props.isAdd
										? undefined
										: props.currentPromotion?.discountPercentage
								}
								rules={[
									{
										required: true,
										message: I18n.t(
											_t(
												translations.promotionDetail
													.messageRequiredDiscountPercentage
											)
										),
									},
									() => ({
										validator: (_, value) => {
											if (value <= 0) {
												return Promise.reject(
													new Error(
														I18n.t(_t(translations.validate.lessThanZero))
													)
												);
											}
											if (value > 100) {
												return Promise.reject(
													new Error(
														I18n.t(
															_t(
																translations.validate.greaterThanHundredPercent
															)
														)
													)
												);
											}
											return Promise.resolve();
										},
									}),
								]}
							>
								<Input
									type="number"
									addonAfter={'%'}
									style={{ width: '100%', textAlign: 'right' }}
									placeholder={I18n.t(
										_t(translations.promotionDetail.placeholderDiscountAmount)
									)}
								/>
							</Form.Item>
						</Col>
						<Col xs={8}>
							<Form.Item
								label={
									<Typography.Title level={5}>
										{I18n.t(
											_t(translations.promotionDetail.maximumDiscountAmount)
										)}
									</Typography.Title>
								}
								name="maximumDiscountAmount"
								initialValue={
									props.isAdd
										? undefined
										: props.currentPromotion?.maximumDiscountAmount
								}
								rules={[
									() => ({
										validator: (_, value) => {
											if (!isEmpty(value) && value <= 0) {
												return Promise.reject(
													new Error(
														I18n.t(_t(translations.validate.lessThanZero))
													)
												);
											}
											return Promise.resolve();
										},
									}),
								]}
							>
								<Input
									type="number"
									addonAfter={'$'}
									style={{ width: '100%', textAlign: 'right' }}
									placeholder={I18n.t(
										_t(
											translations.promotionDetail
												.placeholderMaximumDiscountAmount
										)
									)}
								/>
							</Form.Item>
						</Col>
					</>
				) : (
					<Col xs={8}>
						<Form.Item
							label={
								<Typography.Title level={5}>
									{I18n.t(_t(translations.promotionDetail.discountAmount))}
								</Typography.Title>
							}
							name="discountAmount"
							initialValue={
								props.isAdd ? undefined : props.currentPromotion?.discountAmount
							}
							rules={[
								{
									required: true,
									message: I18n.t(
										_t(
											translations.promotionDetail.messageRequiredDiscountAmount
										)
									),
								},
								() => ({
									validator: (_, value) => {
										if (value <= 0) {
											return Promise.reject(
												new Error(
													I18n.t(_t(translations.validate.lessThanZero))
												)
											);
										}
										return Promise.resolve();
									},
								}),
							]}
						>
							<Input
								type="number"
								addonAfter={'$'}
								style={{ width: '100%', textAlign: 'right' }}
								placeholder={I18n.t(
									_t(translations.promotionDetail.placeholderDiscountAmount)
								)}
							/>
						</Form.Item>
					</Col>
				)}
			</Row>
		</Col>
	);
};

const DateTimeForm = (props: {
	isAdd: boolean;
	currentPromotion?: IPromotion;
}) => {
	return (
		<>
			{formHelper.renderFormDateRangePicker(
				'discountTime',
				I18n.t(_t(translations.promotionDetail.discountTime)),
				props.isAdd
					? []
					: [
							TimeHelper.toTimeZone(props.currentPromotion!.startDateUtc),
							!_.isEmpty(props.currentPromotion?.endDateUtc)
								? TimeHelper.toTimeZone(props.currentPromotion!.endDateUtc)
								: null,
					  ],
				[
					{
						required: true,
						message: I18n.t(
							_t(translations.promotionDetail.messageRequiredDatePicker)
						),
					},
				],
				[],
				{ xs: 24, md: 12 },
				{
					showTime: { format: 'HH:mm:ss' },
					format: 'MM-DD-YYYY HH:mm:ss',
					style: { width: '100%' },
					allowEmpty: [false, true],
				}
			)}
		</>
	);
};

const Limitation = (props: {
	isAdd: boolean;
	currentPromotion?: IPromotion;
}) => {
	const [limitationType, setLimitationType] = useState<number>(
		props.isAdd ? 0 : props.currentPromotion!.limitationType
	);

	const Type = [
		{
			id: 0,
			name: I18n.t(_t(translations.promotionDetail.noLimits)),
		},
		{
			id: 1,
			name: I18n.t(_t(translations.promotionDetail.nTimesOnly)),
		},
		{
			id: 2,
			name: I18n.t(_t(translations.promotionDetail.nTimesPerUser)),
		},
	];

	return (
		<>
			{formHelper.renderFormSelect(
				'limitationType',
				I18n.t(_t(translations.promotionDetail.discountLimitation)),
				Type,
				'name',
				'id',
				limitationType.toString(),
				{ sm: 24, md: 12 },
				{
					onChange: (value) => {
						setLimitationType(parseInt(value));
					},
				},
				{}
			)}

			{limitationType === 0 ? (
				<> </>
			) : (
				<Col>
					<Form.Item
						label={
							<Typography.Title level={5}>
								{I18n.t(_t(translations.promotionDetail.nTimes))}
							</Typography.Title>
						}
						name="limitationTimes"
						initialValue={
							props.isAdd ? 0 : props.currentPromotion?.limitationTimes
						}
						rules={[
							{
								required: true,
								message: I18n.t(
									_t(
										translations.promotionDetail.messageRequiredLimitationTimes
									)
								),
							},
						]}
					>
						<Input type="number" style={{ width: '100%' }} min={0} />
					</Form.Item>
				</Col>
			)}
		</>
	);
};

export const Info = (props: {
	requiredCoupon: boolean;
	setRequiredCoupon: (value: boolean) => void;
	discountType: string;
	setDiscountType: (value: string) => void;
	discountTypes: IPromotionType[];
	isAdd: boolean;
	currentPromotion?: IPromotion;
	setImageFiles: (file: (File | undefined)[]) => void;
	setIsHaveInitialImageUrl: (value: boolean) => void;
	description?: string;
	setDescription: (value: string) => void;
}) => {
	const [valueContent, setValueContent] = useState<string>(
		props.currentPromotion?.description || ''
	);
	const [selectedFile, setSelectedFile] = useState<File | undefined>();
	const reactQuillRef = useRef(null) as any;
	useEffect(() => {
		props.setDescription(valueContent);
	}, [valueContent]);
	return (
		<>
			<Row gutter={[8, 0]}>
				<Col span={24}>
					<Row className="logo-title-container">
						<Col>
							<Typography.Title level={5}>Banner</Typography.Title>
							<Typography.Text className="logo-description">
								Click to upload new banner. Recommend resolution: 1920 x 1080
							</Typography.Text>
						</Col>
					</Row>
					<div className="banner-upload">
						{/* <ImageUpload
							defaultImage={props.currentPromotion?.bannerUrl}
							onGetImages={(fileList) => {
								props.setImageFiles(fileList);
								props.setIsHaveInitialImageUrl(false);
							}}
						/> */}
						<Upload
							name="logo"
							className="avatar-uploader"
							beforeUpload={(file) => {
								setSelectedFile(file);
								props.setImageFiles([file]);
							}} //Set this to false => make the Upload not request to any where after select image
							accept={'.png,.jpg,.jpeg'}
							customRequest={(options) => {}}
							showUploadList={false}
						>
							{selectedFile ? (
								<Image
									className="alt-logo"
									src={URL.createObjectURL(selectedFile!)}
									alt="logo"
									preview={false}
									width={640}
									height={360}
								/>
							) : props.currentPromotion?.bannerUrl ? (
								<Image
									src={props.currentPromotion.bannerUrl}
									preview={false}
									width={640}
									height={360}
								/>
							) : (
								<Avatar className="alt-logo" shape="square" size={200}>
									No Banner
								</Avatar>
							)}
						</Upload>
					</div>
					{FormHelper.renderFormInput(
						'name',
						[
							{
								required: true,
								message: I18n.t(
									_t(translations.promotionDetail.messageRequiredName)
								),
							},
						],
						I18n.t(_t(translations.promotionDetail.promotionName)),
						props.isAdd ? '' : props.currentPromotion?.name,
						{},
						{
							placeholder: I18n.t(
								_t(translations.promotionDetail.placeholderName)
							),
						}
					)}
					<Typography.Title level={5} style={{ padding: '8px 0 8px 0' }}>
						{I18n.t(_t(translations.description))}
					</Typography.Title>
					<ReactQuill
						ref={reactQuillRef}
						style={{ marginBottom: '24px' }}
						theme="snow"
						value={valueContent}
						onChange={setValueContent}
					/>

					<Row gutter={[12, 8]}>
						{formHelper.renderFormSelect<IPromotionType>(
							'promotionTypeId',
							I18n.t(_t(translations.promotionDetail.discountType)),
							props.discountTypes!,
							'name',
							'id',
							props.isAdd ? undefined : props.currentPromotion?.promotionTypeId,
							{ sm: 24, md: 8 },
							{ onChange: (value) => props.setDiscountType(value) },
							{
								rules: [
									{
										required: true,
										message: I18n.t(
											_t(
												translations.promotionDetail.messageRequiredDiscountType
											)
										),
									},
								],
							}
						)}
						<DiscountAmount
							isAdd={props.isAdd}
							currentPromotion={props.currentPromotion}
						/>
					</Row>
				</Col>

				<DateTimeForm
					isAdd={props.isAdd}
					currentPromotion={props.currentPromotion}
				/>

				<Col span={24}>
					<Row>
						{formHelper.renderFormSwitch(
							'requiresCouponCode',
							I18n.t(_t(translations.promotionDetail.requiresCoupon)),
							props.isAdd ? false : props.currentPromotion!.requiresCouponCode,
							{ xs: 12, md: 8 },
							{ onChange: (value) => props.setRequiredCoupon(value) }
						)}

						{formHelper.renderFormSwitch(
							'isCumulative',
							I18n.t(_t(translations.promotionDetail.cumulativeWithOther)),
							props.isAdd ? false : props.currentPromotion!.isCumulative,
							{ xs: 12, md: 8 }
						)}
					</Row>
				</Col>

				<Limitation
					isAdd={props.isAdd}
					currentPromotion={props.currentPromotion}
				/>

				<Col span={24}>
					{formHelper.renderFormSwitch(
						'isEnabled',
						I18n.t(_t(translations.active)),
						props.isAdd ? false : props.currentPromotion!.isEnabled,
						{}
					)}
				</Col>
			</Row>
		</>
	);
};
