import GlobalStyles from 'GlobalStyles';

const styles: any = {
	header: {
		backgroundColor: 'white',
		padding: 0,
		paddingLeft: '16px',
		height: 'auto',
		position: 'fixed',
		zIndex: 9999,
		width: '100%',
		...GlobalStyles.boxShadow,
	} as React.CSSProperties,
	select: {
		width: '100%',
	},
	rightCol: {
		textAlign: 'right',
		padding: '0 16px',
	},
	avatarBtn: {
		padding: 0,
		borderColor: 'white',
	},
	btnNoti: {
		container: {
			display: 'inline-block',
			textAlign: 'center',
		},
		noti: {
			backgroundColor: 'white',
			borderColor: 'white',
		},
		notiOnHover: {
			backgroundColor: '#f0f2f5',
			borderColor: 'white',
		},
	},
	btnLanguage: {
		marginRight: '0',
	},
	branch: {
		form: {
			height: '100%',
			display: 'flex',
			alignItems: 'center',
		},
		formItem: {
			flex: 1,
			margin: 0,
		},
	},
	title: {
		marginTop: '16px',
		fontWeight: 'bold',
	},
	dropdownItem: { alignItems: 'center' },
	icClose: {
		marginLeft: 10,
		cursor: 'pointer',
	} as React.CSSProperties,
	headerTitle: {
		color: 'white',
		textAlign: 'center',
		marginBottom: 0,
		justifyContent: 'center',
	} as React.CSSProperties,
};

export default styles;
