import React, { Component } from 'react';
import {
	Row,
	Col,
	Typography,
	Space,
	Rate,
	Input,
	Result,
	Card,
	Button,
	Image,
} from 'antd';
import styles from './styles';
import { Header, IComponentProps } from 'components';
import { I18n, _t, translations } from 'utils';
import { Dispatch } from 'redux';
import { BranchActions, RatingActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import _ from 'lodash';
import Layout, { Content } from 'antd/lib/layout/layout';
import { IBooking } from 'models';
import { withRouter } from 'react-router';
import { ArrowLeftOutlined, StarOutlined } from '@ant-design/icons';
import { ICreateRatingRequest } from 'models/RequestModels';
import qs from 'qs';
import { StringHelper } from 'helpers';
import * as images from './images';
import { setTimeout } from 'timers';
import { convertStringToUpperCaseFirstChar } from 'helpers/StringHelper';

const { Title } = Typography;
const { TextArea } = Input;

interface IRatingProps
	extends IComponentProps<any>,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}
interface IRatingState {
	booking: IBooking[];
	value: number;
	isShowComment: boolean;
	comment: string;
	showResultSuccess: boolean;
	showOk: boolean;
}
const customIcons = [
	{ icons: <StarOutlined /> },
	{ icons: <StarOutlined /> },
	{ icons: <StarOutlined /> },
	{ icons: <StarOutlined /> },
	{ icons: <StarOutlined /> },
];
class RatingPage extends Component<IRatingProps, IRatingState> {
	state = {
		booking: [],
		value: 0,
		isShowComment: false,
		comment: '',
		showResultSuccess: false,
		showOk: false,
	};

	componentDidMount() {
		let path = window.location.pathname;
		let str = path.split('/');
		let branchId = str[2];
		let bookingId = str[3];
		this.props.getBranchById(branchId);
	}

	componentWillUnmount() {}

	componentDidUpdate(prevProps: IRatingProps, prevState: IRatingState) {
		if (prevProps.currentAction !== this.props.currentAction) {
		}
	}
	REVIEW_PAGE = [
		{
			key: 'google',
			imgUrl: images.google,
			callBack: () => window.open(this.props.currentBranch?.googleReviewUrl),
		},
		{
			key: 'yelp',
			imgUrl: images.yelp,
			callBack: () => window.open(this.props.currentBranch?.yelpReviewlUrl),
		},
	];
	render() {
		return this.state.showResultSuccess ? (
			<Result
				status="success"
				title={
					<Title level={4}>
						{I18n.t(_t(translations.rating.ratingSuccess))}
					</Title>
				}
				subTitle={
					<Title level={5}>{I18n.t(_t(translations.rating.haveNiceDay))}</Title>
				}
				extra={
					<Space direction="vertical">
						<Row gutter={[16, 16]}>
							{this.REVIEW_PAGE.map((page) => (
								<Col span={24}>
									<Button
										key={page.key}
										style={{
											textAlign: 'center',
											paddingTop: 2,
											width: 200,
											alignItems: 'center',
											justifyContent: 'center',
										}}
										onClick={page.callBack}
									>
										<Space>
											<Title level={5}>
												{I18n.t(_t(translations.rating.vote))}
											</Title>
											<Image
												style={{ height: 40, width: 40 }}
												src={page.imgUrl}
												preview={false}
											/>
										</Space>
									</Button>
								</Col>
							))}
						</Row>
					</Space>
				}
			/>
		) : (
			<Layout style={{ minHeight: '100vh', backgroundColor: 'white' }}>
				{this.renderHeader()}
				{this.renderContent()}
			</Layout>
		);
	}
	renderHeader() {
		return (
			<Header
				detailPageProps={{
					title: I18n.t(_t(translations.rating.rating)),
					hideCloseButton: true,
				}}
			/>
		);
	}
	renderContent() {
		const { currentBranch } = this.props;
		if (!currentBranch) {
			return null;
		}
		const phone = currentBranch.phone;
		const email = currentBranch.email;

		let address = '';
		!_.isEmpty(currentBranch.address)
			? !_.isEmpty(currentBranch.address!.street) ||
			  !_.isEmpty(currentBranch.address!.city) ||
			  !_.isEmpty(currentBranch.address!.state)
				? (address = `${currentBranch.address!.street} - ${
						currentBranch.address!.city
				  }`)
				: (address = '')
			: (address = '');
		return (
			<Content style={{ marginTop: 64, height: '100%' }}>
				<Space
					style={{ justifyContent: 'center', width: '100%', paddingTop: 16 }}
					direction="vertical"
				>
					<Title level={2} style={{ textAlign: 'center' }}>
						{`${currentBranch.name}`}
					</Title>
					<Title level={5} style={{ textAlign: 'center' }}>
						{convertStringToUpperCaseFirstChar(address)}
					</Title>
					<Title level={5} style={{ textAlign: 'center' }}>
						<Typography.Link style={styles.link} href={`callto:${phone}`}>
							{StringHelper.formatPhoneNumber(phone!)}
						</Typography.Link>
					</Title>
					<Title level={5} style={{ textAlign: 'center' }}>
						{email}
					</Title>
				</Space>
				<Row>
					<Col xs={2} sm={2} md={4} lg={6} xl={6} xxl={4}></Col>
					<Col xs={20} sm={20} md={16} lg={12} xl={12} xxl={16}>
						<Card
							style={{
								marginTop: 64,
								width: '100%',
								boxShadow: 'rgb(164 173 186 / 25%) 0px 2px 5px 3px',
							}}
						>
							<Space
								style={{ width: '100%', padding: 16 }}
								direction="vertical"
								size="large"
							>
								{this.state.isShowComment ? (
									<Space>
										{' '}
										<Button
											icon={<ArrowLeftOutlined />}
											size="small"
											onClick={() => this.setState({ isShowComment: false })}
											style={{ position: 'absolute', top: 5, left: 5 }}
										/>
									</Space>
								) : null}
								{this.state.isShowComment ? (
									<>
										<Title level={3} style={{ textAlign: 'center' }}>
											{I18n.t(_t(translations.rating.writeReview))}
										</Title>
										<Title level={5} style={{ textAlign: 'center' }}>
											{I18n.t(_t(translations.rating.leaveUsaComment))}
										</Title>
										<TextArea
											rows={4}
											onChange={this.onChangeComment}
											autoFocus
										/>
										<Space
											style={{ display: 'flex', justifyContent: 'flex-end' }}
										>
											<Button
												type="primary"
												onClick={() =>
													this.setState({ showResultSuccess: true }, () => {
														this.onRating();
													})
												}
												disabled={!this.state.showOk}
											>
												{I18n.t(_t(translations.ok))}
											</Button>
										</Space>
									</>
								) : (
									<>
										<Title level={5} style={{ textAlign: 'center' }}>
											{I18n.t(_t(translations.rating.tellUs))}
										</Title>
										<Rate
											value={this.state.value}
											onChange={this.handleChange}
											character={({ index }: any) => customIcons[index].icons}
											style={{
												fontSize: '10vmin',
												display: 'flex',
												justifyContent: 'center',
											}}
										/>
									</>
								)}
							</Space>
						</Card>
					</Col>
					<Col xs={20} sm={2} md={4} lg={6} xl={6} xxl={4}></Col>
				</Row>
			</Content>
		);
	}
	handleChange = (value: any) => {
		this.setState({ value });
		if (value > 3) {
			this.setState({ showResultSuccess: true }, () => {
				this.onRating();
			});
		} else {
			setTimeout(() => {
				this.setState({ isShowComment: true });
			}, 1000);
		}
	};
	onChangeComment = (e: any) => {
		this.setState({ comment: e.target.value });
		this.setState({ showOk: _.isEmpty(e.target.value) ? false : true });
	};
	onRating = () => {
		let path = window.location.pathname;
		let str = path.split('/');
		let bookingId = str[3];

		const data: ICreateRatingRequest = {
			bookingId: bookingId,
			comment: this.state.comment,
			rate: this.state.value,
			status: 1,
		};
		this.props.createRating(data);
	};
	// getQueries(): IBookingSearchQuery {
	//   return qs.parse(this.props.location.search, {
	//     ignoreQueryPrefix: true,
	//   }) as IBookingSearchQuery;
	// }
}

const mapStateToProps = (state: RootState) => ({
	error: state.ErrorReducer.error?.errors,
	isLoading: state.AppConfigReducer.showLoading,
	currentBranch: state.BranchReducer.currentBranch,
	currentAction: state.ReduxActionReducer['RATING'],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	createRating: (request: ICreateRatingRequest) => {
		dispatch(RatingActions.createRating.request(request));
	},
	getBranchById: (id: string) => {
		dispatch(BranchActions.getBranchById.request(id));
	},
});
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(RatingPage)
);
