import { Component, CSSProperties } from 'react';
import {
	Space,
	Divider,
	Typography,
	Row,
	Col,
	Tag,
	Dropdown,
	Button,
} from 'antd';
import { _t, I18n, translations } from 'utils';
import { Avatar } from 'components';
import { IComponentProps } from 'components';
import { ICustomer } from 'models';
import _ from 'lodash';
import { StringHelper } from 'helpers';
import { MoreOutlined } from '@ant-design/icons';

interface ICustomerInfoProps {
	showMenu?: boolean;
	overlay?: any;
	customer: Partial<ICustomer> | undefined;
	hideAvatar?: boolean;
}
class CustomerInfo extends Component<ICustomerInfoProps> {
	render() {
		const selectedCustomer = this.props.customer || {};
		const customerName = `${selectedCustomer!.firstName} ${
			selectedCustomer!.lastName
		}`;
		return (
			<>
				<Row>
					{this.props.hideAvatar ? null : (
						<Col style={{ alignSelf: 'center', marginRight: 16 }}>
							<Space
								size={4}
								style={styles.maxWidth}
								direction="vertical"
								align="center"
							>
								<Avatar size="large" name={customerName}></Avatar>
								{selectedCustomer?.notCome !== 0 ? (
									<Tag
										color="red"
										style={{
											marginRight: 0,
											padding: 2,
											marginTop: -20,
											fontSize: 10,
										}}
									>
										{`${I18n.t(
											_t(translations.customerProfile.noShow)
										)} (${selectedCustomer?.notCome!})`}
									</Tag>
								) : selectedCustomer?.newCustomer ? (
									<Tag
										color="green"
										style={{
											marginRight: 0,
											padding: 2,
											marginTop: -20,
											fontSize: 10,
										}}
									>
										{I18n.t(_t(translations.bookingCustomerInfo.newClient))}
									</Tag>
								) : null}
							</Space>
						</Col>
					)}
					<Col>
						{/* <Space size='small' direction="vertical"> */}
						<Typography.Title level={5} style={{ margin: 0 }}>
							{customerName}
						</Typography.Title>
						<Typography.Text style={{ margin: 0 }}>
							{StringHelper.formatPhoneNumber(selectedCustomer!.phone)}
						</Typography.Text>
						{/* <Typography.Text style={{ margin: "8px 0" }}>{selectedCustomer!.email}</Typography.Text> */}
						{/* </Space> */}
					</Col>
					{this.props.showMenu && (
						<Dropdown trigger={['click']} overlay={this.props.overlay}>
							<Button style={styles.threeDotBtn}>
								More options
								<MoreOutlined style={{ fontSize: 20, fontWeight: 900 }} />
							</Button>
						</Dropdown>
					)}
				</Row>
			</>
		);
	}
}

const styles = {
	divider: {
		width: '100%',
		margin: '16px 0',
	},

	maxWidth: {
		minWidth: '100%',
	},
	threeDotBtn: {
		position: 'absolute',
		top: 5,
		right: 5,
		minWidth: '46px',
		boxShadow: '0 2px 5px 0 rgb(164 173 186 / 30%)',
		fontSize: 16,
	} as React.CSSProperties,

	dividerTabPane: {
		width: '100%',
		margin: 0,
	},
	tabPane: {
		minWidth: '100%',
		margin: 0,
	},
	noMargin: {
		margin: 0,
	},
	textSize: {
		fontSize: 16,
	},
	btnConfirm: {
		width: '100%',
		fontSize: 18,
	},

	noPaddingCol: {
		paddingInline: 0,
		width: '100%',
	},
	maxWidthSpace: {
		minWidth: '100%',
		marginTop: 16,
	},
	col: {
		minWidth: '100%',
		alignItems: 'center',
	},
};

export default CustomerInfo;
