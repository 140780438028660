import { Button, Col, ColProps, Typography } from 'antd';
import { kStyles } from 'GlobalStyles';
import React from 'react';
import { Colors } from 'utils';

interface ISimpleModalListItem extends ColProps {
	title: string;
	isSelected?: boolean;
	onSelectItem: () => void;
}
const SimpleModalListItem = (props: ISimpleModalListItem) => {
	const { title, isSelected, onSelectItem } = props;
	return (
		<Col lg={6} md={12} sm={12} xs={24} {...props}>
			<Button
				onClick={() => {
					onSelectItem();
				}}
				style={isSelected ? styles.buttonSelected : styles.button}
			>
				<Typography.Title
					level={4}
					style={isSelected ? styles.itemSelected : {}}
				>
					{title}
				</Typography.Title>
			</Button>
		</Col>
	);
};

const styles: kStyles = {
	button: { height: 100, width: '100%', overflow: 'hidden' },
	buttonSelected: {
		height: 100,
		backgroundColor: Colors.PRIMARY_COLOR,
		width: '100%',
    overflow: 'hidden'
	},
	itemSelected: { color: Colors.WHITE },
};

export default SimpleModalListItem;
