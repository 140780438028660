import React, { useEffect, useState } from 'react';
import CheckOutBaseModal from './CheckOutBaseModal';
import { translations, _t, I18n } from 'utils';
import _ from 'lodash';
import {
	Col,
	Input,
	Typography,
	Button,
	Row,
	Space,
	Descriptions,
	Spin,
} from 'antd';
import styles from './styles';
import { IGetPromotionByCouponCode, IPromotion } from 'models/IPromotion';
import moment from 'moment';
import PromotionApiService from 'services/PromotionApiService';
import { IApiResponse, IBooking } from 'models';
import { showError } from 'helpers/alertHelper';
import { formatBalance } from 'helpers/currencyHelper';
import { PRIMARY_COLOR } from 'utils/Colors';

interface IPromotionListModalProps {
	onOk: (promotion: IPromotion) => void;
	onCancel: () => void;
	currentBooking: IBooking;
	listBookingCombined: IBooking[];
}

const RowItem = (props: {
	promotion: IPromotion;
	onChoosePromotion: (promotion: IPromotion) => void;
	perRow?: number;
}) => {
	const { promotion, perRow } = props;
	const endDate = !_.isEmpty(promotion.endDateUtc)
		? `- ${moment(promotion.endDateUtc).format('MM/DD/YYYY')}`
		: `- Infinity`;
	return (
		<Col
			md={perRow ? perRow : 24}
			onClick={() => props.onChoosePromotion(promotion)}
		>
			<Col
				style={{
					borderStyle: 'solid',
					borderWidth: 0.5,
					color: '#cdcd',
					borderRadius: 8,
					paddingInline: 12,
					paddingBlock: 8,
				}}
			>
				<DescriptionsItem
					title="Promotion name"
					value={promotion.name}
					valueColor={PRIMARY_COLOR}
				/>
				<DescriptionsItem
					title="Promotion Duration"
					value={`${moment(promotion.startDateUtc).format(
						'MM/DD/YYYY'
					)} ${endDate}`}
				/>
				<DescriptionsItem
					title="Promotion Type"
					value={promotion.promotionTypeCode}
				/>

				<DescriptionsItem
					title="Discount Amount"
					value={
						!promotion.usePercentage
							? formatBalance(promotion.discountAmount)
							: `${promotion.discountPercentage}%`
					}
				/>
				<DescriptionsItem title="Description" value={promotion.note} />
			</Col>
		</Col>
	);
};

const DescriptionsItem = (props: {
	title: string;
	value: string;
	valueColor?: string;
}) => {
	return (
		<Descriptions
			style={{
				width: '100%',
				alignContent: 'baseline',
				alignItems: 'baseline',
			}}
			labelStyle={{ fontSize: 14 }}
			contentStyle={{
				justifyContent: 'flex-end',
				alignContent: 'baseline',
				fontSize: 16,
				fontWeight: 'bold',
				color: props.valueColor,
			}}
			column={1}
		>
			<Descriptions.Item style={{ paddingBottom: 1 }} label={props.title}>
				{props.value}
			</Descriptions.Item>
		</Descriptions>
	);
};

const InputCouponModal = (props: IPromotionListModalProps) => {
	const { currentBooking, listBookingCombined } = props;

	const [loading, setLoading] = useState(false);
	const [promotion, setPromotion] = useState<IPromotion>();
	const [couponCode, setCouponCode] = useState<string>('');
	const [error, setError] = useState<string>();

	const getPromotionByCouponCode = async () => {
		setLoading(true);
		setError(undefined);
		let listAllBookingDetails = Array.from(currentBooking.bookingDetails || []);
		if (!_.isEmpty(listBookingCombined)) {
			listBookingCombined.forEach((booking) => {
				booking.bookingDetails.map((bookingDetail) =>
					listAllBookingDetails.push(bookingDetail)
				);
			});
		}
		const request: IGetPromotionByCouponCode = {
			couponCode,
			date: moment.utc(moment()).format('MM-DD-YYYY HH:mm'),
			customerId: currentBooking.customerId || '',
			serviceIds: listAllBookingDetails.map((x) => x.item?.id!),
			branchId: currentBooking.branchId || '',
		};
		const res = await PromotionApiService.getPromotionByCouponCode(request);
		setLoading(false);
		if (!_.isEmpty(res.errors)) {
			setError(res.message[0]?.Text);
			return;
		}

		const resData = res as IApiResponse<IPromotion>;
		if (!_.isEmpty(resData.message)) {
			setError(resData.message[0]?.text);
			return;
		}
		if (resData.succeeded && !_.isEmpty(resData.data)) {
			setPromotion(resData?.data!);
		}
	};
	const onOk = (promotion: IPromotion) => {
		props.onOk(promotion);
	};
	const onCancel = () => {
		props.onCancel();
	};
	return (
		<CheckOutBaseModal
			title={'Input Promotion Coupon Code'}
			onOk={() => {}}
			onCancel={onCancel}
			disableOk={false}
			// width={promotions.length > 1 ? '80%' : undefined}
			centered
			btnOkHidden
		>
			<Spin spinning={loading}>
				<Space size={'small'} direction={'vertical'} style={styles.maxWidth}>
					<Col>
						<Typography.Title level={4}>Promotion Coupon Code</Typography.Title>
						<Row align="middle" gutter={12}>
							<Col md={20}>
								<Input
									// style={styles.inputNumber}
									onPressEnter={getPromotionByCouponCode}
									autoFocus={true}
									value={couponCode}
									onChange={(event) => setCouponCode(event.target.value)}
								/>
							</Col>
							<Col md={4}>
								<Space style={styles.maxWidth} size="small">
									<Col md={12}>
										<Button
											disabled={_.isEmpty(couponCode)}
											onClick={getPromotionByCouponCode}
										>
											Check
										</Button>
									</Col>
								</Space>
							</Col>
							<Col span={24} style={{ ...styles.textRight, marginTop: 8 }}>
								{!_.isEmpty(error) ? (
									<Typography.Text type="danger">{error}</Typography.Text>
								) : null}
							</Col>
						</Row>
					</Col>
					{!_.isEmpty(promotion) && (
						<Col>
							<Typography.Title level={4}>
								Promotion Information
							</Typography.Title>
							<Col md={24}>
								<Row gutter={[16, 16]} align="middle">
									<RowItem
										promotion={promotion!}
										onChoosePromotion={(promotion) => onOk(promotion)}
									/>
								</Row>
							</Col>
						</Col>
					)}
				</Space>
			</Spin>
		</CheckOutBaseModal>
	);
};

export default InputCouponModal;
