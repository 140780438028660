import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IBranch } from 'models/IBranch';
import { AuthenticationActions, BranchActions } from '../actions';
import _ from 'lodash';
export interface IBranchReducer {
	branches: IBranch[];
	currentBranch?: IBranch;
	branchId?: string;
	// paymentReferenceCodeRequiring: boolean;
}

const initialState: IBranchReducer = {
	branches: [],
	// paymentReferenceCodeRequiring: true,
};

// Get Branches by Shop ID

function getBranchesByShopIdSuccess(
	state: IBranchReducer,
	action: PayloadAction<IBranch[] | null>
) {
	state.branches = action.payload!;
	// if (_.isEmpty(state.currentBranch) || _.isEmpty(state.currentBranch?.name)) {
	//   state.currentBranch = _.find(action.payload, (branch) => branch.isPrimary === true);
	// }
}
function selectBranch(
	state: IBranchReducer,
	action: PayloadAction<Partial<IBranch>>
) {
	state.currentBranch = action.payload as IBranch;
}
function getBranchSuccess(
	state: IBranchReducer,
	action: PayloadAction<IBranch>
) {
	state.currentBranch = action.payload;
	if (_.isEmpty(state.branches.filter((x) => x.id === action.payload.id))) {
		state.branches = _.concat(state.branches, [action.payload]);
	}
}
// const setPaymentReferenceCodeRequiring = (
// 	state: IBranchReducer,
// 	action: PayloadAction<boolean>
// ) => {
// 	state.paymentReferenceCodeRequiring = action.payload;
// };

const BranchReducer = createReducer(
	initialState,
	(builder) =>
		builder
			.addCase(
				BranchActions.getBranchesByShopId.success,
				getBranchesByShopIdSuccess
			)
			.addCase(BranchActions.selectBranch.request, selectBranch)
			.addCase(BranchActions.updateBranch.success, getBranchSuccess)
			.addCase(BranchActions.getBranchById.success, getBranchSuccess)
			.addCase(
				BranchActions.setBranchId.request,
				(state: IBranchReducer, action: PayloadAction<string>) => {
					state.branchId = action.payload;
				}
			)
			.addCase(
				AuthenticationActions.logOut.request,
				(state: IBranchReducer) => {
					state.currentBranch = undefined;
					state.branches = [];
				}
			)
	// .addCase(
	// 	BranchActions.setPaymentReferenceCodeRequiring.request,
	// 	setPaymentReferenceCodeRequiring
	// )
);

export default BranchReducer;
