import { kStyles } from 'GlobalStyles';

const styles: kStyles = {
	timeContainer: {
		borderColor: 'black',
		borderWidth: 1,
		textAlign: 'center',
		justifyContent: 'center',
		borderStyle: 'solid',
		padding: 10,
		cursor: 'pointer',
		marginInline: 8,
		width: 100,
		borderRadius: 4,
	},
	timeContainerMobile: {
		borderColor: 'black',
		borderWidth: 1,
		textAlign: 'center',
		justifyContent: 'center',
		borderStyle: 'solid',
		padding: 10,
		cursor: 'pointer',
		marginInline: 8,
		width: 90,
		borderRadius: 4,
	},
	timeContainerActive: {
		background: `linear-gradient(to right, var(--base-gradient-color), var(--second-gradient-color))`,
		fontWeight: 'bold',
		color: 'white',
		borderWidth: 0,
	},
	buttonCalendar: {
		minHeight: '60px',
		minWidth: '100%',
		padding: '11px 8px',
	},
};

export default styles;
