import GlobalStyles from 'GlobalStyles';

const styles = {
	layoutContainer: { backgroundColor: 'white', height: 'calc(100vh - 64px)' },
	header: {
		position: 'fixed',
		zIndex: 1,
		width: '100%',
		alignItems: 'center',
		padding: 0,
		...GlobalStyles.boxShadow,
	} as React.CSSProperties,
	contentContainer: { marginTop: 64, height: 'calc(100vh - 64px)' },
	btnConfirm: {
		width: '100%',
		fontSize: 18,
	},
	icClose: {
		marginRight: 100,
		cursor: 'pointer',
	} as React.CSSProperties,
	iconContainer: { textAlign: 'end' } as React.CSSProperties,
	noPaddingCol: {
		paddingInline: 0,
		width: '100%',
	},
	maxWidth: { width: '100%' },

	title: {
		color: 'white',
		textAlign: 'center',
		marginBottom: 0,
		justifyContent: 'center',
	} as React.CSSProperties,
	textCenter: { textAlign: 'center' } as React.CSSProperties,
	textRight: { textAlign: 'right' } as React.CSSProperties,
	halfWidth: { width: '50%', textAlign: 'center' } as React.CSSProperties,
	inputNumber: { width: '100%', textAlign: 'right' } as React.CSSProperties,
};

export default styles;
