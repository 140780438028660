import { DollarCircleTwoTone, PercentageOutlined } from '@ant-design/icons';
import {
	Button,
	Col,
	Divider,
	Input,
	Radio,
	Row,
	Space,
	Typography,
} from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { translations, _t, I18n } from 'utils';
import { DiscountType, PriceType, DiscounterType } from 'utils/Consts';
import CheckOutBaseModal from './CheckOutBaseModal';
import { price } from 'redux/selectors/checkout';
import styles from './styles';
import { CurrencyHelper } from 'helpers';
import { IBooking, IBookingDetail, IEmployee } from 'models';
import Title from 'antd/lib/typography/Title';
import { priceFixed } from 'helpers/currencyHelper';

interface IAddDiscountModalProps extends ReturnType<typeof mapStateToProps> {
	onOk(
		discount: number,
		discountPercent: number,
		sharingType: DiscounterType,
		discountType: DiscountType
	): void;
	onCancel(): void;
	listBookingCombined: IBooking[];
	allBookingDetails: Partial<IBookingDetail>[];
}

interface IAddDiscountModalState {
	discount?: number;
	discountPercent?: number;
	type: DiscountType;
	discounterType: DiscounterType;
	addonAfter: string;
}

class AddDiscountModal extends React.Component<
	IAddDiscountModalProps,
	IAddDiscountModalState
> {
	constructor(props: IAddDiscountModalProps) {
		super(props);
		this.state = {
			type:
				this.props.discountType || this.props.discountType === 0
					? this.props.discountType
					: DiscountType.PERCENT,
			discounterType: this.props.discounterType || DiscounterType.BOTH,
			addonAfter: '%',
			discount: this.props.discount,
			discountPercent: this.props.discountPercent,
		};
	}
	inputRef: any = React.createRef();
	stylists = this.props.allBookingDetails.map((x) => x.stylist);
	uniqStylists = _.uniqBy(this.stylists, (z) => z?.id);

	render() {
		return (
			<CheckOutBaseModal
				title={I18n.t(_t(translations.checkout.addDiscount))}
				onOk={() => this.onOk()}
				onCancel={this.props.onCancel}
				disableOk={!this.state.discount || this.state.discount <= 0}
			>
				{this.renderDiscountTypeMethod()}
				{this.renderDiscountInput()}
				{this.renderSharingMethod()}
				{this.state.discount && this.state.discount !== 0 ? (
					<>
						<Divider style={{ margin: 0 }} />
						<Title style={{ margin: 0 }} level={4}>
							Discounter Responsible:{' '}
						</Title>
					</>
				) : null}
				{this.renderDiscountResponsible()}
			</CheckOutBaseModal>
		);
	}

	renderDescription = (
		firstName?: string,
		lastName?: string,
		discount?: number
	) => {
		return (
			<Row justify="space-between" style={{ margin: 0, width: '100%' }}>
				<Typography.Title level={5} style={{ flex: 1 }}>
					{firstName || ''} {lastName || ''} :{' '}
				</Typography.Title>
				<Typography.Title level={5} style={{ display: 'contents' }}>
					{CurrencyHelper.formatPrice(discount || 0)}
				</Typography.Title>
			</Row>
		);
	};

	renderDiscountResponsible = () => {
		if (!this.state.discount) {
			return null;
		}
		if (this.state.discounterType === DiscounterType.OWNER_ONLY) {
			return this.renderDescription('Owner', '', this.state.discount);
		}
		if (this.state.discounterType === DiscounterType.EMPLOYEE_ONLY) {
			return this.uniqStylists.map((x, i) => {
				const stylistBookingDetails = this.props.allBookingDetails.filter(
					(b) => b.stylistId === x?.id
				);
				const discount = _.sumBy(stylistBookingDetails, (sb) => {
					return this.getDiscount(sb, false, false);
				});
				return this.renderDescription(x?.firstName, x?.lastName, discount);
			});
		}
		if (this.state.discounterType === DiscounterType.BOTH) {
			return (
				<>
					{this.renderDescription(
						'Owner',
						'',
						this.getDiscount(undefined, true)
					)}
					{this.uniqStylists.map((x, i) => {
						const stylistBookingDetails = this.props.allBookingDetails.filter(
							(b) => b.stylistId === x?.id
						);
						const discount = _.sumBy(stylistBookingDetails, (sb) => {
							return this.getDiscount(sb, false, false);
						});
						return (
							<Col>
								{this.renderDescription(x?.firstName, x?.lastName, discount)}
							</Col>
						);
					})}
				</>
			);
		}
	};

	renderDiscountTypeMethod() {
		console.log(this.state.type);

		return (
			<>
				<Radio.Group
					buttonStyle="solid"
					style={styles.maxWidth}
					onChange={this.onChangeType}
					value={this.state.type}
					defaultValue={this.state.type}
				>
					{this.renderDiscountTypeMethodButton({
						value: DiscountType.PERCENT,
						children: (
							<>
								<PercentageOutlined /> {I18n.t(_t(translations.percent))}
							</>
						),
					})}
					{this.renderDiscountTypeMethodButton({
						value: DiscountType.DOLLAR,
						children: (
							<>
								<DollarCircleTwoTone twoToneColor="#52c41a" />{' '}
								{I18n.t(_t(translations.money))}
							</>
						),
					})}
				</Radio.Group>
			</>
		);
	}

	renderDiscountInput() {
		return (
			<Row gutter={8}>
				<Col md={20}>
					<Input
						ref={this.inputRef}
						addonAfter={this.state.addonAfter}
						type="number"
						style={styles.inputNumber}
						autoFocus={true}
						value={
							this.state.type === DiscountType.DOLLAR
								? this.state.discount
								: this.state.discountPercent
						}
						onPressEnter={() => this.onOk()}
						onChange={(event) => this.onChange(_.toNumber(event.target.value))}
					/>
				</Col>
				<Col md={4}>
					<Button
						onClick={() => {
							this.setState({
								discount: undefined,
								discountPercent: undefined,
							});
							this.inputRef.current?.focus();
						}}
						danger
					>
						{I18n.t(_t(translations.clear))}
					</Button>
				</Col>
				{!_.isEmpty(this.props.listBookingCombined) ? (
					<Col span={24}>
						<br />
						<Typography.Text type="warning">
							Note: You are only allowed to add discounts for bookings that do
							not have a discount.
						</Typography.Text>
					</Col>
				) : null}
			</Row>
		);
	}

	renderSharingMethod() {
		return (
			<Col style={{ marginTop: 10 }}>
				<Typography.Text strong style={{ fontSize: 20 }}>
					{I18n.t(_t(translations.checkout.sharingMethod))}
				</Typography.Text>
				<Row style={{ marginTop: 10 }}>
					<Radio.Group
						buttonStyle="solid"
						style={styles.maxWidth}
						onChange={(event) =>
							this.setState({ discounterType: event.target.value })
						}
						value={this.state.discounterType}
					>
						{this.renderSharingMethodButton({
							value: DiscounterType.BOTH,
							title: I18n.t(_t(translations.checkout.both)),
						})}
						{this.renderSharingMethodButton({
							value: DiscounterType.OWNER_ONLY,
							title: I18n.t(_t(translations.checkout.ownerOnly)),
						})}
						{this.renderSharingMethodButton({
							value: DiscounterType.EMPLOYEE_ONLY,
							title: I18n.t(_t(translations.checkout.employeeOnly)),
						})}
					</Radio.Group>
				</Row>
			</Col>
		);
	}

	renderDiscountTypeMethodButton({
		value,
		children,
	}: {
		value: DiscountType;
		children: JSX.Element;
	}) {
		return (
			<>
				{this.renderRadioButton({
					value: value,
					children: children,
					style: { width: '50%', textAlign: 'center' },
				})}
			</>
		);
	}

	renderSharingMethodButton({
		value,
		title,
	}: {
		value: DiscounterType;
		title: string;
	}) {
		return (
			<>
				{this.renderRadioButton({
					value: value,
					children: title,
					style: { width: '33.3%', textAlign: 'center' },
				})}
			</>
		);
	}

	renderRadioButton({
		value,
		children,
		style,
	}: {
		value: DiscountType | DiscounterType;
		children: JSX.Element | string;
		style: React.CSSProperties;
	}) {
		return (
			<Radio.Button style={style} value={value}>
				{children}
			</Radio.Button>
		);
	}

	getDiscount = (
		bookingDetail?: Partial<IBookingDetail>,
		isOwner?: boolean,
		isLast?: boolean
	): number => {
		const servicePriceRatio = this.props.price(PriceType.MAX_DISCOUNT)
			? ((bookingDetail?.price || 0) /
					this.props.price(PriceType.MAX_DISCOUNT)!) *
			  100
			: 0;
		if (this.state.discounterType === DiscounterType.BOTH) {
			const owner = priceFixed((this.state?.discount || 0) / 2);
			let discount = !isLast
				? priceFixed((servicePriceRatio * owner) / 100)
				: owner -
				  _.sumBy(
						this.props.allBookingDetails.filter(
							(y) => y.id !== bookingDetail?.id
						),
						(x) => this.getDiscount(x, false, false)
				  );

			return isOwner ? owner : discount;
		}
		if (this.state.discounterType === DiscounterType.EMPLOYEE_ONLY) {
			return !isLast
				? priceFixed((servicePriceRatio * (this.state.discount || 0)) / 100)
				: (this.state.discount || 0) -
						_.sumBy(
							this.props.allBookingDetails.filter(
								(y) => y.id !== bookingDetail?.id
							),
							(x) => this.getDiscount(x, false, false)
						);
		}
		return 0;
	};

	onChangeType = (event: any) => {
		this.setState({
			type: event.target.value,
			// discount: undefined,
			// discountPercent: undefined,
			addonAfter: event.target.value === DiscountType.DOLLAR ? '$' : '%',
		});
	};

	onSharingMethodChange = (event: any) => {};
	onChange = (value: number) => {
		const totalService = this.props.price(PriceType.MAX_DISCOUNT)!;
		if (this.state.type === DiscountType.PERCENT) {
			if (value > 100) {
				value = 100;
			}
			if (value < 0) {
				value = 0;
			}
			let discount = CurrencyHelper.priceFixed((value * totalService) / 100);
			this.setState({ discount, discountPercent: value });
		} else {
			if (value > totalService) {
				value = totalService;
			}
			let discountPercent = _.toNumber(
				((value / totalService) * 100).toFixed(2)
			);
			this.setState({
				discount: CurrencyHelper.priceFixed(value),
				discountPercent,
			});
		}
	};

	onOk = () => {
		if (!this.state.discount) {
			return;
		}
		this.props.onOk(
			this.state.discount!,
			this.state.discountPercent!,
			this.state.discounterType!,
			this.state.type
		);
	};
}

const mapStateToProps = (state: RootState) => ({
	price: (priceType: PriceType) => price(state, priceType),
	discountPercent: state.CheckoutReducer.discountPercent,
	discount: state.CheckoutReducer.discount,
	discounterType: state.CheckoutReducer.discounterType,
	discountType: state.CheckoutReducer.discountType,
});

export default connect(mapStateToProps)(AddDiscountModal);
