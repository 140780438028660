/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Typography, Layout, Table } from "antd";
import styles from "./styles";
import { I18n, _t, translations } from "utils";
import GlobalStyles from "GlobalStyles";
import { topStaffTableConfig } from "utils/configurations";
import { ITopServicesRequest } from "models/RequestModels";
import { TopServiceRequestPeriodType } from "utils/Consts";
import { IApiResponse, IErrorResponse, ITopStaffs } from "models";
import AdminDashboardApiService from "services/AdminDashboardApiService";
import { AlertHelper } from "helpers";
const { Title } = Typography;
const { Header } = Layout;

interface ITopStaffsProps {}

const TopStaffs = (props: ITopStaffsProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const [topStaffs, setTopStaff] = useState<ITopStaffs[]>();
  const [isLoading, setIsLoading] = useState<boolean>();

  useEffect(() => {
    getTopStaffs({
      date: new Date(),
      pageNumber: 1,
      pageSize: 5,
      periodType: TopServiceRequestPeriodType.MONTH,
    });
  }, []);

  const getTopStaffs = async (request: ITopServicesRequest) => {
    setIsLoading(true);
    const result = (await AdminDashboardApiService.getTopStaffs(
      request
    )) as IApiResponse<ITopStaffs[]>;
    setIsLoading(false);
    if (result.succeeded) {
      setTopStaff(result.data);
    } else {
      const error = result as IErrorResponse;
      AlertHelper.showError(error);
    }
  };

  return (
    <div
      style={isHover ? styles.listContainerHover : styles.listContainer}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Header style={styles.listHeader}>
        <Title level={4} style={GlobalStyles.textBold}>
          {I18n.t(_t(translations.dashboard.tableTopStaffsTitle))}
        </Title>
      </Header>
      <div style={styles.tableContainer}>
        <Table
          columns={topStaffTableConfig}
          dataSource={topStaffs}
          pagination={false}
          loading={isLoading}
          rowKey={(record: ITopStaffs) => record.stylistId!}
        />
      </div>
    </div>
  );
};

export default TopStaffs;
