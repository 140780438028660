import { Tag, TagProps } from "antd";
import { ColorHelper } from "helpers";
import React from "react";
import { I18n, _t, translations } from "utils";

interface IStatusTagProps extends TagProps {
  status: number;
}

export default class StatusTag extends React.Component<IStatusTagProps> {
  render() {
    return (
      <Tag
        color={ColorHelper.getStatusColor(this.props.status)}
        style={{ ...this.props.style, marginLeft: 8, marginRight: 0 }}
      >
        {I18n.t(_t(translations.bookingStatus[`status_${this.props.status}`]))}
      </Tag>
    );
  }
}
