const styles: any = {
	layout: {
		height: '100vh',
	},

	nailHeader: {
		height: '250px',
		background: 'none',
		textAlign: 'center',
	},
	nailHeaderLogo: {
		margin: '0 auto',
		width: '200px',
	},
	nailHeaderSlogan: {
		lineHeight: 1.6,
		margin: '0 auto',
	},
	row: { margin: '0 auto' },
};

export default styles;
