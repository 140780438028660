import { Col, Row } from 'antd';
import { SimpleModalListItem } from 'components';
import { kStyles } from 'GlobalStyles';
import ModalHelper from 'helpers/modalHelper';
import { IExpenseType } from 'models';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { I18n, translations, _t } from 'utils';

interface IExpenseTypePickerModalProps {
	isShowModal: boolean;
	onCancel: () => void;
	onChooseType: (type: IExpenseType) => void;
	selectedType?: IExpenseType;
	showAll?: boolean;
}
const ExpenseTypePickerModal = ({
	isShowModal,
	onCancel,
	onChooseType,
	selectedType,
	showAll,
}: IExpenseTypePickerModalProps) => {
	const isSelected = (id: string) => selectedType?.id === id;
	const expenseTypes = useSelector(
		(state: RootState) => state.ExpenseTypeReducer.types
	);
	const expenseTypeWithAll = [...expenseTypes, ALL];
	return (
		<ModalHelper
			title={I18n.t(_t(translations.expenses.chooseType))}
			onCancel={onCancel}
			onOk={() => null}
			visible={isShowModal}
			btnOkHidden
			width={'75%'}
		>
			<Col style={styles.modalContainer}>
				<Row gutter={[16, 16]}>
					{(showAll ? expenseTypeWithAll : expenseTypes).map((type) => {
						return (
							<SimpleModalListItem
								title={type.description}
								onSelectItem={() => onChooseType(type)}
								isSelected={isSelected(type.id)}
							/>
						);
					})}
				</Row>
			</Col>
		</ModalHelper>
	);
};

export const ALL = {
	id: '',
	code: '',
	description: I18n.t(_t(translations.expenses.all)),
};

const styles: kStyles = {
	modalContainer: {
		height: window.outerHeight * 0.6,
		width: '100%',
		overflow: 'scroll', //define overflow rules if you don't want the content to be flow out of screen
		overflowX: 'hidden',
	},
};

export default ExpenseTypePickerModal;
