import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { BookingActions } from '../actions';
import _ from 'lodash';
import { IBooking, IBookingDetail, ICustomer, IEmployee } from 'models';
import { ITimeBlock } from 'models/ITimeBlock';
import { IStylistAndTimeBlockResponse } from 'models/ResponseModels';
import { TimeHelper } from 'helpers';
import moment from 'moment';
import { Const } from 'utils';
export interface IBookingReducer {
	todayBookings: {
		[key: string]: IBooking;
	};
	bookings: IBooking[];
	currentBooking?: IBooking;
	bookingDetails: Partial<IBookingDetail>[];
	timeBlocks: ITimeBlock[];
	timeActiveId?: string;
	availableStylist: IEmployee[][];
	selectedCustomer?: Partial<ICustomer>;
	editing: boolean;
	isNewCustomer: boolean;
}

const initialState: IBookingReducer = {
	bookings: [],
	todayBookings: {},
	timeBlocks: [],
	bookingDetails: [{}],
	availableStylist: [],
	editing: false,
	isNewCustomer: false,
};

// Get Booking

function getBookingsByBranchSuccess(
	state: IBookingReducer,
	action: PayloadAction<IBooking[] | null>
) {
	state.bookings = action.payload!;
}
function getTodayBookingSuccess(
	state: IBookingReducer,
	action: PayloadAction<IBooking[] | null>
) {
	state.todayBookings = action.payload?.reduce((result, item) => {
		result[item.id] = item;
		return result;
	}, {} as { [key: string]: IBooking })!;
}

function socketUpdateBooking(
	state: IBookingReducer,
	action: PayloadAction<IBooking>
) {
	const booking = action.payload;
	state.todayBookings[booking.id] = booking;
}
function createBookingSuccess(
	state: IBookingReducer,
	action: PayloadAction<IBooking>
) {
	state.currentBooking = action.payload;
	state.editing = false;
}
function deleteChoosingService(
	state: IBookingReducer,
	action: PayloadAction<number>
) {
	let bookingDetails = Array.from(state.bookingDetails);
	bookingDetails.splice(action.payload, 1);
	state.editing = true;
	state.bookingDetails = bookingDetails;
}
function selectTimeStart(
	state: IBookingReducer,
	action: PayloadAction<string>
) {
	state.timeActiveId = action.payload;
	state.editing = true;
}
function getBookingByIdSuccess(
	state: IBookingReducer,
	action: PayloadAction<IBooking>
) {
	const newBookingDetails: Partial<IBookingDetail>[] = Array.from(
		action.payload.bookingDetails
	);
	state.currentBooking = action.payload;
	if (action.payload.status === Const.BookingStatus.PENDING) {
		newBookingDetails.push({
			stylistId: action.payload.bookingDetails[0].stylistId,
		});
	}
	state.bookingDetails = newBookingDetails;
	state.selectedCustomer = action.payload.customer;
	state.timeActiveId = TimeHelper.generateTimeBlockId(
		moment(action.payload.startTimeExpected).format('YYYY-MM-DDTHH:mm')
	);
}
function selectCustomer(
	state: IBookingReducer,
	action: PayloadAction<Partial<ICustomer>>
) {
	state.selectedCustomer = action.payload;
}
function removeCustomer(state: IBookingReducer, action: PayloadAction<any>) {
	state.selectedCustomer = undefined;
}
function getCustomerByPhone(
	state: IBookingReducer,
	action: PayloadAction<ICustomer>
) {
	state.selectedCustomer = action.payload;
}

function changeBookingDetails(
	state: IBookingReducer,
	action: PayloadAction<{
		index: number;
		bookingDetail: Partial<IBookingDetail>;
	}>
) {
	const sameServiceStylists = state.bookingDetails
		.filter(
			(x) =>
				!_.isEmpty(x.itemId) && x.itemId === action.payload.bookingDetail.itemId
		)
		.map((x) => x.stylistId);
	let newBookingDetail = Object.assign({}, action.payload.bookingDetail);
	if (
		sameServiceStylists.includes(
			state.bookingDetails[action.payload.index].stylistId
		)
	) {
		newBookingDetail.stylistId = undefined;
	}
	state.bookingDetails[action.payload.index] = Object.assign(
		state.bookingDetails[action.payload.index],
		newBookingDetail
	);
	if (!_.isEmpty(state.bookingDetails[action.payload.index + 1])) {
		for (
			let i = action.payload.index + 1;
			i <= state.bookingDetails.length - 1;
			i++
		) {
			if (
				!_.isEmpty(action.payload.bookingDetail.stylistId) &&
				!_.isEmpty(state.bookingDetails[i]) &&
				_.isEmpty(state.bookingDetails[i].stylistId) &&
				state.bookingDetails[i].itemId !==
					state.bookingDetails[action.payload.index].itemId
			) {
				state.bookingDetails[i] = Object.assign(state.bookingDetails[i], {
					stylistId: action.payload.bookingDetail.stylistId,
				});
			}
		}
	}

	if (
		action.payload.index === state.bookingDetails.length - 1 &&
		!_.isEmpty(state.bookingDetails[action.payload.index].item)
	) {
		state.bookingDetails.push({
			stylistId: state.bookingDetails[action.payload.index].stylistId,
		});
	}
	state.editing = true;
}

const addExtraAmount = (
	state: IBookingReducer,
	action: PayloadAction<{
		index: number;
		amount: number;
		note: string;
	}>
) => {
	const currentBooking = state.currentBooking!;
	let bookingDetail = currentBooking.bookingDetails[action.payload.index];
	bookingDetail.extraAmount = action.payload.amount;
	bookingDetail.note = action.payload.note;
	currentBooking.bookingDetails[action.payload.index] = bookingDetail;
	state.currentBooking = currentBooking;
};
function getAvailableStylistAndTimeBlocks(
	state: IBookingReducer,
	action: PayloadAction<{
		index: number;
		bookingDetail: IStylistAndTimeBlockResponse;
	}>
) {
	if (!state.availableStylist[action.payload.index]) {
		let newList = Array.from(state.availableStylist);
		state.bookingDetails.forEach((e, i) => {
			if (_.isEmpty(newList[i])) newList[i] = [];
		});
		newList[action.payload.index] = action.payload.bookingDetail.stylists;
		state.availableStylist = newList;
		// state.availableStylist.push(action.payload.bookingDetail.stylists);
	} else {
		const newStylistList = Array.from(action.payload.bookingDetail.stylists);
		if (
			state.currentBooking?.bookingDetails[action.payload.index] &&
			state.currentBooking?.bookingDetails[action.payload.index].stylist &&
			!_.find(
				newStylistList,
				(x) =>
					x.id ===
					state.currentBooking?.bookingDetails[action.payload.index].stylist?.id
			)
		) {
			newStylistList.push(
				state.currentBooking?.bookingDetails[action.payload.index].stylist!
			);
		}
		state.availableStylist[action.payload.index] = newStylistList;
	}
	const canStylistDoService = !_.isEmpty(
		_.find(
			action.payload.bookingDetail.stylists,
			(stylist) =>
				stylist.id === state.bookingDetails[action.payload.index].stylistId
		)
	);
	if (!canStylistDoService) {
		state.bookingDetails[action.payload.index].stylistId = undefined;
	}
	if (action.payload.index === 0) {
		state.timeBlocks = action.payload.bookingDetail.timeBlocks.map(
			(timeBlock) => {
				if (state.currentBooking?.bookingDetails[0]) {
					const startTimeUnix = moment
						.utc(state.currentBooking?.bookingDetails[0].startAtExpected)
						.unix();
					const endTimeUnix = moment
						.utc(state.currentBooking?.bookingDetails[0].endAtExpected)
						.unix();
					const blockTimeUnix = moment.utc(timeBlock.time).unix();
					if (startTimeUnix <= blockTimeUnix) {
						return {
							...timeBlock,
							// unavailable: false,
							// noStylistsAvailable: false,
							time: timeBlock.time,
						};
					}
				}
				return {
					...timeBlock,
					time: timeBlock.time,
				};
			}
		);
	}
}
function editBookingSuccess(
	state: IBookingReducer,
	action: PayloadAction<IBooking>
) {
	state.currentBooking = _.mergeWith(
		{},
		state.currentBooking,
		action.payload,
		(o, s) => (_.isNull(s) ? o : s)
	);
	state.editing = false;
}

function setIsNewCustomer(
	state: IBookingReducer,
	action: PayloadAction<boolean>
) {
	state.isNewCustomer = action.payload;
}
function resetBooking(state: IBookingReducer, action: PayloadAction<any>) {
	state.currentBooking = undefined;
	state.bookingDetails = [{}];
	state.selectedCustomer = undefined;
	state.timeActiveId = undefined;
	state.availableStylist = [];
	state.timeBlocks = [];
	state.editing = false;
}

const updatePromotionDiscount = (
	state: IBookingReducer,
	action: PayloadAction<{ id: string; promotionDiscount: number }[]>
) => {
	//current booking

	let newBooking = Object.assign({}, state.currentBooking);
	newBooking.bookingDetails?.forEach((x) =>
		action.payload?.forEach((y) => {
			if (x.id === y.id) {
				x.promotionDiscount = y.promotionDiscount;
			}
		})
	);
	state.bookingDetails = newBooking.bookingDetails;
	state.currentBooking = newBooking;
};

const BookingReducer = createReducer(initialState, (builder) =>
	builder
		.addCase(BookingActions.selectCustomer.request, selectCustomer)
		.addCase(
			BookingActions.getBookingsByBranch.success,
			getBookingsByBranchSuccess
		)
		.addCase(BookingActions.changeBookingDetail.request, changeBookingDetails)
		.addCase(
			BookingActions.deleteChoosingService.request,
			deleteChoosingService
		)
		.addCase(
			BookingActions.getAvailableStylistAndTimeBlocks.success,
			getAvailableStylistAndTimeBlocks
		)
		.addCase(BookingActions.getBookingById.success, getBookingByIdSuccess)
		.addCase(BookingActions.selectTimeStart.request, selectTimeStart)
		.addCase(BookingActions.removeCustomer.request, removeCustomer)
		.addCase(BookingActions.createBooking.success, createBookingSuccess)
		.addCase(BookingActions.resetBooking.request, resetBooking)
		.addCase(BookingActions.getCustomerByPhone.success, getCustomerByPhone)
		.addCase(BookingActions.getCustomerByPhone.request, removeCustomer)
		.addCase(BookingActions.editBooking.success, editBookingSuccess)
		.addCase(BookingActions.updateStatus.success, editBookingSuccess)
		.addCase(BookingActions.getTodayBookings.success, getTodayBookingSuccess)
		.addCase(BookingActions.socketUpdateBooking.request, socketUpdateBooking)
		.addCase(
			BookingActions.addBookingDetail.request,
			(state: IBookingReducer) => {
				state.bookingDetails.push({
					stylistId: state.bookingDetails[0].stylistId,
				});
			}
		)
		.addCase(BookingActions.addExtraAmount.request, addExtraAmount)
		.addCase(BookingActions.setIsNewCustomer.request, setIsNewCustomer)
		.addCase(
			BookingActions.updatePromotionDiscount.request,
			updatePromotionDiscount
		)
);

export default BookingReducer;
