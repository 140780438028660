import React, { Component } from "react";
import {
  SaleChart,
  UpComingBookingChart,
  BookingActivity,
  TodayNextBooking,
  TopServices,
  TopStaffs,
} from "./components";
import styles from "./styles";
import { Row, Col } from "antd";
import { ContentAdminContainer } from "components";

interface IDashboardProps {}
interface IState {}

class DashBoard extends Component<IDashboardProps, IState> {
  render() {
    return (
      <ContentAdminContainer>
        <Row
          style={styles.row}
          gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
        >
          <Col style={styles.col} lg={12} xs={24}>
            <SaleChart />
          </Col>
          <Col style={styles.col} lg={12} xs={24}>
            <UpComingBookingChart />
          </Col>
        </Row>
        <Row
          style={styles.row}
          gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
        >
          <Col style={styles.col} lg={12} xs={24}>
            <BookingActivity />
          </Col>
          <Col style={styles.col} lg={12} xs={24}>
            <TodayNextBooking />
          </Col>
        </Row>
        <Row
          style={styles.row}
          gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
        >
          <Col style={styles.col} lg={12} xs={24}>
            <TopServices />
          </Col>
          <Col style={styles.col} lg={12} xs={24}>
            <TopStaffs />
          </Col>
        </Row>
      </ContentAdminContainer>
    );
  }
}

export default DashBoard;
