import React from 'react';
import {
	Col,
	Row,
	Space,
	Timeline,
	Typography,
	Select,
	Spin,
	Descriptions,
	Statistic,
	Divider,
	Card,
	Button,
	Result,
	Modal,
} from 'antd';
import moment, { Moment } from 'moment';
import GlobalStyles, { kStyles } from 'GlobalStyles';
import { AppSelect, EmployeePickerModal, FormInModal } from 'components';
import { Dispatch } from 'redux';
import { Const, I18n, translations, _t } from 'utils';
import { connect } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import {
	BookingActions,
	BranchActions,
	CategoryActions,
	EmployeeActions,
	ServiceActions,
} from 'redux/actions';
import _ from 'lodash';
import { IApiResponse, IBookingDetail, ICustomer, IService } from 'models';
import { getServicesByCategory, searchServices } from 'redux/selectors/service';
import { AlertHelper, CurrencyHelper, FormHelper, TimeHelper } from 'helpers';
import TextArea from 'antd/lib/input/TextArea';
import { CalendarOutlined, CloseOutlined } from '@ant-design/icons';
import {
	IStylistAndBlockTimeRequest,
	IBookingSearchQuery,
	IGetBookingsRequest,
} from 'models/RequestModels';
import { IBranch } from 'models/IBranch';
import { availableTimeBlock } from 'redux/selectors/booking';
import {
	getAvailableEmployeeSortByTurn,
	numberOfBookingOfEmployee,
	getEmployeesSortByTurn,
} from 'redux/selectors/employee';
import SelectServiceModal from '../component/SelectServiceModal';
import { DatePickerModal } from '../component';
import { convertDurationToStringDetail } from 'helpers/timeHelper';
import { BookingStatus } from 'utils/Consts';
import { StringHelper } from 'helpers';
import CustomerApiService from 'services/CustomerApiService';
import { getActiveCategory } from 'redux/selectors/category';
import { DatePicker } from 'components/DatePicker/DatePicker';

const { Text, Title } = Typography;
const { Option, OptGroup } = Select;

interface IMainBookingProps
	extends ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps> {
	queries?: IBookingSearchQuery;
	canEdit?: boolean;
	isEdit?: boolean;
	isCustomer?: boolean;
	note?: string;
	onChangeNote?(note: string): void;
	onChangeDate?(date: Moment): void;
	isNewCustomer?: boolean; // check for this customer have on the database yet?
}
interface IStates {
	showTimeBlock: boolean;
	showStylistModal: boolean;
	showServiceModal: boolean;
	bookingIndex?: number;
	selectedDate: moment.Moment;
	showDatePickerModal?: boolean;
	modalEditCustomerVisible: boolean;
	modalChangeCustomerVisible: boolean;
	modalConfirmChangeCustomerVisible: boolean;
	currentCustomer?: Partial<ICustomer>;
	foundedCustomer?: Partial<ICustomer>;
	isConfirmingEditCustomer?: boolean;
}

class MainBooking extends React.Component<IMainBookingProps, IStates> {
	constructor(props: IMainBookingProps) {
		super(props);
		this.state = {
			showTimeBlock:
				!_.isEmpty(this.props.queries?.serviceId) || this.props.isEdit!,
			showStylistModal: false,
			showServiceModal: false,
			selectedDate: props.isEdit
				? moment(this.props.currentBooking?.date, 'YYYY-MM-DD:00:00:00')
				: props.queries?.date
				? moment(props.queries.date, 'MM-DD-YYYY')
				: TimeHelper.toTimeZone(new Date()),
			showDatePickerModal: false,
			modalEditCustomerVisible: false,
			modalChangeCustomerVisible: false,
			modalConfirmChangeCustomerVisible: false,
			currentCustomer: props.selectedCustomer,
			isConfirmingEditCustomer: false,
		};
	}

	componentDidMount() {
		setTimeout(() => {
			this.initialCheck();
		}, 100);
	}

	componentDidUpdate(prev: IMainBookingProps) {
		if (!_.isEmpty(this.props.currentBranch)) {
			if (prev.currentBranch?.id !== this.props.currentBranch?.id) {
				if (
					this.props.branchAction?.includes(
						BranchActions.selectBranch.actionName
					)
				) {
					this.getAvailableStylistAndTimeBlocks();
				}
			}
		}
		if (prev.services !== this.props.services) {
			if (
				this.props.currentAction['SERVICE'] ===
					ServiceActions.getAllServices.successName &&
				!this.props.isEdit
			) {
				if (!_.isEmpty(this.props.queries?.serviceId)) {
					const service = this.props.services.find(
						(service) => service.id === this.props.queries?.serviceId
					);
					const bookingDetail: Partial<IBookingDetail> = {
						item: service,
						itemId: this.props.queries?.serviceId,
						startAtExpected: !_.isEmpty(this.props.queries?.date)
							? moment(this.props.queries?.date, 'MM-DD-YYYY').toDate()
							: moment().toDate(),
					};
					this.props.changeBookingDetail({ index: 0, bookingDetail });
					return;
				}
			}
			return;
		}
		if (prev.bookingDetails !== this.props.bookingDetails) {
			if (
				this.props.currentAction['BOOKING'] ===
				BookingActions.getBookingById.successName
			) {
				this.setState(
					{
						selectedDate: moment(
							this.props.currentBooking?.date!,
							'YYYY-MM-DD:00:00:00'
						),
					},
					() => {
						this.getAvailableStylistAndTimeBlocks();
						this.getBookings();
					}
				);
				return;
			}
		}
	}

	render() {
		const container = {
			...styles.container,
			overflow: this.props.isCustomer ? 'hidden' : 'auto',
			height: 'auto',
		};
		return !_.isEmpty(this.props.services) &&
			!_.isEmpty(this.props.categories) ? (
			<Spin
				spinning={
					this.props.currentAction['BOOKING'] ===
						BookingActions.createBooking.requestName ||
					this.props.currentAction['BOOKING'] ===
						BookingActions.editBooking.requestName
				}
			>
				<Row>
					<Col lg={2} />
					<Col sm={24} lg={20}>
						<Space size="large" direction="vertical" style={container}>
							{this.props.isCustomer && this.renderCustomerInfo()}
							{this.renderDatePicker()}
							{this.props.isEdit ||
							this.checkIsWorkingDay(this.state.selectedDate || moment()) ? (
								this.renderMainContent()
							) : (
								<Result
									status="403"
									title={I18n.t(_t(translations.calendar.closed))}
								/>
							)}
							{this.renderAppointmentNote()}
							{this.renderSelectServiceModal()}
							{this.renderSelectStylistModal()}
							{this.renderDatePickerModal()}
							{!_.isEmpty(this.props.bookingDetails[0].item) &&
							this.props.isCustomer
								? this.renderTotalDuration()
								: null}
							{this.renderFormInModal()}
						</Space>
					</Col>
					<Col lg={2} />
				</Row>
			</Spin>
		) : (
			<Col style={styles.maxWidth}>
				<Spin
					tip="Loading..."
					spinning={this.props.loading}
					style={styles.loading}
				/>
			</Col>
		);
	}

	renderSelectStylistModal = () => {
		if (!this.state.showStylistModal) {
			return null;
		}
		// const bookingDetail = this.props.bookingDetails[this.state.bookingIndex!];
		// const sameServiceStylists = _.filter(
		// 	this.props.bookingDetails,
		// 	(x) => x.itemId === bookingDetail.item?.id && !_.isEmpty(x.stylistId)
		// ).map((x) => x.stylistId);
		const stylists = this.props.isCustomer
			? this.props.availableStylist[this.state.bookingIndex!]
			: _.map(
					this.props.getAvailableEmployeeSortByTurn(this.state.bookingIndex!),
					// .filter((x) => !sameServiceStylists.includes(x.id))
					(stylist) => {
						return {
							...stylist,
							turn: this.props.numberOfBookingOfEmployee(stylist.id) || 0,
						};
					}
			  );

		const selectedEmployeeId =
			this.props.bookingDetails[this.state.bookingIndex!].stylistId;
		return (
			<EmployeePickerModal
				onSelectEmployee={(employee) =>
					this.handleSelectStylist(
						_.isEmpty(employee) ? 'auto-pick' : employee!.id,
						this.state.bookingIndex!
					)
				}
				selectedEmployeeId={selectedEmployeeId}
				employeeList={stylists}
				onCancel={() => this.setState({ showStylistModal: false })}
				isLoading={
					this.props.currentAction['BOOKING'] ===
					BookingActions.getAvailableStylistAndTimeBlocks.requestName
				}
				isCheckout={
					this.props.currentBooking?.status === BookingStatus.CHECKED_IN
				}
			/>
		);
	};

	renderSelectServiceModal = () => {
		if (!this.state.showServiceModal) {
			return null;
		}
		return (
			<SelectServiceModal
				onCancel={() => this.setState({ showServiceModal: false })}
				bookingIndex={this.state.bookingIndex!}
				onSelectService={(serviceId: string, bookingIndex: number) =>
					this.handleSelectService(serviceId, bookingIndex)
				}
				bookingDetails={this.props.bookingDetails}
			/>
		);
	};

	renderCustomerInfo = () => {
		const { selectedCustomer } = this.props;
		return (
			<Col style={{ marginLeft: 31 }}>
				<Card
					title={I18n.t(_t(translations.mainBooking.customerInfo))}
					extra={
						<Title
							onClick={() => this.handleEditCustomer()}
							style={{ cursor: 'pointer' }}
							underline
							level={5}
						>
							{this.props.isNewCustomer
								? I18n.t(_t(translations.edit))
								: I18n.t(_t(translations.change))}
						</Title>
					}
				>
					<Descriptions
						column={{ xs: 1, sm: 1, md: 2 }}
						contentStyle={{ fontWeight: 'bold' }}
					>
						<Descriptions.Item
							label={I18n.t(_t(translations.mainBooking.name))}
						>
							{`${selectedCustomer?.firstName} ${
								selectedCustomer?.lastName || ''
							}`}
						</Descriptions.Item>
						<Descriptions.Item
							label={I18n.t(_t(translations.mainBooking.phone))}
						>
							{StringHelper.formatPhoneNumber(selectedCustomer?.phone)}
						</Descriptions.Item>
					</Descriptions>
				</Card>
			</Col>
		);
	};

	renderDatePicker = () => {
		return (
			<Row>
				<DatePicker
					disabledBefore
					selectedDate={this.state.selectedDate}
					onSelectDate={(date) => this.onChangeDate(date)}
				/>
				{/* <Button
					style={styles.datePickerButtonContainer}
					disabled={
						this.props.currentBooking?.status === BookingStatus.CHECKED_IN ||
						!this.props.canEdit
					}
					onClick={() => this.setState({ showDatePickerModal: true })}
				>
					<Typography.Text>
						{this.state.selectedDate!.format('dddd DD MMM,YYYY')}
					</Typography.Text>
					<CalendarOutlined style={styles.iconDatePickerContainer} />
				</Button> */}
			</Row>
		);
	};

	renderDatePickerModal = () => {
		if (!this.state.showDatePickerModal) {
			return null;
		}
		return (
			<DatePickerModal
				selectedDate={this.state.selectedDate!}
				onPickedDate={(date) => this.onChangeDate(date)}
				onCancel={() => this.setState({ showDatePickerModal: false })}
			/>
		);
	};

	renderMainContent = () => {
		const { bookingDetails } = this.props;
		return (
			<Col>
				<Timeline mode="left">
					{bookingDetails.map((bookingDetailRequest, index) => {
						if (!this.props.canEdit && _.isEmpty(bookingDetailRequest.itemId)) {
							return null;
						}
						return (
							<Timeline.Item
								key={'detail_' + index}
								dot={<Col style={styles.numberContainer}>{`${index + 1}`}</Col>}
							>
								{this.renderItemContainer(bookingDetailRequest, index)}
							</Timeline.Item>
						);
					})}
				</Timeline>
				{bookingDetails.length === 1 ? (
					<Row
						id={'row_detail_add'}
						key={'row_detail_add'}
						gutter={[16, 16]}
						style={{ justifyContent: 'flex-end', width: '100%' }}
					>
						<Title
							onClick={() => {
								this.props.addBookingDetail();
								this.setState({ bookingIndex: this.state.bookingIndex! + 1 });
							}}
							style={{ cursor: 'pointer' }}
							underline
							level={5}
						>
							{I18n.t(_t(translations.mainBooking.addService))}
						</Title>
					</Row>
				) : null}
			</Col>
		);
	};

	renderItemContainer = (
		bookingDetail: IStylistAndBlockTimeRequest,
		index: number
	) => {
		const serviceIdFromQuery = this.props.queries?.serviceId;
		// const stylistId = !_.isEmpty(bookingDetail.stylistId)
		// 	? bookingDetail.stylistId
		// 	: this.props.queries?.stylistId;
		const isSelectServiceFromQuery =
			index === 0 &&
			!_.isEmpty(serviceIdFromQuery) &&
			_.isEmpty(bookingDetail.itemId);
		const stylists = this.props.isCustomer
			? this.props.availableStylist[index]
			: this.props.allEmployee;
		// const stylistHasService = !_.isEmpty(
		// 	_.find(stylists, (stylist) => stylist.id === stylistId)
		// );
		const service = this.props.services.find(
			(service) =>
				service.id ===
				(isSelectServiceFromQuery ? serviceIdFromQuery : bookingDetail.itemId)
		);
		const isTodayBooking = moment().isSame(
			this.props.bookingDetails[0].startAtExpected,
			'day'
		);
		const serviceName = !_.isEmpty(service)
			? `${service?.name} ($${service?.price} - ${convertDurationToStringDetail(
					service?.duration!
			  )})`
			: I18n.t(_t(translations.mainBooking.selectService));
		const stylist = _.find(
			stylists,
			(stylist) => stylist.id === this.props.bookingDetails[index].stylistId
		);
		const stylistName = this.props.isCustomer
			? _.isEmpty(stylist) || _.isEmpty(bookingDetail.itemId)
				? I18n.t(_t(translations.mainBooking.chooseLater))
				: `${stylist?.firstName} ${stylist?.lastName || ''}`
			: stylist
			? `${stylist?.firstName} ${stylist?.lastName || ''} (${
					!isTodayBooking || this.props.isCustomer
						? ''
						: this.props.numberOfBookingOfEmployee(
								this.props.bookingDetails[index].stylistId!
						  )
			  })`
			: this.props.currentBooking?.status === BookingStatus.CHECKED_IN
			? I18n.t(_t(translations.mainBooking.selectBeforeCheckOut))
			: I18n.t(_t(translations.mainBooking.chooseLater));

		return (
			<Row
				id={'row_detail_' + index}
				key={'row_detail_' + index}
				gutter={[16, 16]}
				style={Object.assign(
					{
						backgroundColor:
							!_.isEmpty(service) &&
							!_.isEmpty(stylist) &&
							!_.isEmpty(stylist?.color) &&
							!this.props.isCustomer
								? stylist?.color
								: 'white',
					},
					_.isEmpty(bookingDetail.itemId) && !isSelectServiceFromQuery
						? styles.itemContainerMissing
						: styles.itemContainer
				)}
			>
				<Col xs={24} sm={24} lg={12}>
					<Title level={5}>
						{I18n.t(_t(translations.mainBooking.selectService))}
					</Title>

					<Button
						disabled={!this.props.canEdit}
						onClick={() =>
							this.setState({ showServiceModal: true, bookingIndex: index })
						}
						style={styles.selectButton}
						type={serviceName ? 'default' : 'dashed'}
						// size="large"
					>
						{serviceName}
					</Button>
				</Col>

				<Col xs={24} sm={24} lg={12}>
					<Title level={5}>
						{I18n.t(_t(translations.mainBooking.selectStylist))}
					</Title>

					<Button
						disabled={!this.props.canEdit || _.isEmpty(bookingDetail.item)}
						onClick={() => this.onOpenStylistModal(index)}
						style={styles.selectButton}
						loading={this.props.loading}
						// size="large"
					>
						{stylistName}
					</Button>
				</Col>
				{index === 0 && this.state.showTimeBlock
					? this.renderTimeBlocks()
					: null}
				{this.props.bookingDetails.length <= 1 ||
				((this.props.currentBooking?.status === Const.BookingStatus.PENDING ||
					!this.props.currentBooking) &&
					index === 0) ? null : (
					<CloseOutlined
						onClick={() => this.onDeleteChoosingService(index)}
						style={styles.closeIcon}
					/>
				)}
			</Row>
		);
	};
	renderTimeBlocks() {
		const activeBlock = {
			label: TimeHelper.getTimesFromBlock(this.props.timeActiveId)!,
			id: this.props.timeActiveId!,
		};
		return (
			<Col span={24}>
				<Row gutter={[16, 8]}>
					{this.props.currentBooking?.status === BookingStatus.CHECKED_IN
						? this.renderTimeBlock(activeBlock)
						: this.getTimeBlocks().map((time) => {
								if (
									this.props.currentBooking?.status === BookingStatus.CHECKED_IN
								) {
									return null;
								}
								return this.renderTimeBlock(time);
						  })}
				</Row>
			</Col>
		);
	}
	renderTimeBlock(time: { id: string; label: string }) {
		return (
			<Text
				id={time.id}
				key={time.id}
				disabled={
					!this.props.canEdit ||
					this.props.currentBooking?.status === BookingStatus.CHECKED_IN
				}
				onClick={(event) => {
					if (
						this.props.canEdit &&
						this.props.currentBooking?.status !== BookingStatus.CHECKED_IN
					) {
						this.onChooseTime(event);
					}
				}}
				className={
					time.id === this.props.timeActiveId
						? 'selected-time-block'
						: undefined
				}
				style={
					time.id !== this.props.timeActiveId
						? styles.timeContainer
						: { ...styles.timeContainer, ...styles.timeContainerActive }
				}
			>
				{TimeHelper.toTimeZone(time.label).format('hh:mmA')}
			</Text>
		);
	}

	renderItemService = (
		service: IService,
		bookingIndex = 0,
		bookingDetail?: IStylistAndBlockTimeRequest
	) => {
		const serviceCanChoose = this.checkServiceCanChoose(
			service.id,
			bookingIndex
		);
		if (!serviceCanChoose) {
			return null;
		}
		return (
			<Option disabled={!serviceCanChoose} key={service.id} value={service.id}>
				{`${service.name} ($${
					bookingDetail?.item?.price
				} - ${TimeHelper.convertDurationToStringDetail(service.duration)})`}
			</Option>
		);
	};
	renderAppointmentNote = () => {
		return (
			<Row style={styles.noteContainer}>
				<Col xs={24}>
					<Title level={5}>
						{I18n.t(_t(translations.bookingCustomerInfo.appointmentNote))}
					</Title>
					<TextArea
						onChange={(e) => this.props.onChangeNote!(e.target.value)}
						rows={3}
						value={this.props.note}
					/>
				</Col>
			</Row>
		);
	};

	renderTotalDuration = () => {
		const totalPrice = CurrencyHelper.formatPrice(
			_.sumBy(
				this.props.bookingDetails,
				(bookingDetail) => bookingDetail.price || bookingDetail.item?.price!
			)
		);
		const totalDuration = TimeHelper.convertDurationToStringDetail(
			_.sumBy(
				this.props.bookingDetails,
				(bookingDetail) => bookingDetail.item?.duration!
			)
		);
		return (
			<>
				<Divider />
				<Row style={styles.totalDuration} gutter={[16, 16]} justify="end">
					<Col>
						<Statistic
							title={I18n.t(_t(translations.bookingCustomerInfo.total))}
							value={`${totalPrice} (${totalDuration})`}
						/>
					</Col>
				</Row>
			</>
		);
	};

	renderFormInModal() {
		return (
			<>
				<FormInModal
					visible={this.state.modalEditCustomerVisible}
					title={I18n.t(_t(translations.bookingCustomerInfo.editCustomer))}
					onCancel={() =>
						this.setState({
							modalEditCustomerVisible: false,
							currentCustomer: this.props.selectedCustomer,
						})
					}
					onOk={(value: any) => this.onEditCustomer(value)}
					centered
					// isAdd={false}
					isEditNewCustomer={true}
					isConfirming={this.state.isConfirmingEditCustomer}
				>
					{this.renderFormName()}
					<Divider style={styles.divider} />
					{this.renderFormContact()}
				</FormInModal>
				{this.renderChangeCustomerModal()}
				{this.renderConfirmChangeCustomerModal()}
			</>
		);
	}
	renderFormName() {
		const { currentCustomer } = this.state;
		return (
			<Row gutter={[16, 24]}>
				<Col sm={24} md={12} style={styles.noPaddingCol}>
					{FormHelper.renderFormInput(
						'firstName',
						[
							{
								required: true,
								message: I18n.t(_t(translations.staffDetail.messageFirstName)),
							},
						],
						I18n.t(_t(translations.staffDetail.firstName)),
						currentCustomer?.firstName,
						{},
						{
							placeholder: I18n.t(_t(translations.placeHolder.firstName)),
							maxLength: 256,
						}
					)}
				</Col>
				<Col sm={24} md={12} style={styles.noPaddingCol}>
					{FormHelper.renderFormInput(
						'lastName',
						[
							{
								required: false,
								message: I18n.t(_t(translations.staffDetail.messageLastName)),
							},
						],
						I18n.t(_t(translations.staffDetail.lastNameOptional)),
						currentCustomer?.lastName,
						{},
						{
							placeholder: I18n.t(_t(translations.placeHolder.lastName)),
							maxLength: 256,
						}
					)}
				</Col>
			</Row>
		);
	}

	renderFormContact() {
		const { currentCustomer } = this.state;
		return (
			<Row gutter={[16, 16]}>
				<Col sm={24} md={12} style={styles.noPaddingCol}>
					{FormHelper.renderFormInput(
						'email',
						[
							{
								required: false,
								message: I18n.t(_t(translations.staffDetail.messageEmail)),
								type: 'email',
							},
						],
						I18n.t(_t(translations.emailOptional)),
						currentCustomer?.email,
						{},
						{
							placeholder: I18n.t(_t(translations.placeHolder.email)),
							maxLength: 256,
						}
					)}
				</Col>
				<Col sm={24} md={12} style={styles.noPaddingCol}>
					{FormHelper.renderFormPhoneInput(
						'phone',
						[
							{
								required: true,
								message: I18n.t(
									_t(translations.staffDetail.messageCustomerPhone)
								),
							},
						],
						I18n.t(_t(translations.staffDetail.phone)),
						currentCustomer?.phone,
						{},
						{
							placeholder: I18n.t(_t(translations.placeHolder.phone)),
						}
					)}
				</Col>
			</Row>
		);
	}

	renderChangeCustomerModal() {
		return (
			<Modal
				title={I18n.t(_t(translations.mainBooking.changeAnotherPhone))}
				visible={this.state.modalChangeCustomerVisible}
				onOk={() =>
					this.setState({ modalChangeCustomerVisible: false }, () =>
						this.props.removeCustomer()
					)
				}
				onCancel={() => this.setState({ modalChangeCustomerVisible: false })}
			>
				<Typography.Text>
					{I18n.t(_t(translations.mainBooking.changeAnotherPhone))}
				</Typography.Text>
			</Modal>
		);
	}

	renderConfirmChangeCustomerModal() {
		return (
			<Modal
				title={I18n.t(_t(translations.warning))}
				visible={this.state.modalConfirmChangeCustomerVisible}
				okText={I18n.t(_t(translations.mainBooking.changeToThisPhone))}
				onOk={() =>
					this.setState(
						{
							modalConfirmChangeCustomerVisible: false,
							modalEditCustomerVisible: false,
						},
						() => {
							this.props.setSelectedCustomer(this.state.currentCustomer!);
							this.props.setIsNewCustomer(false);
						}
					)
				}
				onCancel={() =>
					this.setState({
						modalConfirmChangeCustomerVisible: false,
						currentCustomer: this.props.selectedCustomer,
					})
				}
				width={500}
				centered
				zIndex={9999}
			>
				<Typography.Text>
					{I18n.t(_t(translations.mainBooking.changeToExitedPhone))}
				</Typography.Text>
			</Modal>
		);
	}

	handleEditCustomer = () => {
		return this.props.isNewCustomer
			? this.setState({ modalEditCustomerVisible: true })
			: this.setState({ modalChangeCustomerVisible: true });
	};

	onEditCustomer = async (value: any) => {
		if (value.phone === this.state.currentCustomer?.phone) {
			this.setState(
				{
					modalEditCustomerVisible: false,
				},
				() => this.props.setSelectedCustomer(value as Partial<ICustomer>)
			);
		} else {
			this.setState(
				{ currentCustomer: value, isConfirmingEditCustomer: true },
				async () => {
					const result = (await CustomerApiService.getCustomerByPhone(
						StringHelper.convertToRawPhone(this.state.currentCustomer!.phone!)!
					)) as IApiResponse<ICustomer>;
					if (result.succeeded) {
						if (result.data!) {
							this.setState({
								modalConfirmChangeCustomerVisible: true,
								currentCustomer: result.data!,
							});
						} else {
							this.setState(
								{
									modalEditCustomerVisible: false,
								},
								() =>
									this.props.setSelectedCustomer(value as Partial<ICustomer>)
							);
						}
					}
					this.setState({ isConfirmingEditCustomer: false });
				}
			);
		}
	};

	handleSelectService = (serviceId: string, bookingDetailIndex: number) => {
		this.setState({ showTimeBlock: true, showServiceModal: false }, () => {
			const service = this.props.services.find(
				(service) => service.id === serviceId
			);
			const bookingDetail: Partial<IBookingDetail> = {
				item: service,
				itemId: serviceId,
			};
			const stylistAndTimeBlockRequest: IStylistAndBlockTimeRequest = {
				serviceId: serviceId,
				stylistId: !bookingDetailIndex
					? this.props.bookingDetails[bookingDetailIndex].stylistId
					: undefined,
			};
			this.onChangeBookingDetail(bookingDetail, bookingDetailIndex);
			this.getAvailableStylistAndTimeBlocks(
				stylistAndTimeBlockRequest,
				bookingDetailIndex
			);
		});
	};

	checkServiceCanChoose(serviceId: string, bookingIndex: number): boolean {
		let serviceCanChoose = true;
		for (let i = 0; i < this.props.bookingDetails.length; i++) {
			if (
				i !== bookingIndex &&
				this.props.bookingDetails[i].itemId === serviceId
			) {
				serviceCanChoose = false;
				return false;
			}
		}
		return serviceCanChoose;
	}
	onOpenStylistModal(index: number) {
		const { bookingDetails, timeActiveId } = this.props;
		let time = TimeHelper.getTimesFromBlock(timeActiveId);
		if (index !== 0) {
			const newTime = moment(
				TimeHelper.getTimesFromBlock(timeActiveId),
				'YYYY-MM-DDTHH:mm'
			)
				.add(bookingDetails[index - 1].item?.duration, 'minute')
				.format('YYYY-MM-DDTHH:mm');
			time = newTime;
		}
		const stylistAndTimeBlockRequest: IStylistAndBlockTimeRequest = {
			serviceId: bookingDetails[index].itemId,
			time: time,
		};
		this.getAvailableStylistAndTimeBlocks(
			stylistAndTimeBlockRequest,
			index,
			true
		);
		this.setState({ showStylistModal: true, bookingIndex: index });
	}

	handleSelectStylist(stylistId: string, bookingDetailIndex: number) {
		this.setState({ showStylistModal: false });
		const bookingDetail: Partial<IBookingDetail> = {
			stylistId: stylistId === 'auto-pick' ? undefined : stylistId,
		};
		const stylistAndTimeBlockRequest: IStylistAndBlockTimeRequest = {
			stylistId: stylistId === 'auto-pick' ? undefined : stylistId,
		};
		this.onChangeBookingDetail(bookingDetail, bookingDetailIndex);
		if (bookingDetailIndex === 0) {
			this.getAvailableStylistAndTimeBlocks(
				stylistAndTimeBlockRequest,
				bookingDetailIndex
			);
		}
	}
	onChangeDate = (date: Moment) => {
		if (date.endOf('date').isBefore(moment())) {
			AlertHelper.showError({
				message: [{ Text: I18n.t(_t(translations.mainBooking.notValidDate)) }],
			});
		} else {
			this.setState({ selectedDate: date, showDatePickerModal: false }, () => {
				const bookingDetail: Partial<IBookingDetail> = {
					startAtExpected: date.toDate(),
				};
				this.getBookings();
				this.props.selectTimeStart('');
				this.onChangeBookingDetail(bookingDetail);
				const stylistAndTimeBlockRequest: IStylistAndBlockTimeRequest = {
					date: date.format('MM-DD-YYYY'),
				};
				this.getAvailableStylistAndTimeBlocks(stylistAndTimeBlockRequest);
			});
			if (this.props.onChangeDate) {
				this.props.onChangeDate(date);
			}
		}
	};

	onChooseTime = (event: any) => {
		this.props.selectTimeStart(event.target.id);
		const time = TimeHelper.getTimesFromBlock(event.target.id);
		const stylistAndTimeBlockRequest: IStylistAndBlockTimeRequest = {
			time: `${time}`,
			stylistId: this.props.bookingDetails[0].stylistId,
		};
		this.getAvailableStylistAndTimeBlocks(stylistAndTimeBlockRequest);
	};
	onChangeBookingDetail(bookingDetail: Partial<IBookingDetail>, index = 0) {
		this.props.changeBookingDetail({ bookingDetail, index });
	}
	onDeleteChoosingService = (index: number) => {
		this.props.deleteChoosingService(index);
	};

	getAvailableStylistAndTimeBlocks(
		stylistAndTimeBlockRequest?: Partial<IStylistAndBlockTimeRequest>,
		index = 0,
		noStylist = false
	) {
		const bookingDetail: IStylistAndBlockTimeRequest = {
			date: this.state.selectedDate.format('MM-DD-YYYY'),
			serviceId: this.props.bookingDetails[0].itemId,
			branchId: this.props.currentBranch?.id,
			shopId: this.props.currentBranch?.shopId,
			bookingId: this.props.currentBooking?.id,
			stylistId: noStylist
				? undefined
				: this.props.bookingDetails[0].stylistId || undefined,
			...stylistAndTimeBlockRequest,
		};
		this.props.getAvailableStylistAndTimeBlocks({
			bookingDetail,
			index,
		});
	}

	initialCheck() {
		// if (this.props.isCustomer) {
		// 	this.props.getAllCategory();
		// 	this.props.getAllServices();
		// 	this.props.getAllEmployee();
		// }
		if (!this.props.isEdit) {
			this.getBookings();
		}
		if (!this.props.isEdit) {
			this.getBookingFromQueries();
		}
	}
	checkIsWorkingDay(selectedDate: Moment) {
		const dayIndex = selectedDate.day();
		const workingDayTime = _.find(this.props.currentBranch?.workingHours, (x) =>
			x.days?.includes(dayIndex.toString())
		);
		if (!workingDayTime) {
			return false;
		}
		return true;
	}
	getBookings() {
		const request: IGetBookingsRequest = {
			fromDate: this.state.selectedDate?.format('MM-DD-YYYY')!,
			toDate: this.state.selectedDate?.format('MM-DD-YYYY')!,
		};
		this.props.getBookings(request);
	}
	getTimeBlocks() {
		let timeBlocks = _.clone(
			TimeHelper.generateTimeBlocks(
				this.props.timeBlocks.filter(
					(x) => !x.noStylistsAvailable && !x.unavailable
				)
			)
		);
		if (this.props.timeActiveId) {
			const activeBlock = {
				label: TimeHelper.getTimesFromBlock(this.props.timeActiveId)!,
				id: this.props.timeActiveId!,
			};
			const hasBlock = !_.isEmpty(
				_.find(timeBlocks, (x) => x.id === activeBlock.id)
			);
			if (!hasBlock) {
				timeBlocks.push(activeBlock);
				timeBlocks = _.orderBy(timeBlocks, (x) => moment(x.label).unix());
			}
		}
		return timeBlocks;
	}
	getBookingFromQueries() {
		const bookingDetail: Partial<IBookingDetail> = {
			startAtExpected: !_.isEmpty(this.props.queries?.date)
				? TimeHelper.toTimeZone(
						moment(this.props.queries?.date, 'MM-DD-YYYY').toDate()
				  ).toDate()
				: TimeHelper.toTimeZone(moment().toDate()).toDate(),
			stylistId: this.props.queries?.stylistId,
		};
		this.onChangeBookingDetail(bookingDetail);
		if (!_.isEmpty(this.props.queries?.time)) {
			const timeInTimeZone = moment(this.props.queries?.time, 'HHmm').format(
				`${this.state.selectedDate?.format('YYYY-MM-DD')}THH:mm`
			);

			const timeInUtc = TimeHelper.fromTimeZoneStringToUtc(timeInTimeZone);
			this.props.selectTimeStart(TimeHelper.generateTimeBlockId(timeInUtc));
		}
		if (!_.isEmpty(this.props.queries?.serviceId)) {
			this.getAvailableStylistAndTimeBlocks({
				serviceId: this.props.queries?.serviceId,
				date: !_.isEmpty(this.props.queries?.date)
					? this.props.queries?.date
					: moment().format('MM-DD-YYYY'),
			});
		}
	}
}

const mapStateToProps = (state: RootState) => ({
	services: state.ServiceReducer.services,
	loading: state.AppConfigReducer.showLoading,
	categories: getActiveCategory(state),
	availableStylist: state.BookingReducer.availableStylist,
	currentAction: state.ReduxActionReducer,
	currentBranch: state.BranchReducer.currentBranch,
	bookingDetails: state.BookingReducer.bookingDetails,
	currentBooking: state.BookingReducer.currentBooking,
	timeActiveId: state.BookingReducer.timeActiveId,
	allEmployee: state.EmployeeReducer.employees,
	selectedCustomer: state.BookingReducer.selectedCustomer,
	branchAction: state.ReduxActionReducer['BRANCH'],
	timeBlocks: availableTimeBlock(state),
	getAvailableEmployeeSortByTurn: (index: number) =>
		getAvailableEmployeeSortByTurn(state, index, state.BookingReducer.bookings),
	getEmployeesSortByTurn: () =>
		getEmployeesSortByTurn(state, state.BookingReducer.bookings),
	numberOfBookingOfEmployee: (employeeId: string) =>
		numberOfBookingOfEmployee(state.BookingReducer.bookings, employeeId),
	servicesByCategory: (
		categoryId: string,
		services: IService[] = state.ServiceReducer.services
	) => getServicesByCategory(categoryId, services),
	searchServices: (textInput: string) => searchServices(state, textInput),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	getAllServices: () => {
		dispatch(ServiceActions.getAllServices.request());
	},
	getAllCategory: () => {
		dispatch(CategoryActions.getAllCategory.request());
	},
	getAvailableStylistAndTimeBlocks: (params: {
		index: number;
		bookingDetail: IStylistAndBlockTimeRequest;
	}) => {
		dispatch(BookingActions.getAvailableStylistAndTimeBlocks.request(params));
	},
	selectBranch: (branch: Partial<IBranch>) => {
		dispatch(BranchActions.selectBranch.request(branch));
	},
	changeBookingDetail: (params: {
		index: number;
		bookingDetail: Partial<IBookingDetail>;
	}) => {
		dispatch(BookingActions.changeBookingDetail.request(params));
	},
	getAllEmployee: () => {
		dispatch(EmployeeActions.getAllEmployees.request());
	},
	addBookingDetail: () => {
		dispatch(BookingActions.addBookingDetail.request());
	},
	deleteChoosingService: (index: number) =>
		dispatch(BookingActions.deleteChoosingService.request(index)),
	selectTimeStart: (timeId: string) =>
		dispatch(BookingActions.selectTimeStart.request(timeId)),
	getBookings: (request: IGetBookingsRequest) => {
		dispatch(BookingActions.getBookingsByBranch.request(request));
	},
	removeCustomer: () => {
		dispatch(BookingActions.removeCustomer.request());
	},
	getCustomer: (request: string) => {
		dispatch(BookingActions.getCustomerByPhone.request(request));
	},
	setSelectedCustomer: (request: Partial<ICustomer>) => {
		dispatch(BookingActions.selectCustomer.request(request));
	},
	setIsNewCustomer: (request: boolean) => {
		dispatch(BookingActions.setIsNewCustomer.request(request));
	},
});

const styles: kStyles = {
	container: {
		backgroundColor: 'white',
		padding: 10,
		width: '100%',
		height: '100vh',
		paddingBottom: 'auto',
	},
	closeIcon: {
		position: 'absolute',
		top: 10,
		right: 20,
		color: 'red',
		fontSize: 20,
	},
	numberContainer: {
		borderColor: 'black',
		borderWidth: 1,
		width: 20,
		height: 20,
		textAlign: 'center',
		justifyContent: 'center',
		borderStyle: 'solid',
		padding: 2,
		borderRadius: 10,
	},
	totalDuration: {
		// ...GlobalStyles.boxShadow,
		marginInline: 8,
		// padding: "16px 4px",
		marginLeft: 32,
	},
	maxWidth: { width: '100%' },
	noteContainer: {
		// width: "100%",
		marginLeft: 32,
	},
	timeContainer: {
		borderColor: 'black',
		borderWidth: 1,
		textAlign: 'center',
		justifyContent: 'center',
		borderStyle: 'solid',
		padding: 10,
		cursor: 'pointer',
		marginInline: 8,
		width: 100,
		borderRadius: 4,
	},
	timeContainerActive: {
		// backgroundColor: 'black',
		fontWeight: 'bold',
		color: 'white',
		borderWidth: 0,
	},
	itemContainer: {
		...GlobalStyles.boxShadow,
		marginInline: 8,
		padding: '16px 4px',
	},
	itemContainerMissing: {
		marginInline: 8,
		padding: '16px 4px',
		backgroundColor: '#f7f7f8',
	},
	descriptionLabelStyle: {
		fontSize: 16,
	},
	descriptionContentStyle: {
		fontWeight: 'bold',
		fontSize: 16,
		textAlign: 'right',
	},
	loading: { margin: '10% 50%' },
	btnConfirm: {
		fontSize: 18,
		marginBottom: 16,
	},
	selectButton: {
		width: '100%',
		textAlign: 'left',
	},
	datePickerButtonContainer: { marginLeft: 31, borderRadius: 2, minWidth: 210 },
	iconDatePickerContainer: { marginLeft: 20 },
};

export default connect(mapStateToProps, mapDispatchToProps)(MainBooking);
