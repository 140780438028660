import { SearchOutlined } from '@ant-design/icons';
import {
	Button,
	Card,
	Col,
	Divider,
	Form,
	FormInstance,
	Input,
	Row,
	Table,
	Typography,
} from 'antd';
import styles from 'components/styles';
import React, { Component, useMemo, useRef, useState } from 'react';
import { translations, I18n, _t, Colors } from 'utils';
import AddPaymentMethodModal, {
	PaymentMethodFormDataType,
} from './components/AddPaymentMethodModal';
import { Dispatch } from 'redux';
import { IPaymentMethod } from 'models';
import { PaymentMethodActions } from 'redux/actions';
import { connect } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { PaymentType } from 'utils/Consts';
import { searchPaymentMethods } from 'redux/selectors/search';
import './style.css';
import { FormHelper } from 'helpers';
import AppTable, { IColumnProps, ITableRef } from 'components/Table';

interface IPaymentMethodProps
	extends ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps> {
	formRef?: FormInstance;
}
interface IPaymentMethodState {
	searchText: string;
	isAddNewPaymentMethod: boolean;
	isAdd: boolean;
	rowDataPaymentMethod: any;
	isShowPaymentReferenceCodeWarning: boolean;
}

// class PaymentMethod extends Component<
// 	IPaymentMethodProps,
// 	IPaymentMethodState
// > {
// 	constructor(props: any) {
// 		super(props);
// 		this.state = {
// 			searchText: '',
// 			isAddNewPaymentMethod: false,
// 			isAdd: false,
// 			rowDataPaymentMethod: {},
// 			isShowPaymentReferenceCodeWarning:
// 				!this.props.paymentReferenceCodeRequiring,
// 		};
// 	}

// 	componentDidUpdate(prevProps: IPaymentMethodProps) {
// 		if (
// 			prevProps.paymentMethodAction !== this.props.paymentMethodAction &&
// 			this.props.paymentMethodAction?.includes('SUCCESS')
// 		) {
// 			this.setState({ isAddNewPaymentMethod: false });
// 		}
// 	}

// 	render() {
// 		const columns = [
// 			{
// 				title: (
// 					<Typography.Title level={5}>
// 						{I18n.t(_t(translations.branchSetting.paymentMethod))}
// 					</Typography.Title>
// 				),
// 				dataIndex: 'name',
// 				render: (text: string) => {
// 					return <Typography.Text style={styles.link}>{text}</Typography.Text>;
// 				},
// 				className: 'methodNameCell',
// 			},
// 			{
// 				title: (
// 					<Typography.Title level={5}>
// 						{I18n.t(_t(translations.branchSetting.paymentMethodCode))}
// 					</Typography.Title>
// 				),
// 				dataIndex: 'code',
// 				render: (text: string) => {
// 					return <Typography.Text style={styles.link}>{text}</Typography.Text>;
// 				},
// 				className: 'methodCodeCell',
// 			},
// 			{
// 				title: (
// 					<Typography.Title level={5}>
// 						{I18n.t(_t(translations.description))}
// 					</Typography.Title>
// 				),
// 				dataIndex: 'description',
// 				render: (text: string) => {
// 					return <Typography.Text style={styles.link}>{text}</Typography.Text>;
// 				},
// 				className: 'methodDesciptionCell',
// 			},
// 			{
// 				title: (
// 					<Typography.Title level={5}>
// 						{I18n.t(_t(translations.branchSetting.paymentMethodStatus))}
// 					</Typography.Title>
// 				),
// 				dataIndex: 'status',
// 				render: (status: number) => {
// 					const isActive = status === 1;
// 					return (
// 						<Typography.Text
// 							style={{
// 								...styles.link,
// 								color: isActive ? Colors.GREEN : Colors.RED_BERRY,
// 							}}
// 						>
// 							{isActive
// 								? I18n.t(_t(translations.active))
// 								: I18n.t(_t(translations.unActive))}
// 						</Typography.Text>
// 					);
// 				},
// 				className: 'methodStatusCell',
// 				align: 'center' as const,
// 			},
// 		];
// 		return (
// 			<>
// 				<Card>
// 					<Card.Meta
// 						title={I18n.t(_t(translations.branchSetting.paymentConfig))}
// 						description={I18n.t(
// 							_t(translations.branchSetting.subPaymentConfig)
// 						)}
// 					/>
// 					<Divider style={styles.divider} />
// 					{FormHelper.renderFormSwitch(
// 						'paymentReferenceCodeRequiring',
// 						I18n.t(_t(translations.branchSetting.referenceCodeRequiring)),
// 						this.props.paymentReferenceCodeRequiring || false,
// 						undefined,
// 						{
// 							onChange: (checked) =>
// 								this.setState({ isShowPaymentReferenceCodeWarning: !checked }),
// 						},
// 						{ style: { marginBottom: 0 } }
// 					)}
// 					{this.state.isShowPaymentReferenceCodeWarning && (
// 						<div className="warning-text">
// 							<Typography.Text>
// 								{I18n.t(_t(translations.branchSetting.referenceCodeWarning))}
// 							</Typography.Text>
// 						</div>
// 					)}
// 					<Form.Item
// 						label={
// 							<Typography.Title level={5}>
// 								{I18n.t(_t(translations.branchSetting.paymentMethod))}
// 							</Typography.Title>
// 						}
// 					>
// 						<Row
// 							gutter={[16, 16]}
// 							style={{ alignItems: 'center', paddingBottom: 20 }}
// 						>
// 							<Col flex={1}>
// 								<Input
// 									allowClear
// 									prefix={<SearchOutlined />}
// 									style={style.width100}
// 									placeholder={I18n.t(_t(translations.customerList.search))}
// 									onChange={(e) =>
// 										this.setState({ searchText: e.target.value })
// 									}
// 									maxLength={256}
// 								/>
// 							</Col>
// 							<Col>
// 								<Button
// 									type="primary"
// 									size="middle"
// 									onClick={(e) =>
// 										this.setState({
// 											isAddNewPaymentMethod: true,
// 											isAdd: true,
// 											rowDataPaymentMethod: {},
// 										})
// 									}
// 								>
// 									{I18n.t(_t(translations.addNew))}
// 								</Button>
// 							</Col>
// 						</Row>
// 						<Table
// 							style={{ paddingBottom: 24, cursor: 'pointer' }}
// 							// scroll={{ x: true }}
// 							size="large"
// 							dataSource={this.props.paymentMethods(this.state.searchText)}
// 							pagination={false}
// 							columns={columns}
// 							onRow={this.onClickRow}
// 						/>
// 					</Form.Item>
// 				</Card>
// 				{this.state.isAddNewPaymentMethod && (
// 					<AddPaymentMethodModal
// 						onCancel={() => this.setState({ isAddNewPaymentMethod: false })}
// 						onFormFinish={this.onAddPaymentMethod}
// 						onDelete={this.onDelete}
// 						isLoading={this.props.isLoading}
// 						isAdd={this.state.isAdd}
// 						dataPaymentMethod={this.state.rowDataPaymentMethod}
// 					/>
// 				)}
// 			</>
// 		);
// 	}

// 	onAddPaymentMethod = (data: PaymentMethodFormDataType) => {
// 		if (this.state.isAdd) {
// 			this.props.createPaymentMethod({
// 				...data,
// 				status: data.status ? 1 : 0,
// 				paymentType: PaymentType.OTHER,
// 			});
// 		} else {
// 			this.props.updatePaymentMethod({
// 				...data,
// 				id: data.id,
// 				status: data.status ? 1 : 0,
// 				paymentType: PaymentType.OTHER,
// 			});
// 		}
// 	};

// 	onDelete = (id: string) => {
// 		this.props.deletePaymentMethod(id);
// 	};

// 	onClickRow = (record: any) => {
// 		return {
// 			onClick: (event: any) => {
// 				this.setState({
// 					isAddNewPaymentMethod: true,
// 					isAdd: false,
// 					rowDataPaymentMethod: record,
// 				});
// 			},
// 		};
// 	};
// }

const PaymentMethod = (props: IPaymentMethodProps) => {
	const [isAddNewPaymentMethod, setIsAddNewPaymentMethod] = useState(false);
	const [isAdd, setIsAdd] = useState(false);
	const [
		isShowPaymentReferenceCodeWarning,
		setIsShowPaymentReferenceCodeWarning,
	] = useState(!props.paymentReferenceCodeRequiring);
	const [searchText, setSearchText] = useState('');
	const [rowDataPaymentMethod, setRowDataPaymentMethod] = useState<any>({});
	const tableRef = useRef<ITableRef>();

	const onAddPaymentMethod = (data: PaymentMethodFormDataType) => {
		if (isAdd) {
			props.createPaymentMethod({
				...data,
				status: data.status ? 1 : 0,
				paymentType: PaymentType.OTHER,
			});
		} else {
			props.updatePaymentMethod({
				...data,
				id: data.id,
				status: data.status ? 1 : 0,
				paymentType: PaymentType.OTHER,
			});
		}
		setIsAddNewPaymentMethod(false);
	};

	const onDelete = (id: string) => {
		props.deletePaymentMethod(id);
		setIsAddNewPaymentMethod(false);
	};

	const onClickRow = (record: any) => {
		setIsAddNewPaymentMethod(true);
		setIsAdd(false);
		setRowDataPaymentMethod(record);
	};

	const columns = useMemo<IColumnProps<IPaymentMethod>[]>(() => {
		return [
			{
				header: I18n.t(_t(translations.branchSetting.paymentMethod)),
				key: 'name',
				type: 'text',
			},
			{
				header: I18n.t(_t(translations.branchSetting.paymentMethodCode)),
				key: 'code',
				type: 'text',
			},
			{
				header: I18n.t(_t(translations.description)),
				key: 'description',
				type: 'text',
			},
			{
				header: I18n.t(_t(translations.branchSetting.paymentMethodStatus)),
				key: 'status',
				renderCell: (record) => {
					const isActive = record!.status === 1;
					return (
						<Typography.Text
							style={{
								...styles.link,
								color: isActive ? Colors.GREEN : Colors.RED_BERRY,
							}}
						>
							{isActive
								? I18n.t(_t(translations.active))
								: I18n.t(_t(translations.unActive))}
						</Typography.Text>
					);
				},
				align: 'center',
			},
		];
	}, []);

	return (
		<>
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.branchSetting.paymentConfig))}
					description={I18n.t(_t(translations.branchSetting.subPaymentConfig))}
				/>
				<Divider style={styles.divider} />
				{FormHelper.renderFormSwitch(
					'paymentReferenceCodeRequiring',
					I18n.t(_t(translations.branchSetting.referenceCodeRequiring)),
					props.paymentReferenceCodeRequiring || false,
					undefined,
					{
						onChange: (checked) =>
							setIsShowPaymentReferenceCodeWarning(!checked),
					},
					{ style: { marginBottom: 0 } }
				)}
				{isShowPaymentReferenceCodeWarning && (
					<div className="warning-text">
						<Typography.Text>
							{I18n.t(_t(translations.branchSetting.referenceCodeWarning))}
						</Typography.Text>
					</div>
				)}
				<Form.Item
					label={
						<Typography.Title level={5}>
							{I18n.t(_t(translations.branchSetting.paymentMethod))}
						</Typography.Title>
					}
				>
					<Row
						gutter={[16, 16]}
						style={{ alignItems: 'center', paddingBottom: 20 }}
					>
						<Col flex={1}>
							<Input
								allowClear
								prefix={<SearchOutlined />}
								style={style.width100}
								placeholder={I18n.t(_t(translations.customerList.search))}
								onChange={(e) => setSearchText(e.target.value)}
								maxLength={256}
							/>
						</Col>
						<Col>
							<Button
								type="primary"
								size="middle"
								onClick={(e) => {
									setIsAddNewPaymentMethod(true);
									setIsAdd(true);
									setRowDataPaymentMethod({});
								}}
							>
								{I18n.t(_t(translations.addNew))}
							</Button>
						</Col>
					</Row>
					<AppTable<IPaymentMethod>
						ref={(ref) => {
							if (ref) {
								tableRef.current = ref;
							}
						}}
						queryUrl=""
						data={props.paymentMethods(searchText)}
						columns={columns}
						onClickRow={onClickRow}
					/>
				</Form.Item>
			</Card>
			{isAddNewPaymentMethod && (
				<AddPaymentMethodModal
					onCancel={() => setIsAddNewPaymentMethod(false)}
					onFormFinish={onAddPaymentMethod}
					onDelete={onDelete}
					isLoading={props.isLoading}
					isAdd={isAdd}
					dataPaymentMethod={rowDataPaymentMethod}
				/>
			)}
		</>
	);
};

const style = { width100: { width: '100%' } };

const mapStateToProps = (state: RootState) => ({
	isLoading: state.AppConfigReducer.showLoading,
	paymentMethods: (searchText: string) =>
		searchPaymentMethods(state, searchText),
	paymentMethodAction: state.ReduxActionReducer['PAYMENT_METHODS'],
	paymentReferenceCodeRequiring:
		state.BranchReducer.currentBranch?.paymentReferenceCodeRequiring,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	createPaymentMethod: (request: Partial<IPaymentMethod>) => {
		dispatch(PaymentMethodActions.createPaymentMethod.request(request));
	},
	updatePaymentMethod: (request: IPaymentMethod) => {
		dispatch(PaymentMethodActions.updatePaymentMethod.request(request));
	},
	deletePaymentMethod: (request: string) => {
		dispatch(PaymentMethodActions.deletePaymentMethod.request(request));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
