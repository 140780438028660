import React from "react";
import { Tabs } from "antd";
import { I18n, _t, translations } from "utils";
import StaffMembers from "pages/Staff/components/StaffMembers";
import { ContentAdminContainer } from "components";

const { TabPane } = Tabs;

class StaffPage extends React.Component {
  render() {
    return <ContentAdminContainer>{this.renderTabs()}</ContentAdminContainer>;
  }
  _onChangeTab(key: string) {}
  renderTabs() {
    return (
      <Tabs onChange={this._onChangeTab} type="card">
        {/* <TabPane tab={I18n.t(_t(translations.staff.staffWorkingHourTab))} key="Tab1">
          <WorkingHours />
        </TabPane>
        <TabPane tab={I18n.t(_t(translations.staff.holidayTab))} key="Tab2">
          <ShopHoliday />
        </TabPane> */}
        <TabPane tab={I18n.t(_t(translations.staff.staffMemberTab))} key="Tab3">
          <StaffMembers />
        </TabPane>
      </Tabs>
    );
  }
}

export default StaffPage;
