import './styles.css';
import { Component, ReactElement } from 'react';
import { Card, Row, Col, Typography, Space, Layout, Button } from 'antd';
import { I18n, _t, translations } from 'utils';
import GlobalStyles from 'GlobalStyles';
import styles from './styles';
import {
	SlidersOutlined,
	SettingOutlined,
	PhoneOutlined,
} from '@ant-design/icons';
import { AlertHelper, FormHelper } from 'helpers';
import _ from 'lodash';
import ContactSupportFormModal from './components/ContactSupportFormModal';
import { Content } from 'antd/lib/layout/layout';
import EmployeeTypeApiService from 'services/EmployeeTypeApiService';
import SupportMessageApiService from 'services/SupportMessageApiService';
import { IApiResponse } from 'models';
import { history } from 'routers';
import { Header } from 'components';
import { CallSupportModal } from './components/CallSuportModal';
const { Title, Link } = Typography;

interface ISettingState {
	showHelpCenterModal: boolean;
}

class HelpCenterPage extends Component<any, ISettingState> {
	state = {
		showHelpCenterModal: false,
	};

	render() {
		return (
			<Layout style={{ minHeight: '100vh', backgroundColor: 'white' }}>
				{this.renderHeader()}
				{this.renderContent()}
				{this.renderContactSupportModal()}
			</Layout>
		);
	}

	renderHeader = () => {
		return (
			<Header
				detailPageProps={{
					title: I18n.t(_t(translations.profileMenu.helpCenter)),
					onClosePress: () => history.goBack(),
				}}
			/>
		);
	};

	renderContent = () => {
		return (
			<Content style={{ marginTop: 64, height: '100%' }}>
				<Space
					style={{ justifyContent: 'center', width: '100%', paddingTop: 16 }}
					direction="vertical"
				>
					<Row style={{ padding: 16 }}>
						<Col span={24}>
							<Row style={{ width: '100%' }}>
								<Col style={{ padding: 8 }} span={24} lg={6}>
									{this.renderItem(
										I18n.t(_t(translations.profileMenu.contactSupport)),
										<PhoneOutlined
											style={{ ...GlobalStyles.defaultIconSize }}
										/>,
										() => {
											this.onOpenContactSupport();
										}
									)}
								</Col>
								<Col style={{ padding: 8 }} span={24} lg={6}>
									{this.renderItem(
										I18n.t(_t(translations.helpCenter.userGuide)),
										<SlidersOutlined
											style={{ ...GlobalStyles.defaultIconSize }}
										/>,
										() => {},
										'/user-guide'
									)}
								</Col>
							</Row>
						</Col>
						<Col lg={0} xl={6} />
					</Row>
				</Space>
			</Content>
		);
	};

	renderContactSupportModal = () => {
		return (
			<CallSupportModal
				visible={this.state.showHelpCenterModal}
				onCancel={() => this.setState({ showHelpCenterModal: false })}
			/>
		);
	};

	renderItem = (
		title: string,
		icon: ReactElement,
		onClick: () => void,
		href?: string
	) => {
		return (
			<Link onClick={onClick} style={styles.cardLink} href={href}>
				<Card
					style={styles.cardOption}
					onMouseEnter={(e) => {
						e.currentTarget.style.boxShadow = styles.hoverBoxShadow.boxShadow!;
					}}
					onMouseLeave={(e) => {
						e.currentTarget.style.boxShadow = GlobalStyles.boxShadow.boxShadow!;
					}}
					title={<Title level={5}>{title}</Title>}
					extra={icon}
				/>
			</Link>
		);
	};

	onOpenContactSupport = () => {
		this.setState({ showHelpCenterModal: true });
	};

	onSaveContactSupport = async (values: SupportMessage) => {
		try {
			const result = (await SupportMessageApiService.addNewSupportMessage(
				values
			)) as IApiResponse<boolean>;
			if (result.succeeded) {
				this.setState({ showHelpCenterModal: false });
				AlertHelper.showSuccess(
					I18n.t(_t(translations.helpCenter.createMessageSuccessfully))
				);
			} else {
				AlertHelper.showSuccess(
					I18n.t(_t(translations.helpCenter.createMessageFailed))
				);
			}
		} catch (error) {}
	};
}

export default HelpCenterPage;

interface SupportMessage {
	subject?: string;
	body?: string;
}
