import { ReduxHelper } from 'helpers';
import {
	IAddCategoryRequest,
	IEditCategoryRequest,
} from 'models/RequestModels';
import { IItemCategory } from 'models';

export const prefix = 'CATEGORY';

const getAllCategory = ReduxHelper.generateActions<
	null | undefined,
	IItemCategory[]
>(`${prefix}/GET_ALL_CATEGORY`);
const addCategory = ReduxHelper.generateActions<
	IAddCategoryRequest,
	IItemCategory
>(`${prefix}/ADD_CATEGORY`);
const selectCategory = ReduxHelper.generateLocalAction<IItemCategory>(
	`${prefix}/SELECT_CURRENT_CATEGORY`
);
const removeCurrentCategory = ReduxHelper.generateLocalAction(
	`${prefix}/REMOVE_CURRENT_CATEGORY`
);
const removeApiResponse = ReduxHelper.generateLocalAction(
	`${prefix}/REMOVE_API_RESPONSE_CATEGORY`
);
const editCategory = ReduxHelper.generateActions<
	IEditCategoryRequest,
	IItemCategory
>(`${prefix}/EDIT_CATEGORY`);
const getCategoryById = ReduxHelper.generateActions<string, IItemCategory>(
	`${prefix}/GET_CATEGORY_BY_ID`
);
const deleteCategory = ReduxHelper.generateActions<string, string>(
	`${prefix}/DELETE_CATEGORY`
);
const selectOpenedCategory = ReduxHelper.generateLocalAction<string[]>(
	`${prefix}/SELECT_OPENED_CATEGORY`
);
const updateCategories = ReduxHelper.generateLocalAction<IItemCategory[]>(
	`${prefix}/UPDATE_CATEGORIES`
);
export {
	addCategory,
	selectCategory,
	removeCurrentCategory,
	removeApiResponse,
	getAllCategory,
	editCategory,
	getCategoryById,
	deleteCategory,
	selectOpenedCategory,
	updateCategories,
};
