import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row, Space, Modal, Button, Checkbox } from 'antd';
import { Modal as ModalDelete } from 'components';
import AppTable, { IColumnProps, ITableRef } from 'components/Table';
import { AlertHelper } from 'helpers';
import { IApiResponse, IItemCategory } from 'models';
import { IPromotionItemCategory } from 'models/IPromotion';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PromotionApiService from 'services/PromotionApiService';
import { I18n, _t, translations } from 'utils';
import { Header } from './AppliedToServices';

export const CategoriesModal = (props: {
	onSave: (selectedRows: IItemCategory[]) => void;
	selectedRows: IItemCategory[];
}) => {
	const [selectedRows, setSelectedRows] = useState<IItemCategory[]>([]);

	const tableRef = useRef<ITableRef>();
	const checkIsSelect = (data: IItemCategory) => {
		let index = selectedRows.findIndex((x) => x.id === data.id);

		if (index !== -1) {
			return true;
		} else {
			return false;
		}
	};
	const columns = useMemo<IColumnProps<IItemCategory>[]>(() => {
		return [
			{
				header: '',
				key: 'id',
				type: 'text',
				renderCell: (data) => {
					return (
						<Checkbox
							onChange={(e) => {
								if (e.target.checked) {
									setSelectedRows([...selectedRows, data!]);
								} else {
									const newData = selectedRows.filter((e) => e.id !== data!.id);
									setSelectedRows(newData);
								}
							}}
							checked={checkIsSelect(data!)}
						/>
					);
				},
				width: 20,
			},
			{
				header: I18n.t(_t(translations.categoryDetail.labelName)),
				key: 'name',
				type: 'text',
			},
		];
	}, [selectedRows]);
	useEffect(() => {
		setSelectedRows(props.selectedRows);
	}, [props.selectedRows]);

	return (
		<>
			<AppTable<IItemCategory>
				ref={(ref) => {
					if (ref) {
						tableRef.current = ref;
					}
				}}
				columns={columns}
				queryUrl={'/ItemCategory/get-all-item-categories'}
				showSearch
				renderHeader={<Header onSave={() => props.onSave(selectedRows)} />}
				selectedRows={selectedRows}
				keySelected={'id'}
			/>
		</>
	);
};

const PromotionCategoriesTable = (props: {
	data?: IItemCategory[];
	setShowModalDelete: (value: boolean) => void;
	setCategoryToDelete: (value: IItemCategory) => void;
}) => {
	const tableRef = useRef<ITableRef>();

	const columns = useMemo<IColumnProps<IItemCategory>[]>(() => {
		return [
			{
				header: I18n.t(_t(translations.categoryDetail.labelName)),
				key: 'name',
				type: 'text',
			},
			{
				header: '',
				key: 'id',
				type: 'text',
				width: 100,
				renderCell: (data) => {
					return (
						<Space>
							<Button
								icon={<DeleteOutlined />}
								onClick={() => {
									props.setCategoryToDelete(data!);
									props.setShowModalDelete(true);
								}}
							/>
						</Space>
					);
				},
			},
		];
	}, []);

	return (
		<>
			<AppTable<IItemCategory>
				ref={(ref) => {
					if (ref) {
						tableRef.current = ref;
					}
				}}
				columns={columns}
				queryUrl={''}
				data={props.data}
			/>
		</>
	);
};

export const AppliedToCategories = (props: {
	isAdd: boolean;
	setCategories: (value: string[]) => void;
}) => {
	const { id } = useParams() as { id: string };
	const [showModal, setShowModal] = useState(false);
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [categoryToDelete, setCategoryToDelete] = useState<IItemCategory>();
	const [promotionCategories, setPromotionCategories] = useState<
		IItemCategory[]
	>([]);

	const onSave = (selectedRows: IItemCategory[]) => {
		props.isAdd
			? setPromotionCategories(selectedRows)
			: handleAdd(selectedRows);
		setShowModal(false);
	};
	const onDelete = async (value: IItemCategory) => {
		const newData = promotionCategories.filter((e) => e.id !== value.id);
		if (props.isAdd) {
			setPromotionCategories(newData);
		} else {
			try {
				const result = (await PromotionApiService.removePromotionItemCategory({
					promotionId: id,
					itemCategoryIds: [value.id],
				})) as IApiResponse;
				if (result.succeeded) {
					AlertHelper.showSuccess(
						I18n.t(_t(translations.promotionDetail.messageDeletedCategory))
					);
					fetchPromotionCategories();
				}
			} catch (error) {}
		}
	};

	const fetchPromotionCategories = async () => {
		try {
			const result = (await PromotionApiService.getPromotionItemCategory({
				promotionId: id,
			})) as IApiResponse<IPromotionItemCategory[]>;
			if (result.succeeded) {
				const categories = result.data?.map((element) => element.itemCategory);
				setPromotionCategories(categories || []);
			}
		} catch (error) {}
	};
	const handleAdd = async (categories: IItemCategory[]) => {
		try {
			const categoryKeys = categories.map((category) => category.id);
			const result = (await PromotionApiService.updatePromotionItemCategory({
				promotionId: id,
				itemCategoryIds: categoryKeys,
			})) as IApiResponse<IPromotionItemCategory[]>;
			if (result.succeeded) {
				AlertHelper.showSuccess('Added categories success');
				fetchPromotionCategories();
			}
		} catch (error) {}
	};

	useEffect(() => {
		if (!props.isAdd) fetchPromotionCategories();
	}, []);

	useEffect(() => {
		const categoryKeys = promotionCategories.map((category) => category.id);
		props.setCategories(categoryKeys);
	}, [promotionCategories]);

	return (
		<Row gutter={[16, 16]}>
			<Col span={4}>
				<Button
					onClick={(e) => {
						setShowModal(true);
					}}
					icon={<PlusOutlined />}
					type="primary"
				>
					{I18n.t(_t(translations.addNew))}
				</Button>
			</Col>

			<Col span={24}>
				<PromotionCategoriesTable
					data={promotionCategories}
					setCategoryToDelete={(value) => setCategoryToDelete(value)}
					setShowModalDelete={(value) => setShowModalDelete(value)}
				/>
				<Modal
					visible={showModal}
					title={I18n.t(_t(translations.promotionDetail.modalTitle))}
					onCancel={() => {
						setShowModal(false);
					}}
					footer={null}
					width={1000}
				>
					<CategoriesModal
						onSave={(selectedRows) => onSave(selectedRows)}
						selectedRows={promotionCategories}
					/>
				</Modal>
			</Col>

			<ModalDelete
				visible={showModalDelete}
				title={I18n.t(_t(translations.delete))}
				handleOk={() => {
					onDelete(categoryToDelete!);
					setShowModalDelete(false);
				}}
				handleCancel={() => {
					setShowModalDelete(false);
				}}
			>
				{I18n.t(_t(translations.promotionDetail.deleteCategoryConfirmContent))}
			</ModalDelete>
		</Row>
	);
};
