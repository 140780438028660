import { PayloadAction } from '@reduxjs/toolkit';
import { IApiResponse, IErrorResponse, IUser } from 'models';
import {
	IForgotPasswordRequest,
	ILoginRequest,
	IResetPasswordRequest,
} from 'models/RequestModels';
import { put, takeEvery, call } from 'redux-saga/effects';
import { AuthenticationActions, UserActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import AuthenticationApiService from 'services/AuthenticationApiService';

function* authenticate(action: PayloadAction<ILoginRequest>) {
	try {
		const result: IApiResponse<IUser> = yield call(
			AuthenticationApiService.authenticate,
			action.payload
		);
		if (result.succeeded) {
			yield put(
				AuthenticationActions.setToken.request({
					token: result.data?.jwToken!,
					refreshToken: result.data?.refreshToken!,
				})
			);
			yield put(
				AuthenticationActions.setDeviceId.request({
					deviceId: action.payload.deviceId,
				})
			);
			yield put(UserActions.getCurrentUserInfo.request());
			// if (action.payload.rememberMe) {
			//   localStorage.setItem("login", JSON.stringify(action.payload));
			// } else {
			//   localStorage.removeItem("login");
			// }
		} else {
			const errorResult = result as IErrorResponse;
			yield put(AuthenticationActions.authenticate.failed(errorResult));
		}
		// yield put(UserActions.login.success(result));
		// if (action.payload.isRemember) {
		//   localStorage.setItem('login', JSON.stringify(action.payload));
		// }else {
		//   localStorage.removeItem('login');
		// }
	} catch (error) {
		yield put(AuthenticationActions.authenticate.failed(error));
	}
}

function* forgotPassword(action: PayloadAction<IForgotPasswordRequest>) {
	try {
		const result: IApiResponse<string> = yield call(
			AuthenticationApiService.forgotPassword,
			action.payload
		);
		//Because the API currently not return any thing so we assume that everything works fine
		console.info(result);
		if (result.succeeded) {
			yield put(AuthenticationActions.forgotPassword.success());
		} else {
			const error = result as IErrorResponse;
			yield put(AuthenticationActions.forgotPassword.failed(error));
		}
	} catch (error) {
		yield put(AuthenticationActions.forgotPassword.failed(error));
	}
}

function* resetPassword(action: PayloadAction<IResetPasswordRequest>) {
	try {
		const result: IApiResponse<string> = yield call(
			AuthenticationApiService.resetPassword,
			action.payload
		);
		if (result.succeeded) {
			yield put(AuthenticationActions.resetPassword.success());
		} else {
			const error = result as IErrorResponse;
			yield put(AuthenticationActions.resetPassword.failed(error));
		}
	} catch (error) {
		yield put(AuthenticationActions.resetPassword.failed(error));
	}
}

export function* AuthenticateWatcher() {
	yield takeEvery(AuthenticationActions.authenticate.requestName, authenticate);
	yield takeEvery(
		AuthenticationActions.forgotPassword.requestName,
		forgotPassword
	);
	yield takeEvery(
		AuthenticationActions.resetPassword.requestName,
		resetPassword
	);
}
