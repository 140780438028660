import I18n from 'i18n-js';
import { EPeriodType } from 'models';
import { _t } from './locales';
import { translations } from './locales/translations';

export const version = 0.1;

export const BookingStatus = {
	DELETED: -1,
	PENDING: 0,
	CHECKED_IN: 1,
	CANCELED: 2,
	CONFIRMED: 3,
	PROCESSING: 4,
	FINISHED: 5,
	ERROR: 6,
	DONE: 7,
	EXPIRED: 8,
	SUSPENDED: 9,
};

export const LANGUAGE = {
	en: 'en-US',
	vi: 'vi-VN',
};

export const Colors = {
	DELETED: '#bf1919',
	PENDING: '#eaa407',
	CHECKED_IN: '#009533',
	CANCELED: '#bf1919',
	CONFIRMED: '#009533',
	PROCESSING: '#1945bf',
	FINISHED: '#7a209f',
	ERROR: '#bf1919',
	DONE: '#000000',
	EXPIRED: '#bf1919',
	SUSPENDED: '#000000',
};

export const HolidayType = {
	ONCE: 0,
	WEEKLY: 1,
	MONTHLY: 2,
};

export const WeeksOfMonth = {
	FIRST: 1,
	SECOND: 2,
	THIRD: 3,
	FOURTH: 4,
	LAST: -1,
};

export enum ModalType {
	TIP,
	DISCOUNT,
	SALE_GIFT_CARD,
	EXTRA_AMOUNT,
	COMBINE_BILl,
	APPLIED_PROMOTION,
	INPUT_COUPON,
}

export enum DiscountType {
	DOLLAR,
	PERCENT,
}

export enum DiscounterType {
	BOTH,
	OWNER_ONLY,
	EMPLOYEE_ONLY,
}

export enum PaymentType {
	CASH,
	GIFT_CARD,
	CREDIT_CARD,
	SEC,
	APP,
	E_WALLET,
	OTHER,
}

export enum TipType {
	AUTO,
	SPLIT_EVEN,
	MANUALLY,
}

export enum AppType {
	CASH_APP,
	VENMO,
	ZELLE,
}

export enum PriceType {
	SUB_TOTAL,
	TAX,
	TOTAL,
	BALANCE,
	TIP,
	GIFT_CARD,
	MAX_DISCOUNT,
	CARD_CHARGE,
}

export enum ChartRangeType {
	WEEK,
	MONTH,
}

export enum TopServiceRequestPeriodType {
	WEEK = 1,
	SEMI_MONTH,
	MONTH,
	QUARTER,
	SEMI_YEAR,
	YEAR,
}

export enum DateRangeType {
	TODAY,
	YESTERDAY,
	WEEKLY,
	SEMI_MONTH_1st,
	SEMI_MONTH_2nd,
	MONTHLY,
	DATE_RANGE,
	LAST_MONTH,
}

export enum WageType {
	HOURLY = 1,
	WEEKLY,
	MONTHLY,
	SEMIMONTHLY,
}

export const DefaultPassword = {
	PASSWORD: '123456aA@',
};

export const ChargeRate = {
	CARD_CHARGE: 0,
	TIP_CARD_CHARGE: 0,
	CASH_CHECK_COMMISSION: 50,
};

export const BOOKING_RULES = {
	defaultLateTime: 60,
	defaultEarly: 60,
};

export const SOCKET_STAGING_URL = 'https://test.scssolutions.io';

export enum PRINTER_CONNECTION {
	NETWORK,
	USB,
}

export enum RECEIPT_PRINT_TYPE {
	ONE,
	TWO,
	NONE,
}

export const DATE_TYPE = [
	{
		id: 'dateRange',
		title: I18n.t(_t(translations.dateRangeFrame.dateRange)),
		value: DateRangeType.DATE_RANGE,
	},
	{
		id: 'today',
		title: I18n.t(_t(translations.dateRangeFrame.today)),
		value: DateRangeType.TODAY,
	},
	{
		id: 'yesterday',
		title: I18n.t(_t(translations.dateRangeFrame.yesterday)),
		value: DateRangeType.YESTERDAY,
	},
	{
		id: 'weekly',
		title: I18n.t(_t(translations.dateRangeFrame.weekly)),
		value: DateRangeType.WEEKLY,
	},
	{
		id: 'semiMonth1st',
		title: I18n.t(_t(translations.dateRangeFrame.semiMonth1st)),
		value: DateRangeType.SEMI_MONTH_1st,
	},
	{
		id: 'semiMonth2nd',
		title: I18n.t(_t(translations.dateRangeFrame.semiMonth2nd)),
		value: DateRangeType.SEMI_MONTH_2nd,
	},
	{
		id: 'monthly',
		title: I18n.t(_t(translations.dateRangeFrame.monthly)),
		value: DateRangeType.MONTHLY,
	},
	{
		id: 'lastMonth',
		title: I18n.t(_t(translations.dateRangeFrame.lastMonth)),
		value: DateRangeType.LAST_MONTH,
	},
];
export enum WeekDays {
	SUNDAY,
	MONDAY,
	TUESDAY,
	WEDNESDAY,
	THURSDAY,
	FRIDAY,
	SATURDAY,
	WHOLEWEEK,
}

export const ThemeType = {
	SLIDER: 'Slider',
	ABOUT_US: 'AboutUs',
	WELCOME_TEXT: 'WelcomeText',
	WHY_CHOOSE: 'WhyChoose',
	GALLERY: 'Gallery',
	SERVICES: 'Services',
};

export const OnlineBookingUrl = '/customer-booking';

export const PERIOD_TYPES = [
	{
		id: 'date-range',
		title: I18n.t(_t(translations.dateRangeFrame.dateRange)),
		value: EPeriodType.DATE_RANGE,
	},
	// {
	// 	id: 'today',
	// 	title: I18n.t(_t(translations.dateRangeFrame.day)),
	// 	value: EPeriodType.DAY,
	// },
	{
		id: 'week',
		title: I18n.t(_t(translations.dateRangeFrame.week)),
		value: EPeriodType.WEEK,
	},
	{
		id: 'semi-month',
		title: I18n.t(_t(translations.dateRangeFrame.semiMonth)),
		value: EPeriodType.SEMI_MONTH,
	},
	{
		id: 'month',
		title: I18n.t(_t(translations.dateRangeFrame.month)),
		value: EPeriodType.MONTH,
	},
	{
		id: 'quarter',
		title: I18n.t(_t(translations.dateRangeFrame.quarter)),
		value: EPeriodType.QUARTER,
	},
	{
		id: 'semi-year',
		title: I18n.t(_t(translations.dateRangeFrame.semiYear)),
		value: EPeriodType.SEMI_YEAR,
	},
	{
		id: 'year',
		title: I18n.t(_t(translations.dateRangeFrame.year)),
		value: EPeriodType.YEAR,
	},
];

export enum EDatePickerType {
	FROM_DATE,
	TO_DATE,
}

// export enum EExpenseType {
// 	ALL = -2,
// 	OTHER,
// 	ELECTRICITY,
// 	WATER,
// 	CABLE,
// 	PHONE,
// 	CELL_PHONE,
// 	NATURAL_GAS,
// 	INTERNET,
// }

export const PromotionCode = {
	TOTAL_BOOKING: 'AssignedToBookingTotal',
	ALL_SERVICE: 'AssignedToAllServices',
	CATEGORIES: 'AssignedToCategories',
};

export enum EBillType {
	NORMAL,
	COMBINE,
	REFUND,
}
