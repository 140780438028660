import { Form } from 'antd';
import formHelper from 'helpers/formHelper';
import ModalHelper from 'helpers/modalHelper';
import React, { useState } from 'react';
import { I18n, _t, translations } from 'utils';

interface IAdminPasswordModal {
	visible: boolean;
	onOk: (password: string) => void;
	onCancel: () => void;
	isLoading: boolean;
}
const AdminPasswordModal = ({
	visible,
	onOk,
	onCancel,
	isLoading,
}: IAdminPasswordModal) => {
	const [password, setPassword] = useState<string>('');
	return (
		<ModalHelper
			visible={visible}
			onOk={() => onOk(password)}
			onCancel={onCancel}
			title={I18n.t(_t(translations.confirmation))}
			disableOk={!password}
			isLoading={isLoading}
		>
			<Form layout='vertical'>
				{formHelper.renderFormInput(
					'',
					[],
					I18n.t(_t(translations.staffDetail.adminPassword)),
					'',
					undefined,
					{
						onChange: (e) => setPassword(e.target.value),
            type: 'password'
					}
				)}
			</Form>
		</ModalHelper>
	);
};

export default AdminPasswordModal;
