import GlobalStyles, { kStyles } from "GlobalStyles";

const styles: kStyles = {
  chartContainer: {
    ...GlobalStyles.boxShadow,
    backgroundColor: "white",
    padding: "16px",
    //boxShadow: "#00000038 5px 5px 10px",
  },
  chartContainerHover: {
    backgroundColor: "white",
    padding: "16px",
    boxShadow: "0 2px 5px 0 rgb(164 173 186 / 50%)",
  },
  chartTitle: {
    fontWeight: "bold",
    margin: "0",
    display: "inline-block",
  },
  chartSub: {
    display: "block",
    color: "grey",
  },
  chartTotalSale: {
    margin: "16px 0",
    display: "inline-block",
  },
  chartTotalAppointment: {
    margin: "0 0 20px 0",
  },
  listContainer: {
    ...GlobalStyles.boxShadow,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  },
  listContainerHover: {
    backgroundColor: "white",
    boxShadow: "rgba(0,0,0,0.2) 2px 2px 10px",
    display: "flex",
    flexDirection: "column",
  },
  listHeader: {
    background: "none",
    padding: "16px",
  },
  theListContainer: {
    // ...GlobalStyles.dashboardView,
    height: '300px',
    overflow: "auto",
  },
  listItemContainer: {
    width: "100%",
    paddingLeft: "16px",
    paddingRight: 16
  },
  listTextDate: {
    margin: 0,
    fontWeight: "bold",
    fontSize: "17px",
  },
  listTextDay: {
    margin: 0,
  },
  listTextDateTime: {
    fontSize: "14px",
    display: "block",
    color: "grey",
  },
  listTextService: {
    fontWeight: "bold",
    fontSize: "17px",
    display: "block",
  },
  listTextCustomer: {
    fontSize: "14px",
    display: "block",
    color: "grey",
  },
  listColCost: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    fontSize: "17px",
  },
  listTextCost: {
    margin: 0,
    fontWeight: "bold",
  },
  listLoadingComponent: {
    textAlign: "center",
    padding: "16px 0",
  },
  tableContainer: {
    height: 330
  }  
};

export default styles;
