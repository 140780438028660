import {
	ICloseOnlineBooking,
	ICloseOnlineBookingRequest,
} from 'models/ICloseOnlinebooking';
import { IPaginateResponse } from 'models/ResponseModels';
import { BaseApiService } from './BaseApiService';

const url = '/CloseOnlineBookingTime';

class CloseOnlineBookingApiService extends BaseApiService {
	public getAll = (request: ICloseOnlineBookingRequest) =>
		this.get<IPaginateResponse<ICloseOnlineBooking[]>>(
			`${url}/get-all-close-online-booking-times`,
			{
				params: request,
			}
		);
	public add = (request: ICloseOnlineBooking) =>
		this.post<ICloseOnlineBooking, any>(
			`${url}/create-close-online-booking-time`,
			request
		);
	public update = (request: ICloseOnlineBooking) =>
		this.put<ICloseOnlineBooking, any>(
			`${url}/update-close-online-booking-time`,
			request
		);
	public deleteById = (id: string) =>
		this.delete<string>(
			`/CloseOnlineBookingTime/delete-close-online-booking-time/${id}`
		);
}

export default new CloseOnlineBookingApiService(true);
