import {
	CalendarFilled,
	CloudFilled,
	ThunderboltFilled,
} from '@ant-design/icons';
import { Button, Card, Col, Descriptions, Row, Space, Typography } from 'antd';
import { BookingItem, CustomerInfo, StatusTag, Avatar } from 'components';
import InputFromTag from 'components/InputFromTag';
import {
	BookingHelper,
	ColorHelper,
	CurrencyHelper,
	TimeHelper,
} from 'helpers';
import { calculateBookingDetailAmount } from 'helpers/bookingHelper';
import { toTimeZone } from 'helpers/timeHelper';
import _ from 'lodash';
import { EBookingInputFrom, IBooking } from 'models';
import moment from 'moment';
import React from 'react';
import { history } from 'routers';
import { I18n, _t, translations } from 'utils';
import { BookingStatus } from 'utils/Consts';
interface IBookingCardProps {
	booking: IBooking;
	showAction?: boolean;
	short?: boolean;
	width?: number;
	maxHeight?: number;
	hideCustomerAvatar?: boolean;
	showBookingNumber?: boolean;
	showBlinking?: boolean;
}

export default class BookingCard extends React.Component<IBookingCardProps> {
	render() {
		const { booking } = this.props;

		return (
			<Card
				actions={
					this.props.showAction
						? [
								<Descriptions
									style={{ paddingLeft: 16 }}
									size="small"
									layout="vertical"
									labelStyle={{ fontWeight: 'bold' }}
								>
									<Descriptions.Item label="Created At">
										{TimeHelper.toTimeZone(booking.createdDate).format(
											'MM-DD-YYYY h:mmA'
										)}
									</Descriptions.Item>
								</Descriptions>,
								<Button onClick={() => this.onOpenBooking(booking)}>
									{I18n.t(_t(translations.mainBooking.viewDetail))}
								</Button>,
						  ]
						: []
				}
				onClick={() => this.props.short && this.onOpenBooking(booking)}
				className={`card-booking-container ${
					this.props.showBlinking &&
					this.props.booking.status === BookingStatus.FINISHED &&
					moment(moment()).diff(
						this.props.booking.endTime || this.props.booking.endTimeExpected,
						'minute'
					) > 10 &&
					'blinking'
				}`}
				style={{
					borderColor: ColorHelper.getStatusColor(booking.status),
					borderWidth: 2,
					width: this.props.width ? this.props.width : undefined,
					height: this.props.maxHeight ? this.props.maxHeight : undefined,
					overflow: 'hidden',
				}}
				bodyStyle={
					this.props.short
						? { backgroundColor: booking.stylist?.color, padding: 0 }
						: undefined
				}
				title={
					<Space style={{ justifyContent: 'space-between', width: '100%' }}>
						<CustomerInfo hideAvatar customer={booking.customer} />
						{this.props.short ? (
							<Space size={0} direction="vertical">
								<Col style={{ textAlign: 'right' }}>
									<Typography.Title level={4}>
										{CurrencyHelper.formatPrice(
											_.sumBy(booking.bookingDetails, (x) =>
												calculateBookingDetailAmount(x)
											)
										)}
									</Typography.Title>
								</Col>
								<Space
									style={{ justifyContent: 'space-between', width: '100%' }}
								>
									<StatusTag status={booking.status} />
									<InputFromTag
										bookingStatus={booking.status}
										inputSource={booking.inputFrom!}
										bookingType={booking.bookingType!}
									/>
								</Space>
							</Space>
						) : null}
					</Space>
				}
				// extra={
				//   <>
				//     <Col style={{ textAlign: "right" }}>
				//       <Typography.Title level={4}>{CurrencyHelper.formatPrice(booking.totalAmount)}</Typography.Title>
				//     </Col>
				//     <StatusTag status={booking.status} />
				//   </>
				// }
			>
				{this.props.short ? (
					this.renderShort()
				) : (
					<div style={{ padding: 16 }}>
						<BookingItem hideDate showStylist booking={booking} />
					</div>
				)}
			</Card>
		);
	}

	renderShort = () => {
		const { booking } = this.props;
		const currentStylist = !_.isEmpty(booking.stylist)
			? booking.stylist
			: booking.bookingDetails[0].stylist;
		const stylistName = !_.isEmpty(currentStylist)
			? `${currentStylist?.firstName} ${currentStylist?.lastName}`
			: '';

		return (
			<Row style={{ width: '100%' }}>
				<Col span={6}>
					<Avatar
						fullName
						size="large"
						shape="square"
						name={`${
							currentStylist?.firstName || currentStylist?.lastName || ''
						}`}
						src={currentStylist?.imageUrl}
					/>
				</Col>
				<Col span={18}>
					<Space size={0} direction="vertical">
						<Typography.Text strong>
							{booking.bookingDetails[0]
								? _.truncate(
										booking.bookingDetails[0].item?.name.toUpperCase(),
										{
											length: 17,
										}
								  )
								: ''}
							<Typography.Text underline>
								{`(${toTimeZone(
									booking.startTime || booking.startTimeExpected
								).format('h:mmA')})`}
							</Typography.Text>
						</Typography.Text>
						<Typography.Text underline>
							<Typography.Text strong>
								{_.truncate(stylistName, {
									length: 16,
								})}
							</Typography.Text>
							{`(${
								booking.bookingDetails[0]
									? booking.bookingDetails[0].item?.duration
									: ''
							}  minutes)`}
						</Typography.Text>
					</Space>
				</Col>
			</Row>
		);
	};
	onOpenBooking(booking: IBooking) {
		BookingHelper.openBooking(booking);
	}
}
