import AdminRouter from "./AdminRouter";
import AuthenticationRouter from "./AuthenticationRouter";
import history from "./history";

export const goBackHistory = () => {
  if (history.action === "POP") {
    history.replace("/today");
    return;
  } else {
    history.goBack();
  }
};
export { AdminRouter, AuthenticationRouter, history };
