import { Select } from "antd";
import React from "react";
import { I18n, translations, _t } from "utils";
import { ChartRangeType } from "utils/Consts";

interface IRangeSelectProps {
  defaultMode: number;
  onValueChange(value: number): void;
}

const RangeSelect = (props: IRangeSelectProps) => {
  return (
    <Select
      defaultValue={props.defaultMode}
      style={{ float: "right" }}
      onChange={props.onValueChange}
    >
      <Select.Option value={ChartRangeType.WEEK}>
        {I18n.t(_t(translations.dashboard.week))}
      </Select.Option>
      <Select.Option value={ChartRangeType.MONTH}>
        {I18n.t(_t(translations.dashboard.month))}
      </Select.Option>
    </Select>
  );
};

export default RangeSelect;
