import { Col, List, Row, Typography } from 'antd';
import React from 'react';
import styles from './styles';
import { IBookingActivity } from 'models';
import { I18n, _t, translations } from 'utils';
import { CurrencyHelper, TimeHelper } from 'helpers';
import { Link } from 'react-router-dom';
import { StatusTag } from 'components';
import { BookingStatus } from 'utils/Consts';

const { toTimeZone } = TimeHelper;
const { Title, Text } = Typography;

interface IBookingActivityProps {
	bookingActivity: IBookingActivity;
	showMonth?: boolean;
}

const BookingActivityItem = (props: IBookingActivityProps) => {
	const renderDay = () => {
		return (
			<Col span={4}>
				<Title level={4} style={styles.listTextDate}>
					{toTimeZone(props.bookingActivity.startTimeExpected).format(
						props.showMonth ? 'MMM, DD' : 'DD'
					)}
				</Title>
				<Title level={5} style={styles.listTextDay}>
					{toTimeZone(props.bookingActivity.startTimeExpected).format('ddd')}
				</Title>
			</Col>
		);
	};
	const renderService = () => {
		return (
			<Col span={16}>
				<Text style={styles.listTextDateTime}>
					{toTimeZone(props.bookingActivity.startTimeExpected).format(
						'ddd, MMM DD YYYY h:mma'
					)}
					<StatusTag status={props.bookingActivity.status!} />
				</Text>
				<Text style={{ ...styles.listTextService }}>
					{props.bookingActivity.firstServiceName}
				</Text>
				<Text style={styles.listTextCustomer}>
					{I18n.t(_t(translations.dashboard.customerDurationWithStylist), {
						customer: props.bookingActivity.customerName,
						duration: TimeHelper.convertDurationToStringDetail(
							props.bookingActivity.duration!
						),
						stylist: props.bookingActivity.stylistName,
					})}
				</Text>
			</Col>
		);
	};
	const renderPrice = () => {
		return (
			<Col span={4} style={styles.listColCost}>
				<Title level={4} style={styles.listTextCost}>
					{CurrencyHelper.formatPrice(props.bookingActivity.totalAmount)}
				</Title>
			</Col>
		);
	};

	return (
		<List.Item>
			<Link
				to={
					props.bookingActivity.status === BookingStatus.FINISHED
						? `checkout?bookingId=${props.bookingActivity.bookingId}`
						: props.bookingActivity.status === BookingStatus.DONE
						? `billing?bookingId=${props.bookingActivity.bookingId}`
						: `booking/${props.bookingActivity.bookingId}`
				}
				style={styles.listItemContainer}
			>
				<Row>
					{renderDay()}
					{renderService()}
					{renderPrice()}
				</Row>
			</Link>
		</List.Item>
	);
};

export default BookingActivityItem;
