import './style.css';
import React, { useEffect, useState } from 'react';
import {
	EBookingInputFrom,
	IApiResponse,
	IBooking,
	IErrorResponse,
	IService,
} from 'models';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import moment from 'moment';
import {
	Layout,
	Col,
	Row,
	Image,
	Typography,
	Divider,
	Button,
	Spin,
} from 'antd';
import GlobalStyles from 'GlobalStyles';
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import qs from 'qs';
import _ from 'lodash';
import {
	AlertHelper,
	BookingHelper,
	checkIsMobile,
	CurrencyHelper,
	TimeHelper,
} from 'helpers';
import SelectStaff from './components/SelectStaff';
import { SelectService } from './components/SelectServices';
import { SelectStaffCalendar } from './components/SelectStaffCalendar';
import { BranchActions, CustomerBookingActions } from 'redux/actions';
import { CustomerInfo } from './components/CustomerInfo';
import { BookingSuccess } from './components/BookingSuccess';
import BookingApiService from 'services/BookingApiService';
import { I18n, translations, _t } from 'utils';
import { SelectBranch } from './components/SelectBranch';
import chroma from 'chroma-js';
import { useAppSelector } from 'helpers/hookHelpers';
import ModalHelper from 'helpers/modalHelper';

const { Content } = Layout;

interface IConfirm {
	onConfirm: () => void;
}

const useConfirmBooking = () => {
	const [loading, setLoading] = useState(false);
	const [newBooking, setNewBooking] = useState<IBooking | undefined>(undefined);
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	const customer = useSelector(
		(state: RootState) => state.CustomerBookingReducer.customer
	);
	const note = useSelector(
		(state: RootState) => state.CustomerBookingReducer.note
	);
	const shopId = useSelector((state: RootState) => state.ShopReducer.shop?.id);
	const branchId = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch?.id
	);

	const confirmBooking = async () => {
		setLoading(true);
		// const totalDuration = _.sumBy(
		// 	booking?.bookingDetails,
		// 	(x) => x.item?.duration || 0
		// );
		const convertedBookingDetails = BookingHelper.convertBookingDetails(
			booking?.bookingDetails!,
			moment.utc(booking?.startTimeExpected)
		);
		const bookingRequest: Partial<IBooking> = {
			date: booking?.date,
			customerId: customer?.id,
			startTimeExpected: booking?.startTimeExpected,
			endTimeExpected: _.last(convertedBookingDetails)?.endAtExpected,
			inputFrom: EBookingInputFrom.OnlineSite,
			stylistId:
				booking?.bookingDetails![0].id !== 'Anyone'
					? booking?.bookingDetails![0].id
					: undefined,
			shopId: shopId,
			branchId: branchId,
			note: note,
			discount: 0,
			bookingDetails: convertedBookingDetails,
			totalAmount: _.sumBy(
				convertedBookingDetails,
				(bookingDetail) => bookingDetail.amount!
			),
			realAmount: _.sumBy(
				convertedBookingDetails,
				(bookingDetail) => bookingDetail.amount! - (bookingDetail.discount || 0)
			),
			totalQuantity: convertedBookingDetails.length,
			couponCodes: booking?.couponCodes,
		};
		const bookingResponse = (await BookingApiService.createBooking(
			bookingRequest
		)) as IApiResponse<IBooking>;
		setLoading(false);
		if (bookingResponse.succeeded && !_.isEmpty(bookingResponse.data)) {
			setNewBooking(bookingResponse.data);
		} else {
			const error = bookingResponse as IErrorResponse;
			AlertHelper.showError(error);
		}
	};
	return {
		confirmBooking,
		loading,
		newBooking,
	};
};
const useCheckMultipleBranches = () => {
	const branches = useSelector(
		(state: RootState) => state.BranchReducer.branches
	);
	return branches.length > 1;
};

const getMainByStep = (step: number, isMultiple = false) => {
	if (isMultiple) {
		switch (step) {
			case 1:
				return <SelectBranch />;
			case 2:
				return <SelectService />;
			case 3:
				return <SelectStaff />;
			case 4:
				return <SelectStaffCalendar />;
			case 5:
				return <CustomerInfo />;
			default:
				break;
		}
	} else {
		switch (step) {
			case 1:
				return <SelectService />;
			case 2:
				return <SelectStaff />;
			case 3:
				return <SelectStaffCalendar />;
			case 4:
				return <CustomerInfo />;
			default:
				break;
		}
	}
};
const useGetStepName = (step: number, isMultipleBranches = false) => {
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	const stylists = useSelector(
		(state: RootState) => state.CustomerBookingReducer.stylists
	);
	const customer = useSelector(
		(state: RootState) => state.CustomerBookingReducer.customer
	);
	if (isMultipleBranches) {
		switch (step) {
			case 1:
				return 'Select Branch';
			case 2:
				return 'Select Services';
			case 3:
				return 'Select Staff';
			case 4:
				const stylistName =
					booking?.bookingDetails![0].stylistId === 'Anyone'
						? ''
						: `${
								_.find(
									stylists,
									(x) => x.id === booking?.bookingDetails![0].stylistId
								)?.firstName
						  } ${
								_.find(
									stylists,
									(x) => x.id === booking?.bookingDetails![0].stylistId
								)?.lastName
						  }`;
				return _.isEmpty(stylistName)
					? 'Select Time'
					: `Select Time with ${stylistName}`;
			case 5:
				const customerName = `${customer?.firstName} ${customer?.lastName}`;
				return !_.isEmpty(customer)
					? `Welcome, ${customerName}`
					: 'Customer Info';
			default:
				break;
		}
	} else {
		switch (step) {
			case 1:
				return 'Select Services';
			case 2:
				return 'Select Staff';
			case 3:
				const stylistName =
					booking?.bookingDetails![0].stylistId === 'Anyone'
						? ''
						: `${
								_.find(
									stylists,
									(x) => x.id === booking?.bookingDetails![0].stylistId
								)?.firstName
						  } ${
								_.find(
									stylists,
									(x) => x.id === booking?.bookingDetails![0].stylistId
								)?.lastName
						  }`;
				return _.isEmpty(stylistName)
					? 'Select Time'
					: `Select Time with ${stylistName}`;
			case 4:
				const customerName = `${customer?.firstName} ${customer?.lastName}`;
				return !_.isEmpty(customer)
					? `Welcome, ${customerName}`
					: 'Customer Info';
			default:
				break;
		}
	}
};
const useCheckValidStep = () => {
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	const branches = useSelector(
		(state: RootState) => state.BranchReducer.branches
	);
	const currentBranch = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);
	const isMultipleBranches = useCheckMultipleBranches();
	const step = useGetStep();
	if (isMultipleBranches) {
		if (branches && _.isEmpty(currentBranch) && step !== 1) {
			window.location.href = '/customer-booking';
		}
	}

	if (
		_.isEmpty(booking?.bookingDetails) &&
		step > (isMultipleBranches ? 2 : 1)
	) {
		window.location.href = isMultipleBranches
			? '/customer-booking?step=2'
			: '/customer-booking';
		return false;
	}
	return true;
};
const useGetStep = () => {
	const history = useHistory();
	const location = history.location;
	const params = qs.parse(location.search, {
		ignoreQueryPrefix: true,
	});
	let step = 1;
	if (params.step) {
		step = Number.parseInt(params.step as string);
	}
	return step;
};
const useCheckCanNext = () => {
	const step = useGetStep();
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	const customer = useSelector(
		(state: RootState) => state.CustomerBookingReducer.customer
	);
	const isRequireStylist = useSelector(
		(state: RootState) =>
			state.BranchReducer.currentBranch?.technicianSelectionWhenBookingOnline
	);
	const stylists = useSelector(
		(state: RootState) => state.CustomerBookingReducer.stylists
	)!;
	let listStylistAvailable: any = [];
	const isMultiple = useCheckMultipleBranches();

	booking?.bookingDetails?.forEach((x) => {
		const stylistsCanDoService = stylists.filter((stylist) =>
			stylist.serviceIds?.includes(x.item?.id || '')
		);
		listStylistAvailable.push(stylistsCanDoService);
	});
	const isEmptyStylist = listStylistAvailable.map((x: any) => {
		if (_.isEmpty(x)) {
			return true;
		}
		return false;
	});
	if (isMultiple) {
		switch (step) {
			case 2:
				if (_.isEmpty(booking?.bookingDetails)) {
					return false;
				}
				break;
			case 3:
				if (
					(!isRequireStylist &&
						!_.isEmpty(
							booking?.bookingDetails?.filter((x) => _.isEmpty(x.stylistId))
						)) ||
					(isRequireStylist &&
						isEmptyStylist.includes(false) &&
						_.isEmpty(
							booking?.bookingDetails?.filter((x) => !_.isEmpty(x.stylistId))
						))
				) {
					return false;
				}

				break;
			case 4:
				if (_.isEmpty(booking?.startTimeExpected)) {
					return false;
				}
				break;
			case 5:
				if (_.isEmpty(customer)) {
					return false;
				}
				break;
			default:
				break;
		}
	} else {
		switch (step) {
			case 1:
				if (_.isEmpty(booking?.bookingDetails)) {
					return false;
				}
				break;
			case 2:
				if (
					(!isRequireStylist &&
						!_.isEmpty(
							booking?.bookingDetails?.filter((x) => _.isEmpty(x.stylistId))
						)) ||
					(isRequireStylist &&
						isEmptyStylist.includes(false) &&
						_.isEmpty(
							booking?.bookingDetails?.filter((x) => !_.isEmpty(x.stylistId))
						))
				) {
					return false;
				}

				break;
			case 3:
				if (_.isEmpty(booking?.startTimeExpected)) {
					return false;
				}
				break;
			case 4:
				if (_.isEmpty(customer)) {
					return false;
				}
				break;
			default:
				break;
		}
	}

	return true;
};

const NextButton = (props: IConfirm) => {
	const history = useHistory();
	const step = useGetStep();
	const canNext = useCheckCanNext();
	const isMultipleBranches = useCheckMultipleBranches();
	const onNext = () => {
		if (step === (isMultipleBranches ? 5 : 4)) {
			props.onConfirm();
		} else {
			history.push({
				pathname: `/customer-booking`,
				search: `?step=${step + 1}`,
			});
		}
	};
	return (
		<Button
			onClick={(e) => {
				e.stopPropagation();
				onNext();
			}}
			style={{ minWidth: 80 }}
			type="primary"
			disabled={!canNext}
		>
			{`${step === (isMultipleBranches ? 5 : 4) ? 'Confirm' : 'Next'}`}
		</Button>
	);
};
const CustomerHeader = () => {
	const history = useHistory();
	const step = useGetStep();
	const isMultipleBranches = useCheckMultipleBranches();
	const stepName = useGetStepName(step, isMultipleBranches);
	const dispatch = useDispatch();
	const booking = useAppSelector(
		(state) => state.CustomerBookingReducer.booking
	);
	const onGoBack = () => {
		if (step === (isMultipleBranches ? 3 : 2)) {
			dispatch(
				CustomerBookingActions.updateBooking.request({
					startTimeExpected: undefined,
					date: undefined,
				})
			);
		}
		if (step === (isMultipleBranches ? 5 : 4)) {
			dispatch(
				CustomerBookingActions.updateBooking.request({
					couponCodes: '',
					bookingDetails: booking!.bookingDetails!.map((element) => ({
						...element,
						promotionDiscount: 0,
					})),
				})
			);
		}
		if (isMultipleBranches && step === 2) {
			history.push({
				pathname: `/customer-booking`,
			});
			return;
		}

		history.goBack();
	};
	return (
		<>
			<div className="customer-header selected-primary">
				<ArrowLeftOutlined className="back-icon" onClick={() => onGoBack()} />
				<Content style={{ width: '70%', alignSelf: 'center' }}>
					<Row>
						<Col>
							<Typography.Text
								style={{ color: 'white' }}
								type="secondary"
							>{`Step ${step} of ${
								isMultipleBranches ? 5 : 4
							}`}</Typography.Text>
							<Typography.Title
								style={{ color: 'white', marginTop: 0 }}
								level={3}
							>
								{stepName}
							</Typography.Title>
						</Col>
					</Row>
				</Content>
			</div>
			<div className="after-header selected-primary"></div>
		</>
	);
};
const WebFooter = (props: IConfirm) => {
	return (
		<div className="web-footer">
			<Content
				style={{
					width: '70%',
					alignSelf: 'center',
					justifyContent: 'flex-end',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Row justify="end" align="middle">
					<Col style={{ justifyContent: 'center' }}>
						<NextButton onConfirm={() => props.onConfirm()} />
					</Col>
				</Row>
			</Content>
		</div>
	);
};
const MobileFooter = (props: IConfirm) => {
	const [visible, setVisible] = useState(false);
	const shop = useSelector((state: RootState) => state.ShopReducer.shop);
	const branch = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	const dispatch = useDispatch();
	const newBookingDetails = Array.from(booking?.bookingDetails || []);
	const removeService = (service: IService) => {
		_.remove(
			newBookingDetails,
			(bookingDetail) => bookingDetail.itemId === service.id
		);
		dispatch(
			CustomerBookingActions.updateBooking.request({
				...booking,
				bookingDetails: newBookingDetails,
			})
		);
	};
	const tax = _.sumBy(
		booking?.bookingDetails,
		(bookingDetail) =>
			(((bookingDetail.item?.price || 0) +
				(bookingDetail.extraAmount || 0) -
				(branch?.discountBeforeTax ? bookingDetail.discount || 0 : 0) -
				(branch?.discountBeforeTax
					? bookingDetail.promotionDiscount || 0
					: 0)) *
				(branch?.taxPercent || 0)) /
			100
	);
	const isMultipleBranches = useCheckMultipleBranches();

	let shopName = `${shop?.name}`;
	if (isMultipleBranches) {
		shopName += ` - ${branch?.name}`;
	}
	return (
		<>
			<div
				className="mobile-footer"
				onClick={(e) => {
					e.stopPropagation();
					setVisible(true);
				}}
			>
				<div style={{ padding: 16 }}>
					<Row align="middle" justify="center">
						<Col span={18} style={{ display: 'flex', flexDirection: 'column' }}>
							<Typography.Text strong>{`${shopName}`}</Typography.Text>
							<Typography.Text type="secondary">{`${branch?.address?.street}, ${branch?.address?.city}, ${branch?.address?.state}`}</Typography.Text>
						</Col>
						<Col span={6} style={{ display: 'flex', justifyContent: 'center' }}>
							<Image
								style={{
									width: 40,
									height: 40,
									borderRadius: 5,
									alignSelf: 'center',
									...GlobalStyles.boxShadow,
								}}
								src={
									!_.isEmpty(shop?.logoUrl) ? shop?.logoUrl : '/app_logo.png'
								}
							/>
						</Col>
					</Row>
					<BookingTimeInfo />
					{!_.isEmpty(booking?.bookingDetails) ? (
						<>
							<Divider />
							<Row>
								<Col
									span={14}
									style={{ display: 'flex', flexDirection: 'column' }}
								>
									<Typography.Text
										strong
										type="secondary"
									>{`${booking?.bookingDetails?.length} Services`}</Typography.Text>

									<Typography.Title level={5} style={{ marginTop: 0 }}>
										{CurrencyHelper.formatPrice(
											_.sumBy(
												booking?.bookingDetails,
												(x) =>
													x.item?.price! -
													(x.promotionDiscount || 0) -
													(x.discount || 0)
											) + tax
										)}
									</Typography.Title>
								</Col>
								<Col
									span={10}
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'flex-end',
									}}
								>
									<NextButton onConfirm={() => props.onConfirm()} />
								</Col>
							</Row>
						</>
					) : null}
				</div>
			</div>
			<ModalHelper
				visible={visible}
				onOk={() => {
					setVisible(false);
				}}
				onCancel={() => {
					setVisible(false);
					console.log(visible);

					console.log('cancel');
				}}
				title="Booking detail"
				btnOkHidden
				width={'80%'}
				mediumFooter
			>
				{booking?.bookingDetails?.map((bookingDetail, index) => {
					const discount =
						(bookingDetail.item?.price || 0) -
						(bookingDetail.discount || 0) -
						(bookingDetail.promotionDiscount || 0);
					return (
						<>
							<Row className="full-width">
								<Col
									style={{ display: 'flex', flexDirection: 'column' }}
									span={16}
								>
									<Typography.Text>{bookingDetail.item?.name}</Typography.Text>
									<Typography.Text type="secondary">
										{TimeHelper.convertDurationToStringDetail(
											bookingDetail.item?.duration || 0
										)}
									</Typography.Text>
								</Col>
								<Col
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'flex-end',
									}}
									span={6}
								>
									{discount < bookingDetail.item?.price! && (
										<Typography.Text>
											{CurrencyHelper.formatPrice(discount)}
										</Typography.Text>
									)}
									<Typography.Text
										className={
											discount < bookingDetail.item?.price!
												? 'price-discounted'
												: ''
										}
										style={{ color: 'rgba(0, 0, 0, 0.45)' }}
									>
										{CurrencyHelper.formatPrice(bookingDetail.item?.price)}
									</Typography.Text>
								</Col>
								<Col span={2} style={{ display: 'flex', alignItems: 'center' }}>
									<DeleteOutlined
										style={{ color: 'red', fontSize: '20px' }}
										onClick={() => {
											removeService(bookingDetail.item!);
										}}
									/>
								</Col>
							</Row>
							{index === newBookingDetails.length - 1 ? null : <Divider />}
						</>
					);
				})}
			</ModalHelper>
		</>
	);
};
const BookingTimeInfo = () => {
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	if (!booking?.startTimeExpected) {
		return null;
	}
	const time = TimeHelper.toTimeZone(booking.startTimeExpected);
	const duration = _.sumBy(booking.bookingDetails, (x) => x.item?.duration!);
	const endTimeExpected = time.add(duration, 'minute');
	return (
		<>
			<Divider />
			<div
				className="full-width"
				style={{
					paddingLeft: checkIsMobile() ? 0 : 16,
					paddingRight: checkIsMobile() ? 0 : 16,
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography.Text strong>{`${time.format(
					'D MMM YYYY'
				)} at ${TimeHelper.toTimeZone(booking.startTimeExpected).format(
					'hh:mma'
				)} `}</Typography.Text>
				<Typography.Text type="secondary">{`${TimeHelper.convertDurationToStringDetail(
					duration
				)} duration, ends at ${endTimeExpected.format(
					'hh:mma'
				)} `}</Typography.Text>
			</div>
		</>
	);
};
const WebBillInfo = () => {
	const shop = useSelector((state: RootState) => state.ShopReducer.shop);
	const branch = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	const dispatch = useDispatch();
	const newBookingDetails = Array.from(booking?.bookingDetails || []);
	const removeService = (service: IService) => {
		_.remove(
			newBookingDetails,
			(bookingDetail) => bookingDetail.itemId === service.id
		);
		dispatch(
			CustomerBookingActions.updateBooking.request({
				...booking,
				bookingDetails: newBookingDetails,
			})
		);
	};
	const isMultipleBranches = useCheckMultipleBranches();

	let shopName = `${shop?.name}`;
	if (isMultipleBranches) {
		shopName += ` - ${branch?.name}`;
	}
	const tax = _.sumBy(
		booking?.bookingDetails,
		(bookingDetail) =>
			(((bookingDetail.item?.price || 0) +
				(bookingDetail.extraAmount || 0) -
				(branch?.discountBeforeTax ? bookingDetail.discount || 0 : 0) -
				(branch?.discountBeforeTax
					? bookingDetail.promotionDiscount || 0
					: 0)) *
				(branch?.taxPercent || 0)) /
			100
	);
	return (
		<Col span={8} style={{ marginTop: -20 }}>
			<div className="bill-info-container full-width">
				<Image
					className="shop-logo box-shadow"
					src={!_.isEmpty(shop?.logoUrl) ? shop?.logoUrl : '/app_logo.png'}
				/>
				<div className="shop-info-container">
					<Typography.Title level={5}>{`${shopName}`}</Typography.Title>
					<Typography.Text type="secondary">{`${branch?.address?.street}, ${branch?.address?.city}, ${branch?.address?.state}`}</Typography.Text>
				</div>
				<BookingTimeInfo />
				<Divider />
				{!_.isEmpty(booking?.bookingDetails) ? (
					<>
						{booking?.bookingDetails?.map((bookingDetail) => {
							const discount =
								(bookingDetail.item?.price || 0) -
								(bookingDetail.discount || 0) -
								(bookingDetail.promotionDiscount || 0);
							return (
								<Row
									className="full-width"
									style={{ paddingLeft: 16, paddingRight: 16 }}
								>
									<Col
										style={{ display: 'flex', flexDirection: 'column' }}
										span={16}
									>
										<Typography.Text>
											{bookingDetail.item?.name}
										</Typography.Text>
										<Typography.Text type="secondary">
											{TimeHelper.convertDurationToStringDetail(
												bookingDetail.item?.duration || 0
											)}
										</Typography.Text>
									</Col>
									<Col
										style={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'flex-end',
										}}
										span={6}
									>
										{discount < bookingDetail.item?.price! && (
											<Typography.Text>
												{CurrencyHelper.formatPrice(discount)}
											</Typography.Text>
										)}
										<Typography.Text
											className={
												discount < bookingDetail.item?.price!
													? 'price-discounted'
													: ''
											}
											style={{ color: 'rgba(0, 0, 0, 0.45)' }}
										>
											{CurrencyHelper.formatPrice(bookingDetail.item?.price)}
										</Typography.Text>
									</Col>
									<Col span={2} style={{ display: 'flex', alignItems: 'end' }}>
										<DeleteOutlined
											style={{
												color: 'red',
												fontSize: '18px',
												marginBottom: '4px',
											}}
											onClick={() => {
												removeService(bookingDetail.item!);
											}}
										/>
									</Col>
								</Row>
							);
						})}
						<Divider />
						{!_.isEmpty(booking?.couponCodes) && (
							<Row
								className="full-width"
								style={{ paddingLeft: 16, paddingRight: 16 }}
							>
								<Col span={18}>
									<Typography.Title level={5} type="secondary">
										{I18n.t(_t(translations.customerBooking.couponCode))}
									</Typography.Title>
								</Col>
								<Col
									span={6}
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-end',
									}}
								>
									<Typography.Title level={5} type="secondary">
										{booking?.couponCodes}
									</Typography.Title>
								</Col>
							</Row>
						)}
						<Row
							className="full-width"
							style={{ paddingLeft: 16, paddingRight: 16 }}
						>
							<Col span={18}>
								<Typography.Title level={5} type="secondary">
									Taxes
								</Typography.Title>
							</Col>
							<Col
								span={6}
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'flex-end',
								}}
							>
								<Typography.Title level={5} type="secondary">
									{CurrencyHelper.formatPrice(tax)}
								</Typography.Title>
							</Col>
						</Row>
						<Row
							className="full-width"
							style={{ paddingLeft: 16, paddingRight: 16 }}
						>
							<Col span={18}>
								<Typography.Title level={5}>Total</Typography.Title>
							</Col>
							<Col
								span={6}
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'flex-end',
								}}
							>
								<Typography.Title level={5}>
									{CurrencyHelper.formatPrice(
										_.sumBy(
											booking?.bookingDetails,
											(x) =>
												x.item?.price! -
												(x.promotionDiscount || 0) -
												(x.discount || 0)
										) + tax
									)}
								</Typography.Title>
							</Col>
						</Row>
					</>
				) : null}
			</div>
		</Col>
	);
};

const WebLayOut = (props: IConfirm) => {
	const step = useGetStep();
	const isMultipleBranches = useCheckMultipleBranches();
	return (
		<Layout style={{ minHeight: '100vh', backgroundColor: '#EEEDED' }}>
			<CustomerHeader />
			<Content className="web-content-container full-height">
				<Row gutter={16} className="full-width full-height">
					<Col span={16} className="web-main-content-container full-height">
						{getMainByStep(step, isMultipleBranches)}
					</Col>
					{step > (isMultipleBranches ? 1 : 0) && <WebBillInfo />}
				</Row>
			</Content>
			<WebFooter onConfirm={() => props.onConfirm()} />
		</Layout>
	);
};

const MobileLayout = (props: IConfirm) => {
	const step = useGetStep();
	const isMultipleBranches = useCheckMultipleBranches();
	return (
		<Layout
			style={{ minHeight: window.innerHeight, backgroundColor: '#EEEDED' }}
		>
			<CustomerHeader />
			<Layout className="full-height">
				<div className="mobile-main-content-container full-height">
					{getMainByStep(step, isMultipleBranches)}
				</div>
			</Layout>
			{step > (isMultipleBranches ? 1 : 0) && (
				<MobileFooter onConfirm={() => props.onConfirm()} />
			)}
		</Layout>
	);
};

const CustomerBooking = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const shop = useSelector((state: RootState) => state.ShopReducer.shop);
	const branch = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);
	const branchAction = useSelector(
		(state: RootState) => state.ReduxActionReducer['BRANCH']
	);
	useEffect(() => {
		dispatch(BranchActions.getBranchesByShopId.request(shop?.id!));
	}, []);
	useEffect(() => {
		if (!_.isEmpty(shop)) {
			document.documentElement.style.setProperty(
				'--base-gradient-color',
				`${chroma(shop?.themeShopConfig?.defaultColor || 'black')}`
			);
			document.documentElement.style.setProperty(
				'--second-gradient-color',
				`${chroma(shop?.themeShopConfig?.defaultColor || 'black').darken(1.4)}`
			);
		}
	}, [shop]);
	// useSelectBranch();
	const validStep = useCheckValidStep();
	const createBooking = useConfirmBooking();

	if (!validStep) {
		return null;
	}
	if (createBooking.newBooking) {
		return <BookingSuccess booking={createBooking.newBooking} />;
	}
	return (
		<Spin spinning={false} style={{ height: window.innerHeight }}>
			{checkIsMobile() ? (
				<MobileLayout onConfirm={() => createBooking.confirmBooking()} />
			) : (
				<WebLayOut onConfirm={() => createBooking.confirmBooking()} />
			)}
		</Spin>
	);
};

export default CustomerBooking;
