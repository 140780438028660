import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row, Space, Modal, Button, Checkbox } from 'antd';
import { AlertHelper } from 'helpers';
import { IApiResponse, IErrorResponse, IService } from 'models';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PromotionApiService from 'services/PromotionApiService';
import { I18n, _t, translations } from 'utils';
import { Modal as ModalDelete } from 'components';
import { IPromotionItem } from 'models/IPromotion';
import { useMemo } from 'react';
import AppTable, { IColumnProps, ITableRef } from 'components/Table';
import { useRef } from 'react';
import './style.css';

export const Header = (props: { onSave: () => void }) => {
	return (
		<Row gutter={16} style={{ alignItems: 'center' }}>
			<Col md={12}></Col>
			<Col md={12}>
				<Space
					style={{ justifyContent: 'flex-end', width: '100%' }}
					size="small"
				>
					<Button type="primary" onClick={() => props.onSave()}>
						{I18n.t(_t(translations.save))}
					</Button>
				</Space>
			</Col>
		</Row>
	);
};

export const ServicesModal = (props: {
	onSave: (selectedRows: IService[]) => void;
	selectedRows: IService[];
}) => {
	const [selectedRows, setSelectedRows] = useState<IService[]>(
		props.selectedRows
	);

	const tableRef = useRef<ITableRef>();
	const checkIsSelect = (data: IService) => {
		let index = selectedRows.findIndex((x) => x.id === data.id);

		if (index !== -1) {
			return true;
		} else {
			return false;
		}
	};
	const columns = useMemo<IColumnProps<IService>[]>(() => {
		return [
			{
				header: '',
				key: 'id',
				type: 'text',
				renderCell: (data) => {
					return (
						<Checkbox
							onChange={(e) => {
								if (e.target.checked) {
									setSelectedRows([...selectedRows, data!]);
								} else {
									const newData = selectedRows.filter((e) => e.id !== data!.id);
									setSelectedRows(newData);
								}
							}}
							checked={checkIsSelect(data!)}
						></Checkbox>
					);
				},
				width: 20,
			},
			{
				header: I18n.t(_t(translations.servicesDetail.serviceName)),
				key: 'name',
				type: 'text',
			},
			{
				header: I18n.t(_t(translations.servicesDetail.duration)),
				key: 'duration',
				type: 'text',
				width: 200,
			},
			{
				header: I18n.t(_t(translations.billing.price)),
				key: 'price',
				type: 'text',
				width: 100,
			},
		];
	}, [selectedRows]);
	useEffect(() => {
		setSelectedRows(props.selectedRows);
	}, [props.selectedRows]);

	return (
		<>
			<AppTable<IService>
				ref={(ref) => {
					if (ref) {
						tableRef.current = ref;
					}
				}}
				columns={columns}
				queryUrl={'/Service/get-all-services'}
				showSearch
				renderHeader={<Header onSave={() => props.onSave(selectedRows)} />}
				selectedRows={selectedRows}
				keySelected={'id'}
			/>
		</>
	);
};

export const PromotionServiceTable = (props: {
	data?: IService[];
	setShowModalDelete: (value: boolean) => void;
	setServiceToDelete: (value: IService) => void;
}) => {
	const tableRef = useRef<ITableRef>();

	const columns = useMemo<IColumnProps<IService>[]>(() => {
		return [
			{
				header: I18n.t(_t(translations.servicesDetail.serviceName)),
				key: 'name',
				type: 'text',
			},
			{
				header: I18n.t(_t(translations.servicesDetail.duration)),
				key: 'duration',
				type: 'text',
				width: 200,
			},
			{
				header: I18n.t(_t(translations.billing.price)),
				key: 'price',
				type: 'text',
				width: 100,
			},
			{
				header: '',
				key: 'id',
				type: 'text',
				width: 100,
				renderCell: (data) => {
					return (
						<Space>
							<Button
								icon={<DeleteOutlined />}
								onClick={() => {
									props.setServiceToDelete(data!);
									props.setShowModalDelete(true);
								}}
							/>
						</Space>
					);
				},
			},
		];
	}, []);

	return (
		<>
			<AppTable<IService>
				ref={(ref) => {
					if (ref) {
						tableRef.current = ref;
					}
				}}
				columns={columns}
				queryUrl={''}
				data={props.data}
			/>
		</>
	);
};

export const AppliedToServices = (props: {
	isAdd: boolean;
	setServices: (value: string[]) => void;
}) => {
	const { id } = useParams() as { id: string };
	const [showModal, setShowModal] = useState(false);
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [serviceToDelete, setServiceToDelete] = useState<IService>();
	const [promotionServices, setPromotionServices] = useState<IService[]>([]);

	const onSave = (selectedRows: IService[]) => {
		props.isAdd ? setPromotionServices(selectedRows) : handleAdd(selectedRows);
		setShowModal(false);
	};
	const onDelete = async (value: IService) => {
		const newData = promotionServices.filter((e) => e.id !== value.id);
		if (props.isAdd) {
			setPromotionServices(newData);
		} else {
			try {
				const result = (await PromotionApiService.removePromotionItem({
					promotionId: id,
					itemIds: [value.id],
				})) as IApiResponse;
				if (result.succeeded) {
					AlertHelper.showSuccess(
						I18n.t(_t(translations.promotionDetail.messageDeletedItem))
					);
					fetchPromotionItem();
				} else {
					const error = result as IErrorResponse;
					AlertHelper.showError(error);
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const handleAdd = async (items: IService[]) => {
		try {
			const serviceKeys = items.map((item) => item.id);
			const result = (await PromotionApiService.updatePromotionItem({
				promotionId: id,
				itemIds: serviceKeys,
			})) as IApiResponse<IPromotionItem[]>;
			if (result.succeeded) {
				AlertHelper.showSuccess(
					I18n.t(_t(translations.promotionDetail.messageAddedItem))
				);
				fetchPromotionItem();
			} else {
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const fetchPromotionItem = async () => {
		try {
			const result = (await PromotionApiService.getPromotionItem({
				promotionId: id,
			})) as IApiResponse<IPromotionItem[]>;
			if (result.succeeded) {
				const items = result.data?.map((element) => element.item);
				setPromotionServices(items || []);
			}
		} catch (error) {}
	};

	useEffect(() => {
		if (!props.isAdd) fetchPromotionItem();
	}, []);

	useEffect(() => {
		const serviceKeys = promotionServices.map((item) => item.id);
		props.setServices(serviceKeys);
	}, [promotionServices]);

	return (
		<Row gutter={[16, 16]}>
			<Col span={4}>
				<Button
					onClick={(e) => {
						setShowModal(true);
					}}
					icon={<PlusOutlined />}
					type="primary"
				>
					{I18n.t(_t(translations.addNew))}
				</Button>
			</Col>
			<Col span={24}>
				<PromotionServiceTable
					data={promotionServices}
					setServiceToDelete={(value) => setServiceToDelete(value)}
					setShowModalDelete={(value) => setShowModalDelete(value)}
				/>
				<Modal
					visible={showModal}
					title={I18n.t(_t(translations.promotionDetail.modalTitle))}
					onCancel={() => {
						setShowModal(false);
					}}
					footer={null}
					width={1000}
				>
					<ServicesModal
						onSave={(selectedRows) => onSave(selectedRows)}
						selectedRows={promotionServices}
					/>
				</Modal>
			</Col>

			<ModalDelete
				visible={showModalDelete}
				title={I18n.t(_t(translations.delete))}
				handleOk={() => {
					onDelete(serviceToDelete!);
					setShowModalDelete(false);
				}}
				handleCancel={() => {
					setShowModalDelete(false);
				}}
			>
				{I18n.t(_t(translations.promotionDetail.deleteConfirmContent))}
			</ModalDelete>
		</Row>
	);
};
