/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
	ContentAdminContainer,
	DatePickerModal,
	DateTypePickerModal,
} from 'components';
import { IBaseComponentScreenProps } from 'models/BaseScreenProps';
import { I18n, _t, translations } from 'utils';
import { Button, Col, Input, Row, Space, Table, Typography } from 'antd';
import { DateRangeSelect } from 'components';
import { IApiResponse, IErrorResponse, IExpense, IExpenseType } from 'models';
import moment from 'moment';
import { DateRangeType, DATE_TYPE, EDatePickerType } from 'utils/Consts';
import {
	CalendarOutlined,
	PlusOutlined,
	ProjectOutlined,
} from '@ant-design/icons';
import { ExpenseDetailModal, ExpenseTypePickerModal } from './components';
import './styles.css';
import { ExpenseApiService, ExpenseTypeApiService } from 'services';
import { IPaginateResponse } from 'models/ResponseModels';
import { AlertHelper, BookingHelper, CurrencyHelper } from 'helpers';
import { useDispatch } from 'react-redux';
import { ExpenseTypeActions } from 'redux/actions';
import { ALL } from './components/ExpenseTypePickerModal';
import { ExpenseFormData } from './components/ExpenseDetailModal';
import styles from './styles';
import { IGetAllExpenseRequest } from 'models/RequestModels';

interface IExpensesProps extends IBaseComponentScreenProps {}
const Expenses = ({ location }: IExpensesProps) => {
	const [selectedDateType, setselectedDateType] = useState<typeof DATE_TYPE[0]>(
		DATE_TYPE[0]
	);
	const [fromDate, setFromDate] = useState<moment.Moment>(
		moment().startOf('date')
	);
	const [toDate, setToDate] = useState<moment.Moment>(moment().startOf('date'));
	const [datePickerType, setDatePickerType] = useState<EDatePickerType>(
		EDatePickerType.FROM_DATE
	);
	const [selectedExpenseType, setSelectedExpenseType] =
		useState<IExpenseType>(ALL);
	const [isShowDateRangePicker, setIsShowDateRangePicker] =
		useState<boolean>(false);
	const [isShowDatePicker, setIsShowDatePicker] = useState<boolean>(false);
	const [isShowExpenseTypePickerModal, setIsShowExpenseTypePickerModal] =
		useState<boolean>(false);

	const [isShowExpenseDetailModal, setIsShowExpenseDetailModal] =
		useState<boolean>(false);
	const [isShowDateTypePickerModal, setIsShowDateTypePickerModal] =
		useState<boolean>(false);
	const [expenses, setExpenses] = useState<IPaginateResponse<IExpense[]>>();
	const dispatch = useDispatch();
	const [isUpdateExpenseLoading, setIsUpdateExpenseLoading] =
		useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [page, setPage] = useState<number>(1);
	const [selectedExpense, setSelectedExpense] = useState<IExpense>();

	useEffect(() => {
		getAllInitialData();
	}, []);

	useEffect(() => {
		getAllExpenses({
			pageNumber: page,
			pageSize: 9,
			fromDate: BookingHelper.convertDateRequest(fromDate),
			toDate: BookingHelper.convertDateRequest(toDate),
			expenseTypeId: selectedExpenseType.id,
			orderBy: 'date desc',
		});
	}, [selectedExpenseType, fromDate, toDate, page]);

	const renderHeader = () => {
		return (
			<>
				<Row className="max-width header" gutter={[16, 16]}>
					<Col xs={12} sm={12} md={8} lg={5}>
						<Input
							className="uneditable-input-with-icon"
							suffix={<ProjectOutlined />}
							allowClear={false}
							value={selectedExpenseType.description}
							onClick={() => setIsShowExpenseTypePickerModal(true)}
							contentEditable={false}
						/>
					</Col>
					{renderDateTypeSelect()}
					{isShowDateRangePicker && (
						<DateRangeSelect
							fromDate={fromDate}
							toDate={toDate}
							onDateRangeButtonClick={onDateRangeButtonClicked}
						/>
					)}
					<Col flex={1}>
						<Space style={styles.addNewBtnContainer} size="small">
							<Button
								icon={<PlusOutlined />}
								type="primary"
								onClick={() => {
									setSelectedExpense(undefined);
									setIsShowExpenseDetailModal(true);
								}}
							>
								{I18n.t(_t(translations.addNew))}
							</Button>
						</Space>
					</Col>
				</Row>
			</>
		);
	};

	const renderDateTypeSelect = () => {
		return (
			<Col xs={12} sm={12} md={8} lg={5}>
				<Input
					className="uneditable-input"
					value={selectedDateType.title}
					suffix={<CalendarOutlined />}
					onClick={() => setIsShowDateTypePickerModal(true)}
				/>
			</Col>
		);
	};

	const renderExpenseTable = () => {
		return (
			<Space
				direction="vertical"
				className="max-width"
				style={styles.expenseTableContainer}
			>
				<Table<IExpense>
					dataSource={expenses?.data}
					pagination={{
						pageSize: expenses?.pageSize,
						hideOnSinglePage: true,
						total: expenses?.totalRecords,
						onChange: (page: number) => setPage(page),
					}}
					loading={isLoading}
					onRow={(record) => ({
						onClick: () => {
							setSelectedExpense(record);
							setIsShowExpenseDetailModal(true);
						},
						className: 'expense-row',
					})}
				>
					{renderDateCol()}
					{renderExpenseTypeCol()}
					{renderExpenseAmountCol()}
					{renderResponsibilityCol()}
					{renderNoteCol()}
				</Table>
			</Space>
		);
	};

	const renderDateCol = () => {
		return (
			<Table.Column<IExpense>
				render={(_, record) => {
					return moment(record.date).format('MMMM DD, YYYY');
				}}
				title={
					<Typography.Text>
						{I18n.t(_t(translations.expenses.paymentDate))}
					</Typography.Text>
				}
			/>
		);
	};

	const renderExpenseTypeCol = () => {
		return (
			<Table.Column<IExpense>
				render={(_, record) => {
					return record.description;
				}}
				title={
					<Typography.Text>
						{I18n.t(_t(translations.expenses.expenseType))}
					</Typography.Text>
				}
			/>
		);
	};

	const renderExpenseAmountCol = () => {
		return (
			<Table.Column<IExpense>
				render={(_, record) => {
					return CurrencyHelper.formatPrice(record.amount);
				}}
				title={
					<Typography.Text>
						{I18n.t(_t(translations.expenses.paymentAmount))}
					</Typography.Text>
				}
			/>
		);
	};
	const renderResponsibilityCol = () => {
		return (
			<Table.Column<IExpense>
				render={(_, record) => {
					return (
						(record.employee?.firstName || '') +
						' ' +
						(record.employee?.lastName || '')
					);
				}}
				title={
					<Typography.Text>
						{I18n.t(_t(translations.expenses.paymentResponsibility))}
					</Typography.Text>
				}
			/>
		);
	};
	const renderNoteCol = () => {
		return (
			<Table.Column<IExpense>
				render={(_, record) => {
					return record.note;
				}}
				title={
					<Typography.Text>
						{I18n.t(_t(translations.expenses.note))}
					</Typography.Text>
				}
			/>
		);
	};

	const onChooseDateType = (type: typeof DATE_TYPE[0]) => {
		setselectedDateType(type);
		switch (type.value) {
			case DateRangeType.TODAY:
				setFromDate(moment());
				setToDate(moment());
				setIsShowDateRangePicker(false);
				break;
			case DateRangeType.YESTERDAY:
				setFromDate(moment().add(-1, 'day'));
				setToDate(moment().add(-1, 'day'));
				setIsShowDateRangePicker(false);
				break;
			case DateRangeType.WEEKLY:
				setFromDate(moment().startOf('week'));
				setToDate(moment().endOf('week'));
				setIsShowDateRangePicker(false);
				break;
			case DateRangeType.SEMI_MONTH_1st:
				setFromDate(moment().startOf('month'));
				setToDate(moment().startOf('month').add(14, 'day'));
				setIsShowDateRangePicker(false);
				break;
			case DateRangeType.SEMI_MONTH_2nd:
				setFromDate(moment().startOf('month').add(15, 'day'));
				setToDate(moment().endOf('month'));
				setIsShowDateRangePicker(false);
				break;
			case DateRangeType.MONTHLY:
				setFromDate(moment().startOf('month'));
				setToDate(moment().endOf('month'));
				setIsShowDateRangePicker(false);
				break;
			case DateRangeType.DATE_RANGE:
				setFromDate(moment());
				setToDate(moment());
				setIsShowDateRangePicker(true);
				break;
			case DateRangeType.LAST_MONTH:
				setFromDate(moment().add(-1, 'month').startOf('month'));
				setToDate(moment().add(-1, 'month').endOf('month'));
				setIsShowDateRangePicker(false);
				break;
			default:
				break;
		}
		setIsShowDateTypePickerModal(false);
	};

	const onDateRangeButtonClicked = (type: EDatePickerType) => {
		setIsShowDatePicker(true);
		setDatePickerType(type);
	};

	const onPickedDate = (date: moment.Moment) => {
		switch (datePickerType) {
			case EDatePickerType.FROM_DATE: {
				setFromDate(date);
				break;
			}
			case EDatePickerType.TO_DATE: {
				setToDate(date);
				break;
			}
		}
		setIsShowDatePicker(false);
	};

	const onFormFinish = (formData: ExpenseFormData) => {
		const newExpense: Partial<IExpense> = {
			amount: formData.amount,
			date: BookingHelper.convertDateRequest(formData.date?.value!),
			description: formData.expenseType.description,
			type: formData.expenseType.code,
			// employeeId: formData.employee.id,
			note: formData.note,
		};
		if (!selectedExpense) {
			createNewExpense(newExpense);
		} else {
			updateExpense({ ...selectedExpense, ...newExpense });
		}
	};

	const getAllInitialData = async () => {
		try {
			const requests = [
				ExpenseTypeApiService.getAllExpenseTypes({
					pageNumber: 1,
					pageSize: 1000,
				}),
			];
			const resultArr = (await Promise.all(requests)) as [
				IPaginateResponse<IExpenseType[]>
			];
			if (resultArr.every((element) => element.succeeded)) {
				dispatch(
					ExpenseTypeActions.getAllExpenseTypes.success(resultArr[0].data!)
				);
			} else {
				AlertHelper.showError(
					resultArr.find((element) => !element.succeeded) as IErrorResponse
				);
			}
		} catch (error) {
			console.error('Network error', error);
		}
	};

	const createNewExpense = async (expense: Partial<IExpense>) => {
		setIsUpdateExpenseLoading(true);
		try {
			const result = (await ExpenseApiService.createExpense(
				expense
			)) as IApiResponse<IExpense>;
			if (result.succeeded) {
				AlertHelper.showSuccess(
					I18n.t(_t(translations.expenses.createExpenseSuccess))
				);
				setIsShowExpenseDetailModal(false);
				getAllExpenses({
					pageNumber: page,
					pageSize: 9,
					fromDate: BookingHelper.convertDateRequest(fromDate),
					toDate: BookingHelper.convertDateRequest(toDate),
					expenseTypeId: selectedExpenseType.id,
					orderBy: 'date desc',
				});
			} else {
				AlertHelper.showError(result as IErrorResponse);
			}
		} catch (error) {
			console.error('Network Error', error);
		} finally {
			setIsUpdateExpenseLoading(false);
		}
	};

	const getAllExpenses = async (request: IGetAllExpenseRequest) => {
		setIsLoading(true);
		try {
			const result = (await ExpenseApiService.getAllExpenses(
				request
			)) as IPaginateResponse<IExpense[]>;
			if (result.succeeded) {
				setExpenses(result);
			} else {
				AlertHelper.showError(result as IErrorResponse);
			}
		} catch (error) {
			console.error('Network error', error);
		} finally {
			setIsLoading(false);
		}
	};

	const updateExpense = async (request: IExpense) => {
		setIsUpdateExpenseLoading(true);
		try {
			const result = (await ExpenseApiService.updateExpense(
				request
			)) as IApiResponse<IExpense>;
			if (result.succeeded) {
				const newExpenses = Object.assign({}, expenses);
				newExpenses.data = newExpenses.data?.map((element) =>
					element.id === result.data?.id ? result.data : element
				);
				setExpenses(newExpenses);
				AlertHelper.showSuccess(
					I18n.t(_t(translations.expenses.updateExpenseSuccess))
				);
				setIsShowExpenseDetailModal(false);
			} else {
				AlertHelper.showError(result as IErrorResponse);
			}
		} catch (error) {
			console.error('Network error', error);
		} finally {
			setIsUpdateExpenseLoading(false);
		}
	};

	const deleteExpense = async (id: string) => {
		setIsUpdateExpenseLoading(true);
		try {
			const result = (await ExpenseApiService.deleteExpense(
				id
			)) as IApiResponse<string>;
			if (result.succeeded) {
				const newExpenses = Object.assign({}, expenses);
				newExpenses.data = newExpenses.data?.filter(
					(element) => element.id !== result.data
				);
				setExpenses(newExpenses);
				AlertHelper.showSuccess(
					I18n.t(_t(translations.expenses.deleteExpenseSuccess))
				);
				setIsShowExpenseDetailModal(false);
			} else {
				AlertHelper.showError(result as IErrorResponse);
			}
		} catch (error) {
			console.error('Network error', error);
		} finally {
			setIsUpdateExpenseLoading(false);
		}
	};

	return (
		<ContentAdminContainer>
			{renderHeader()}
			{isShowDatePicker && (
				<DatePickerModal
					onCancel={() => setIsShowDatePicker(false)}
					onPickedDate={onPickedDate}
					selectedDate={
						datePickerType === EDatePickerType.FROM_DATE ? fromDate : toDate
					}
				/>
			)}
			{renderExpenseTable()}
			<ExpenseDetailModal
				isShowModal={isShowExpenseDetailModal}
				onCancel={() => setIsShowExpenseDetailModal(false)}
				onDelete={deleteExpense}
				onFormFinish={onFormFinish}
				isLoading={isUpdateExpenseLoading}
				selectedExpense={selectedExpense}
			/>
			<ExpenseTypePickerModal
				isShowModal={isShowExpenseTypePickerModal}
				onCancel={() => setIsShowExpenseTypePickerModal(false)}
				onChooseType={(type) => {
					setSelectedExpenseType(type);
					setIsShowExpenseTypePickerModal(false);
				}}
				selectedType={selectedExpenseType}
				showAll
			/>
			<DateTypePickerModal
				isShowModal={isShowDateTypePickerModal}
				onCancel={() => setIsShowDateTypePickerModal(false)}
				onChooseDateType={onChooseDateType}
				selectedDateType={selectedDateType}
			/>
		</ContentAdminContainer>
	);
};

export default Expenses;
