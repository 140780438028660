import Layout from 'antd/lib/layout';
import Menu from 'antd/lib/menu';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { RootState } from 'redux/configuration/rootReducer';
import { HomeRoute } from 'routers/routes';

const { SubMenu } = Menu;
interface IAppMenuProps
	extends RouteComponentProps,
		ReturnType<typeof mapStateToProps> {}

class AppMenu extends React.Component<IAppMenuProps> {
	render() {
		return (
			<Layout style={{ backgroundColor: 'white' }} {...this.props}>
				<Menu mode="inline" style={{ background: 'white' }}>
					{HomeRoute.filter((r) => r.showInMenu).map((route) => {
						if (
							route.mainMobile ||
							(!_.isEmpty(route.roles) &&
								_.isEmpty(
									_.intersection(route.roles, this.props.user!.userRoles)
								) &&
								!_.includes(route.roles, this.props.employee?.employeeTypeCode))
						) {
							return null;
						}
						if (!_.isEmpty(route.subMenu)) {
							return (
								<SubMenu
									style={{ backgroundColor: 'white' }}
									key={route.key}
									icon={route.icon}
									title={route.name}
								>
									{route.subMenu?.map((subRoute) => (
										<Menu.Item
											style={{ backgroundColor: 'white' }}
											key={subRoute.path}
											icon={subRoute.icon}
										>
											<span>{subRoute.name}</span>
											<Link to={subRoute.path} />
										</Menu.Item>
									))}
								</SubMenu>
							);
						}
						return (
							<Menu.Item key={route.path} icon={route.icon}>
								<span>{route.name}</span>
								<Link to={route.path!} />
							</Menu.Item>
						);
					})}
				</Menu>
			</Layout>
		);
	}
}

const mapStateToProps = (state: RootState) => ({
	loading: state.AppConfigReducer.showLoading,
	user: state.UserReducer.user,
	employee: state.UserReducer.employee,
	currentBranch: state.BranchReducer.currentBranch,
	branchAction: state.ReduxActionReducer['BRANCH'],
});
export default connect(mapStateToProps)(AppMenu);
