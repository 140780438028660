import { kStyles } from "GlobalStyles";

const styles: kStyles = {
  checkBoxCol: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  container: {
    marginTop: 8,
    marginBottom: 8,
  },

  dayText: {
    marginLeft: 16,
  },
};

export default styles;
