import { Col, Input, Radio, Row, Select, Space, Typography } from 'antd';
import { AppSelect, Button } from 'components';
import { CurrencyHelper } from 'helpers';
import { priceFixed } from 'helpers/currencyHelper';
import _, { sumBy } from 'lodash';
import { IBookingDetail, IEmployee } from 'models';
import { IEmployeeTip } from 'models/IEmployeeTip';
import { ITip } from 'models/ITip';
import React from 'react';
import { connect } from 'react-redux';
import { CheckoutActions } from 'redux/actions';
import configureStore from 'redux/configuration/configureStore';
import { RootState } from 'redux/configuration/rootReducer';
import { price } from 'redux/selectors/checkout';
import { translations, _t, I18n } from 'utils';
import { PriceType, TipType } from 'utils/Consts';
import CheckOutBaseModal from './CheckOutBaseModal';
import styles from './styles';

interface IAddTipModalProps extends ReturnType<typeof mapStateToProps> {
	onOk(tip: ITip, tipValue: number): void;
	onCancel(): void;
	tip: ITip;
	stylists: (IEmployee | undefined)[];
	allBookingDetails: Partial<IBookingDetail>[];
}

interface IAddTipModalState {
	tip?: number;
	stylistId?: string;
	type: TipType;
}

class AddTipModal extends React.Component<
	IAddTipModalProps,
	IAddTipModalState
> {
	constructor(props: IAddTipModalProps) {
		super(props);
		this.state = {
			type: TipType.AUTO,
		};
	}
	inputRef: any = React.createRef();
	listTipAuto: number[] = _.uniqBy(this.props.stylists, (x) => x?.id).map(
		(y) => 0
	);
	render() {
		return (
			<CheckOutBaseModal
				title={
					this.props.tip?.totalTip
						? I18n.t(_t(translations.checkout.changeTip))
						: I18n.t(_t(translations.checkout.addTip))
				}
				onOk={() => this.onOk()}
				disableOk={
					!this.state.tip ||
					this.state.tip <= 0 ||
					(this.state.type === TipType.MANUALLY &&
						_.isEmpty(this.state.stylistId))
				}
				onCancel={this.props.onCancel}
			>
				<Space size="middle" direction="vertical" className="full-width">
					<Col>
						<Typography.Title level={5}>
							{I18n.t(_t(translations.checkout.tipAmount))}
						</Typography.Title>
						<Row gutter={8}>
							<Col md={20}>
								<Input
									ref={this.inputRef}
									addonAfter="$"
									style={styles.inputNumber}
									type="number"
									autoFocus={true}
									value={this.state.tip || ''}
									onChange={(e) => this.onChangeTip(_.toNumber(e.target.value))}
								/>
							</Col>
							<Col md={4}>
								<Button
									onClick={() => {
										this.setState({ tip: undefined });
										this.inputRef.current?.focus();
									}}
									danger
								>
									{I18n.t(_t(translations.clear))}
								</Button>
							</Col>
						</Row>
					</Col>
					<Col>
						<Radio.Group
							defaultValue={this.state.type}
							buttonStyle="solid"
							style={styles.maxWidth}
							onChange={(e) => this.onChangeType(e.target.value)}
						>
							<Radio.Button
								style={styles.buttonTipTypeContainer}
								value={TipType.AUTO}
							>
								{I18n.t(_t(translations.checkout.autoTips))}
							</Radio.Button>
							<Radio.Button
								style={styles.buttonTipTypeContainer}
								value={TipType.SPLIT_EVEN}
							>
								{I18n.t(_t(translations.checkout.splitEven))}
							</Radio.Button>
							<Radio.Button
								style={styles.buttonTipTypeContainer}
								value={TipType.MANUALLY}
							>
								{I18n.t(_t(translations.checkout.manually))}
							</Radio.Button>
						</Radio.Group>
					</Col>
					{![TipType.AUTO, TipType.SPLIT_EVEN].includes(this.state.type) && (
						<Col>
							<Typography.Title level={5}>
								{I18n.t(_t(translations.checkout.stylistTip))}
							</Typography.Title>
							{this.renderSelectStylist()}
						</Col>
					)}
					{this.renderTipFor()}
				</Space>
			</CheckOutBaseModal>
		);
	}

	renderTipFor = () => {
		const stylists = this.props.allBookingDetails.map((x) => x.stylist);
		const money = priceFixed(
			priceFixed((this.state.tip || 0) / stylists.length) *
				(stylists.length - 1)
		);

		if (
			this.state.type === TipType.SPLIT_EVEN ||
			this.state.type === TipType.AUTO
		) {
			return _.uniqBy(stylists, (y) => y?.id).map((x, i) => {
				return (
					<Row justify="space-between" style={{ paddingInline: 8 }}>
						<Typography.Title level={5}>
							{I18n.t(_t(translations.checkout.tipFor))}{' '}
							{`${x?.firstName || ''} ${x?.lastName || ''}`}
						</Typography.Title>
						<Typography.Title level={5} style={{ display: 'contents' }}>
							{CurrencyHelper.formatPrice(this.getPrice(x!, i))}
						</Typography.Title>
					</Row>
				);
			});
		}
		return null;
	};
	getPrice = (stylist: IEmployee, i: number) => {
		const stylists = this.props.allBookingDetails.map((x) => x.stylist);

		const averageTip = priceFixed(
			(this.state.tip || 0) /
				(this.state.type === TipType.AUTO
					? stylists.length
					: _.uniqBy(stylists, (y) => y?.id).length)
		);

		//new

		const totalService = this.props.price(PriceType.SUB_TOTAL)!;

		const bookingSameStylist = this.props.allBookingDetails.filter(
			(x) => x.stylistId === stylist.id
		);
		const totalServiceSameStylist = _.sumBy(
			bookingSameStylist,
			(x) => (x?.price || 0) + (x?.extraAmount || 0)
		);

		const tipAutoPercent = priceFixed(
			(totalServiceSameStylist / totalService) * 100
		);

		const tipAuto = priceFixed((tipAutoPercent * (this.state.tip || 0)) / 100);

		this.listTipAuto[i] = tipAuto;

		//split even
		const lastSplitTip = priceFixed(
			(this.state.tip || 0) -
				averageTip * (_.uniqBy(stylists, (y) => y?.id).length - 1)
		);
		// auto tip
		// const stylistsCount = _.countBy(stylists, (x) => x?.id);

		// const money = averageTip * stylistsCount[`${stylist.id}`];

		let lastAutoTip = priceFixed(
			(this.state.tip || 0) - _.sumBy(this.listTipAuto.slice(0, -1), (x) => x)
		);

		if (this.state.type === TipType.SPLIT_EVEN) {
			return i !== _.uniqBy(stylists, (x) => x?.id).length - 1
				? averageTip
				: lastSplitTip;
		}
		if (this.state.type === TipType.AUTO) {
			return i !== _.uniqBy(stylists, (x) => x?.id).length - 1
				? tipAuto
				: lastAutoTip;
		}
	};
	renderSelectStylist() {
		return (
			<AppSelect
				value={this.state.stylistId}
				placeholder="Select Stylist"
				onChange={(stylistId) => this.setState({ stylistId })}
			>
				{this.getAvailableStylist().map((stylist) => (
					<Select.Option key={stylist!.id} value={stylist!.id!}>
						{stylist!.firstName} {stylist!.lastName}
					</Select.Option>
				))}
			</AppSelect>
		);
	}
	getTippedStylistId() {
		if (_.isEmpty(this.props.tip)) {
			return [];
		}
		return this.props.tip.tips?.map((employeeTip) => employeeTip.employeeId);
	}
	getAvailableStylist() {
		const tippedStylist = this.getTippedStylistId();
		return _.filter(
			this.props.stylists,
			(bookingStylist) => !_.includes(tippedStylist, bookingStylist?.id)
		);
	}
	onChangeTip = (tip: number) => {
		this.setState({ tip });
	};
	onChangeType = (type: TipType) => {
		this.setState({ type });
	};

	onOk = () => {
		let tip: ITip;
		if ([TipType.AUTO, TipType.SPLIT_EVEN].includes(this.state.type)) {
			tip = {
				totalTip: this.state.tip,
			};
		} else {
			tip = Object.assign({}, this.props.tip);
			const employeeTip: IEmployeeTip = {
				employeeId: this.state.stylistId!,
				tipAmount: this.state.tip!,
			};
			tip.totalTip = undefined;
			if (tip.tips) {
				const employeeTips = Array.from(tip.tips);
				employeeTips.push(employeeTip);
				tip.tips = employeeTips;
			} else {
				tip.tips = [employeeTip];
			}
		}
		configureStore().store.dispatch(
			CheckoutActions.addTipTypeLocal.request(this.state.type)
		);
		this.props.onOk(tip, this.state.tip || 0);
	};
}

const mapStateToProps = (state: RootState) => ({
	price: (priceType: PriceType) => price(state, priceType),
});

export default connect(mapStateToProps)(AddTipModal);
