import React, { Component } from "react";
import { Row, Col, Typography, InputProps, Space } from "antd";
import { Input, Button } from "components";
import styles from "components/styles";
import _ from "lodash";
import { translations, I18n, _t } from "utils";
import { MaskedInput } from "antd-mask-input";

interface ICustomerInputProps extends InputProps {
  title: string;
  handelButtonName(inputValue: string): string;
  onOk(value: string): void;
  error?: string;
  onCancel?(): void;
  phoneInput?: boolean;
}

interface ICustomerInputStates {
  value?: string;
}

export default class CustomerInput extends Component<
  ICustomerInputProps,
  ICustomerInputStates
> {
  state = {
    value: "",
  };
  render() {
    return (
      <Row justify="end" gutter={[16, 20]} style={styles.itemContainer}>
        <Col span={24}>
          <Typography.Title level={4}>{this.props.title}</Typography.Title>
          {!this.props.phoneInput && (
            <Input
              {...this.props}
              style={styles.maxWidth}
              autoFocus={true}
              value={this.state.value}
              onChange={(event) => this.setState({ value: event.target.value })}
              onPressEnter={() => {
                // if (!this.state.value) {
                //     return;
                // }
                this.props.onOk(this.state.value!);
              }}
            />
          )}
          {this.props.phoneInput && (
            <MaskedInput
              style={styles.maxWidth}
              value={this.state.value}
              autoFocus={true}
              onChange={(e) => this.setState({ value: e.target.value })}
              onPressEnter={() => {
                this.props.onOk(this.state.value!);
              }}
              mask="111-111-1111"
            />
          )}

          {this.props.error && (
            <Typography.Text type="danger">{this.props.error}</Typography.Text>
          )}
        </Col>
        <Space>
          {this.props.onCancel ? (
            <Button
              style={styles.maxWidth}
              onClick={() => this.props.onCancel!()}
              size="large"
              type="primary"
            >
              {I18n.t(_t(translations.back))}
            </Button>
          ) : null}

          <Button
            style={styles.maxWidth}
            // disabled={_.isEmpty(this.state.value)}
            onClick={() => this.props.onOk(this.state.value!)}
            size="large"
            type="primary"
          >
            {this.props.handelButtonName(this.state.value)}
          </Button>
        </Space>
      </Row>
    );
  }
}
