import React, { Component } from 'react';
import {
	Button,
	Card,
	Col,
	Dropdown,
	Input,
	Menu,
	Row,
	Space,
	Tabs,
	Typography,
} from 'antd';
import {
	EmployeePickerModal,
	IComponentProps,
	Button as AppButton,
	Spin,
	DateTypePickerModal,
} from 'components';
import ReactToPrint from 'react-to-print';
import {
	IApiResponse,
	IEmployee,
	IErrorResponse,
	IPayrollDetail,
	IPayrollDetailDetail,
	IPayrollOverview,
	IPayrollOverviewDetail,
} from 'models';
import moment, { Moment } from 'moment';
import { DateRangeType, DATE_TYPE } from 'utils/Consts';
import {
	CalendarOutlined,
	DownloadOutlined,
	UserOutlined,
	PrinterOutlined,
} from '@ant-design/icons';
import { I18n, translations, _t, Colors } from 'utils';
import {
	AlertHelper,
	BookingHelper,
	CurrencyHelper,
	ValidationHelper,
} from 'helpers';
import { withRouter } from 'react-router-dom';
import GlobalStyles, { kStyles } from 'GlobalStyles';
import { ReportApiService } from 'services';
import {
	DetailTable,
	FeeAndPayment,
	ServedServices,
	SideView,
} from '../components';
import { createESCPayrollDetail, print } from 'helpers/printHelper';
import { DateRangePicker } from 'components/DatePicker/DateRangePicker';
import NCard from 'components/Card';

interface IMainViewProps extends IComponentProps {}
interface IMainViewStates {
	showPreview?: boolean;
	payrollOverviewData?: IPayrollOverview;
	payrollDetailData?: IPayrollDetail;
	printType?: PrintType;
	showDateRange: boolean;
	startDate: Moment;
	endDate: Moment;
	isStartDate?: boolean;
	showEmployeePickerModal?: boolean;
	selectedEmployee?: IEmployee;
	showDatePickerModal?: boolean;
	selectedBookingIndex?: number;
	selectedBooking?: IPayrollDetailDetail | IPayrollOverviewDetail;
	isDetail?: boolean;
	isLoading?: boolean;
	isShowDateTypePickerModal: boolean;
	selectedDateType: typeof DATE_TYPE[0];
}

enum PrintType {
	ONE,
	ALL,
}

const { Text, Title } = Typography;

export class MainView extends Component<IMainViewProps, IMainViewStates> {
	constructor(props: IMainViewProps) {
		super(props);
		this.state = {
			showDateRange: true,
			startDate: moment(),
			endDate: moment(),
			isShowDateTypePickerModal: false,
			selectedDateType: DATE_TYPE[0],
		};
	}

	componentDidUpdate(_: any, prevState: IMainViewStates) {
		this.dateDidUpdate(prevState);
	}

	printer?: ReactToPrint | null;

	render() {
		return (
			<>
				<Row gutter={[0, 8]}>
					<Col sm={24} md={24} lg={16} style={styles.containerCol}>
						{this.renderHeader()}
						{this.state.isLoading ? (
							<Spin />
						) : (
							this.state.payrollOverviewData &&
							this.state.payrollDetailData &&
							this.renderMainViewTabs()
						)}
					</Col>
					<Col sm={24} md={24} lg={8} style={styles.containerCol}>
						{this.state.isLoading ? (
							<Spin />
						) : (
							this.state.payrollOverviewData &&
							this.state.payrollDetailData && (
								<SideView
									isDetail={this.state.isDetail || false}
									isAllTime={this.isAllTime()}
									payrollOverviewData={this.state.payrollOverviewData!}
									payrollDetailData={this.state.payrollDetailData!}
								/>
							)
						)}
					</Col>
				</Row>
				{this.state.showEmployeePickerModal && this.renderEmployeePickerModal()}
				<DateTypePickerModal
					isShowModal={this.state.isShowDateTypePickerModal}
					onCancel={() => this.setState({ isShowDateTypePickerModal: false })}
					onChooseDateType={(dateType) => {
						this.setState(
							{
								isShowDateTypePickerModal: false,
								showDateRange: false,
							},
							() => this.onChangeDateRangeType(dateType)
						);
					}}
					selectedDateType={this.state.selectedDateType}
				/>
			</>
		);
	}

	renderHeader = () => {
		return (
			<Row gutter={[8, 8]} justify="space-between">
				<Col flex={1}>{this.renderControllers()}</Col>
				{/* <Col>{this.renderFunctionalButton()}</Col> */}
			</Row>
		);
	};

	renderControllers() {
		return (
			<Row gutter={[8, 8]}>
				{this.renderEmployeePicker()}
				{this.renderDateTypeSelect()}
				{this.state.showDateRange && this.renderDateRangeSelect()}
				{/* {this.renderApplyButton()} */}
			</Row>
		);
	}

	renderEmployeePicker() {
		return (
			<Col xs={24} sm={24} md={12} lg={6}>
				<Input
					className="uneditable-input-with-icon"
					style={styles.maxWidth}
					suffix={<UserOutlined />}
					allowClear={false}
					value={
						this.state.selectedEmployee
							? `${this.state.selectedEmployee.firstName} ${this.state.selectedEmployee.lastName}`
							: I18n.t(_t(translations.payrollDetailReport.searchEmployee))
					}
					onClick={() => this.setState({ showEmployeePickerModal: true })}
				/>
			</Col>
		);
	}

	renderDateTypeSelect() {
		// const dropdownStyle = { borderWidth: 1, borderColor: 'red' };
		return (
			<Col xs={12} sm={12} md={8} lg={5}>
				{/* <Select
					defaultValue={this.state.selectedDateType}
					style={styles.maxWidth}
					onChange={this.onChangeDateRangeType}
					allowClear={false}
					suffixIcon={<CalendarOutlined />}
					dropdownStyle={dropdownStyle}
				>
					{DATE_RANGE.map((dateRange) => {
						return (
							<Select.Option key={dateRange.id} value={dateRange.value}>
								{dateRange.title}
							</Select.Option>
						);
					})}
				</Select> */}
				<Input
					className="uneditable-input-with-icon"
					value={this.state.selectedDateType.title}
					suffix={<CalendarOutlined />}
					onClick={() => this.setState({ isShowDateTypePickerModal: true })}
				/>
			</Col>
		);
	}

	renderDateRangeSelect() {
		return (
			<DateRangePicker
				onSelectDateRange={(dateRage) => {
					this.setState(
						{
							startDate: dateRage.fromDate!,
							endDate: dateRage.toDate!,
						},
						() => {
							this.canApply() && this.getPayrollData();
						}
					);
				}}
				selectedDateRange={{
					fromDate: this.state.startDate,
					toDate: this.state.endDate,
				}}
				disabled={
					this.state.selectedDateType.value !== DateRangeType.DATE_RANGE
				}
			/>
		);
	}

	renderApplyButton() {
		return (
			<Col xs={12} sm={12} md={5} lg={2}>
				<AppButton
					type="primary"
					disabled={!this.canApply()}
					onClick={this.getPayrollData}
				>
					{I18n.t(_t(translations.payrollDetailReport.apply))}
				</AppButton>
			</Col>
		);
	}

	renderFunctionalButton() {
		return (
			<Space>
				<Dropdown overlay={this.renderMenuExport} trigger={['click']}>
					<Button type="default" icon={<DownloadOutlined />}>
						{I18n.t(_t(translations.services.btnExport))}
					</Button>
				</Dropdown>
			</Space>
		);
	}

	renderMenuExport = () => (
		<Menu>
			<Menu.Item key="menuPDF" onClick={() => alert('Export PDF')}>
				{I18n.t(_t(translations.services.menuPDF))}
			</Menu.Item>
			<Menu.Item key="menuExcel" onClick={() => alert('Export Excel')}>
				{I18n.t(_t(translations.services.menuExcel))}
			</Menu.Item>
			<Menu.Item key="menuCSV" onClick={() => alert('Export CSV')}>
				{I18n.t(_t(translations.services.menuCSV))}
			</Menu.Item>
		</Menu>
	);

	renderEmployeePickerModal() {
		return (
			<EmployeePickerModal
				onSelectEmployee={(employee) =>
					this.setState(
						{
							selectedEmployee: employee,
							showEmployeePickerModal: false,
						},
						() => this.canApply() && this.getPayrollData()
					)
				}
				selectedEmployeeId={this.state.selectedEmployee?.id}
				onCancel={() => this.setState({ showEmployeePickerModal: false })}
			/>
		);
	}

	renderMainViewTabs() {
		return (
			<Tabs
				style={styles.tabsContainer}
				defaultActiveKey="1"
				type="card"
				onChange={(key: string) =>
					this.setState({ isDetail: key.includes('detail') })
				}
				tabBarExtraContent={this.renderPrintButton()}
			>
				<Tabs.TabPane
					tab={I18n.t(_t(translations.payrollDetailReport.overview))}
					key="overview"
				>
					{this.renderMainContent()}
					{this.renderOtherInformation()}
				</Tabs.TabPane>
				<Tabs.TabPane
					tab={I18n.t(_t(translations.payrollDetailReport.detail))}
					key="detail"
				>
					{this.renderMainContent()}
					{this.renderOtherInformation()}
				</Tabs.TabPane>
			</Tabs>
		);
	}

	renderPrintButton() {
		return (
			<Button
				icon={<PrinterOutlined />}
				onClick={() => this.onPrint()}
				type="primary"
				style={{ marginBottom: 16, marginRight: 16, flex: 1 }}
			>
				Print
			</Button>
		);
	}

	renderMainContent() {
		return (
			<>
				<Row>
					<Typography.Title level={4}>
						{this.state.selectedDateType.title}
					</Typography.Title>
				</Row>
				<Row>
					<Typography.Title level={3} style={styles.employeeName}>
						{this.state.isDetail
							? this.state.payrollDetailData?.employeeName
							: this.state.payrollOverviewData?.employeeName}
					</Typography.Title>
				</Row>
				<Row>
					<Typography.Title level={4}>
						{BookingHelper.isDateRange(
							this.getMappedData()!.fromDate!,
							this.getMappedData()!.toDate!
						)
							? `${moment(this.getMappedData()!.fromDate).format(
									'MMM DD, YYYY'
							  )} - ${moment(this.getMappedData()!.toDate).format(
									'MMM DD, YYYY'
							  )}`
							: moment(this.getMappedData()!.fromDate).format('MMM DD, YYYY')}
					</Typography.Title>
				</Row>
				{this.renderDetailTable()}
			</>
		);
	}

	renderDetailTable() {
		const data = this.state.isDetail
			? this.state.payrollDetailData!
			: this.state.payrollOverviewData!;
		return (
			<DetailTable
				isDetail={this.state.isDetail || false}
				payrollDetailData={data}
				selectedBookingIndex={this.state.selectedBookingIndex}
			/>
		);
	}
	renderNewOtherInformation(
		title: string,
		value?: number,
		isBold?: boolean,
		isNegative?: boolean
	) {
		return (
			<Row justify="space-between">
				<Col>
					{isBold ? <Title level={5}>{title}</Title> : <Text>{title}</Text>}
				</Col>
				<Col>
					{isBold ? (
						<Title level={5}>
							{isNegative ? '-' : '\xa0'}
							{value !== undefined && CurrencyHelper.formatPrice(value)}
						</Title>
					) : (
						<Text>
							{isNegative ? '-' : '\xa0'}
							{value !== undefined && CurrencyHelper.formatPrice(value)}
						</Text>
					)}
				</Col>
			</Row>
		);
	}
	//Khong biet goi cai nay la gi
	renderOtherInformation() {
		return (
			<Row style={styles.otherInformationContainer} gutter={[8, 8]}>
				<Col xl={8} lg={8} md={24} xs={24}>
					<Card
						style={{
							...GlobalStyles.boxShadow,
						}}
					>
						<FeeAndPayment payrollData={this.state.payrollOverviewData!} />
					</Card>
				</Col>
				<Col xl={8} lg={8} md={0} xs={0}>
					<Card
						style={{
							...GlobalStyles.boxShadow,
						}}
					>
						{this.renderTotalPayment()}
						{this.renderTips()}
					</Card>
				</Col>
				<Col xl={8} lg={8} md={24} xs={24}>
					<ServedServices
						servedServices={this.state.payrollOverviewData!.servedServices!}
					/>
				</Col>
			</Row>
		);
	}
	renderTips() {
		return (
			<>
				{this.renderNewOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.tipCollected)),
					this.getMappedData()?.totalTip
				)}
				<Row justify="space-between">
					<Col>
						<Text>{I18n.t(_t(translations.payrollDetailReport.tipTimes))}</Text>
					</Col>
					<Col>
						<Text>
							{/* {`\xa0${_.sumBy(
								this.props.isDetail
									? (this.getMappedData() as IPayrollDetail)
											.payrollDetailDetails
									: this.getMappedData().payrollOverviewDetails,
								(element) => (element.tip ? 1 : 0)
							)}`} */}
							{this.state.isDetail
								? (this.getMappedData() as IPayrollDetail).totalTipCount
								: this.getMappedData()?.totalTipCount}
						</Text>
					</Col>
				</Row>
				{this.renderNewOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.tipCardCharge)),
					this.getMappedData()?.totalTipCardCharge!,
					false,
					true
				)}
				{/* {this.renderOtherInformation(
          I18n.t(_t(translations.payrollDetailReport.totalTips)),
          this.getMappedData()?.totalTip! -
            this.getMappedData()?.totalTipCardCharge!,
          true
        )} */}
			</>
		);
	}

	renderTotalPayment() {
		return (
			<>
				{this.renderNewOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.supplyFee)),
					this.getMappedData()?.totalSupplyFee!,
					true,
					true
				)}
				{this.renderNewOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.tax)),
					this.getMappedData()?.totalTax!,
					true,
					true
				)}
				{this.renderNewOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.refund)),
					this.getMappedData()?.totalRefund!,
					true,
					true
				)}
			</>
		);
	}

	onChangeDateRangeType = (dateType: typeof DATE_TYPE[0]) => {
		switch (dateType.value) {
			case DateRangeType.TODAY:
				this.setState(
					{
						startDate: moment(),
						endDate: moment(),
						showDateRange: false,
						selectedDateType: dateType,
					},
					() => this.canApply() && this.getPayrollData()
				);
				break;
			case DateRangeType.YESTERDAY:
				this.setState(
					{
						startDate: moment().add(-1, 'day'),
						endDate: moment().add(-1, 'day'),
						showDateRange: false,
						selectedDateType: dateType,
					},
					() => this.canApply() && this.getPayrollData()
				);
				break;
			case DateRangeType.WEEKLY:
				this.setState(
					{
						startDate: moment(moment()).startOf('week'),
						endDate: moment(moment()).endOf('week'),
						showDateRange: true,
						selectedDateType: dateType,
					},
					() => this.canApply() && this.getPayrollData()
				);
				break;
			case DateRangeType.SEMI_MONTH_1st:
				this.setState(
					{
						startDate: moment(moment()).startOf('month'),
						endDate: moment(moment()).startOf('month').add(14, 'day'),
						showDateRange: true,
						selectedDateType: dateType,
					},
					() => this.canApply() && this.getPayrollData()
				);
				break;
			case DateRangeType.SEMI_MONTH_2nd:
				this.setState(
					{
						startDate: moment(moment()).startOf('month').add(15, 'day'),
						endDate: moment(moment()).endOf('month'),
						showDateRange: true,
						selectedDateType: dateType,
					},
					() => this.canApply() && this.getPayrollData()
				);
				break;
			case DateRangeType.MONTHLY:
				this.setState(
					{
						startDate: moment(moment()).startOf('month'),
						endDate: moment(moment()).endOf('month'),
						showDateRange: true,
						selectedDateType: dateType,
					},
					() => this.canApply() && this.getPayrollData()
				);
				break;
			case DateRangeType.LAST_MONTH:
				this.setState(
					{
						startDate: moment(moment().add(-1, 'month')).startOf('month'),
						endDate: moment(moment().add(-1, 'month')).endOf('month'),
						showDateRange: true,
						selectedDateType: dateType,
					},
					() => this.canApply() && this.getPayrollData()
				);
				break;
			case DateRangeType.DATE_RANGE:
				this.setState(
					{
						showDateRange: true,
						startDate: moment(),
						endDate: moment(),
						selectedDateType: dateType,
					},
					() => this.canApply() && this.getPayrollData()
				);
				break;
			default:
				break;
		}
	};

	canApply = (): boolean => {
		if (!this.state.selectedEmployee) {
			return false;
		}
		if (
			this.state.selectedDateType.value === DateRangeType.DATE_RANGE &&
			this.state.startDate.isAfter(this.state.endDate)
		) {
			return false;
		}
		return true;
	};

	isAllTime = () => {
		return this.state.selectedDateType.value === DateRangeType.DATE_RANGE;
	};

	dateDidUpdate = (prevState: IMainViewStates) => {
		if (
			ValidationHelper.isThisBoolDiffThatBool(
				prevState.showDatePickerModal || false,
				this.state.showDatePickerModal || false
			) &&
			this.state.showDatePickerModal === false
		) {
			if (this.state.startDate.isAfter(this.state.endDate)) {
				this.setState({
					payrollDetailData: undefined,
					payrollOverviewData: undefined,
				});
				AlertHelper.showAlert(
					I18n.t(_t(translations.text.error)),
					I18n.t(_t(translations.payrollDetailReport.datePickerModalMessage)),
					'error'
				);
			}
		}
	};

	getPayrollData = async () => {
		const { selectedEmployee, startDate, endDate } = this.state;
		const request = {
			employeeId: selectedEmployee?.id,
			fromDate: BookingHelper.convertDateRequest(startDate),
			toDate: BookingHelper.convertDateRequest(endDate),
			pageNumber: 1,
			pageSize: 1000,
		};
		const apiList = [
			ReportApiService.getPayrollOverview(request),
			ReportApiService.getPayrollDetail(request),
		];
		this.setState({
			isLoading: true,
			showPreview: false,
			selectedBooking: undefined,
			selectedBookingIndex: undefined,
		});
		try {
			const result = await Promise.all(apiList);

			const [payrollOverview, payrollDetail] = result;
			if ((payrollOverview as IApiResponse<IPayrollOverview>).succeeded) {
				if ((payrollDetail as IApiResponse<IPayrollDetail>).succeeded) {
					this.setState({
						payrollOverviewData: (
							payrollOverview as IApiResponse<IPayrollOverview>
						).data!,
						payrollDetailData: (payrollDetail as IApiResponse<IPayrollDetail>)
							.data!,
						isLoading: false,
						isDetail: false,
					});
				} else {
					const error = payrollDetail as IErrorResponse;
					AlertHelper.showError(error);
				}
			} else {
				const error = payrollOverview as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			this.setState({ isLoading: false });
			console.error(error);
		}
	};

	getMappedData() {
		return this.state.isDetail
			? this.state.payrollDetailData
			: this.state.payrollOverviewData;
	}

	async onPrint() {
		const payrollESCCommands = createESCPayrollDetail(
			this.state.payrollOverviewData!
		);
		await print(payrollESCCommands, undefined);
	}
}

const styles: kStyles = {
	maxWidth: { width: '100%' },
	tabsContainer: {
		...GlobalStyles.kDefaultMargin,
	},
	employeeName: { color: Colors.RED_BERRY },
	spin: { margin: '0 auto' },
	otherInformationContainer: { marginTop: 16 },
	containerCol: {
		width: '100%',
		paddingInline: 8,
		marginBottom: 64,
		overflow: 'hidden',
	},
};

export default withRouter(MainView);
