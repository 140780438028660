import { Col, Select } from 'antd';
import styles from 'components/Header/styles';
import React from 'react';
import { BranchActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { IBranch } from 'models/IBranch';
import _ from 'lodash';

interface IBranchSelectProps
	extends ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps> {
	lg?: number;
	disabled?: boolean | false;
}

class BranchSelect extends React.Component<IBranchSelectProps> {
	render() {
		return (
			<Col lg={this.props.lg || 6} xs={12}>
				{this.renderBranchSelect()}
			</Col>
		);
	}
	renderBranchSelect() {
		return (
			<Select
				placeholder={'Select branch'}
				style={styles.select}
				disabled={this.props.disabled}
				value={this.props.currentBranch?.id}
				onSelect={(id) => this.onSelectBranch(id)}
				loading={
					this.props.branchAction ===
					BranchActions.getBranchesByShopId.requestName
				}
			>
				{this.renderBranches()}
			</Select>
		);
	}

	renderBranches = () => {
		if (!_.isEmpty(this.props.branches))
			return _.sortBy(this.props.branches, (branch) => !branch.isPrimary).map(
				(branch) => (
					<Select.Option
						value={branch.id}
						key={branch.id}
						style={styles.dropdownItem}
					>
						{branch.name}
					</Select.Option>
				)
			);
	};
	onSelectBranch(id: string) {
		const branch = _.find(this.props.branches, (branch) => branch.id === id)!;
		this.props.selectBranch(branch);
	}
}

const mapStateToProps = (state: RootState) => ({
	currentLanguage: state.AppConfigReducer.language,
	branches: state.BranchReducer.branches,
	branchAction: state.ReduxActionReducer['BRANCH'],
	currentBranch: state.BranchReducer.currentBranch,
	user: state.UserReducer.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	selectBranch: (branch: IBranch) =>
		dispatch(BranchActions.selectBranch.request(branch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BranchSelect);
