import React, { Component } from 'react';
import {
	Col,
	Row,
	Space,
	Input,
	Table,
	Button,
	Typography,
	TablePaginationConfig,
	Switch,
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { I18n, _t, translations } from 'utils';
import _ from 'lodash';
import styles from './styles';
import moment from 'moment';
import { CurrencyHelper } from 'helpers';
import GiftCardApiService from 'services/GiftCardApiService';
import { IApiResponse, IGiftCard } from 'models';
import { connect } from 'react-redux';
import { CheckoutActions, ErrorActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import { Dispatch } from '@reduxjs/toolkit';
import SalesGiftCardModal from 'pages/Checkout/components/SalesGiftCardModal';
import { removeSpecialCharacter } from 'helpers/StringHelper';
import { BranchActions } from 'redux/actions';
import { history } from 'routers';

interface IListGiftCardProps
	extends ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps> {}

interface IListGiftCardStates {
	pagination: TablePaginationConfig;
	giftCards: IGiftCard[];
	isLoading: boolean;
	status: number;
	showModal: boolean;
	searchText?: string;
	sellGiftCardModal: boolean;
}

const PAGE_SIZE = 10;
class ListGiftCard extends Component<IListGiftCardProps, IListGiftCardStates> {
	constructor(props: IListGiftCardProps) {
		super(props);
		this.state = {
			pagination: {
				current: 1,
				pageSize: PAGE_SIZE,
				showTotal: (total: any) => `Total ${total} items`,
			},
			giftCards: [],
			isLoading: false,
			status: -1,
			showModal: false,
			sellGiftCardModal: false,
		};
		this.onChangeDebounce = _.debounce(this.onChangeDebounce, 1000);
	}

	componentDidMount() {
		this.onTableChange(1, 'ascend');
	}
	componentDidUpdate(
		prevProps: IListGiftCardProps,
		prevState: IListGiftCardStates
	) {
		if (prevProps.currentAction !== this.props.currentAction) {
			if (
				this.state.status !== prevState.status ||
				this.props.currentAction === BranchActions.getBranchById.requestName
			) {
				this.onTableChange(1, 'ascend');
			}
		}
	}
	render() {
		return (
			<Space direction="vertical" style={styles.container} size="large">
				<Row>
					<Col style={{ width: '100%' }}>
						{this.renderHeader()}
						{this.renderTable()}
						{/* {this.state.showModal ? (
							<SalesGiftCardModal
								onCancel={() => this.setState({ showModal: false })}
								onOk={() => {
									this.setState({ showModal: false });
									this.onTableChange(1, 'ascend');
								}}
								currentBranchId={this.props.currentBranch?.id!}
								shopId={this.props.shopId!}
							/>
						) : null} */}
						{this.state.sellGiftCardModal && (
							<SalesGiftCardModal
								currentBranchId={this.props.currentBranch?.id}
								title={I18n.t(_t(translations.addVoucher))}
								inputCodeLabel={I18n.t(
									_t(translations.listGiftCard.voucherCode)
								)}
								onOk={this.onSellGiftCard}
								onCancel={() => this.setState({ sellGiftCardModal: false })}
								isVoucher={true}
							/>
						)}
					</Col>
				</Row>
			</Space>
		);
	}
	renderHeader = () => {
		return (
			<Row
				gutter={[16, 16]}
				style={{ alignItems: 'center', paddingBottom: 20 }}
			>
				<Col md={12}>
					<Input
						allowClear
						prefix={<SearchOutlined />}
						style={styles.maxWidth}
						placeholder={I18n.t(_t(translations.customerList.search))}
						value={this.state.searchText}
						onChange={(e) => this.onSearch(e)}
					/>
				</Col>
				<Col md={12}>
					<Space
						style={{ justifyContent: 'flex-end', width: '100%' }}
						size="small"
					>
						<Button
							onClick={() => this.setState({ sellGiftCardModal: true })}
							icon={<PlusOutlined />}
							// type="primary"
						>
							{I18n.t(_t(translations.addVoucher))}
						</Button>
						<Button
							onClick={() => history.push('checkout/sale-gift-card')}
							icon={<PlusOutlined />}
							type="primary"
						>
							{I18n.t(_t(translations.sellGiftCard))}
						</Button>
					</Space>
				</Col>
			</Row>
		);
	};
	renderTable() {
		return (
			<>
				<Table
					style={{ paddingBottom: 24, cursor: 'pointer' }}
					scroll={{ y: '50vh', x: true }}
					size="large"
					dataSource={this.state.giftCards}
					columns={[
						{
							title: (
								<Typography.Title level={5}>
									{I18n.t(_t(translations.listGiftCard.giftCode))}
								</Typography.Title>
							),
							render: (text, record, index) => {
								const giftCard = record as IGiftCard;
								return (
									<Typography.Text strong>{giftCard.giftCode}</Typography.Text>
								);
							},
						},
						{
							title: (
								<Typography.Title level={5}>
									{I18n.t(_t(translations.listGiftCard.customername))}
								</Typography.Title>
							),
							dataIndex: 'name',
							render: (text, record, index) => {
								const giftCard = record as IGiftCard;
								return (
									<Typography.Text strong>{giftCard.name}</Typography.Text>
								);
							},
							sorter: true,
						},
						{
							title: (
								<Typography.Title level={5}>
									{I18n.t(_t(translations.listGiftCard.saleDate))}
								</Typography.Title>
							),
							dataIndex: 'saleDate',
							render: (text, record, index) => {
								const giftCard = record as IGiftCard;
								return (
									<Typography.Text>
										{giftCard.sellDate
											? moment(giftCard.sellDate).format('LL')
											: moment(giftCard.fromDate).format('LL')}
									</Typography.Text>
								);
							},
							sorter: true,
						},
						{
							title: (
								<Typography.Title level={5}>
									{I18n.t(_t(translations.listGiftCard.expiredDate))}
								</Typography.Title>
							),
							dataIndex: 'expiredDate',
							render: (text, record, index) => {
								const giftCard = record as IGiftCard;
								return (
									<Typography.Text>
										{giftCard.toDate
											? moment(giftCard.toDate).format('LL')
											: ''}
									</Typography.Text>
								);
							},
							sorter: true,
						},
						{
							title: (
								<Typography.Title level={5}>
									{I18n.t(_t(translations.listGiftCard.lastedUse))}
								</Typography.Title>
							),
							dataIndex: 'lastedUse',
							render: (text, record, index) => {
								const giftCard = record as IGiftCard;
								return (
									<Typography.Text>
										{giftCard.lastUsed
											? moment(giftCard.lastUsed).format('LL')
											: moment(giftCard.fromDate).format('LL')}
									</Typography.Text>
								);
							},
							sorter: true,
						},
						{
							title: (
								<Typography.Title level={5}>
									{I18n.t(_t(translations.listGiftCard.description))}
								</Typography.Title>
							),
							dataIndex: 'description',
							render: (text, record, index) => {
								const giftCard = record as IGiftCard;
								return (
									<>
										<Typography.Title level={5}>
											{giftCard.title}
										</Typography.Title>
										<Typography.Text>{giftCard.description}</Typography.Text>
									</>
								);
							},
						},
						{
							title: (
								<Typography.Title level={5}>
									{I18n.t(_t(translations.listGiftCard.balance))}($)
								</Typography.Title>
							),
							dataIndex: 'totalAmount',
							render: (text, record, index) => {
								const giftCard = record as IGiftCard;
								return (
									<Typography.Text>
										{CurrencyHelper.formatPrice(giftCard.balance, false)}
									</Typography.Text>
								);
							},
							align: 'right',
							sorter: (a, b) => a.balance - b.balance,
						},
						{
							title: (
								<Typography.Title level={5}>
									{I18n.t(_t(translations.amount))}($)
								</Typography.Title>
							),
							render: (text, record, index) => {
								const giftCard = record as IGiftCard;
								return (
									<Typography.Text>
										{CurrencyHelper.formatPrice(giftCard.amount, false)}
									</Typography.Text>
								);
							},
							align: 'right',
							sorter: (a, b) => a.amount - b.amount,
						},
					]}
					pagination={this.state.pagination}
					onChange={(pagination, filter, sorter) =>
						this.onTableChange(pagination.current!, sorter)
					}
					loading={this.state.isLoading}
					rowKey={(record: IGiftCard) => record.id}
				/>
			</>
		);
	}
	onSellGiftCard = (giftCard: IGiftCard) => {
		this.setState({ sellGiftCardModal: false });
		this.onTableChange(1, 'ascend');
	};
	onSearch = (e: any) => {
		let searchText = removeSpecialCharacter(e.target.value.toLowerCase());
		this.setState({ searchText });
		this.onChangeDebounce();
	};

	onChangeDebounce = () => {
		this.onTableChange(1, 'ascend');
	};
	async onTableChange(pageNumber: number, sorter: any) {
		this.setState({ isLoading: true });
		const params = {
			pageNumber,
			pageSize: PAGE_SIZE,
			searchText: this.state.searchText,
			status: `${this.state.status === -1 ? 1 : this.state.status}`,
			orderBy: sorter.order === 'ascend' ? 'fromDate' : 'fromDate desc',
		};
		try {
			const result = (await GiftCardApiService.getAllGiftCard(
				params
			)) as IApiResponse<IGiftCard[]>;
			if (result.succeeded) {
				const pagination = {
					...this.state.pagination,
					total: result.totalRecords,
					current: pageNumber,
				};
				this.setState({ pagination, giftCards: result.data! });
			}
			this.setState({ isLoading: false });
		} catch (error) {}
	}
	onClickRadioButton = (e: any) => {
		this.setState({ status: e.target.value });
	};
}

const mapStateToProps = (state: RootState) => ({
	shopId: state.ShopReducer.shopId,
	currentAction: state.ReduxActionReducer['BRANCH'],
	error: state.ErrorReducer.error?.message
		? state.ErrorReducer.error?.message[0]
		: { text: 'Error' },
	currentBranch: state.BranchReducer.currentBranch,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	clearError: () => {
		dispatch(ErrorActions.clearError.request());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(ListGiftCard);
