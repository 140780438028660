import { formatBalance } from "helpers/currencyHelper";
import _ from "lodash";
import { IPayrollSummaryDetail } from "models";

import moment from "moment";
import React from "react";
import { I18n, translations, _t } from "utils";
import "./styles.scss";

interface IPayrollPrintPreviewProps {
    data?: IPayrollSummaryDetail;
    period?: string
}

export default class PayrollPrintPreview extends React.Component<IPayrollPrintPreviewProps> {
    render() {
        const name = this.props.data?.employeeName
        const phone = this.props.data?.phone ? `${this.props.data?.phone!}` : ""
        const email = this.props.data?.email
        const period = this.props.period
        const salary = formatBalance(this.props.data?.salary)
        const income = formatBalance(this.props.data?.income)
        const serviceIncome = formatBalance(this.props.data?.serviceIncome)
        const productIncome = formatBalance(this.props.data?.productIncome)
        const giftCardIncome = formatBalance(this.props.data?.giftCardIncome)
        const commission = formatBalance(this.props.data?.commission)
        const serviceCommission = formatBalance(this.props.data?.serviceCommission)
        const giftCardCommission = formatBalance(this.props.data?.giftCardCommission)
        const productCommission = formatBalance(this.props.data?.productCommission)
        const tip = formatBalance(this.props.data?.tip)
        const cardCharge = `-${formatBalance(this.props.data?.cardCharge)}`
        const tipCardCharge = `-${formatBalance(this.props.data?.tipCardCharge)}`
        const salaryPay = formatBalance(this.props.data?.salaryPay)
        const salaryPayByCash = formatBalance(this.props.data?.salaryPayByCash)
        const salaryPayByCheck = formatBalance(this.props.data?.salaryPayByCheck)
        return (
            <div id="payroll-POS">
                <div style={{ textAlign: "center" }}>
                    <div id="top">
                        <h2>Payroll</h2>
                    </div>
                </div>
                <div style={{ marginTop: 8 }} id="mid">
                    <div className="divider-dotted" >
                        <p>{I18n.t(_t(translations.payrollReport.name))}: <b>{name}</b></p>
                        <p>{I18n.t(_t(translations.email))}: <b>{email}</b></p>
                        <p>{I18n.t(_t(translations.phone))}: <b>{phone}</b></p>
                        <p>{I18n.t(_t(translations.payrollReport.period))}: <b>{period}</b> </p>
                    </div>
                    <table style={{ width: "100%", marginTop: 8 }}>
                        <tr className="divider-dotted">
                            <td>
                                <p><b>{I18n.t(_t(translations.payrollReport.salary))}</b></p>
                            </td>
                            <td className="align-right">
                                <p>{salary}</p>
                            </td>
                        </tr>
                        <p />
                        <tr>
                            <td>
                                <p><b>{I18n.t(_t(translations.payrollReport.revenue))}</b></p>
                            </td>
                            <td className="align-right">
                                <p>{income}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>{I18n.t(_t(translations.payrollReport.serviceRevenue))}</p>
                            </td>
                            <td className="align-right">
                                <p>{serviceIncome}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>{I18n.t(_t(translations.payrollReport.productRevenue))}</p>
                            </td>
                            <td className="align-right">
                                <p>{productIncome}</p>
                            </td>
                        </tr>
                        <tr className="divider-dotted">
                            <td>
                                <p>{I18n.t(_t(translations.payrollReport.giftCardRevenue))}</p>
                            </td>
                            <td className="align-right">
                                <p>{giftCardIncome}</p>
                            </td>
                        </tr>
                        <p />
                        <tr>
                            <td>
                                <p><b>{I18n.t(_t(translations.payrollReport.commission))}</b></p>
                            </td>
                            <td className="align-right">
                                <p>{commission}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>{I18n.t(_t(translations.serviceCommission))}</p>
                            </td>
                            <td className="align-right">
                                <p>{serviceCommission}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>{I18n.t(_t(translations.productCommission))}</p>
                            </td>
                            <td className="align-right">
                                <p>{productCommission}</p>
                            </td>
                        </tr>
                        <tr className="divider-dotted" >
                            <td>
                                <p>{I18n.t(_t(translations.payrollReport.giftCardCommission))}</p>
                            </td>
                            <td className="align-right">
                                <p>{giftCardCommission}</p>
                            </td>
                        </tr>
                        <p />
                        <tr>
                            <td>
                                <p><b>{I18n.t(_t(translations.payrollReport.tips))}</b></p>
                            </td>
                            <td className="align-right">
                                <p>{tip}</p>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <p><b>{I18n.t(_t(translations.payrollReport.cardCharge), { number: 0 })}</b></p>
                            </td>
                            <td className="align-right">
                                <p>{cardCharge}</p>
                            </td>
                        </tr>

                        <tr className="divider-dotted">
                            <td>
                                <p><b>{I18n.t(_t(translations.payrollReport.tipsCardCharge), { number: 0 })}</b></p>
                            </td>
                            <td className="align-right">
                                <p>{tipCardCharge}</p>
                            </td>
                        </tr>
                        <p />
                        <tr>
                            <td>
                                <p><b>{I18n.t(_t(translations.payrollReport.totalSalary))}</b></p>
                            </td>
                            <td className="align-right">
                                <p>{salaryPay}</p>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <p><b>{I18n.t(_t(translations.payrollReport.cashIncome))}</b></p>
                            </td>
                            <td className="align-right">
                                <p>{salaryPayByCash}</p>
                            </td>
                        </tr>

                        <tr >
                            <td>
                                <p><b>{I18n.t(_t(translations.payrollReport.checkIncome))}</b></p>
                            </td>
                            <td className="align-right">
                                <p>{salaryPayByCheck}</p>
                            </td>
                        </tr>
                    </table>
                </div>
                <br />
                <p>{` Date: ${moment().local().format("MM-DD-YYYY")}          Time: ${moment().local().format("HH:mm:ss")}`}</p>
                <div style={{ textAlign: "center" }}>
                    <div className="divider-dotted" />
                    <p />
                    <div className="divider-dotted" />
                    <p />
                    <h2>{I18n.t(_t(translations.thank))}</h2>
                </div>
            </div >
        );
    }
}
