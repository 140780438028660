/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Typography, Layout, List, Spin } from "antd";
import styles from "./styles";
import InfiniteScroller from "react-infinite-scroller";
import { I18n, _t, translations } from "utils";
import GlobalStyles from "GlobalStyles";
import { IBookingActivity, IErrorResponse } from "models";
import moment from "moment";
import { ITodayNextBookingActivityRequest } from "models/RequestModels";
import BookingActivityItem from "./BookingActivityItem";
import { AdminDashBoardApiService } from "services";
import { AlertHelper, BookingHelper } from "helpers";
import { IPaginateResponse } from "models/ResponseModels";

const { Title } = Typography;
const { Header } = Layout;

const TodayNextBooking = () => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const [todayNextBookingActivity, setTodayNextBookingActivity] =
    useState<IPaginateResponse<IBookingActivity[]> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getTodayNextBooking(baseRequest);
  }, []);

  const onLoadMore = async () => {
    if (todayNextBookingActivity?.nextPage != null) {
      getTodayNextBooking({
        ...baseRequest,
        pageNumber: todayNextBookingActivity.pageNumber + 1,
      });
    }
  };

  const baseRequest: ITodayNextBookingActivityRequest = {
    date: BookingHelper.convertDateRequest(moment()),
    pageNumber: 1,
    pageSize: 10,
  };

  const getTodayNextBooking = async (
    request: ITodayNextBookingActivityRequest
  ) => {
    setIsLoading(true);
    const result = (await AdminDashBoardApiService.getTodayNextBookingActivity(
      request
    )) as IPaginateResponse<IBookingActivity[]>;
    setIsLoading(false);
    if (result.succeeded) {
      if (request.pageNumber === 1) {
        setTodayNextBookingActivity(result);
      } else {
        setTodayNextBookingActivity({
          ...result,
          data: [...todayNextBookingActivity?.data!, ...result.data!],
        });
      }
    } else {
      const error = result as IErrorResponse;
      AlertHelper.showError(error);
    }
  };

  return (
    <div
      style={isHover ? styles.listContainerHover : styles.listContainer}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Header style={styles.listHeader}>
        <Title level={4} style={GlobalStyles.textBold}>
          {I18n.t(_t(translations.dashboard.todayNextBooking))}
        </Title>
      </Header>
      <div style={styles.theListContainer}>
        <InfiniteScroller
          pageStart={0}
          loadMore={onLoadMore}
          initialLoad={false}
          hasMore={
            !isLoading && todayNextBookingActivity?.nextPage !== undefined
          }
          useWindow={false}
          threshold={300}
        >
          <List
            dataSource={todayNextBookingActivity?.data}
            renderItem={(item: IBookingActivity) => (
              <BookingActivityItem bookingActivity={item} />
            )}
          >
            {isLoading && (
              <div style={styles.listLoadingComponent}>
                <Spin size="large" />
              </div>
            )}
          </List>
        </InfiniteScroller>
      </div>
    </div>
  );
};

export default TodayNextBooking;
