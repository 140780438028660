import { Form, ModalProps } from 'antd';
import ModalHeper from 'helpers/modalHelper';
import React from 'react';

interface IProps extends ModalProps {
	visible: boolean;
	title: string;
	onOk: (values: any) => void;
	onCancel: () => void;
	onDelete?: () => void;
	btnDelete?: boolean;
	centered?: boolean;
	isConfirming?: boolean;
	isEditNewCustomer?: boolean;
}

const FormInModal: React.FC<IProps> = ({
	visible,
	title,
	onOk,
	onCancel,
	onDelete,
	btnDelete,
	children,
	centered,
	isConfirming,
	isEditNewCustomer,
}) => {
	const [form] = Form.useForm();
	return (
		<ModalHeper
			{...FormInModal}
			visible={visible}
			title={title}
			onCancel={() => {
				form.resetFields();
				onCancel();
			}}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						!isEditNewCustomer && form.resetFields();
						onOk(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
			isConfirming={isConfirming}
			centered={centered}
			onDelete={() => {
				onDelete!();
			}}
			btnDelete={btnDelete}
		>
			<Form
				form={form}
				layout="vertical"
				name="form_in_modal"
				scrollToFirstError={true}
			>
				{/* <Row>
					<Col lg={1} />
					<Col lg={22} xs={24}>
						<Space direction="vertical" size="small" style={{ width: '100%' }}>
							{children}
						</Space>
					</Col>
					<Col lg={1} />
				</Row> */}
				{children}
			</Form>
		</ModalHeper>
	);
};

export default FormInModal;
