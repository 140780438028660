const styles: any = {
	menu: {
		width: 250,
		boxShadow:
			'0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
	},
	maxWidth: {
		width: '100%',
	},
};

export default styles;
