const styles = {
  headerContainer: {
    // padding: 16,
    width: "100%",
  },
  employeeSelect: {
    width: "60%",
  },
  viewsContainer: {
    textAlign: "right" as const,
  },
  dateContainer: {
    textAlign: "center" as const,
  },
};

export default styles;
