import { LineConfig } from '@ant-design/charts/es/line';
import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { CurrencyHelper } from 'helpers';
import { ITopServices, ITopStaffs } from 'models';
import { I18n, _t, translations, Colors } from 'utils';
const { Text } = Typography;

export const columnChartConfig = {
	isGroup: true,
	xField: 'day',
	yField: 'value',
	seriesField: 'category',
	// colorField: "status", // or seriesField in some cases
	color: ({ category }: any) => {
		if (category === I18n.t(_t(translations.dashboard.categoryConfirmed))) {
			return '#3347ff';
		}
		return '#d60000';
	},
};

export const lineChartConfig: Partial<LineConfig> = {
	seriesField: 'category',
	xField: 'day',
	yField: 'value',
	point: {
		//Config of the line-most point
		size: 5,
		shape: 'circle',
		style: {
			fill: '#5B8FF9',
			stroke: '#f2f2f2',
			lineWidth: 2, //width of the stroke
		},
	},

	tooltip: { showMarkers: false }, //The small circle inside the point
	state: {
		active: {
			style: {
				shadowColor: 'yellow',
				shadowBlur: 4,
				stroke: 'transparent',
			},
		},
	},
	theme: {
		geometries: {
			point: {
				circle: {
					active: {
						//theme when user hover to the point
						style: {
							shadowColor: '#f6d116',
							shadowBlur: 1,
							stroke: '#f6d116',
						},
					},
				},
			},
		},
	},
	interactions: [{ type: 'marker-active' }],
	color: ({ category }: any) => {
		if (category === I18n.t(_t(translations.dashboard.categoryBooking))) {
			return Colors.GREEN;
		}
		return '#d60000';
	},
};

export const topServiceTableConfig: ColumnsType<ITopServices> = [
	{
		title: (_) => (
			<Text style={{ fontSize: '17px', fontWeight: 700 }}>
				{I18n.t(_t(translations.dashboard.colServiceTitle))}
			</Text>
		),
		dataIndex: 'serviceName',
		key: 'serviceName',
		width: '60%',
	},
	{
		title: (_) => (
			<Text style={{ fontSize: '17px', fontWeight: 700 }}>
				{I18n.t(_t(translations.dashboard.colThisMonthTitle))}
			</Text>
		),
		dataIndex: 'thisPeriod',
		key: 'thisPeriod',
		align: 'center',
	},
	{
		title: (_) => (
			<Text style={{ fontSize: '17px', fontWeight: 700 }}>
				{I18n.t(_t(translations.dashboard.colLastMonthTitle))}
			</Text>
		),
		dataIndex: 'lastPeriod',
		key: 'lastPeriod',
		align: 'right',
	},
];

export const topStaffTableConfig: ColumnsType<ITopStaffs> = [
	{
		title: (_) => (
			<Text style={{ fontSize: '17px', fontWeight: 700 }}>
				{I18n.t(_t(translations.dashboard.colStaffTitle))}
			</Text>
		),
		dataIndex: 'stylistName',
		key: 'stylistName',
		width: '60%',
	},
	{
		title: (_) => (
			<Text style={{ fontSize: '17px', fontWeight: 700 }}>
				{I18n.t(_t(translations.dashboard.colThisMonthTitle))}
				{'($)'}
			</Text>
		),
		dataIndex: 'thisPeriod',
		key: 'thisPeriod',
		align: 'center',
		render: (text) => (
			<Text style={{ fontSize: '14px' }}>
				{CurrencyHelper.formatPrice(text, false)}
			</Text>
		),
	},
	{
		title: (_) => (
			<Text style={{ fontSize: '17px', fontWeight: 700 }}>
				{I18n.t(_t(translations.dashboard.colLastMonthTitle))}
				{'($)'}
			</Text>
		),
		dataIndex: 'lastPeriod',
		key: 'lastPeriod',
		align: 'right',
		render: (text) => (
			<Text style={{ fontSize: '14px' }}>
				{CurrencyHelper.formatPrice(text, false)}
			</Text>
		),
	},
];
