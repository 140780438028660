import { CSSProperties } from "react";

export interface kStyles {
  [key: string]: CSSProperties;
}
const GlobalStyles: kStyles = {
  kDefaultPadding: {
    padding: 16,
  },
  kDefaultMargin: {
    margin: 16,
  },
  dashboardView: {
    height: 200,
  },
  boxShadow: {
    boxShadow: "0 2px 5px 0 rgb(164 173 186 / 25%)",
  },
  textBold: {
    fontWeight: "bold",
  },
  rounded: {
    borderRadius: 2,
  },
  defaultIconSize: {
    fontSize: 14,
  },
};

export default GlobalStyles;
