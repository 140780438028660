import {
	Col,
	Menu,
	Row,
	Button,
	Space,
	Input,
	Table,
	Typography,
	TablePaginationConfig,
	Select,
} from 'antd';
import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { IApiResponse, IPayrollSummaryDetail, IPayrollSummary } from 'models';
import { IPayrollRequest } from 'models/RequestModels';
import moment, { Moment } from 'moment';
import React, { Component, CSSProperties } from 'react';
import ReportApiService from 'services/ReportApiService';
import { I18n, _t, translations } from 'utils';
import { DateRangeType, EDatePickerType } from 'utils/Consts';
import { DatePickerModal, DateRangeSelect } from 'components';
import { AlertHelper, CurrencyHelper, ValidationHelper } from 'helpers';
import {
	createESCPayroll,
	createESCPayrollAll,
	print,
} from 'helpers/printHelper';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import { BranchActions } from 'redux/actions';
import { DateRangePicker } from 'components/DatePicker/DateRangePicker';

interface IPayrollStates {
	pagination: TablePaginationConfig;
	dataPayroll?: IPayrollSummary;
	isLoading: boolean;
	status: number;
	showDateRange: boolean;
	startDate: Moment;
	endDate: Moment;
	isStartDate?: boolean;
	showDatePickerModal: boolean;
	selectedDateRangeType: DateRangeType;
}
interface IPayrollProps extends ReturnType<typeof mapStateToProps> {
	onCellClick(
		dataCell: IPayrollSummaryDetail,
		dataPayroll: IPayrollSummary,
		index: number
	): void;
	onPrint(dataCell: any): void;
	onPrintAll(dataPayroll: IPayrollSummary): void;
}

const PAGE_SIZE = 10;

export class Payroll extends Component<IPayrollProps, IPayrollStates> {
	state = {
		pagination: {
			current: 1,
			pageSize: PAGE_SIZE,
			showTotal: (total: any) => `Total ${total} items`,
		},
		isLoading: false,
		status: -1,
		showDateRange: true,
		startDate: moment(),
		endDate: moment(),
		dataPayroll: undefined,
		showDatePickerModal: false,
		isStartDate: false,
		selectedDateRangeType: DateRangeType.DATE_RANGE,
	};

	DATE_RANGE = [
		{
			id: 'dateRange',
			title: I18n.t(_t(translations.dateRangeFrame.dateRange)),
			value: DateRangeType.DATE_RANGE,
		},
		{
			id: 'today',
			title: I18n.t(_t(translations.dateRangeFrame.today)),
			value: DateRangeType.TODAY,
		},
		{
			id: 'yesterday',
			title: I18n.t(_t(translations.dateRangeFrame.yesterday)),
			value: DateRangeType.YESTERDAY,
		},
		{
			id: 'weekly',
			title: I18n.t(_t(translations.dateRangeFrame.weekly)),
			value: DateRangeType.WEEKLY,
		},
		{
			id: 'semiMonth1st',
			title: I18n.t(_t(translations.dateRangeFrame.semiMonth1st)),
			value: DateRangeType.SEMI_MONTH_1st,
		},
		{
			id: 'semiMonth2nd',
			title: I18n.t(_t(translations.dateRangeFrame.semiMonth2nd)),
			value: DateRangeType.SEMI_MONTH_2nd,
		},
		{
			id: 'monthly',
			title: I18n.t(_t(translations.dateRangeFrame.monthly)),
			value: DateRangeType.MONTHLY,
		},
		{
			id: 'lastMonth',
			title: I18n.t(_t(translations.dateRangeFrame.lastMonth)),
			value: DateRangeType.LAST_MONTH,
		},
	];

	componentDidMount() {
		this.onTableChange(1, 'ascend', '');
	}
	componentDidUpdate(prevProps: IPayrollProps, prevState: IPayrollStates) {
		const { branchAction } = this.props;
		if (prevProps.branchAction !== branchAction) {
			if (branchAction === BranchActions.getBranchById.successName) {
				this.onTableChange(1, 'ascend', '');
				return;
			}
		}
		this.dateDidUpdate(prevState);
	}

	render() {
		return (
			<Space direction="vertical" style={styles.maxWidth}>
				{this.renderButton()}
				{this.renderTableContainer()}
			</Space>
		);
	}
	renderButton = () => {
		return (
			<Row gutter={[8, 8]} justify="space-between">
				<Col flex={1}>
					<Row gutter={[8, 8]}>
						<Col xs={24} sm={24} md={8} lg={6}>
							<Select
								defaultValue={this.DATE_RANGE[0].value}
								style={styles.maxWidth}
								onChange={this.onChangeDateRange}
							>
								{this.DATE_RANGE.map((dateRange) => {
									return (
										<Select.Option key={dateRange.id} value={dateRange.value}>
											{dateRange.title}
										</Select.Option>
									);
								})}
							</Select>
						</Col>
						{this.state.showDateRange ? (
							<DateRangePicker
								onSelectDateRange={(dateRage) => {
									this.setState(
										{
											startDate: dateRage.fromDate!,
											endDate: dateRage.toDate!,
										},
										() => {
											this.onTableChange(1, 'ascend', '');
										}
									);
								}}
								selectedDateRange={{
									fromDate: this.state.startDate,
									toDate: this.state.endDate,
								}}
								disabled={
									this.state.selectedDateRangeType !== DateRangeType.DATE_RANGE
								}
							/>
						) : // <DateRangeSelect
						// 	fromDate={this.state.startDate}
						// 	toDate={this.state.endDate}
						// 	onDateRangeButtonClick={(type) => {
						// 		this.setState({
						// 			showDatePickerModal: true,
						// 			isStartDate: type === EDatePickerType.FROM_DATE,
						// 		});
						// 	}}
						// />
						null}
					</Row>
				</Col>
				<Col>
					<Space>
						<Button
							disabled={!this.state.dataPayroll}
							onClick={() => this.onPrintAll(this.state.dataPayroll!)}
							icon={<PrinterOutlined />}
						>
							{I18n.t(_t(translations.payrollReport.printAll))}
						</Button>
						{/* <Dropdown overlay={this.renderMenuExport} trigger={['click']}>
							<Button type="default" icon={<DownloadOutlined />}>
								{I18n.t(_t(translations.services.btnExport))}
							</Button>
						</Dropdown> */}
					</Space>
				</Col>
			</Row>
		);
	};

	renderTableContainer = () => {
		return (
			<Space direction="vertical" style={styles.maxWidth}>
				{this.renderSearchInput()}
				{this.renderTable()}
			</Space>
		);
	};

	renderTable = () => {
		const payroll: IPayrollSummary = this.state.dataPayroll!;
		return (
			<>
				<Table
					style={{ paddingBottom: 24, cursor: 'pointer' }}
					size="large"
					onRow={(record, rowIndex) => {
						return {
							onClick: (event) => {
								this.props.onCellClick(
									record,
									this.state.dataPayroll!,
									rowIndex!
								);
							},
						};
					}}
					dataSource={!_.isEmpty(payroll) ? payroll.details.data : []}
					scroll={{ y: '50vh' }}
					columns={[
						{
							title: (
								<Typography.Title level={5}>
									{I18n.t(_t(translations.employee))}
								</Typography.Title>
							),
							render: (text, record, index) => {
								const payroll = record as IPayrollSummaryDetail;
								return (
									<Typography.Link>{payroll.employeeName}</Typography.Link>
								);
							},
						},
						{
							title: (
								<Typography.Title level={5}>
									{I18n.t(_t(translations.payrollReport.salary))}($)
								</Typography.Title>
							),
							render: (text, record, index) => {
								const payroll = record as IPayrollSummaryDetail;
								return (
									<Typography.Text>
										{CurrencyHelper.formatBalance(payroll.salary)}
									</Typography.Text>
								);
							},
							align: 'right',
							// sorter: true,
						},
						{
							title: (
								<Typography.Title level={5}>Total Service($)</Typography.Title>
							),
							render: (text, record, index) => {
								const payroll = record as IPayrollSummaryDetail;
								return (
									<Typography.Text>
										{CurrencyHelper.formatBalance(
											payroll.income + (payroll.supplyFee || 0)
										)}
									</Typography.Text>
								);
							},
							align: 'right',
							// sorter: true,
						},
						{
							title: (
								<Typography.Title level={5}>Total Fee($)</Typography.Title>
							),
							render: (text, record, index) => {
								const payroll = record as IPayrollSummaryDetail;
								return (
									<Typography.Text>
										{CurrencyHelper.formatBalance(payroll.supplyFee || 0)}
									</Typography.Text>
								);
							},
							align: 'right',
							// sorter: true,
						},
						{
							title: (
								<Typography.Title level={5}>
									{I18n.t(_t(translations.payrollReport.revenue))}($)
								</Typography.Title>
							),
							render: (text, record, index) => {
								const payroll = record as IPayrollSummaryDetail;
								return (
									<>
										<Typography.Text>
											{CurrencyHelper.formatBalance(payroll.income)}
										</Typography.Text>
									</>
								);
							},
							align: 'right',
						},
						{
							title: (
								<Typography.Title level={5}>
									{I18n.t(_t(translations.payrollReport.commission))}($)
								</Typography.Title>
							),
							render: (text, record, index) => {
								const payroll = record as IPayrollSummaryDetail;
								return (
									<Typography.Text>
										{CurrencyHelper.formatBalance(payroll.commission)}
									</Typography.Text>
								);
							},
							align: 'right',
						},
						{
							title: (
								<Typography.Title level={5}>
									{I18n.t(_t(translations.payrollReport.commission))}(%)
								</Typography.Title>
							),
							render: (text, record, index) => {
								const payroll = record as IPayrollSummaryDetail;
								const commission = _.find(
									this.props.staffs,
									(x) => x.id === payroll.employeeId
								)?.servicesCommissionPercent;
								return <Typography.Text>{commission}</Typography.Text>;
							},
							align: 'right',
						},
						{
							title: (
								<Typography.Title level={5}>
									{I18n.t(_t(translations.action))}
								</Typography.Title>
							),
							render: (text, record, index) => {
								return (
									<Space onClick={() => this.onPrint(record)}>
										<PrinterOutlined />
										<Typography.Link>Print</Typography.Link>
									</Space>
								);
							},
							align: 'center',
						},
					]}
					pagination={this.state.pagination}
					onChange={(pagination, filter, sorter) =>
						this.onTableChange(pagination.current!, sorter, '')
					}
					loading={this.state.isLoading}
					rowKey={(record: any) => record.id}
				/>
			</>
		);
	};
	renderSearchInput = () => {
		return (
			<Col span={8}>
				<Input
					allowClear
					prefix={<SearchOutlined />}
					style={styles.maxWidth}
					placeholder={I18n.t(_t(translations.placeHolder.searchEmployee))}
					onChange={(e) =>
						this.onTableChange(1, 'ascend', e.target.value.toLowerCase())
					}
				/>
			</Col>
		);
	};

	async onTableChange(pageNumber: number, sorter: any, searchText: string) {
		this.setState({ isLoading: true });
		const params: IPayrollRequest = {
			pageNumber,
			pageSize: PAGE_SIZE,
			searchText: searchText,
			status: `${this.state.status === -1 ? 0 : this.state.status}`,
			fromDate: this.state.startDate.format('MM-DD-YYYY'),
			toDate: this.state.endDate.format('MM-DD-YYYY'),
		};
		try {
			const result = (await ReportApiService.getPayrollSummary(
				params
			)) as IApiResponse<IPayrollSummary>;
			if (result.succeeded) {
				const pagination = {
					...this.state.pagination,
					total: result.data?.details.totalRecords,
					current: pageNumber,
				};
				this.setState({ pagination, dataPayroll: result.data });
			}
			this.setState({ isLoading: false });
		} catch (error) {}
	}
	onClickRadioButton = (e: any) => {
		this.setState({ status: e.target.value });
	};

	renderMenuExport = () => (
		<Menu>
			<Menu.Item key="menuPDF" onClick={() => alert('Export PDF')}>
				{I18n.t(_t(translations.services.menuPDF))}
			</Menu.Item>
			<Menu.Item key="menuExcel" onClick={() => alert('Export Excel')}>
				{I18n.t(_t(translations.services.menuExcel))}
			</Menu.Item>
			<Menu.Item key="menuCSV" onClick={() => alert('Export CSV')}>
				{I18n.t(_t(translations.services.menuCSV))}
			</Menu.Item>
		</Menu>
	);

	onChangeDateRange = (value: number) => {
		switch (value) {
			case DateRangeType.TODAY:
				this.setState(
					{
						startDate: moment(),
						endDate: moment(),
						showDateRange: false,
						selectedDateRangeType: DateRangeType.TODAY,
					},
					() => {
						this.onTableChange(1, 'ascend', '');
					}
				);
				break;
			case DateRangeType.YESTERDAY:
				this.setState(
					{
						startDate: moment().add(-1, 'day'),
						endDate: moment().add(-1, 'day'),
						showDateRange: false,
						selectedDateRangeType: DateRangeType.YESTERDAY,
					},
					() => {
						this.onTableChange(1, 'ascend', '');
					}
				);
				break;
			case DateRangeType.WEEKLY:
				this.setState({ showDateRange: false }, () =>
					this.setState(
						{
							startDate: moment(moment()).startOf('week'),
							endDate: moment(moment()).endOf('week'),
							showDateRange: true,
							selectedDateRangeType: DateRangeType.WEEKLY,
						},
						() => {
							this.onTableChange(1, 'ascend', '');
						}
					)
				);

				break;
			case DateRangeType.SEMI_MONTH_1st:
				this.setState({ showDateRange: false }, () =>
					this.setState(
						{
							startDate: moment(moment()).startOf('month'),
							endDate: moment(moment()).startOf('month').add(14, 'day'),
							showDateRange: true,
							selectedDateRangeType: DateRangeType.SEMI_MONTH_1st,
						},
						() => {
							this.onTableChange(1, 'ascend', '');
						}
					)
				);

				break;
			case DateRangeType.SEMI_MONTH_2nd:
				this.setState({ showDateRange: false }, () =>
					this.setState(
						{
							startDate: moment(moment()).startOf('month').add(15, 'day'),
							endDate: moment(moment()).endOf('month'),
							showDateRange: true,
							selectedDateRangeType: DateRangeType.SEMI_MONTH_2nd,
						},
						() => {
							this.onTableChange(1, 'ascend', '');
						}
					)
				);

				break;
			case DateRangeType.MONTHLY:
				this.setState({ showDateRange: false }, () =>
					this.setState(
						{
							startDate: moment(moment()).startOf('month'),
							endDate: moment(moment()).endOf('month'),
							showDateRange: true,
							selectedDateRangeType: DateRangeType.MONTHLY,
						},
						() => {
							this.onTableChange(1, 'ascend', '');
						}
					)
				);
				break;
			case DateRangeType.LAST_MONTH:
				this.setState({ showDateRange: false }, () =>
					this.setState(
						{
							startDate: moment(moment().add(-1, 'month')).startOf('month'),
							endDate: moment(moment().add(-1, 'month')).endOf('month'),
							showDateRange: true,
							selectedDateRangeType: DateRangeType.LAST_MONTH,
						},
						() => {
							this.onTableChange(1, 'ascend', '');
						}
					)
				);
				break;
			case DateRangeType.DATE_RANGE:
				this.setState({
					showDateRange: true,
					selectedDateRangeType: DateRangeType.DATE_RANGE,
				});
				break;
			default:
				break;
		}
	};

	onCalendarOk = () => {};

	onCalendarChange = (
		dates: any,
		dateStrings: [string, string],
		info: { range: string }
	) => {
		this.setState({ startDate: dates[0], endDate: dates[1] });
	};
	onPrintAll = async (dataPayroll: IPayrollSummary) => {
		// this.setState({ payroll: dataPayroll, printType: PrintType.ALL });
		const period = `${moment(
			this.state.startDate ? this.state.startDate! : ''
		).format('MM/DD/YYYY')} - ${moment(
			this.state.endDate ? this.state.endDate! : ''
		).format('MM/DD/YYYY')}`;
		const payrollAllESCCommands = createESCPayrollAll(
			dataPayroll.details.data,
			period
		);
		// eslint-disable-next-line no-restricted-globals
		await print(payrollAllESCCommands, undefined, true);
	};

	onPrint = async (payrollItem: IPayrollSummaryDetail) => {
		// this.setState({ printType: PrintType.ONE });
		const period = `${moment(
			this.state.startDate ? this.state.startDate! : ''
		).format('MM/DD/YYYY')} - ${moment(
			this.state.endDate ? this.state.endDate! : ''
		).format('MM/DD/YYYY')}`;

		const payrollESCCommands = createESCPayroll(payrollItem, period);
		await print(payrollESCCommands, undefined);
		// if (printReport) {
		//     alert('123')
		// }
	};

	dateDidUpdate = (prevState: IPayrollStates) => {
		if (
			ValidationHelper.isThisBoolDiffThatBool(
				prevState.showDatePickerModal || false,
				this.state.showDatePickerModal || false
			) &&
			this.state.showDatePickerModal === false
		) {
			if (this.state.startDate.isAfter(this.state.endDate)) {
				this.setState({
					dataPayroll: undefined,
				});
				AlertHelper.showAlert(
					I18n.t(_t(translations.text.error)),
					I18n.t(_t(translations.payrollDetailReport.datePickerModalMessage)),
					'error'
				);
			}
		}
	};

	canApply = (): boolean => {
		if (this.state.startDate.isAfter(this.state.endDate)) {
			return false;
		}
		return true;
	};
}

const styles = {
	maxWidth: { width: '100%' },
	textRight: { textAlign: 'right' } as CSSProperties,
};

const mapStateToProps = (state: RootState) => ({
	branchAction: state.ReduxActionReducer['BRANCH'],
	staffs: state.EmployeeReducer.employees,
});

export default connect(mapStateToProps)(Payroll);
