/* eslint-disable react-hooks/exhaustive-deps */
import ModalHelper from 'helpers/modalHelper';
import { IHolidayModel } from 'models';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import I18n from 'i18n-js';
import { translations, _t } from 'utils';
import { FormInstance, Form, Row, Typography } from 'antd';
import {
	ConfirmModal,
	EmployeePickerModal,
} from 'components';
import { FormHelper } from 'helpers';
import moment from 'moment';
import { UserOutlined } from '@ant-design/icons';
import { toTimeZone } from 'helpers/timeHelper';
import momentTz from 'moment-timezone';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { RangePickerTimeProps } from 'antd/lib/date-picker/generatePicker';
import { DateRangePicker } from 'components/DatePicker/DateRangePicker';

interface IHolidayDetailModalProps {
	isShowModal?: boolean;
	selectedHoliday?: IHolidayModel;
	onCancel: () => void;
	onDelete: (id: string) => void;
	isLoading?: boolean;
	onSubmit?: (value: IHoliday) => void;
	isShop?: boolean;
}
const HolidayDetailModal = ({
	isShowModal,
	selectedHoliday,
	onCancel,
	onDelete,
	isLoading,
	onSubmit,
	isShop,
}: IHolidayDetailModalProps) => {
	const isAdd = useMemo(() => _.isEmpty(selectedHoliday), [selectedHoliday]);
	const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>();
	const [isShowEmployeePicker, setIsShowEmployeePicker] = useState<boolean>();
	const [isEntireDay, setIsEntireDay] = useState<boolean>(isAdd ? true : false);
	const staff = useSelector((state: RootState) =>
		state.EmployeeReducer.employees?.find(
			(element) => element.id === selectedHoliday?.employeeId
		)
	);
	const formRef = useRef<FormInstance<IHoliday>>(null);
	const timezone = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch?.localTimeZone
	);

	useEffect(() => {
		// if (isEntireDay) {
		// 	formRef.current?.setFields([
		// 		{
		// 			name: 'time',
		// 			value: [
		// 				momentTz.tz(timezone!).startOf('date'),
		// 				momentTz.tz(timezone!).endOf('date'),
		// 			],
		// 		},
		// 	]);
		// } else {
		// 	formRef.current?.setFields([
		// 		{
		// 			name: 'time',
		// 			value: [
		// 				momentTz.tz(timezone!).hour(8).startOf('hour'),
		// 				momentTz.tz(timezone!).hour(22).startOf('hour'),
		// 			],
		// 		},
		// 	]);
		// }
	}, [isEntireDay]);

	const renderDateInput = () => {
		return (
			<>
				{/* <Form.Item
					name={['date', 'value']}
					initialValue={isAdd ? moment() : moment(selectedHoliday?.fromDate)}
					noStyle
				></Form.Item> */}
				<Form.Item
					name={'fromDate'}
					noStyle
					initialValue={
						selectedHoliday ? moment(selectedHoliday.fromDate) : moment()
					}
				></Form.Item>
				<Form.Item
					name={'toDate'}
					label={
						<Typography.Title level={5}>
							{I18n.t(_t(translations.holiday.date))}
						</Typography.Title>
					}
					initialValue={
						selectedHoliday ? moment(selectedHoliday.toDate) : moment()
					}
				>
					<Row>
						<DateRangePicker
							selectedDateRange={{
								fromDate: selectedHoliday
									? moment(selectedHoliday.fromDate)
									: moment(),
								toDate: selectedHoliday
									? moment(selectedHoliday.toDate)
									: moment(),
							}}
							onSelectDateRange={(dateRage) => {
								formRef.current?.setFields([
									{
										name: 'fromDate',
										value: dateRage.fromDate,
									},
								]);
								formRef.current?.setFields([
									{
										name: 'toDate',
										value: dateRage.toDate,
									},
								]);
							}}
						/>
						{/* <DateRangeSelect
							fromDate={
								formRef.current
									? formRef.current.getFieldValue('fromDate')
									: isAdd
									? moment()
									: moment(selectedHoliday?.fromDate)
							}
							toDate={
								formRef.current
									? formRef.current.getFieldValue('toDate')
									: isAdd
									? moment()
									: moment(selectedHoliday?.toDate)
							}
							onDateRangeButtonClick={(type) => {
								setDateRangePickerType(type);
								setIsShowDatePickerModal(true);
							}}
							xs={24}
							sm={24}
							md={24}
							lg={24}
						/> */}
					</Row>
				</Form.Item>
			</>
		);
	};

	const renderStaffInput = () => {
		return (
			<>
				<Form.Item
					name={['staff', 'id']}
					initialValue={isAdd ? '' : selectedHoliday?.employeeId}
					noStyle
				></Form.Item>
				{FormHelper.renderFormInput(
					['staff', 'name'],
					[
						// {
						// 	required: true,
						// 	message: I18n.t(_t(translations.holiday.staffRequired)),
						// },
					],
					I18n.t(_t(translations.holiday.staff)),
					isAdd
						? I18n.t(_t(translations.all))
						: `${staff?.firstName || ''} ${staff?.lastName || ''}`,
					undefined,
					{
						contentEditable: false,
						disabled: isLoading,
						className: 'uneditable-input-with-icon',
						suffix: <UserOutlined />,
						onClick: () => setIsShowEmployeePicker(true),
					}
				)}
			</>
		);
	};

	const renderTimeInput = () => {
		return (
			<>
				{FormHelper.renderFormSwitch(
					'',
					I18n.t(_t(translations.holiday.entireDay)),
					isEntireDay,
					undefined,
					{
						onChange: (checked) => {
							setIsEntireDay(checked);
							if (checked) {
								formRef.current?.setFields([
									{
										name: 'time',
										value: [
											momentTz.tz(timezone!).startOf('date'),
											momentTz.tz(timezone!).endOf('date'),
										],
									},
								]);
							} else {
								formRef.current?.setFields([
									{
										name: 'time',
										value: [
											momentTz.tz(timezone!).hour(8).startOf('hour'),
											momentTz.tz(timezone!).hour(22).startOf('hour'),
										],
									},
								]);
							}
						},
					}
				)}
				{isEntireDay ? (
					<Form.Item
						name="time"
						noStyle
						initialValue={[
							momentTz.tz(timezone!).startOf('date'),
							momentTz.tz(timezone!).endOf('date'),
						]}
					/>
				) : (
					FormHelper.renderFormTimeRangePicker(
						'time',
						I18n.t(_t(translations.holiday.time)),
						isAdd
							? [
									momentTz.tz(timezone!).hour(8).startOf('hour'),
									momentTz.tz(timezone!).hour(22).startOf('hour'),
							  ]
							: [
									toTimeZone(selectedHoliday!.startTime),
									toTimeZone(selectedHoliday!.endTime),
							  ],
						[
							{
								required: true,
								message: I18n.t(_t(translations.holiday.timeRequired)),
							},
						],
						undefined,
						{ use12Hours: true, format: 'h:mmA' } as RangePickerTimeProps<any>
					)
				)}
			</>
		);
	};

	const renderDescription = () => {
		return FormHelper.renderFormTextArea(
			'description',
			[],
			I18n.t(_t(translations.addHoliday.description)),
			I18n.t(_t(translations.addHoliday.description)),
			isAdd ? '' : selectedHoliday?.description
		);
	};

	return (
		<>
			<ModalHelper
				visible={isShowModal || false}
				centered
				title={
					isAdd
						? I18n.t(_t(translations.holiday.newHoliday))
						: I18n.t(_t(translations.holiday.editHoliday))
				}
				onCancel={onCancel}
				onOk={() => {
					formRef.current?.submit();
				}}
				btnDelete={!isAdd ? true : false}
				onDelete={() => setIsShowConfirmModal(true)}
				disableDelete={moment(selectedHoliday?.fromDate).isBefore(
					moment().startOf('date')
				)}
				isLoading={isLoading}
			>
				<Form
					ref={formRef}
					layout="vertical"
					name="expense-detail-form"
					onFinish={(value) =>
						onSubmit &&
						onSubmit({
							...value,
							time: isEntireDay ? undefined : value.time,
						})
					}
				>
					{renderDateInput()}
					{!isShop && renderStaffInput()}
					{renderTimeInput()}
					{renderDescription()}
				</Form>
			</ModalHelper>
			{isShowEmployeePicker && (
				<EmployeePickerModal
					allOption
					onCancel={() => setIsShowEmployeePicker(false)}
					onSelectEmployee={(employee) => {
						formRef.current?.setFields([
							{
								name: 'staff',
								value: {
									id: employee?.id,
									name: _.isEmpty(employee)
										? I18n.t(_t(translations.all))
										: `${employee?.firstName || ''} ${
												employee?.lastName || ''
										  }`,
								},
							},
						]);
						formRef.current?.validateFields();
						setIsShowEmployeePicker(false);
					}}
					selectedEmployeeId={formRef.current?.getFieldValue(['staff', 'id'])}
				/>
			)}

			{isShowConfirmModal && (
				<ConfirmModal
					visible
					onCancel={() => setIsShowConfirmModal(false)}
					onOk={() => {
						setIsShowConfirmModal(false);
						onDelete(selectedHoliday!.id);
					}}
				/>
			)}
		</>
	);
};

type IHoliday = {
	description: string;
	staff: { id: string; name: string };
	time?: moment.Moment[];
	fromDate: moment.Moment;
	toDate: moment.Moment;
};

export type { IHoliday };

export default HolidayDetailModal;
