import { combineReducers } from 'redux';
import {
	AppConfigReducer,
	UserReducer,
	ServiceReducer,
	CategoryReducer,
	AuthenticationReducer,
	EmployeeReducer,
	ErrorReducer,
	ReduxActionReducer,
	BranchReducer,
	BookingReducer,
	CustomerReducer,
	ShopReducer,
	SearchReducer,
	CheckoutReducer,
	HolidayReducer,
	AdminDashboardReducer,
	SettingReducer,
	RoleReducer,
	EmployeeTypeReducer,
	PaymentMethodReducer,
	CurrencyReducer,
	CustomerBookingReducer,
	ThemeReducer,
	ExpenseTypeReducer,
	BillingReducer,
} from '../reducers';
// const reducerMap = {
//   UserReducer: reducers.UserReducer,
// };
const rootReducer = combineReducers({
	AppConfigReducer,
	UserReducer,
	ServiceReducer,
	CategoryReducer,
	AuthenticationReducer,
	EmployeeReducer,
	ErrorReducer,
	ReduxActionReducer,
	BranchReducer,
	BookingReducer,
	CustomerReducer,
	ShopReducer,
	CheckoutReducer,
	SearchReducer,
	HolidayReducer,
	AdminDashboardReducer,
	SettingReducer,
	RoleReducer,
	EmployeeTypeReducer,
	PaymentMethodReducer,
	CurrencyReducer,
	CustomerBookingReducer,
	ThemeReducer,
	ExpenseTypeReducer,
	BillingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
