import { ICustomer } from "models";
import { BaseApiService } from "services/BaseApiService";

class CustomerApiService extends BaseApiService {
  public getCustomers = (query?: any) =>
    this.get<ICustomer[]>(`/Customer/get-all-customers`, {
      params: query,
    });
  public getCustomerByEmail = (email: string) =>
    this.get<ICustomer[]>(`/Customer/get-customer-by-email?email=${email}`);
  public getCustomerByPhone = (phone: string) =>
    this.get<ICustomer[]>(`/Customer/get-customer-by-phone?phoneNumber=${phone}`);
  public addCustomer = (addCustomerRequest: Partial<ICustomer>) =>
    this.post<Partial<ICustomer>, string>(`/Customer/create-customer`, addCustomerRequest);
  public editCustomer = (editCustomerRequest: Partial<ICustomer>) =>
    this.put<Partial<ICustomer>, string>(`/Customer/update-customer`, editCustomerRequest);
  public getCustomerById = (id: string) => this.get<ICustomer>(`/Customer/get-customer/${id}`);
  public deleteCustomer = (id: string) => this.delete<string>(`/Customer/delete-customer/${id}`);
}

export default new CustomerApiService();
