import { kStyles } from "GlobalStyles";

const styles: kStyles = {
  spinLayout: {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  spin: { margin: "0 auto" },
};

export default styles;
