import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Result, Button } from 'antd';
import { I18n, translations, _t } from 'utils';
import configureStore from 'redux/configuration/configureStore';
import { AppConfigActions } from 'redux/actions';
import _ from 'lodash';
import { electron } from 'ipc';

interface Props {
	children: ReactNode;
}

interface State {
	hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false,
	};

	async componentDidMount() {
		if (electron) {
			const branchId =  await electron.ipcRenderer.getBranchId()
			const uuid = await electron.ipcRenderer.getUuid()
			if (!_.isEmpty(uuid)) {
				configureStore().store.dispatch(
					AppConfigActions.setUuid.request(uuid)
				);
			}
		}
		
	}

	public static getDerivedStateFromError(_: Error): State {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error('Uncaught error:', error, errorInfo);
	}

	public render() {
		if (this.state.hasError) {
			return (
				<Result
					status="warning"
					title={I18n.t(_t(translations.someProblem))}
					extra={
						<Button
							onClick={() => (window.location.href = '/')}
							type="primary"
							key="console"
						>
							{I18n.t(_t(translations.reload))}
						</Button>
					}
				/>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
