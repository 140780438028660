import { Col, Input, List, Spin, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import CheckOutBaseModal from './CheckOutBaseModal';
import styles from './styles';
import { translations, _t, I18n } from 'utils';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import { BookingItem } from 'components';
import { IApiResponse, IBooking, IBookingDetail } from 'models';
import BookingApiService from 'services/BookingApiService';
import { IGetBookingsRequest } from 'models/RequestModels';
import moment from 'moment';
import { convertDateRequest } from 'helpers/bookingHelper';
import InfiniteScroll from 'react-infinite-scroller';
import { BookingStatus } from 'utils/Consts';
import { AlertHelper } from 'helpers';
import { Dispatch } from '@reduxjs/toolkit';
import { BookingActions } from 'redux/actions';
import { showAlert, showError } from 'helpers/alertHelper';
import BillingApiService from 'services/BillingApiService';
import { IBilling } from 'models/IBilling';

interface ICombineBillModalProps extends ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> {
	onOk(bookingItem: IBooking): void;
	onCancel(): void;
	currentBookingId: string;
}

interface ICombineBillModalState {
	searchText?: string;
	listBooking?: IBooking[];
	isLoading: boolean;
}

class CombineBillModal extends React.Component<
	ICombineBillModalProps,
	ICombineBillModalState
> {
	constructor(props: ICombineBillModalProps) {
		super(props);
		this.state = {
			isLoading: false,
		};
		this.onChangeDebounced = _.debounce(this.onChangeDebounced, 1000);
	}

	componentDidMount() {
		this.fetchData();
	}

	render() {
		return (
			<CheckOutBaseModal
				title={I18n.t(_t(translations.checkout.combineBill))}
				// onOk={() => this.onOk()}
				onOk={() => { }}
				onCancel={this.props.onCancel}
				width={1000}
				btnOkHidden
				hideSaveBtn
			>
				<Spin spinning={this.state.isLoading}>
					<Input
						style={styles.maxWidth}
						// onPressEnter={this.onOk}
						autoFocus={true}
						value={this.state.searchText}
						onChange={(e) => this.onChangeText(e)}
						placeholder={I18n.t(_t(translations.placeHolder.combineBill))}
					/>
					<div
						style={{
							height: '300px',
							overflow: 'auto',
						}}
					>
						<InfiniteScroll
							initialLoad={false}
							pageStart={0}
							loadMore={() => { }}
							useWindow={false}
							style={{ paddingTop: 16, paddingBottom: 8 }}
						>
							<List
								dataSource={this.state.listBooking}
								rowKey={(e) => e.id!.toString()}
								renderItem={(booking: IBooking) => {
									return (
										<BookingItem
											key={`bookingItem-${booking.id}`}
											booking={booking}
											showDivider
											onClick={(booking) => this.onItemClick(booking)}
										/>
									);
								}}
							></List>
						</InfiniteScroll>
					</div>
				</Spin>
			</CheckOutBaseModal>
		);
	}

	onOk = (booking: IBooking) => {
		this.props.onOk(booking);
	};

	onItemClick = (booking: IBooking) => {
		if (this.checkNoStylist(booking)) {
			AlertHelper.showError({
				message: [
					{
						Text: 'Booking must be assigned to stylists/technicians before checkout',
					},
				],
			});
		} else {
			this.onOk(booking)
		}
	};

	checkNoStylist = (booking: IBooking) => {
		let noStylist = false;
		for (const bookingDetail of booking.bookingDetails) {
			if (_.isEmpty(bookingDetail.stylistId)) {
				noStylist = true;
				break;
			}
		}
		return noStylist;
	};

	fetchData = async () => {
		const params: IGetBookingsRequest = {
			bookingStatus: `${BookingStatus.CHECKED_IN},${BookingStatus.PROCESSING},${BookingStatus.FINISHED}`,
			searchText: this.state.searchText,
			fromDate: convertDateRequest(moment()),
			toDate: convertDateRequest(moment()),
			orderBy: 'date desc',
		};
		this.setState({ isLoading: true });
		const bookingResponse = await BookingApiService.getBookingByBranch(params);
		this.setState({ isLoading: false });
		if (!_.isEmpty(bookingResponse.errors)) {
			return;
		}
		const bookingSuccess = bookingResponse as IApiResponse<IBooking[]>;
		if (!_.isEmpty(bookingSuccess.data)) {
			let listBooking = _.filter(
				bookingSuccess.data,
				(booking) =>
					!this.props.listBookingCombined
						.map((booking) => booking.id)
						.includes(booking.id)
			);
			_.remove(
				listBooking,
				(booking) => booking.id === this.props.currentBookingId
			);
			this.setState({ listBooking });
			return;
		}
		this.setState({ listBooking: [] });
	};

	onChangeText = (e: any) => {
		this.setState({ searchText: e.target.value });
		this.onChangeDebounced(e);
	};
	onChangeDebounced = (e: Event) => {
		if (this.state.searchText === '') {
			this.setState({ listBooking: [] });
			return;
		}
		this.fetchData();
	};
}

const mapStateToProps = (state: RootState) => ({
	employees: state.EmployeeReducer.employees,
	listBookingCombined: state.CheckoutReducer.listBookingCombine,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	updateBooking: (params: IBooking) => {
		dispatch(BookingActions.editBooking.request(params));
	},
});
export default connect(mapStateToProps, mapDispatchToProps)(CombineBillModal);
