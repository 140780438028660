import { Dispatch } from '@reduxjs/toolkit';
import { Content } from 'antd/lib/layout/layout';
import _ from 'lodash';
import { IUser } from 'models/IUser';
import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router';
import { BookingActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import PermissionRoute from 'routers/PermissionRoute';
import { getSubRoutes, HomeRoute } from 'routers/routes';
import SocketService from 'services/SocketService';

interface IContentAdmin
	extends ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps> {
	user: IUser;
}

class ContentAdmin extends React.Component<IContentAdmin> {
	render() {
		return (
			<Content style={{ marginTop: 64, height: '100%' }}>
				<Switch>
					{HomeRoute.concat(getSubRoutes()).map((route) => {
						const hasPermission =
							_.isEmpty(route.roles) ||
							!_.isEmpty(
								_.intersection(route.roles, this.props.user?.userRoles)
							) ||
							(this.props.employee &&
								_.includes(
									route.roles,
									this.props.employee?.employeeTypeCode
								))!;
						return (
							<PermissionRoute
								hasPermission={hasPermission}
								exact
								path={route.path}
								component={route.component}
								key={route.key}
							/>
						);
					})}
				</Switch>
				{/* <Redirect exact to="/dashboard" from="/" /> */}
			</Content>
		);
	}
}

const mapStateToProps = (state: RootState) => ({
	// listEmployees: state.EmployeeReducer.employees,
	employee: state.UserReducer.employee,
	currentAction: state.ReduxActionReducer['BOOKING'],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	getTodayBookings: () => {
		dispatch(BookingActions.getTodayBookings.request());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentAdmin);
