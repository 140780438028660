import { ReduxHelper } from 'helpers';
import { IUser } from 'models/IUser';
import {
	IForgotPasswordRequest,
	ILoginRequest,
	IResetPasswordRequest,
} from 'models/RequestModels';

export const prefix = 'AUTHENTICATION';

const authenticate = ReduxHelper.generateActions<ILoginRequest, IUser, any>(
	`${prefix}/AUTHENTICATE`
);
const setToken = ReduxHelper.generateLocalAction<{
	token: string;
	refreshToken: string;
}>(prefix + '/SET_TOKEN');

const setDeviceId = ReduxHelper.generateLocalAction<{ deviceId: string }>(
	prefix + '/SET_DEVICE_ID'
);

const clearError = ReduxHelper.generateLocalAction(`${prefix}/CLEAR_ERROR`);
const logOut = ReduxHelper.generateLocalAction(`${prefix}/LOG_OUT`);
const forgotPassword = ReduxHelper.generateActions<IForgotPasswordRequest>(
	`${prefix}/FORGOT_PASSWORD`
);
const resetPassword = ReduxHelper.generateActions<IResetPasswordRequest>(
	`${prefix}/RESET_PASSWORD`
);
const setAdminAccount = ReduxHelper.generateLocalAction<string>(
	`${prefix}/SET_ADMIN_ACCOUNT`
);

export {
	authenticate,
	setToken,
	clearError,
	logOut,
	forgotPassword,
	resetPassword,
	setDeviceId,
	setAdminAccount,
};
