import { ReduxHelper } from 'helpers';
import { IBooking } from 'models';
import { IBilling } from 'models/IBilling';
import { IBranch } from 'models/IBranch';
import { IGiftCard } from 'models/IGiftCard';
import { IPromotion } from 'models/IPromotion';
import { ITip } from 'models/ITip';
import { IPaymentType } from 'models/ITransaction';
import {
	ICheckOutRequest,
	IUpdateChildBookingAndBill,
} from 'models/RequestModels';
import { DiscounterType, DiscountType, TipType } from 'utils/Consts';

export const prefix = 'CHECKOUT';

const addDiscount = ReduxHelper.generateLocalAction<{
	discount: number;
	discountPercent: number;
	discounterType: DiscounterType;
	discountType: DiscountType;
}>(`${prefix}/ADD_DISCOUNT`);

const addTipLocal = ReduxHelper.generateLocalAction<{
	tip: ITip;
	tipValue: number;
	branch: IBranch;
	isChangePayment: boolean;
}>(`${prefix}/ADD_TIP`);

const deleteTipLocal = ReduxHelper.generateLocalAction<{
	tip: ITip;
	tipValue: number;
	branch: IBranch;
	amountNeedToPay: number;
}>(`${prefix}/DELETE_TIP`);

const addTipTypeLocal = ReduxHelper.generateLocalAction<TipType>(
	`${prefix}/ADD_TIP_TYPE`
);

const checkOut = ReduxHelper.generateActions<ICheckOutRequest>(
	`${prefix}/CHECK_OUT`
);

const addToListGiftCard = ReduxHelper.generateLocalAction<Partial<IGiftCard>>(
	`${prefix}/ADD_TO_LIST_GIFT_CARD`
);

const addPaymentType = ReduxHelper.generateLocalAction<Partial<IPaymentType>>(
	`${prefix}/ADD_PAYMENT_TYPE`
);

const deletePaymentType = ReduxHelper.generateLocalAction<{
	paymentType: number;
	giftCode?: string;
}>(`${prefix}/DELETE_PAYMENT_TYPE`);

const removeAllPaymentType = ReduxHelper.generateLocalAction(
	`${prefix}/REMOVE_ALL_PAYMENT_TYPE`
);

const deleteGiftCard = ReduxHelper.generateActions<string, string>(
	`${prefix}/DELETE_GIFT_CARD`
);

const addBookingCombine = ReduxHelper.generateLocalAction<IBooking>(
	`${prefix}/ADD_BOOKING_COMBINE`
);

const createBill = ReduxHelper.generateActions<
	Partial<IBilling>,
	Partial<IBilling>
>(`${prefix}/CREATE_BILL`);

const deleteBookingCombine = ReduxHelper.generateLocalAction<string>(
	`${prefix}/DELETE_BOOKING_COMBINE`
);

const updateChildBookingAndBill = ReduxHelper.generateActions<
	IUpdateChildBookingAndBill,
	void,
	void
>(`${prefix}/UPDATE_CHILD_BOOKING_AND_BILL`);

const updateBookingToListCombine = ReduxHelper.generateLocalAction<IBooking>(
	`${prefix}/UPDATE_BOOKING_TO_LIST_COMBINE`
);

const addExtraAmount = ReduxHelper.generateLocalAction<{
	billIndex: number;
	bookingItemIndex: number;
	amount: number;
	note: string;
}>(`${prefix}/ADD_EXTRA_AMOUNT`);

const deleteExtraAmount = ReduxHelper.generateLocalAction<{
	billIndex: number;
	bookingItemIndex: number;
}>(`${prefix}/DELETE_EXTRA_AMOUNT`);

const applyPromotion = ReduxHelper.generateLocalAction<
	Partial<IPromotion> | undefined
>(`${prefix}/APPLY_PROMOTION`);

const updatePromotionDiscount = ReduxHelper.generateLocalAction<
	{ id: string; promotionDiscount: number }[]
>(`${prefix}/UPDATE_PROMOTION_DISCOUNT_FOR_BOOKING_COMBINED`);

const setBillId = ReduxHelper.generateLocalAction<string>(
	`${prefix}/SET_BILL_ID`
);
const setTipIds = ReduxHelper.generateLocalAction<
	{
		employeeId: string;
		id: string;
	}[]
>(`${prefix}/SET_TIP_IDs`);
const resetAll = ReduxHelper.generateLocalAction(`${prefix}/RESET_ALL`);

export {
	addDiscount,
	addTipLocal,
	addTipTypeLocal,
	checkOut,
	addToListGiftCard,
	deleteGiftCard,
	resetAll,
	addPaymentType,
	deletePaymentType,
	addBookingCombine,
	createBill,
	deleteBookingCombine,
	addExtraAmount,
	deleteExtraAmount,
	updateBookingToListCombine,
	updateChildBookingAndBill,
	removeAllPaymentType,
	applyPromotion,
	updatePromotionDiscount,
	deleteTipLocal,
	setBillId,
	setTipIds,
};
