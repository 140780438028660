const styles: any = {
	nailForm: {
		backgroundColor: 'white',
		display: 'block',
		padding: '24px',
		marginTop: '24px',
	},
	alert: {
		margin: '16px 0',
	},
	input: {
		height: '50px',
		margin: '0 0 16px 0',
	},
	forgotText: {
		color: '#1890ff',
		float: 'right',
	},
	otherShop: {
		float: 'right',
	},
	formItem: {
		marginBottom: 0
	}
};

export default styles;
