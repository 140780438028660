import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Spin, Typography } from 'antd';
import { checkIsMobile, TimeHelper } from 'helpers';
import _ from 'lodash';
import { EBookingInputFrom, IBooking } from 'models';
import { IStylistAndBlockTimeRequest } from 'models/RequestModels';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerBookingActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import BookingApiService from 'services/BookingApiService';
import { I18n, translations, _t } from 'utils';
import '../../CustomerBooking/style.css';
import styles from '../styles';
const { Title, Text } = Typography;

const checkDisabled = (date: any) => {
	const today = new Date();
	today.setDate(today.getDate() - 1);
	const newDate = new Date(date);
	if (today.getTime() > newDate.getTime()) {
		return true;
	}
	return false;
};

const isItWeekEnd = (day: any) => {
	let dateValue = day.getDay();
	if (dateValue === 6 || dateValue == 0) return true;
	else return false;
};

const groupTimeBlocks = (timeBlocks: any) => {
	return timeBlocks
		.filter((x: any) => x.unavailable === false)
		.reduce((r: any, a: any) => {
			r[a.label.slice(-2)] = [...(r[a.label.slice(-2)] || []), a];
			return r;
		}, {});
};

const dates = (current: Date, amount: number) => {
	var listDate = new Array();
	// Get 6 days next
	for (var i = 0; i < amount; i++) {
		listDate.push(new Date(current));
		current.setDate(current.getDate() + 1);
	}
	return listDate;
};

const isCompareDate = (selectedDay: Date, day: Date) => {
	return formatDay(selectedDay) == formatDay(day);
};

const formatDay = (days: Date) => moment(days).format('MM-DD-YYYY');

interface IDateCalendar {
	selectedDay: any;
	daysOfWeek: any[];
	currentMonthYear: any[];
	selectedTime: any;
	existDisplay: any[];
}

interface ICalendarProps {
	dateCalendar: IDateCalendar;
	handleDecreaseWeek: () => void;
	handleChooseCalendar: (x: Date) => void;
	handleIncreaseWeek: () => void;
	isMobile: boolean;
}

const Calendar = (props: ICalendarProps) => {
	return (
		<Row className="d-calendar">
			<Col span={24}>
				<Row gutter={[8, 8]} justify="center" style={{ marginBottom: '-15px' }}>
					<Col>
						<LeftOutlined
							className="iconAction"
							onClick={() => props.handleDecreaseWeek()}
						/>
					</Col>
					<Col span={22} style={{ maxWidth: '87%' }}>
						<Row gutter={[8, 8]}>
							{
								(props.dateCalendar.existDisplay =
									[] &&
									props.dateCalendar.daysOfWeek?.map((x: any) => {
										let dateView = moment(x).format('MMM');
										const exist = props.dateCalendar.existDisplay.find(
											(y: any) => y === moment(x).format('MMM')
										);
										if (!exist) {
											props.dateCalendar.existDisplay.push(
												moment(x).format('MMM')
											);
										}
										const obj = (
											<Col span={props.isMobile ? 6 : 4}>
												<Title level={4} style={{ marginLeft: '6px' }}>
													{exist ? <span>&nbsp;</span> : dateView}
												</Title>
												<Button
													type={
														isCompareDate(props.dateCalendar.selectedDay, x)
															? 'primary'
															: 'default'
													}
													className={isItWeekEnd(x) ? 'lastWeek' : ''}
													style={styles.buttonCalendar}
													disabled={checkDisabled(x) ? true : false}
													onClick={() => props.handleChooseCalendar(x)}
												>
													{moment(x).format('ddd')} <br /> {x.getDate()}
												</Button>
											</Col>
										);
										return obj;
									}))
							}
						</Row>
					</Col>
					<Col>
						<RightOutlined
							className="iconAction"
							onClick={() => props.handleIncreaseWeek()}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

interface ITimeBlockProps {
	timeBlocks: any;
	dateCalendar: IDateCalendar;
	handleChooseTime: (item: any) => void;
	isMobile: boolean;
}
const TimeBlock = (props: ITimeBlockProps) => {
	return (
		<Row className="d-time">
			{props.timeBlocks?.AM ? (
				<>
					<Col span={24}>
						<Title level={5}>
							{I18n.t(_t(translations.customerBooking.morning))}
						</Title>
					</Col>
					<Col span={24}>
						<Row gutter={[8, 8]} style={{ margin: 0 }}>
							{props.timeBlocks?.AM?.map((item: any) => (
								<Text
									onClick={() => props.handleChooseTime(item)}
									style={
										props.dateCalendar.selectedTime != item.label
											? {
													...styles.timeContainer,
													width: props.isMobile ? 90 : 100,
											  }
											: {
													...styles.timeContainer,
													...styles.timeContainerActive,
													width: props.isMobile ? 90 : 100,
											  }
									}
								>
									{item.label}
								</Text>
							))}
						</Row>
					</Col>
				</>
			) : null}
			{props.timeBlocks?.PM ? (
				<>
					<Col span={24} style={{ marginTop: '16px' }}>
						<Title level={5}>
							{I18n.t(_t(translations.customerBooking.afternoon))}
						</Title>
					</Col>
					<Col span={24}>
						<Row gutter={[8, 8]} style={{ margin: 0 }}>
							{props.timeBlocks?.PM?.map((item: any) => (
								<Text
									onClick={() => props.handleChooseTime(item)}
									style={
										props.dateCalendar.selectedTime != item.label
											? {
													...styles.timeContainer,
													width: props.isMobile ? 90 : 100,
											  }
											: {
													...styles.timeContainer,
													...styles.timeContainerActive,
													width: props.isMobile ? 90 : 100,
											  }
									}
								>
									{item.label}
								</Text>
							))}
						</Row>
					</Col>
				</>
			) : null}
			{_.isEmpty(props.timeBlocks) && (
				<Col span={24} style={{ textAlign: 'center' }}>
					<Title level={5}>
						{I18n.t(_t(translations.customerBooking.stylistNotWorking))}
					</Title>
				</Col>
			)}
		</Row>
	);
};

export const SelectStaffCalendar = () => {
	const isMobile = checkIsMobile();
	const dispatch = useDispatch();
	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	const [dateCalendar, setDateCalendar] = useState<any>({
		selectedDay: formatDay(new Date()),
		daysOfWeek: [],
		currentMonthYear: formatDay(new Date()),
		selectedTime: '',
		existDisplay: [],
	});
	const [loading, setLoading] = useState(false);
	const [timeBlocks, setTimeBlocks] = useState<any>([]);

	useEffect(() => {
		const currentDaysOfWeek: any = dates(new Date(), isMobile ? 4 : 6);
		setDateCalendar({
			...dateCalendar,
			daysOfWeek: currentDaysOfWeek,
			selectedDay: currentDaysOfWeek[0],
		});
		getAvailableStylistAndTimeBlocks(dateCalendar.selectedDay);
	}, []);

	const handleDecreaseWeek = () => {
		let fistDayOfLastWeek = '';
		if (isMobile) {
			fistDayOfLastWeek = moment(dateCalendar.daysOfWeek[0])
				.subtract(4, 'days')
				.format('YYYY-MM-DD');
		} else {
			fistDayOfLastWeek = moment(dateCalendar.daysOfWeek[1])
				.subtract(1, 'weeks')
				.format('YYYY-MM-DD');
		}

		const currentDaysOfWeek: any = dates(
			new Date(fistDayOfLastWeek),
			isMobile ? 4 : 6
		);
		setDateCalendar({
			...dateCalendar,
			daysOfWeek: currentDaysOfWeek,
		});
	};
	const handleIncreaseWeek = () => {
		const fistDayOfNextWeek = moment(dateCalendar.daysOfWeek.pop())
			.add(1, 'day')
			.format('YYYY-MM-DD');
		const currentDaysOfWeek: any = dates(
			new Date(fistDayOfNextWeek),
			isMobile ? 4 : 6
		);
		setDateCalendar({
			...dateCalendar,
			daysOfWeek: currentDaysOfWeek,
		});
	};

	const handleChooseCalendar = (day: any) => {
		const currentDaysOfWeek: any = dates(new Date(day), isMobile ? 4 : 6);
		setDateCalendar({
			...dateCalendar,
			daysOfWeek: currentDaysOfWeek,
			selectedDay: currentDaysOfWeek[0],
		});
		getAvailableStylistAndTimeBlocks(day);
	};

	const getAvailableStylistAndTimeBlocks = async (day: any) => {
		setLoading(true);
		const param: IStylistAndBlockTimeRequest = {
			date: formatDay(day),
			// serviceId: 'bb32d366-8aff-4498-f47e-08d9a12237eb', // TODO
			serviceId: booking?.bookingDetails![0].itemId,
			stylistId:
				booking?.bookingDetails![0].stylistId !== 'Anyone'
					? booking?.bookingDetails![0].stylistId
					: undefined,
			inputFrom: EBookingInputFrom.OnlineSite,
		};
		const response: any =
			await BookingApiService.getAvailableStylistAndTimeBlocks(param);
		if (response?.succeeded) {
			response.data.timeBlocks.map((item: any) => {
				item.label = TimeHelper.toTimeZone(item.time).format('hh:mmA');
			});

			setTimeBlocks(groupTimeBlocks(response.data.timeBlocks));
		}
		setLoading(false);
	};

	const handleChooseTime = (time: any) => {
		setDateCalendar({ ...dateCalendar, selectedTime: time.label });
		const newBooking: Partial<IBooking> = {
			...booking,
			startTimeExpected: time.time,
			date: TimeHelper.toTimeZone(moment.utc(time.time).toDate()).format(
				'YYYY-MM-DDT00:00:00'
			),
		};
		dispatch(CustomerBookingActions.updateBooking.request(newBooking));
	};

	return (
		<div className="containerCalendar">
			<Calendar
				dateCalendar={dateCalendar}
				handleDecreaseWeek={() => handleDecreaseWeek()}
				handleChooseCalendar={(x) => handleChooseCalendar(x)}
				handleIncreaseWeek={() => handleIncreaseWeek()}
				isMobile={isMobile}
			/>
			<Divider style={{ borderTop: 'none', margin: '10px 0' }} />
			<Spin spinning={loading}>
				<TimeBlock
					timeBlocks={timeBlocks}
					dateCalendar={dateCalendar}
					handleChooseTime={(item) => handleChooseTime(item)}
					isMobile={isMobile}
				/>
			</Spin>
		</div>
	);
};
