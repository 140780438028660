import { kStyles } from 'GlobalStyles';

const styles: kStyles = {
	closedDateContainer: {
		display: 'flex',
		width: '100%',
	},
	workingHourCellLink: {
		padding: 8,
		borderRadius: 5,
		display: 'block',
	},
	workingHourCellEmpty: {
		backgroundColor: 'white',
	},
	workingHourCellNotEmpty: {
		backgroundColor: 'rgba(0,0,0,0.5)',
	},
	workingHourCellTextEmpty: {
		color: 'black',
	},
	workingHourCellTextNotEmpty: {
		color: 'white',
	},
	workingHourStaffTitle: {
		width: '100%',
		height: '100%',
		textAlign: 'center',
	},
	maxWidth: {
		minWidth: '100%',
	},
	container: {
		display: 'flex',
		width: '100%',
	},
	employeeContainer: {
		width: '100%',
	},
	icon: {
		fontSize: 28,
	},
	iconBars: {
		fontSize: 28,
		marginInlineEnd: 10,
	},
	listItem: {
		borderRadius: 10,
	},
	nameContainer: {
		marginLeft: 10,
	},
	iconRight: {
		marginLeft: 'auto',
	},
	divider: {
		margin: '16px 0',
	},
	linkContainer: {
		display: 'contents',
	},
	link: {
		color: '#037aff',
	},
	pointer: {
		cursor: 'pointer',
	},
	noPaddingCol: {
		paddingInline: 0,
		width: '100%',
	},
};

export default styles;
