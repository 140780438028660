import { Col, Row } from 'antd';
import { SimpleModalListItem } from 'components';
import { kStyles } from 'GlobalStyles';
import ModalHelper from 'helpers/modalHelper';
import I18n from 'i18n-js';
import { ITheme } from 'models';
import React, { useState } from 'react';
import { translations, _t } from 'utils';

interface IThemePickerModalProps {
	isShowModal: boolean;
	onCancel: () => void;
	onChooseTheme: (theme: ITheme) => void;
	themes: ITheme[];
	initialTheme?: string;
}
const ThemePickerModal = ({
	onCancel,
	isShowModal,
	themes,
	onChooseTheme,
	initialTheme,
}: IThemePickerModalProps) => {
	const [selectedTheme, setSelectedTheme] = useState<string | undefined>(
		initialTheme
	);
	const isSelected = (themeName?: string) => selectedTheme === themeName;

	return (
		<ModalHelper
			title={I18n.t(_t(translations.shopSetting.chooseTheme))}
			onCancel={onCancel}
			onOk={() => null}
			visible={isShowModal}
			btnOkHidden
			width={'75%'}
		>
			<Col style={styles.modalContainer}>
				<Row gutter={[16, 16]}>
					{themes.map((theme) => {
						return (
							<SimpleModalListItem
								title={theme.themeName}
								onSelectItem={() => {
									setSelectedTheme(theme.themeName);
									onChooseTheme(theme);
								}}
								isSelected={isSelected(theme.themeName)}
							/>
						);
					})}
				</Row>
			</Col>
		</ModalHelper>
	);
};

const styles: kStyles = {
	modalContainer: {
		height: window.outerHeight * 0.6,
		width: '100%',
		overflow: 'scroll', //define overflow rules if you don't want the content to be flow out of screen
		overflowX: 'hidden',
	},
};

export default ThemePickerModal;
