import React, { Component, useEffect, useState } from 'react';
import {
	Button,
	Col,
	Row,
	Space,
	Collapse,
	Dropdown,
	Menu,
	List,
	Input,
	Typography,
	Spin,
} from 'antd';
import {
	EllipsisOutlined,
	CaretRightOutlined,
	SearchOutlined,
	EditOutlined,
	PlusOutlined,
	DeleteOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { I18n, _t, translations } from 'utils';
import { IService } from 'models/IServices';
import { RootState } from 'redux/configuration/rootReducer';
import { Dispatch } from 'redux';
import { ServiceActions, CategoryActions } from 'redux/actions';
import { IItemCategory } from 'models/IItemCategory';
import { AlertHelper, CurrencyHelper, TimeHelper } from 'helpers';
import _ from 'lodash';
import { IComponentProps, Modal } from 'components';
import { useHistory, withRouter } from 'react-router';
import CategoryApiService from 'services/CategoryApiService';
import { IApiResponse, IErrorResponse } from 'models';
import { useAppSelector } from 'helpers/hookHelpers';
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragEndEvent,
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableItem } from './SortableItem';
import { SortableList } from './SortableList';
import ServiceApi from 'services/ServiceApi';
const { Panel } = Collapse;
const { Text, Title } = Typography;

interface ISearchBarProps {
	searchTerm?: string;
	onSearch: (text: string) => void;
}
interface ICategoriesProps {
	onSelect: (category: IItemCategory) => void;
	currentCategory?: IItemCategory;
}
interface IListServiceProps {
	currentCategory?: IItemCategory;
}
const SearchBar = (props: ISearchBarProps) => {
	const categories = useAppSelector(
		(state) => state.CategoryReducer.categories
	);
	return (
		<Row gutter={[16, 16]} style={{ alignItems: 'center', paddingBottom: 20 }}>
			<Col md={12}>
				<Input
					allowClear
					prefix={<SearchOutlined />}
					placeholder={I18n.t(_t(translations.placeHolder.searchService))}
					style={styles.maxWidth}
					value={props.searchTerm}
					onChange={(e) => props.onSearch(`${e.target.value}`)}
				/>
			</Col>
			<Col md={12}>
				<Space
					direction="horizontal"
					size="small"
					style={{ justifyContent: 'flex-end', width: '100%' }}
				>
					<Dropdown
						trigger={['click']}
						overlay={
							<Menu>
								{!_.isEmpty(categories) ? (
									<Menu.Item key="menuNewService">
										<Link to={`/services/add-new`}>
											{I18n.t(_t(translations.services.menuNewService))}
										</Link>
									</Menu.Item>
								) : null}
								<Menu.Item key="menuNewCategory">
									<Link to={`/services/category/add-new`}>
										{I18n.t(_t(translations.services.menuNewCategory))}
									</Link>
								</Menu.Item>
							</Menu>
						}
					>
						<Button icon={<PlusOutlined />} type="primary">
							{I18n.t(_t(translations.addNew))}
						</Button>
					</Dropdown>
				</Space>
			</Col>
		</Row>
	);
};

const ListServices = (props: IListServiceProps) => {
	const allServices = useAppSelector((state) => state.ServiceReducer.services);
	const [services, setServices] = useState<IService[]>([]);
	const [showServiceDeleteModal, setShowServiceDeleteModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	useEffect(() => {
		if (props.currentCategory) {
			setServices(
				_.orderBy(
					_.filter(
						allServices,
						(x) => x.itemCategoryId === props.currentCategory?.id
					),
					['indexNumber', 'createdDate']
				)
			);
		}
	}, [allServices, props.currentCategory]);
	const handleDragEnd = async (event: DragEndEvent) => {
		const { active, over } = event;
		if (over) {
			if (active.id !== over.id) {
				const oldIndex = services.map((x) => x.id).indexOf(active.id as string);
				const newIndex = services.map((x) => x.id).indexOf(over.id as string);
				const newListServices = arrayMove(services, oldIndex, newIndex);
				const newListServicesWithIndex = newListServices.map((x, i) => {
					return {
						...x,
						indexNumber: i,
					};
				});
				setServices(newListServicesWithIndex);
				const response = (await ServiceApi.updateServicesIndexes(
					newListServicesWithIndex.map((x) => {
						return {
							id: x.id,
							indexNumber: x.indexNumber,
						};
					})
				)) as IApiResponse<IService[]>;
				if (response.data && !response.failed) {
					dispatch(ServiceActions.updateServices.request(response.data));
				}
			}
		}
	};
	const onDeleteService = async (id: string) => {
		setLoading(true);
		try {
			const result = (await ServiceApi.deleteService(
				id
			)) as IApiResponse<string>;
			setShowServiceDeleteModal(false);
			setLoading(false);

			if (result.succeeded) {
				const newServices = services.filter(
					(element) => element.id !== result.data!
				);
				setServices(newServices);
			} else {
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.error(error);
			setShowServiceDeleteModal(false);
		}
	};

	return (
		<Col span={16}>
			<Row style={{ marginBottom: 24 }} justify="space-between" align="bottom">
				<Col>
					<Typography.Title level={3} style={{ color: 'black' }}>
						{props.currentCategory?.name}
					</Typography.Title>
				</Col>
				<Col>
					<Button
						onClick={() => {
							history.push({
								pathname: `/services/add-new`,
								state: { itemCategoryId: props.currentCategory?.id },
							});
						}}
						type="default"
						icon={<PlusOutlined />}
					>
						{I18n.t(_t(translations.addService))}
					</Button>
				</Col>
			</Row>

			<SortableList<IService>
				data={services}
				onDragEnd={handleDragEnd}
				renderItem={(item, index) => {
					return (
						<List.Item
							actions={[
								<Button
									size="small"
									icon={<EditOutlined />}
									onClick={() => {
										history.push(`/services/edit/${item.id}`);
									}}
								/>,
								<Button
									size="small"
									icon={<DeleteOutlined />}
									onClick={() => {
										setShowServiceDeleteModal(true);
									}}
								/>,
							]}
							onClick={() => {}}
							style={{
								cursor: 'pointer',
								padding: 8,
								borderBottom: '1px solid #f0f0f0',
							}}
							className={
								props.currentCategory && item.id === props.currentCategory.id
									? `list-primary-background`
									: 'list-white-background'
							}
						>
							<Row
								justify="space-between"
								style={{ width: '-webkit-fill-available' }}
							>
								<Col span={10} style={styles.listContainer}>
									<List.Item.Meta
										title={item.name}
										description={TimeHelper.convertDurationToStringDetail(
											item.duration
										)}
									/>
								</Col>
								<Col
									span={14}
									style={{
										alignSelf: 'center',
										display: 'flex',
										justifyContent: 'flex-end',
									}}
								>
									<Text style={styles.price}>
										{CurrencyHelper.formatPrice(item.price)}
									</Text>
								</Col>
							</Row>
							<Modal
								visible={showServiceDeleteModal}
								title={I18n.t(_t(translations.delete))}
								handleOk={() => onDeleteService(item.id)}
								handleCancel={() => setShowServiceDeleteModal(false)}
								isLoading={loading}
							>
								{I18n.t(_t(translations.servicesDetail.deleteConfirmContent))}
							</Modal>
						</List.Item>
					);
				}}
			/>
		</Col>
	);
};

const ListCategories = (props: ICategoriesProps) => {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const categories = useAppSelector(
		(state) => state.CategoryReducer.categories
	);
	const history = useHistory();
	const dispatch = useDispatch();
	const [listCategories, setListCategories] = useState(categories);

	const handleDragEnd = async (event: DragEndEvent) => {
		const { active, over } = event;
		if (over) {
			if (active.id !== over.id) {
				const oldIndex = listCategories
					.map((x) => x.id)
					.indexOf(active.id as string);
				const newIndex = listCategories
					.map((x) => x.id)
					.indexOf(over.id as string);

				const newCategories = arrayMove(listCategories, oldIndex, newIndex);
				const newCategoriesWithIndex = newCategories.map((x, i) => ({
					...x,
					indexNumber: i,
				}));
				setListCategories(newCategoriesWithIndex);
				const response = (await CategoryApiService.updateServicesIndexes(
					newCategoriesWithIndex.map((x) => {
						return {
							id: x.id,
							indexNumber: x.indexNumber,
						};
					})
				)) as IApiResponse<IItemCategory[]>;
				if (response.data && !response.failed) {
					dispatch(CategoryActions.updateCategories.request(response.data));
				}
			}
		}
	};
	const onEditCategory = (category: IItemCategory) => {
		history.push(`/services/category/edit/${category.id}`);
	};
	useEffect(() => {
		setListCategories(categories);
	}, [categories]);
	const onDeleteCategory = async (category: IItemCategory) => {
		setLoading(true);
		try {
			const result = (await CategoryApiService.deleteCategory(
				category?.id!
			)) as IApiResponse<string>;
			setShowDeleteModal(false);
			if (result.succeeded) {
				const newCategoryList = listCategories.filter(
					(element) => element.id !== result.data!
				);
				setLoading(false);
				dispatch(CategoryActions.getAllCategory.success(newCategoryList));
				props.onSelect && props.onSelect(newCategoryList[0]);
			} else {
				setLoading(false);
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.error(error);
		}
	};
	return (
		<Col span={8}>
			<SortableList<IItemCategory>
				data={listCategories}
				onDragEnd={handleDragEnd}
				renderItem={(item, index) => {
					return (
						<List.Item
							actions={[
								<Button
									size="small"
									icon={<EditOutlined />}
									onClick={() => onEditCategory(item)}
								/>,
								<Button
									size="small"
									icon={<DeleteOutlined />}
									onClick={() => setShowDeleteModal(true)}
								/>,
							]}
							onClick={() => {
								props.onSelect(item);
								dispatch(CategoryActions.selectCategory.request(item));
							}}
							style={{
								cursor: 'pointer',
								padding: 8,
								borderBottom: '1px solid #f0f0f0',
							}}
							className={
								props.currentCategory && item.id === props.currentCategory.id
									? `list-primary-background`
									: 'list-white-background'
							}
						>
							{item.name}
						</List.Item>
					);
				}}
			/>
			<Modal
				visible={showDeleteModal}
				title={I18n.t(_t(translations.delete))}
				handleOk={() => onDeleteCategory(props.currentCategory!)}
				handleCancel={() => setShowDeleteModal(false)}
				isLoading={loading}
			>
				{I18n.t(_t(translations.categoryDetail.deleteWaringContent))}
			</Modal>
		</Col>
	);
};

export const Services = () => {
	const [loading, setLoading] = useState(false);
	const categories = useAppSelector(
		(state) => state.CategoryReducer.categories
	);
	const [searchTerm, setSearchTerm] = useState<string>();
	const selectedCategory = useAppSelector(
		(state) => state.CategoryReducer.selectedCategory
	);

	return (
		<Spin spinning={loading}>
			<Col span={24} style={styles.container}>
				<Space direction="vertical" style={styles.spaceContainer} size="small">
					<SearchBar
						searchTerm={searchTerm}
						onSearch={(text) => setSearchTerm(text)}
					/>
					<Row gutter={[16, 16]}>
						<ListCategories
							currentCategory={selectedCategory || categories[0]}
							onSelect={(category) => {}}
						/>
						<ListServices currentCategory={selectedCategory || categories[0]} />
					</Row>
				</Space>
			</Col>
		</Spin>
	);
};
const styles = {
	container: {
		alignSelf: 'center',
		width: '100%',
		paddingBottom: 50,
		height: '100vh',
	},
	listContainer: { alignSelf: 'center' },
	divider: { margin: '16px 0' },
	dividerNoMargin: { margin: 0 },
	spaceContainer: { display: 'block', width: '100%' },
	spaceTimeAndPrice: { minWidth: '100%', justifyContent: 'space-between' },
	textDelete: { color: 'red' },
	listItem: { alignItems: 'normal' },
	time: { color: '#8c8c8c' },
	price: { fontWeight: 700 },
	expandIcon: { marginTop: -2 },
	linkContainer: { display: 'contents' },
	titleStyle: { fontWeight: 'bold', marginBottom: 0 },
	maxWidth: { minWidth: '100%' },
};
