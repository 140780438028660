import { Col, ColProps, Divider, Row, Skeleton, Typography } from 'antd';
import { CurrencyHelper } from 'helpers';
import I18n from 'i18n-js';
import { IOwnerFinancialSummaryReport } from 'models';
import React, { useMemo } from 'react';
import { translations, _t } from 'utils';
import styles from './styles';
import moment from 'moment';
import { Column } from '@ant-design/charts';
import GlobalStyles from 'GlobalStyles';
import { useAppSelector } from 'helpers/hookHelpers';
import { RootState } from 'redux/configuration/rootReducer';

interface IReportProps {
	isLoading?: boolean;
	report?: IOwnerFinancialSummaryReport;
}
const Report = ({ isLoading, report }: IReportProps) => {
	const currentBranch = useAppSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);
	console.log(
		report?.revenue || 0,
		report?.supplyFee || 0,
		report?.giftCardSold || 0,
		report?.cardCharge || 0,
		report?.tax || 0
	);
	const renderReportOne = () => {
		return (
			<>
				{/* Income Section */}
				<Description
					title={I18n.t(
						_t(translations.ownerFinancialSummaryReport.serviceSold)
					)}
					value={CurrencyHelper.formatPrice(report?.serviceSold)}
				/>
				<Description
					title={I18n.t(
						_t(translations.ownerFinancialSummaryReport.supplyCharge)
					)}
					value={CurrencyHelper.formatPrice(report?.supplyFee)}
				/>
				<Description
					title={I18n.t(
						_t(translations.ownerFinancialSummaryReport.productSold)
					)}
					value={CurrencyHelper.formatPrice(report?.productSold)}
				/>
				{!currentBranch?.discountBeforeTax && (
					<>
						<Divider dashed type="horizontal" style={styles.divider} />
						<Description
							title={I18n.t(
								_t(translations.ownerFinancialSummaryReport.taxableRevenue)
							)}
							value={CurrencyHelper.formatPrice(report?.taxableRevenue)}
						/>
						{renderFieldValueLayout(
							undefined,
							<Typography.Text>
								{I18n.t(
									_t(translations.ownerFinancialSummaryReport.totalSaleTax)
								)}
							</Typography.Text>,
							<Typography.Text>
								{CurrencyHelper.formatPrice(report?.tax)}
							</Typography.Text>,
							{ span1: 4, span3: 9 }
						)}
					</>
				)}
				<Description
					title={`${I18n.t(
						_t(translations.ownerFinancialSummaryReport.discount)
					)} (
						${
							currentBranch?.discountBeforeTax
								? I18n.t(_t(translations.ownerFinancialSummaryReport.beforeTax))
								: I18n.t(_t(translations.ownerFinancialSummaryReport.afterTax))
						}
						)`}
					value={CurrencyHelper.formatPrice(report?.discount)}
				/>
				<Description
					title={`${I18n.t(
						_t(translations.ownerFinancialSummaryReport.promotion)
					)} (
						${
							currentBranch?.discountBeforeTax
								? I18n.t(_t(translations.ownerFinancialSummaryReport.beforeTax))
								: I18n.t(_t(translations.ownerFinancialSummaryReport.afterTax))
						}
						)`}
					value={CurrencyHelper.formatPrice(report?.promotionDiscount)}
				/>
				{currentBranch?.discountBeforeTax && (
					<>
						<Divider dashed type="horizontal" style={styles.divider} />
						<Description
							title={I18n.t(
								_t(translations.ownerFinancialSummaryReport.taxableRevenue)
							)}
							value={CurrencyHelper.formatPrice(report?.taxableRevenue)}
						/>
						{renderFieldValueLayout(
							undefined,
							<Typography.Text>
								{I18n.t(
									_t(translations.ownerFinancialSummaryReport.totalSaleTax)
								)}
							</Typography.Text>,
							<Typography.Text>
								{CurrencyHelper.formatPrice(report?.tax)}
							</Typography.Text>,
							{ span1: 4, span3: 9 }
						)}
					</>
				)}
				<Description
					title={I18n.t(
						_t(translations.ownerFinancialSummaryReport.totalCardCharge)
					)}
					value={CurrencyHelper.formatPrice(report?.cardCharge)}
				/>
				<Description
					title={I18n.t(
						_t(translations.ownerFinancialSummaryReport.giftCardSold)
					)}
					value={CurrencyHelper.formatPrice(report?.giftCardSold)}
				/>
				<Description
					title={I18n.t(
						_t(translations.ownerFinancialSummaryReport.serviceRefund)
					)}
					value={CurrencyHelper.formatPrice(report?.serviceRefund)}
				/>
				<Description
					title={I18n.t(
						_t(translations.ownerFinancialSummaryReport.productRefund)
					)}
					value={CurrencyHelper.formatPrice(report?.productRefund)}
				/>
				<Divider dashed type="horizontal" style={styles.divider} />
				<Description
					title={I18n.t(_t(translations.ownerFinancialSummaryReport.gross))}
					value={CurrencyHelper.formatPrice(report?.revenue)}
					primary
				/>

				{report?.expensesBreakdown.map((element, index) => (
					<Description
						title={EXPENSE_BREAKDOWN[index]}
						value={CurrencyHelper.formatPrice(element?.amount)}
						primary
					/>
				))}

				<Divider dashed type="horizontal" style={styles.divider} />
				<Description
					title={I18n.t(_t(translations.ownerFinancialSummaryReport.profit))}
					value={CurrencyHelper.formatPrice(report?.profit)}
					primary
				/>
				{/* {renderFieldAndValueTopLevel(
					I18n.t(_t(translations.ownerFinancialSummaryReport.income))
				)} */}
				{/* {renderFieldAndValue(
					I18n.t(_t(translations.ownerFinancialSummaryReport.gross)),
					report?.income || 0,
					undefined,
					true
				)} */}

				{/* Income Breakdown */}

				{/* {report?.incomeBreakdown.map((element, index) => {
					if (index === INCOME_BREAKDOWN_TITLE.length) {
						return renderFieldAndValue(
							INCOME_BREAKDOWN_TITLE[index],
							element.amount,
							index,
							false
						);
					}
					return renderFieldAndValue(
						INCOME_BREAKDOWN_TITLE[index],
						element.amount,
						index
					);
				})} */}

				{/* <Divider dashed type="horizontal" style={styles.divider} /> */}

				{/* {renderFieldAndValue(
					I18n.t(_t(translations.ownerFinancialSummaryReport.totalSaleTax)),
					report?.tax || 0
				)}
				{renderFieldAndValue(
					I18n.t(_t(translations.ownerFinancialSummaryReport.revenue)),
					report?.revenue || 0
				)} */}

				{/* <Divider dashed type="horizontal" style={styles.divider} /> */}

				{/* {renderLayout(
					undefined,
					<Typography.Title level={4}>
						{I18n.t(_t(translations.ownerFinancialSummaryReport.expenses))}
					</Typography.Title>
				)}

				{report?.expensesBreakdown.map((element, index) =>
					renderLayout(
						undefined,
						EXPENSE_BREAKDOWN[index],
						CurrencyHelper.formatPrice(element.amount)
					)
				)} */}

				{/* <Divider dashed type="horizontal" style={styles.divider} /> */}

				{/* {renderFieldAndValue(
					I18n.t(_t(translations.ownerFinancialSummaryReport.profit)),
					report?.profit || 0,
					undefined,
					true
				)} */}

				{/* Other Section */}
			</>
		);
	};

	const renderReportTwo = () => {
		return (
			<>
				<Description
					title={I18n.t(_t(translations.ownerFinancialSummaryReport.giftCard))}
					value={I18n.t(_t(translations.ownerFinancialSummaryReport.quantity))}
					primary
				/>
				{renderFieldValueLayout(
					<Typography.Text>
						{I18n.t(_t(translations.ownerFinancialSummaryReport.giftCardSold))}
					</Typography.Text>,
					<Typography.Text>
						{CurrencyHelper.formatPrice(report?.giftCardSold)}
					</Typography.Text>,
					<Typography.Text>{report?.giftCardSoldQuantity}</Typography.Text>,
					{ span1: 18, span2: 3, span3: 3 }
				)}
				{renderFieldValueLayout(
					<Typography.Text>
						{I18n.t(
							_t(translations.ownerFinancialSummaryReport.promotionGiftCard)
						)}
					</Typography.Text>,
					<Typography.Text>{CurrencyHelper.formatPrice(report?.giftCardPromote)}</Typography.Text>,
					undefined,
					{ span1: 18, span2: 3, span3: 3 }
				)}
				{renderFieldValueLayout(
					<Typography.Text>
						{I18n.t(_t(translations.ownerFinancialSummaryReport.giftCardUsed))}
					</Typography.Text>,
					<Typography.Text>
						{CurrencyHelper.formatPrice(report?.giftCardUsed)}
					</Typography.Text>,
					<Typography.Text>{report?.giftCardUsedQuantity}</Typography.Text>,
					{ span1: 18, span2: 3, span3: 3 }
				)}
				<Divider dashed type="horizontal" style={styles.divider} />
				{renderFieldValueLayout(
					<Typography.Title level={5}>
						{I18n.t(_t(translations.ownerFinancialSummaryReport.totalBalance))}
					</Typography.Title>,
					<Typography.Title level={5}>
						{CurrencyHelper.formatPrice(report?.giftCardBalance)}
					</Typography.Title>,
					undefined,
					{ span1: 18, span2: 3, span3: 3 }
				)}
				<Divider dashed type="horizontal" style={styles.divider} />
				{renderFieldValueLayout(
					<Typography.Title level={5}>
						{I18n.t(_t(translations.ownerFinancialSummaryReport.totalTip))}
					</Typography.Title>,
					<Typography.Title level={5}>
						{CurrencyHelper.formatPrice(report?.tip)}
					</Typography.Title>,
					undefined,
					{ span1: 18, span2: 3, span3: 3 }
				)}
				<Divider dashed type="horizontal" style={styles.divider} />
				{renderFieldValueLayout(
					<Typography.Title level={5}>
						{I18n.t(_t(translations.ownerFinancialSummaryReport.totalDiscount))}
					</Typography.Title>,
					<Typography.Title level={5}>
						{CurrencyHelper.formatPrice(report?.discount)}
					</Typography.Title>,
					undefined,
					{ span1: 18, span2: 3, span3: 3 }
				)}

				{/* {renderFieldAndValueTopLevel(
					I18n.t(_t(translations.ownerFinancialSummaryReport.giftCard))
				)}
				{renderFieldAndValue(
					I18n.t(_t(translations.ownerFinancialSummaryReport.giftCardSold)),
					report?.giftCardSold!,
					undefined
				)}
				{renderFieldAndValue(
					I18n.t(
						_t(translations.ownerFinancialSummaryReport.giftCardSoldQuantity)
					),
					report?.giftCardSoldQuantity!,
					undefined
				)}
				{renderFieldAndValue(
					I18n.t(_t(translations.ownerFinancialSummaryReport.giftCardUsed)),
					report?.giftCardUsed!,
					undefined
				)}
				{renderFieldAndValue(
					I18n.t(
						_t(translations.ownerFinancialSummaryReport.giftCardUsedQuantity)
					),
					report?.giftCardUsedQuantity!,
					undefined
				)}
				{renderFieldAndValueTopLevel(
					I18n.t(_t(translations.ownerFinancialSummaryReport.productSold)),
					report?.productSold
				)}
				{renderFieldAndValueTopLevel(
					I18n.t(_t(translations.ownerFinancialSummaryReport.totalDiscount)),
					report?.discount
				)}
				{renderFieldAndValueTopLevel(
					I18n.t(_t(translations.ownerFinancialSummaryReport.totalRefund)),
					report?.refund
				)}
				{renderFieldAndValueTopLevel(
					I18n.t(_t(translations.ownerFinancialSummaryReport.totalTip)),
					report?.tip
				)}
				{renderFieldAndValueTopLevel(
					I18n.t(_t(translations.ownerFinancialSummaryReport.totalCardCharge)),
					report?.cardCharge
				)}
				{renderFieldAndValueTopLevel(
					I18n.t(_t(translations.ownerFinancialSummaryReport.totalSupplyFee)),
					report?.supplyFee
				)} */}
			</>
		);
	};

	const renderReportThree = () => {
		return (
			<>
				<Description
					title={I18n.t(_t(translations.ownerFinancialSummaryReport.gross))}
					value={CurrencyHelper.formatPrice(report?.revenue)}
					primary
				/>
				{report?.incomeBreakdown.map((element, index) => {
					if (index === INCOME_BREAKDOWN_TITLE.length) {
						return (
							<Description
								title={INCOME_BREAKDOWN_TITLE[index]}
								value={CurrencyHelper.formatPrice(element.amount)}
							/>
						);
					}
					return (
						<Description
							title={INCOME_BREAKDOWN_TITLE[index]}
							value={CurrencyHelper.formatPrice(element.amount)}
							primary
						/>
					);
				})}
				{/* <Description
					title={I18n.t(_t(translations.ownerFinancialSummaryReport.cash))}
					value={CurrencyHelper.formatPrice(report?)}
				/>
				<Description
					title={I18n.t(_t(translations.ownerFinancialSummaryReport.card))}
					value={CurrencyHelper.formatPrice(200)}
				/>
				<Description
					title={I18n.t(_t(translations.ownerFinancialSummaryReport.giftCard))}
					value={CurrencyHelper.formatPrice(200)}
				/>
				<Description
					title={I18n.t(_t(translations.ownerFinancialSummaryReport.payByApp))}
					value={CurrencyHelper.formatPrice(200)}
				/>
				<Description
					title={I18n.t(_t(translations.ownerFinancialSummaryReport.other))}
					value={CurrencyHelper.formatPrice(200)}
				/> */}
			</>
		);
	};

	// const renderLayout = (
	// 	col1?: React.ReactNode,
	// 	col2?: React.ReactNode,
	// 	col3?: React.ReactNode,
	// 	index?: number
	// ) => {
	// 	return (
	// 		<Row key={index}>
	// 			<Col span={8}>{col1}</Col>
	// 			<Col span={8}>{col2}</Col>
	// 			<Col span={8}>{col3}</Col>
	// 		</Row>
	// 	);
	// };

	// const renderFieldAndValue = (
	// 	fieldName: string,
	// 	value: number,
	// 	index?: number,
	// 	bold?: boolean
	// ) => {
	// 	return renderLayout(
	// 		undefined,
	// 		<Typography.Text
	// 			style={bold ? styles.fieldValueBold : styles.reportValue}
	// 		>
	// 			{fieldName}
	// 		</Typography.Text>,
	// 		<Typography.Text
	// 			style={bold ? styles.fieldValueBold : styles.reportValue}
	// 		>
	// 			{CurrencyHelper.formatPrice(value)}
	// 		</Typography.Text>,
	// 		index
	// 	);
	// };

	// const renderFieldAndValueTopLevel = (
	// 	fieldName: string,
	// 	value?: number,
	// 	index?: number
	// ) => {
	// 	return renderLayout(
	// 		<Typography.Title level={5}>{fieldName}</Typography.Title>,
	// 		undefined,
	// 		<Typography.Title level={5}>
	// 			{value || value === 0 ? CurrencyHelper.formatPrice(value) : ''}
	// 		</Typography.Title>,
	// 		index
	// 	);
	// };

	const renderLoading = () => {
		return (
			<>
				<Skeleton paragraph={{ rows: 13, width: '100%' }} />
			</>
		);
	};

	const renderFieldValueLayout = (
		col1?: React.ReactNode,
		col2?: React.ReactNode,
		col3?: React.ReactNode,
		spanOptions?: {
			span1?: number;
			span2?: number;
			span3?: number;
			col1Props?: ColProps;
			col2Props?: ColProps;
			col3Props?: ColProps;
		}
	) => {
		return (
			<Row justify="space-between">
				<Col span={spanOptions?.span1 || 2} {...spanOptions?.col1Props}>
					{col1}
				</Col>
				<Col span={spanOptions?.span2 || 11} {...spanOptions?.col2Props}>
					{col2}
				</Col>
				<Col
					span={spanOptions?.span3 || 11}
					style={{ textAlign: 'right' }}
					{...spanOptions?.col3Props}
				>
					{col3}
				</Col>
			</Row>
		);
	};

	const Description = (props: {
		title: string;
		value: string;
		primary?: boolean;
		sub?: boolean;
	}) => {
		const { title, value, primary, sub } = props;
		return renderFieldValueLayout(
			!sub ? (
				primary ? (
					<Typography.Title level={5}>{title}</Typography.Title>
				) : (
					<Typography.Text>{title}</Typography.Text>
				)
			) : undefined,
			!sub ? undefined : primary ? (
				<Typography.Title level={5}>{title}</Typography.Title>
			) : (
				<Typography.Text>{title}</Typography.Text>
			),
			primary ? (
				<Typography.Title level={5}>{value}</Typography.Title>
			) : (
				<Typography.Text>{value}</Typography.Text>
			),
			{
				span1: sub ? undefined : 11,
				span2: sub ? undefined : 2,
			}
		);
	};

	const mockChartData = useMemo(() => {
		console.log(report?.incomeBreakdown);
		return [
			{
				type: I18n.t(_t(translations.ownerFinancialSummaryReport.cash)),
				value: report?.incomeBreakdown[0].amount,
			},
			{
				type: I18n.t(_t(translations.ownerFinancialSummaryReport.giftCard)),
				value: report?.incomeBreakdown[1].amount,
			},
			{
				type: I18n.t(_t(translations.ownerFinancialSummaryReport.card)),
				value: report?.incomeBreakdown[2].amount,
			},
			{
				type: I18n.t(_t(translations.ownerFinancialSummaryReport.check)),
				value: report?.incomeBreakdown[3].amount,
			},

			{
				type: I18n.t(_t(translations.ownerFinancialSummaryReport.payByApp)),
				value: report?.incomeBreakdown[4].amount,
			},
			{
				type: I18n.t(_t(translations.ownerFinancialSummaryReport.wallet)),
				value: report?.incomeBreakdown[5].amount,
			},
			{
				type: I18n.t(_t(translations.ownerFinancialSummaryReport.other)),
				value: report?.incomeBreakdown[6].amount,
			},
		];
	}, [report]);

	return (
		<>
			<Row style={{ width: '100%' }}>
				<Col>
					<Typography.Title style={styles.reportTitle}>
						{I18n.t(_t(translations.ownerFinancialSummaryReport.report))}
					</Typography.Title>
					<Typography.Text className="report-date">
						{getDateString(moment(report?.fromDate), moment(report?.toDate))}
					</Typography.Text>
				</Col>
			</Row>
			<Row style={{ width: '100%' }}>
				<Col lg={11} sm={24} className="report-container box-shadow">
					{/* <Card> */}
					<Row className="max-width report">
						<Col span={24}>
							{isLoading || !report ? renderLoading() : renderReportOne()}
						</Col>
					</Row>
					{/* </Card> */}
				</Col>
				<Col lg={11} sm={24} className="report-container box-shadow">
					{/* <Card> */}
					<Row className="max-width report">
						<Col span={24}>
							{isLoading || !report ? renderLoading() : renderReportTwo()}
						</Col>
					</Row>
					{/* </Card> */}
				</Col>
			</Row>
			<Row style={{ width: '100%' }}>
				<Col lg={11} sm={24} className="report-container box-shadow">
					{/* <Card> */}
					<Row className="max-width report">
						<Col span={24}>
							{isLoading || !report ? renderLoading() : renderReportThree()}
						</Col>
					</Row>
					{/* </Card> */}
				</Col>
			</Row>
			<Row style={{ width: '100%' }}>
				<Col span={24} className="report-container box-shadow">
					<Column
						loading={isLoading || !report}
						style={GlobalStyles.dashboardView}
						data={mockChartData}
						{...{
							// isGroup: true,
							xField: 'type',
							yField: 'value',
							seriesField: 'type',
							// colorField: "status", // or seriesField in some cases
							color: ({ type }: any) => {
								switch (type) {
									case I18n.t(
										_t(translations.ownerFinancialSummaryReport.cash)
									): {
										return '#3347ff';
									}
									case I18n.t(
										_t(translations.ownerFinancialSummaryReport.card)
									): {
										return '#d60000';
									}
									case I18n.t(
										_t(translations.ownerFinancialSummaryReport.giftCard)
									): {
										return '#4dff00';
									}
									case I18n.t(
										_t(translations.ownerFinancialSummaryReport.payByApp)
									): {
										return '#ff00fb';
									}
									case I18n.t(
										_t(translations.ownerFinancialSummaryReport.wallet)
									): {
										return '#7b00ff';
									}
									case I18n.t(
										_t(translations.ownerFinancialSummaryReport.other)
									): {
										return '#ffaa00';
									}
									default:
										return '#000000';
								}
							},
						}}
					/>
				</Col>
			</Row>
		</>
	);
};

const getDateString = (fromDate: moment.Moment, toDate: moment.Moment) => {
	if (fromDate.isSame(toDate)) {
		return toDate.format('MMM DD, YYYY');
	} else {
		return (
			fromDate.format('MMM DD, YYYY') + ' - ' + toDate.format('MMM DD, YYYY')
		);
	}
};
const INCOME_BREAKDOWN_TITLE = [
	I18n.t(_t(translations.ownerFinancialSummaryReport.cash)),
	I18n.t(_t(translations.ownerFinancialSummaryReport.giftCard)),
	I18n.t(_t(translations.ownerFinancialSummaryReport.card)),
	I18n.t(_t(translations.ownerFinancialSummaryReport.check)),
	I18n.t(_t(translations.ownerFinancialSummaryReport.payByApp)),
	I18n.t(_t(translations.ownerFinancialSummaryReport.wallet)),
	I18n.t(_t(translations.ownerFinancialSummaryReport.other)),
];

const EXPENSE_BREAKDOWN = [
	I18n.t(_t(translations.ownerFinancialSummaryReport.payrollExpenses)),
	I18n.t(_t(translations.ownerFinancialSummaryReport.otherExpenses)),
];

export default Report;
