import { Button, Col, Input, Modal, Row, Typography } from 'antd';
import I18n from 'i18n-js';
import React, { useState } from 'react';
import { _t, translations } from 'utils';
import { Device } from '@twilio/voice-sdk';
import Twilio from 'twilio';
import { useAppSelector } from 'helpers/hookHelpers';
import { useDispatch } from 'react-redux';
import { AppConfigActions } from 'redux/actions';
import TwilioServiceInstance from 'services/TwilioServices';

interface ICallSupportModalProps {
	visible: boolean;
	onCancel: () => void;
	centered?: boolean;
	isConfirming?: boolean;
}
const AccessToken = Twilio.jwt.AccessToken;
const VoiceGrant = AccessToken.VoiceGrant;

export const CallSupportModal = (props: ICallSupportModalProps) => {
	const [name, setName] = useState('');
	const [loading, setLoading] = useState(false);
	const calling = useAppSelector((state) => state.AppConfigReducer.calling);
	const shop = useAppSelector((state) => state.ShopReducer.shop);
	const branch = useAppSelector((state) => state.BranchReducer.currentBranch);
	const onEndCall = () => {
		TwilioServiceInstance.endCall();
		props.onCancel();
	};

	const onCall = () => {
		TwilioServiceInstance.makeCall(
			`${name}/${shop?.name}/${branch?.name}`.replaceAll(' ', '_')
		);
		// props.onCancel();
	};
	return (
		<Modal
			title={
				<Typography.Title style={{ textAlign: 'center', margin: 0 }} level={4}>
					{I18n.t(_t(translations.helpCenter.callSupport))}
				</Typography.Title>
			}
			centered
			visible={props.visible}
			onCancel={() => {
				props.onCancel();
			}}
			footer={[
				<Button
					disabled={!calling}
					onClick={() => onEndCall()}
					type="ghost"
					danger
					key="back"
				>
					End Call
				</Button>,
				<Button
					disabled={calling}
					type="primary"
					loading={loading}
					onClick={() => onCall()}
				>
					Call Now !
				</Button>,
				// <Button
				// 	key="link"
				// 	href="https://google.com"
				// 	loading={loading}
				// 	onClick={() => props.onCancel()}
				// >
				// 	Cancel
				// </Button>,
			]}
		>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Input
						title="Name"
						placeholder="Please enter your name"
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
				</Col>
				<Col span={24}>
					<Typography.Text>
						If you don't have microphone and speaker/headphone, please call this
						number: <strong>+1 202 733 6544</strong>
					</Typography.Text>
				</Col>
			</Row>
		</Modal>
	);
};
