import { createReducer } from "@reduxjs/toolkit";
import { prefixes } from "redux/actions";
import _ from "lodash";
type IReduxActionReducer = {
  [key in typeof prefixes[number]]?: string;
};

const initialState: IReduxActionReducer = {};

const ReduxActionReducer = createReducer(initialState, (builder) =>
  builder.addDefaultCase((state: IReduxActionReducer, action) => {
    const actionPrefix = _.split(action.type, "/")[0];
    if (!_.isEmpty(actionPrefix) && _.includes(prefixes, actionPrefix)) {
      state[actionPrefix] = action.type;
    }
  })
);

export default ReduxActionReducer;
