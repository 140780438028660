import { Component } from 'react';
import { Image, Typography, Layout, Row, Col, Result } from 'antd';
import { Button, AuthFooter } from 'components';
import styles from './styles';
import { I18n, _t, translations } from 'utils';
import _ from 'lodash';
import { Content } from 'antd/lib/layout/layout';

class PartnerConfirmSuccess extends Component {
	state = {};

	render() {
		return (
			<Layout style={styles.layout}>
				<Layout.Header style={styles.nailHeader}>
					<Row>
						<Col lg={1} xs={1} />
						<Col lg={22} xs={22}>
							<Row justify="center" style={styles.row}>
								<Image style={styles.nailHeaderLogo} src="./logo-square.png" />
							</Row>
							<Row>
								<Typography.Text style={styles.nailHeaderSlogan} strong>
									{I18n.t(_t(translations.login.slogan))}
								</Typography.Text>
							</Row>
						</Col>
						<Col lg={1} xs={1} />
					</Row>
				</Layout.Header>
				<Content>
					<Row>
						<Col lg={6} md={6} xs={1} />
						<Col lg={12} md={12} xs={22}>
							<Result
								status="success"
								title={I18n.t(_t(translations.partner.thanks))}
								extra={
									<Button
										type="primary"
										key="console"
										onClick={() =>
											(window.location.href = `http://partners.${process.env
												.REACT_APP_PRODUCTION_URL!}/login`)
										}
									>
										{I18n.t(_t(translations.partner.goToShop))}
									</Button>
								}
							/>
						</Col>
						<Col lg={6} md={6} xs={1} />
					</Row>
				</Content>
				<AuthFooter />
			</Layout>
		);
	}
}

export default PartnerConfirmSuccess;
