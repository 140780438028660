/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Column } from '@ant-design/charts';
import { Typography, Row } from 'antd';
import { I18n, _t, translations } from 'utils';
import { columnChartConfig } from 'utils/configurations';
import styles from './styles';
import GlobalStyles from 'GlobalStyles';
import { ChartRangeType } from 'utils/Consts';
import { IGetRequest } from 'models/RequestModels';
import moment from 'moment';
import RangeSelect from './RangeSelect';
import { AdminDashBoardApiService } from 'services';
import {
	ChartDataType,
	IApiResponse,
	IErrorResponse,
	IUpComingBookingsChartDataSource,
} from 'models';
import { AlertHelper } from 'helpers';
import _ from 'lodash';
import { Spin } from 'components';

const { Text, Title } = Typography;

interface IUpcomingBookingChartProps {}

const UpcomingBookingChart = (props: IUpcomingBookingChartProps) => {
	const [mode, setMode] = useState<number>(ChartRangeType.WEEK);
	const [isHover, setIsHover] = useState<boolean>(false);
	const [upComingBookingsDataSource, setUpComingBookingsDataSource] =
		useState<IUpComingBookingsChartDataSource>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onSelectChangeHandle = (value: number) => {
		setMode(value);
	};

	useEffect(() => {
		const fromDate =
			mode === ChartRangeType.MONTH
				? moment().startOf('month').toDate()
				: moment().startOf('week').toDate();
		const toDate =
			mode === ChartRangeType.MONTH
				? moment().endOf('month').toDate()
				: moment().endOf('week').toDate();
		getUpComingBookingsChartData({
			fromDate: fromDate,
			toDate: toDate,
		});
	}, [mode]);

	const renderSubTitle = (mode: number) => (
		<Text strong style={styles.chartSub}>
			{mode === ChartRangeType.MONTH
				? I18n.t(_t(translations.dashboard.lineChartAppointmentSubMonth))
				: I18n.t(_t(translations.dashboard.lineChartAppointmentSubWeek))}
		</Text>
	);

	const renderConfirmedApp = (isConfirmed: boolean) => {
		return (
			<Text style={{ ...styles.chartSub, ...styles.chartTotalAppointment }}>
				{isConfirmed
					? `${I18n.t(
							_t(translations.dashboard.columnChartConfirmedBooking)
					  )} ${upComingBookingsDataSource?.totalAppointmentConfirm}`
					: `${I18n.t(
							_t(translations.dashboard.columnChartCancelledBooking)
					  )} ${upComingBookingsDataSource?.totalAppointmentCancel}`}
			</Text>
		);
	};

	const renderTotalBook = () => {
		return (
			<Title level={1} style={styles.chartTotalSale}>{`${
				upComingBookingsDataSource?.totalAppointment
			} ${I18n.t(_t(translations.dashboard.numOfBooked))}`}</Title>
		);
	};

	const getUpComingBookingsChartData = async (request: IGetRequest) => {
		setIsLoading(true);
		const result = (await AdminDashBoardApiService.getUpComingBookingsChart(
			request
		)) as IApiResponse<IUpComingBookingsChartDataSource>;
		setIsLoading(false);
		if (result.succeeded) {
			setUpComingBookingsDataSource(result.data);
		} else {
			const error = result as IErrorResponse;
			AlertHelper.showError(error);
		}
	};

	const upComingBookingsChartData = useMemo(() => {
		const data: ChartDataType[] = [];
		let dates = 0;
		let endDate: moment.Moment;
		switch (mode) {
			case ChartRangeType.WEEK: {
				dates = 7;
				endDate = moment().endOf('week');
				break;
			}
			case ChartRangeType.MONTH: {
				endDate = moment().endOf('month');
				dates = endDate.daysInMonth();
				break;
			}
		}
		for (let i = dates - 1; i >= 0; --i) {
			const thisDay = endDate!.clone().startOf('day').subtract(i, 'day');
			const thisDayDataIndex = _.findIndex(
				upComingBookingsDataSource?.data,
				(element) => thisDay.date() === moment(element.date).date()
			);
			const sale: ChartDataType = {
				day: thisDay.format('ddd DD'),
				count: 0,
				value:
					thisDayDataIndex === -1
						? 0
						: upComingBookingsDataSource?.data[thisDayDataIndex]
								.appointmentConfirm!,
				category: I18n.t(_t(translations.dashboard.categoryConfirmed)),
			};

			const booking: ChartDataType = {
				day: thisDay.format('ddd DD'),
				count: 0,
				value:
					thisDayDataIndex === -1
						? 0
						: upComingBookingsDataSource?.data[thisDayDataIndex]
								.appointmentCancel!,
				category: I18n.t(_t(translations.dashboard.categoryCanceled)),
			};

			data.push(sale, booking);
		}
		return data;
	}, [upComingBookingsDataSource]);

	return (
		<div
			style={isHover ? styles.chartContainerHover : styles.chartContainer}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<div>
				<Title level={4} style={{ ...styles.chartTitle }}>
					{I18n.t(_t(translations.dashboard.columnChartAppointmentTitle))}
				</Title>
				<RangeSelect defaultMode={mode} onValueChange={onSelectChangeHandle} />
			</div>
			{isLoading ? (
				<Row style={GlobalStyles.dashboardView}>
					<Spin />
				</Row>
			) : (
				<>
					{renderSubTitle(mode)}
					{renderTotalBook()}
					{renderConfirmedApp(true)}
					{renderConfirmedApp(false)}

					<Column
						style={GlobalStyles.dashboardView}
						data={upComingBookingsChartData}
						{...columnChartConfig}
					/>
				</>
			)}
		</div>
	);
};

export default UpcomingBookingChart;
