import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Dispatch } from '@reduxjs/toolkit';
import { Button, Col, Divider, Layout, List, Row, Tag, Typography } from 'antd';
import { Avatar, Input, BookingItem, Header } from 'components';
import { IBooking, ICustomer } from 'models';
import { I18n, _t, translations } from 'utils';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { SearchActions } from 'redux/actions';
import { getOrderedBookings } from 'redux/selectors/search';
import { RootState } from 'redux/configuration/rootReducer';

interface ISearchProps
	extends ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps>,
		RouteComponentProps {}
class Search extends React.Component<ISearchProps> {
	searchWaiting?: ReturnType<typeof setTimeout>;

	componentDidMount() {
		this.props.search('');
	}
	render() {
		return (
			<Layout
				style={{ backgroundColor: 'white', height: 'calc(100vh - 64px)' }}
			>
				<Header
					detailPageProps={{
						title: I18n.t(_t(translations.search.title)),
						onClosePress: () => this.onGoBack(),
					}}
				/>
				<Col>
					<Layout.Content
						style={{
							marginTop: 60,
							paddingTop: 16,
							paddingRight: 100,
							paddingLeft: 100,
						}}
					>
						<Input
							autoFocus={true}
							placeholder="What are you looking for ?"
							style={{ fontSize: 32, height: 60 }}
							onChange={(e) => this.onSearchText(e.target.value)}
							size="large"
						/>
						<Typography.Text>
							{I18n.t(_t(translations.search.searchBy))}
						</Typography.Text>
						<Divider />
						<Row gutter={[24, 16]} style={{ width: '100%' }}>
							{this.renderUpcomingBookings()}
							{this.renderCustomers()}
						</Row>
					</Layout.Content>
				</Col>
			</Layout>
		);
	}
	renderUpcomingBookings() {
		return (
			<Col span={12}>
				<Typography.Title level={3}>
					{I18n.t(_t(translations.search.upComingBooking))}
				</Typography.Title>
				<List
					loading={
						this.props.currentAction === SearchActions.searchByText.requestName
					}
					itemLayout="vertical"
					dataSource={this.props.bookings}
					style={{ height: 'calc(100vh - 250px)', overflow: 'auto' }}
					renderItem={(booking: IBooking) => {
						return (
							<List.Item style={{ cursor: 'pointer' }} key={booking.id}>
								<BookingItem booking={booking} />
							</List.Item>
						);
					}}
				/>
			</Col>
		);
	}
	renderCustomers() {
		return (
			<Col span={12}>
				<Typography.Title level={3}>
					{I18n.t(_t(translations.search.customers))}
				</Typography.Title>
				<List
					loading={
						this.props.currentAction === SearchActions.searchByText.requestName
					}
					style={{ height: 'calc(100vh - 250px)', overflow: 'auto' }}
					itemLayout="vertical"
					dataSource={this.props.customers}
					renderItem={(customer) => this.renderCustomer(customer)}
					split
				/>
			</Col>
		);
	}
	renderCustomer(customer: ICustomer) {
		const customerName = `${customer.firstName} ${customer.lastName}`;
		return (
			<List.Item style={{ cursor: 'pointer' }} key={customer.id}>
				<List.Item.Meta
					avatar={<Avatar size="large" name={customerName} />}
					title={customerName}
					description={customer.phone}
				/>
			</List.Item>
		);
	}
	onGoBack() {
		this.props.history.goBack();
	}
	onSearchText(text: string) {
		// this.setState({ searchText: text });
		if (this.searchWaiting) {
			clearTimeout(this.searchWaiting);
		}
		this.searchWaiting = setTimeout(() => {
			this.searchWaiting = undefined;
			this.props.search(text);
		}, 1000);
	}
}

const mapStateToProps = (state: RootState) => ({
	bookings: getOrderedBookings(state),
	customers: state.SearchReducer.customers,
	currentAction: state.ReduxActionReducer['SEARCH'],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	search: (searchText: string) => {
		dispatch(SearchActions.searchByText.request(searchText));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
