import { kStyles } from "GlobalStyles";

const styles: kStyles = {
  nailHeader: {
    height: "250px",
    background: "none",
    textAlign: "center",
  },
  nailHeaderLogo: {
    margin: "0 auto",
    width: "200px",
  },
  nailHeaderSlogan: {
    lineHeight: 1.6,
    margin: "0 auto",
  },
  nailTitle: {
    fontWeight: "bold",
  },
  nailForm: {
    backgroundColor: "white",
    display: "block",
    padding: "24px",
    marginTop: "24px",
  },
  row: { margin: "0 auto" },
};

export default styles;
