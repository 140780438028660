import { kStyles } from 'GlobalStyles';

const styles: kStyles = {
	addNewBtnContainer: { justifyContent: 'flex-end', width: '100%' },
	expenseTableContainer: {
		height: '60vh',
	},
};

export default styles;
