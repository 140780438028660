import { Tabs } from 'antd';
import { ContentAdminContainer } from 'components';
import I18n from 'i18n-js';
import React from 'react';
import ListEmailBlast from './components/ListEmailBlast';
import ListTemplate from './components/ListTemplate';

function EmailBlast() {
	return (
		<ContentAdminContainer customPageName="Email Blast">
			<Tabs type="card">
				<Tabs.TabPane tab={'Email'} key="email">
					<ListEmailBlast />
				</Tabs.TabPane>
				<Tabs.TabPane tab={'Template'} key="template">
					<ListTemplate />
				</Tabs.TabPane>
			</Tabs>
		</ContentAdminContainer>
	);
}

export default EmailBlast;
