import { kStyles } from 'GlobalStyles';
import { Colors } from 'utils';

const styles: kStyles = {
	reportTitle: {
		color: Colors.RED_BERRY,
	},
	fieldValue: {
		fontSize: 16,
	},
	fieldValueBold: {
		fontWeight: 'bold',
		fontSize: 16,
	},
	divider: { borderColor: 'black' },
};

export default styles;
