import { SupportMessage } from 'models';
import { BaseApiService } from 'services/BaseApiService';

class SupportMessageApiService extends BaseApiService {
	public addNewSupportMessage = (addSupportMessageRequest: SupportMessage) =>
		this.post<SupportMessage, boolean>(
			`/SupportMessage/create-support-message`,
			addSupportMessageRequest
		);
}

export default new SupportMessageApiService();
