import './styles.css';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table } from 'antd';
import { ContentAdminContainer } from 'components';
import { AlertHelper } from 'helpers';
import { useAppSelector } from 'helpers/hookHelpers';
import _ from 'lodash';
import { IApiResponse, IErrorResponse } from 'models';
import { IAddress } from 'models/IAddress';
import { IBranch } from 'models/IBranch';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RootState } from 'redux/configuration/rootReducer';
import BranchApiService from 'services/BranchApiService';
import { I18n, _t, translations } from 'utils';
import { BranchDetailModal } from './components';
import { useDispatch } from 'react-redux';
import { BranchActions } from 'redux/actions';

const Branches = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [searchText, setSearchText] = useState<string>('');
	const [branchesData, setBranchesData] = useState<IBranch[]>();
	const [isBranchModalLoading, setIsBranchModalLoading] =
		useState<boolean>(false);
	const [isShowBranchModal, setIsShowBranchModal] = useState<boolean>(false);
	const [selectedBranch, setSelectedBranch] = useState<IBranch>();
	const shopId = useAppSelector(
		(state: RootState) => state.ShopReducer.shopId!
	);
	const currentBranch = useAppSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);
	const filteredBranches = useMemo(
		() => filterByValue(branchesData || [], searchText),
		[searchText, branchesData]
	);
	const dispatch = useDispatch();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onSearchTextChange = useCallback(
		_.throttle<(text: string) => void>((searchText: string) => {
			setSearchText(searchText);
		}, 200),
		[]
	);

	useEffect(() => {
		getAllBranches(shopId!);
	}, [shopId]);

	const renderNameCol = () => (
		<Table.Column<IBranch>
			title={I18n.t(_t(translations.branches.nameCol))}
			dataIndex={'name'}
			key={'Name'}
			width={'20%'}
		/>
	);

	const renderAddressCol = () => (
		<Table.Column<IBranch>
			title={I18n.t(_t(translations.branches.addressCol))}
			dataIndex={'address'}
			render={(value?: IAddress) =>
				`${value?.street || ''} ${value?.city || ''}, ${value?.state || ''}`
			}
			key={'Address'}
		/>
	);

	const renderPhoneCol = () => (
		<Table.Column<IBranch>
			title={I18n.t(_t(translations.branches.phoneCol))}
			dataIndex={'phone'}
			key={'Phone'}
			width={'15%'}
		/>
	);

	const renderEmailCol = () => (
		<Table.Column<IBranch>
			title={I18n.t(_t(translations.branches.emailCol))}
			dataIndex={'email'}
			key={'Email'}
			width={'20%'}
		/>
	);

	const onSubmit = (branch: Partial<IBranch>) => {
		if (_.isEmpty(selectedBranch)) {
			const { id, isPrimary, localTimeZone, ..._rest } = currentBranch!;
			addNewbranch({ ..._rest, ...branch });
		} else {
			updateBranch({ ...selectedBranch!, ...branch });
		}
		// console.log(branch)
	};

	const addNewbranch = async (branch: Partial<IBranch>) => {
		setIsBranchModalLoading(true);
		const result = (await BranchApiService.createBranch(
			branch
		)) as IApiResponse<IBranch>;
		if (result.succeeded) {
			setIsBranchModalLoading(false);
			setIsShowBranchModal(false);
			setSelectedBranch(undefined);
			AlertHelper.showSuccess(
				I18n.t(_t(translations.branches.addBranchSuccess))
			);
			getAllBranches(shopId);
			dispatch(BranchActions.getBranchesByShopId.request(shopId));
		} else {
			setIsBranchModalLoading(false);
			AlertHelper.showError(result as IErrorResponse);
		}
	};

	const updateBranch = async (branch: IBranch) => {
		setIsBranchModalLoading(true);
		const result = (await BranchApiService.updateBranch(
			branch
		)) as IApiResponse<IBranch>;
		if (result.succeeded) {
			setIsBranchModalLoading(false);
			setIsShowBranchModal(false);
			setSelectedBranch(undefined);
			AlertHelper.showSuccess(
				I18n.t(_t(translations.branches.updateBranchSuccess))
			);
			getAllBranches(shopId);
			dispatch(BranchActions.getBranchesByShopId.request(shopId));
		} else {
			setIsBranchModalLoading(false);
			AlertHelper.showError(result as IErrorResponse);
		}
	};

	// const deleteBranch = async (id: string) => {
	// 	setIsBranchModalLoading(true);
	// 	const result = (await BranchApiService.deleteBranch(
	// 		id
	// 	)) as IApiResponse<string>;
	// 	if (result.succeeded) {
	// 		setIsBranchModalLoading(false);
	// 		setIsShowBranchModal(false);
	// 		setSelectedBranch(undefined);
	// 		AlertHelper.showSuccess(
	// 			I18n.t(_t(translations.branches.deleteBranchSuccess))
	// 		);
	// 		getAllBranches(shopId);
	// 	} else {
	// 		setIsBranchModalLoading(false);
	// 		AlertHelper.showError(result as IErrorResponse);
	// 	}
	// };

	const getAllBranches = async (shopId: string) => {
		setIsLoading(true);
		const result = (await BranchApiService.getBranchesByShopId(
			shopId
		)) as IApiResponse<IBranch[]>;
		if (result.succeeded) {
			setBranchesData(result.data!.filter((element) => element.status !== 0));
		} else {
			AlertHelper.showError(result as IErrorResponse);
		}
		setIsLoading(false);
	};

	const renderHeader = () => (
		<Row justify="space-between">
			<Col span={12}>
				<Input
					allowClear
					prefix={<SearchOutlined />}
					placeholder="Search Branch"
					onChange={(e) => {
						onSearchTextChange(e.target.value);
					}}
				/>
			</Col>
			<Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					icon={<PlusOutlined />}
					type="primary"
					onClick={() => setIsShowBranchModal(true)}
				>
					{I18n.t(_t(translations.addNew))}
				</Button>
			</Col>
		</Row>
	);
	return (
		<ContentAdminContainer>
			{renderHeader()}
			<Table<IBranch>
				className="table_branches"
				loading={isLoading}
				dataSource={filteredBranches}
				pagination={{
					pageSize: 10,
					total: filteredBranches.length,
				}}
				onRow={(record) => ({
					onClick: () => {
						setSelectedBranch(record);
						setIsShowBranchModal(true);
					},
				})}
				tableLayout={'fixed'}
			>
				{renderNameCol()}
				{renderAddressCol()}
				{renderEmailCol()}
				{renderPhoneCol()}
			</Table>
			<BranchDetailModal
				visibility={isShowBranchModal}
				onCancel={() => {
					setIsShowBranchModal(false);
					setSelectedBranch(undefined);
				}}
				onSubmit={onSubmit}
				branch={selectedBranch}
				isLoading={isBranchModalLoading}
				onDelete={() => updateBranch({ ...selectedBranch!, status: 0 })}
			/>
		</ContentAdminContainer>
	);
};

const filterByValue = (array: any[], searchText: string = '') => {
	return array.filter((branch) =>
		Object.keys(branch).some((k) =>
			branch[k].toString().toLowerCase().includes(searchText.toLowerCase())
		)
	);
};

export default Branches;
