import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CustomerBookingActions } from '../actions';
import { IBooking, ICustomer, IEmployee } from 'models';
export interface ICustomerBookingReducer {
	booking?: Partial<IBooking>;
	customer?: Partial<ICustomer>;
	stylists?: Partial<IEmployee>[];
	note: string;
}

const initialState: ICustomerBookingReducer = {
	booking: {},
	customer: {},
	stylists: [],
	note: '',
};

// Get Branches by Shop ID

function updateCustomer(
	state: ICustomerBookingReducer,
	action: PayloadAction<Partial<ICustomer>>
) {
	state.customer = action.payload;

	// if (_.isEmpty(state.currentBranch) || _.isEmpty(state.currentBranch?.name)) {
	//   state.currentBranch = _.find(action.payload, (branch) => branch.isPrimary === true);
	// }
}
function updateBooking(
	state: ICustomerBookingReducer,
	action: PayloadAction<Partial<IBooking>>
) {
	state.booking = Object.assign(state.booking, action.payload);
}
function updateNote(
	state: ICustomerBookingReducer,
	action: PayloadAction<string>
) {
	state.note = action.payload;
}

function getAllStylists(
	state: ICustomerBookingReducer,
	action: PayloadAction<Partial<IEmployee>[]>
) {
	state.stylists = action.payload!;
}
const CustomerBookingReducer = createReducer(initialState, (builder) =>
	builder
		.addCase(CustomerBookingActions.updateBooking.request, updateBooking)
		.addCase(CustomerBookingActions.updateCustomer.request, updateCustomer)
		.addCase(CustomerBookingActions.updateNote.request, updateNote)

		.addCase(CustomerBookingActions.getAllStylists.request, getAllStylists)
);

export default CustomerBookingReducer;
