import React, { useEffect, useState } from 'react';
import { TimeHelper } from 'helpers';
import { Button, Col, Row, Typography } from 'antd';
import ReloadOutlined from '@ant-design/icons/lib/icons/ReloadOutlined';
import { electron } from 'ipc';
import I18n from 'i18n-js';
import { Colors, translations, _t } from 'utils';
import { PhoneOutlined } from '@ant-design/icons';

const Clock = () => {
	const [date, setDate] = useState(new Date());
	const phoneNum = '+1202 733 6544';
	useEffect(() => {
		setInterval(() => setDate(new Date()), 1000);
		return () => clearInterval();
	}, []);
	const renderSupportPhoneNum = () => {
		return (
			<Row
				style={{
					backgroundColor: Colors.WHITE,
					width: 'fit-content',
					padding: 8,
					borderRadius: 10,
					height: 46,
					borderWidth: 1,
					marginLeft: 8,
				}}
			>
				<Col
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-end',
					}}
				>
					<Typography.Title
						style={{
							fontSize: 10,
							color: Colors.PRIMARY_COLOR,
							margin: 1,
							lineHeight: 1,
							width: 'fit-content',
						}}
					>
						<PhoneOutlined
							style={{
								alignSelf: 'center',
								color: Colors.PRIMARY_COLOR,
								marginRight: 4,
								fontSize: 10,
								display: 'inline',
							}}
						/>
						{I18n.t(_t(translations.helpCenter.callSupport))}
					</Typography.Title>
					<Typography.Title
						style={{
							fontSize: 16,
							color: Colors.PRIMARY_COLOR,
							margin: 1,
							lineHeight: 1,
							width: 'fit-content',
						}}
					>
						{phoneNum}
					</Typography.Title>
				</Col>
			</Row>
		);
	};
	return (
		<Row style={{ flex: 1, alignItems: 'center' }}>
			<Col style={styles.body}>
				{renderContent(date, 'h:mm A', 22)}
				{renderContent(date, 'dddd, ll', 12)}
			</Col>
			{electron && (
				<Col>
					<Button
						onClick={() => window.location.reload()}
						icon={<ReloadOutlined />}
						size="middle"
						shape="circle"
						style={{ marginLeft: 10, marginRight: 10 }}
					/>
				</Col>
			)}
			{renderSupportPhoneNum()}
		</Row>
	);
};

const renderContent = (date: Date, format: string, fontSize: number) => {
	return (
		<p
			style={{
				fontSize,
				color: 'white',
				textAlign: 'right',
				margin: 1,
				lineHeight: 1,
			}}
		>
			{TimeHelper.toTimeZone(date).format(format)}
		</p>
	);
};

const styles = {
	body: {
		flex: 1,
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	} as React.CSSProperties,
	supportPhone: {
		flex: 7,
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		textAlign: 'right',
	} as React.CSSProperties,
};

export default Clock;
