import { Component } from 'react';
import { Layout, Row, Col, Typography, Space, Spin, Divider, Card } from 'antd';
import styles from '../styles';
import { IComponentProps, Avatar } from 'components';
import { I18n, _t, translations } from 'utils';
import { AlertHelper, FormHelper } from 'helpers';
import { Dispatch } from 'redux';
import { CustomerActions, ErrorActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import _ from 'lodash';
import { DetailPageLayout, Modal } from 'components';
import { ICustomer } from 'models';
import moment from 'moment';
import { convertToRawPhone } from 'helpers/StringHelper';
import { convertDateRequest } from 'helpers/bookingHelper';
const { Title, Text } = Typography;

interface IAddCustomerRouteParams {
	id: string;
}
interface ICustomerDetailProps
	extends IComponentProps<IAddCustomerRouteParams>,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}
interface ICustomerDetailState {
	showModal: boolean;
}
const ListGender = [
	{
		id: '0',
		type: 'Male',
	},
	{
		id: '1',
		type: 'Female',
	},
];
class CustomerDetailPage extends Component<
	ICustomerDetailProps,
	ICustomerDetailState
> {
	state = {
		showModal: false,
	};
	componentDidMount() {
		!this.isAdd() && this.props.getCustomersById(this.props.match.params.id);
	}

	componentWillUnmount() {
		this.props.resetCustomer();
	}

	componentDidUpdate(prevProp: ICustomerDetailProps) {
		const { currentAction, clearError, history } = this.props;
		if (
			currentAction === CustomerActions.addCustomer.successName ||
			currentAction === CustomerActions.editCustomer.successName ||
			currentAction === CustomerActions.deleteCustomer.successName
		) {
			this.goBack();
			return;
		}
		if (
			currentAction === CustomerActions.deleteCustomer.successName ||
			currentAction === CustomerActions.deleteCustomer.failedName
		) {
			this.setState({ showModal: false });
		}
	}
	render() {
		return this.isAdd() || !_.isEmpty(this.props.currentCustomers) ? (
			<DetailPageLayout
				title={I18n.t(
					_t(
						this.isAdd()
							? translations.customerDetail.addNew
							: translations.customerDetail.editCustomer
					)
				)}
				positiveButtonName={I18n.t(_t(translations.save))}
				negativeButtonName={I18n.t(_t(translations.delete))}
				showDelete={this.isAdd()}
				isLoading={this.props.isLoading}
				goBack={() => this.goBack()}
				onDeleteClick={() => this.onDelete()}
				onFormFinish={this.onFormFinish}
			>
				{this.renderFormBasicInfo()}
				{this.renderFormAddress()}
				<Modal
					visible={this.state.showModal}
					title={I18n.t(_t(translations.delete))}
					handleOk={() => {
						this.props.deleteCustomer(this.props.match.params.id);
						this.setState({ showModal: false });
					}}
					handleCancel={() => this.setState({ showModal: false })}
				>
					{I18n.t(_t(translations.staffDetail.deleteWaringContent))}
				</Modal>
			</DetailPageLayout>
		) : (
			<Layout style={styles.spinLayout}>
				<Spin
					tip="Loading..."
					spinning={this.props.isLoading}
					style={styles.loading}
				/>
			</Layout>
		);
	}
	renderFormBasicInfo() {
		const currentCustomers = this.props.currentCustomers;
		return (
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.customerDetail.basicInfo))}
					description={I18n.t(_t(translations.customerDetail.description))}
				/>
				<Divider style={styles.divider} />
				<Row gutter={[16, 16]} style={{ alignItems: 'center' }}>
					<Col
						sm={24}
						md={6}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Avatar
							size={120}
							name={
								this.isAdd()
									? 'A'
									: `${currentCustomers!.firstName} ${
											currentCustomers!.lastName
									  }`
							}
						></Avatar>
					</Col>
					<Col md={18} style={{ width: '100%', paddingInline: 0 }}>
						<Row gutter={[16, 16]} style={{ alignItems: 'center' }}>
							<Col sm={24} md={12} style={{ width: '100%' }}>
								{FormHelper.renderFormInput(
									'firstName',
									[
										{
											required: true,
											message: I18n.t(
												_t(translations.customerDetail.messageFirstName)
											),
										},
									],
									I18n.t(_t(translations.customerDetail.firstName)),
									this.isAdd() ? '' : currentCustomers!.firstName,
									{},
									{
										placeholder: I18n.t(_t(translations.placeHolder.firstName)),
										maxLength: 256,
									}
								)}
							</Col>
							<Col sm={24} md={12} style={{ width: '100%' }}>
								{FormHelper.renderFormInput(
									'lastName',
									[
										{
											required: false,
											message: I18n.t(
												_t(translations.customerDetail.messageLastName)
											),
										},
									],
									I18n.t(_t(translations.customerDetail.lastName)),
									this.isAdd() ? '' : currentCustomers!.lastName,
									{},
									{
										placeholder: I18n.t(_t(translations.placeHolder.lastName)),
										maxLength: 256,
									}
								)}
							</Col>
						</Row>
					</Col>
				</Row>
				<Divider style={styles.divider} />

				{/* Contact */}
				{this.renderFormContact()}
				{/* BirthDay */}
				{this.renderFormPersonalInfo()}
			</Card>
		);
	}
	renderFormAddress() {
		const currentCustomers = this.props.currentCustomers;
		return (
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.customerDetail.address))}
					description={I18n.t(
						_t(translations.customerDetail.addressDescription)
					)}
				/>
				<Divider style={styles.divider} />
				<Row gutter={[16, 16]} style={{ alignItems: 'center' }}>
					<Col sm={24} md={8} style={{ width: '100%' }}>
						{FormHelper.renderFormInput(
							'street',
							[
								{
									required: false,
								},
							],
							I18n.t(_t(translations.customerDetail.street)),
							this.isAdd() ? '' : currentCustomers!.address?.street,
							{},
							{
								placeholder: I18n.t(_t(translations.placeHolder.street)),
								maxLength: 256,
							}
						)}
					</Col>
					<Col sm={24} md={8} style={{ width: '100%' }}>
						{FormHelper.renderFormInput(
							'city',
							[
								{
									required: false,
								},
							],
							I18n.t(_t(translations.customerDetail.city)),
							this.isAdd() ? '' : currentCustomers!.address?.city,
							{},
							{
								placeholder: I18n.t(_t(translations.placeHolder.city)),
								maxLength: 256,
							}
						)}
					</Col>
					<Col sm={24} md={8} style={{ width: '100%' }}>
						{FormHelper.renderFormInput(
							'state',
							[
								{
									required: false,
								},
							],
							I18n.t(_t(translations.customerDetail.state)),
							this.isAdd() ? '' : currentCustomers!.address?.state,
							{},
							{
								placeholder: I18n.t(_t(translations.placeHolder.state)),
								maxLength: 256,
							}
						)}
					</Col>
					<Col sm={24} md={12} style={{ width: '100%' }}>
						{FormHelper.renderFormInput(
							'zipCode',
							[
								{
									required: false,
								},
							],
							I18n.t(_t(translations.customerDetail.zipCode)),
							this.isAdd() ? '' : currentCustomers!.address?.zipCode,
							{},
							{
								placeholder: I18n.t(_t(translations.placeHolder.zipCode)),
								maxLength: 256,
							}
						)}
					</Col>
					<Col sm={24} md={12} style={{ width: '100%' }}>
						{FormHelper.renderFormInput(
							'countryCode',
							[
								{
									required: false,
								},
							],
							I18n.t(_t(translations.customerDetail.countryCode)),
							this.isAdd() ? '' : currentCustomers!.address?.countryCode,
							{},
							{
								placeholder: I18n.t(_t(translations.placeHolder.countryCode)),
								maxLength: 256,
							}
						)}
					</Col>
				</Row>
			</Card>
		);
	}
	renderFormContact() {
		const currentCustomers = this.props.currentCustomers;
		return (
			<>
				<Space direction="vertical" style={styles.maxWidthSpace}>
					<Title level={4}>
						{I18n.t(_t(translations.customerDetail.contact))}
					</Title>
					<Text>
						{I18n.t(_t(translations.customerDetail.contactDescription))}
					</Text>
					<Row gutter={[16, 16]}>
						<Col sm={24} md={12} style={styles.noPaddingCol}>
							{FormHelper.renderFormInput(
								'email',
								[
									{
										required: false,
										message: I18n.t(
											_t(translations.customerDetail.emailMessage)
										),
										type: 'email',
									},
								],
								I18n.t(_t(translations.email)),
								this.isAdd() ? '' : currentCustomers!.email,
								{},
								{
									placeholder: I18n.t(_t(translations.placeHolder.email)),
									maxLength: 256,
								}
							)}
						</Col>
						<Col sm={24} md={12} style={styles.noPaddingCol}>
							{FormHelper.renderFormPhoneInput(
								'phone',
								[
									({ getFieldValue }) => ({
										validator(robj, value) {
											if (value) {
												if (convertToRawPhone(value)!.length === 10) {
													return Promise.resolve();
												}
												return Promise.reject(
													new Error(
														I18n.t(_t(translations.customerDetail.phoneMessage))
													)
												);
											}
											return Promise.reject(
												new Error(
													I18n.t(_t(translations.customerDetail.phoneMessage))
												)
											);
										},
									}),
								],
								I18n.t(_t(translations.phone)),
								this.isAdd() ? '' : currentCustomers!.phone,
								{},
								{
									placeholder: I18n.t(_t(translations.placeHolder.phone)),
								}
							)}
						</Col>
					</Row>
				</Space>
			</>
		);
	}
	renderFormPersonalInfo() {
		const currentCustomers = this.props.currentCustomers;
		return (
			<>
				<Space direction="vertical" style={styles.maxWidthSpace}>
					<Title level={4}>
						{I18n.t(_t(translations.customerDetail.personalInfo))}
					</Title>
					<Text>
						{I18n.t(_t(translations.customerDetail.personalInfoDescription))}
					</Text>
					<Row gutter={[16, 0]}>
						<Col sm={24} md={12} style={{ paddingInline: 0, width: '100%' }}>
							{FormHelper.renderFormDateTimePicker(
								'dayOfBirth',
								I18n.t(_t(translations.customerDetail.birthDate)),
								this.isAdd()
									? ''
									: !_.isEmpty(currentCustomers!.dateOfBirth)
									? moment(currentCustomers!.dateOfBirth)
									: '',
								[{ required: false }],
								{ width: '100%' },
								{}
							)}
						</Col>
						<Col sm={24} md={12} style={{ paddingInline: 0, width: '100%' }}>
							{FormHelper.renderFormSelect<any>(
								'gender',
								I18n.t(_t(translations.bookingCustomerInfo.gender)),
								ListGender,
								'type',
								'id',
								this.isAdd()
									? ListGender[0].id
									: currentCustomers
									? currentCustomers.gender!.toString()
									: ListGender[0].id
							)}
						</Col>
					</Row>
				</Space>
			</>
		);
	}
	isAdd(): boolean {
		return this.props.location.pathname.includes('add-new');
	}
	goBack() {
		const { state } = this.props.location;
		// this.props.removeData();
		if (state && state.fromService) {
			this.props.history.goBack();
		} else {
			this.props.history.push('/customer');
		}
	}
	onDelete() {
		this.setState({ showModal: true });
	}

	onFormFinish = (name: string, { values, forms }: any) => {
		const data: Partial<ICustomer> = {
			code: `${moment().unix()}`,
			firstName: values.firstName,
			lastName: values.lastName,
			additionalName: '',
			color: '',
			gender: parseInt(values.gender),
			userId: '',
			phone: convertToRawPhone(values.phone),
			phoneNumberConfirmed: !_.isEmpty(values.phone) ? true : false,
			email: values.email,
			emailConfirmed: false,
			address: {
				street: values.street,
				city: values.city,
				state: values.state,
				zipCode: values.zipCode,
				countryCode: values.countryCode,
			},
			status: 0,
			dateOfBirth: !values.dayOfBirth
				? undefined
				: convertDateRequest(values.dayOfBirth),
		};
		const dataEdit: Partial<ICustomer> = {
			id: this.props.currentCustomers?.id!,
			code: `${moment().unix()}`,
			...data,
		};
		this.isAdd()
			? this.props.addCustomer(data)
			: this.props.editCustomer(dataEdit);
	};
}
const mapStateToProps = (state: RootState) => ({
	currentAction: state.ReduxActionReducer['CUSTOMER'],
	isLoading: state.AppConfigReducer.showLoading,
	error: state.ErrorReducer.error?.errors,
	currentCustomers: state.CustomerReducer.selectedCustomer,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	getCustomersById: (id: string) => {
		dispatch(CustomerActions.getCustomerById.request(id));
	},
	addCustomer: (newCustomer: Partial<ICustomer>) => {
		dispatch(CustomerActions.addCustomer.request(newCustomer));
	},
	editCustomer: (customer: Partial<ICustomer>) => {
		dispatch(CustomerActions.editCustomer.request(customer));
	},
	clearError: () => {
		dispatch(ErrorActions.clearError.request());
	},
	resetCustomer: () => {
		dispatch(CustomerActions.removeCurrentCustomer.request());
		dispatch(ErrorActions.clearError.request());
	},
	deleteCustomer: (id: string) => {
		dispatch(CustomerActions.deleteCustomer.request(id));
	},
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetailPage);
