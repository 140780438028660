import { Col, ModalProps, Row, Space, Typography } from 'antd';
import { Avatar as AppAvatar, Spin } from 'components';
import GlobalStyles, { kStyles } from 'GlobalStyles';
import { AlertHelper } from 'helpers';
import ModalHelper from 'helpers/modalHelper';
import { IApiResponse, IEmployee, IErrorResponse } from 'models';
import React from 'react';
import EmployeeApiService from 'services/EmployeeApiService';
import { translations, _t, I18n } from 'utils';
import _ from 'lodash';

interface IEmployeePickerModalProps extends ModalProps {
	onCancel(): void;
	onSelectEmployee(employee?: IEmployee): void;
	employeeList?: IEmployee[];
	selectedEmployeeId?: string;
	isLoading?: boolean;
	isCheckout?: boolean;
	allOption?: boolean;
}

interface IEmployeePickerModalState {
	employeeList: IEmployee[];
	isLoading: boolean;
}

class EmployeePickerModal extends React.Component<
	IEmployeePickerModalProps,
	IEmployeePickerModalState
> {
	constructor(props: IEmployeePickerModalProps) {
		super(props);
		this.state = {
			employeeList: props.employeeList || [],
			isLoading: false,
		};
	}
	componentDidMount() {
		if (!this.props.employeeList) {
			this.getAllEmployees();
		}
	}
	componentDidUpdate(prevProps: IEmployeePickerModalProps) {
		if (
			prevProps.employeeList !== this.props.employeeList &&
			this.props.employeeList
		) {
			this.setState({ employeeList: this.props.employeeList });
		}
	}
	render() {
		return (
			<ModalHelper
				{...this.props}
				visible={true}
				title={I18n.t(_t(translations.mainBooking.selectStylist))}
				width={'65%'}
				onCancel={() => this.props.onCancel()}
				onOk={() => null}
				btnOkHidden={true}
			>
				{this.state.isLoading || this.props.isLoading ? (
					<Spin />
				) : (
					this.renderContent()
				)}
			</ModalHelper>
		);
	}

	renderContent = () => {
		return (
			<Row style={styles.contentContainer} gutter={[16, 16]}>
				{!this.props.isCheckout && (
					<Col
						key={'auto-pick-stylist'}
						onClick={() => this.props.onSelectEmployee(undefined)}
						style={styles.contentCol}
						className={
							_.isEmpty(this.props.selectedEmployeeId) ? 'selected-primary' : ''
						}
					>
						<Space direction="vertical" size="small" style={styles.space}>
							<Typography.Text
								style={{
									color: !_.isEmpty(this.props.selectedEmployeeId)
										? 'black'
										: 'white',
									fontWeight: 600,
									fontSize: 24,
								}}
							>
								{this.props.allOption
									? I18n.t(_t(translations.all))
									: I18n.t(_t(translations.chooseLater))}
							</Typography.Text>
						</Space>
					</Col>
				)}
				{this.state.employeeList?.map((employee) => {
					let name = `${employee?.firstName || ''} ${employee?.lastName || ''}`;
					if (employee.turn) {
						name += `(${employee.turn})`;
					}
					return (
						<Col
							key={employee.id}
							onClick={() => this.props.onSelectEmployee(employee)}
							style={styles.contentCol}
							className={
								this.props.selectedEmployeeId === employee.id
									? 'selected-primary'
									: ''
							}
						>
							<Space direction="vertical" size="small" style={styles.space}>
								<AppAvatar
									name={`${employee?.firstName} ${employee?.lastName}`}
									src={employee.imageUrl}
									size={150}
								/>
								<Typography.Text
									style={{
										color:
											this.props.selectedEmployeeId === employee.id
												? 'white'
												: 'black',
										fontWeight: 600,
										fontSize: 20,
									}}
								>
									{name}
								</Typography.Text>
							</Space>
						</Col>
					);
				})}
			</Row>
		);
	};

	getAllEmployees = async () => {
		this.setState({ isLoading: true });
		try {
			const result =
				(await EmployeeApiService.getAllEmployees()) as IApiResponse<
					IEmployee[]
				>;
			this.setState({ isLoading: false });
			if (result.succeeded) {
				this.setState({ employeeList: result.data! });
			} else {
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			this.setState({ isLoading: false });
			console.error(error);
		}
	};
}

const MARGIN_BOTTOM_ITEM = 10;

const styles: kStyles = {
	contentContainer: {
		height: window.innerHeight * 0.6,
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	contentCol: {
		...GlobalStyles.boxShadow,
		borderColor: 'black',
		borderWidth: 0.5,
		borderStyle: 'solid',
		cursor: 'pointer',
		borderRadius: 4,
		textAlign: 'center',
		height: window.innerHeight * 0.3 - MARGIN_BOTTOM_ITEM * 2,
		// height: 'fit-content',
		marginRight: '1%',
		marginBottom: MARGIN_BOTTOM_ITEM,
		width: '24%',
		display: 'flex',
		alignItems: 'center',
	},
	space: {
		padding: 8,
		flex: 1,
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		alignItems: 'center',
		justifyContent: 'center',
	},
};

export default EmployeePickerModal;
