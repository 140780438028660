import React, { useEffect, useState } from 'react';
import {
	DeleteOutlined,
	EditOutlined,
	PlusOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import { Row, Col, Space, Input, Button, Table, Typography } from 'antd';
import { I18n, _t, translations } from 'utils';
import styles from '../../EmailBlast/style';
import { useHistory } from 'react-router-dom';
import { ITextMessage } from 'models/ITextMessage';
import TextMessageApiService from 'services/TextMessageApiService';
import { IApiResponse, IErrorResponse } from 'models';
import { AlertHelper } from 'helpers';
import { Modal } from 'components';
import moment from 'moment';

const PAGE_SIZE = 10;
const ListTextMessage = () => {
	const history = useHistory();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [textMessages, setTextMessages] = useState<ITextMessage[] | undefined>(
		[]
	);
	const [showModal, setShowModal] = useState(false);
	const [textMessageId, setTextMessageId] = useState('');
	const [searchText, setSearchText] = useState('');
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: PAGE_SIZE,
		showTotal: (total: any) => `Total ${total} items`,
	});
	const fetchTextMessage = async (pageNumber: number) => {
		setLoading(true);
		const params = {
			pageNumber,
			pageSize: PAGE_SIZE,
			searchText: searchText,
		};
		try {
			const result = (await TextMessageApiService.getTextMessages(
				params
			)) as IApiResponse<ITextMessage[]>;
			if (result.succeeded) {
				const newPagination = {
					...pagination,
					total: result.totalRecords,
					current: pageNumber,
				};
				setPagination(newPagination);
				setTextMessages(result.data);
			}
			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};
	const onDeleteTextMessage = async () => {
		setLoading(true);
		try {
			const result = (await TextMessageApiService.deleteTextMessage(
				textMessageId
			)) as IApiResponse;
			if (result.succeeded) {
				fetchTextMessage(pagination.current!);
				setLoading(false);
				AlertHelper.showSuccess(
					I18n.t(_t(translations.textMessageDetail.messageDeleteSuccess))
				);
			} else {
				setLoading(false);
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {}
	};
	const onSearch = (e: any) => {
		let searchText = e.target.value.toLowerCase();
		setSearchText(searchText);
	};
	const onClickRow = (record: ITextMessage) => {
		return {
			onClick: () => {
				history.push(`text-message/edit/${record.id}`);
			},
		};
	};
	useEffect(() => {
		const ac = new AbortController();
		fetchTextMessage(1);
		return () => {
			ac.abort();
		};
	}, [searchText]);
	return (
		<>
			<Space direction="vertical" style={styles.container} size="large">
				<Row>
					<Col style={{ width: '100%' }}>
						{/* Header */}
						<Row
							gutter={[16, 16]}
							style={{ alignItems: 'center', paddingBottom: 20 }}
						>
							<Col md={12}>
								<Input
									allowClear
									prefix={<SearchOutlined />}
									placeholder={I18n.t(_t(translations.textMessage.search))}
									style={styles.maxWidth}
									onChange={(e) => onSearch(e)}
								/>
							</Col>
							<Col md={12}>
								<Space
									style={{ justifyContent: 'flex-end', width: '100%' }}
									size="small"
								>
									<Button
										icon={<PlusOutlined />}
										type="primary"
										onClick={(e) => {
											e.stopPropagation();
											history.push(`text-message/add-new`);
										}}
									>
										{I18n.t(_t(translations.addNew))}
									</Button>
								</Space>
							</Col>
						</Row>

						{/* ListMessage */}
						<Table
							style={{ paddingBottom: 24, cursor: 'pointer' }}
							scroll={{
								y: '50vh',
								scrollToFirstRowOnChange: true,
								x: 'max-content',
							}}
							size="large"
							dataSource={textMessages}
							columns={[
								{
									title: (
										<Typography.Title level={5}>
											{I18n.t(_t(translations.textMessage.title))}
										</Typography.Title>
									),
									render: (text) => {
										return (
											<Space>
												<Typography.Text>{text}</Typography.Text>
											</Space>
										);
									},
									dataIndex: 'title',
									width: '20%',
								},
								{
									title: (
										<Typography.Title level={5}>
											{I18n.t(_t(translations.textMessage.content))}
										</Typography.Title>
									),
									dataIndex: 'content',
									width: '35%',
								},
								{
									title: (
										<Typography.Title level={5}>
											{I18n.t(_t(translations.textMessage.customerReach))}
										</Typography.Title>
									),
									dataIndex: 'customerReach',
									width: '10%',
								},
								{
									title: (
										<Typography.Title level={5}>
											{I18n.t(_t(translations.status))}
										</Typography.Title>
									),
									dataIndex: 'status',
									width: '10%',
									render: (record) => {
										return record === 0
											? I18n.t(_t(translations.textMessageDetail.pending))
											: I18n.t(_t(translations.textMessageDetail.sent));
									},
								},
								{
									title: (
										<Typography.Title level={5}>
											{I18n.t(_t(translations.textMessage.sentTime))}
										</Typography.Title>
									),
									dataIndex: 'sentTime',
									width: '15%',
									render: (record) => {
										return record
											? moment(record).format('YYYY-MM-DD')
											: undefined;
									},
								},
								{
									dataIndex: 'id',
									render: (id) => {
										return (
											<Space>
												<Button
													icon={<EditOutlined />}
													onClick={(e) => {
														e.stopPropagation();
														history.push(`text-message/edit/${id}`);
													}}
												></Button>
												<Button
													icon={<DeleteOutlined />}
													onClick={() => {
														setTextMessageId(id);
														setShowModal(true);
													}}
												/>
											</Space>
										);
									},
									width: '10%',
								},
							]}
							loading={isLoading}
							pagination={pagination}
							onChange={(pagination) => {
								fetchTextMessage(pagination.current!);
							}}
							rowKey={(record: ITextMessage) => record.id}
						/>
					</Col>
				</Row>
			</Space>
			<Modal
				visible={showModal}
				title={I18n.t(_t(translations.delete))}
				handleOk={() => {
					onDeleteTextMessage();
					setShowModal(false);
				}}
				handleCancel={() => {
					setShowModal(false);
				}}
			>
				{I18n.t(_t(translations.textMessageDetail.deleteConfirmContent))}
			</Modal>
		</>
	);
};

export default ListTextMessage;
