import { CloseOutlined } from '@ant-design/icons';
import { Descriptions, Divider, Space, Typography } from 'antd';
import { formatBalance } from 'helpers/currencyHelper';
import { IPayrollSummaryDetail } from 'models';
import { Component, CSSProperties } from 'react';
import { I18n, _t, translations } from 'utils';

const { Title, Text } = Typography;

interface IPayrollPreviewProps {
	onClose?(): void;
	data?: IPayrollSummaryDetail;
	period?: string;
}
interface IPayrollPreviewStates {}

export class PayrollPreview extends Component<
	IPayrollPreviewProps,
	IPayrollPreviewStates
> {
	render() {
		return (
			<Space direction="vertical" style={{ margin: '8px 0px' }}>
				{this.renderTitle()}

				{this.renderInfo()}
				{this.renderDetail()}
				{this.renderTotal()}
				<Title level={5} style={styles.textCenter}>
					{I18n.t(_t(translations.thank))}
				</Title>
			</Space>
		);
	}

	renderTitle = () => {
		return (
			<>
				<Title level={3} style={styles.textCenter}>
					{I18n.t(_t(translations.payrollReport.payrollPreview))}
				</Title>
				<CloseOutlined style={styles.icClose} onClick={this.props.onClose} />
				<Divider />
			</>
		);
	};

	renderInfo = () => {
		const name = this.props.data?.employeeName;
		const phone = this.props.data?.phone ? `${this.props.data?.phone!}` : '';
		const email = this.props.data?.email;
		const period = this.props.period;
		return (
			<Space direction="vertical">
				{this.renderTextInfo(I18n.t(_t(translations.payrollReport.name)), name)}
				{this.renderTextInfo(`${I18n.t(_t(translations.phone))}: `, phone)}
				{this.renderTextInfo(`${I18n.t(_t(translations.email))}: `, email)}
				{this.renderTextInfo(
					`${I18n.t(_t(translations.payrollReport.period))}: `,
					period
				)}
			</Space>
		);
	};

	renderMoney(
		title: string,
		value: string,
		labelSize?: number,
		contentSize?: number,
		bold?: boolean
	) {
		return (
			<Descriptions
				style={{
					width: '100%',
					alignContent: 'baseline',
					alignItems: 'baseline',
				}}
				labelStyle={{
					fontSize: labelSize ? labelSize : 14,
					fontWeight: bold ? 'bold' : 'normal',
				}}
				contentStyle={{
					justifyContent: 'flex-end',
					alignContent: 'baseline',
					fontSize: contentSize,
					fontWeight: bold ? 'bold' : 'normal',
				}}
				column={1}
			>
				<Descriptions.Item style={{ paddingBottom: 1 }} label={title}>
					{value}
				</Descriptions.Item>
			</Descriptions>
		);
	}

	renderTextInfo = (title: string, value?: string) => {
		return (
			<Text>
				{title}
				{<Text style={{ fontWeight: 'bold' }}>{value}</Text>}
			</Text>
		);
	};

	renderDetail = () => {
		const salary = formatBalance(this.props.data?.salary);
		const income = formatBalance(this.props.data?.income);
		const serviceIncome = formatBalance(this.props.data?.serviceIncome);
		const productIncome = formatBalance(this.props.data?.productIncome);
		const giftCardIncome = formatBalance(this.props.data?.giftCardIncome);
		const commission = formatBalance(this.props.data?.commission);
		const serviceCommission = formatBalance(this.props.data?.serviceCommission);
		const giftCardCommission = formatBalance(
			this.props.data?.giftCardCommission
		);
		const productCommission = formatBalance(this.props.data?.productCommission);
		const tip = formatBalance(this.props.data?.tip);
		const cardCharge = `-${formatBalance(this.props.data?.cardCharge)}`;
		const tipCardCharge = `-${formatBalance(this.props.data?.tipCardCharge)}`;
		return (
			<>
				<Divider dashed />
				{this.renderMoney(
					I18n.t(_t(translations.payrollReport.salary)),
					salary,
					16,
					16,
					true
				)}
				<Divider dashed />
				{this.renderMoney(
					I18n.t(_t(translations.payrollReport.revenue)),
					income,
					16,
					16,
					true
				)}
				{this.renderMoney(
					I18n.t(_t(translations.payrollReport.serviceRevenue)),
					serviceIncome
				)}
				{this.renderMoney(
					I18n.t(_t(translations.payrollReport.productRevenue)),
					productIncome
				)}
				{this.renderMoney(
					I18n.t(_t(translations.payrollReport.giftCardRevenue)),
					giftCardIncome
				)}
				<Divider dashed />
				{this.renderMoney(
					I18n.t(_t(translations.payrollReport.commission)),
					commission,
					16,
					16,
					true
				)}
				{this.renderMoney(
					I18n.t(_t(translations.serviceCommission)),
					serviceCommission
				)}
				{this.renderMoney(
					I18n.t(_t(translations.productCommission)),
					productCommission
				)}
				{this.renderMoney(
					I18n.t(_t(translations.payrollReport.giftCardCommission)),
					giftCardCommission
				)}
				<Divider dashed />
				{this.renderMoney(
					I18n.t(_t(translations.payrollReport.tips)),
					tip,
					16,
					16,
					true
				)}
				{this.renderMoney(
					I18n.t(_t(translations.payrollReport.cardCharge)),
					cardCharge,
					16,
					16,
					true
				)}
				{this.renderMoney(
					I18n.t(_t(translations.payrollReport.tipsCardCharge)),
					tipCardCharge,
					16,
					16,
					true
				)}
				<Divider dashed />
			</>
		);
	};
	renderTotal = () => {
		const salaryPay = formatBalance(this.props.data?.salaryPay);
		const salaryPayByCash = formatBalance(this.props.data?.salaryPayByCash);
		const salaryPayByCheck = formatBalance(this.props.data?.salaryPayByCheck);
		return (
			<>
				{this.renderMoney(
					I18n.t(_t(translations.payrollReport.totalSalary)),
					salaryPay,
					16,
					16,
					true
				)}
				{this.renderMoney(
					I18n.t(_t(translations.payrollReport.cashIncome)),
					salaryPayByCash,
					16,
					16,
					true
				)}
				{this.renderMoney(
					I18n.t(_t(translations.payrollReport.checkIncome)),
					salaryPayByCheck,
					16,
					16,
					true
				)}
				<Divider />
			</>
		);
	};
}

const styles = {
	textCenter: { textAlign: 'center' } as CSSProperties,
	icClose: {
		position: 'absolute',
		top: 16,
		fontSize: 18,
		right: 16,
		cursor: 'pointer',
	} as CSSProperties,
};

export default PayrollPreview;
