import {
	Col,
	Divider,
	Input,
	Radio,
	Row,
	Typography,
	Image,
	Button,
} from 'antd';
import _ from 'lodash';
import React from 'react';
import { translations, _t, I18n } from 'utils';
import { AppType } from 'utils/Consts';
import CheckOutBaseModal from './CheckOutBaseModal';
import styles from './styles';
import * as images from '../images';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import { GenerateHelper } from 'helpers';

interface IPayByAppModalProps extends ReturnType<typeof mapStateToProps> {
	onOk(referenceNumber: string, appName: string): void;
	onCancel(): void;
}

interface IPayByAppModalState {
	referenceCode?: string;
	appSelected?: AppType;
}

class PayByAppModal extends React.Component<
	IPayByAppModalProps,
	IPayByAppModalState
> {
	constructor(props: IPayByAppModalProps) {
		super(props);
		this.state = {};
	}
	inputRef: any = React.createRef();
	PAY_APPS = [
		{
			key: 'cashApp',
			imgUrl: images.cashApp,
			title: 'Cash app',
			callBack: () => this.selectAppToPay(AppType.CASH_APP),
			appType: AppType.CASH_APP,
		},
		{
			key: 'venmo',
			imgUrl: images.venmo,
			title: 'Venmo',
			callBack: () => this.selectAppToPay(AppType.VENMO),
			appType: AppType.VENMO,
		},
		{
			key: 'zelle',
			imgUrl: images.zelle,
			title: 'Zelle',
			callBack: () => this.selectAppToPay(AppType.ZELLE),
			appType: AppType.ZELLE,
		},
	];
	render() {
		return (
			<CheckOutBaseModal
				title={I18n.t(_t(translations.checkout.payWithApp))}
				onOk={() => this.onOk()}
				onCancel={this.props.onCancel}
				disableOk={!this.state.referenceCode}
			>
				<Col>
					<Col style={{ textAlign: 'center' }}>
						<Radio.Group buttonStyle="outline" size="large">
							<Row gutter={[32, 16]}>
								{this.PAY_APPS.map((app) => (
									<Col>
										<Radio.Button
											key={app.key}
											value={app.key}
											style={{ textAlign: 'center', paddingTop: 4, width: 120 }}
											onClick={app.callBack}
										>
											<Image src={app.imgUrl} alt={app.title} preview={false} />
											<Typography.Title level={4}>{app.title}</Typography.Title>
										</Radio.Button>
									</Col>
								))}
							</Row>
						</Radio.Group>
					</Col>
					{this.props.paymentReferenceCodeRequiring &&
					(this.state.appSelected ||
						this.state.appSelected === AppType.CASH_APP) ? (
						<>
							<Divider />
							<Col>
								<Typography.Title level={4}>
									{I18n.t(_t(translations.checkout.last4Digits))}
								</Typography.Title>
								<Row gutter={8}>
									<Col md={20}>
										<Input
											ref={this.inputRef}
											style={styles.inputNumber}
											onPressEnter={this.onOk}
											autoFocus={true}
											value={this.state.referenceCode}
											onChange={(event) =>
												this.setState({ referenceCode: event.target.value })
											}
										/>
									</Col>
									<Col md={4}>
										<Button
											onClick={() => {
												this.inputRef.current?.focus();
												this.setState({ referenceCode: undefined });
											}}
											danger
										>
											{I18n.t(_t(translations.clear))}
										</Button>
									</Col>
								</Row>
							</Col>
						</>
					) : null}
				</Col>
			</CheckOutBaseModal>
		);
	}
	selectAppToPay = (appType: number) => {
		if (this.props.paymentReferenceCodeRequiring) {
			this.setState({ appSelected: appType });
		} else {
			this.setState({
				referenceCode: GenerateHelper.generateFourDigitsNumber().toString(),
				appSelected: appType,
			});
		}
	};

	onOk = () => {
		if (!this.state.referenceCode) {
			return;
		} else {
			const appName = _.find(
				this.PAY_APPS,
				(appSelected) => appSelected.appType === this.state.appSelected
			);
			this.props.onOk(this.state.referenceCode!, appName?.title!);
		}
	};
}

const mapStateToProps = (state: RootState) => ({
	paymentReferenceCodeRequiring:
		state.BranchReducer.currentBranch?.paymentReferenceCodeRequiring,
});

export default connect(mapStateToProps)(PayByAppModal);
