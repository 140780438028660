import { ReduxHelper } from 'helpers';
import { IBillingDetail } from 'models';

export const prefix = 'BILLING';

export const addRefundLocal = ReduxHelper.generateLocalAction<
	Partial<IBillingDetail>
>(`${prefix}/ADD_REFUND`);

export const removeRefundLocal = ReduxHelper.generateLocalAction<string>(
	`${prefix}/REMOVE_REFUND`
);

export const setRefundLocal = ReduxHelper.generateLocalAction<
	Partial<IBillingDetail>[]
>(`${prefix}/SET_REFUND_LOCAL`);
