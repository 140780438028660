import React, { Component } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { Layout, Row, Col } from "antd";
import { AuthHeader, AuthFooter } from "components";
import { AuthRoute } from "routers/routes";
const { Content } = Layout;

interface IRouteComponentProps extends RouteComponentProps {}

export default class AuthenticationRouter extends Component<IRouteComponentProps> {
  render() {
    return (
      <Layout style={styles.layout}>
        <AuthHeader />
        <Content>
          <Row>
            <Col lg={9} md={6} xs={1} />
            <Col lg={6} md={12} xs={22}>
              <Switch>
                {AuthRoute.map((route) => {
                  return <Route exact path={route.path} component={route.component} key={route.key} />;
                })}
              </Switch>
            </Col>
            <Col lg={9} md={6} xs={1} />
          </Row>
        </Content>
        <AuthFooter />
      </Layout>
    );
  }
}

const styles = {
  layout: {
    height: "100vh",
  },
};
