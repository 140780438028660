import { kStyles } from 'GlobalStyles';

const styles: kStyles = {
	altLogoLg: {
		width: 500,
		height: 500,
	},
	altLogoSm: {
		width: 700,
		height: 700,
	},
	altLogoXs: {
		width: 800,
		height: 800,
	},
	spinLayout: {
		height: '100vh',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	spin: {
		margin: '0 auto',
	},
};

export default styles;
