import GlobalStyles, { kStyles } from "GlobalStyles";

const styles: kStyles = {
  spinContainer: {
    height: "50vh",
    width: "100%",
    display: "flex",
    backgroundColor: 'white',
    flexDirection: "row",
    alignItems: "center",
  },
  link: {
    color: "#037aff",
  },
  branchTextInfo: {
    textAlign: "center",
    marginBottom: 0
  },
  cardContainer: {
    ...GlobalStyles.boxShadow,
    backgroundColor: 'white',
    width: "fit-content",
    borderRadius: 10,
    padding: 16
  },
  contentContainer: {
    paddingTop: 100,
    backgroundColor: '#F0F2F5',
    alignSelf: 'center'
  },
  spaceContentContainer: {
    justifyContent: "center",
    width: "100%",
    textAlign: "center",
    // paddingTop: 16
  },
  header: {
    width: "100%",
    padding: 0
  },
  titleHeader: {
    color: "white",
    marginTop: 8,
    textAlign: "center",
  },
  layout: {
    height: "100vh",
    backgroundColor: '#F0F2F5',
  },
  spin: { margin: "0 auto" },
};

export default styles;
