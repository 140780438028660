import React, { Component } from "react";
import { Tabs } from "antd";
import { I18n, _t, translations } from "utils";
import _ from "lodash";
import { ContentAdminContainer } from "components";
import ListGiftCard from "./ListGiftCard";
import ListBooking from "./ListBooking"


class Sales extends Component {

  render() {
    return (
      <ContentAdminContainer>
        <Tabs type="card">
          <Tabs.TabPane tab={I18n.t(_t(translations.booking))} key="booking">
            <ListBooking />
          </Tabs.TabPane>
          <Tabs.TabPane tab={I18n.t(_t(translations.giftCard))} key="giftCard">
            <ListGiftCard />
          </Tabs.TabPane>
        </Tabs>
      </ContentAdminContainer>
    );
  }
}

export default Sales;
