import React from 'react';
import {
	DeleteOutlined,
	EditOutlined,
	PlusOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import { Row, Col, Space, Input, Button, Table, Typography } from 'antd';
import { I18n, _t, translations } from 'utils';
import styles from '../style';
const ListEmailBlast = () => {
	return (
		<>
			<Space direction="vertical" style={styles.container} size="large">
				<Row>
					<Col style={{ width: '100%' }}>
						{/* Header */}
						<Row
							gutter={[16, 16]}
							style={{ alignItems: 'center', paddingBottom: 20 }}
						>
							<Col md={12}>
								<Input
									allowClear
									prefix={<SearchOutlined />}
									placeholder={I18n.t(_t(translations.emailBlast.search))}
									style={styles.maxWidth}
								/>
							</Col>
							<Col md={12}>
								<Space
									style={{ justifyContent: 'flex-end', width: '100%' }}
									size="small"
								>
									<Button icon={<PlusOutlined />} type="primary">
										{I18n.t(_t(translations.addNew))}
									</Button>
								</Space>
							</Col>
						</Row>

						{/* ListEmail */}
						<Table
							style={{ paddingBottom: 24, cursor: 'pointer' }}
							scroll={{
								y: '50vh',
								scrollToFirstRowOnChange: true,
								x: 'max-content',
							}}
							size="large"
							dataSource={[
								{
									email: 'special discount 1',
									customer: '25',
									status: 'Pending',
									sentTime: '',
								},
								{
									email: 'special discount 2',
									customer: '15',
									status: 'Sent',
									sentTime: '10/25/2021 10:55 AM',
								},
							]}
							columns={[
								{
									title: (
										<Typography.Title level={5}>
											{I18n.t(_t(translations.emailBlast.emailSubject))}
										</Typography.Title>
									),
									render: (text) => {
										return (
											<Space>
												<Typography.Text>{text}</Typography.Text>
											</Space>
										);
									},
									dataIndex: 'email',
									width: '40%',
								},
								{
									title: (
										<Typography.Title level={5}>
											{I18n.t(_t(translations.emailBlast.customerReach))}
										</Typography.Title>
									),
									dataIndex: 'customer',
									width: '15%',
								},
								{
									title: (
										<Typography.Title level={5}>
											{I18n.t(_t(translations.status))}
										</Typography.Title>
									),
									dataIndex: 'status',
									width: '10%',
								},
								{
									title: (
										<Typography.Title level={5}>
											{I18n.t(_t(translations.emailBlast.sentTime))}
										</Typography.Title>
									),
									dataIndex: 'sentTime',
									width: '25%',
								},
								{
									render: (id) => {
										return (
											<Space>
												<Button icon={<EditOutlined />}></Button>
												<Button icon={<DeleteOutlined />} />
											</Space>
										);
									},
									width: '10%',
								},
							]}
						/>
					</Col>
				</Row>
			</Space>
		</>
	);
};

export default ListEmailBlast;
