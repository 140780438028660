import { Component } from 'react';
import {
	Layout,
	Row,
	Col,
	Typography,
	Space,
	Spin,
	Divider,
	Input,
	Card,
	Transfer,
	Tabs,
	DatePicker,
	Form,
	Modal,
	Button,
	FormInstance,
} from 'antd';
import styles from './styles';
import { IComponentProps, ImageUpload } from 'components';
import { I18n, _t, translations } from 'utils';
import { AlertHelper, FormHelper, StringHelper } from 'helpers';
import { Dispatch } from 'redux';
import { EmployeeActions, ErrorActions, ServiceActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import _ from 'lodash';
import { DetailPageLayout, Modal as AppModal } from 'components';
import {
	IAddEmployeeRequest,
	IEditEmployeeRequest,
} from 'models/RequestModels';
import moment, { Moment } from 'moment';
import { IApiResponse, IEmployeeType, IErrorResponse, IService } from 'models';
import { DefaultPassword, WageType, ChargeRate, WeekDays } from 'utils/Consts';
import CommissionRate from '../components/CommissionRate';
import RadioWage from '../components/RadioWage';
import { history } from 'routers';
import { convertDateRequest } from 'helpers/bookingHelper';
import { staffColors } from 'helpers/colorHelper';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { AdminPasswordModal } from './components';
import UserApiService from 'services/UserApiService';
import { getTimeFieldData } from 'pages/BranchSetting/component/WorkingTimeOfDay';
import WorkingTimeOfDay from 'pages/BranchSetting/component/WorkingTimeOfDay';
import { WorkingHour } from 'models/IBranch';
import { getDayOfWeek } from 'helpers/StringHelper';
import momentTz from 'moment-timezone';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

interface IAddStaffRouteParams {
	id: string;
}
interface IStaffDetailProps
	extends IComponentProps<IAddStaffRouteParams>,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}
interface IStaffDetailState {
	showDeleteModal: boolean;
	showSuccessModal: boolean;
	firstName: string;
	lastName: string;
	userName: string;
	initialService: any;
	targetKeys: any;
	imageFiles?: (File | undefined)[];
	tabKey: any;
	servicesCommission?: number;
	giftCardCommission?: number;
	cashCheckCommission?: number;
	cardCharge?: number;
	tipCardCharge?: number;
	wageType: WageType | undefined;
	showWageInput: boolean;
	wage?: number;
	salaryEffectiveStartDate?: Moment | Date;
	employeeType?: string;
	employeeTypeCodes?: string[];
	employeeTypeId?: string;
	employeeTypeIds?: string[];
	showServiceTransfer: boolean;
	isHaveInitialImageUrl?: boolean;
	isShowAdminPasswordModal: boolean;
	isResetingPassword: boolean;
	isShowNewPasswordModal: boolean;
	newPassword?: string;
	wholeWeekChecked?: boolean;
}

class StaffDetailPage extends Component<IStaffDetailProps, IStaffDetailState> {
	state = {
		showDeleteModal: false,
		showSuccessModal: false,
		firstName: '',
		lastName: '',
		userName: '',
		initialService: [],
		targetKeys: [],
		imageFiles: undefined,
		tabKey: 1,
		servicesCommission: this.props.currentBranch?.defaultEmployeeCommission,
		giftCardCommission: this.props.currentBranch?.defaultEmployeeCommission,
		cashCheckCommission: ChargeRate.CASH_CHECK_COMMISSION,
		cardCharge: ChargeRate.CARD_CHARGE,
		tipCardCharge: ChargeRate.TIP_CARD_CHARGE,
		wageType: undefined,
		showWageInput: false,
		wage: undefined,
		salaryEffectiveStartDate: undefined,
		employeeType: '',
		employeeTypeCodes: [],
		employeeTypeId: '',
		employeeTypeIds: [],
		showServiceTransfer: false,
		isHaveInitialImageUrl: !_.isEmpty(this.props.currentEmployee?.imageUrl),
		isShowAdminPasswordModal: false,
		isResetingPassword: false,
		isShowNewPasswordModal: false,
		newPassword: '',
		wholeWeekChecked: undefined,
	};

	formRef?: FormInstance = undefined;

	componentDidMount() {
		!this.isAdd() && this.props.getEmployeeById(this.props.match.params.id);
		if (_.isEmpty(this.props.services)) {
			this.props.getAllServices();
		} else {
			this.getService();
		}

		if (this.isAdd()) {
			const employeeType =
				this.props.employeeTypes && this.props.employeeTypes.length > 0
					? this.props.employeeTypes[0]
					: { id: '', code: '' };

			this.setState({
				employeeTypeId: employeeType.id,
				employeeType: employeeType.code,
			});
		}
	}

	componentDidUpdate(prevProps: IStaffDetailProps) {
		if (prevProps.currentAction !== this.props.currentAction) {
			const { currentAction, serviceAction } = this.props;
			const currentEmployee = this.props.currentEmployee!;
			// const employeeType = _.find(
			// 	this.props.employeeTypes,
			// 	(x) => x.id === currentEmployee?.employeeTypeId!
			// );
			if (serviceAction === ServiceActions.getAllServices.successName) {
				this.getService();
			}
			if (currentAction === EmployeeActions.getEmployeeById.successName) {
				this.setState({
					tabKey: currentEmployee.incomeType,
					servicesCommission: currentEmployee.servicesCommissionPercent,
					giftCardCommission: currentEmployee.giftCardCommissionPercent,
					cashCheckCommission: currentEmployee.checkPercent,
					cardCharge: currentEmployee.cardChargePercent,
					tipCardCharge: currentEmployee.tipCardChargePercent,
					wageType: currentEmployee.salaryType,
					wage: currentEmployee.salary,
					salaryEffectiveStartDate: currentEmployee.salaryEffectiveStartDate,
					employeeTypeId: currentEmployee.employeeTypeId,
					employeeTypeIds: currentEmployee.employeeTypes?.map((x) => x.id),
					employeeTypeCodes: currentEmployee.employeeTypes?.map(
						(x: IEmployeeType) => x.code!
					),
					// employeeType: currentEmployee.employeeTypeCode,
					// showServiceTransfer: this.checkRole({
					// 	roleName: employeeType?.roleName,
					// }),
					wholeWeekChecked: false,
				});
			}
			if (
				currentAction === EmployeeActions.editEmployee.successName ||
				currentAction === EmployeeActions.deleteEmployee.successName
			) {
				this.goBack();
				return;
			}
			if (currentAction === EmployeeActions.addEmployee.successName) {
				this.setState({ showSuccessModal: true });
				return;
			}
			if (
				currentAction === EmployeeActions.deleteEmployee.successName ||
				currentAction === EmployeeActions.deleteEmployee.failedName
			) {
				this.setState({ showDeleteModal: false });
			}
		}
	}
	componentWillUnmount() {
		this.props.resetData();
	}

	render() {
		return (
			<>
				{this.isAdd() || !_.isEmpty(this.props.currentEmployee)
					? this.renderContainer()
					: this.renderLoading()}
			</>
		);
	}

	renderLoading() {
		return (
			<Layout style={styles.spinLayout}>
				<Spin
					style={styles.spin}
					size="default"
					tip="Loading..."
					spinning={this.props.isLoading}
				/>
			</Layout>
		);
	}

	renderContainer() {
		const { id } = this.props.match.params;
		return (
			<DetailPageLayout
				title={I18n.t(
					_t(
						this.isAdd()
							? translations.staffDetail.addStaff
							: translations.staffDetail.editStaff
					)
				)}
				positiveButtonName={I18n.t(_t(translations.save))}
				negativeButtonName={I18n.t(_t(translations.delete))}
				showDelete={this.isAdd()}
				isLoading={this.props.isLoading}
				goBack={() => this.goBack()}
				onDeleteClick={() => this.onDelete()}
				onFormFinish={(name, info) => this.onFormFinish(name, info)}
				formRef={(form) => {
					this.formRef = form;
				}}
			>
				{this.renderFormBasicInfo()}
				{this.renderFormColour()}
				{this.renderFormPayRoll()}
				{this.renderServiceTransfer()}
				{this.renderTimeInput()}
				<AppModal
					visible={this.state.showDeleteModal}
					title={I18n.t(_t(translations.delete))}
					handleOk={() => this.props.deleteEmployee(id)}
					handleCancel={() => this.setState({ showDeleteModal: false })}
				>
					{I18n.t(_t(translations.staffDetail.deleteWaringContent))}
				</AppModal>
				<Modal
					visible={this.state.showSuccessModal}
					title={
						<Title level={4} style={{ textAlign: 'center' }}>
							<CheckCircleTwoTone twoToneColor="#52c41a" />
							{'  '}
							{I18n.t(_t(translations.text.success))}
						</Title>
					}
					closable={false}
					footer={[
						<Button onClick={() => this.goBack()} type="primary">
							{I18n.t(_t(translations.ok))}
						</Button>,
					]}
				>
					<Text>{I18n.t(_t(translations.staffDetail.addNewStaffSuccess))}</Text>
					<br />
					<Text strong>
						{I18n.t(_t(translations.staffDetail.staffUser), {
							userName: this.state.userName,
						})}
					</Text>
					<br />
					<Text strong>
						{I18n.t(_t(translations.staffDetail.defaultPassword))}
					</Text>
					<br />
					<Text>{I18n.t(_t(translations.staffDetail.mustChangePass))}</Text>
				</Modal>
				<AdminPasswordModal
					visible={this.state.isShowAdminPasswordModal}
					onCancel={() => this.setState({ isShowAdminPasswordModal: false })}
					onOk={this.onResetPassword}
					isLoading={this.state.isResetingPassword}
				/>
				{!this.isAdd() && (
					<Modal
						visible={this.state.isShowNewPasswordModal}
						onCancel={() => this.setState({ isShowNewPasswordModal: false })}
						onOk={() => this.setState({ isShowNewPasswordModal: false })}
						cancelButtonProps={{ hidden: true }}
					>
						<div
							dangerouslySetInnerHTML={{ __html: this.state.newPassword || '' }}
						/>
					</Modal>
				)}
			</DetailPageLayout>
		);
	}

	renderFormBasicInfo() {
		const currentEmployee = this.props.currentEmployee;
		return (
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.staffDetail.basicInfo))}
					description={I18n.t(_t(translations.placeHolder.description))}
				/>
				<Divider style={styles.divider} />
				<Row gutter={[16, 16]} style={{ alignItems: 'center' }}>
					<Col sm={24} md={6}>
						<ImageUpload
							defaultImage={this.props.currentEmployee?.imageUrl}
							onGetImages={(fileList) =>
								this.setState({
									imageFiles: fileList,
									isHaveInitialImageUrl: false,
								})
							}
						/>
					</Col>
					<Col md={18} style={{ width: '100%', paddingInline: 0 }}>
						<Row gutter={[16, 16]} style={{ alignItems: 'center' }}>
							<Col sm={24} md={12} style={{ width: '100%' }}>
								{FormHelper.renderFormInput(
									'firstName',
									[
										{
											required: true,
											message: I18n.t(
												_t(translations.staffDetail.messageFirstName)
											),
										},
									],
									I18n.t(_t(translations.staffDetail.firstName)),
									this.isAdd() ? '' : currentEmployee!.firstName,
									{},
									{
										placeholder: I18n.t(_t(translations.placeHolder.firstName)),
										onChange: (event) =>
											this.setState({ firstName: event.target.value }),
										maxLength: 256,
									}
								)}
							</Col>
							<Col sm={24} md={12} style={{ width: '100%' }}>
								{FormHelper.renderFormInput(
									'lastName',
									[
										{
											required: false,
											message: I18n.t(
												_t(translations.staffDetail.messageLastName)
											),
										},
									],
									I18n.t(_t(translations.staffDetail.lastName)),
									this.isAdd() ? '' : currentEmployee!.lastName,
									{},
									{
										placeholder: I18n.t(_t(translations.placeHolder.lastName)),
										onChange: (event) =>
											this.setState({ lastName: event.target.value }),
										maxLength: 256,
									}
								)}
							</Col>
							<Col sm={24} md={12} style={{ width: '100%' }}>
								<Form.Item
									initialValue={
										this.isAdd()
											? ''
											: !_.isEmpty(currentEmployee!.dateOfBirth)
											? moment(currentEmployee!.dateOfBirth)
											: ''
									}
									label={
										<Typography.Title style={{ marginLeft: 8 }} level={5}>
											{I18n.t(_t(translations.staffDetail.birthDate))}
										</Typography.Title>
									}
									name="birthDate"
								>
									<DatePicker
										style={{ width: '100%', marginLeft: 8 }}
										defaultPickerValue={
											this.isAdd()
												? undefined
												: !_.isEmpty(currentEmployee!.dateOfBirth)
												? moment(currentEmployee!.dateOfBirth)
												: undefined
										}
										format="MM/DD/YYYY"
									/>
								</Form.Item>
							</Col>
							{!this.isAdd() && (
								<Col sm={24} md={12} style={{ width: '100%', height: '100%' }}>
									<Form.Item
										label={
											<Typography.Title style={{ marginLeft: 8 }} level={5} />
										}
									>
										<Button
											type="primary"
											style={{ height: '100%', marginLeft: 8 }}
											onClick={() =>
												this.setState({ isShowAdminPasswordModal: true })
											}
										>
											{I18n.t(_t(translations.staffDetail.resetPassword))}
										</Button>
									</Form.Item>
								</Col>
							)}
						</Row>
					</Col>
				</Row>
				<Divider style={styles.divider} />
				{/* Contact */}
				{this.renderFormContact()}
				<Divider style={styles.divider} />
				{/* Employment */}
				{this.renderFormEmployment()}
			</Card>
		);
	}

	renderFormContact() {
		const currentEmployee = this.props.currentEmployee;
		return (
			<>
				<Space direction="vertical" style={styles.maxWidthSpace}>
					<Title level={5}>
						{I18n.t(_t(translations.staffDetail.contact))}
					</Title>
					<Text>{I18n.t(_t(translations.staffDetail.contactDescription))}</Text>
				</Space>
				<Row gutter={[16, 16]}>
					<Col sm={24} md={12} style={styles.noPaddingCol}>
						{FormHelper.renderFormInput(
							'email',
							[
								{
									required: false,
									message: I18n.t(_t(translations.staffDetail.messageEmail)),
									type: 'email',
								},
							],
							I18n.t(_t(translations.email)),
							this.isAdd() ? '' : currentEmployee!.email,
							{},
							{
								placeholder: I18n.t(_t(translations.placeHolder.email)),
								maxLength: 256,
							}
						)}
					</Col>
					<Col sm={24} md={12} style={styles.noPaddingCol}>
						{FormHelper.renderFormPhoneInput(
							'phone',

							[
								{
									required: true,
									message: I18n.t(_t(translations.staffDetail.messagePhone)),
								},
								({ getFieldValue }) => ({
									validator(robj, value) {
										if (value) {
											if (StringHelper.checkPhoneNumber(value)) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error(
													I18n.t(_t(translations.staffDetail.messagePhone))
												)
											);
										}
										return Promise.resolve();
									},
								}),
							],
							I18n.t(_t(translations.staffDetail.phone)),
							this.isAdd() ? '' : currentEmployee!.phone,
							{},
							{
								placeholder: I18n.t(_t(translations.placeHolder.phone)),
							}
						)}
					</Col>
				</Row>
			</>
		);
	}
	renderFormEmployment() {
		const currentEmployee = this.props.currentEmployee;
		return (
			<>
				<Space direction="vertical" style={styles.maxWidthSpace}>
					<Title level={5}>
						{I18n.t(_t(translations.staffDetail.employment))}
					</Title>
					<Text>{I18n.t(_t(translations.staffDetail.contactDescription))}</Text>
					<Row gutter={[16, 0]}>
						<Col sm={24} md={12} style={{ paddingInline: 0, width: '60%' }}>
							{FormHelper.renderFormDateTimePicker(
								'hireDate',
								I18n.t(_t(translations.staffDetail.startDate)),
								this.isAdd()
									? ''
									: !_.isEmpty(currentEmployee!.hireDate)
									? moment(currentEmployee!.hireDate)
									: '',
								[
									{
										required: true,
										message: I18n.t(
											_t(translations.staffDetail.messageHireDate)
										),
									},
								],
								{ width: '100%' },
								{}
							)}
						</Col>
						<Col sm={24} md={12} style={{ paddingInline: 0, width: '100%' }}>
							<Row gutter={[16, 0]}>
								<Col span={12}>
									<Space direction="vertical" style={styles.maxWidthSpace}>
										<Typography.Title level={5}>
											{I18n.t(_t(translations.staffDetail.userName))}
										</Typography.Title>
										<Input
											disabled={!this.isAdd()}
											placeholder={I18n.t(
												_t(translations.placeHolder.userName)
											)}
											value={
												this.isAdd()
													? _.isEmpty(this.state.userName)
														? `${this.state.firstName}${this.state.lastName}`.replace(
																' ',
																''
														  )
														: this.state.userName
													: currentEmployee!.userName
											}
											onChange={(event) =>
												this.setState({ userName: event.target.value })
											}
										/>
									</Space>
								</Col>
								{this.isAdd() ? null : (
									<Col span={12}>
										<Space direction="vertical" style={styles.maxWidthSpace}>
											<Typography.Title level={5}>
												{I18n.t(_t(translations.staffDetail.pinCode))}
											</Typography.Title>
											<Input
												disabled={!this.isAdd()}
												value={this.isAdd() ? '' : currentEmployee!.code}
											/>
										</Space>
									</Col>
								)}
							</Row>
						</Col>
						<Col span={24}>
							<Space direction="vertical" style={styles.maxWidthSpace}>
								<Typography.Title level={5}>
									{I18n.t(_t(translations.staffDetail.employeeType))}
								</Typography.Title>
								<Row gutter={4}>
									{this.props.employeeTypes.map((x, index) => (
										<Col>
											<Button
												key={index}
												onClick={() => this.onClickType(x)}
												value={x.id}
												style={
													(this.state.employeeTypeIds as string[]).includes(
														x.id
													)
														? styles.employeeTypeButton
														: undefined
												}
											>
												{x.code ? x.code.toUpperCase() : ''}
											</Button>
										</Col>
									))}
								</Row>
							</Space>
						</Col>
					</Row>
				</Space>
			</>
		);
	}

	renderFormColour() {
		return (
			<>
				<Card>
					<Card.Meta
						title={I18n.t(_t(translations.staffDetail.colour))}
						description={I18n.t(
							_t(translations.staffDetail.bookingDescription)
						)}
					/>
					<Divider style={styles.divider} />
					{FormHelper.renderFormColour(
						'colour',
						I18n.t(_t(translations.staffDetail.selectColour)),
						this.isAdd()
							? (_.sample(staffColors) as string)
							: this.props.currentEmployee?.color!,
						{}
					)}
				</Card>
			</>
		);
	}

	renderFormPayRoll() {
		return (
			<Card
				style={{ width: '100%' }}
				title={
					<Title level={4}>
						{I18n.t(_t(translations.staffDetail.payrollSetup))}
					</Title>
				}
			>
				<Tabs
					activeKey={this.state.tabKey.toString()}
					type="card"
					onChange={(key) => {
						this.onTabChange(key);
					}}
				>
					{this.renderCommissionTab()}
					{this.renderSalaryTab()}
					{this.renderSalaryAndCommissionTab()}
				</Tabs>
			</Card>
		);
	}
	renderCommissionTab() {
		const currentEmployee = this.props.currentEmployee;
		return (
			<TabPane tab={I18n.t(_t(translations.staffDetail.commission))} key="1">
				<Space size="middle" direction="vertical" style={styles.maxWidth}>
					<Title level={5}>
						{I18n.t(_t(translations.staffDetail.employeeOwnerCommission))}
					</Title>
					<CommissionRate
						title={I18n.t(_t(translations.staffDetail.forService))}
						onSelect={this.getServiceCommissionPercent}
						defaultValue={
							this.isAdd()
								? this.props.currentBranch?.defaultEmployeeCommission
								: currentEmployee?.servicesCommissionPercent
						}
						isAdd={this.isAdd()}
					/>
					<CommissionRate
						title={I18n.t(_t(translations.staffDetail.forGiftCard))}
						onSelect={this.getGiftCardCommissionPercent}
						defaultValue={
							this.isAdd()
								? this.props.currentBranch?.defaultEmployeeCommission
								: currentEmployee?.giftCardCommissionPercent
						}
						isAdd={this.isAdd()}
					/>
					<Divider style={styles.divider} />
					<CommissionRate
						title={I18n.t(_t(translations.staffDetail.cardCheckPercentage))}
						onSelect={this.getCashCheckCommissionPercent}
						defaultValue={
							this.isAdd()
								? ChargeRate.CASH_CHECK_COMMISSION
								: currentEmployee?.checkPercent
						}
						isAdd={this.isAdd()}
						canChoose100
					/>
					<Divider style={styles.divider} />
					<Title level={5}>
						{I18n.t(_t(translations.staffDetail.cardCharge))}
					</Title>
					<Col xs={8} sm={8} md={4} lg={4} xl={4} xxl={4}>
						<Input
							addonAfter="%"
							type="number"
							style={{ width: '100%', textAlign: 'right' }}
							maxLength={3}
							value={this.state.cardCharge}
							onChange={(event) =>
								this.onChangeCardCharge(_.toNumber(event.target.value))
							}
							defaultValue={
								!this.isAdd()
									? currentEmployee?.cardChargePercent!
									: ChargeRate.CARD_CHARGE
							}
						/>
					</Col>
					<Divider style={styles.divider} />
					<Title level={5}>
						{I18n.t(_t(translations.staffDetail.percentTipCardCharge))}
					</Title>
					<Col xs={8} sm={8} md={4} lg={4} xl={4} xxl={4}>
						<Input
							addonAfter="%"
							type="number"
							style={{ width: '100%', textAlign: 'right' }}
							maxLength={3}
							value={this.state.tipCardCharge}
							onChange={(event) =>
								this.onChangeTipCardCharge(_.toNumber(event.target.value))
							}
							defaultValue={
								!this.isAdd()
									? currentEmployee?.tipCardChargePercent!
									: ChargeRate.TIP_CARD_CHARGE
							}
						/>
					</Col>
					{this.isAdd() ? null : (
						<>
							<Divider style={styles.divider} />
							<Title level={5}>
								{I18n.t(_t(translations.staffDetail.salaryEffectiveStartDate))}
							</Title>
							<Col xs={8} sm={8} md={4} lg={4} xl={4} xxl={4}>
								<DatePicker
									defaultValue={
										!this.isAdd()
											? !_.isEmpty(currentEmployee?.salaryEffectiveStartDate)
												? moment(currentEmployee!.salaryEffectiveStartDate!)
												: undefined
											: undefined
									}
									onChange={(date) =>
										this.setState({ salaryEffectiveStartDate: date! })
									}
									format="MM/DD/YYYY"
								/>
							</Col>
						</>
					)}
				</Space>
			</TabPane>
		);
	}
	renderSalaryAndCommissionTab() {
		const currentEmployee = this.props.currentEmployee;
		return (
			<TabPane
				tab={I18n.t(_t(translations.staffDetail.salaryAndCommission))}
				key="3"
			>
				<Space size="middle" direction="vertical" style={styles.maxWidth}>
					<RadioWage
						getWage={this.getWage}
						onSelectWageType={this.onSelectWageType}
						defaultWageType={currentEmployee?.salaryType}
						defaultWage={currentEmployee?.salary}
					/>
					<Divider style={styles.divider} />

					<Title level={5}>
						{I18n.t(_t(translations.staffDetail.employeeOwnerCommission))}
					</Title>
					<CommissionRate
						title={I18n.t(_t(translations.staffDetail.forService))}
						onSelect={this.getServiceCommissionPercent}
						defaultValue={currentEmployee?.servicesCommissionPercent}
						isAdd={this.isAdd()}
					/>
					<CommissionRate
						title={I18n.t(_t(translations.staffDetail.forGiftCard))}
						onSelect={this.getGiftCardCommissionPercent}
						defaultValue={currentEmployee?.giftCardCommissionPercent}
						isAdd={this.isAdd()}
					/>
					<Divider style={styles.divider} />
					<CommissionRate
						title={I18n.t(_t(translations.staffDetail.cardCheckPercentage))}
						onSelect={this.getCashCheckCommissionPercent}
						defaultValue={
							this.isAdd()
								? ChargeRate.CASH_CHECK_COMMISSION
								: currentEmployee?.checkPercent
						}
						isAdd={this.isAdd()}
					/>
					<Divider style={styles.divider} />
					<Title level={5}>
						{I18n.t(_t(translations.staffDetail.cardCharge))}
					</Title>
					<Col xs={8} sm={8} md={4} lg={4} xl={4} xxl={4}>
						<Input
							addonAfter="%"
							type="number"
							style={{ width: '100%', textAlign: 'right' }}
							maxLength={3}
							value={this.state.cardCharge}
							onChange={(event) =>
								this.onChangeCardCharge(_.toNumber(event.target.value))
							}
							defaultValue={
								!this.isAdd()
									? currentEmployee?.cardChargePercent!
									: ChargeRate.CARD_CHARGE
							}
						/>
					</Col>
					<Divider style={styles.divider} />
					<Title level={5}>
						{I18n.t(_t(translations.staffDetail.percentTipCardCharge))}
					</Title>
					<Col xs={8} sm={8} md={4} lg={4} xl={4} xxl={4}>
						<Input
							addonAfter="%"
							type="number"
							style={{ width: '100%', textAlign: 'right' }}
							maxLength={3}
							value={this.state.tipCardCharge}
							onChange={(event) =>
								this.onChangeTipCardCharge(_.toNumber(event.target.value))
							}
							defaultValue={
								!this.isAdd()
									? currentEmployee?.tipCardChargePercent!
									: ChargeRate.TIP_CARD_CHARGE
							}
						/>
					</Col>
					{this.isAdd() ? null : (
						<>
							{' '}
							<Divider style={styles.divider} />
							<Title level={5}>
								{I18n.t(_t(translations.staffDetail.salaryEffectiveStartDate))}
							</Title>
							<Col xs={8} sm={8} md={4} lg={4} xl={4} xxl={4}>
								<DatePicker
									defaultValue={
										!this.isAdd()
											? !_.isEmpty(currentEmployee?.salaryEffectiveStartDate)
												? moment(currentEmployee!.salaryEffectiveStartDate!)
												: undefined
											: undefined
									}
									onChange={(date) =>
										this.setState({ salaryEffectiveStartDate: date! })
									}
									format="MM/DD/YYYY"
								/>
							</Col>
						</>
					)}
				</Space>
			</TabPane>
		);
	}
	renderSalaryTab() {
		const currentEmployee = this.props.currentEmployee;
		return (
			<TabPane tab={I18n.t(_t(translations.staffDetail.salary))} key="2">
				<Space size="middle" direction="vertical" style={styles.maxWidth}>
					<RadioWage
						getWage={this.getWage}
						onSelectWageType={this.onSelectWageType}
						defaultWageType={currentEmployee?.salaryType}
						defaultWage={currentEmployee?.salary}
					/>
					<Divider style={styles.divider} />
					<CommissionRate
						title={I18n.t(_t(translations.staffDetail.cardCheckPercentage))}
						onSelect={this.getCashCheckCommissionPercent}
						defaultValue={
							this.isAdd()
								? ChargeRate.CASH_CHECK_COMMISSION
								: currentEmployee?.checkPercent
						}
						isAdd={this.isAdd()}
					/>
					<Divider style={styles.divider} />
					<Title level={5}>
						{I18n.t(_t(translations.staffDetail.percentTipCardCharge))}
					</Title>
					<Col xs={8} sm={8} md={4} lg={4} xl={4} xxl={4}>
						<Input
							addonAfter="%"
							type="number"
							style={{ width: '100%', textAlign: 'right' }}
							maxLength={3}
							value={this.state.tipCardCharge}
							onChange={(event) =>
								this.onChangeTipCardCharge(_.toNumber(event.target.value))
							}
							defaultValue={
								!this.isAdd()
									? currentEmployee?.tipCardChargePercent!
									: ChargeRate.TIP_CARD_CHARGE
							}
						/>
					</Col>
					{this.isAdd() ? null : (
						<>
							{' '}
							<Divider style={styles.divider} />
							<Title level={5}>
								{I18n.t(_t(translations.staffDetail.salaryEffectiveStartDate))}
							</Title>
							<Col xs={8} sm={8} md={4} lg={4} xl={4} xxl={4}>
								<DatePicker
									defaultValue={
										!this.isAdd()
											? !_.isEmpty(currentEmployee?.salaryEffectiveStartDate)
												? moment(currentEmployee!.salaryEffectiveStartDate!)
												: undefined
											: undefined
									}
									onChange={(date) =>
										this.setState({ salaryEffectiveStartDate: date! })
									}
									format="MM/DD/YYYY"
								/>
							</Col>
						</>
					)}
				</Space>
			</TabPane>
		);
	}
	renderServiceTransfer() {
		return (
			<>
				<Card>
					<Card.Meta title={I18n.t(_t(translations.staffDetail.services))} />
					<Divider style={styles.divider} />

					<Transfer
						dataSource={this.state.initialService}
						showSearch
						titles={[
							I18n.t(_t(translations.staffDetail.allService)),
							I18n.t(_t(translations.staffDetail.staffService)),
						]}
						filterOption={(value, option) => {
							return option.title.toLowerCase().includes(value.toLowerCase());
						}}
						listStyle={{
							width: window.innerWidth / 4,
							height: window.innerWidth / 4,
						}}
						operations={[
							I18n.t(_t(translations.staffDetail.addService)),
							I18n.t(_t(translations.staffDetail.remove)),
						]}
						targetKeys={this.state.targetKeys}
						onChange={this.handleChange}
						render={(item: any) => item.title}
						style={{ justifyContent: 'center', width: '100%' }}
					/>
				</Card>
			</>
			// )
		);
	}

	renderTimeInput() {
		return (
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.staffDetail.workingTime))}
					description={I18n.t(_t(translations.staffDetail.subWorkingTime))}
				/>
				<Divider style={styles.divider} />
				{this.renderWorkingTimeInputs()}
			</Card>
		);
	}

	renderWorkingTimeInputs() {
		return (
			<>
				{this.renderDayWorkingTime(
					WeekDays.WHOLEWEEK,
					(value) => {
						this.setState({ wholeWeekChecked: value }, () => {
							const fieldsKeys = Object.keys(this.formRef?.getFieldsValue());
							const fieldData = getTimeFieldData(fieldsKeys, value);
							this.formRef?.setFields(fieldData);
							this.formRef?.validateFields().then();
						});
					},
					true
				)}
				{this.renderDayWorkingTime(WeekDays.SUNDAY)}
				{this.renderDayWorkingTime(WeekDays.MONDAY)}
				{this.renderDayWorkingTime(WeekDays.TUESDAY)}
				{this.renderDayWorkingTime(WeekDays.WEDNESDAY)}
				{this.renderDayWorkingTime(WeekDays.THURSDAY)}
				{this.renderDayWorkingTime(WeekDays.FRIDAY)}
				{this.renderDayWorkingTime(WeekDays.SATURDAY)}
			</>
		);
	}

	renderDayWorkingTime = (
		day: number,
		onValueChange?: (value: boolean) => void,
		isWholeWeek = false
	) => {
		const { currentEmployee } = this.props;
		const initialValue = !isWholeWeek
			? _.find(currentEmployee?.employeeWorkingTimes, (element) => {
					return element.days! === day.toString();
			  })
			: undefined;
		const initialWholeWeekCheck =
			currentEmployee?.employeeWorkingTimes?.length === 7 ? true : false;
		const initialWholeWeekValue =
			initialWholeWeekCheck &&
			currentEmployee?.employeeWorkingTimes?.every(
				(element) =>
					element.workingTimeStartAt ===
						currentEmployee.employeeWorkingTimes![0].workingTimeStartAt &&
					element.workingTimeEndAt ===
						currentEmployee.employeeWorkingTimes![0].workingTimeEndAt
			)
				? [
						currentEmployee.employeeWorkingTimes![0].workingTimeStartAt!,
						currentEmployee.employeeWorkingTimes![0].workingTimeEndAt!,
				  ]
				: undefined;
		return (
			<WorkingTimeOfDay
				day={day}
				wholeWeekChecked={this.state.wholeWeekChecked || false}
				onValueChange={onValueChange}
				defaultChecked={
					!isWholeWeek
						? _.isEmpty(initialValue)
							? false
							: true
						: initialWholeWeekCheck
				}
				initialValue={
					isWholeWeek
						? initialWholeWeekValue
						: !_.isEmpty(initialValue)
						? [
								initialValue?.workingTimeStartAt!,
								initialValue?.workingTimeEndAt!,
						  ]
						: undefined
				}
				formRef={this.formRef}
			/>
		);
	};

	isAdd() {
		return this.props.location.pathname.includes('add');
	}

	goBack = () => {
		const { state } = this.props.location;
		this.props.resetData();
		if (state && state.fromService) {
			history.goBack();
		} else {
			history.push('/staffs');
		}
	};
	onDelete() {
		this.setState({ showDeleteModal: true });
	}

	filterOption = (inputValue: any, option: any) =>
		option.description.indexOf(inputValue) > -1;

	handleChange = (targetKeys: any) => {
		this.setState({ targetKeys });
	};

	onClickType = (type: IEmployeeType) => {
		let employeeTypeIds: string[] = this.state.employeeTypeIds;
		let employeeTypeCodes: string[] = this.state.employeeTypeCodes;
		let index = employeeTypeIds.findIndex((x: any) => x === type.id);
		if (index !== -1) {
			employeeTypeIds.splice(index, 1);
			employeeTypeCodes.splice(index, 1);
		} else {
			employeeTypeIds.push(type.id);
			employeeTypeCodes.push(type.code!);
		}
		this.setState({
			employeeTypeIds,
			employeeTypeCodes,
			// employeeTypeId: type.id,
			// employeeType: type.code,
			// showServiceTransfer: this.checkRole({ roleName: type?.roleName })!,
		});
	};

	checkRole = (type: { roleName?: string }) => {
		return (
			type.roleName?.toLowerCase() === 'employee' ||
			type.roleName?.toLowerCase() === 'technician'
		);
	};
	getService = () => {
		const targetKeys: any = [];
		const initialService: any = [];
		const currentEmployeeId = this.props.match.params.id;
		this.props.services.forEach((services: IService) => {
			const data = {
				key: services.id,
				title: services.name,
				employeeId: services.employeeIds,
			};
			if (data.employeeId.includes(currentEmployeeId)) {
				targetKeys.push(data.key);
			}
			initialService.push(data);
		});
		this.setState({ initialService, targetKeys });
	};
	onTabChange = (key: string) => {
		this.setState({ tabKey: _.toNumber(key) });
	};
	getServiceCommissionPercent = (commissionPercent: any) => {
		this.setState({ servicesCommission: commissionPercent });
	};
	getGiftCardCommissionPercent = (commissionPercent: any) => {
		this.setState({ giftCardCommission: commissionPercent });
	};
	getCashCheckCommissionPercent = (commissionPercent: any) => {
		this.setState({ cashCheckCommission: commissionPercent });
	};
	getWage = (wage: any) => {
		this.setState({ wage });
	};

	getWorkingTimes(formData: any): WorkingHour[] {
		//clone the current working times
		const currentWorkingTimes = Object.assign(
			[],
			this.props.currentEmployee?.employeeWorkingTimes
		) as WorkingHour[];

		//Get the new "checked" working times
		const newWorkingTimes: Partial<WorkingHour>[] = [];
		for (let i = 0; i < 7; ++i) {
			const day = getDayOfWeek(i).replaceAll(' ', '').toLowerCase();
			if (formData[`${day}Check`]) {
				const workingTime = formData[day] as Moment[];
				newWorkingTimes.push({
					days: i.toString(),
					workingTimeStartAt: momentTz
						.tz(workingTime[0], this.props.timezone!)
						.utc()
						.format('YYYY-MM-DDTHH:mm'),
					workingTimeEndAt: momentTz
						.tz(workingTime[1], this.props.timezone!)
						.utc()
						.format('YYYY-MM-DDTHH:mm'),
					breakingTimeEndAt: undefined,
					breakingTimeStartAt: undefined,
				});
			}
		}
		//remove the "unchecked" times from current
		const cleanCurrentWorkingTimes = currentWorkingTimes.filter((element) => {
			const day = getDayOfWeek(Number.parseInt(element.days!))
				.replaceAll(' ', '')
				.toLowerCase();
			if (formData[`${day}Check`]) {
				return true;
			}
			return false;
		});
		//update or add to current working times
		for (let i = 0; i < 7; ++i) {
			//if the day exist
			const currentIndex = cleanCurrentWorkingTimes.findIndex(
				(element) => element.days === i.toString()
			);
			const newIndex = newWorkingTimes.findIndex(
				(element) => element.days === i.toString()
			);
			if (currentIndex !== -1) {
				//update the working time;
				cleanCurrentWorkingTimes[currentIndex] = {
					...cleanCurrentWorkingTimes[currentIndex],
					...newWorkingTimes[newIndex],
				};
			} else if (newIndex !== -1) {
				//add new working time;
				cleanCurrentWorkingTimes.push(newWorkingTimes[newIndex]);
			}
		}
		return cleanCurrentWorkingTimes;
	}

	onSelectWageType = (wageType: WageType) => {
		this.setState({ wageType });
	};
	onChangeCardCharge = (value: number) => {
		let cardCharge = value;
		if (value > 100) {
			cardCharge = 100;
		}
		if (value < 0) {
			cardCharge = 0;
		}
		this.setState({ cardCharge });
	};
	onChangeTipCardCharge = (value: number) => {
		let tipCardCharge = value;
		if (value > 100) {
			tipCardCharge = 100;
		}
		if (value < 0) {
			tipCardCharge = 0;
		}
		this.setState({ tipCardCharge });
	};
	onResetPassword = async (password: string) => {
		this.setState({ isResetingPassword: true });
		const result = (await UserApiService.resetUserPassword({
			userId: this.props.currentEmployee?.userId!,
			adminPassword: password,
		})) as IApiResponse<string>;
		if (result.succeeded) {
			AlertHelper.showSuccess(
				I18n.t(_t(translations.staffDetail.resetPasswordSuccess))
			);
			this.setState({
				isShowAdminPasswordModal: false,
				isResetingPassword: false,
				newPassword: result.data,
				isShowNewPasswordModal: true,
			});
		} else {
			AlertHelper.showError(result as IErrorResponse);
			this.setState({ isResetingPassword: false });
		}
	};
	disabledDate(current: Moment) {
		return current && current < moment().endOf('day');
	}
	contentList: any = {
		1: this.renderCommissionTab(),
		2: this.renderSalaryTab(),
		3: this.renderSalaryAndCommissionTab(),
	};
	onFormFinish = (formName: string, form: any) => {
		const currentEmployee = this.props.currentEmployee;
		const currentBranch = this.props.currentBranch;
		const userName = !_.isEmpty(this.state.userName)
			? this.state.userName.split(' ').join('')
			: `${form.values.firstName}${form.values.lastName}`.split(' ').join('');
		this.setState({ userName });
		const data: IAddEmployeeRequest = {
			code: `${moment().unix()}`,
			color: form.values.colour,
			hireDate: form.values.hireDate,
			employeeTypeId: this.state.employeeTypeId,
			employeeTypeIds: this.state.employeeTypeIds,
			departmentId: undefined,
			gender: 0,
			// employeeTypeCode: this.state.employeeType,
			employeeTypeCodes: this.state.employeeTypeCodes,
			dateOfBirth: !form.values.birthDate
				? undefined
				: convertDateRequest(form.values.birthDate),
			commissionPercent: form.values.serviceCommission,
			imageUrl: '',
			branchId: currentBranch?.id!,
			firstName: form.values.firstName,
			lastName: form.values.lastName,
			profilePicture: '',
			shopId: currentBranch?.shopId!,
			userName,
			email: !_.isEmpty(form.values.email) ? form.values.email : undefined,
			password: DefaultPassword.PASSWORD,
			confirmPassword: DefaultPassword.PASSWORD,
			phone: StringHelper.convertToRawPhone(form.values.phone)!,
			additionalName: '',
			address: {
				street: '',
				city: '',
				state: '',
				zipCode: '',
				countryCode: '',
			},
			status: 1,
			serviceIds: this.state.targetKeys,
			file: this.state.imageFiles ? this.state.imageFiles![0] : undefined,
			incomeType: _.toNumber(this.state.tabKey),
			salaryType: this.state.wageType ? this.state.wageType! : 0,
			salary: this.state.wage ? this.state.wage! : 0,
			servicesCommissionPercent: this.state.servicesCommission
				? this.state.servicesCommission!
				: 0,
			productCommissionPercent: 0,
			giftCardCommissionPercent: this.state.giftCardCommission
				? this.state.giftCardCommission!
				: 0,
			checkPercent: this.state.cashCheckCommission
				? this.state.cashCheckCommission!
				: 0,
			cardChargePercent: this.state.cardCharge ? this.state.cardCharge! : 0,
			tipCardChargePercent: this.state.tipCardCharge
				? this.state.tipCardCharge!
				: 0,
			// salaryEffectiveStartDate: this.isAdd()
			// 	? BookingHelper.convertDateRequest(moment(form.values.hireDate))
			// 	: this.state.salaryEffectiveStartDate!,
			salaryEffectiveStartDate: this.isAdd()
				? form.values.hireDate
				: this.state.salaryEffectiveStartDate!,
			employeeWorkingTimes: this.getWorkingTimes(form.values),
		};
		if (this.state.tabKey === 2 || this.state.tabKey === 3) {
			if (!this.state.wage) {
				AlertHelper.showAlert(
					'Error',
					I18n.t(_t(translations.staffDetail.pleaseInputSalary)),
					'error'
				);
			} else {
				this.isAdd()
					? this.props.addEmployee(data)
					: this.props.editEmployee({
							...data,
							id: currentEmployee?.id!,
							userId: currentEmployee?.userId!,
							userName: currentEmployee?.userName!,
							imageUrl: this.state.isHaveInitialImageUrl
								? this.props.currentEmployee?.imageUrl!
								: '',
							status: currentEmployee?.status!,
							canBookedOnline: currentEmployee?.canBookedOnline!,
					  });
			}
		} else {
			this.isAdd()
				? this.props.addEmployee(data)
				: this.props.editEmployee({
						...data,
						id: currentEmployee?.id!,
						userId: currentEmployee?.userId!,
						userName: currentEmployee?.userName!,
						code: currentEmployee?.code!,
						imageUrl: this.state.isHaveInitialImageUrl
							? this.props.currentEmployee?.imageUrl!
							: '',
						status: currentEmployee?.status!,
						canBookedOnline: currentEmployee?.canBookedOnline!,
				  });
		}
	};
}
const mapStateToProps = (state: RootState) => ({
	currentAction: state.ReduxActionReducer['EMPLOYEE'],
	isLoading: state.AppConfigReducer.showLoading,
	error: state.ErrorReducer.error?.errors,
	currentEmployee: state.EmployeeReducer.selectedEmployee,
	currentBranch: state.BranchReducer.currentBranch,
	services: state.ServiceReducer.services,
	serviceAction: state.ReduxActionReducer['SERVICE'],
	employeeTypes: state.EmployeeTypeReducer.employeeTypes,
	roles: state.RoleReducer.roles,
	timezone: state.BranchReducer.currentBranch?.localTimeZone,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	getAllServices: () => {
		dispatch(ServiceActions.getAllServices.request());
	},
	resetData: () => {
		dispatch(EmployeeActions.removeCurrentEmployee.request());
		dispatch(ServiceActions.getAllServices.request());
		dispatch(ErrorActions.clearError.request());
	},
	clearError: () => {
		dispatch(ErrorActions.clearError.request());
	},
	getEmployeeById: (id: string) => {
		dispatch(EmployeeActions.getEmployeeById.request(id));
	},
	addEmployee: (newEmployee: IAddEmployeeRequest) => {
		dispatch(EmployeeActions.addEmployee.request(newEmployee));
	},
	editEmployee: (employee: IEditEmployeeRequest) => {
		dispatch(EmployeeActions.editEmployee.request(employee));
	},
	deleteEmployee: (id: string) => {
		dispatch(EmployeeActions.deleteEmployee.request(id));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffDetailPage);
