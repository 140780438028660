import ModalHelper from 'helpers/modalHelper';
import I18n from 'i18n-js';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { translations, _t } from 'utils';

interface IColorPickerModalProps {
	isShowModal: boolean;
	onCancel: () => void;
	onChooseColor: (color: string) => void;
	initialColor?: string;
}
const ColorPickerModal = ({
	isShowModal,
	onCancel,
	onChooseColor,
	initialColor,
}: IColorPickerModalProps) => {
	const [color, setColor] = useState<string | undefined>(initialColor);
	const isMobile = window.innerWidth <= 768;
	return (
		<ModalHelper
			title={I18n.t(_t(translations.shopSetting.mainColor))}
			onCancel={onCancel}
			onOk={() => {
				onChooseColor(color!);
			}}
			visible={isShowModal}
			width={isMobile ? '100%' : '30%'}
		>
			<SketchPicker
				onChange={(color) => {
					setColor(color.hex);
				}}
				color={color}
				styles={{
					default: { picker: { width: '100%', padding: 0, boxShadow: 'none' } },
				}}
			/>
		</ModalHelper>
	);
};

export default ColorPickerModal;
