import { Col, Row } from "antd";
import React from "react";
import ReactToPrint, { IReactToPrintProps } from "react-to-print";

interface IPrintComponentProps extends Partial<IReactToPrintProps> {
  refPrinter(ref: ReactToPrint): void;
}

export default class PrintComponent extends React.Component<IPrintComponentProps> {
  refPrintComponent: any;
  render() {
    const pageStyle = `
    @page {
      size: 80mm 140mm;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      @media all {
        .pagebreak {
          display: none;
        }
      }
    }`;
    return (
      <Row style={{ display: "none" }}>
        <ReactToPrint
          {...this.props}
          ref={this.props.refPrinter}
          content={() => this.refPrintComponent}
          pageStyle={pageStyle}
        />
        <Col ref={(el) => (this.refPrintComponent = el)}>{this.props.children}</Col>
      </Row>
    );
  }
}
