import { Button, Col, Modal, Row, Space, Typography } from "antd";
import { translations, _t, I18n } from "utils";
import React from "react";

interface IBaseModalModal {
  title: string;
  onOk(): void;
  onCancel(): void;
  disableOk?: boolean;
  isLoading?: boolean;
}

export default class BaseModalModal extends React.Component<IBaseModalModal> {
  render() {
    return (
      <Modal
        width={455}
        visible={true}
        title={
          <Typography.Title style={styles.textCenter} level={3}>
            {this.props.title}
          </Typography.Title>
        }
        onOk={() => this.props.onOk()}
        onCancel={() => this.props.onCancel()}
        footer={this.renderModalFooter()}
      >
        <Space size="middle" direction="vertical" style={styles.maxWidth}>
          {this.props.children}
        </Space>
      </Modal>
    );
  }

  renderModalFooter() {
    return (
      <Row gutter={8}>
        <Col span={12}>
          <Button onClick={this.props.onCancel} type="ghost" size="large" style={styles.maxWidth}>
            {I18n.t(_t(translations.cancel))}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            onClick={this.props.onOk}
            type="primary"
            size="large"
            style={styles.maxWidth}
            disabled={this.props.disableOk}
            loading={this.props.isLoading}
          >
            {I18n.t(_t(translations.save))}
          </Button>
        </Col>
      </Row>
    );
  }
}

const styles = {
  maxWidth: { width: "100%" },
  textCenter: { textAlign: "center" } as React.CSSProperties,
};
