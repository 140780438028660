import React from 'react';
import { Tabs } from 'antd';
import { I18n, _t, translations } from 'utils';
import { ContentAdminContainer } from 'components';
import ListEmployeeType from './components/ListEmployeeType';

const { TabPane } = Tabs;

class EmployeeTypePage extends React.Component {
	render() {
		return <ContentAdminContainer>{this.renderTabs()}</ContentAdminContainer>;
	}

	_onChangeTab(key: string) {}

	renderTabs() {
		return (
			<Tabs onChange={this._onChangeTab} type="card">
				<TabPane
					tab={I18n.t(_t(translations.employeeType.employeeTypeTab))}
					key="Tab3"
				>
					<ListEmployeeType />
				</TabPane>
			</Tabs>
		);
	}
}

export default EmployeeTypePage;
