import React from 'react';
import { Button, Col, Result, Row, Typography } from 'antd';
import { translations, _t, I18n } from 'utils';
import QRCode from 'react-qr-code';
import { IBooking } from 'models/IBooking';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { BookingHelper, TimeHelper } from 'helpers';
import _ from 'lodash';

interface IBookingSuccess {
	booking: IBooking;
}
const b64toBlob = (dataURI: any) => {
	var byteString = atob(dataURI.split(',')[1]);
	var ab = new ArrayBuffer(byteString.length);
	var ia = new Uint8Array(ab);

	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ab], { type: 'image/jpeg' });
};

export const BookingSuccess = (props: IBookingSuccess) => {
	const shop = useSelector((state: RootState) => state.ShopReducer.shop);
	const branch = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);
	const onSaveQRCode = () => {
		const imageSrc = `data:image/jpeg;base64,${BookingHelper.generateQrCode(
			props.booking.id
		)}`;
		const blob = b64toBlob(imageSrc);
		const url = URL.createObjectURL(blob);
		window.open(url);
		// DownloadHelper.downloadFile(
		//   imageSrc,
		//   `booking_${moment(currentBooking?.startTimeExpected).format("MM/DD/YYYY-HHmm")}.png`
		// );
	};
	const onBookOther = () => {
		if (window.self !== window.top) {
			if (_.isEmpty(shop?.portalUrl)) {
				window.parent.location.href = `https://${shop?.adminUrl}.website.smartsalon.live/booking`;
			} else {
				window.parent.location.href = `https://${shop?.portalUrl}/booking`;
			}
		} else {
			window.location.href = `https://${shop?.adminUrl}.smartsalon.live/customer-booking`;
		}
	};
	const QRCodeButton = () => {
		return (
			<Button onClick={() => onSaveQRCode()} key="buy">
				{I18n.t(_t(translations.bookingPage.saveQRCode))}
			</Button>
		);
	};
	const BookingInfo = () => {
		return (
			<Col style={{ margin: 16 }}>
				<Row style={{ justifyContent: 'space-between' }}>
					<Typography.Text strong style={{ color: '#9F9F9F' }}>
						{I18n.t(_t(translations.bookingPage.shop))}
					</Typography.Text>
					<Typography.Text>{shop?.name}</Typography.Text>
				</Row>
				<Row style={{ justifyContent: 'space-between' }}>
					<Typography.Text strong style={{ color: '#9F9F9F' }}>
						{I18n.t(_t(translations.bookingPage.startTimeAt))}
					</Typography.Text>
					<Typography.Text>
						{TimeHelper.toTimeZone(props.booking.startTimeExpected).format(
							'hh:mmA'
						)}
					</Typography.Text>
				</Row>{' '}
				<Row style={{ justifyContent: 'space-between' }}>
					<Typography.Text strong style={{ color: '#9F9F9F' }}>
						{I18n.t(_t(translations.address))}
					</Typography.Text>
					<Typography.Text>{`${branch?.address?.street}, ${branch?.address?.city}, ${branch?.address?.state}`}</Typography.Text>
				</Row>
			</Col>
		);
	};
	return (
		<Row align={'middle'} justify={'center'}>
			<Col>
				<Result
					status="success"
					title={
						<Typography.Text strong>
							{I18n.t(_t(translations.bookingPage.successTitle))}
						</Typography.Text>
					}
					subTitle={
						<Typography.Text strong style={{ fontSize: 16 }}>
							{I18n.t(_t(translations.bookingPage.successSubTitle))}
						</Typography.Text>
					}
					style={{ textAlign: 'center' }}
					extra={[
						<Button onClick={() => onBookOther()} type="primary" key="console">
							{I18n.t(_t(translations.bookingPage.bookOther))}
						</Button>,
					]}
				/>
				{BookingInfo()}
				<Row justify={'center'} style={{ marginBottom: 16 }}>
					<QRCode
						value={BookingHelper.generateQrCode(props.booking.id)}
						size={200}
					/>
				</Row>
			</Col>
		</Row>
	);
};
