import { BaseApiService } from 'services/BaseApiService';
import { ITip } from 'models/ITip';

class TipApiService extends BaseApiService {
	public addTips = (params: ITip) =>
		this.post<ITip, string>(`/Tip/add-bill-tips`, params);
	public addBillTipsManually = (params: ITip) =>
		this.post<ITip, string>(`/Tip/add-bill-tips-manually`, params);
	public addBillTipsBalance = (params: ITip) =>
		this.post<ITip, string>(`/Tip/add-bill-tips-balance`, params);
	public deleteTip = (id: string) =>
		this.delete<string>(`/Tip/delete-tip/${id}`);
	public updateTipBill = (params: {
		id: string;
		employeeId: string;
		tipAmount: number;
	}) =>
		this.put<
			{
				id: string;
				employeeId: string;
				tipAmount: number;
			},
			unknown
		>(`/Tip/update-tip`, params);
}

export default new TipApiService();
