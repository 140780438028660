import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row, Space, Select, Typography, Button } from 'antd';
import { ContentAdminContainer, Modal } from 'components';
import AppTable, { IColumnProps, ITableRef } from 'components/Table';
import { AlertHelper } from 'helpers';
import _ from 'lodash';
import { IApiResponse, IErrorResponse } from 'models';
import { IPromotion, IPromotionType } from 'models/IPromotion';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PromotionApiService from 'services/PromotionApiService';
import { I18n, _t, translations } from 'utils';
const { Option } = Select;

const Header = (props: {
	promotionTypes: IPromotionType[];
	setType: (value: string) => void;
}) => {
	const history = useHistory();
	return (
		<Row justify="space-between">
			<Col md={12}>
				<Row align="middle">
					<Col>
						<Typography.Title level={4}>Discount Type</Typography.Title>
					</Col>
					<Col md={12}>
						<Select
							defaultValue=""
							style={{ width: '100%', marginLeft: '1rem' }}
							onChange={(value) => props.setType(value)}
						>
							<Option value={''}>All</Option>
							{props.promotionTypes.map((type) => (
								<Option value={type.id}>{type.name}</Option>
							))}
						</Select>
					</Col>
				</Row>
			</Col>

			<Col md={12}>
				<Space
					style={{ justifyContent: 'flex-end', width: '100%' }}
					size="small"
				>
					<Button
						onClick={(e) => {
							e.stopPropagation();
							history.push(`promotions/add-new`);
						}}
						icon={<PlusOutlined />}
						type="primary"
					>
						{I18n.t(_t(translations.addNew))}
					</Button>
				</Space>
			</Col>
		</Row>
	);
};

export const Promotion = () => {
	const history = useHistory();
	const [showModal, setShowModal] = useState(false);
	const tableRef = useRef<ITableRef>();
	const [type, setType] = useState<string>('');
	const [promotionId, setPromotionId] = useState('');

	const [promotionTypes, setPromotionTypes] = useState<IPromotionType[]>([]);
	const fetchPromotionTypes = async () => {
		try {
			const result =
				(await PromotionApiService.getPromotionType()) as IApiResponse<
					IPromotionType[]
				>;
			if (result.succeeded) {
				setPromotionTypes(result.data || []);
			}
		} catch (error) {}
	};
	const onDeletePromotion = async () => {
		try {
			(await PromotionApiService.updatePromotionRequirement({
				promotionId: promotionId,
				requirements: [],
			})) as IApiResponse;
			(await PromotionApiService.updatePromotionItemCategory({
				promotionId: promotionId,
				itemCategoryIds: [],
			})) as IApiResponse;
			(await PromotionApiService.updatePromotionItem({
				promotionId: promotionId,
				itemIds: [],
			})) as IApiResponse;
			(await PromotionApiService.updatePromotionCoupon({
				promotionId: promotionId,
				couponCodes: [],
			})) as IApiResponse;

			const result = (await PromotionApiService.deletePromotion(
				promotionId
			)) as IApiResponse;
			if (result.succeeded) {
				AlertHelper.showSuccess(
					I18n.t(_t(translations.promotionDetail.messageDeletePromotionSuccess))
				);
				tableRef.current?.refresh();
			} else {
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchPromotionTypes();
	}, []);

	const columns = useMemo<IColumnProps<IPromotion>[]>(() => {
		return [
			{
				header: 'Promotion Name',
				key: 'name',
				type: 'text',
			},
			{
				header: 'Discount Type',
				key: 'promotionTypeId',
				type: 'text',
				renderCell: (data) => {
					const typeName =
						_.find(promotionTypes, (x) => x.id === data?.promotionTypeId)
							?.name || data?.promotionTypeId;
					return <Typography.Text>{typeName}</Typography.Text>;
				},
				width: 300,
			},
			{
				header: 'Discount Amount/Percentage',
				key: 'discountPercentage',
				type: 'number',
				renderCell: (data) => {
					return data?.usePercentage ? (
						<Typography.Text>{data.discountPercentage}%</Typography.Text>
					) : (
						<Typography.Text>{data?.discountAmount}$</Typography.Text>
					);
				},
				width: 100,
			},
			{
				header: 'Start Date',
				key: 'startDateUtc',
				type: 'date',
				width: 200,
			},
			{
				header: 'End Date',
				key: 'endDateUtc',
				type: 'date',
				width: 200,
			},
			{
				header: 'Status',
				key: 'isEnabled',
				type: 'text',
				width: 100,
				renderCell: (data) => {
					return data?.isEnabled ? (
						<Typography.Text>Active</Typography.Text>
					) : (
						<Typography.Text>Inactive</Typography.Text>
					);
				},
			},
			{
				header: '',
				key: 'id',
				type: 'text',
				width: 200,
				renderCell: (data) => {
					return (
						<Space>
							<Button
								icon={<EditOutlined />}
								onClick={(e) => {
									e.stopPropagation();
									history.push(`promotions/edit/${data!.id}`);
								}}
							></Button>
							<Button
								icon={<DeleteOutlined />}
								onClick={() => {
									setPromotionId(data!.id);
									setShowModal(true);
								}}
							/>
						</Space>
					);
				},
			},
		];
	}, [promotionTypes]);

	return (
		<ContentAdminContainer>
			<AppTable<IPromotion>
				ref={(ref) => {
					if (ref) {
						tableRef.current = ref;
					}
				}}
				columns={columns}
				queryUrl={'/Promotion/get-all-promotions'}
				renderHeader={
					<Header
						promotionTypes={promotionTypes}
						setType={(value) => setType(value)}
					/>
				}
				showSearch
				additionalParams={{
					promotionTypeId: type,
				}}
			/>
			<Modal
				visible={showModal}
				title={I18n.t(_t(translations.delete))}
				handleOk={() => {
					onDeletePromotion();
					setShowModal(false);
				}}
				handleCancel={() => {
					setShowModal(false);
				}}
			>
				{I18n.t(_t(translations.promotionDetail.deleteConfirmPromotion))}
			</Modal>
		</ContentAdminContainer>
	);
};
