import { Form, FormInstance, Radio, Row, Typography } from 'antd';
import { FormHelper, TimeHelper } from 'helpers';
import ModalHelper from 'helpers/modalHelper';
import I18n from 'i18n-js';
import _ from 'lodash';
import { ICloseOnlineBooking } from 'models/ICloseOnlinebooking';
import { useRef, useState } from 'react';
import { _t, translations } from 'utils';
import momentTz from 'moment-timezone';
import { toTimeZone } from 'helpers/timeHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { RangePickerTimeProps } from 'antd/lib/date-picker/generatePicker';
import moment from 'moment';
import DateSelect from 'components/DateSelect';
import CloseOnlineBookingApiService from 'services/CloseOnlineBookingApiService';
import { DatePicker } from 'components/DatePicker/DatePicker';

export interface IDetailModalProps {
	closeOnlineBooking?: ICloseOnlineBooking;
	show?: boolean;
	onCancel?: () => void;
	onSuccess: () => void;
}
enum DATE_TYPE {
	TODAY = 'TODAY',
	TOMORROW = 'TOMORROW',
	SELECT_DATE = 'SELECT_DATE',
}
export const DetailModal = (props: IDetailModalProps) => {
	const { closeOnlineBooking, show } = props;
	const isAdd = _.isEmpty(closeOnlineBooking);
	const formRef = useRef<FormInstance<ICloseOnlineBooking>>();
	const timezone = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch?.localTimeZone
	);
	const branchId = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch?.id
	);
	const [dateType, setDateType] = useState<DATE_TYPE>(
		isAdd ? DATE_TYPE.TODAY : DATE_TYPE.SELECT_DATE
	);
	const onDelete = async () => {
		const response = await CloseOnlineBookingApiService.deleteById(
			closeOnlineBooking?.id!
		);
		if (_.isEmpty(response.errors)) {
			props.onSuccess && props.onSuccess();
		}
	};
	const onSubmit = async (data: any) => {
		const startTime = data.time[0];
		const endTime = data.time[1];
		const params: ICloseOnlineBooking = {
			id: isAdd ? undefined : closeOnlineBooking?.id,
			startTime,
			endTime,
			date: TimeHelper.toTimeZone(moment.utc(data.date).toDate()).format(
				'YYYY-MM-DDT00:00:00'
			),
			status: 1,
			description: data.description,
			branchId,
		};
		const response = isAdd
			? await CloseOnlineBookingApiService.add(params)
			: await CloseOnlineBookingApiService.update(params);
		if (_.isEmpty(response.errors)) {
			props.onSuccess && props.onSuccess();
		}
	};
	const onSelectDateType = (type: DATE_TYPE) => {
		switch (type) {
			case DATE_TYPE.TODAY:
				formRef.current?.setFields([
					{
						name: 'date',
						value: moment(),
					},
				]);
				break;
			case DATE_TYPE.TOMORROW:
				formRef.current?.setFields([
					{
						name: 'date',
						value: moment().add(1, 'day'),
					},
				]);
				break;
			default:
				break;
		}
		setDateType(type);
	};
	return (
		<ModalHelper
			visible={show || false}
			centered
			title={
				isAdd ? I18n.t(_t(translations.addNew)) : I18n.t(_t(translations.edit))
			}
			onCancel={() => props.onCancel && props.onCancel()}
			onOk={() => {
				formRef.current?.submit();
			}}
			btnDelete={!isAdd ? true : false}
			onDelete={() => onDelete()}
			// disableDelete={moment(selectedHoliday?.fromDate).isBefore(
			// 	moment().startOf('date')
			// )}
			// isLoading={isLoading}
		>
			<Form
				ref={(ref) => {
					if (ref) {
						formRef.current = ref;
					}
				}}
				layout="vertical"
				name="expense-detail-form"
				onFinish={onSubmit}
			>
				<Form.Item
					name={'date'}
					label={
						<Typography.Title level={5}>
							{I18n.t(_t(translations.holiday.date))}
						</Typography.Title>
					}
					initialValue={
						closeOnlineBooking ? moment(closeOnlineBooking.date) : moment()
					}
				>
					<Row>
						<Radio.Group
							defaultValue={DATE_TYPE.TODAY}
							buttonStyle="solid"
							style={{ width: '100%' }}
							onChange={(e) => onSelectDateType(e.target.value)}
						>
							<Radio.Button value={DATE_TYPE.TODAY}>{'TODAY'}</Radio.Button>
							<Radio.Button value={DATE_TYPE.TOMORROW}>
								{'TOMORROW'}
							</Radio.Button>
							<Radio.Button value={DATE_TYPE.SELECT_DATE}>
								{'SELECT DATE'}
							</Radio.Button>
						</Radio.Group>
					</Row>
					{dateType === DATE_TYPE.SELECT_DATE && (
						<Row>
							<DatePicker
								containerStyle={{ marginTop: 20 }}
								onSelectDate={(date) => {
									formRef.current?.setFields([{ name: 'date', value: date }]);
								}}
								selectedDate={
									formRef.current?.getFieldValue('date') || moment()
								}
							/>
						</Row>
					)}
				</Form.Item>
				{FormHelper.renderFormTimeRangePicker(
					'time',
					I18n.t(_t(translations.holiday.time)),
					isAdd
						? [
								momentTz.tz(timezone!).hour(8).startOf('hour'),
								momentTz.tz(timezone!).hour(22).startOf('hour'),
						  ]
						: [
								toTimeZone(closeOnlineBooking!.startTime),
								toTimeZone(closeOnlineBooking!.endTime),
						  ],
					[
						{
							required: true,
							message: I18n.t(_t(translations.holiday.timeRequired)),
						},
					],
					undefined,
					{ use12Hours: true, format: 'h:mmA' } as RangePickerTimeProps<any>
				)}
				{FormHelper.renderFormTextArea(
					'description',
					[],
					I18n.t(_t(translations.addHoliday.description)),
					I18n.t(_t(translations.addHoliday.description)),
					isAdd ? '' : closeOnlineBooking?.description
				)}
			</Form>
		</ModalHelper>
	);
};
