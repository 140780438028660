import { RootState } from 'redux/configuration/rootReducer';
import { createSelector } from 'reselect';
import _ from 'lodash';

const getItemCategories = (state: RootState) => state.CategoryReducer
const getAllCategories = (state: RootState) => state.CategoryReducer.categories;

export const getCurrentCategory = createSelector(getItemCategories, (categoryReducer) => categoryReducer.selectedCategory);
export const getActiveCategory = createSelector(
	[getAllCategories],
	(categories) => {
		return _.filter(
			categories,
			(categories) =>
            categories.status === 1
		);
	}
);