import { StarFilled } from '@ant-design/icons';
import { Button, Col, Radio, Row, Space, Typography } from 'antd';
import { ContentAdminContainer, Avatar } from 'components';
import _ from 'lodash';
import { IReview } from 'models';
import React, { ReactNodeArray, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { history } from 'routers';
import { _t, translations, I18n } from 'utils';
import AppTable, { IColumnProps, ITableRef } from 'components/Table';

const options = [
	{
		label: `1-3`,
		value: '1,2,3',
	},
	{
		label: '4-5',
		value: '4,5',
	},
];
const Header = (props: {
	stars: string;
	onClickRadioButton: (e: any) => void;
}) => {
	return (
		<Row gutter={16} style={{ alignItems: 'center' }}>
			<Col md={4}></Col>
			<Col md={20}>
				<Space
					style={{ justifyContent: 'flex-end', width: '100%' }}
					size="small"
				>
					<div className="flex-display flex-justify-right">
						<Radio.Group
							onChange={props.onClickRadioButton}
							defaultValue={'-1'}
							optionType="button"
							buttonStyle="solid"
						>
							<Radio.Button value={'-1'}>{`All`}</Radio.Button>
							{options.map((type, index) => {
								return (
									<Radio.Button value={type.value}>
										{`${type.label}`}{' '}
										<StarFilled style={{ color: '#fadb14', fontSize: 16 }} />
									</Radio.Button>
								);
							})}
						</Radio.Group>
					</div>
				</Space>
			</Col>
		</Row>
	);
};

const ReviewManagement = () => {
	const tableRef = useRef<ITableRef>();
	const [star, setStar] = useState('-1');

	const getStar = (review: IReview) => {
		return (
			<Row justify="end" gutter={[8, 8]}>
				<Col span={24}>{renderStar(review.rate)}</Col>
			</Row>
		);
	};

	const renderStar = (amount: number) => {
		let node: ReactNodeArray = [];
		for (let index = 0; index < amount; index++) {
			node.push(<StarFilled style={{ color: '#fadb14', fontSize: 16 }} />);
		}
		return <Row justify="end">{node}</Row>;
	};

	const columns = useMemo<IColumnProps<IReview>[]>(() => {
		return [
			{
				header: I18n.t(_t(translations.listBooking.customerName)),
				key: 'id',
				renderCell: (record) => {
					const customerName = `${record!.customer.firstName || ''} ${
						record!.customer.lastName || ''
					}`;
					return (
						<Button
							type="link"
							onClick={(e) => {
								e.stopPropagation();
								history.push(`customer/details/${record!.customerId}`);
							}}
						>
							<Space>
								<Avatar name={customerName}></Avatar>
								<Typography.Text
									style={{ color: '#037aff', cursor: 'pointer' }}
								>
									{customerName}
								</Typography.Text>
							</Space>
						</Button>
					);
				},
				width: 350,
			},
			{
				header: `${I18n.t(_t(translations.listBooking.date))} ${I18n.t(
					_t(translations.listBooking.time)
				)}`,
				key: 'createdDate',
				type: 'date',
				width: 150,
			},
			{
				header: I18n.t(_t(translations.listBooking.technician)),
				key: 'id',
				align: 'right',
				width: 150,
				renderCell: (record) => {
					const technician = _.uniqBy(
						record!.ratingDetails,
						(element) => element.stylist?.id
					).map(
						(technician) =>
							`${technician.stylist?.firstName || ''} ${
								technician.stylist?.lastName || ''
							}`
					);
					return (
						<>
							{technician.map((x) => (
								<Typography.Text style={{ display: 'block' }}>
									{x}
								</Typography.Text>
							))}
						</>
					);
				},
			},
			{
				header: 'Rate',
				align: 'right',
				width: 150,
				key: 'rate',
				renderCell: (record) => {
					return getStar(record!);
				},
			},
			{
				header: 'Comment',
				key: 'comment',
				type: 'text',
				width: 350,
			},
		];
	}, []);
	return (
		<ContentAdminContainer customPageName={'Review Management'}>
			<AppTable<IReview>
				ref={(ref) => {
					if (ref) {
						tableRef.current = ref;
					}
				}}
				columns={columns}
				queryUrl={'/Rating/get-all-ratings'}
				showSearch
				renderHeader={
					<Header
						stars={star}
						onClickRadioButton={(e) => setStar(e.target.value)}
					/>
				}
				additionalParams={{
					orderBy: 'createdDate desc',
					status: 1,
					rates: `${star === '-1' ? '' : star}`,
				}}
			/>
		</ContentAdminContainer>
	);
};

const mapStateToProps = (state: RootState) => ({
	currentAction: state.ReduxActionReducer['BRANCH'],
});
export default connect(mapStateToProps)(ReviewManagement);
