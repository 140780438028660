import { IBilling } from 'models/IBilling';
import { ITransaction } from 'models/ITransaction';
import { ICombineBillRequest } from 'models/RequestModels';
import { BaseApiService } from 'services/BaseApiService';

const url = '/Billing';
class BillingApiService extends BaseApiService {
	public createBill = (params: Partial<IBilling>) =>
		this.post<Partial<IBilling>, IBilling>(`${url}/create-bill`, params);
	public getBillByBookingId = (bookingId: string) =>
		this.get<IBilling[]>(`${url}/get-bills-by-booking-id`, {
			params: {
				bookingId,
			},
		});

	public combineBill = (params: ICombineBillRequest) =>
		this.post<ICombineBillRequest, IBilling>(`/Billing/combine-bill`, params);

	public updateBill = (params: Partial<IBilling>) =>
		this.put<Partial<IBilling>, IBilling>(`/Billing/update-bill`, params);
	public getBillById = (id: string) =>
		this.get<IBilling>(`${url}/get-bill/${id}`);
}

export default new BillingApiService();
