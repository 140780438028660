import {
	DeleteOutlined,
	EditOutlined,
	SearchOutlined,
	UploadOutlined,
} from '@ant-design/icons';
import {
	Button,
	Card,
	Col,
	Divider,
	Form,
	Input,
	Modal,
	Row,
	Space,
	Table,
	TablePaginationConfig,
	Typography,
	Upload,
} from 'antd';
import { DetailPageLayout_V2 } from 'components';
import { AlertHelper, FormHelper } from 'helpers';
import ModalHeper from 'helpers/modalHelper';
import { getBase64Image } from 'helpers/StringHelper';
import { debounce } from 'lodash';
import { IErrorResponse } from 'models';
import { IAddGalleryRequest } from 'models/RequestModels';
import React, { useEffect, useState } from 'react';
import GalleryApiService from 'services/GalleryApiService';
import UploadBaseService from 'services/UploadBaseService';
import { Colors, I18n, translations, _t } from 'utils';
import './style.css';
import styles from './styles';

const { Title, Text } = Typography;

const PAGE_SIZE = 10;
const debounceTime = 1000;
function GallerySetup() {
	const [form] = Form.useForm();
	const [galleryData, setGalleryData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [searchText, setSearchText] = useState<any>('');
	const [reloadPage, setReloadPage] = useState(false);
	const [tblPagination, setTblPagination] = useState<TablePaginationConfig>({
		showSizeChanger: false,
		pageSize: PAGE_SIZE,
		current: 1,
		total: 0,
		showTotal: (total: any) => `Total ${total} items`,
	});
	const [formVisibleGallery, setFormVisibleGallery] = useState<any>({
		edfIsCreate: false,
		isShowModal: false,
		edfWrapper: {},
	});
	const [preview, setPreview] = useState({
		previewVisible: false,
		previewImage: '',
		previewTitle: '',
	});

	useEffect(() => {
		(async () => {
			await getAllGallery();
		})();
	}, [reloadPage]);

	const getAllGallery = async () => {
		setIsLoading(true);
		const params = {
			pageSize: PAGE_SIZE,
			pageNumber: tblPagination?.current,
			searchText: searchText,
		};
		const res: any = await GalleryApiService.getAllGallery(params);
		if (res.succeeded) {
			res.data.map((x: any) => {
				x.numberOfImages = x.galleryPictures.length;
				x.includeCatetogy = x.description ? true : false;
				x.galleryPictures.map((p: any) => (p.checked = true));
			});
			setGalleryData(res.data);
			setTblPagination({ ...tblPagination, total: res.totalRecords });
		}
		setIsLoading(false);
	};

	const galleryColumns = [
		{
			title: I18n.t(_t(translations.miniCMSSetting.columnGalleryName)),
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: I18n.t(_t(translations.miniCMSSetting.columnType)),
			dataIndex: 'includeCatetogy',
			key: 'includeCatetogy',
			render: (item: boolean) => {
				return (
					<span>
						{item
							? I18n.t(_t(translations.miniCMSSetting.Category))
							: I18n.t(_t(translations.miniCMSSetting.Gallery))}
					</span>
				);
			},
		},
		{
			title: I18n.t(_t(translations.miniCMSSetting.columnNumberOfImages)),
			dataIndex: 'numberOfImages',
			key: 'numberOfImages',
		},
		{
			title: I18n.t(_t(translations.status)),
			dataIndex: 'status',
			key: 'status',
			render: (status: number) => {
				return (
					<span
						style={{ color: status == 0 ? Colors.RED_BERRY : Colors.GREEN }}
					>
						{status == 0
							? I18n.t(_t(translations.unActive))
							: I18n.t(_t(translations.active))}
					</span>
				);
			},
		},
		{
			title: I18n.t(_t(translations.action)),
			dataIndex: 'id',
			key: 'action',
			align: 'right' as const,
			render: (_id: any, record: any) => {
				return (
					<Button
						type="primary"
						onClick={() => {
							showEditModalGallery(record);
						}}
						icon={<EditOutlined />}
					></Button>
				);
			},
		},
	];

	const showEditModalGallery = (record: any) => {
		const clone = JSON.parse(JSON.stringify(record));
		form.setFieldsValue({
			name: record.title,
			status: record.status == 1 ? true : false,
			description: record.description,
		});
		setFormVisibleGallery({ isShowModal: true, edfWrapper: clone });
	};

	const showModalGallery = () => {
		setFormVisibleGallery({
			...formVisibleGallery,
			edfIsCreate: true,
			isShowModal: true,
		});
	};

	const cancelModalGallery = () => {
		form.resetFields();
		setFormVisibleGallery({
			...formVisibleGallery,
			isShowModal: false,
			edfWrapper: {},
		});
	};

	const pictureIdsParam = async () => {
		let pictureIds: any = [];
		let error: any = null;
		if (
			formVisibleGallery.edfWrapper &&
			formVisibleGallery.edfWrapper.galleryPictures
		) {
			const dataImageNeedUpload =
				formVisibleGallery.edfWrapper.galleryPictures.filter(
					(x: any) => !x.id && x.checked == true
				);
			const dataPost = formVisibleGallery.edfWrapper.galleryPictures.filter(
				(x: any) => x.id && x.checked == true
			);
			if (dataImageNeedUpload.length > 0) {
				const files = dataImageNeedUpload.map((x: any) => x.file);
				const res: any = await UploadBaseService.uploadImage(files);
				if (res.succeeded) {
					const { data } = res;
					data.map((x: any) => {
						dataPost.push({ pictureId: x.id });
					});
				} else {
					error = res as IErrorResponse;
				}
			}
			pictureIds = dataPost.map((x: any) => x.pictureId);
		}
		return { pictureIds, error };
	};

	const handleOkGallery = () => {
		form
			.validateFields()
			.then(async (values) => {
				const { pictureIds, error } = await pictureIdsParam();
				if (error) {
					AlertHelper.showError(error);
					return;
				}
				const params: IAddGalleryRequest = {
					title: values.name.trim(),
					status: values.status ? 1 : 0,
					description: values.description,
					pictureIds,
				};
				const res = await addOrUpdateGallery(
					params,
					formVisibleGallery.edfIsCreate
				);
				if (!res.errors) {
					const error = res as IErrorResponse;
					AlertHelper.showError(error);
				} else {
					formVisibleGallery.edfIsCreate
						? AlertHelper.showSuccess(
								I18n.t(_t(translations.miniCMSSetting.addGallerySuccess))
						  )
						: AlertHelper.showSuccess(
								I18n.t(_t(translations.miniCMSSetting.updateGallerySuccess))
						  );

					cancelModalGallery();
					setTimeout(() => setReloadPage(!reloadPage), debounceTime);
				}
			})
			.catch((info) => {
				console.log(info);
			});
	};

	const addOrUpdateGallery = async (
		params: IAddGalleryRequest,
		isAdd: boolean
	) => {
		if (isAdd) {
			return await GalleryApiService.addGallery(params);
		} else {
			params.id = formVisibleGallery.edfWrapper.id;
			return await GalleryApiService.updateGallery(params);
		}
	};

	const goBack = () => {
		window.history.back();
	};

	const handleTableChange = (
		curPagination: any,
		_filters: any,
		_sorter: any
	) => {
		setTblPagination({
			...tblPagination,
			current: curPagination.current,
			pageSize: curPagination.pageSize,
		});
		setReloadPage(!reloadPage);
	};

	const handleSearchGallery = (value: any) => {
		setSearchText(value);
		setTblPagination({
			...tblPagination,
			current: 1,
		});
		setReloadPage(!reloadPage);
	};

	const debounceGalleryChange = debounce(
		(value) => handleSearchGallery(value),
		debounceTime
	);

	const handleOnChangeSearchGallery = (value: any) => {
		debounceGalleryChange(value);
	};

	const uploadImage = (options: any) => {
		const { file } = options;
		getBase64Image(file, (imageUrl) => {
			if (
				formVisibleGallery.edfIsCreate &&
				!formVisibleGallery.edfWrapper.galleryPictures
			) {
				let galleryPictures = [];
				galleryPictures.push({
					checked: true,
					picture: {
						imageUrl: imageUrl,
					},
					file,
				});
				formVisibleGallery.edfWrapper.galleryPictures = galleryPictures;
			} else {
				formVisibleGallery.edfWrapper?.galleryPictures?.push({
					checked: true,
					picture: {
						imageUrl: imageUrl,
					},
					file,
				});
			}

			setFormVisibleGallery({
				...formVisibleGallery,
				edfWrapper: formVisibleGallery.edfWrapper,
			});
		});
	};

	const handlePreview = (image: any) => {
		setPreview({
			previewVisible: true,
			previewImage: image,
			previewTitle: image.substring(image.lastIndexOf('/') + 1),
		});
	};

	const handleRemoveImage = (imageUrl: any) => {
		let index: number = formVisibleGallery.edfWrapper.galleryPictures.findIndex(
			(a: any) => a.picture.imageUrl == imageUrl
		);

		if (index != -1) {
			formVisibleGallery.edfWrapper?.galleryPictures.splice(index, 1);
		}
		setFormVisibleGallery({
			...formVisibleGallery,
			edfWrapper: formVisibleGallery.edfWrapper,
		});
	};

	const handleDeleteGallery = async () => {
		const res: any = await GalleryApiService.deleteGallery(
			formVisibleGallery.edfWrapper.id
		);
		if (res.succeeded) {
			AlertHelper.showSuccess(
				I18n.t(_t(translations.miniCMSSetting.deleteGallerySuccess))
			);
			cancelModalGallery();
			setTblPagination({ ...tblPagination, current: 1 });
			setTimeout(() => setReloadPage(!reloadPage), debounceTime);
		} else {
			const error = res as IErrorResponse;
			AlertHelper.showError(error);
		}
	};

	const ModalGallery = () => {
		return (
			<ModalHeper
				title={
					formVisibleGallery.edfIsCreate
						? I18n.t(_t(translations.miniCMSSetting.addGallery))
						: I18n.t(_t(translations.miniCMSSetting.editGallery))
				}
				visible={formVisibleGallery.isShowModal}
				onCancel={cancelModalGallery}
				onOk={handleOkGallery}
				onDelete={handleDeleteGallery}
				disableDelete={formVisibleGallery.edfWrapper.includeCatetogy}
				btnDelete={!formVisibleGallery.edfIsCreate}
			>
				<Form form={form} layout="vertical">
					{FormHelper.renderFormInput(
						'name',
						[
							{
								required: true,
								message: I18n.t(
									_t(translations.miniCMSSetting.messageGalleryName)
								),
							},
						],
						I18n.t(_t(translations.miniCMSSetting.galleryName)),
						'',
						{},
						{
							placeholder: I18n.t(
								_t(translations.placeHolder.enterGalleryName)
							),
							disabled: formVisibleGallery.edfWrapper.includeCatetogy,
							maxLength: 256,
						}
					)}
					{FormHelper.renderFormSwitch(
						'status',
						I18n.t(_t(translations.action)),
						true
					)}
					<Row style={{ display: 'none' }}>
						{FormHelper.renderFormInput(
							'description',
							[],
							'',
							undefined,
							{},
							{}
						)}
					</Row>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Text strong>
								{I18n.t(_t(translations.miniCMSSetting.textImage))}
							</Text>
						</Col>
						<Col span={8}>
							<Upload
								multiple
								name="avatar"
								className="avatar-uploader upload-custom"
								customRequest={uploadImage}
							>
								<Button icon={<UploadOutlined />}>
									{I18n.t(_t(translations.upload))}
								</Button>
							</Upload>
						</Col>
						<Col span={16}>
							<Text strong>
								{I18n.t(_t(translations.miniCMSSetting.bestViewImage))}
							</Text>
							<br />
							<Text strong>
								{I18n.t(_t(translations.miniCMSSetting.allowImage))}
							</Text>
						</Col>
						{formVisibleGallery.edfWrapper?.galleryPictures?.map((x: any) => (
							<Col span={8}>
								<Row>
									<Col span={24}>
										<img
											alt="example"
											style={{ width: '100%' }}
											src={x.picture.imageUrl}
											onClick={() => handlePreview(x.picture.imageUrl)}
										/>
										<DeleteOutlined
											className="close-icon"
											onClick={() => handleRemoveImage(x.picture.imageUrl)}
										/>
									</Col>
								</Row>
							</Col>
						))}
						<Modal
							visible={preview.previewVisible}
							title={preview.previewTitle}
							onCancel={() => setPreview({ ...preview, previewVisible: false })}
							footer={null}
						>
							<img
								alt="example"
								style={{ width: '100%' }}
								src={preview.previewImage}
							/>
						</Modal>
					</Row>
				</Form>
			</ModalHeper>
		);
	};

	return (
		<DetailPageLayout_V2
			title={I18n.t(_t(translations.miniCMSSetting.title))}
			positiveButtonName={I18n.t(_t(translations.save))}
			showDelete={true}
			isLoading={false}
			goBack={() => goBack()}
		>
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.miniCMSSetting.gallery))}
					description={I18n.t(_t(translations.miniCMSSetting.subGallery))}
				/>
				<Divider style={styles.divider} />
				<Row gutter={[16, 16]} className="rowSearch">
					<Col md={12}>
						<Input
							allowClear
							prefix={<SearchOutlined />}
							style={styles.maxWidth}
							placeholder={I18n.t(_t(translations.miniCMSSetting.search))}
							onChange={(e: any) =>
								handleOnChangeSearchGallery(e.target.value.toLowerCase())
							}
						/>
					</Col>
					<Col md={12}>
						<Space className="spaceBtnAddGallery">
							<Button onClick={showModalGallery} type="primary">
								{I18n.t(_t(translations.miniCMSSetting.addGallery))}
							</Button>
						</Space>
					</Col>
				</Row>
				<Table
					loading={isLoading}
					columns={galleryColumns}
					dataSource={galleryData}
					pagination={tblPagination}
					onChange={(curPagination, filters, sorter) =>
						handleTableChange(curPagination, filters, sorter)
					}
				/>
				{ModalGallery()}
			</Card>
		</DetailPageLayout_V2>
	);
}

export default GallerySetup;
