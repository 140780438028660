import { IService } from 'models';
import { IServiceRequest, IEditServiceRequest } from 'models/RequestModels';
import { BaseApiService } from 'services/BaseApiService';

class ServiceApi extends BaseApiService {
	public getAllService = () =>
		this.get<IService[]>(`/Service/get-all-services`, {
			params: {
				pageSize: 1000,
				pageNumber: 1,
			},
		});

	// public getServiceByBranch = () => this.get<IService[]>
	//   (`/Service/get-services-by-branch`);

	public getServiceById = (id: string) =>
		this.get<IService>(`/Service/get-service/${id}`);

	public addNewService = (addNewRequest: IServiceRequest) =>
		this.post<IServiceRequest, IService>(
			`/Service/create-service`,
			addNewRequest
		);

	public editService = (editServiceRequest: IEditServiceRequest) =>
		this.put<IEditServiceRequest, string>(
			`/Service/update-service`,
			editServiceRequest
		);

	public deleteService = (id: string) =>
		this.delete<string>(`/Service/delete-service/${id}`);
	public updateServicesIndexes = (
		services: { id: string; indexNumber: number }[]
	) =>
		this.put<
			{ indexNumbersRequest: { id: string; indexNumber: number }[] },
			IService[]
		>('/Service/update-list-indexnumber-service', {
			indexNumbersRequest: services,
		});
}

export default new ServiceApi();
