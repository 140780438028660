import { RightOutlined, UserOutlined } from '@ant-design/icons';
import { Typography, Space, Spin, Col, Collapse, Row, List } from 'antd';
import { Avatar } from 'components';
import { showError } from 'helpers/alertHelper';
import _ from 'lodash';
import { IApiResponse, IBooking, IBookingDetail, IEmployee } from 'models';
import { CSSProperties, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerBookingActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import EmployeeApiService from 'services/EmployeeApiService';

const { Panel } = Collapse;

type SelectStaffProps = {};

const primaryColor = '#87CEFA';
const secondaryColor = '#EEEDED';

const noStylist: Partial<IEmployee> = {
	firstName: 'No preference',
	id: 'Anyone',
	employeeTypes: [
		{
			id: '',
			roleName: 'Maximum available',
		},
	],
};

const SelectStaff = (props: SelectStaffProps) => {
	const dispatch = useDispatch();
	const stylists = useSelector(
		(state: RootState) => state.CustomerBookingReducer.stylists
	)!;
	useEffect(() => {
		if (_.isEmpty(stylists)) {
			getAllStylists();
		}
	}, []);

	const booking = useSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	)!;

	const isRequireStylist = useSelector(
		(state: RootState) =>
			state.BranchReducer.currentBranch?.technicianSelectionWhenBookingOnline
	)!;

	const bookingDetails = booking?.bookingDetails!;

	const [activeCollapse, setActiveCollapse] = useState<string[]>([
		bookingDetails![0].itemId!,
	]);

	const [firstChoose, setFirstChoose] = useState<boolean>(true);
	const [loading, setLoading] = useState(false);
	const onChangeCollapse = (key: string[]) => {
		setActiveCollapse(key);
	};

	const getAllStylists = async () => {
		setLoading(true);
		const response = await EmployeeApiService.getAllStylists();
		setLoading(false);
		if (!_.isEmpty(response.errors)) {
			showError(response.errors);
		}
		const responseData = response as IApiResponse<IEmployee[]>;
		if (responseData?.succeeded && !_.isEmpty(responseData.data)) {
			dispatch(
				CustomerBookingActions.getAllStylists.request(responseData.data!)
			);
		}
		setLoading(false);
	};

	const onChooseStylist = (
		stylist: Partial<IEmployee>,
		currentBookingDetail: Partial<IBookingDetail>,
		listStylistByIndex: Partial<IEmployee>[][],
		isRequireStylist?: boolean
	) => {
		let element: any = document.getElementById(`collapse`);
		let newBookingDetails = Array.from(bookingDetails);
		let elementId: string[] = [];
		let activeCollapseTemp: string[] = [];

		newBookingDetails = newBookingDetails?.map((bookingDetail) => {
			if (bookingDetail.itemId === currentBookingDetail.itemId) {
				bookingDetail = {
					...bookingDetail,
					stylistId: stylist.id,
				};
			}
			return bookingDetail;
		});

		if (firstChoose) {
			newBookingDetails = newBookingDetails?.map((bookingDetail, index) => {
				if (
					listStylistByIndex[index]
						.map((stylist) => stylist.id)
						.includes(stylist.id)
				) {
					const newBookingDetail = {
						...bookingDetail,
						stylistId: stylist.id,
					};
					return newBookingDetail;
				} else {
					const newBookingDetail = {
						...bookingDetail,
						stylistId: isRequireStylist ? undefined : 'Anyone',
					};
					activeCollapseTemp.push(bookingDetail.itemId!);
					elementId.push(bookingDetail.itemId!);
					return newBookingDetail;
				}
			});
			_.delay(() => {
				element = document.getElementById(`bookingDetail ${elementId[0]}`);
				if (element) {
					const topPos = element.getBoundingClientRect().top + window.scrollY;
					window.scrollTo({ left: 0, top: topPos, behavior: 'smooth' });
				}
			}, 1000);

			setFirstChoose(false);
			dispatch(
				CustomerBookingActions.updateBooking.request({
					...booking,
					bookingDetails: newBookingDetails,
				})
			);
			onChangeCollapse(activeCollapseTemp);
			return;
		}
		dispatch(
			CustomerBookingActions.updateBooking.request({
				...booking,
				bookingDetails: newBookingDetails,
			})
		);
		onChangeCollapse(
			activeCollapse.filter((key) => key !== currentBookingDetail.itemId)
		);
	};

	return (
		<div id="collapse">
			<Collapse
				onChange={(key) => onChangeCollapse(key as string[])}
				expandIcon={({ isActive }) => (
					<RightOutlined
						style={styles.collapseIcon}
						rotate={isActive ? 90 : 0}
					/>
				)}
				ghost
				expandIconPosition="right"
				activeKey={activeCollapse}
			>
				{booking.bookingDetails?.map(
					(bookingDetail: Partial<IBookingDetail>, index: number) => {
						return renderPanelService(
							bookingDetail,
							activeCollapse,
							onChooseStylist,
							stylists,
							booking,
							loading,
							isRequireStylist
						);
					}
				)}
			</Collapse>
		</div>
	);
};

const renderPanelService = (
	bookingDetail: Partial<IBookingDetail>,
	activeCollapse: string[],
	onChooseStylist: (
		stylist: Partial<IEmployee>,
		bookingDetail: Partial<IBookingDetail>,
		listStylistByIndex: Partial<IEmployee>[][],
		isRequireStylist: boolean
	) => void,
	stylists: Partial<IEmployee>[],
	booking: Partial<IBooking>,
	loading: boolean,
	isRequireStylist: boolean
) => {
	// const { bookingDetail, activeCollapse, onChooseStylist } = props;
	const stylistsCanDoService = isRequireStylist
		? stylists.filter(
				(stylist) =>
					stylist.serviceIds?.includes(bookingDetail.item?.id || '') &&
					stylist.canBookedOnline === true &&
					stylist.status !== 0
		  )
		: [noStylist].concat(
				stylists.filter(
					(stylist) =>
						stylist.serviceIds?.includes(bookingDetail.item?.id || '') &&
						stylist.canBookedOnline === true &&
						stylist.status !== 0
				)
		  );
	const listStylistByIndex: Partial<IEmployee>[][] = [];
	for (let index = 0; index < booking?.bookingDetails!.length; index++) {
		const stylistCanDoService = [noStylist].concat(
			stylists.filter((stylist) =>
				stylist.serviceIds?.includes(
					booking?.bookingDetails![index].item?.id || ''
				)
			)
		);
		listStylistByIndex.push(stylistCanDoService);
	}

	const StaffItem = (props: {
		onChoose: (stylist: Partial<IEmployee>) => void;
		stylist: Partial<IEmployee>;
	}) => {
		const stylistName = `${props.stylist.firstName || ''} ${
			props.stylist.lastName || ''
		}`;

		const avatarSrc =
			props.stylist.id !== 'Anyone' ? props.stylist.imageUrl : <UserOutlined />;

		const avatarBGColor =
			props.stylist.id !== 'Anyone' ? primaryColor : secondaryColor;

		return (
			<List.Item
				style={{ cursor: 'pointer' }}
				onClick={() => props.onChoose(props.stylist)}
			>
				<Row align="middle">
					<Space>
						<Avatar
							color={avatarBGColor}
							src={avatarSrc}
							size={'large'}
							name={stylistName}
						></Avatar>
						<Col>
							<Typography.Title level={5}>{stylistName}</Typography.Title>
							<Typography.Text type="secondary">
								{props.stylist?.employeeTypes![0].roleName}
							</Typography.Text>
						</Col>
					</Space>
				</Row>
			</List.Item>
		);
	};

	const PanelHeader = (props: {
		bookingDetail: Partial<IBookingDetail>;
		dataList: Partial<IEmployee>[];
	}) => {
		let isActive = activeCollapse.includes(props.bookingDetail?.itemId!);
		const isAnyone = props.bookingDetail.stylistId === 'Anyone';
		const stylist = props.dataList.find(
			(e) => e.id === props.bookingDetail.stylistId
		);
		const stylistName = `${stylist?.firstName || ''} ${
			stylist?.lastName || ''
		}`;

		const avatarSrc =
			!isAnyone && !_.isEmpty(props.bookingDetail.stylistId) ? (
				stylist?.imageUrl
			) : (
				<UserOutlined />
			);
		const avatarBGColor =
			!isAnyone && !_.isEmpty(props.bookingDetail.stylistId)
				? primaryColor
				: secondaryColor;

		return (
			<Space direction="vertical" style={styles.maxWidth}>
				<Col>
					<Typography.Title level={4}>
						{props.bookingDetail.item?.name}
					</Typography.Title>
				</Col>
				{!isActive ? (
					<Col>
						<Row align="middle">
							<Space>
								<Avatar
									color={avatarBGColor}
									src={avatarSrc}
									name={stylistName}
								></Avatar>
								<Typography.Text type="secondary">
									{stylistName}
								</Typography.Text>
							</Space>
						</Row>
					</Col>
				) : null}
			</Space>
		);
	};

	return (
		<Panel
			id={`bookingDetail ${bookingDetail.itemId}`}
			key={bookingDetail?.itemId!}
			header={
				<PanelHeader
					bookingDetail={bookingDetail}
					dataList={stylistsCanDoService}
				/>
			}
			style={styles.panel}
		>
			<Spin spinning={loading}>
				<List
					itemLayout="horizontal"
					dataSource={stylistsCanDoService}
					renderItem={(item) => {
						return (
							<StaffItem
								stylist={item}
								onChoose={(stylist) =>
									onChooseStylist(
										stylist,
										bookingDetail,
										listStylistByIndex,
										isRequireStylist
									)
								}
							/>
						);
					}}
					locale={{ emptyText: 'No stylist available' }}
				/>
			</Spin>
		</Panel>
	);
};

const styles = {
	maxWidth: {
		width: '100%',
	},
	panel: {
		margin: 0,
		marginBottom: 16,
		background: 'white',
		borderRadius: 5,
	},
	collapseIcon: {
		position: 'absolute',
		top: 25,
		fontSize: 18,
	} as CSSProperties,
};
export default SelectStaff;
