import { ArrowRightOutlined, CalendarOutlined } from '@ant-design/icons';
import { Row, Typography } from 'antd';
import Button from 'components/Button';
import moment, {
	Moment,
	MomentFormatSpecification,
	MomentBuiltinFormat,
} from 'moment';
import { useState } from 'react';
import { CalendarModal, TDateRange } from './CalendarModal';

interface IDateRagePickerProps {
	selectedDateRange?: TDateRange;
	onSelectDateRange?: (dateRange: TDateRange) => void;
	format?: MomentFormatSpecification;
	containerStyle?: StyleSheet;
	disabled?: boolean;
}

export const DateRangePicker = (props: IDateRagePickerProps) => {
	const { format = 'MM/DD/YYYY' } = props;
	const [showModal, setShowModal] = useState(false);
	const [dateRage, setDateRage] = useState(
		props.selectedDateRange || { fromDate: moment() }
	);

	const onSelectDateRange = (dateRage: TDateRange) => {
		setDateRage(dateRage);
		props.onSelectDateRange && props.onSelectDateRange(dateRage);
		setShowModal(false);
	};
	return (
		<>
			<Button
				style={{
					borderRadius: 20,
					minWidth: 250,
					maxWidth: 250,
					...props.containerStyle,
					justifyContent: 'flex-start',
					display: 'flex',
				}}
				onClick={() => setShowModal(true)}
				disabled={props.disabled}
			>
				{/* <CalendarOutlined style={{ marginRight: 30 }} /> */}

				<Typography.Text disabled={props.disabled}>
					{dateRage.fromDate
						? dateRage.fromDate.format(`${format}`)
						: moment().format(`${format}`)}
					<ArrowRightOutlined style={{ marginLeft: 20, marginRight: 20 }} />
				</Typography.Text>
				{dateRage.toDate && (
					<Typography.Text disabled={props.disabled} >
						{dateRage.toDate.format(`${format}`)}
					</Typography.Text>
				)}
			</Button>
			{showModal && (
				<CalendarModal
					rangeSelect
					selectedDateRange={dateRage}
					onSelectDateRange={(dateRange) => onSelectDateRange(dateRange)}
					onCancel={() => setShowModal(false)}
				/>
			)}
		</>
	);
};
