import {
	Col,
	Divider,
	Input,
	Spin,
	Typography,
	Button,
	Space,
	Row,
} from 'antd';
import { AlertHelper, CurrencyHelper } from 'helpers';
import { IApiResponse } from 'models';
import { IGiftCard } from 'models/IGiftCard';
import React from 'react';
import GiftCardApiService from 'services/GiftCardApiService';
import { translations, _t, I18n } from 'utils';
import CheckOutBaseModal from './CheckOutBaseModal';
import _ from 'lodash';

import styles from './styles';
import { removeSpecialCharacter } from 'helpers/StringHelper';
import { priceFixed } from 'helpers/currencyHelper';
import configureStore from 'redux/configuration/configureStore';
import { CheckoutActions } from 'redux/actions';

interface IPayGiftCardModalProps {
	onOk(giftCode: string, balance: number, money: number): void;
	onCancel(): void;
	amountNeedToPay: number;
}

interface IPayGiftCardModalState {
	isLoading: boolean;
	giftCard?: IGiftCard;
	code?: string;
	error?: string;
	money?: string;
}

export default class PayGiftCardModal extends React.Component<
	IPayGiftCardModalProps,
	IPayGiftCardModalState
> {
	constructor(props: IPayGiftCardModalProps) {
		super(props);
		this.state = {
			isLoading: false,
		};
	}
	inputCodeRef: any = React.createRef();
	moneyRef: any = React.createRef();
	render() {
		const isDisable =
			_.isEmpty(this.state.giftCard) ||
			this.state.giftCard?.balance === 0 ||
			_.isEmpty(this.state.money);

		return (
			<CheckOutBaseModal
				title={I18n.t(_t(translations.checkout.giftCard))}
				onOk={() => this.onOk()}
				disableOk={isDisable}
				onCancel={this.props.onCancel}
			>
				<Spin spinning={this.state.isLoading}>
					<Space direction="vertical" size="middle" style={styles.maxWidth}>
						<Col>
							<Typography.Title level={4}>
								{I18n.t(_t(translations.checkout.code))}
							</Typography.Title>

							<Row gutter={12} align="middle">
								<Col md={16}>
									<Input
										ref={this.inputCodeRef}
										style={styles.inputNumber}
										onPressEnter={() => this.onOk()}
										autoFocus={true}
										value={this.state.code}
										onChange={(event) => this.onChangeCode(event.target.value)}
									/>
								</Col>
								<Col span={4}>
									<Button
										type="primary"
										onClick={this.onCheckGiftCard}
										disabled={!this.state.code}
										style={{ minWidth: 0 }}
									>
										{I18n.t(_t(translations.checkout.check))}
									</Button>
								</Col>
								<Col span={4}>
									<Button
										onClick={() => {
											this.setState({
												code: undefined,
												giftCard: undefined,
											});
											this.inputCodeRef.current?.focus();
										}}
										danger
									>
										{I18n.t(_t(translations.clear))}
									</Button>
								</Col>
							</Row>
						</Col>
						{this.state.error ? (
							<Col span={24} style={styles.textRight}>
								<Typography.Text type="danger">
									{this.state.error}
								</Typography.Text>
							</Col>
						) : null}
						{this.state.giftCard && (
							<Col span={24} style={styles.textRight}>
								<Typography.Text>
									{I18n.t(_t(translations.checkout.balance))}
									{': '}
									{CurrencyHelper.formatBalance(this.state.giftCard?.balance)}
								</Typography.Text>
							</Col>
						)}
					</Space>
					<p />
					{this.renderMoneyInput()}
				</Spin>
			</CheckOutBaseModal>
		);
	}
	renderMoneyInput = () => {
		if (_.isEmpty(this.state.giftCard)) {
			return null;
		}
		return (
			<Space direction="vertical" size="middle" style={styles.maxWidth}>
				<Col>
					<Typography.Title level={4}>Input money</Typography.Title>

					<Row gutter={12} align="middle">
						<Col md={16}>
							<Input
								ref={this.moneyRef}
								type="number"
								style={styles.inputNumber}
								onPressEnter={this.onOk}
								value={this.state.money}
								onChange={(event) => this.onChangeMoney(event.target.value)}
							/>
						</Col>
						<Col span={4}>
							<Button
								disabled={false}
								onClick={() =>
									this.setState({
										money:
											this.props.amountNeedToPay < this.state.giftCard?.balance!
												? `${this.props.amountNeedToPay}`
												: `${this.state.giftCard?.balance!}`,
									})
								}
							>
								Exact
							</Button>
						</Col>
						<Col span={4}>
							<Button
								onClick={() => {
									this.setState({ money: undefined });
									this.moneyRef.current?.focus();
								}}
								danger
							>
								{I18n.t(_t(translations.clear))}
							</Button>
						</Col>
					</Row>
				</Col>
			</Space>
		);
	};
	onChangeMoney = (money: string) => {
		if ((this.state.giftCard?.balance || 0) > this.props.amountNeedToPay) {
			this.setState({
				money:
					Number(money) > this.props.amountNeedToPay
						? `${this.props.amountNeedToPay}`
						: money,
			});
		} else {
			this.setState({
				money:
					Number(money) > this.props.amountNeedToPay
						? `${this.state.giftCard?.balance}`
						: money,
			});
		}
	};
	onCheckGiftCard = async () => {
		this.setState({ error: undefined });
		if (this.state.code) {
			this.setState({ isLoading: true });
			const giftCardResponse = await GiftCardApiService.getGiftCardByCode(
				this.state.code
			);
			this.setState({ isLoading: false });
			if (!_.isEmpty(giftCardResponse.errors)) {
				this.setState({ error: I18n.t(_t(translations.text.error)) });
				return;
			}
			const giftCardSuccess = giftCardResponse as IApiResponse<IGiftCard>;
			if (_.isEmpty(giftCardSuccess.data)) {
				this.setState({
					error: I18n.t(_t(translations.checkout.giftCardNotFound)),
				});
				return;
			}

			if (giftCardSuccess.data?.status === 0) {
				this.setState({
					error: I18n.t(_t(translations.checkout.giftCardInactive)),
				});
				return;
			} else {
				this.setState({ giftCard: giftCardSuccess.data });
			}
		}
	};

	onChangeCode = (code: string) => {
		this.setState({ code: removeSpecialCharacter(code), giftCard: undefined });
	};

	onOk = () => {
		if (!this.state.giftCard) {
			return;
		}

		this.props.onOk(
			this.state.giftCard!.giftCode,
			this.state.giftCard.balance,
			priceFixed(Number(this.state.money))
		);
	};
}
