import { Col, Row } from 'antd';
import { SimpleModalListItem } from 'components';
import { kStyles } from 'GlobalStyles';
import ModalHelper from 'helpers/modalHelper';
import React from 'react';
import { I18n, translations, _t } from 'utils';
import { DATE_TYPE } from 'utils/Consts';

interface IDateTypePickerModalProps {
	isShowModal: boolean;
	onCancel: () => void;
	onChooseDateType: (type: typeof DATE_TYPE[0]) => void;
	selectedDateType?: typeof DATE_TYPE[0];
	showAll?: boolean;
}
const DateTypePickerModal = ({
	isShowModal,
	onCancel,
	onChooseDateType,
	selectedDateType,
	showAll,
}: IDateTypePickerModalProps) => {
	const isSelected = (id: string) => selectedDateType?.id === id;
	return (
		<ModalHelper
			title={I18n.t(_t(translations.expenses.chooseType))}
			onCancel={onCancel}
			onOk={() => null}
			visible={isShowModal}
			btnOkHidden
			width={'50%'}
		>
			<Col style={styles.modalContainer}>
				<Row gutter={[16, 16]}>
					{DATE_TYPE.map((element) => {
						return (
							<SimpleModalListItem
								title={element.title}
								onSelectItem={() => onChooseDateType(element)}
								isSelected={isSelected(element.id)}
								lg={24} md={24} sm={24} xs={24}
							/>
						);
					})}
				</Row>
			</Col>
		</ModalHelper>
	);
};

export const ALL = {
	id: '',
	code: 'ALL',
	description: I18n.t(_t(translations.expenses.all)),
};

const styles: kStyles = {
	modalContainer: {
		height: window.outerHeight * 0.6,
		width: '100%',
		overflow: 'scroll', //define overflow rules if you don't want the content to be flow out of screen
		overflowX: 'hidden',
	},
};

export default DateTypePickerModal;
