import React, { Component } from "react";
import {
  Form,
  // Button,
  Typography,
  Alert,
  Result,
  // Input
} from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { ValidationHelper } from "helpers";
import { Input, Button, IComponentProps } from "components";
import styles from "./styles";
import { I18n, _t, translations } from "utils";
import { IErrorResponse } from "models";
import { RootState } from "redux/configuration/rootReducer";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AuthenticationActions } from "redux/actions";
import { IResetPasswordRequest } from "models/RequestModels";
import qs from "qs";
const { Title } = Typography;

interface IResetPasswordQuery {
  token?: string;
}
interface IResetPasswordProps
  extends IComponentProps,
    ReturnType<typeof mapDispatchToProps>,
    ReturnType<typeof mapStateToProps> {}
interface IResetPasswordState {}

class ResetPassword extends Component<IResetPasswordProps, IResetPasswordState> {
  render() {
    return (
      <Form style={styles.nailForm} onFinish={(values) => this.onFinish(values)}>
        {this.getToken() ? (
          <>
            <Title level={3}>{I18n.t(_t(translations.resetPassword.title))}</Title>
            {this.renderError()}
            {this.renderInput()}
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={this.props.isLoading}>
                {I18n.t(_t(translations.resetPassword.resetPassword))}
              </Button>
            </Form.Item>
          </>
        ) : (
          this.renderInvalidUrl()
        )}
      </Form>
    );
  }

  getToken(): string | undefined {
    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }) as IResetPasswordQuery;
    return queries.token;
  }

  // onResetPasswordClick = async () => {
  //   const { email, newPassword, confirmPassword, token } = this.state;
  //   this.props.clearError();
  //   if (this.validate()) {
  //     this.props.resetPassword({
  //       email: email,
  //       password: newPassword,
  //       confirmPassword: confirmPassword,
  //       token: token,
  //     });
  //   }
  // };

  renderInput() {
    return (
      <>
        <Form.Item
          name="email"
          rules={[
            {
              validator: (_, value: string) => {
                if (!value) {
                  return Promise.reject(new Error(I18n.t(_t(translations.resetPassword.emailMessage))));
                } else if (ValidationHelper.validateEmail(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(I18n.t(_t(translations.resetPassword.emailFormatMessage))));
              },
            },
          ]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            type="email"
            placeholder={I18n.t(_t(translations.email))}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: I18n.t(_t(translations.resetPassword.newPasswordMessage)),
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={I18n.t(_t(translations.resetPassword.newPassword))}
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: I18n.t(_t(translations.resetPassword.confirmPasswordMessage)),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(I18n.t(_t(translations.resetPassword.confirmNotMatch))));
              },
            }),
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={I18n.t(_t(translations.resetPassword.confirmPassword))}
          />
        </Form.Item>
      </>
    );
  }

  renderError() {
    return this.props.error && this.props.currentAction === AuthenticationActions.resetPassword.failedName ? (
      <Alert
        style={styles.alert}
        message={this.props.error.Text}
        type="error"
        showIcon
        closable
        onClose={() => this.props.clearError()}
      />
    ) : null;
  }

  renderInvalidUrl() {
    return (
      <Result
        status="warning"
        title={I18n.t(_t(translations.resetPassword.urlNotValid))}
        extra={
          <Button onClick={() => this.props.history.push("/login")}>
            {I18n.t(_t(translations.resetPassword.goLogin))}
          </Button>
        }
      />
    );
  }

  onFinish(values: IResetPasswordRequest) {
    this.props.resetPassword({
      email: values.email,
      password: values.password,
      confirmPassword: values.confirmPassword,
      token: this.getToken()?.replaceAll(" ", "+") || "",
    });
  }
}

const mapStateToProps = (state: RootState) => ({
  error: state.ErrorReducer.error?.message[0],
  isLoading: state.AppConfigReducer.showLoading,
  currentAction: state.ReduxActionReducer["AUTHENTICATION"],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchError: (error: IErrorResponse) => {
    dispatch(AuthenticationActions.resetPassword.failed(error));
  },
  resetPassword: (request: IResetPasswordRequest) => {
    dispatch(AuthenticationActions.resetPassword.request(request));
  },
  clearError: () => {
    dispatch(AuthenticationActions.clearError.request());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
