import {
	Button,
	Col,
	Card,
	Divider,
	Result,
	Row,
	Descriptions,
	Space,
	Typography,
} from 'antd';
import React from 'react';
import CustomerInfo from 'components/CustomerInfo';
import { price } from 'redux/selectors/checkout';

import {
	IApiResponse,
	IBooking,
	IBookingDetail,
	ICustomer,
	IEmployee,
} from 'models';
import { Colors, I18n, translations, _t } from 'utils';
import _ from 'lodash';
import {
	FileDoneOutlined,
	PrinterOutlined,
	CloseOutlined,
} from '@ant-design/icons';
import {
	DiscounterType,
	DiscountType,
	ModalType,
	PaymentType,
	PriceType,
	PromotionCode,
	TipType,
} from 'utils/Consts';
import { ITip } from 'models/ITip';
import { Dispatch } from '@reduxjs/toolkit';
import Title from 'antd/lib/typography/Title';
import { formatBalance, priceFixed } from 'helpers/currencyHelper';
import CustomerBooking from 'pages/Booking/CustomerBooking';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import { IPaymentType } from 'models/ITransaction';
import { AlertHelper, CurrencyHelper } from 'helpers';
import { IEmployeeTip } from 'models/IEmployeeTip';
import { CheckoutActions } from 'redux/actions';
import '../styles.css';
import { IPromotion } from 'models/IPromotion';
import { IBranch } from 'models/IBranch';
import TipApiService from 'services/TipApiService';
import { showError } from 'helpers/alertHelper';
interface IPaymentProps
	extends ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps> {
	selectedCustomer: Partial<ICustomer>;
	currentBooking: IBooking;
	getModalByType(paymentType: number): void;
	tip: ITip;
	discountPercent: number;
	paymentType: number[];
	canPrintBill: boolean;
	canComplete: boolean;
	onPrint(isDraft: boolean): void;
	onComplete(): void;
	isDone?: boolean;
	cashReceived: number;
	cardCharge?: number;
	cash?: number;
	canUpdate: boolean;
	isSaleGiftCard?: boolean;
	moneyChangeBack: number;
	onRemovePayment(): void;
	listPaymentType: Partial<IPaymentType>[];
	onAddDiscount(
		discount: number,
		discountPercent: number,
		discounterType: DiscounterType,
		discountType: DiscountType
	): void;
	listStylistAddedTip: string[];
	employees?: IEmployee[];
	amountNeedToPay: number;
	onRemovePromotion: () => void;
	listAllBookingDetails: Partial<IBookingDetail>[];
	onChangeCashReceived: (tipAmount: number) => void;
}
interface IPaymentStates {}
class Payment extends React.Component<IPaymentProps, IPaymentStates> {
	render() {
		return (
			<Col style={styles.colContainer} md={6}>
				{this.props.isSaleGiftCard ? (
					<CustomerBooking
						isCheckout
						isSaleGiftCard={this.props.isSaleGiftCard}
					/>
				) : (
					<>
						<CustomerInfo customer={this.props.selectedCustomer} />
						<Divider style={styles.divider} />
					</>
				)}
				{this.renderContent()}
			</Col>
		);
	}

	renderContent = () => {
		if (_.isEmpty(this.props.selectedCustomer)) {
			return null;
		}
		return (
			<>
				{this.props.isDone ? (
					<Result
						status="success"
						title={I18n.t(_t(translations.checkout.checkoutSuccess))}
						subTitle={I18n.t(_t(translations.checkout.checkoutSuccessSub))}
					/>
				) : (
					<>{this.renderPaymentInformation()}</>
				)}
				{this.renderInvoiced()}

				{this.renderFooterButton()}
			</>
		);
	};

	renderRequireMessage() {
		if (!this.checkHasStylist()) {
			return null;
		}
		return (
			<Typography.Text type={'danger'} strong>
				All Booking must be assigned to stylist before checkout
			</Typography.Text>
		);
	}

	renderInvoiced = () => {
		return (
			<Row
				justify="end"
				align="bottom"
				style={styles.invoicedContainer}
				// className="scrollPaymentInfo"
			>
				<Col md={24} lg={24}>
					{this.renderRequireMessage()}
					{this.renderSubTotal()}
					{this.props.discountBeforeTax ? this.renderDiscount() : null}
					{this.props.discountBeforeTax ? this.renderPromotion() : null}
					{this.renderTax()}
					{!this.props.discountBeforeTax ? this.renderDiscount() : null}
					{!this.props.discountBeforeTax ? this.renderPromotion() : null}
					{this.renderGiftCards()}
					{this.renderCardChargeOfInvoice()}
					{this.renderTipAuto()}
					{this.renderListTipManually()}
					{this.renderBalance()}
					<Divider style={styles.divider} />
					{this.renderMoneyPaid()}
					{this.renderAmountNeedToPay()}
					{this.renderMoneyChangeBack()}
				</Col>
			</Row>
		);
	};
	renderDiscount() {
		const discountByServices = this.props.listAllBookingDetails.map(
			(bookingDetail) => bookingDetail.discount === 0
		);

		return (this.props.discount && this.props.discount !== 0) ||
			discountByServices?.includes(false) ? (
			<>
				<Row justify="space-between" align="middle">
					<Col span={12}>
						<Title level={5}>
							{I18n.t(_t(translations.checkout.discount))}
						</Title>
					</Col>
					<Col span={6}>
						{discountByServices?.includes(false) ? null : (
							<Title style={styles.textCenter} level={5}>
								{this.props.discountPercent}%
							</Title>
						)}
					</Col>
					<Col span={6}>
						<Title style={styles.textRight} level={5}>
							{discountByServices?.includes(false)
								? CurrencyHelper.formatBalance(
										_.sumBy(
											this.props.listAllBookingDetails,
											(x) => x.discount || 0
										)
								  )
								: CurrencyHelper.formatBalance(this.props.discount)}
						</Title>
					</Col>
					<CloseOutlined
						onClick={() => {
							this.props.onAddDiscount(0, 0, 0, DiscountType.PERCENT);
						}}
						style={{
							...styles.icClose,
							marginBottom: 8,
							display: this.checkShowDeleteDiscount(),
						}}
					/>
				</Row>
				<Divider style={styles.divider} />
			</>
		) : null;
	}

	renderItemTip = (employeeTip: IEmployeeTip) => {
		const employee = this.props.employees?.find(
			(e) => e.id === employeeTip.employeeId
		);
		return (
			<Row key={employeeTip.employeeId} justify="space-between" align="middle">
				<Title level={5}>
					{I18n.t(_t(translations.checkout.tipFor))}{' '}
					{`${employee?.firstName} ${employee?.lastName}`}
				</Title>
				<Title level={5} style={styles.textInline}>
					{CurrencyHelper.formatPrice(employeeTip.tipAmount)}
				</Title>
				<CloseOutlined
					onClick={() => this.onDeleteItemTip(employeeTip)}
					style={styles.icClose}
				/>
			</Row>
		);
	};
	renderListTipManually() {
		if (
			_.isEmpty(this.props.tip) ||
			_.isEmpty(this.props.tip?.tips) ||
			this.props.tipType !== TipType.MANUALLY
		) {
			return null;
		}
		return this.props.tip?.tips!.map(this.renderItemTip);
	}
	renderBalance() {
		return (
			<Row justify="space-between" align="middle">
				<Title level={4}>{I18n.t(_t(translations.checkout.totalPay))}</Title>
				<Title level={4} style={styles.textInline}>
					{CurrencyHelper.formatBalance(this.props.price(PriceType.BALANCE))}
				</Title>
			</Row>
		);
	}
	renderTipAuto() {
		if (
			_.isEmpty(this.props.tip) ||
			this.props.tipType === TipType.MANUALLY ||
			this.props.tip.totalTip === 0
		) {
			return null;
		}
		return (
			<Row justify="space-between" align="middle">
				<Title level={5}>{I18n.t(_t(translations.checkout.tip))}</Title>
				<Title level={5} style={styles.textInline}>
					{CurrencyHelper.formatPrice(this.props.tip?.totalTip)}
				</Title>
				<CloseOutlined
					onClick={() => {
						if (this.props.isDone) {
							this.props.tip.tips?.forEach((x) => {
								this.deleteTip(x.id || '');
							});
						}
						const tip: ITip = {
							totalTip: 0,
						};
						this.props.deleteTip(
							tip,
							this.props.tip?.totalTip || 0,
							this.props.currentBranch!,
							this.props.amountNeedToPay
						);
						this.props.amountNeedToPay <= 0 &&
							this.props.onChangeCashReceived(this.props.tip.totalTip || 0);
					}}
					style={{ ...styles.icClose, marginBottom: 0 }}
				/>
			</Row>
		);
	}

	renderGiftCards() {
		if (
			this.props.price(PriceType.GIFT_CARD) === 0 ||
			this.props.isSaleGiftCard
		) {
			return null;
		}
		return (
			<>
				<Row justify="space-between" align="middle">
					<Title level={5}>
						{I18n.t(_t(translations.checkout.totalGiftCard))}
					</Title>
					<Title level={5} style={styles.textInline}>
						{CurrencyHelper.formatPrice(this.props.price(PriceType.GIFT_CARD))}
					</Title>
				</Row>
				<Divider style={styles.divider} />
			</>
		);
	}

	renderCardChargeOfInvoice() {
		if (this.props.cardCharge) {
			return (
				<>
					<Row justify="space-between" align="middle">
						<Title level={5}>
							{I18n.t(_t(translations.checkout.cardCharge))}
						</Title>
						<Title level={5} style={styles.textInline}>
							{CurrencyHelper.formatPrice(this.props.cardCharge)}
						</Title>
					</Row>
					<Divider style={styles.divider} />
				</>
			);
		}
		return null;
	}
	renderTax() {
		if (this.props.isSaleGiftCard) {
			return null;
		}
		return (
			<>
				{this.renderMoney(
					`${I18n.t(_t(translations.checkout.tax))} (${
						this.props.taxPercent
					}%)`,
					CurrencyHelper.formatPrice(this.props.price(PriceType.TAX))
				)}
				<Divider style={styles.divider} />
			</>
		);
	}

	renderSubTotal() {
		if (this.props.isSaleGiftCard) {
			return null;
		}
		return (
			<>
				{this.renderMoney(
					I18n.t(_t(translations.checkout.subtotal)),
					CurrencyHelper.formatPrice(this.props.price(PriceType.SUB_TOTAL))
				)}
			</>
		);
	}
	renderMoney(title: string, value: string) {
		return (
			<Descriptions
				style={{
					width: '100%',
					alignContent: 'baseline',
					alignItems: 'baseline',
				}}
				labelStyle={{ fontSize: 14 }}
				contentStyle={{
					justifyContent: 'flex-end',
					alignContent: 'baseline',
					fontSize: 18,
					fontWeight: 'bold',
				}}
				column={1}
			>
				<Descriptions.Item style={{ paddingBottom: 1 }} label={title}>
					{value}
				</Descriptions.Item>
			</Descriptions>
		);
	}

	renderPromotion = () => {
		console.log('this.props.promotion', this.props.promotion);
		const promotion = this.props.promotion;

		if (
			_.isEmpty(promotion) &&
			!this.isExistPromotion()
			// ||
			// promotion?.promotionType.code !== PromotionCode.TOTAL_BOOKING
		) {
			return null;
		}
		const discountPercent = promotion?.discountPercentage;

		const discountByPercent =
			((discountPercent || 0) * this.props.currentBooking.totalAmount) / 100;

		let listAllBookingDetails = Array.from(
			this.props.currentBooking.bookingDetails || []
		);
		if (!_.isEmpty(this.props.listBookingCombined)) {
			this.props.listBookingCombined.forEach((booking) => {
				booking.bookingDetails.map((bookingDetail) =>
					listAllBookingDetails.push(bookingDetail)
				);
			});
		}

		return (
			<>
				<Col>
					<Title level={5}>
						Promotion: {promotion?.name}{' '}
						{!_.isEmpty(promotion?.coupons)
							? `( ${promotion?.coupons![0].couponCode} )`
							: ''}
					</Title>
					<Row justify="space-between" align="middle">
						<Col span={12}>
							<Title level={5}>
								{I18n.t(_t(translations.checkout.discount))}
							</Title>
						</Col>
						{/* <Col span={6}>
							{discountPercent && discountPercent !== 0 ? (
								<Title style={styles.textCenter} level={5}>
									{discountPercent}%
								</Title>
							) : null}
						</Col> */}
						<Col span={6}>
							<Title style={styles.textRight} level={5}>
								{CurrencyHelper.formatBalance(
									_.sumBy(
										listAllBookingDetails,
										(x) => x?.promotionDiscount || 0
									)
									// promotion?.discountAmount !== 0
									// 	? promotion?.discountAmount
									// 	: discountByPercent
								)}
							</Title>
						</Col>
					</Row>
					<CloseOutlined
						onClick={() => this.props.onRemovePromotion()}
						style={{ ...styles.icClose, top: 16 }}
					/>
				</Col>
				<Divider />
			</>
		);
	};

	renderItemInfo(title: string, value: string) {
		return (
			<Descriptions
				style={{
					width: '100%',
					alignContent: 'baseline',
					alignItems: 'baseline',
				}}
				labelStyle={{ fontSize: 14 }}
				contentStyle={{
					justifyContent: 'flex-end',
					alignContent: 'baseline',
					fontSize: 14,
					fontWeight: 600,
				}}
				column={1}
			>
				<Descriptions.Item style={{ paddingBottom: 1 }} label={title}>
					{value}
				</Descriptions.Item>
			</Descriptions>
		);
	}
	renderPaymentInformation = () => {
		const { paymentType, listPaymentType } = this.props;
		return (
			<Col
				style={{
					maxHeight: '35vh',
				}}
				className="scrollPaymentInfo"
			>
				{!_.isEmpty(paymentType) && (
					<Card
						title={
							<Title level={5} style={{ textAlign: 'center' }}>
								{I18n.t(_t(translations.checkout.paymentInfo))}
							</Title>
						}
						bordered={false}
						bodyStyle={{ padding: 0 }}
					>
						<Row style={{ width: '100%' }}>
							{listPaymentType.map((x, i) => {
								return (
									<>
										<Col
											lg={24}
											style={{
												padding: '8px 0',
											}}
										>
											<Row
												style={{
													height: '100%',
												}}
											>
												<Col span={21} style={{}}>
													{this.renderPaymentType(x.paymentType!, x.appName)}
													{this.renderAmount(x.paymentType!, x.amount || 0)}
													{this.renderAmountReceived(
														x.paymentType!,
														this.props.cashReceived
													)}
													{this.renderGiftCode(x.giftCode)}
													{this.renderGiftBalance(x.paymentType!, x.amount)}
													{this.renderCardCharge(x.paymentType!)}
													{this.renderReference(
														x.paymentType!,
														x.transactionReferenceNumber || ''
													)}
												</Col>
												<Col
													style={{
														color: 'red',
														justifyItems: 'center',
														textAlign: 'center',
													}}
													span={3}
												>
													<Button
														type="text"
														disabled={this.props.isDone}
														icon={<CloseOutlined style={{ fontSize: 25 }} />}
														style={{
															color: 'red',
															height: '100%',
															width: '90%',
														}}
														onClick={() =>
															this.props.deletePaymentType(
																x.paymentType!,
																x.giftCode
															)
														}
													/>
												</Col>
											</Row>
										</Col>
										{listPaymentType.length - 1 !== i && (
											<Divider style={styles.divider} />
										)}
									</>
								);
							})}
						</Row>
					</Card>
				)}
				<Divider />
			</Col>
		);
	};
	renderGiftBalance = (paymentType: number, amount?: number) => {
		if (paymentType !== PaymentType.GIFT_CARD) {
			return null;
		}
		return this.renderItemInfo('Gift Card Pay', `${formatBalance(amount)}`);
	};
	renderGiftCode = (giftCode?: string) => {
		return !_.isEmpty(giftCode) && this.renderItemInfo('Gift Code', giftCode!);
	};
	renderPaymentType = (index: number, appName?: string) => {
		return this.renderItemInfo(
			I18n.t(_t(translations.checkout.paymentType)),
			`${this.getPaymentName(index, appName)}`
		);
	};
	renderAmount = (paymentType: number, amount: number) => {
		// if (paymentType === PaymentType.CREDIT_CARD || paymentType === PaymentType.APP || paymentType === PaymentType.OTHER  ) {
		return this.renderItemInfo('Amount', `${formatBalance(amount)}`);
		// }
	};
	renderAmountReceived = (paymentType: number, amount: number) => {
		if (paymentType === PaymentType.CASH) {
			return this.renderItemInfo(
				I18n.t(_t(translations.checkout.amountReceived)),
				`${formatBalance(amount)}`
			);
		}
	};

	renderCardCharge = (paymentType: number) => {
		return (
			paymentType === PaymentType.CREDIT_CARD &&
			this.renderItemInfo(
				I18n.t(_t(translations.checkout.cardCharge)),
				`${formatBalance(this.props.cardCharge)}`
			)
		);
	};
	renderMoneyPaid = () => {
		const paid =
			_.sumBy(this.props.listPaymentType, (x) =>
				x.paymentType !== PaymentType.CASH ? x.amount || 0 : 0
			) + this.props.cashReceived;
		return (
			<Row justify="space-between" align="middle">
				<Title level={5}>Paid</Title>
				<Title level={5} style={styles.textInline}>
					{CurrencyHelper.formatBalance(paid)}
				</Title>
			</Row>
		);
	};
	renderMoneyChangeBack = () => {
		const paid =
			_.sumBy(this.props.listPaymentType, (x) =>
				x.paymentType !== PaymentType.CASH ? x.amount || 0 : 0
			) + this.props.cashReceived;
		const moneyChangeBack = priceFixed(
			paid - priceFixed(this.props.price(PriceType.BALANCE)!)
		);

		if (
			moneyChangeBack <= 0 ||
			_.sumBy(
				this.props.listPaymentType
					// .filter((x) => x.paymentType === PaymentType.CASH || x.paymentType === PaymentType.GIFT_CARD)
					.map((x) => x.amount)
			) < this.props.price(PriceType.BALANCE)! ||
			!this.props.paymentType.includes(PaymentType.CASH)
		) {
			return null;
		}
		return (
			<Row justify="space-between" align="middle">
				<Title level={5}>{I18n.t(_t(translations.checkout.change))}</Title>
				<Title level={5} style={styles.textInline}>
					{CurrencyHelper.formatBalance(moneyChangeBack)}
				</Title>
			</Row>
		);
	};
	renderReference = (paymentType: number, reference: string) => {
		return (
			(paymentType === PaymentType.CREDIT_CARD ||
				paymentType === PaymentType.APP ||
				paymentType === PaymentType.OTHER) &&
			this.renderItemInfo(
				I18n.t(
					_t(
						paymentType === PaymentType.OTHER
							? translations.checkout.otherReference
							: translations.checkout.reference
					)
				),
				reference
			)
		);
	};
	renderAmountNeedToPay = () => {
		return (
			<Row justify="space-between" align="middle">
				<Title level={5}>
					{I18n.t(_t(translations.checkout.amountNeedToPay))}
				</Title>
				<Title level={5} style={styles.textInline}>
					{CurrencyHelper.formatBalance(this.props.amountNeedToPay)}
				</Title>
			</Row>
		);
	};

	deleteTip = async (tipId: string) => {
		const res = (await TipApiService.deleteTip(tipId)) as IApiResponse<string>;
		if (!res.succeeded) {
			showError(res.errors);
			return;
		}
	};

	getPaymentName = (paymentType: number, appName?: string) => {
		switch (paymentType) {
			case PaymentType.CASH:
				return I18n.t(_t(translations.checkout.cash));
			case PaymentType.GIFT_CARD:
				return I18n.t(_t(translations.checkout.giftCard));
			case PaymentType.CREDIT_CARD:
				return I18n.t(_t(translations.checkout.creditCard));
			case PaymentType.APP:
				return `App ${appName}`;
			case PaymentType.OTHER:
				return appName || '';
			default:
				return '';
		}
	};

	renderFooterButton = () => {
		return (
			<Row
				gutter={[16, 16]}
				align="bottom"
				style={{ position: 'absolute', bottom: 16, width: '98%' }}
			>
				<Col sm={24} md={12}>
					<Button
						onClick={() => this.props.onPrint(!this.props.canPrintBill)}
						// type="primary"
						// size="large"
						icon={<PrinterOutlined />}
						style={{
							...styles.maxWidth,
							backgroundColor: '#248232',
							color: 'white',
						}}
					>
						{I18n.t(
							_t(
								this.props.canPrintBill
									? translations.checkout.reprintBill
									: translations.checkout.draftBill
							)
						)}
					</Button>
				</Col>
				<Col sm={24} md={12}>
					<Button
						type="primary"
						onClick={() => this.props.onComplete()}
						disabled={!this.props.canUpdate ? !this.props.canComplete : true}
						// size="large"
						icon={<FileDoneOutlined />}
						style={styles.maxWidth}
					>
						{I18n.t(_t(translations.checkout.complete))}
					</Button>
				</Col>
			</Row>
		);
	};
	// onComplete = () => {
	// 	const noStylist = this.checkHasStylist()
	// 	if (noStylist){
	// 		AlertHelper.showError({

	// 		})
	// 	}
	// }

	isExistPromotion = () => {
		return this.props.currentBooking?.bookingDetails
			?.map((x) => x.promotionDiscount === 0)
			.includes(false)
			? true
			: false;
	};

	checkShowDeleteDiscount = () => {
		const discountByServices = this.props.listAllBookingDetails.map(
			(bookingDetail) => bookingDetail.discount === 0
		);

		return discountByServices?.includes(false) ? 'none' : 'unset';
	};
	onDeleteItemTip = (employeeTip: IEmployeeTip) => {
		if (this.props.isDone) {
			this.deleteTip(employeeTip.id || '');
		}
		let tip = Object.assign({}, this.props.tip);
		const listStylistAddedTip = Array.from(this.props.listStylistAddedTip);
		if (tip) {
			tip.tips = tip.tips?.filter(
				(e) => e.employeeId !== employeeTip.employeeId
			);
			this.props.deleteTip(
				tip,
				employeeTip.tipAmount,
				this.props.currentBranch!,
				this.props.amountNeedToPay
			);
			this.setState({
				listStylistAddedTip: _.filter(
					listStylistAddedTip,
					(id) => id !== employeeTip.employeeId
				),
			});
			this.props.amountNeedToPay <= 0 &&
				this.props.onChangeCashReceived(employeeTip.tipAmount);
		}
	};
	checkHasStylist = () => {
		let noStylist = false;
		for (const booking of this.props.listBookingCombined) {
			if (noStylist) {
				break;
			}
			for (const bookingDetail of booking.bookingDetails) {
				if (_.isEmpty(bookingDetail.stylistId)) {
					noStylist = true;
					break;
				}
			}
		}
		return noStylist;
	};
}

const mapStateToProps = (state: RootState) => ({
	price: (priceType: PriceType) => price(state, priceType),
	discount: state.CheckoutReducer.discount,
	taxPercent: state.BranchReducer.currentBranch?.taxPercent,
	listBookingCombined: state.CheckoutReducer.listBookingCombine,
	promotion: state.CheckoutReducer.promotion,
	discountBeforeTax: state.BranchReducer.currentBranch?.discountBeforeTax,
	currentBranch: state.BranchReducer.currentBranch,
	tipType: state.CheckoutReducer.tipType,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	deletePaymentType: (paymentType: number, giftCode?: string) => {
		dispatch(
			CheckoutActions.deletePaymentType.request({ paymentType, giftCode })
		);
	},
	deleteTip: (
		tip: ITip,
		tipValue: number,
		branch: IBranch,
		amountNeedToPay: number
	) => {
		dispatch(
			CheckoutActions.deleteTipLocal.request({
				tip,
				tipValue,
				branch,
				amountNeedToPay,
			})
		);
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);

const styles = {
	colContainer: {
		backgroundColor: 'white',
		padding: 16,
		overflow: 'hidden',
		height: 'calc(100vh - 64px)',
	} as React.CSSProperties,
	maxWidth: { width: '100%' },
	divider: { width: '100%', margin: '8px 0' },

	textInline: { display: 'contents' },

	invoicedContainer: {
		padding: '0 40px 0px 8px',
		background: '#F7F7F8',
		position: 'absolute',
		// maxHeight: '30vh',
		bottom: 68,
	} as React.CSSProperties,
	textCenter: { textAlign: 'center' } as React.CSSProperties,
	textRight: { textAlign: 'right' } as React.CSSProperties,
	icClose: {
		color: 'red',
		fontSize: 20,
		cursor: 'pointer',
		right: -25,
		position: 'absolute',
	} as React.CSSProperties,
};
