import GlobalStyles, { kStyles } from "GlobalStyles";

const styles: kStyles = {
  layout: {
    minHeight: "100vh",
  },
  formContainer: { width: "100%", display: "contents", marginTop: 10, minHeight: "100vh" },
  headerContainer: {
    zIndex: 9999,
    width: "100%",
    backgroundColor: "white",
    position: "fixed",
    ...GlobalStyles.boxShadow,
  },
  rowHeader: {
    paddingInline: 16,
    alignItems: "center",
    paddingTop: 16,
    paddingBottom: 16,
  },
  icClose: {
    fontSize: 18,
    zIndex: 1,
  },
  headerTitle: {
    textAlign: "center",
    zIndex: 9999,
    marginBottom: "auto",
  },
  buttonContainer: { textAlign: "right", marginBottom: 10 },
  // rowForm: { paddingTop: "120px" }
  spinLayout: {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  spin: { margin: "0 auto" },
  divider: {
    margin: "16px 0",
  },
  avatar: {
    borderRadius: 10
  },
  maxWidthSpace: {
    width: "100%",
  },
  noPaddingCol:{
    paddingInline: 0,
    width: "100%"
  },
  maxWidth:{ width: "100%"},
  employeeTypeButton: { background: "#323dad", color: "white" }
};

export default styles;
