import React from 'react';
import {
	Typography,
	Button,
	Layout,
	Space,
	Col,
	Row,
	Form,
	Divider,
	Checkbox,
	Spin,
	Card,
	FormInstance,
} from 'antd';
import { DetailPageLayout, ImageUpload, Modal } from 'components';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { I18n, _t, translations } from 'utils';
import { Dispatch } from 'redux';
import styles from './styles';
import { isEditingService } from 'redux/selectors/service';
import {
	CategoryActions,
	EmployeeActions,
	ErrorActions,
	ServiceActions,
} from 'redux/actions';
import { history } from 'routers';
import { IItemCategory } from 'models/IItemCategory';
import { IEmployee, IService } from 'models';
import _ from 'lodash';
import { AlertHelper, FormHelper, TimeHelper } from 'helpers';
import formHelper from 'helpers/formHelper';
import { IEditServiceRequest, IServiceRequest } from 'models/RequestModels';
import moment from 'moment';
import AppAvatar from 'components/Avatar';

const { Title, Text } = Typography;

const LIST_PRICE_TYPE = [
	{
		id: '1',
		type: 'From',
	},
	{
		id: '2',
		type: 'Fixed',
	},
];
const LIST_TAX = [
	{
		id: '1',
		tax: 'No Tax',
	},
	{
		id: '2',
		tax: 'Default: Columbus (7.5%)',
	},
	{
		id: '3',
		tax: 'Columbus (7.5%)',
	},
	{
		id: '4',
		tax: 'Ohio Tax (7.5%)',
	},
];

interface ParamsProps {
	id: string;
}
interface StateProps {
	itemCategoryId: string;
}
interface IProps
	extends RouteComponentProps<ParamsProps, any, StateProps>,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}
interface IStates {
	// checkedList: string[];
	// showAllStaff: boolean;
	showServiceDeleteModal: boolean;
	isRequiredSupply: boolean;
	imageFiles?: (File | undefined)[];
	isHaveInitialImageUrl?: boolean;
}

class ServiceDetailPage extends React.Component<IProps, IStates> {
	formRef: FormInstance | undefined;

	state = {
		// checkedList: !this.isAddNew()
		// 	? this.props.currentService?.employeeIds!
		// 	: [],
		// showAllStaff: false,
		showServiceDeleteModal: false,
		isRequiredSupply: false,
		imageFiles: undefined,
		isHaveInitialImageUrl: !_.isEmpty(this.props.currentService?.imageUrl),
	};

	componentDidMount() {
		if (this.props.match.params.id && !this.props.currentService) {
			this.props.getServiceById(this.props.match.params.id);
		}
		this.props.getAllCategory();
		this.props.getAllEmployees();
	}

	componentWillUnmount() {
		this.props.resetService();
	}

	componentDidUpdate(prevProp: IProps, prevState: IStates) {
		const { currentAction } = this.props;
		if (prevProp.currentAction !== this.props.currentAction) {
			if (
				currentAction === ServiceActions.addNewService.successName ||
				currentAction === ServiceActions.editService.successName ||
				currentAction === ServiceActions.deleteService.successName
			) {
				AlertHelper.showAlert(
					'Success',
					I18n.t(
						_t(
							this.isAddNew()
								? translations.servicesDetail.addNewSuccess
								: currentAction === ServiceActions.editService.successName
								? translations.servicesDetail.editSuccess
								: translations.servicesDetail.deleteSuccess
						)
					),
					'success'
				);
				history.goBack();
				return;
			}

			// if (currentAction === ServiceActions.getServiceById.successName) {
			// 	this.setState({ checkedList: this.props.currentService?.employeeIds! });
			// 	return;
			// }

			if (
				currentAction === ServiceActions.deleteService.successName ||
				currentAction === ServiceActions.deleteService.failedName
			) {
				this.setState({ showServiceDeleteModal: false });
			}
		}
	}

	render() {
		return this.isAddNew() || !_.isEmpty(this.props.currentService) ? (
			<DetailPageLayout
				title={I18n.t(
					_t(
						this.isAddNew()
							? translations.servicesDetail.addNew
							: translations.servicesDetail.editService
					)
				)}
				formRef={(ref) => (this.formRef = ref)}
				positiveButtonName={I18n.t(_t(translations.save))}
				negativeButtonName={I18n.t(_t(translations.servicesDetail.delete))}
				showDelete={this.isAddNew()}
				isLoading={this.props.loading}
				goBack={() => history.goBack()}
				onDeleteClick={() => this.onDeleteClick()}
				onFormFinish={this.onFormFinish}
			>
				{this.renderForm()}
			</DetailPageLayout>
		) : (
			<Layout style={styles.spinLayout}>
				<Spin
					tip="Loading..."
					spinning={this.props.loading}
					style={styles.loading}
				/>
			</Layout>
		);
	}

	onDeleteClick() {
		this.setState({ showServiceDeleteModal: true });
	}

	renderForm = () => {
		console.log(';sadsadsaddsaads', this.props);
		const { id } = this.props.match.params;
		return (
			<>
				<Space direction="vertical" size="small" style={styles.maxWidth}>
					{this.renderFormBasicInfo()}
					{/* <FormOnlineBooking /> */}
					{/* {this.renderFormStaff()} */}
					{this.renderFormPricingAndDuration()}
					{/* {this.renderFormSalesSettings()} */}
					<Modal
						visible={this.state.showServiceDeleteModal}
						title={I18n.t(_t(translations.delete))}
						handleOk={() => this.props.deleteService(id)}
						handleCancel={() =>
							this.setState({ showServiceDeleteModal: false })
						}
					>
						{I18n.t(_t(translations.servicesDetail.deleteConfirmContent))}
					</Modal>
				</Space>
			</>
		);
	};

	renderFormBasicInfo = () => {
		return (
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.servicesDetail.tBasicInfo))}
					description={I18n.t(_t(translations.servicesDetail.desBasicInfo))}
				/>
				<Divider style={styles.divider} />
				<Space direction="vertical" size="middle" style={styles.maxWidth}>
					<ImageUpload
						defaultImage={this.props.currentService?.imageUrl!}
						onGetImages={(fileList) =>
							this.setState({
								imageFiles: fileList,
								isHaveInitialImageUrl: false,
							})
						}
					/>
					{FormHelper.renderFormInput(
						'serviceName',
						[
							{
								required: true,
								message: I18n.t(
									_t(translations.servicesDetail.inputServiceName)
								),
							},
						],
						I18n.t(_t(translations.servicesDetail.serviceName)),
						this.props.currentService ? this.props.currentService.name : '',
						{},
						{ maxLength: 256 }
					)}

					{FormHelper.renderFormSelect<IItemCategory>(
						'category',
						I18n.t(_t(translations.servicesDetail.serviceCategory)),
						this.props.categories,
						'name',
						'id',
						this.getCategory(this.props.currentService!)
							? this.getCategory(this.props.currentService!)!.id
							: this.props.location.state
							? this.props.location.state.itemCategoryId
							: this.props.categories[0].id
					)}
					{FormHelper.renderFormTextArea(
						'description',
						[],
						I18n.t(_t(translations.servicesDetail.serviceDescription)),
						I18n.t(_t(translations.placeHolder.egServiceName)),
						this.props.currentService
							? this.props.currentService.description!
							: ''
					)}
				</Space>
			</Card>
		);
	};

	// renderStaff(employee: IEmployee, i: number) {
	// 	return (
	// 		<Col
	// 			key={employee.id}
	// 			sm={24}
	// 			md={12}
	// 			xl={8}
	// 			style={
	// 				!this.state.showAllStaff
	// 					? i > 5
	// 						? styles.seeLessStaff
	// 						: styles.seeAllStaff
	// 					: styles.seeAllStaff
	// 			}
	// 		>
	// 			<Checkbox
	// 				checked={_.includes(this.state.checkedList, employee.id)}
	// 				value={employee.id}
	// 				style={styles.staffCheckbox}
	// 				onChange={(e) =>
	// 					this._onChangeStaffCheckbox(e.target.checked, employee.id!)
	// 				}
	// 			>
	// 				<Row align="middle" justify="space-between">
	// 					<AppAvatar
	// 						size={59}
	// 						style={styles.avatarStyle}
	// 						name={`${employee.firstName || ''} ${employee.lastName || ''}`}
	// 					/>
	// 					<Title
	// 						level={4}
	// 						style={styles.staffNameTitle}
	// 					>{` ${employee.firstName || ''} ${employee.lastName || ''}`}</Title>
	// 				</Row>
	// 			</Checkbox>
	// 		</Col>
	// 	);
	// }

	// renderStaffCheckbox() {
	// 	const stylistIdInService = this.state.checkedList
	// 		? this.state.checkedList.filter(
	// 				(id) =>
	// 					_.findIndex(
	// 						this.props.employees,
	// 						(employee) => employee.id === id
	// 					) >= 0
	// 		  )
	// 		: [];
	// 	return (
	// 		<Col span={24}>
	// 			<Checkbox
	// 				indeterminate={
	// 					!_.isEmpty(this.state.checkedList) &&
	// 					stylistIdInService.length !== this.props.employees!.length
	// 				}
	// 				onChange={this._onCheckAllChange}
	// 				checked={
	// 					!_.isEmpty(this.state.checkedList) &&
	// 					stylistIdInService.length === this.props.employees!.length
	// 				}
	// 			>
	// 				<Title level={5}>
	// 					{I18n.t(_t(translations.servicesDetail.selectAll))}
	// 				</Title>
	// 			</Checkbox>
	// 			<br />
	// 			<Form.Item name="staff-checkbox">
	// 				<Space direction="vertical" size="small" style={styles.maxWidth}>
	// 					<Row gutter={[16, 24]}>
	// 						{this.props.employees!.map((employee: IEmployee, i) =>
	// 							this.renderStaff(employee, i)
	// 						)}
	// 					</Row>
	// 					{this.props.employees!.length > 6 ? (
	// 						<Button
	// 							size="large"
	// 							type="link"
	// 							onClick={() =>
	// 								this.setState({ showAllStaff: !this.state.showAllStaff })
	// 							}
	// 							style={styles.btnShowStaff}
	// 						>
	// 							<Text>
	// 								{I18n.t(
	// 									_t(
	// 										!this.state.showAllStaff
	// 											? translations.servicesDetail.seeAllStaff
	// 											: translations.servicesDetail.seeLess
	// 									)
	// 								)}
	// 							</Text>
	// 						</Button>
	// 					) : null}
	// 				</Space>
	// 			</Form.Item>
	// 		</Col>
	// 	);
	// }

	// renderFormStaff = () => {
	// 	return (
	// 		<Card>
	// 			<Card.Meta
	// 				title={I18n.t(_t(translations.servicesDetail.staff))}
	// 				description={I18n.t(_t(translations.servicesDetail.staffDescription))}
	// 			/>
	// 			<Divider style={styles.divider} />
	// 			{this.renderStaffCheckbox()}
	// 			<Divider style={styles.dividerNoMargin} />
	// 			{/* <Col>
	//       <Title level={4}>{I18n.t(_t(translations.servicesDetail.tStaffCommission))}</Title>
	//       <Text>{I18n.t(_t(translations.servicesDetail.tDesCalculateStaff))}</Text>
	//     </Col>
	//     <Space direction="horizontal" size="middle" style={styles.maxWidthSpace}>
	//       <Switch defaultChecked onChange={this._onSwitch} />
	//       <Text>{I18n.t(_t(translations.servicesDetail.enableStaff))}</Text>
	//     </Space> */}
	// 		</Card>
	// 	);
	// };

	renderFormPricingOption() {
		return (
			<Col style={styles.cardPricing}>
				<Row>
					<Space direction="vertical" size="middle" style={styles.maxWidth}>
						<Row justify="space-between">
							<Title level={3}>Pricing option 1</Title>
						</Row>
						<Row justify="space-between" gutter={10}>
							{FormHelper.renderFormTimePicker(
								'duration',
								I18n.t(_t(translations.servicesDetail.duration)),
								this.isAddNew()
									? moment('01:00', 'HH:mm')
									: moment(
											TimeHelper.convertDurationToString(
												this.props.currentService?.duration!
											),
											'HH:mm'
									  ),
								[
									{
										required: true,
										message: I18n.t(
											_t(translations.servicesDetail.inputDuration)
										),
									},
								],
								{
									xs: 24,
									md: 12,
									lg: 3,
								},
								{
									minuteStep: 5,
									hideDisabledOptions: true,
									disabledHours: () => {
										const disabledHours = [];
										for (let i = 0; i < 24; ++i) {
											if (i > 6) {
												disabledHours.push(i);
											}
										}
										return disabledHours;
									},
								}
							)}

							{FormHelper.renderFormSelect<{ id: string; type: string }>(
								'priceType',
								I18n.t(_t(translations.servicesDetail.priceType)),
								LIST_PRICE_TYPE,
								'type',
								'id',
								'Fixed',
								{ xs: 24, md: 12, lg: 7 }
							)}

							{FormHelper.renderFormInputNumber(
								'price',
								[
									// {
									// 	required: true,
									// 	message: I18n.t(_t(translations.servicesDetail.inputPrice)),
									// },
									({ getFieldValue }) => ({
										validator(robj, value) {
											if (value) {
												if (
													_.toNumber(value) !==
													Math.round(value * 1e2) / 1e2
												) {
													return Promise.reject(
														new Error(
															'Services price can only enter 2 decimal places'
														)
													);
												}
												return Promise.resolve();
											}
											return Promise.resolve();
										},
									}),
								],
								I18n.t(_t(translations.price)),
								this.props.currentService?.price!,
								{ xs: 24, md: 12, lg: 7 }
							)}

							{FormHelper.renderFormInputNumber(
								'supplyPrice',
								[
									({ getFieldValue }) => ({
										validator(robj, value) {
											if (value) {
												if (
													_.toNumber(value) !==
													Math.round(value * 1e2) / 1e2
												) {
													return Promise.reject(
														new Error(
															'Supply price can only enter 2 decimal places'
														)
													);
												}
												if (
													_.toNumber(value) < _.toNumber(getFieldValue('price'))
												) {
													return Promise.resolve();
												}
												return Promise.reject(
													new Error(
														I18n.t(
															_t(
																translations.servicesDetail
																	.supplyPriceSmallerThanService
															)
														)
													)
												);
											}
											return Promise.resolve();
										},
									}),
								],
								I18n.t(_t(translations.servicesDetail.supplyPrice)),
								this.props.currentService?.supplyPrice!,
								{ xs: 24, md: 24, lg: 7 }
							)}
						</Row>
						{/* {FormHelper.renderFormInput(
							'pricingName',
							[],
							I18n.t(_t(translations.servicesDetail.pricingName)),
							''
						)} */}
					</Space>
				</Row>
			</Col>
		);
	}

	renderFormPricingAndDuration = () => {
		return (
			<Card>
				{/* form */}
				<Card.Meta
					title={I18n.t(_t(translations.servicesDetail.pricingDuration))}
					description={I18n.t(
						_t(translations.servicesDetail.desPricingDuration)
					)}
				/>
				<Divider style={styles.divider} />
				<Col>
					{this.renderFormPricingOption()}
					{/* <Form.List name="pricingOption">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }: any) => (
                  <Col key={key + "priceOptions"} style={styles.cardPricing}>
                    <Row>
                      <Space direction="vertical" size="middle" style={styles.maxWidth}>
                        <Row justify="space-between">
                          <h2 style={{ fontWeight: "bold" }}>Pricing option {name + 2}</h2>
                          {fields.length > 0 && (
                            <CloseCircleTwoTone
                              style={styles.icClose}
                              twoToneColor="red"
                              onClick={() => remove(name)}
                            />
                          )}
                        </Row>
                        <Row justify="space-between" gutter={10}>
                          <Col xs={24} md={12} lg={6}>
                            <h3>{I18n.t(_t(translations.servicesDetail.duration))}</h3>
                            <Select
                              defaultValue={LIST_DURATION[0].id}
                              onChange={(values) => console.log(values)}
                              style={styles.maxWidth}
                            >
                              {LIST_DURATION.map((e) => (
                                <Option key={e.id + "durationOption"} value={e.id}>
                                  {e.duration}min
                                </Option>
                              ))}
                            </Select>
                          </Col>
                          <Col xs={24} md={12} lg={6}>
                            <h3>{I18n.t(_t(translations.servicesDetail.priceType))}</h3>
                            <Select
                              defaultValue={LIST_PRICE_TYPE[0].id}
                              onChange={(values) => console.log(values)}
                              style={styles.maxWidth}
                            >
                              {LIST_PRICE_TYPE.map((e) => (
                                <Option key={e.id + "priceTypeOption"} value={e.id}>
                                  {e.type}
                                </Option>
                              ))}
                            </Select>
                          </Col>
                          <Col xs={24} md={12} lg={6}>
                            <h3>Price</h3>
                            <Form.Item
                              {...restField}
                              name={[name, "priceOption"]}
                              fieldKey={[fieldKey, "priceOption"]}
                              rules={[{ required: true, message: "Input Price" }]}
                            >
                              <InputNumber
                                style={styles.maxWidth}
                                formatter={formatPrice}
                                parser={parserPrice}
                                onChange={(value) => console.log("changed", value)}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} lg={6}>
                            <h3>{I18n.t(_t(translations.servicesDetail.specialPrice))}</h3>
                            <Form.Item
                              {...restField}
                              name={[name, "priceSpecialOption"]}
                              fieldKey={[fieldKey, "priceSpecialOption"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Input special price",
                                },
                              ]}
                            >
                              <InputNumber
                                style={styles.maxWidth}
                                // defaultValue={100}
                                formatter={formatPrice}
                                parser={parserPrice}
                                onChange={(value) => console.log("changed", value)}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item
                          {...restField}
                          name={[name, "pricingNameOption"]}
                          fieldKey={[fieldKey, "pricingNameOption"]}
                          rules={[{ required: true, message: "Add pricing name" }]}
                        >
                          <Col>
                            <h3>{I18n.t(_t(translations.servicesDetail.pricingName))}</h3>
                            <Input placeholder="e.g. Long hair" />
                          </Col>
                        </Form.Item>
                      </Space>
                    </Row>
                  </Col>
                ))}
                <Form.Item>
                  <Button
                    type="link"
                    size="large"
                    icon={<PlusCircleTwoTone />}
                    onClick={() => add()}
                    style={styles.btnAddPricingOption}
                  >
                    <Text className="btn-decoration">{I18n.t(_t(translations.servicesDetail.addPricingOption))}</Text>
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List> */}
				</Col>
			</Card>
		);
	};

	renderFormSalesSettings = () => {
		return (
			<Card>
				<Card.Meta title={I18n.t(_t(translations.servicesDetail.setTaxRate))} />
				<Divider style={styles.divider} />
				{/* tax */}
				<Space direction="vertical" size="middle" style={styles.maxWidth}>
					<Col>
						{formHelper.renderFormSelect<any>(
							'tax',
							I18n.t(_t(translations.servicesDetail.taxAddPrice)),
							LIST_TAX,
							'tax',
							'id',
							LIST_TAX[0].id
						)}
					</Col>
				</Space>
			</Card>
		);
	};

	// _onChangeStaffCheckbox = (isChecked: boolean, id: string) => {
	// 	let checkedList = Array.from(this.state.checkedList);
	// 	if (isChecked) {
	// 		checkedList.push(id);
	// 	} else {
	// 		checkedList = this.state.checkedList.filter((i) => i !== id);
	// 	}

	// 	this.setState({
	// 		checkedList,
	// 	});
	// };

	// _onCheckAllChange = (e: any) => {
	// 	this.setState({
	// 		checkedList: e.target.checked
	// 			? this.props.employees!.map((e) => e.id)!
	// 			: [],
	// 	});
	// };

	// _onSwitch = (checked: boolean) => {
	// 	// console.log(`switch to ${checked}`);
	// };

	onDeleteService = () => {
		this.props.deleteService(this.props.match.params.id);
	};

	onFormFinish = (name: string, { values, forms }: any) => {
		// const allEmployeeIds = this.props.employees?.map((employee) => employee.id);
		// const employeeNotInBranch = _.xor(
		// 	this.props.currentService?.employeeIds!,
		// 	allEmployeeIds
		// );
		const data: Partial<IServiceRequest> = {
			code: '1',
			name: values.serviceName,
			price: _.toNumber(values.price),
			description: values.description,
			itemCategoryId: values.category,
			imageUrl: '',
			taxId: undefined,
			duration: TimeHelper.convertDurationToNumber(values.duration),
			status: 1,
			shopId: this.props.branch?.shopId!,
			// employeeIds: _.uniq(
			// 	_.concat(employeeNotInBranch, this.state.checkedList)
			// ),
			branchIds: [this.props.branch?.id!],
			supplyPrice: values.supplyPrice ? _.toNumber(values.supplyPrice) : 0,
			file: this.state.imageFiles ? this.state.imageFiles![0] : undefined,
		};

		const dataEdit: IEditServiceRequest = {
			...data,
			id: this.props.match.params.id,
			imageUrl: this.state.isHaveInitialImageUrl
				? this.props.currentService?.imageUrl!
				: '',
		};
		this.isAddNew()
			? this.props.addNewService(data)
			: this.props.editService(dataEdit);
	};

	getCategory = (service: IService): IItemCategory | null => {
		if (this.props.currentService) {
			return _.filter(this.props.categories, (category) => {
				return category.id === service.itemCategoryId;
			})[0];
		}
		return null;
	};

	isAddNew(): boolean {
		return this.props.location.pathname.includes('add-new');
	}
}

const mapStateToProps = (state: RootState) => ({
	employees: state.EmployeeReducer.employees,
	loading: state.AppConfigReducer.showLoading,
	currentAction: state.ReduxActionReducer['SERVICE'],
	error: state.ErrorReducer.error?.errors,
	categories: state.CategoryReducer.categories,
	currentService: state.ServiceReducer.currentService,
	isEditingService: isEditingService(state),
	branch: state.BranchReducer.currentBranch,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	getServiceById: (id: string) => {
		dispatch(ServiceActions.getServiceById.request(id));
	},
	addNewService: (request: Partial<IServiceRequest>) => {
		dispatch(ServiceActions.addNewService.request(request));
	},
	editService: (request: IEditServiceRequest) => {
		dispatch(ServiceActions.editService.request(request));
	},
	deleteService: (id: string) => {
		dispatch(ServiceActions.deleteService.request(id));
	},
	resetService: () => {
		dispatch(ServiceActions.reset.request());
	},
	getAllCategory: () => {
		dispatch(CategoryActions.getAllCategory.request(null));
	},
	getAllEmployees: () => {
		dispatch(EmployeeActions.getAllEmployees.request());
	},
	clearError: () => {
		dispatch(ErrorActions.clearError.request());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetailPage);
