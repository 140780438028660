const styles: any = {
  col: {
    background: "none",
  },
  row: {
    marginBottom: '32px'
  }
};

export default styles;
