import axios, {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
} from 'axios';
import * as qs from 'qs';
// import { STATUS_CODE } from "./statusCode";
// import _ from "lodash";
import configureStore from 'redux/configuration/configureStore';
import { IApiResponse, IErrorResponse, IUser } from 'models';
import _ from 'lodash';
import { AuthenticationActions } from 'redux/actions';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import moment from 'moment';
import { history } from 'routers';
import { Const } from 'utils';

const API_CONFIG: AxiosRequestConfig = {
	// returnRejectedPromiseOnError: true,
	// withCredentials: true,
	// timeout: 30000,
	baseURL: process.env.REACT_APP_STAGING_API,
	headers: {
		// 'Content-type': 'application/json',
		'accept-language': 'en-Us',
		// timeOffset: Math.round(moment().utcOffset() / 60),
		accept: '*/*',
	},
	params: {
		pageSize: 100,
		pageNumber: 1,
	},
	paramsSerializer: (params: any) => qs.stringify(params, { indices: false }),
};

// interface IOriginRequest extends AxiosRequestConfig {
//   _retry?: boolean;
// }

export abstract class BaseApiService {
	private instance: AxiosInstance;
	private needVersion = false;
	constructor(needVersion = true) {
		this.instance = this.initHttp(needVersion);
		this.needVersion = needVersion;
	}

	initHttp(needVersion: boolean) {
		const http = axios.create(API_CONFIG);
		http.interceptors.request.use(this.injectShopId);
		if (needVersion) {
			http.interceptors.request.use(this.injectVersion);
			http.interceptors.request.use(this.injectParams);
		}
		http.interceptors.request.use(this.injectToken, (error) =>
			Promise.reject(error)
		);
		http.interceptors.request.use(this.injectLanguage);
		http.interceptors.request.use((res) => this._injectRefreshToken(res));
		return http;
	}

	injectLanguage = (config: AxiosRequestConfig): AxiosRequestConfig => {
		try {
			const store = configureStore().store;
			const language = store.getState().AppConfigReducer.language as
				| 'en'
				| 'vi';
			if (language) {
				config.headers['accept-language'] = `${Const.LANGUAGE[language]}`;
			}
			return config;
		} catch (error) {
			throw new Error(error as string);
		}
	};

	injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
		try {
			if (
				history.location.search &&
				history.location.search.includes('isCustomer=true')
			) {
				return config;
			}
			const store = configureStore().store;
			const token = store.getState().AuthenticationReducer.token;
			if (token != null && this.needVersion) {
				config.headers.Authorization = `Bearer ${token}`;
			}
			return config;
		} catch (error) {
			throw new Error(error as string);
		}
	};
	injectVersion = (config: AxiosRequestConfig): AxiosRequestConfig => {
		try {
			const version = 1;
			if (!config.baseURL?.includes(`/v${version}`)) {
				config.baseURL = config.baseURL + `/v${version}`;
			}
			return config;
		} catch (error) {
			throw new Error(error as string);
		}
	};
	injectParams = (config: AxiosRequestConfig): AxiosRequestConfig => {
		try {
			const store = configureStore().store;
			const currentBranch = store.getState().BranchReducer.currentBranch;
			if (!_.isEmpty(currentBranch)) {
				if (!_.isEmpty(config.params)) {
					config.params['branchId'] = currentBranch?.id;
				}
			}
			if (
				!_.isEmpty(config.params) &&
				(config.method === 'post' || config.method === 'put')
			) {
				config.params = undefined;
			}

			return config;
		} catch (error) {
			throw new Error(error as string);
		}
	};
	injectShopId = (config: AxiosRequestConfig): AxiosRequestConfig => {
		try {
			const store = configureStore().store;
			const shopId = store.getState().ShopReducer.shopId;
			if (shopId) {
				if (config.method === 'GET' || config.method === 'get') {
					if (!_.isEmpty(config.params)) {
						if (_.isEmpty(config.params['shopId'])) {
							config.params['shopId'] = shopId;
						}
					} else {
						config.params = {
							shopId,
						};
					}
				}
				if (
					config.method === 'POST' ||
					config.method === 'post' ||
					config.method === 'put' ||
					config.method === 'PUT'
				) {
					if (_.isEmpty(config.data['shopId'])) {
						config.data['shopId'] = shopId;
					}
				}
			}
			return config;
		} catch (error) {
			throw new Error(error as string);
		}
	};
	private async refreshToken(): Promise<AxiosResponse<
		IApiResponse<IUser>
	> | null> {
		try {
			const store = configureStore().store;
			const state = store.getState();
			var config: AxiosRequestConfig = API_CONFIG;
			config.headers.Authorization = null;
			config.headers.isRefresh = true;
			const response = await axios.create(config).post<IApiResponse<IUser>>(
				`${process.env.REACT_APP_STAGING_API}/identity/refresh-token`,
				{
					userNameOrEmail: state.UserReducer.user?.userName,
					refreshToken: state.AuthenticationReducer.refreshToken!,
					shopId: state.ShopReducer.shopId!,
					deviceId: state.AuthenticationReducer.deviceId,
				},
				config
			);
			return response;
		} catch (error) {
			return null;
		}
	}

	private handleNewToken = (data?: IUser, status?: number) => {
		const store = configureStore().store;
		if (!_.isEmpty(data)) {
			store.dispatch(
				AuthenticationActions.setToken.request({
					token: data?.jwToken!,
					refreshToken: data?.refreshToken!,
				})
			);
			this.onRefreshed(data?.jwToken!);
		} else {
			if (status === 401) {
				store.dispatch(AuthenticationActions.logOut.request());
			}
		}
	};

	private _injectRefreshToken = async (
		config: AxiosRequestConfig
	): Promise<AxiosRequestConfig> => {
		let orgConfig = Object.assign({}, config);
		const store = configureStore().store;
		if (
			!this._checkNeedRefreshToken() ||
			config.url?.includes('/identity/refresh-token')
		) {
			return config;
		}
		const retryReq = new Promise<AxiosRequestConfig>((resolve) => {
			this.subscribeTokenRefresh(async (accessToken) => {
				orgConfig.headers['Authorization'] = 'Bearer ' + accessToken;
				resolve(orgConfig);
			});
		});
		if (!global.isAppRefreshingToken) {
			if (global.refreshSubscribers === undefined) {
				global.refreshSubscribers = [];
			}
			global.isAppRefreshingToken = true;
			const refreshResponse = await this.refreshToken();
			global.isAppRefreshingToken = false;
			if (_.isEmpty(refreshResponse)) {
				store.dispatch(AuthenticationActions.logOut.request());
			} else {
				this.handleNewToken(
					refreshResponse?.data.data,
					refreshResponse?.status
				);
			}
		}
		return retryReq;
	};

	// private _handleRefreshToken = async (
	// 	res: AxiosResponse
	// ): Promise<AxiosResponse> => {
	// 	const originalRequest = res.config;
	// 	const store = configureStore().store;
	// 	const token = store.getState().AuthenticationReducer.token;
	// 	if (_.isEmpty(token)) {
	// 		return res;
	// 	}
	// 	const tokenDecode = jwtDecode<JwtPayload>(token!);
	// 	const isExpiredToken = res.headers['Token-Expired'];
	// 	if (isExpiredToken || moment.unix(tokenDecode.exp!).isBefore(moment())) {
	// 		const retryOrigReq = new Promise<AxiosResponse>((resolve) => {
	// 			this.subscribeTokenRefresh(async (accessToken) => {
	// 				originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
	// 				const newResponse = await this.instance(originalRequest);
	// 				resolve(newResponse);
	// 			}, originalRequest.url!);
	// 		});
	// 		if (!global.isAppRefreshingToken) {
	// 			if (global.refreshSubscribers === undefined) {
	// 				global.refreshSubscribers = [];
	// 			}
	// 			global.isAppRefreshingToken = true;
	// 			const refreshResponse = await this.refreshToken();
	// 			global.isAppRefreshingToken = false;
	// 			if (_.isEmpty(refreshResponse)) {
	// 				store.dispatch(AuthenticationActions.logOut.request());
	// 			}
	// 			this.handleNewToken(
	// 				refreshResponse?.data.data,
	// 				refreshResponse?.status
	// 			);
	// 		}
	// 		return retryOrigReq;
	// 	}
	// 	return res;
	// };

	private _checkNeedRefreshToken = (): boolean => {
		const store = configureStore().store;
		const token = store.getState().AuthenticationReducer.token;
		if (_.isEmpty(token)) {
			return false;
		}
		const tokenDecode = jwtDecode<JwtPayload>(token!);
		if (moment.unix(tokenDecode.exp!).isBefore(moment())) {
			return true;
		}
		return false;
	};

	subscribeTokenRefresh(cb: (token: string) => void) {
		if (global.refreshSubscribers === undefined) {
			global.refreshSubscribers = [];
		}
		global.refreshSubscribers.push(cb);
	}
	onRefreshed(token: string) {
		global.refreshSubscribers.map((cb) => cb(token));
	}

	private _handleResponse<T = void>(
		response: AxiosResponse<IApiResponse<T>>
	): IApiResponse<T> | IErrorResponse {
		if (response.data.succeeded) {
			return response.data!;
		}
		const error: IErrorResponse = {
			message: response.data.message,
			errors: !_.isEmpty(response.data.errors)
				? response.data.errors
				: {
						message: {
							Text: response.data.message[0].text,
							Code: response.data.message[0].code,
						},
				  },
		};
		return error;
	}

	private _handleError(err: any): IErrorResponse {
		try {
			const axiosError = err as AxiosError;
			const response = axiosError.response!;
			// if (response.status === 401) {
			//   history.replace("/login");
			// }
			const responseError: IErrorResponse = {
				message:
					!_.isEmpty(response.data) &&
					response.data.message.length !== undefined
						? response.data.message
						: [response.data.message],
				errors: !_.isEmpty(response.data.errors)
					? response.data.errors
					: {
							message: {
								Text: response.data.message.Text,
								Code: response.data.message.Code,
							},
					  },
			};
			return responseError;
		} catch (error) {
			const responseError: IErrorResponse = {
				message: [
					{
						text: 'System Error',
						code: 100,
					},
				],
				errors: {
					message: {
						Text: 'System Error',
						Code: '100',
						Type: 100,
					},
				},
			};
			return responseError;
		}
	}

	public async get<T>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<IApiResponse<T> | IErrorResponse> {
		try {
			const response = await this.instance.get(url, config);
			return this._handleResponse<T>(response);
		} catch (error) {
			return this._handleError(error);
		}
	}

	public async post<P = void, T = void>(
		url: string,
		data?: P,
		config?: AxiosRequestConfig
	): Promise<IApiResponse<T> | IErrorResponse> {
		try {
			const response = await this.instance.post(url, data, config);
			return this._handleResponse<T>(response);
		} catch (error) {
			return this._handleError(error);
		}
	}

	public async put<P = void, T = void>(
		url: string,
		data?: P,
		config?: AxiosRequestConfig
	): Promise<IApiResponse<T> | IErrorResponse> {
		try {
			const response = await this.instance.put(url, data, config);
			return this._handleResponse<T>(response);
		} catch (error) {
			return this._handleError(error);
		}
	}

	public async delete<T = void>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<IApiResponse<T> | IErrorResponse> {
		try {
			const response = await this.instance.delete(url, config);
			return this._handleResponse<T>(response);
		} catch (error) {
			return this._handleError(error);
		}
	}
}
