export const CONTAINER_BACKGROUND = '#E8E8E8';
export const PRIMARY_COLOR = '#323dad';
export const GREY = '#969696';
export const RED_BERRY = '#911439';
export const WHITE_SMOKE = '#FFFAFA';
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const BLACK_60 = '#00000060';
export const BLACK_40 = '#00000040';
export const BLACK_OPACITY_80 = 'rgba(000,0,0,.8)';
export const BLACK_OPACITY_60 = 'rgba(000,0,0,.6)';
export const GREEN = '#009533';
