import { IApiResponse, IGallery } from 'models';
import { put, takeEvery, call } from 'redux-saga/effects';
import { GalleryActions } from 'redux/actions';
import GalleryApiService from 'services/GalleryApiService';
import { IErrorResponse } from '../../models/IErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';
import {
	IAddGalleryRequest
} from 'models/RequestModels';
import UploadBaseService from 'services/UploadBaseService';
import { IFileUploadResponse } from 'models/ResponseModels';
import { AlertHelper } from 'helpers';
import { I18n, _t, translations } from 'utils'


function* addGallery(action: PayloadAction<IAddGalleryRequest>) {
	try {
		const result: IApiResponse<IGallery> = yield call(
			GalleryApiService.addGallery,
			action.payload
		);
		if (result.succeeded) {
			yield put(GalleryActions.addGallery.success(result.data!));
			AlertHelper.showAlert(
				'Success',
				// I18n.t(_t(translations.customerDetail.addNewSuccess)),
				"Gallery of this category was create automatically",
				'success'
			);
		} else {
			const error = result as IErrorResponse;
			yield put(GalleryActions.addGallery.failed(error));
			AlertHelper.showError(error);
		}
	} catch (error) {
		yield put(GalleryActions.addGallery.failed(error));
		AlertHelper.showError(error as IErrorResponse);
	}
}

export function* GalleryWatcher() {
    yield takeEvery(GalleryActions.addGallery.requestName, addGallery);
  }