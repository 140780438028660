import { Col, Input, Typography, Button, Row, Space } from 'antd';
import { CurrencyHelper } from 'helpers';
import ModalHelper from 'helpers/modalHelper';
import _ from 'lodash';
import React from 'react';
import { translations, _t, I18n } from 'utils';
import CheckOutBaseModal from './CheckOutBaseModal';
import styles from './styles';
import { DownOutlined } from '@ant-design/icons';
import { ICurrency } from 'models/ICurrency';

interface IPayCashModalProps {
	onOk(cash: number, currencyCode: string): void;
	onCancel(): void;
	amount?: number;
	currencies: ICurrency[];
}

interface IPayCashModalState {
	cash?: number;
	currency: string;
	currencyCode: string;
	showSelectCurrencyModal: boolean;
}

export default class PayCashModal extends React.Component<
	IPayCashModalProps,
	IPayCashModalState
> {
	constructor(props: IPayCashModalProps) {
		super(props);
		this.state = {
			currency: `${this.mainCurrency?.code!} ${
				this.mainCurrency?.symbol ? `(${this.mainCurrency?.symbol})` : ''
			}`,
			showSelectCurrencyModal: false,
			currencyCode: this.mainCurrency?.code!,
		};
	}
	mainCurrency = this.props.currencies.find((e) => e.isMainCurrency === true);
	inputRef: any = React.createRef();
	render() {
		return (
			<CheckOutBaseModal
				title={I18n.t(_t(translations.checkout.cash))}
				onOk={() => this.onOk()}
				onCancel={this.props.onCancel}
				disableOk={(this.state?.cash || 0) < 0}
			>
				<Col>
					<Typography.Title level={4}>
						{I18n.t(_t(translations.checkout.inputCash))}
					</Typography.Title>
					<Row align="middle" gutter={12}>
						<Col md={16}>
							<Row>
								<Col style={{ flex: 1 }}>
									<Input
										ref={this.inputRef}
										type="number"
										style={styles.inputNumber}
										onPressEnter={this.onOk}
										autoFocus={true}
										value={this.state.cash}
										onChange={(event) => this.onChange(event.target.value)}
									/>
								</Col>
								<Col>
									<Button
										style={{
											minWidth: 60,
											borderRadius: 0,
											backgroundColor: '#FAFAFA',
										}}
										onClick={() =>
											this.setState({ showSelectCurrencyModal: true })
										}
									>
										{this.state.currency} <DownOutlined />
									</Button>
								</Col>
							</Row>
						</Col>
						<Col md={8}>
							<Space style={styles.maxWidth} size="small">
								<Col md={12}>
									<Button
										disabled={
											this.state.currencyCode !== this.mainCurrency?.code
										}
										onClick={() => this.setState({ cash: this.props.amount })}
									>
										Exact
									</Button>
								</Col>
								<Col md={12}>
									<Button
										onClick={() => {
											this.setState({ cash: undefined });
											this.inputRef.current?.focus();
										}}
										danger
									>
										{I18n.t(_t(translations.clear))}
									</Button>
								</Col>
							</Space>
						</Col>
					</Row>
				</Col>
				<Col span={24} style={styles.textRight}>
					<Typography.Text>
						{`${I18n.t(_t(translations.checkout.amountNeedToPay))}
            ${CurrencyHelper.formatBalance(this.props.amount!)}`}
					</Typography.Text>
				</Col>
				{/* {this.state.cash && this.state.cash - this.props.amount! > 0 ? (
					<Col span={24} style={styles.textRight}>
						<Typography.Text>
							{`${I18n.t(
								_t(translations.checkout.moneyChangeBack)
							)} ${CurrencyHelper.formatBalance(
								this.state.cash - this.props.amount!
							)}`}
						</Typography.Text>
					</Col>
				) : null} */}
				{this.renderSelectCurrencyModal()}
			</CheckOutBaseModal>
		);
	}

	renderSelectCurrencyModal = () => {
		return (
			<ModalHelper
				title="Select Currency"
				onOk={() => {}}
				onCancel={() => this.setState({ showSelectCurrencyModal: false })}
				visible={this.state.showSelectCurrencyModal}
				btnOkHidden
			>
				<Row gutter={[16, 16]}>
					{this.props.currencies.map((currency) => {
						return (
							<Col>
								<Button
									style={{ minWidth: 60 }}
									onClick={() => {
										console.log(currency.symbol, 'currency.symbol');

										this.setState({
											currencyCode: currency.code,
											showSelectCurrencyModal: false,
											currency: `${currency.code}${
												currency.symbol ? ` (${currency.symbol})` : ''
											}`,
											cash: undefined,
										});
									}}
								>
									{currency.code}{' '}
									{currency.symbol ? `(${currency.symbol})` : ''}
								</Button>
							</Col>
						);
					})}
				</Row>
			</ModalHelper>
		);
	};

	onChange = (cash: any) => {
		if (isNaN(cash)) {
			this.setState({ cash: undefined });
			return;
		}
		this.setState({
			cash: _.toNumber(cash) === 0 ? undefined : _.toNumber(cash),
		});
	};

	onOk = () => {
		if ((this.state.cash || 0) >= 0) {
			this.props.onOk(this.state.cash || 0, this.state.currencyCode);
		}
	};
}
