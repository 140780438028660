export enum EPeriodType {
	DATE_RANGE = -1,
	DAY,
	WEEK,
	SEMI_MONTH,
	MONTH,
	QUARTER,
	SEMI_YEAR,
	YEAR,
}
