import React, { useState } from 'react';
import { Typography, Row, Col, Input, Form, Button, Space } from 'antd';
import _ from 'lodash';
import { translations, _t, I18n } from 'utils';
import MaskedInput from 'antd-mask-input';
import CustomerApiService from 'services/CustomerApiService';
import { AlertHelper, FormHelper, StringHelper } from 'helpers';
import { IErrorResponse } from 'models';
import { convertToRawPhone } from 'helpers/StringHelper';
import { Spin } from 'components';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { CustomerBookingActions } from 'redux/actions';
import CouponModal from './CouponModal';
import { useAppSelector } from 'helpers/hookHelpers';

const { TextArea } = Input;

export const CustomerInfo = () => {
	const shop = useSelector((state: RootState) => state.ShopReducer.shop);
	const note = useSelector(
		(state: RootState) => state.CustomerBookingReducer.note
	);

	const [phoneNumber, setPhoneNumber] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const customer = useAppSelector(
		(state: RootState) => state.CustomerBookingReducer.customer
	);
	const [status, setStatus] = useState(
		!_.isEmpty(customer) ? 'confirm' : 'phone'
	);
	const [form] = Form.useForm();
	const [isEdit, setIsEdit] = useState(false);
	const [isShowCouponModal, setIsShowCouponModal] = useState<boolean>(false);
	const [coupon, setCoupon] = useState<string>('');
	const booking = useAppSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	const dispatch = useDispatch();

	const getCustomerByPhone = async () => {
		setIsLoading(true);
		await CustomerApiService.getCustomerByPhone(convertToRawPhone(phoneNumber)!)
			.then((res: any) => {
				if (res.succeeded) {
					if (!_.isEmpty(res.data)) {
						dispatch(CustomerBookingActions.updateCustomer.request(res.data));
						setStatus('confirm');
					} else {
						setStatus('name');
					}
				} else {
					const error = res as IErrorResponse;
					AlertHelper.showError(error);
				}
			})
			.catch((err) => {
				console.log(err);
			});
		setIsLoading(false);
	};

	const addCustomer = async (values: any) => {
		const params = {
			code: `${moment().unix()}`,
			firstName: values.name,
			phone: convertToRawPhone(phoneNumber),
			phoneNumberConfirmed: !_.isEmpty(phoneNumber) ? true : false,
			email: values.email,
			shopId: shop?.id,
			status: 1,
			newCustomer: true,
		};
		await CustomerApiService.addCustomer(params)
			.then((res: any) => {
				if (res.succeeded) {
					setStatus('confirm');
					dispatch(CustomerBookingActions.updateCustomer.request(res.data));

					setIsLoading(false);
				} else {
					const error = res as IErrorResponse;
					AlertHelper.showError(error);
					setIsLoading(false);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const editCustomer = async (values: any) => {
		const params = {
			id: customer?.id,
			code: `${moment().unix()}`,
			firstName: values.name,
			phone: convertToRawPhone(customer!.phone || ''),
			phoneNumberConfirmed: !_.isEmpty(customer?.phone) ? true : false,
			email: values.email,
			shopId: shop?.id,
			status: 1,
			newCustomer: true,
		};
		await CustomerApiService.editCustomer(params)
			.then((res: any) => {
				if (res.succeeded) {
					setStatus('confirm');
					dispatch(CustomerBookingActions.updateCustomer.request(res.data));
					setIsLoading(false);
				} else {
					const error = res as IErrorResponse;
					AlertHelper.showError(error);
					setIsLoading(false);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleCustomer = async () => {
		setIsLoading(true);
		form
			.validateFields()
			.then(async (values) => {
				await CustomerApiService.getCustomerByPhone(
					convertToRawPhone(phoneNumber)!
				)
					.then(async (res: any) => {
						if (res.succeeded) {
							if (!_.isEmpty(res.data)) {
								if (res.data.phone === convertToRawPhone(phoneNumber)) {
									editCustomer(values);
								}
							} else {
								addCustomer(values);
							}
						} else {
							const error = res as IErrorResponse;
							AlertHelper.showError(error);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			})
			.then(() => {
				setIsEdit(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const CustomerPhone = () => {
		return (
			<>
				<Space
					direction="vertical"
					style={{
						width: '100%',
						padding: 16,
						backgroundColor: 'white',
						marginBottom: 18,
						borderRadius: 5,
					}}
				>
					<Typography.Title level={5}>
						{I18n.t(_t(translations.customerBooking.enterPhone))}
					</Typography.Title>
					<Col
						md={12}
						lg={12}
						xl={12}
						xxl={12}
						style={{ paddingBottom: 16, width: '100%' }}
					>
						<MaskedInput
							mask="111-111-1111"
							placeholder={I18n.t(_t(translations.customerBooking.phoneNumber))}
							autoFocus={true}
							style={{ width: '100%' }}
							onChange={(event) => setPhoneNumber(event.target.value)}
							value={phoneNumber}
						/>
					</Col>
				</Space>
				<Row justify="end">
					<Space style={{ width: '100%', justifyContent: 'end' }}>
						<Button
							style={{ minWidth: 80 }}
							onClick={() => getCustomerByPhone()}
							disabled={!StringHelper.checkPhoneNumber(phoneNumber)}
						>
							{I18n.t(_t(translations.next))}
						</Button>
					</Space>
				</Row>
			</>
		);
	};

	const CustomerName = () => {
		return (
			<>
				<Form
					form={form}
					layout="vertical"
					style={{
						width: '100%',
						backgroundColor: 'white',
						padding: 16,
						marginBottom: 18,
						borderRadius: 5,
					}}
				>
					{FormHelper.renderFormInput(
						'name',
						[
							{
								required: true,
								message: I18n.t(_t(translations.customerBooking.nameRequire)),
							},
						],
						I18n.t(_t(translations.customerBooking.yourName)),
						isEdit ? customer?.firstName : '',
						{},
						{
							placeholder: I18n.t(_t(translations.customerBooking.enterName)),
							maxLength: 256,
						}
					)}
					{FormHelper.renderFormInput(
						'email',
						[
							{
								required: false,
								type: 'email',
								message: I18n.t(_t(translations.staffDetail.messageEmail)),
							},
						],
						I18n.t(_t(translations.customerBooking.yourEmail)),
						isEdit ? customer?.email : '',
						{},
						{
							placeholder: I18n.t(
								_t(translations.customerBooking.enterYourEmail)
							),
							maxLength: 256,
						}
					)}
				</Form>
				<Row justify="end">
					<Col md={6} lg={6} xl={6} xxl={6}>
						<Space style={{ width: '100%', justifyContent: 'end' }}>
							<Button
								style={{ minWidth: 80 }}
								onClick={() => {
									dispatch(CustomerBookingActions.updateCustomer.request({}));
									setStatus('phone');
								}}
							>
								{I18n.t(_t(translations.back))}
							</Button>
							<Button
								type="primary"
								style={{ minWidth: 80 }}
								onClick={() => handleCustomer()}
							>
								{I18n.t(_t(translations.next))}
							</Button>
						</Space>
					</Col>
				</Row>
			</>
		);
	};

	const CustomerConfirm = () => {
		return (
			<>
				<Space
					direction="vertical"
					style={{
						width: '100%',
						padding: 16,
						backgroundColor: 'white',
						marginBottom: 18,
						borderRadius: 5,
					}}
				>
					<Row justify="space-between">
						<Col span={12}>
							<Space direction="vertical">
								<Typography.Text style={{ fontSize: 16, fontWeight: 'normal' }}>
									{I18n.t(_t(translations.customerBooking.customerName))}
								</Typography.Text>
								<Typography.Text style={{ fontSize: 16, fontWeight: 'normal' }}>
									{`${I18n.t(_t(translations.customerBooking.phoneNumber))}:`}
								</Typography.Text>
								<Typography.Text style={{ fontSize: 16, fontWeight: 'normal' }}>
									{I18n.t(_t(translations.customerBooking.email))}
								</Typography.Text>
							</Space>
						</Col>
						<Col span={12}>
							<Space direction="vertical" align="end" style={{ width: '100%' }}>
								<Typography.Title level={5}>
									{`${customer?.firstName} ${customer?.lastName}`}
								</Typography.Title>
								<Typography.Title level={5}>{customer?.phone}</Typography.Title>
								<Typography.Title level={5}>{customer?.email}</Typography.Title>
							</Space>
						</Col>
					</Row>
				</Space>
				<Space
					direction="vertical"
					style={{
						width: '100%',
						padding: 16,
						backgroundColor: 'white',
						marginBottom: 18,
						borderRadius: 5,
					}}
				>
					<Typography.Title level={5}>
						{I18n.t(_t(translations.customerBooking.note))}
					</Typography.Title>
					<TextArea
						value={note}
						rows={5}
						onChange={(e) =>
							dispatch(
								CustomerBookingActions.updateNote.request(e.target.value)
							)
						}
					></TextArea>
				</Space>
				<Row justify="end">
					<Space style={{ width: '100%', justifyContent: 'end' }}>
						<Button style={{ minWidth: 80 }} onClick={() => handelEdit()}>
							{I18n.t(_t(translations.edit))}
						</Button>
						<Button
							style={{ minWidth: 80 }}
							onClick={() => {
								if (coupon) {
									dispatch(
										CustomerBookingActions.updateBooking.request({
											couponCodes: '',
											bookingDetails: booking!.bookingDetails!.map(
												(element) => ({
													...element,
													promotionDiscount: 0,
												})
											),
										})
									);
									setCoupon('');
								} else {
									setIsShowCouponModal(true);
								}
							}}
						>
							{coupon
								? I18n.t(_t(translations.customerBooking.useCouponLater))
								: I18n.t(_t(translations.customerBooking.applyCoupon))}
						</Button>
					</Space>
				</Row>
				{isShowCouponModal && (
					<CouponModal
						onCancel={() => setIsShowCouponModal(false)}
						onApplySuccess={(coupon) => {
							setCoupon(coupon);
							setIsShowCouponModal(false);
						}}
						visible
					/>
				)}
			</>
		);
	};

	const handelEdit = () => {
		setStatus('name');
		setIsEdit(true);
	};
	const renderSwitch = (status: string) => {
		switch (status) {
			case 'name':
				return CustomerName();
			case 'confirm':
				return CustomerConfirm();
			default:
				return CustomerPhone();
		}
	};
	return isLoading ? <Spin /> : renderSwitch(status);
};
