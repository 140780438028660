import { ITimeBlock } from 'models/ITimeBlock';
import moment from 'moment';
import momentTz from 'moment-timezone';
import configureStore from 'redux/configuration/configureStore';

export function convertDurationToStringDetail(duration: number) {
	const hours = Math.floor(duration / 60);
	const minutes = duration - hours * 60;
	return `${hours > 0 ? `${hours}h` : ''} ${minutes > 0 ? `${minutes}min` : ''
		}`;
}

export function convertDurationToNumber(duration: string): number {
	const hours = moment(duration, 'HH:mm').hours();
	const minutes = moment(duration, 'HH:mm').minutes();
	return hours * 60 + minutes;
}

export function convertDurationToString(duration: number) {
	const hours = Math.floor(duration / 60);
	const minutes = duration - hours * 60;
	return `${hours}:${minutes}`;
}

export function generateTimeBlockId(time: string) {
	return `timeBlock${time}`;
}
export function getTimesFromBlock(timeBlockId?: string) {
	if (!timeBlockId) {
		return undefined;
	}
	return timeBlockId.split('timeBlock')[1];
}

export function generateTimeBlocks(timeBlocks: ITimeBlock[]) {
	return timeBlocks.map((timeBlock) => {
		const timeString = moment(timeBlock.time).format('YYYY-MM-DDTHH:mm');
		const timeBlockConverted = {
			id: generateTimeBlockId(timeString),
			label: timeString,
		};
		return timeBlockConverted;
	});
}

export const getInitialWorkingTime = (time: string) => {
	const splited = time.slice().split('T');
	const spitedTime = splited[1];
	const date = splited[0];
	const niceTime = toTimeZone(`${moment().format('YYYY-MM-DD')}T${spitedTime}`)
	if (date.includes('2')) {
		niceTime.add(1, 'day');
	}
	return niceTime;
};

export const toTimeZone = (date?: Date | string): moment.Moment => {
	return momentTz
		.utc(date)
		.tz(
			configureStore().store.getState().BranchReducer.currentBranch
				?.localTimeZone || momentTz.tz.guess()
		);
};

export const fromTimeZoneStringToUtc = (
	dateTime: string,
	format = 'YYYY-MM-DDTHH:mm'
): string => {
	return momentTz
		.tz(
			dateTime,
			configureStore().store.getState().BranchReducer.currentBranch
				?.localTimeZone || momentTz.tz.guess()
		)
		.utc()
		.format(format);
};
