import { ReduxHelper } from 'helpers';
import { IBooking, IBookingDetail, ICustomer, IEmployee } from 'models';
import {
	IStylistAndBlockTimeRequest,
	IGetBookingsRequest,
	IUpdateBookingStatusRequest,
} from 'models/RequestModels';
import { IStylistAndTimeBlockResponse } from 'models/ResponseModels';

export const prefix = 'BOOKING';

const getBookingsByBranch = ReduxHelper.generateActions<
	IGetBookingsRequest,
	IBooking[]
>(`${prefix}/GET_BOOKING_BY_BRANCH`);

const changeBookingDetail = ReduxHelper.generateLocalAction<{
	index: number;
	bookingDetail: Partial<IBookingDetail>;
}>(`${prefix}/CHANGE_BOOKING_DETAIL`);

const addExtraAmount = ReduxHelper.generateLocalAction<{
	index: number;
	amount: number;
	note: string;
}>(`${prefix}/ADD_EXTRA_AMOUNT`);

const addBookingDetail = ReduxHelper.generateLocalAction(
	`${prefix}/ADD_BOOKING_DETAIL`
);
const deleteChoosingService = ReduxHelper.generateLocalAction<number>(
	`${prefix}/DELETE_CHOOSING_SERVICE`
);
const getAvailableStylistAndTimeBlocks = ReduxHelper.generateActions<
	{
		index: number;
		bookingDetail: IStylistAndBlockTimeRequest;
	},
	{
		index: number;
		bookingDetail: IStylistAndTimeBlockResponse;
	}
>(`${prefix}/GET_AVAILABLE_STYLIST_AND_TIME_BLOCKS`);
const getBookingById = ReduxHelper.generateActions<string, IBooking>(
	`${prefix}/GET_BOOKING_BY_ID`
);
const selectTimeStart = ReduxHelper.generateLocalAction<string>(
	`${prefix}/SELECT_TIME_START`
);
const selectCustomer = ReduxHelper.generateLocalAction<Partial<ICustomer>>(
	`${prefix}/SELECT_CUSTOMER`
);
const removeCustomer = ReduxHelper.generateLocalAction(
	`${prefix}/REMOVE_CUSTOMER`
);
const resetBooking = ReduxHelper.generateLocalAction(`${prefix}/RESET_BOOKING`);
const createBooking = ReduxHelper.generateActions<Partial<IBooking>, IBooking>(
	`${prefix}/CREATE_BOOKING`
);
const editBooking = ReduxHelper.generateActions<Partial<IBooking>, IBooking>(
	`${prefix}/EDIT_BOOKING`
);
const updateStatus = ReduxHelper.generateActions<
	IUpdateBookingStatusRequest,
	IBooking
>(`${prefix}/UPDATE_STATUS_BOOKING`);
const getCustomerByPhone = ReduxHelper.generateActions<string, ICustomer>(
	`${prefix}/GET_CUSTOMER`
);
const editCustomer = ReduxHelper.generateActions<Partial<ICustomer>>(
	`${prefix}/EDIT_CUSTOMER`
);
const addCustomer = ReduxHelper.generateActions<
	Partial<ICustomer>,
	Partial<ICustomer>
>(`${prefix}/ADD_CUSTOMER`);
const getTodayBookings = ReduxHelper.generateActions<undefined, IBooking[]>(
	`${prefix}/GET_TODAY_BOOKINGS`
);
const socketUpdateBooking = ReduxHelper.generateLocalAction<IBooking>(
	`${prefix}/SOCKET_UPDATE_BOOKING`
);

const setIsNewCustomer = ReduxHelper.generateLocalAction<boolean>(
	`${prefix}/SET_IS_NEW_CUSTOMER`
);

const createBookingWithNewCustomer = ReduxHelper.generateActions<{
	customer: Partial<ICustomer>;
	booking: Partial<IBooking>;
}>(`${prefix}/CREATE_BOOKING_WITH_NEW_CUSTOMER`);

const updatePromotionDiscount = ReduxHelper.generateLocalAction<{ id: string; promotionDiscount: number }[]>(
	`${prefix}/UPDATE_PROMOTION_DISCOUNT`
);

export {
	getBookingsByBranch,
	getAvailableStylistAndTimeBlocks,
	changeBookingDetail,
	addExtraAmount,
	deleteChoosingService,
	getBookingById,
	selectTimeStart,
	selectCustomer,
	removeCustomer,
	createBooking,
	resetBooking,
	getCustomerByPhone,
	editCustomer,
	addCustomer,
	editBooking,
	updateStatus,
	addBookingDetail,
	getTodayBookings,
	socketUpdateBooking,
	setIsNewCustomer,
	createBookingWithNewCustomer,
	updatePromotionDiscount,
};
