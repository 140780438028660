import { ModalProps, Space } from 'antd';
import ModalHelper from 'helpers/modalHelper';
import React from 'react';

interface ICheckOutBaseModal extends ModalProps {
	title: string;
	onOk(): void;
	onCancel(): void;
	disableOk?: boolean;
	hideSaveBtn?: boolean;
	btnOkHidden?: boolean;
}

export default class CheckOutBaseModal extends React.Component<ICheckOutBaseModal> {
	render() {
		return (
			<ModalHelper
				width={this.props.width}
				visible={true}
				title={this.props.title}
				onOk={() => this.props.onOk()}
				onCancel={() => this.props.onCancel()}
				// footer={this.renderModalFooter()}
				disableOk={this.props.disableOk}
				btnOkHidden={this.props.btnOkHidden}
			>
				<Space size="middle" direction="vertical" style={styles.maxWidth}>
					{this.props.children}
				</Space>
			</ModalHelper>
		);
	}

	// renderModalFooter() {
	// 	return (
	// 		<Row gutter={8}>
	// 			<Col span={this.props.hideSaveBtn ? 24 : 12}>
	// 				<Button
	// 					onClick={this.props.onCancel}
	// 					type="ghost"
	// 					size="large"
	// 					style={styles.maxWidth}
	// 				>
	// 					{I18n.t(_t(translations.cancel))}
	// 				</Button>
	// 			</Col>
	// 			{!this.props.hideSaveBtn && (
	// 				<Col span={12}>
	// 					<Button
	// 						onClick={this.props.onOk}
	// 						type="primary"
	// 						size="large"
	// 						style={styles.maxWidth}
	// 						disabled={this.props.disableOk}
	// 					>
	// 						{I18n.t(_t(translations.save))}
	// 					</Button>
	// 				</Col>
	// 			)}
	// 		</Row>
	// 	);
	// }
}

const styles = {
	maxWidth: { width: '100%' },
	textCenter: { textAlign: 'center' } as React.CSSProperties,
};
