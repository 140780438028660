import { Typography } from 'antd';
import { Input } from 'components';
import { useAppSelector } from 'helpers/hookHelpers';
import ModalHelper from 'helpers/modalHelper';
import I18n from 'i18n-js';
import _ from 'lodash';
import { IApiResponse, IBookingDetail, IErrorResponse } from 'models';
import { IPromotion, IApplyPromotionResponse } from 'models/IPromotion';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomerBookingActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import PromotionApiService from 'services/PromotionApiService';
import { translations, _t } from 'utils';

interface ICouponModalProps {
	onCancel: () => void;
	onApplySuccess: (coupon: string) => void;
	visible: boolean;
	error?: string;
}

const CouponModal = ({
	onCancel,
	onApplySuccess,
	visible,
}: ICouponModalProps) => {
	const [coupon, setCoupon] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>();
	const booking = useAppSelector(
		(state: RootState) => state.CustomerBookingReducer.booking
	);
	const customer = useAppSelector(
		(state: RootState) => state.CustomerBookingReducer.customer
	);
	const branch = useAppSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);
	const dispatch = useDispatch();

	const onSubmitCoupon = async () => {
		setIsLoading(true);
		const couponResult = (await PromotionApiService.getPromotionByCouponCode({
			couponCode: coupon,
			customerId: customer!.id!,
			date: booking!.date! as string,
			serviceIds:
				booking?.bookingDetails?.map((detail) => detail.itemId!) || [],
			branchId: branch?.id || '',
		})) as IApiResponse<IPromotion>;
		if (couponResult.succeeded) {
			const applyResult = (await PromotionApiService.applyPromotion({
				...booking!,
				applyPromotionDetails: [
					...booking!.bookingDetails!.map((element) => ({
						...element,
						stylistId:
							element.stylistId === 'Anyone' ? undefined : element.stylistId,
					})),
				] as any,
				couponCodes: `${booking?.couponCodes || ''}${
					booking?.couponCodes ? ',' : ''
				}${coupon}`,
				customerId: customer?.id,
				branchId: branch?.id,
			})) as IApiResponse<IApplyPromotionResponse>;
			if (applyResult.succeeded) {
				const applyDetails = applyResult.data!.applyPromotionDetails!.map(
					(detail) => {
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const { id, stylistId, ..._rest } = detail as any;
						return _rest;
					}
				);
				const bookingDetails = booking?.bookingDetails!.map(
					(element, index) => ({
						...element,
						...applyDetails[index],
					})
				) as Partial<IBookingDetail>[];
				dispatch(
					CustomerBookingActions.updateBooking.request({
						...booking,
						...applyResult.data,
						bookingDetails: bookingDetails,
					})
				);
				setIsLoading(false);
				setCoupon(coupon);
				onApplySuccess(coupon);
			} else {
				setErrorMsg((applyResult as IErrorResponse).message[0].Text);
				setIsLoading(false);
			}
		} else {
			setErrorMsg((couponResult as IErrorResponse).message[0].Text);
			setIsLoading(false);
		}
	};

	return (
		<ModalHelper
			title={I18n.t(_t(translations.customerBooking.applyCoupon))}
			onCancel={onCancel}
			onOk={() => onSubmitCoupon()}
			visible={visible}
			isLoading={isLoading}
		>
			<Typography.Title level={5}>
				{I18n.t(_t(translations.customerBooking.couponCode))}
			</Typography.Title>
			<Input
				onFocus={() => setErrorMsg(undefined)}
				onChange={(e) => setCoupon(e.target.value)}
				onPressEnter={() => onSubmitCoupon()}
				disabled={isLoading}
				autoFocus
			/>
			{errorMsg ? (
				<Typography.Text type="danger">{errorMsg}</Typography.Text>
			) : null}
		</ModalHelper>
	);
};

export default CouponModal;
