import { IUser } from 'models';
import { IShop } from 'models/IShop';
import {
	ILoginRequest,
	IForgotPasswordRequest,
	IResetPasswordRequest,
	IShopRegister,
} from 'models/RequestModels';
import { BaseApiService } from 'services/BaseApiService';

class AuthenticationApiService extends BaseApiService {
	public authenticate = (loginRequest: ILoginRequest) =>
		this.post<ILoginRequest, IUser>('/identity/authenticate', loginRequest);
	public forgotPassword = (forgotRequest: IForgotPasswordRequest) =>
		this.post<IForgotPasswordRequest, any>(
			'/identity/forgot-password',
			forgotRequest
		);
	public resetPassword = (resetPasswordRequest: IResetPasswordRequest) =>
		this.post<IResetPasswordRequest, string>(
			'/identity/reset-password',
			resetPasswordRequest
		);
	public register = (shop: IShopRegister) =>
		this.post<IShopRegister, IShop>('/identity/register', shop);
}

export default new AuthenticationApiService(false);
