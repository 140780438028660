import React from 'react';
import { Select, SelectProps } from 'antd';


interface IProps extends SelectProps<any> {
    // data: Array<any>
}

export default class AppSelect extends React.Component<IProps> {
    render() {
        return (
            <Select
                {...this.props}
                showSearch
                style={{ width: '100%', }}
                optionFilterProp="children"
            >
                {/* {this.props.data.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)} */}
                {this.props.children}
            </Select>
        );
    }

}
