/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Typography, Layout, List, Spin } from 'antd';
import styles from './styles';
import InfiniteScroller from 'react-infinite-scroller';
import { I18n, _t, translations } from 'utils';
import GlobalStyles from 'GlobalStyles';
import { IBookingActivity, IErrorResponse } from 'models';
import moment from 'moment';
import { IPaginateRequest } from 'models/RequestModels';
import BookingActivityItem from './BookingActivityItem';
import { IPaginateResponse } from 'models/ResponseModels';
import AdminDashboardApiService from 'services/AdminDashboardApiService';
import { AlertHelper } from 'helpers';

const { Title } = Typography;
const { Header } = Layout;

interface IBookingActivityProps {}

const BookingActivity = (props: IBookingActivityProps) => {
	const [isHover, setIsHover] = useState<boolean>(false);
	const [bookingActivity, setBookingActivity] = useState<IPaginateResponse<
		IBookingActivity[]
	> | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		getBookingActivity(baseRequest);
	}, []);

	const onLoadMore = async () => {
		if (bookingActivity?.nextPage != null) {
			getBookingActivity({
				...baseRequest,
				pageNumber: bookingActivity?.pageNumber! + 1,
			});
		}
	};

	const getBookingActivity = async (request: IPaginateRequest) => {
		setIsLoading(true);
		const result = (await AdminDashboardApiService.getBookingActivity(
			request
		)) as IPaginateResponse<IBookingActivity[]>;
		setIsLoading(false);
		if (result.succeeded) {
			if (request.pageNumber === 1) {
				setBookingActivity(result);
			} else {
				setBookingActivity({
					...result,
					data: [...bookingActivity?.data!, ...result.data!],
				});
			}
		} else {
			const error = result as IErrorResponse;
			AlertHelper.showError(error);
		}
	};

	const baseRequest = {
		fromDate: moment().startOf('date').subtract(7, 'day').toDate(),
		toDate: moment().endOf('date').add(7, 'day').toDate(),
		pageNumber: 1,
		pageSize: 10,
		orderBy: 'date desc',
	};

	return (
		<div
			style={isHover ? styles.listContainerHover : styles.listContainer}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<Header style={styles.listHeader}>
				<Title level={4} style={GlobalStyles.textBold}>
					{I18n.t(_t(translations.dashboard.bookingActivity))}
				</Title>
			</Header>
			<div style={styles.theListContainer}>
				<InfiniteScroller
					pageStart={0}
					loadMore={onLoadMore}
					initialLoad={false}
					hasMore={!isLoading && bookingActivity?.nextPage !== undefined}
					useWindow={false}
					threshold={300}
				>
					<List
						dataSource={bookingActivity ? bookingActivity?.data! : []}
						renderItem={(item: IBookingActivity) => (
							<BookingActivityItem showMonth bookingActivity={item} />
						)}
					>
						{isLoading && (
							<div style={styles.listLoadingComponent}>
								<Spin size="large" />
							</div>
						)}
					</List>
				</InfiniteScroller>
			</div>
		</div>
	);
};

export default BookingActivity;
