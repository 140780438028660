import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IBillingDetail } from 'models';
import { BillingActions } from 'redux/actions';

interface IBillingReducer {
	localRefundDetails: Partial<IBillingDetail>[];
}

const initialState: IBillingReducer = {
	localRefundDetails: [],
};

const setRefundLocal = (
	state: IBillingReducer,
	action: PayloadAction<Partial<IBillingDetail>[]>
) => {
	state.localRefundDetails = action.payload;
};

const addRefundLocal = (
	state: IBillingReducer,
	action: PayloadAction<Partial<IBillingDetail>>
) => {
	state.localRefundDetails.push({
		// ...action.payload,
		// id: undefined,
		...action.payload,
		id: undefined,
		billDetailId: action.payload.id,
		amount: action.payload.amount,
		tax: action.payload.tax,
	});
};

const removeRefundLocal = (
	state: IBillingReducer,
	action: PayloadAction<string>
) => {
	state.localRefundDetails = state.localRefundDetails.filter(
		(e) => e.billDetailId !== action.payload
	);
};

const billingReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(BillingActions.addRefundLocal.request, addRefundLocal)
		.addCase(BillingActions.removeRefundLocal.request, removeRefundLocal)
		.addCase(BillingActions.setRefundLocal.request, setRefundLocal);
});

export default billingReducer;
