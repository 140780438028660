import React from 'react';
import { Layout, LayoutProps, Spin } from 'antd';
import { kStyles } from 'GlobalStyles';

const Spinner = (props: LayoutProps) => {
	return (
		<Layout style={styles.spinLayout} {...props}>
			<Spin style={styles.spin} size="large" />
		</Layout>
	);
};

const styles: kStyles = {
	spinLayout: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		background: 'none',
		padding: 16,
	},
	spin: { margin: '0 auto' },
};

export default Spinner;
