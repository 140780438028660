const styles = {
	colContainer: {
		padding: 16,
		backgroundColor: 'white',
	},
	divider: {
		width: '100%',
		margin: '16px 0',
	},
	btnAddNew: {
		width: '100%',
		color: '#037aff',
		boxShadow: '0 4px 2px -2px #8080802b',
		marginBottom: '2px',
	},
	list: {
		backgroundColor: 'white',
		padding: 16,
	},
	listItem: {
		cursor: 'pointer',
	},
	maxWidth: {
		minWidth: '100%',
	},
	threeDotBtn: {
		width: '100%',
		justifyContent: 'flex-end',
	},
	row: {
		alignItems: 'center',
	},
	col: {
		minWidth: '100%',
		alignItems: 'center',
	},
	dividerTabPane: {
		width: '100%',
		margin: 0,
	},
	tabPane: {
		minWidth: '100%',
		margin: 0,
	},
	noMargin: {
		margin: 0,
	},
	textSize: {
		fontSize: 16,
	},
	btnConfirm: {
		width: '100%',
	},
	icClose: {
		marginLeft: 10,
		cursor: 'pointer',
	} as React.CSSProperties,
	noPaddingCol: {
		paddingInline: 0,
		width: '100%',
	},
	maxWidthSpace: {
		minWidth: '100%',
		marginTop: 16,
	},
	spinLayout: {
		height: '100vh',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	rightHeaderContainer: {
		position: 'absolute',
		right: 0,
		width: 300,
	} as React.CSSProperties,
	saleGiftCardChooseCustomer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	btnWalkin: {
		width: '48%',
		color: '#037aff',
		boxShadow: '0 4px 2px -2px #8080802b',
		marginBottom: '2px',
	},
};

export default styles;
