import { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { IApiResponse, IErrorResponse } from 'models';
import { IBranch } from 'models/IBranch';
import { ICurrentUserResponse } from 'models/ResponseModels';
import { put, takeEvery, call } from 'redux-saga/effects';
import {
	AppConfigActions,
	AuthenticationActions,
	BranchActions,
	EmployeeActions,
	UserActions,
} from 'redux/actions';
import { history } from 'routers';
import BranchApiService from 'services/BranchApiService';
import UserApiService from 'services/UserApiService';

function* getCurrentUser(action: PayloadAction<string>) {
	try {
		const result: IApiResponse<ICurrentUserResponse> = yield call(
			UserApiService.getCurrentUserInfo
		);
		if (result.succeeded) {
			yield put(UserActions.getCurrentUserInfo.success(result.data!));
			const employee = result.data?.employee;
			if (
				!_.isEmpty(result.data?.user) &&
				(result.data?.user?.userRoles?.includes('Admin') ||
					result.data?.user?.userRoles?.includes('Manager'))
			) {
				yield put(
					AuthenticationActions.setAdminAccount.request(
						result.data?.user?.userName!
					)
				);
			}
			if (!_.isEmpty(employee) && !_.isEmpty(employee?.branchId)) {
				yield put(BranchActions.getBranchById.request(employee?.branchId!));
				yield put(EmployeeActions.getAllEmployees.request(employee?.branchId!));
			} else {
				yield put(
					BranchActions.getBranchesByShopId.request(result.data?.user?.shopId!)
				);

				// if (branches.succeeded && !_.isEmpty(branches.data)) {
				// 	yield put(
				// 		EmployeeActions.getAllEmployees.request(branches.data![0].id)
				// 	);
				// }
			}
			yield put(AppConfigActions.getInitialData.request());
			// yield put(BookingActions.getTodayBookings.request());
			if (history.location.pathname.includes('/login')) {
				history.push('/dashboard');
			}
		} else {
			const error = result as IErrorResponse;
			yield put(UserActions.getCurrentUserInfo.failed(error));
		}
	} catch (error) {
		yield put(UserActions.getCurrentUserInfo.failed(error));
	}
}

export function* UserWatcher() {
	yield takeEvery(UserActions.getCurrentUserInfo.requestName, getCurrentUser);
}
