import { AvatarProps } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { StringHelper } from 'helpers';
import _ from 'lodash';
import React from 'react';

interface IAvatarProps extends AvatarProps {
	name: string;
	fullName?: boolean;
	color?: string;
}

export default class AppAvatar extends React.Component<IAvatarProps> {
	render() {
		var backGroundStyle = {};
		if (this.props.color) {
			backGroundStyle = {
				backgroundColor: this.props.color,
			};
		}
		const name = this.props.fullName
			? `${this.props.name || ''}`
			: StringHelper.getShortName(`${this.props.name}`);
		return (
			<Avatar
				src={!_.isEmpty(this.props.src) ? this.props.src : null}
				style={_.merge(backGroundStyle, { color: 'black' }, this.props.style)}
				{...this.props}
			>
				{_.isEmpty(this.props.src) ? name?.toUpperCase() : null}
			</Avatar>
		);
	}
}
