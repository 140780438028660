import React, { Component } from 'react';
import {
	Col,
	Row,
	Space,
	Input,
	Table,
	Button,
	Typography,
	TablePaginationConfig,
} from 'antd';
import { EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { I18n, _t, translations } from 'utils';
import _ from 'lodash';
import styles from '../styles';
import { Avatar } from 'components';
import { IApiResponse, ICustomer } from 'models';
import CustomerApiService from 'services/CustomerApiService';
import { IComponentProps } from 'components';
import { withRouter } from 'react-router';
import { CurrencyHelper, StringHelper } from 'helpers';
import moment from 'moment';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import { BranchActions } from 'redux/actions';
import { history } from 'routers';
import './styles.css'

interface ICustomerListProps extends ReturnType<typeof mapStateToProps> {}
interface ICustomersListState {
	pagination: TablePaginationConfig;
	customers: ICustomer[];
	isLoading: boolean;
	searchText: string;
}

const PAGE_SIZE = 10;
class ListCustomer extends Component<ICustomerListProps, ICustomersListState> {
	constructor(props: ICustomerListProps) {
		super(props);
		this.state = {
			pagination: {
				current: 1,
				pageSize: PAGE_SIZE,
				showTotal: (total: any) => `Total ${total} items`,
			},
			customers: [],
			isLoading: false,
			searchText: '',
		};
		this.onChangeDebounce = _.debounce(this.onChangeDebounce, 1000);
	}

	componentDidMount() {
		this.onTableChange(1, 'ascend');
	}
	componentDidUpdate(
		prevProps: ICustomerListProps,
		prevState: ICustomersListState
	) {
		if (prevProps.currentAction !== this.props.currentAction) {
			if (
				this.props.currentAction === BranchActions.getBranchById.requestName
			) {
				this.onTableChange(1, 'ascend');
			}
		}
	}
	render() {
		return (
			<>
				<Space direction="vertical" style={styles.container} size="large">
					<Row>
						<Col style={{ width: '100%' }}>
							{this.renderHeader()}
							{this.renderCustomerTable()}
						</Col>
					</Row>
				</Space>
			</>
		);
	}
	renderHeader = () => {
		return (
			<Row
				gutter={[16, 16]}
				style={{ alignItems: 'center', paddingBottom: 20 }}
			>
				<Col md={12}>
					<Input
						allowClear
						prefix={<SearchOutlined />}
						style={styles.maxWidth}
						placeholder={I18n.t(_t(translations.customerList.search))}
						onChange={(e) => this.onSearch(e)}
					/>
				</Col>
				<Col md={12}>
					<Space
						style={{ justifyContent: 'flex-end', width: '100%' }}
						size="small"
					>
						<Button
							icon={<PlusOutlined />}
							href="/customer/add-new"
							type="primary"
						>
							{I18n.t(_t(translations.addNew))}
						</Button>
					</Space>
				</Col>
			</Row>
		);
	};
	renderCustomerTable() {
		return (
			<>
				<Table
					style={{ paddingBottom: 24, cursor: 'pointer' }}
					scroll={{
						y: '50vh',
						scrollToFirstRowOnChange: true,
						x: 'max-content',
					}}
					size="large"
					dataSource={this.state.customers}
					columns={[
						{
							title: (
								<Typography.Title level={5} className="title-table">
									{I18n.t(_t(translations.customerList.name))}
								</Typography.Title>
							),
							render: (text, record, index) => {
								const customer = record as ICustomer;
								const customerName = `${customer.firstName || ''} ${customer.lastName || ''}`;
								return (
									<Space>
										<Avatar name={customerName}></Avatar>
										<Typography.Text>{customerName}</Typography.Text>
									</Space>
								);
							},
							sorter: true,
							width: '15%',
						},
						{
							title: (
								<Typography.Title level={5} className="title-table">
									{I18n.t(_t(translations.customerList.mobile))}
								</Typography.Title>
							),
							dataIndex: 'phone',
							render: (text: string) => {
								return (
									<Button
										type="link"
										onClick={(e) => {
											e.stopPropagation();
										}}
									>
										<Typography.Link
											style={styles.link}
											href={`callto:${text}`}
										>
											{StringHelper.formatPhoneNumber(text)}
										</Typography.Link>
									</Button>
								);
							},
							width: '10%',
						},
						{
							title: (
								<Typography.Title level={5} className="title-table">
									{I18n.t(_t(translations.email))}
								</Typography.Title>
							),

							dataIndex: 'email',
							render: (text: string) => {
								return (
									<Button
										type="link"
										onClick={(e) => {
											e.stopPropagation();
										}}
									>
										<Typography.Link
											style={styles.link}
											href={`mailto:${text}`}
										>
											{text}
										</Typography.Link>
									</Button>
								);
							},
							width: '10%',
						},
						{
							title: (
								<Typography.Title level={5}>
									{I18n.t(_t(translations.customerList.address))}
								</Typography.Title>
							),
							render: (text, record, index) => {
								const customer = record as ICustomer;
								let address = '';
								!_.isEmpty(customer.address)
									? !_.isEmpty(customer.address.street) ||
									  !_.isEmpty(customer.address.city) ||
									  !_.isEmpty(customer.address.state)
										? (address = `${customer.address.street}, ${customer.address.city}, ${customer.address.state}`)
										: (address = '')
									: (address = '');
								return (
									<Space>
										<Typography.Text>{address}</Typography.Text>
									</Space>
								);
							},
							width: '10%',
						},
						{
							title: (
								<Typography.Title level={5} className="title-table">
									{I18n.t(_t(translations.customerList.dateOfBirth))}
								</Typography.Title>
							),
							dataIndex: 'dateOfBirth',
							render: (text: string) => text && moment(text).format('LL'),
							width: '10%',
						},
						// {
						// 	title: (
						// 		<Typography.Title level={5} className="title-table">
						// 			{I18n.t(_t(translations.customerList.loyaltyPoint))}
						// 		</Typography.Title>
						// 	),
						// 	dataIndex: '',
						// 	render: () => Math.floor(Math.random() * 1000),
						// 	width: '5%',
						// },
						// {
						// 	title: (
						// 		<Typography.Title level={5} className="title-table">
						// 			{I18n.t(_t(translations.customerList.loyaltyRank))}
						// 		</Typography.Title>
						// 	),
						// 	dataIndex: ' ',
						// 	render: () => 'Unranked',
						// 	width: '5%',
						// },
						{
							title: (
								<Typography.Title level={5} className="title-table">
									{I18n.t(_t(translations.customerList.totalExpense))}
								</Typography.Title>
							),
							dataIndex: 'totalExpense',
							width: '5%',
							render: (text: number) => CurrencyHelper.formatPrice(text),
						},
						{
							title: (
								<Typography.Title level={5} className="title-table">
									{I18n.t(_t(translations.customerList.lastExpense))}
								</Typography.Title>
							),
							dataIndex: 'lastExpense',
							width: '5%',
							render: (text: number) => CurrencyHelper.formatPrice(text),
						},
						{
							title: (
								<Typography.Title level={5} className="title-table">
									{I18n.t(_t(translations.customerList.numberOfVisit))}
								</Typography.Title>
							),
							dataIndex: 'numberOfVisit',
							width: '5%',
							render: (text: number) => text,
						},
						{
							title: (
								<Typography.Title level={5} className="title-table">
									{I18n.t(_t(translations.action))}
								</Typography.Title>
							),
							dataIndex: 'id',
							render: (id) => {
								return (
									<Button
										type="link"
										style={{ color: '#037aff' }}
										icon={<EditOutlined />}
										onClick={(e) => {
											e.stopPropagation();
											history.push(`customer/edit/${id}`);
										}}
									>
										{I18n.t(_t(translations.edit))}
									</Button>
								);
							},
							width: '5%',
						},
					]}
					pagination={this.state.pagination}
					onChange={(pagination, filter, sorter) =>
						this.onTableChange(pagination.current!, sorter)
					}
					loading={this.state.isLoading}
					rowKey={(record: ICustomer) => record.id}
					onRow={this.onClickRow}
					tableLayout={'fixed'}
				/>
			</>
		);
	}

	onSearch = (e: any) => {
		let searchText = e.target.value.toLowerCase();
		this.setState({ searchText });
		this.onChangeDebounce();
	};

	onChangeDebounce = () => {
		this.onTableChange(1, 'ascend');
	};

	async onTableChange(pageNumber: number, sorter: any) {
		this.setState({ isLoading: true });
		const params = {
			pageNumber,
			pageSize: PAGE_SIZE,
			orderBy: _.isEmpty(sorter.order)
				? 'firstName,lastName'
				: sorter.order === 'ascend'
				? 'firstName,lastName'
				: 'firstName desc,lastName desc',
			searchText: this.state.searchText,
		};
		try {
			const result = (await CustomerApiService.getCustomers(
				params
			)) as IApiResponse<ICustomer[]>;
			if (result.succeeded) {
				const pagination = {
					...this.state.pagination,
					total: result.totalRecords,
					current: pageNumber,
				};
				this.setState({ pagination, customers: result.data! });
			}
			this.setState({ isLoading: false });
		} catch (error) {}
	}
	onClickRow = (record: ICustomer) => {
		return {
			// onClick: () => {
			//   this.props.history.push(`${this.props.location.pathname}/edit/${record.id}`);
			// }
			onClick: () => {
				history.push(`customer/details/${record.id}`);
			},
		};
	};
}
const mapStateToProps = (state: RootState) => ({
	currentAction: state.ReduxActionReducer['BRANCH'],
});

export default connect(mapStateToProps)(ListCustomer);
