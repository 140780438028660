import { BookingStatus, Colors } from 'utils/Consts';

export const staffColors = [
	'#f0f8ff',
	'#a2cffe',
	'#a6e7ff',
	'#87cefa',
	'#ffb07c',
	'#f3e2c6',
	'#ecdfd6',
	'#ffc5cb',
	'#cffdbc',
	'#efc0fe',
];

export function getStatusColor(status: number) {
	switch (status) {
		case BookingStatus.DELETED:
			return Colors.DELETED;
		case BookingStatus.PENDING:
			return Colors.PENDING;
		case BookingStatus.CHECKED_IN:
			return Colors.CHECKED_IN;
		case BookingStatus.CANCELED:
			return Colors.CANCELED;
		case BookingStatus.CONFIRMED:
			return Colors.CONFIRMED;
		case BookingStatus.PROCESSING:
			return Colors.PROCESSING;
		case BookingStatus.FINISHED:
		case BookingStatus.DONE:
			return Colors.FINISHED;
		case BookingStatus.ERROR:
			return Colors.ERROR;
		case BookingStatus.EXPIRED:
			return Colors.EXPIRED;
		case BookingStatus.SUSPENDED:
			return Colors.SUSPENDED;
	}
}
