import { kStyles } from './../../../GlobalStyles/index';
const styles: kStyles = {
  layout: {
    minHeight: "100vh",
  },
  loading: { margin: "0 auto" },
  seeLessStaff: { marginTop: 16, display: "none" },
  seeAllStaff: { marginTop: 16 },
  staffNameTitle: { margin: 0 },
  hiddenCol: { height: 120 },
  spinLayout: {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  avatarStyle: { marginRight: 8 },
  buttonContainer: { textAlign: "right", marginBottom: 10 },
  formContainer: { width: "100%", display: "contents", marginTop: 10, minHeight: "100vh" },
  maxWidth: {
    width: "100%",
  },
  cardBorder: {
    borderRadius: 10,
  },
  icClose: {
    fontSize: 18,
    zIndex: 1,
  },
  dividerNoMargin: {
    marginTop: 0,
  },
  maxWidthSpace: {
    minWidth: "100%",
    marginTop: 16,
  },
  cardPricing: {
    backgroundColor: "#F0F2F5",
    padding: 16,
    borderRadius: 10,
    margin: "10px 0",
  },
  btnShowStaff: {
    padding: 0,
  },
  dividerSpaceTop: {
    margin: "10px 0",
  },
  btnAddPricingOption: { paddingInline: 0 },
  staffCheckbox: { width: "100%", alignItems: "center" },
};

export default styles;
