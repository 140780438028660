import {
	Button,
	Col,
	Input,
	Modal,
	Radio,
	Row,
	Space,
	Spin,
	Typography,
} from 'antd';
import _ from 'lodash';
import React from 'react';
import { translations, _t, I18n } from 'utils';
import CheckOutBaseModal from './CheckOutBaseModal';
import styles from './styles';
import { IGiftCard } from 'models/IGiftCard';
import moment from 'moment';
import { AlertHelper } from 'helpers';
import GiftCardApiService from 'services/GiftCardApiService';
import { IApiResponse } from 'models';
import { removeSpecialCharacter } from 'helpers/StringHelper';

interface ISalesGiftCardModalProps {
	onOk(giftCard: Partial<IGiftCard>): void;
	onCancel(): void;
	currentBranchId?: string;
	shopId?: string;
	canUpdate?: boolean;
	title?: string;
	inputCodeLabel?: string;
	isVoucher?: boolean;
}

interface ISalesGiftCardModalState {
	giftCode?: string;
	amount?: number;
	showInputAmount: boolean;
	isLoading: boolean;
	showModalUpdateGiftCard: boolean;
	giftCard?: IGiftCard;
}

export default class SalesGiftCardModal extends React.Component<
	ISalesGiftCardModalProps,
	ISalesGiftCardModalState
> {
	constructor(props: ISalesGiftCardModalProps) {
		super(props);
		this.state = {
			showInputAmount: false,
			isLoading: false,
			showModalUpdateGiftCard: false,
			giftCard: undefined,
		};
	}
	inputAmountRef: any = React.createRef();
	inputCodeRef: any = React.createRef();

	AMOUNT_GIFT_CARD = [
		{
			key: 'one',
			title: '$20.00',
			callBack: () => this.chooseAmountGiftCard('one'),
		},
		{
			key: 'two',
			title: '$30.00',
			callBack: () => this.chooseAmountGiftCard('two'),
		},
		{
			key: 'three',
			title: '$50.00',
			callBack: () => this.chooseAmountGiftCard('three'),
		},
		{
			key: 'four',
			title: '$100.00',
			callBack: () => this.chooseAmountGiftCard('four'),
		},
		{
			key: 'other',
			title: 'Other',
			callBack: () => this.chooseAmountGiftCard('other'),
		},
	];
	render() {
		return (
			<CheckOutBaseModal
				title={
					!_.isEmpty(this.props.title)
						? this.props.title!
						: I18n.t(_t(translations.checkout.saleGiftCard))
				}
				onOk={() => this.onOk()}
				onCancel={this.props.onCancel}
				disableOk={
					!this.state.giftCode || this.state.amount === 0 || !this.state.amount
				}
			>
				<Spin spinning={this.state.isLoading}>
					<Space size="small" direction="vertical" style={styles.maxWidth}>
						<Col>
							<Typography.Title level={5}>
								{!_.isEmpty(this.props.inputCodeLabel)
									? this.props.inputCodeLabel!
									: I18n.t(_t(translations.checkout.giftCardCode))}
							</Typography.Title>
							<Row gutter={8}>
								<Col md={20}>
									<Input
										ref={this.inputCodeRef}
										style={styles.maxWidth}
										autoFocus={true}
										value={this.state.giftCode}
										onChange={(event) =>
											this.setState({
												giftCode: removeSpecialCharacter(event.target.value),
											})
										}
										placeholder={I18n.t(
											_t(translations.placeHolder.scanQrCode)
										)}
									/>
								</Col>
								<Col md={4}>
									<Button
										onClick={() => {
											this.inputCodeRef.current?.focus();
											this.setState({ giftCode: undefined });
										}}
										danger
									>
										{I18n.t(_t(translations.clear))}
									</Button>
								</Col>
							</Row>
						</Col>
						<Col>
							<Typography.Title level={5}>
								{I18n.t(_t(translations.checkout.amount))}
							</Typography.Title>
							<Radio.Group buttonStyle="solid" size="middle">
								<Row gutter={[8, 8]}>
									{this.AMOUNT_GIFT_CARD.map((amount) => (
										<Col span={4.8}>
											<Radio.Button
												key={amount.key}
												value={amount.key}
												style={{ minWidth: 50, textAlign: 'center' }}
												onClick={amount.callBack}
											>
												{amount.title}
											</Radio.Button>
										</Col>
									))}
									{this.state.showInputAmount ? (
										<Col>
											<Typography.Title level={5}>
												Input amount
											</Typography.Title>
											<Row gutter={8}>
												<Col md={20}>
													<Input
														ref={this.inputAmountRef}
														style={{ width: '100%' }}
														type="number"
														allowClear
														autoFocus={true}
														size="middle"
														value={this.state.amount}
														onChange={(event) =>
															this.onChange(_.toNumber(event.target.value))
														}
														placeholder="Enter number"
													/>
												</Col>
												<Col md={4}>
													<Button
														onClick={() => {
															this.inputAmountRef.current?.focus();
															this.setState({ amount: undefined });
														}}
														danger
													>
														{I18n.t(_t(translations.clear))}
													</Button>
												</Col>
											</Row>
										</Col>
									) : null}
								</Row>
							</Radio.Group>
						</Col>
					</Space>
				</Spin>
				{this.state.showModalUpdateGiftCard && (
					<CheckOutBaseModal
						title={I18n.t(_t(translations.warning))}
						onCancel={() => this.setState({ showModalUpdateGiftCard: false })}
						onOk={() => this.updateGiftCard()}
					>
						<Spin spinning={this.state.isLoading}>
							{I18n.t(_t(translations.checkout.overwrite))}
						</Spin>
					</CheckOutBaseModal>
				)}
			</CheckOutBaseModal>
		);
	}

	chooseAmountGiftCard = (amountType: string) => {
		switch (amountType) {
			case 'one':
				return this.setState({ amount: 20, showInputAmount: false });
			case 'two':
				return this.setState({ amount: 30, showInputAmount: false });
			case 'three':
				return this.setState({ amount: 50, showInputAmount: false });
			case 'four':
				return this.setState({ amount: 100, showInputAmount: false });
			case 'other':
				return this.setState({ amount: undefined, showInputAmount: true });
			default:
				return;
		}
	};

	onChange = (amount: number) => {
		if (amount === 0) {
			this.setState({ amount: undefined });
		} else {
			this.setState({ amount });
		}
	};

	onOk = async () => {
		if (!this.state.amount || !this.state.giftCode) {
			return;
		}
		console.log('currentBranchId', this.props.currentBranchId);

		const saleGiftCard: Partial<IGiftCard> = {
			giftCode: this.state.giftCode,
			title: 'No title',
			description: 'No description',
			fromDate: moment
				.utc(moment(), 'YYYY-MM-DDTHH:mm')
				.format('YYYY-MM-DDTHH:mm'),
			toDate: moment().utc().add(3, 'M').format('YYYY-MM-DDTHH:mm'),
			// amount: this.props.isVoucher ? 0 : this.state.amount!,
			amount: this.state.amount!,
			balance: this.state.amount,
			branchId: this.props.currentBranchId,
			shopId: this.props.shopId,
			status: 1,
			type: this.props.isVoucher ? 1 : undefined, // 0: GIFT CARD, 1: VOUCHER, undefined: default (0)
		};
		if (!this.props.canUpdate) {
			this.addNewGiftCard(saleGiftCard);
			return;
		}
		this.setState({ isLoading: true });
		const giftCardGetFromApi = await GiftCardApiService.getGiftCardByCode(
			this.state.giftCode
		);
		this.setState({ isLoading: false });

		if (!_.isEmpty(giftCardGetFromApi.errors)) {
			// AlertHelper.showError(giftCardGetFromApi)
			this.addNewGiftCard(saleGiftCard);
			return;
		}
		const giftCardGetFromApiSuccess =
			giftCardGetFromApi as IApiResponse<IGiftCard>;
		if (_.isEmpty(giftCardGetFromApiSuccess.data)) {
			this.addNewGiftCard(saleGiftCard);
			return;
		}

		this.setState({
			showModalUpdateGiftCard: true,
			giftCard: giftCardGetFromApiSuccess.data,
		});
	};

	addNewGiftCard = async (giftCard: Partial<IGiftCard>) => {
		this.setState({ isLoading: true });
		const addGiftCardResponse = await GiftCardApiService.addGiftCard(giftCard);
		this.setState({ isLoading: false });
		if (!_.isEmpty(addGiftCardResponse.errors)) {
			AlertHelper.showError(addGiftCardResponse);
			return;
		}
		const addGiftCardSuccess = addGiftCardResponse as IApiResponse<IGiftCard>;
		this.props.onOk(addGiftCardSuccess.data!);
	};

	updateGiftCard = async () => {
		let giftCard: Partial<IGiftCard> = {
			...this.state.giftCard,
			amount: this.state.amount,
			balance: this.state.amount,
		};
		this.setState({ isLoading: true });
		const updateGiftCardResponse = await GiftCardApiService.updateGiftCard(
			giftCard
		);
		this.setState({ isLoading: false, showModalUpdateGiftCard: false });
		if (!_.isEmpty(updateGiftCardResponse.errors)) {
			AlertHelper.showError(updateGiftCardResponse);
			return;
		}
		const updateSuccess = updateGiftCardResponse as IApiResponse<IGiftCard>;
		this.props.onOk(updateSuccess.data!);
	};
}
