import React, { Component, createRef } from 'react';
import { Form, Checkbox, Typography, FormInstance, Row, Col, Space } from 'antd';
import { connect } from 'react-redux';
import { AuthenticationActions, ErrorActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import { Dispatch } from 'redux';
import {
	EyeInvisibleOutlined,
	EyeOutlined,
	EyeTwoTone,
	LockOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { Input, Button } from 'components';
import styles from './styles';
import { I18n, _t, translations } from 'utils';
import { RouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { IErrorResponse } from 'models';
import { ILoginRequest } from 'models/RequestModels';
import { GenerateHelper } from 'helpers';
import { AlertHelper } from 'helpers';
import { electron } from 'ipc';
const { Title } = Typography;

interface ILoginProps
	extends ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps>,
		RouteComponentProps {}
interface ILoginState {}

class Login extends Component<ILoginProps, ILoginState> {
	formRef = createRef<FormInstance>();
	userRef = createRef<any>();
	passRef = createRef<any>();

	state = {
		hiddenPassword: true,
	};

	componentDidMount() {
		// const data = this.initialLoad();
		// this.formRef.current?.setFieldsValue({
		//   userNameOrEmail: data?.userNameOrEmail,
		//   // password: data?.password,
		//   rememberMe: data?.rememberMe,
		// });
	}

	componentDidUpdate(prevProps: ILoginProps) {
		if (prevProps.authenticateAction !== this.props.authenticateAction) {
			if (
				this.props.authenticateAction ===
				AuthenticationActions.authenticate.failedName
			) {
				return AlertHelper.showError(this.props.error!);
			}
		}
	}

	render() {
		return (
			<Form
				ref={this.formRef}
				name="normal_login"
				style={styles.nailForm}
				onFinish={(values) => this.onFormFinish(values)}
			>
				<Space direction="vertical" style={{ width: '100%' }}>
				{this.renderTitle()}
				{this.renderInput()}
				{this.renderRemember()}
				{this.renderLoginButton()}
				</Space>
			</Form>
		);
	}

	renderTitle() {
		return <Title level={3}>{I18n.t(_t(translations.login.loginTitle))}</Title>;
	}

	renderInput() {
		return (
			<>
				<Form.Item
					name="userNameOrEmail"
					rules={[
						{
							message: I18n.t(_t(translations.login.inputUsernameMessage)),
							required: true,
						},
					]}
					style={styles.formItem}
				>
					<Input
						ref={this.userRef}
						prefix={<UserOutlined className="site-form-item-icon" />}
						placeholder={I18n.t(_t(translations.login.loginUserNameOrEmail))}
						onChange={(event) =>
							this.setState({ userNameOrEmail: event.target.value })
						}
						onPressEnter={() => this.passRef.current.focus()}
					/>
				</Form.Item>
				<Form.Item
					name="password"
					rules={[
						{
							message: I18n.t(_t(translations.login.inputPasswordMessage)),
							required: true,
						},
					]}
					style={styles.formItem}
				>
					<Input.Password
						ref={this.passRef}
						prefix={<LockOutlined className="site-form-item-icon" />}
						placeholder={I18n.t(_t(translations.login.loginPassword))}
						onChange={(event: any) =>
							this.setState({ password: event.target.value })
						}
						onPressEnter={() => this.formRef.current?.submit()}
						iconRender={(visible: any) =>
							visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
						}
					/>
				</Form.Item>
			</>
		);
	}

	renderRemember() {
		return (
			<>
				<Form.Item
				 style={styles.formItem}
				>
					<Form.Item
						name="rememberMe"
						valuePropName="checked"
						initialValue={true}
						style={styles.formItem}
						>
						<Checkbox
							onChange={(event) =>
								this.setState({ isRemember: event.target.checked })
							}
						>
							{I18n.t(_t(translations.login.loginRememberMe))}
						</Checkbox>
					</Form.Item>

					<Link to={'forgot'} style={styles.forgotText}>
						{I18n.t(_t(translations.login.loginForgotPassword))}
					</Link>
				</Form.Item>
			</>
		);
	}

	renderLoginButton() {
		return (
			<Form.Item
			style={styles.formItem}
			>
				<Button
					type="primary"
					htmlType="submit"
					loading={this.props.isLoading}
					size="large"
				>
					{I18n.t(_t(translations.login.login))}
				</Button>
				{electron ? (
					<Row gutter={[16, 16]}>
						<Col span={12}>
							<Button
								onClick={() => electron.ipcRenderer.minimize()}
								size="large"
							>
								{I18n.t(_t(translations.profileMenu.minimize))}
							</Button>
						</Col>
						<Col span={12}>
							<Button
								onClick={() => electron.ipcRenderer.quit()}
								danger
								size="large"
							>
								{I18n.t(_t(translations.profileMenu.quit))}
							</Button>
						</Col>
					</Row>
				) : null}
				<a
					href={`http://partners.${process.env.REACT_APP_PRODUCTION_URL}/login`}
					style={styles.otherShop}
				>
					{I18n.t(_t(translations.login.loginToOtherShop))}
				</a>
			</Form.Item>
		);
	}

	onFormFinish = (values: ILoginRequest) => {
		this.props.login({
			userNameOrEmail: values.userNameOrEmail,
			password: values.password,
			rememberMe: values.rememberMe,
			deviceId: GenerateHelper.uuid(),
		});
	};

	initialLoad = () => {
		const savedLoginData = localStorage.getItem('login');
		if (savedLoginData) {
			const data = JSON.parse(savedLoginData) as ILoginRequest;
			return data;
		}
	};
}

const mapStateToProps = (state: RootState) => ({
	isLoading: state.AppConfigReducer.showLoading,
	error: state.ErrorReducer.error,
	authenticateAction: state.ReduxActionReducer['AUTHENTICATION'],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	login: (data: ILoginRequest) => {
		dispatch(AuthenticationActions.authenticate.request(data));
	},
	dispatchError: (error: IErrorResponse) => {
		dispatch(AuthenticationActions.authenticate.failed(error));
	},
	clearError: () => {
		dispatch(ErrorActions.clearError.request());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
