import { ReduxHelper } from 'helpers';
import { Const } from 'utils';

export const prefix = 'SETTINGS';

const savePrinterIP = ReduxHelper.generateLocalAction<{
	printerIp: string;
	printerPort: string;
	type?: Const.PRINTER_CONNECTION;
}>(prefix + '/SAVE_PRINTER_IP');
const saveReceipt = ReduxHelper.generateLocalAction<{
	type: Const.RECEIPT_PRINT_TYPE;
}>(prefix + '/SAVE_RECEIPT_TYPE');
const setEnableTicketPrinting = ReduxHelper.generateLocalAction<boolean>(
	`${prefix}/SET_ENABLE_TICKET_PRINT`
);

export { savePrinterIP, saveReceipt, setEnableTicketPrinting };
