import { Button, Col, Input, Row, Typography } from 'antd';
import React from 'react';
import CheckOutBaseModal from './CheckOutBaseModal';
import styles from './styles';
import { translations, _t, I18n } from 'utils';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';

interface IConfirmModalProps extends ReturnType<typeof mapStateToProps> {
	onOk(employee: string): void;
	onCancel(): void;
}

interface IConfirmModalState {
	employeeCode?: string;
	error?: string;
}

class ConfirmModal extends React.Component<
	IConfirmModalProps,
	IConfirmModalState
> {
	constructor(props: IConfirmModalProps) {
		super(props);
		this.state = {};
	}
	inputRef: any = React.createRef();

	render() {
		return (
			<CheckOutBaseModal
				title={I18n.t(_t(translations.checkout.confirm))}
				onOk={() => this.onOk()}
				onCancel={this.props.onCancel}
				disableOk={!this.state.employeeCode}
			>
				<Col>
					<Typography.Title level={4}>
						{I18n.t(_t(translations.checkout.employeeCode))}
					</Typography.Title>
					<Row gutter={8}>
						<Col md={20}>
							<Input
								ref={this.inputRef}
								style={styles.inputNumber}
								onPressEnter={this.onOk}
								autoFocus={true}
								type="number"
								value={this.state.employeeCode}
								onChange={(event) =>
									this.setState({
										employeeCode: event.target.value.toUpperCase(),
									})
								}
							/>
						</Col>
						<Col md={4}>
							<Button
								onClick={() => {
									this.setState({ employeeCode: undefined, error: undefined });
									this.inputRef.current?.focus();
								}}
								danger
							>
								{I18n.t(_t(translations.clear))}
							</Button>
						</Col>
					</Row>

					{this.state.error ? (
						<Typography.Text type="danger">{this.state.error}</Typography.Text>
					) : null}
				</Col>
			</CheckOutBaseModal>
		);
	}

	onOk = () => {
		const listEmployeeCode = this.props.employees?.map(
			(employee) => employee.code
		);
		if (this.state.employeeCode) {
			if (listEmployeeCode?.includes(this.state.employeeCode)) {
				this.props.onOk(this.state.employeeCode);
			} else {
				this.setState({
					error: I18n.t(_t(translations.checkout.employeeCodeNotFound)),
				});
			}
			return;
		}
	};
}

const mapStateToProps = (state: RootState) => ({
	employees: state.EmployeeReducer.employees,
});

export default connect(mapStateToProps)(ConfirmModal);
