import { Button, Col, Input, Radio, Row, Space, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { translations, _t, I18n } from 'utils';
import { DiscountType, DiscounterType } from 'utils/Consts';
import styles from './styles';
import { IBillingDetail, IService } from 'models';
import ModalHelper from 'helpers/modalHelper';
import { BookingHelper, CurrencyHelper } from 'helpers';
import _ from 'lodash';

interface IAddRefundModalProps extends ReturnType<typeof mapStateToProps> {
	onOk(amount: number): void;
	onCancel(): void;
	billDetail: IBillingDetail;
}

interface IAddRefundModalState {
	refund?: string;
}

class AddRefundModal extends React.Component<
	IAddRefundModalProps,
	IAddRefundModalState
> {
	constructor(props: IAddRefundModalProps) {
		super(props);
		this.state = {
			refund: '0',
		};
	}
	inputRef: any = React.createRef();

	getMaxAmount = () => {
		const { discountBeforeTax, taxRate, billDetail, services } = this.props;
		const isService = !billDetail?.giftCardId ? true : false;
		const service = isService
			? services.find((element) => element.id === billDetail.itemId)
			: ({ price: billDetail.price } as IService);
		const _taxRate = isService ? taxRate : 0;
		const maxAmount =
			(discountBeforeTax
				? BookingHelper.calculateBookingDetailAmount({
						item: service,
						discount: billDetail.discount || 0,
						promotionDiscount: billDetail.promotionDiscount || 0,
				  }) < 0
					? 0
					: BookingHelper.calculateBookingDetailAmount({
							item: service,
							discount: billDetail.discount || 0,
							promotionDiscount: billDetail.promotionDiscount || 0,
					  })
				: billDetail.amount || 0) + (billDetail.extraAmount || 0);
		return maxAmount + (maxAmount * (_taxRate || 0)) / 100;
	};

	render() {
		return (
			<ModalHelper
				visible={true}
				title={I18n.t(_t(translations.billing.refund))}
				onOk={this.onOk}
				onCancel={this.props.onCancel}
				// isLoading={this.props.isLoading}
				disableOk={
					!this.state.refund ||
					_.toNumber(this.state.refund) > this.getMaxAmount()
				}
			>
				{/* {this.renderRefundTypeMethod()} */}
				{this.renderRefundInput()}
				{/* {this.renderSharingMethod()}
				{this.renderRefundReason()} */}
				{/* {this.state.isShowConfirmModal && (
					<ConfirmModal
						onCancel={() => {
							this.setState({ isShowConfirmModal: false });
						}}
						onOk={this.onConfirmRefund}
					/>
				)} */}
			</ModalHelper>
		);
	}

	// renderRefundTypeMethod() {
	// 	return (
	// 		<>
	// 			<Radio.Group
	// 				defaultValue={DiscountType.DOLLAR}
	// 				buttonStyle="solid"
	// 				style={styles.maxWidth}
	// 				onChange={this.onChangeType}
	// 			>
	// 				{this.renderRefundTypeMethodButton({
	// 					value: DiscountType.DOLLAR,
	// 					children: (
	// 						<>
	// 							<DollarCircleTwoTone twoToneColor="#52c41a" />{' '}
	// 							{I18n.t(_t(translations.money))}
	// 						</>
	// 					),
	// 				})}
	// 				{this.renderRefundTypeMethodButton({
	// 					value: DiscountType.PERCENT,
	// 					children: (
	// 						<>
	// 							<PercentageOutlined /> {I18n.t(_t(translations.percent))}
	// 						</>
	// 					),
	// 				})}
	// 			</Radio.Group>
	// 		</>
	// 	);
	// }

	renderRefundInput() {
		return (
			<>
				<Row gutter={8}>
					<Col md={16}>
						<Input
							ref={this.inputRef}
							addonAfter={I18n.t(_t(translations.currency))}
							type={'number'}
							style={styles.inputNumber}
							autoFocus={true}
							value={this.state.refund}
							onPressEnter={this.onOk}
							onChange={(event) => this.onChange(event.target.value)}
							min={0}
							max={this.getMaxAmount()}
						/>
					</Col>
					<Col md={8}>
						<Space style={styles.maxWidth} size="small">
							<Col md={12}>
								<Button
									onClick={() =>
										this.setState({
											refund: this.getMaxAmount().toString(),
										})
									}
								>
									Exact
								</Button>
							</Col>
							<Col md={12}>
								<Button
									onClick={() => {
										this.setState({ refund: undefined });
										this.inputRef.current?.focus();
									}}
									danger
								>
									{I18n.t(_t(translations.clear))}
								</Button>
							</Col>
						</Space>
					</Col>
					<Col span={24} style={{ textAlign: 'right' }}>
						<Typography.Text disabled>
							Max: {CurrencyHelper.formatPrice(this.getMaxAmount())} included
							tax.
						</Typography.Text>
					</Col>
				</Row>
			</>
		);
	}

	// renderSharingMethod() {
	// 	return (
	// 		<Col style={{ marginTop: 10 }}>
	// 			<Typography.Title level={5}>
	// 				{I18n.t(_t(translations.checkout.sharingMethod))}
	// 			</Typography.Title>
	// 			<Row style={{ marginTop: 10 }}>
	// 				<Radio.Group
	// 					defaultValue={DiscounterType.BOTH}
	// 					buttonStyle="solid"
	// 					style={styles.maxWidth}
	// 					onChange={(event) =>
	// 						this.setState({ refundSharingType: event.target.value })
	// 					}
	// 				>
	// 					{this.renderSharingMethodButton({
	// 						value: DiscounterType.BOTH,
	// 						title: I18n.t(_t(translations.checkout.both)),
	// 					})}
	// 					{this.renderSharingMethodButton({
	// 						value: DiscounterType.OWNER_ONLY,
	// 						title: I18n.t(_t(translations.checkout.ownerOnly)),
	// 					})}
	// 					{this.renderSharingMethodButton({
	// 						value: DiscounterType.EMPLOYEE_ONLY,
	// 						title: I18n.t(_t(translations.checkout.employeeOnly)),
	// 					})}
	// 				</Radio.Group>
	// 			</Row>
	// 		</Col>
	// 	);
	// }

	renderRefundTypeMethodButton({
		value,
		children,
	}: {
		value: DiscountType;
		children: JSX.Element;
	}) {
		return (
			<>
				{this.renderRadioButton({
					value: value,
					children: children,
					style: { width: '50%', textAlign: 'center' },
				})}
			</>
		);
	}

	renderSharingMethodButton({
		value,
		title,
	}: {
		value: DiscounterType;
		title: string;
	}) {
		return (
			<>
				{this.renderRadioButton({
					value: value,
					children: title,
					style: { width: '33.3%', textAlign: 'center' },
				})}
			</>
		);
	}

	renderRadioButton({
		value,
		children,
		style,
	}: {
		value: DiscountType | DiscounterType;
		children: JSX.Element | string;
		style: React.CSSProperties;
	}) {
		return (
			<Radio.Button style={style} value={value}>
				{children}
			</Radio.Button>
		);
	}

	// renderRefundReason() {
	// 	return (
	// 		<>
	// 			<Typography.Title level={5}>
	// 				{I18n.t(_t(translations.billing.refundReason))}
	// 			</Typography.Title>
	// 			<Input.TextArea
	// 				placeholder={I18n.t(_t(translations.billing.refundReasonPlaceholder))}
	// 				onChange={(e) => this.setState({ refundReason: e.target.value })}
	// 			/>
	// 		</>
	// 	);
	// }

	// onChangeType = (event: any) => {
	// 	this.setState({
	// 		type: event.target.value,
	// 		refund: undefined,
	// 		refundPercent: undefined,
	// 		addonAfter: event.target.value === DiscountType.DOLLAR ? '$' : '%',
	// 	});
	// };

	onSharingMethodChange = (event: any) => {};
	onChange = (value: string) => {
		this.setState({ refund: value });
		// const billCost = this.getTotalAmount();
		// const totalRefundValue = this.props.refund?.totalAmount || 0;
		// if (this.state.type === DiscountType.PERCENT) {
		// 	const remainPercent = _.toNumber(
		// 		(100 - (totalRefundValue / billCost) * 100).toFixed(2)
		// 	);
		// 	if (value > remainPercent) {
		// 		value = remainPercent;
		// 	}
		// 	if (value < 0) {
		// 		value = 0;
		// 	}
		// 	let refund = CurrencyHelper.priceFixed((value * billCost) / 100);
		// 	this.setState({ refund, refundPercent: value });
		// } else {
		// 	const remainValue = billCost - totalRefundValue;
		// 	if (value > remainValue) {
		// 		value = remainValue;
		// 	}
		// 	if (value < 0) {
		// 		value = 0;
		// 	}
		// 	let refundPercent = _.toNumber(((value / billCost) * 100).toFixed(2));
		// 	this.setState({
		// 		refund: CurrencyHelper.priceFixed(value),
		// 		refundPercent,
		// 	});
		// }
	};

	onOk = () => {
		this.props.onOk(Number.parseFloat(this.state.refund || ''));
	};

	// onConfirmRefund = (employeeCode: string) => {
	// 	this.setState({ isShowConfirmModal: false });
	// 	const { refundSharingType, refund, refundPercent, refundReason } =
	// 		this.state;
	// 	this.props.onOk(
	// 		refund!,
	// 		refundPercent!,
	// 		refundSharingType!,
	// 		refundReason!,
	// 		employeeCode
	// 	);
	// };

	// getTotalAmount = () => {
	// 	const totalServiceAmount = _.sumBy(
	// 		this.props.bill.billDetails,
	// 		(element) => {
	// 			if (element.giftCardId) {
	// 				return 0;
	// 			}
	// 			return (
	// 				(element.amount || 0) +
	// 				(element.extraAmount || 0) -
	// 				(element.discount || 0)
	// 			);
	// 		}
	// 	);
	// 	const giftcards = this.props.bill.billDetails.filter(
	// 		(element) => element.giftCardId
	// 	);
	// 	const totalGiftCardValue = _.sumBy(
	// 		giftcards,
	// 		(element) => element.amount || 0
	// 	);
	// 	// const totalTip = this.props.bill?.totalTip || 0;
	// 	const cardCharge = this.props.bill.cardCharge || 0;
	// 	const totalTax = this.props.bill.totalTax;
	// 	return totalServiceAmount + totalGiftCardValue + cardCharge + totalTax;
	// };

	// isValidated = (): boolean => {
	// 	if (!this.state.refund || Number.parseFloat(this.state.refund.toString())) {
	// 		AlertHelper.showError({
	// 			message: [
	// 				{ Text: I18n.t(_t(translations.billing.pleaseEnterRefundValue)) },
	// 			],
	// 		});
	// 		return false;
	// 	}
	// if (
	// 	!this.state.refund &&
	// 	Number.parseFloat(
	// 		this.state.refund ? this.state.refund.toString() : '0'
	// 	) > 0
	// ) {
	// 	AlertHelper.showError({
	// 		message: [
	// 			{ Text: I18n.t(_t(translations.billing.pleaseEnterRefundValue)) },
	// 		],
	// 	});
	// 	return false;
	// } else if (!this.state.refundReason) {
	// 	AlertHelper.showError({
	// 		message: [
	// 			{ Text: I18n.t(_t(translations.billing.pleaseEnterRefundReason)) },
	// 		],
	// 	});
	// 	return false;
	// }
	// return true;
	// };
}

const mapStateToProps = (state: RootState) => ({
	// price: (priceType: PriceType) => price(state, priceType),
	taxRate: state.BranchReducer.currentBranch?.taxPercent,
	discountBeforeTax: state.BranchReducer.currentBranch?.discountBeforeTax,
	services: state.ServiceReducer.services,
});

export default connect(mapStateToProps)(AddRefundModal);
