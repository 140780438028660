import React, { useEffect, useState } from 'react';
import {
	DeleteOutlined,
	EditOutlined,
	PlusOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import { Row, Col, Space, Input, Button, Table, Typography } from 'antd';
import { I18n, _t, translations } from 'utils';
import { ITextTemplate } from 'models/ITextTemplate';
import TextTemplateApiService from 'services/TextTemplateApiService';
import { IApiResponse, IErrorResponse } from 'models';
import { useHistory } from 'react-router-dom';
import styles from '../../EmailBlast/style';
import { Modal } from 'components';
import { AlertHelper } from 'helpers';

const PAGE_SIZE = 10;
const ListTextTemplate = () => {
	const history = useHistory();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [templates, setTemplates] = useState<ITextTemplate[] | undefined>([]);
	const [showModal, setShowModal] = useState(false);
	const [templateId, setTemplateId] = useState('');
	const [searchText, setSearchText] = useState('');
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: PAGE_SIZE,
		showTotal: (total: any) => `Total ${total} items`,
	});
	const fetchTemplates = async (pageNumber: number) => {
		setLoading(true);
		const params = {
			pageNumber,
			pageSize: PAGE_SIZE,
			searchText: searchText,
		};
		try {
			const result = (await TextTemplateApiService.getTemplates(
				params
			)) as IApiResponse<ITextTemplate[]>;
			if (result.succeeded) {
				const newPagination = {
					...pagination,
					total: result.totalRecords,
					current: pageNumber,
				};
				setPagination(newPagination);
				setTemplates(result.data);
			}
			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};
	const onDeleteTemplate = async () => {
		setLoading(true);
		try {
			const result = (await TextTemplateApiService.deleteTemplate(
				templateId
			)) as IApiResponse;
			if (result.succeeded) {
				fetchTemplates(pagination.current!);
				setLoading(false);
				AlertHelper.showSuccess(
					I18n.t(_t(translations.templateDetail.messageDeleteSuccess))
				);
			} else {
				setLoading(false);
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {}
	};
	const onSearch = (e: any) => {
		let searchText = e.target.value.toLowerCase();
		setSearchText(searchText);
	};
	useEffect(() => {
		const ac = new AbortController();
		fetchTemplates(1);
		return () => {
			ac.abort();
		};
	}, [searchText]);

	return (
		<>
			<Space direction="vertical" style={styles.container} size="large">
				<Row>
					<Col style={{ width: '100%' }}>
						{/* Header */}
						<Row
							gutter={[16, 16]}
							style={{ alignItems: 'center', paddingBottom: 20 }}
						>
							<Col md={12}>
								<Input
									allowClear
									prefix={<SearchOutlined />}
									placeholder={I18n.t(_t(translations.template.search))}
									style={styles.maxWidth}
									onChange={(e) => onSearch(e)}
								/>
							</Col>
							<Col md={12}>
								<Space
									style={{ justifyContent: 'flex-end', width: '100%' }}
									size="small"
								>
									<Button
										icon={<PlusOutlined />}
										type="primary"
										onClick={(e) => {
											e.stopPropagation();
											history.push(`text-template/add-new`);
										}}
									>
										{I18n.t(_t(translations.addNew))}
									</Button>
								</Space>
							</Col>
						</Row>

						{/* ListTextTemplate */}
						<Table
							style={{ paddingBottom: 24, cursor: 'pointer' }}
							scroll={{
								y: '45vh',
								scrollToFirstRowOnChange: true,
								x: 'max-content',
							}}
							size="large"
							dataSource={templates}
							columns={[
								{
									title: (
										<Typography.Title level={5}>
											{I18n.t(_t(translations.template.templateName))}
										</Typography.Title>
									),
									dataIndex: 'name',
									width: '80%',
								},
								{
									dataIndex: 'id',
									render: (id) => {
										return (
											<Space>
												<Button
													icon={<EditOutlined />}
													onClick={(e) => {
														e.stopPropagation();
														history.push(`text-template/edit/${id}`);
													}}
												></Button>
												<Button
													icon={<DeleteOutlined />}
													onClick={() => {
														setTemplateId(id);
														setShowModal(true);
													}}
												/>
											</Space>
										);
									},
									width: '20%',
								},
							]}
							loading={isLoading}
							pagination={pagination}
							onChange={(pagination) => {
								fetchTemplates(pagination.current!);
							}}
							rowKey={(record: ITextTemplate) => record.id}
						/>
					</Col>
				</Row>
			</Space>
			<Modal
				visible={showModal}
				title={I18n.t(_t(translations.delete))}
				handleOk={() => {
					onDeleteTemplate();
					setShowModal(false);
				}}
				handleCancel={() => {
					setShowModal(false);
				}}
			>
				{I18n.t(_t(translations.templateDetail.deleteConfirmContent))}
			</Modal>
		</>
	);
};

export default ListTextTemplate;
