import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row, Table, Typography } from 'antd';
import { CurrencyHelper, TimeHelper } from 'helpers';
import _ from 'lodash';
import { debounce } from 'lodash';
import { IPaginateResponse } from 'models/ResponseModels';
import moment from 'moment';
import { I18n, _t, translations } from 'utils';
import {
	forwardRef,
	Ref,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { BaseApiService } from 'services/BaseApiService';

class TableService extends BaseApiService {}

const tableServices = new TableService(true);

interface IAppTableProps<T> {
	queryUrl: string;
	additionalParams?: object;
	columns: IColumnProps<T>[];
	onClickRow?: (data: T) => void;
	searchKey?: string;
	showSearch?: boolean;
	renderHeader?: React.ReactNode;
	searchPlaceHolder?: string;
	searchIcon?: Element;
	selectedRows?: T[];
	data?: T[];
	keySelected?: keyof T;
}
interface IHeaderProps {
	searchPlaceHolder?: string;
	searchIcon?: Element;
	searchString?: string;
	onChange: (text: string) => void;
	loading?: boolean;
	showSearch?: boolean;
	renderHeader?: React.ReactNode;
}
export interface ITableRef {
	refresh: () => void;
}

export interface IColumnProps<T> {
	header: string;
	renderHeader?: Element;
	align?: 'center' | 'left' | 'right';
	key: keyof T;
	type?: 'text' | 'number' | 'money' | 'date' | 'time' | 'status' | 'switch';
	renderCell?: (data?: T) => JSX.Element;
	width?: number;
}

const TableColumn = <T,>(column: IColumnProps<T>, selectedIds?: string[]) => {
	return (
		<Table.Column<T>
			width={column.width}
			align={column.type === 'time' ? 'right' : 'left'}
			render={(_, record, index) => {
				if (column.renderCell) {
					return column.renderCell(record);
				}
				return (
					<>
						{column.type === 'text' && (
							<Typography.Text>{record[column.key]}</Typography.Text>
						)}
						{column.type === 'date' && (
							<Typography.Text>
								{moment(record[column.key]).format('MM/DD/YYYY')}
							</Typography.Text>
						)}
						{column.type === 'time' && (
							<Typography.Text strong>
								{TimeHelper.toTimeZone(`${record[column.key]}`).format('HH:mm')}
							</Typography.Text>
						)}
						{column.type === 'money' && (
							<Typography.Text>
								{CurrencyHelper.formatPrice(
									Number(`${record[column.key]}`),
									false
								)}
							</Typography.Text>
						)}
					</>
				);
			}}
			title={<Typography.Text>{column.header}</Typography.Text>}
		/>
	);
};

const Header = (props: IHeaderProps) => {
	return (
		<Row justify="space-between" gutter={16} style={{ marginBottom: 16 }}>
			{props.showSearch && (
				<Col lg={6}>
					<Input
						placeholder={I18n.t(_t(translations.placeHolder.search))}
						prefix={<SearchOutlined />}
						allowClear={false}
						disabled={props.loading}
						onChange={(e) => props.onChange && props.onChange(e.target.value)}
					/>
				</Col>
			)}
			{props.renderHeader && (
				<Col lg={props.showSearch ? 18 : 24}>{props.renderHeader}</Col>
			)}
		</Row>
	);
};

const AppTable = forwardRef(
	<T extends object>(props: IAppTableProps<T>, ref: Ref<ITableRef>) => {
		const [searchText, setSearchText] = useState('');
		const didMountRef = useRef(false);
		const [page, setPage] = useState(1);
		const [loading, setLoading] = useState(false);
		const [pageSize, setPageSize] = useState(10);
		const [data, setData] = useState<Partial<IPaginateResponse<T[]>>>();
		useImperativeHandle(ref, () => ({
			refresh() {
				fetchData();
			},
		}));
		useEffect(() => {
			if (props.data) {
				setData({
					pageSize: pageSize,
					pageNumber: 1,
					data: props.data || [],
					totalPages: Math.ceil(
						(props.data ? props.data.length : 0) / pageSize
					),
					totalRecords: props.data ? props.data.length : 0,
				});
			}
		}, [props.data]);
		useEffect(() => {
			fetchData();
		}, [page, pageSize]);

		useEffect(() => {
			if (didMountRef.current) {
				if (page !== 1) {
					setPage(1);
				} else {
					fetchData(1, searchText);
				}
			}
			didMountRef.current = true;
		}, [props.additionalParams]);

		const debounceSearch = useCallback(
			debounce((text: string) => {
				setSearchText(text);
				fetchData(1, text);
			}, 1000),
			[]
		);

		const onSearch = (text: string) => {
			debounceSearch(text);
		};

		const fetchData = async (p = page, searchText?: string) => {
			if (props.data) {
				return;
			}
			setLoading(true);
			const response = (await tableServices.get(`${props.queryUrl}`, {
				params: {
					pageNumber: p,
					pageSize: pageSize,
					...props.additionalParams,
					searchText: !_.isEmpty(searchText) ? searchText : undefined,
				},
			})) as IPaginateResponse<T[]>;
			setLoading(false);
			if (response.succeeded) {
				setData(response);
			}
		};

		const isSelected = (data: T) => {
			if (props.keySelected && props.selectedRows) {
				let index = props.selectedRows.findIndex(
					(x) => `${x[props.keySelected!]}` === `${data[props.keySelected!]}`
				);
				if (index !== -1) return true;
			}
			return false;
		};

		return (
			<>
				{(props.showSearch || props.renderHeader) && (
					<Header onChange={(text) => onSearch(text)} {...props} />
				)}
				<Table<T>
					dataSource={data?.data}
					onRow={(data) => ({
						onClick: () => props.onClickRow && props.onClickRow(data),
					})}
					pagination={{
						pageSize: data?.pageSize,
						current: page,
						total: data?.totalRecords,
						onChange: (page, size) => {
							setPage(page);
							setPageSize(size || 10);
						},
						defaultPageSize: 10,
						showSizeChanger: true,
						showTotal: (total: number) => `Total ${total}`,
					}}
					loading={loading}
					size="large"
					rowClassName={(record, index) =>
						isSelected(record) ? 'selected-row' : ''
					}
				>
					{props.columns.map((column) => {
						return TableColumn(column);
					})}
				</Table>
			</>
		);
	}
);

export default AppTable as <T extends object>(
	props: IAppTableProps<T> & { ref: Ref<ITableRef> }
) => JSX.Element;
