import React, { ComponentType } from 'react';
import {
	Calendar,
	CalendarProps,
	momentLocalizer,
	ResourceHeaderProps,
	View,
} from 'react-big-calendar';
// import overlap from 'react-big-calendar/lib/utils/layout-algorithms/overlap'
import {
	Popover,
	Typography,
	Space,
	Row,
	Col,
	Button,
	Select,
	Spin,
	message,
	Dropdown,
	Menu,
	Divider,
	Tag,
	Result,
} from 'antd';

import { LeftOutlined, RightOutlined, PlusOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import {
	CalendarHelper,
	TimeHelper,
	ColorHelper,
	AlertHelper,
	BookingHelper,
} from 'helpers';
import { EBookingInputFrom, IApiResponse, IEmployee } from 'models';
import {
	Avatar,
	ContentAdminContainer,
	IComponentProps,
	StatusTag,
} from 'components';
import { EventType, IEventCalendar } from 'helpers/calendarHelper';
import { connect } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { Dispatch } from '@reduxjs/toolkit';
import {
	BookingActions,
	EmployeeActions,
	HolidayActions,
	ErrorActions,
	BranchActions,
} from 'redux/actions';
import styles from './styles';
import {
	IAddHolidayRequest,
	IGetAllHolidayRequest,
	IGetBookingsRequest,
} from 'models/RequestModels';
import { HolidayDetailModal } from 'components';
import _ from 'lodash';
import qs from 'qs';
import { Const, I18n, translations, _t } from 'utils';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import './style.css';
import {
	getEmployeesSortByTurn,
	numberOfBookingOfEmployee,
	getStylists,
} from 'redux/selectors/employee';
import { WorkingHour } from 'models/IBranch';
import momentTz from 'moment-timezone';
import { Holiday } from 'components/HolidayDetailModal';
import BookingApiService from 'services/BookingApiService';
import { IStylistAndTimeBlockResponse } from 'models/ResponseModels';
import InputFromTag from 'components/InputFromTag';
import { DatePicker } from 'components/DatePicker/DatePicker';
const { Title } = Typography;
const DragAndDropCalendar = withDragAndDrop<IEventCalendar, Partial<IEmployee>>(
	Calendar as ComponentType<CalendarProps<IEventCalendar, Partial<IEmployee>>>
);

interface ISlotInfo {
	value?: Date;
	resource?: string;
}
interface IMainCalendarProps
	extends IComponentProps,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}
interface IMainCalendarState {
	date: Date;
	view: string;
	selectedEmployee: string;
	isShowAddNewBlockedTime: boolean;
	showCalendar: boolean;
	employees: IEmployee[];
}
class MainCalendar extends React.Component<
	IMainCalendarProps,
	IMainCalendarState
> {
	constructor(props: IMainCalendarProps) {
		super(props);
		this.state = {
			date: TimeHelper.toTimeZone(new Date()).toDate(),
			view: 'day',
			selectedEmployee: 'all-employee',
			isShowAddNewBlockedTime: false,
			showCalendar: false,
			employees: [],
		};
	}

	componentDidMount() {
		// this.props.getListEmployee();ßß
		this.getBookings();
		this.getStylists();
		setTimeout(() => {
			this.setState({ showCalendar: true });
		}, 100);
	}
	componentDidUpdate(prev: IMainCalendarProps) {
		if (prev.holidayAction !== this.props.holidayAction) {
			if (this.props.holidayAction === HolidayActions.addHoliday.successName) {
				AlertHelper.showAlert(
					I18n.t(_t(translations.text.success)),
					I18n.t(_t(translations.addHoliday.addSuccess)),
					'success'
				);
				this.setState({ isShowAddNewBlockedTime: false });
				this.getBookings();
			} else if (
				this.props.holidayAction === HolidayActions.addHoliday.failedName
			) {
				AlertHelper.showAlert(
					I18n.t(_t(translations.text.error)),
					this.props.error?.text ||
						I18n.t(_t(translations.addHoliday.addFailed)),
					'error',
					() => this.props.clearError()
				);
			}
		}
		if (prev.branchAction !== this.props.branchAction) {
			if (this.props.branchAction === BranchActions.getBranchById.successName) {
				setTimeout(() => {
					this.getBookings();
				}, 100);
				return;
			}
		}
	}
	render() {
		return (
			<ContentAdminContainer
				hidePathName
				style={{ backgroundColor: 'white', height: '100%' }}
				extraHeader={this.renderHeader()}
			>
				{!this.isWorkingDay() ? (
					<Result
						status="403"
						title={I18n.t(_t(translations.calendar.closed))}
					/>
				) : (
					<Spin
						spinning={
							this.props.currentAction ===
							BookingActions.getBookingsByBranch.requestName
						}
					>
						{_.isEmpty(this.props.currentBranch) || !this.state.showCalendar
							? null
							: this.renderCalendar()}
					</Spin>
				)}
				{this.state.isShowAddNewBlockedTime && (
					<HolidayDetailModal
						isShopHoliday={false}
						onClose={() => this.setState({ isShowAddNewBlockedTime: false })}
						onSubmit={(data) => this.onAddHoliday(data)}
					/>
				)}
			</ContentAdminContainer>
		);
	}

	getListStylist = () => {
		let listStylist;

		if (
			moment().isAfter(this.state.date, 'date') ||
			_.isEmpty(this.props.getEmployeesSortByTurn(this.state.employees))
		) {
			listStylist = this.props.getStylists();
		} else {
			listStylist = this.props.getEmployeesSortByTurn(this.state.employees);
		}

		return this.state.selectedEmployee === 'all-employee'
			? [
					{
						id: 'Anyone',
						firstName: 'Anyone',
						lastName: '',
					},
			  ].concat(listStylist)
			: _.filter(
					this.state.employees,
					(stylist) => stylist.id === this.state.selectedEmployee
			  );
	};

	renderCalendar() {
		moment.tz.setDefault(
			this.props.currentBranch?.localTimeZone || momentTz.tz.guess()
		);
		const globalizeLocalizer = momentLocalizer(moment);
		const { min, max } = this.getMinMaxDay();
		return (
			<DragAndDropCalendar
				// selectable
				dayLayoutAlgorithm="no-overlap"
				onSelecting={() => true}
				// onSelectEvent={(event) => this.onSelectEvent(event)}
				events={_.concat(
					CalendarHelper.convertBookingToEvent(this.props.bookings),
					CalendarHelper.convertHolidayToEvent(
						_.uniqBy(this.props.holidays || [], (holiday) => holiday.id)
					)
				)}
				startAccessor="start"
				endAccessor="end"
				localizer={globalizeLocalizer}
				view={this.state.view as View}
				views={['day', 'week']}
				longPressThreshold={20}
				// onEventDrop={(event) => console.log("EVENT event", event)}
				step={this.props.currentBranch?.timeBlockInterval}
				timeslots={60 / this.props.currentBranch?.timeBlockInterval!}
				// style={{
				// 	height: `calc(100vh - 190px - ${
				// 		window.innerWidth <= 1439 ? '80px' : '0px'
				// 	})`,
				// }}
				className="heightCalendar"
				min={moment(moment(min).startOf('hour')).toDate()}
				max={max}
				date={this.state.date}
				defaultDate={moment().toDate()}
				scrollToTime={moment().hours(13).toDate()}
				resources={this.getListStylist()}
				resourceIdAccessor={(stylist) => stylist.id}
				resourceTitleAccessor={(stylist) =>
					`${stylist.firstName || stylist.lastName}`
				}
				eventPropGetter={(event) => {
					const resource = _.find(
						this.props.getStylists(),
						(stylist) => stylist.id === event.resourceId
					);
					return {
						style: {
							backgroundColor:
								event.type === EventType.Holiday
									? '#f5f5f5'
									: resource
									? resource!.color
									: 'white',
							borderRadius: 2,
							borderWidth: 3,
							borderColor:
								event.type === EventType.Holiday
									? 'black'
									: ColorHelper.getStatusColor(event.status!),
							// border: "none",
						},
					};
				}}
				components={{
					resourceHeader: (resource) => this.renderResourceHeader(resource),
					timeSlotWrapper: (slot: any) => this.renderTimeSlot(slot),
					event: (event) => this.renderEvent(event.event),
					toolbar: () => null,
					// eventWrapper: (event) => this.renderEventWrapper(event),
				}}
			/>
		);
	}

	renderHeaderDay() {
		const date = this.state.date;
		return (
			<Space size={0} direction="horizontal">
				<Button
					onClick={() => {
						const newDate = moment(date).subtract(1, 'day');
						this.onChangeDate(newDate);
					}}
				>
					<LeftOutlined />
				</Button>
				<Button onClick={() => this.onChangeDate(moment())}>
					{I18n.t(_t(translations.calendar.today))}
				</Button>
				<DatePicker
					format={'dddd DD MMM,YYYY'}
					selectedDate={moment(this.state.date)}
					onSelectDate={(date) => this.onChangeDate(date)}
				/>
				<Button
					onClick={() => {
						const newDate = moment(date).add(1, 'day');
						this.onChangeDate(newDate);
					}}
				>
					<RightOutlined />
				</Button>
			</Space>
		);
	}

	renderViews() {
		const views = [
			{ label: 'Day', value: 'day' },
			{ label: 'Week', value: 'week' },
		];
		return (
			<Select
				style={{ width: 100, textAlign: 'left' }}
				value={this.state.view}
				onSelect={(value) => this.onSelectView(value)}
			>
				{views.map((view) => (
					<Select.Option key={view.value} value={view.value}>
						{`${view.label}`}
					</Select.Option>
				))}
			</Select>
		);
	}
	renderEmployeeSelect() {
		return (
			<Select
				onSelect={(value) => this.onSelectEmployee(value)}
				value={this.state.selectedEmployee}
				style={styles.employeeSelect}
			>
				{this.state.view === 'day' ? (
					<Select.Option key="all-employee" value={'all-employee'}>
						{I18n.t(_t(translations.calendar.allStylist))}
					</Select.Option>
				) : null}
				{this.state.employees?.map((employee) => {
					return (
						<Select.Option key={employee.id} value={employee.id}>
							{`${employee.firstName} ${employee.lastName || ''}`}
						</Select.Option>
					);
				})}
			</Select>
		);
	}

	renderHeader() {
		return (
			<Row justify="space-between" style={styles.headerContainer}>
				<Col xs={24} md={4}>
					{this.renderEmployeeSelect()}
				</Col>
				<Col style={styles.dateContainer} xs={24} md={12}>
					{this.renderHeaderDay()}
				</Col>
				<Col
					style={window.innerWidth > 768 ? styles.viewsContainer : {}}
					xs={24}
					md={8}
				>
					{this.renderViews()}
					{this.renderAddNewBtn()}
				</Col>
			</Row>
		);
	}

	renderAddNewBtn() {
		return (
			<Dropdown
				placement="bottomCenter"
				arrow
				trigger={['click']}
				overlay={
					<Menu>
						<Menu.Item onClick={() => this.onCreateNewBooking()}>
							<span>{I18n.t(_t(translations.calendar.addNewBooking))}</span>
						</Menu.Item>
						<Divider style={{ margin: 0 }} />
						<Menu.Item
							onClick={() => this.setState({ isShowAddNewBlockedTime: true })}
						>
							<span>{I18n.t(_t(translations.calendar.addNewHoliday))}</span>
						</Menu.Item>
					</Menu>
				}
			>
				<Button
					type="primary"
					icon={<PlusOutlined />}
					style={{ marginLeft: 16, flex: 1 }}
				>
					{I18n.t(_t(translations.calendar.addNew))}
				</Button>
			</Dropdown>
		);
	}

	renderTimeSlot(slot: any) {
		var isWorkingTime = this.isWorkingTime(slot.value);
		return (
			<div
				className={`time-slot ${isWorkingTime ? '' : 'out-working-time-slot'}`}
				onClick={() => this.onCreateNewBooking(slot as ISlotInfo)}
				style={{
					backgroundColor: isWorkingTime ? 'white' : '#f5f5f5',
				}}
			>
				<div className="time-label">
					<Typography.Text strong style={{ margin: 4 }}>
						{moment(slot.value).format('hh:mmA')}
					</Typography.Text>
					{/* <a href={"/booking/new"}>Add New</a> */}
				</div>
			</div>
		);
	}

	renderResourceHeader(resource: ResourceHeaderProps) {
		const employee = resource.resource as IEmployee;
		const numberOfTurns = this.props.numberOfBookingOfEmployee(employee.id);
		const isAnyone = employee.id === 'Anyone';
		const turnString = !isAnyone ? `(${numberOfTurns})` : '';
		return (
			<>
				<Avatar
					src={employee.imageUrl}
					size={'large'}
					color={isAnyone ? 'white' : employee.color}
					name={!isAnyone ? resource.label?.toString()! : ''}
				/>
				<Title level={5}>{`${resource.label?.toString()!}${turnString}`}</Title>
			</>
		);
	}
	renderEvent(event: IEventCalendar) {
		const timeString = `${moment(event.start).format('h:mm')}-${moment(
			event.end
		).format('h:mm')}  `;
		if (event.type === EventType.Holiday) {
			return (
				<>
					<Row style={{ width: '100%', padding: 4 }} justify="space-between">
						<Typography.Text style={{ fontSize: 12, fontWeight: 'normal' }}>
							{timeString}
						</Typography.Text>
					</Row>
					<Typography.Text
						style={{ fontSize: 14, fontWeight: 'bold', margin: 4 }}
					>
						{`x ${I18n.t(_t(translations.calendar.blockTime))} x`}
					</Typography.Text>
				</>
			);
		}
		return (
			<div
				onClick={() => {
					if (event.booking) {
						BookingHelper.openBooking(event.booking);
					}
				}}
				className={'pop-over'}
				style={{ zIndex: 100 }}
			>
				<Row style={{ width: '100%' }} justify="space-between">
					<Typography.Text style={{ fontSize: 12, fontWeight: 'normal' }}>
						{timeString}
					</Typography.Text>
					<Space>
						<StatusTag status={event.status!} />
						<InputFromTag
							bookingStatus={event.status!}
							inputSource={event.booking?.inputFrom!}
							bookingType={event.booking?.bookingType!}
						/>
					</Space>
				</Row>
				<Typography.Text style={{ fontSize: 14, fontWeight: 'bold' }}>
					{event.title}
				</Typography.Text>
				<Row>
					{event.services?.map((service) => (
						<Typography.Text style={{ fontSize: 12, fontWeight: 'normal' }}>
							{service.name}
						</Typography.Text>
					))}
				</Row>
			</div>
		);
	}
	// renderEventPopoverContent(event: IEventCalendar) {
	//   return (
	//     <Card
	//       style={{ width: 300 }}
	//       title={
	//         <Card.Meta
	//           avatar={<Avatar name={`${event.customer?.firstName} ${event.customer?.lastName}`} />}
	//           title={`${event.customer?.firstName} ${event.customer?.lastName}`}
	//           description={event.customer?.phone}
	//         />
	//       }
	//       bordered={false}
	//     >
	//       <Row>
	//         <Typography.Text type="secondary">{`${moment(event.start).format("hh:mm")}-${moment(event.end).format(
	//           "hh:mm"
	//         )} `}</Typography.Text>
	//         <Tag style={{ marginLeft: 16 }} color={ColorHelper.getStatusColor(event.status!)}>
	//           {I18n.t(_t(translations.bookingStatus[`status_${event.status}`]))}
	//         </Tag>
	//       </Row>

	//       <Descriptions style={{ width: "100%" }} contentStyle={{ fontWeight: "bold" }} column={1}>
	//         {event.services?.map((service) => (
	//           <Descriptions.Item label={service.item.name}>{`${I18n.t(_t(translations.currency))}${
	//             service.item.price
	//           }`}</Descriptions.Item>
	//         ))}
	//       </Descriptions>
	//     </Card>
	//   );
	// }
	isWorkingDay() {
		const { date } = this.state;
		const timeZoneDate = TimeHelper.toTimeZone(date);
		const dayIndex = timeZoneDate.day();
		return !_.isEmpty(
			_.find(this.props.currentBranch?.workingHours, (x) =>
				x.days?.includes(dayIndex.toString())
			)
		);
	}
	isWorkingTime(time: Date) {
		const timezoneTime = TimeHelper.toTimeZone(time);
		const timeCal = timezoneTime.minutes() + timezoneTime.hours() * 60;
		const dayIndex = timezoneTime.day();
		const workingDayTime = _.find(this.props.currentBranch?.workingHours, (x) =>
			x.days?.includes(dayIndex.toString())
		) as WorkingHour;
		if (!workingDayTime) {
			return false;
		}

		const workingTimeStartAt = workingDayTime.workingTimeStartAt;

		const workingTimeEndAt = workingDayTime.workingTimeEndAt;

		const startTime = TimeHelper.getInitialWorkingTime(
			workingTimeStartAt as string
		)
			.subtract(this.props.currentBranch?.timeBlockInterval, 'minute')
			.format('HH:mm');
		const endTime = TimeHelper.getInitialWorkingTime(
			workingTimeEndAt as string
		).format('HH:mm');
		return moment(timeCal).isBetween(
			TimeHelper.convertDurationToNumber(startTime),
			TimeHelper.convertDurationToNumber(endTime) === 0
				? 24 * 60
				: TimeHelper.convertDurationToNumber(endTime)
		);
	}
	onSelectView(view: string) {
		this.setState(
			{
				view: view,
				selectedEmployee:
					view === 'day' ? 'all-employee' : this.props.getStylists()[0]?.id,
			},
			() => {
				this.getBookings();
				if (view === 'day') {
					this.getStylists();
				} else {
					this.setState({ employees: this.props.getStylists() });
				}
			}
		);
	}
	onSelectEmployee(value: string) {
		this.setState({ selectedEmployee: value }, () => this.getBookings());
	}
	// onSelectEvent(event: IEventCalendar) {
	//   const bookingId = event.bookingId;
	//   this.props.history.push(`/booking/${bookingId}`);
	// }
	onCreateNewBooking(slotInfo?: ISlotInfo) {
		let date = moment().format('MM-DD-YYYY');
		let time;
		let stylistId;
		if (slotInfo) {
			if (moment(slotInfo.value).isBefore(moment())) {
				message.warning(I18n.t(_t(translations.calendar.errorAddBookingPast)));
				return;
			}
			date = moment(slotInfo.value).format('MM-DD-YYYY');
			time = moment(slotInfo.value).format('HHmm');
			stylistId =
				slotInfo.resource !== 'Anyone' ? slotInfo.resource : undefined;
		}
		const query = qs.stringify({
			date,
			time,
			stylistId: stylistId,
		});

		this.props.history.push(`/booking/new?${query}`);
	}
	onChangeDate(requestDate: moment.Moment) {
		this.setState({ date: requestDate.toDate() }, () => {
			this.getBookings();
			this.getStylists();
		});
	}
	getStylists = async () => {
		const stylists = (await BookingApiService.getAvailableStylistAndTimeBlocks({
			branchId: this.props.currentBranch?.id,
			shopId: this.props.currentBranch?.shopId,
			date: moment(this.state.date).format('MM-DD-YYYY'),
			inputFrom: EBookingInputFrom.POS,
		})) as IApiResponse<IStylistAndTimeBlockResponse>;
		if (stylists.data) {
			this.setState({
				employees: stylists.data.stylists,
			});
		}
	};

	onAddHoliday(data: Holiday) {
		const date = data.date as Moment;
		const request: IAddHolidayRequest = {
			frequencyType: Const.HolidayType.ONCE,
			fromDate: BookingHelper.convertDateRequest(date),
			toDate: BookingHelper.convertDateRequest(date),
			startTime: momentTz(
				`${momentTz(date).format('YYYY-MM-DD')} ${momentTz(data.time[0]).format(
					'HH:mm'
				)}`
			)
				.utc()
				.format('YYYY-MM-DDTHH:mm'),
			endTime: momentTz(
				`${momentTz(date).format('YYYY-MM-DD')} ${momentTz(data.time[1]).format(
					'HH:mm'
				)}`
			)
				.utc()
				.format('YYYY-MM-DDTHH:mm'),
			holidayName: `${data.description}_${moment().format(
				'YYYY-MM-DDTHH:mm:SSS'
			)}`,
			description: data.description,
			employeeId: data.staff,
			status: 1,
			branchId: this.props.currentBranch!.id,
		};
		this.props.addHoliday(request);
	}

	getMinMaxDay() {
		const timeZoneDate = TimeHelper.toTimeZone(this.state.date);
		const dayIndex = timeZoneDate.day();
		const workingDayTime = _.find(this.props.currentBranch?.workingHours, (x) =>
			x.days?.includes(dayIndex.toString())
		) as WorkingHour;
		if (!workingDayTime) {
			return {
				min: moment().startOf('day').toDate(),
				max: moment().endOf('day').toDate(),
			};
		}
		const workingTimeStart = moment
			.utc(workingDayTime.workingTimeStartAt)
			.format('HH:mm');
		const workingTimeEnd = moment
			.utc(workingDayTime.workingTimeEndAt)
			.format('HH:mm');
		const min = TimeHelper.toTimeZone(
			`${moment(this.state.date).format('YYYY-MM-DD')}T${workingTimeStart}`
		)
			// .subtract(this.props.currentBranch?.timeBlockInterval, 'minute')
			.toDate();
		const max = TimeHelper.toTimeZone(
			`${moment(this.state.date).format('YYYY-MM-DD')}T${workingTimeEnd}`
		).toDate();
		return { min, max };
	}

	getBookings() {
		const view = this.state.view;
		let request: IGetBookingsRequest;
		const requestDate = moment(this.state.date);
		if (view === 'day') {
			request = {
				// branchId: this.props.currentBranch?.id!,
				fromDate: BookingHelper.convertDateRequest(requestDate),
				toDate: BookingHelper.convertDateRequest(requestDate),
			};
		}
		if (view === 'week') {
			request = {
				// branchId: this.props.currentBranch?.id!,
				fromDate: requestDate.startOf('week').utc().toDate(),
				toDate: requestDate.add(1, 'week').subtract(1, 'minute').utc().toDate(),
				stylistId: this.state.selectedEmployee,
			};
		}

		setTimeout(() => {
			this.props.getBookings(request);
			this.props.getHolidays(request as IGetAllHolidayRequest);
		}, 100);
	}
}

const mapStateToProps = (state: RootState) => ({
	bookings: state.BookingReducer.bookings,
	currentAction: state.ReduxActionReducer['BOOKING'],
	branchAction: state.ReduxActionReducer['BRANCH'],
	currentBranch: state.BranchReducer.currentBranch,
	isLoading: state.AppConfigReducer.showLoading,
	holidayAction: state.ReduxActionReducer['HOLIDAY'],
	error: state.ErrorReducer.error?.message
		? state.ErrorReducer.error?.message[0]
		: { text: 'Error' },
	getEmployeesSortByTurn: (employees: IEmployee[]) =>
		getEmployeesSortByTurn(state, state.BookingReducer.bookings, employees),
	getStylists: () => getStylists(state),
	numberOfBookingOfEmployee: (employeeId: string) =>
		numberOfBookingOfEmployee(state.BookingReducer.bookings, employeeId),
	holidays: state.HolidayReducer.paginateHoliday?.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	getListEmployee: () => {
		dispatch(EmployeeActions.getAllEmployees.request());
	},
	getBookings: (request: IGetBookingsRequest) => {
		dispatch(BookingActions.getBookingsByBranch.request(request));
	},
	addHoliday: (request: IAddHolidayRequest) => {
		dispatch(HolidayActions.addHoliday.request(request));
	},
	clearError: () => {
		dispatch(ErrorActions.clearError.request());
	},
	getHolidays: (request: IGetAllHolidayRequest) => {
		dispatch(HolidayActions.getAllHoliday.request(request));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(MainCalendar);
