import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Space } from 'antd';
import { DateRangeSelect } from 'components';
import { TDateRange } from 'components/DatePicker/CalendarModal';
import { DateRangePicker } from 'components/DatePicker/DateRangePicker';
import I18n from 'i18n-js';
import { IEmployee } from 'models';
import moment from 'moment';
import { translations, _t } from 'utils';
import { EDatePickerType } from 'utils/Consts';

interface IHeaderControllerProps {
	selectedEmployee?: Partial<IEmployee>;
	onEmployeePickerClick?: () => void;
	loading?: boolean;
	selectedDateRage: TDateRange;
	onAddNewClick: () => void;
	onSelectDateRange?: (dateRange: TDateRange) => void;
	isShop?: boolean;
}
const HeaderController = ({
	loading,
	onAddNewClick,
	onEmployeePickerClick,
	selectedEmployee,
	isShop,
	selectedDateRage,
	onSelectDateRange,
}: IHeaderControllerProps) => {
	return (
		<Row justify="space-between" gutter={16}>
			{!isShop && (
				<Col lg={6}>
					<Input
						className="uneditable-input-with-icon"
						suffix={<UserOutlined />}
						allowClear={false}
						value={
							selectedEmployee
								? `${selectedEmployee.firstName} ${selectedEmployee.lastName}`
								: I18n.t(_t(translations.payrollDetailReport.searchEmployee))
						}
						onClick={() => onEmployeePickerClick && onEmployeePickerClick()}
						disabled={loading}
					/>
				</Col>
			)}

			{/* <DateRangeSelect
				fromDate={fromDate}
				toDate={toDate}
				onDateRangeButtonClick={onDateRangeButtonClick}
				xs={24}
				sm={24}
				md={9}
				lg={6}
				// style={{ marginLeft: 16}}
				isLoading={loading}
			/> */}
			<Col lg={6}>
				<Space
					style={{ justifyContent: 'flex-start', width: '100%' }}
					size="small"
				>
					<DateRangePicker
						selectedDateRange={selectedDateRage}
						onSelectDateRange={(dateRage) =>
							onSelectDateRange && onSelectDateRange(dateRage)
						}
					/>
				</Space>
			</Col>

			<Col lg={12}>
				<Space
					style={{ justifyContent: 'flex-end', width: '100%' }}
					size="small"
				>
					<Button
						icon={<PlusOutlined />}
						type="primary"
						onClick={onAddNewClick}
					>
						{I18n.t(_t(translations.addNew))}
					</Button>
				</Space>
			</Col>
		</Row>
	);
};

export default HeaderController;
