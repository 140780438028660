import { ContentAdminContainer} from "components"
import React from "react";
import ListCustomer from "./components/ListCustomer";

class CustomerPage extends React.Component {
  render() {
    return (
    <ContentAdminContainer>
        <ListCustomer/>
    </ContentAdminContainer>
    );
  }
}

export default CustomerPage;
