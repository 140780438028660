import React, { Component } from 'react';
import {
	Form,
	Spin,
	Image,
	Typography,
	Layout,
	Row,
	Col,
	Select,
	Divider,
	Result,
} from 'antd';
import {
	ShopOutlined,
	UserOutlined,
	PhoneOutlined,
	MailOutlined,
	LockOutlined,
	HomeOutlined,
	CodeOutlined,
	BgColorsOutlined,
} from '@ant-design/icons';
import { Input, Button, AuthFooter } from 'components';
import styles from './styles';
import { I18n, _t, translations, COUNTRIES } from 'utils';
import { IApiResponse, IErrorResponse, ITheme } from 'models';
import _ from 'lodash';
import { Content } from 'antd/lib/layout/layout';
import { IShopRegister } from 'models/RequestModels';
import AuthenticationApiService from 'services/AuthenticationApiService';
import moment from 'moment';
import { showAlert, showError } from 'helpers/alertHelper';
import { history } from 'routers';
import formHelper from 'helpers/formHelper';
import { ThemePickerModal } from 'components';
import { ThemeApiService } from 'services';
import { AlertHelper } from 'helpers';
import {
	getAllCity,
	getAllStates,
	getLocationFromZip,
	getZipFromLocation,
	ICity,
	IState,
} from 'helpers/geolocation';
import tzlookup from 'tz-lookup';

const { Title } = Typography;

interface IStates {
	shopName?: string;
	adminUrl?: string;
	isLoading: boolean;
	firstName: string;
	lastName: string;
	country: string;
	phone: string;
	email: string;
	done: false;
	isShowThemePickerModal: boolean;
	themes: ITheme[];
	state?: IState;
	cities?: ICity[];
	city?: string;
	zipCode: string;
	states?: IState[];
	timeZone?: string;
}
class PartnerRegister extends Component {
	formRef: any;

	state: IStates = {
		isLoading: false,
		firstName: '',
		lastName: '',
		country: 'US',
		phone: '',
		email: '',
		done: false,
		isShowThemePickerModal: false,
		themes: [],
		zipCode: '',
	};

	componentDidMount() {
		this.getAllThemes();
		this.setState({ states: getAllStates() });
	}

	onChangeDebounce = () => {
		if (!_.isEmpty(this.state.zipCode)) {
			const location = getLocationFromZip(this.state.zipCode!);
			if (!_.isEmpty(location)) {
				this.setState({ city: location?.city }, () => {
					this.onSelectState(location?.state || '');
				});
			}
		}
	};

	render() {
		console.log(this.state.timeZone);

		return (
			<>
				<Layout style={styles.layout}>
					<Layout.Header style={styles.nailHeader}>
						<Row>
							<Col lg={1} xs={1} />
							<Col lg={22} xs={22}>
								<Row justify="center" style={styles.row}>
									<Image
										style={styles.nailHeaderLogo}
										src="./logo-square.png"
									/>
								</Row>
								<Row>
									<Typography.Text style={styles.nailHeaderSlogan} strong>
										{I18n.t(_t(translations.login.slogan))}
									</Typography.Text>
								</Row>
							</Col>
							<Col lg={1} xs={1} />
						</Row>
					</Layout.Header>
					<Content>
						{this.state.done ? this.renderDone() : this.renderForm()}
					</Content>
					<AuthFooter />
				</Layout>
				<ThemePickerModal
					isShowModal={this.state.isShowThemePickerModal}
					onCancel={() => this.setState({ isShowThemePickerModal: false })}
					onChooseTheme={(theme) => {
						this.formRef.setFields([
							{
								name: 'themeShopConfig',
								value: {
									themeName: theme.themeName,
									defaultColor: theme.defaultColor,
								},
							},
						]);
						this.setState({ isShowThemePickerModal: false });
					}}
					themes={this.state.themes}
					// selectedTheme={this.formRef?.getFieldValue([
					// 	'themeShopConfig',
					// 	'themeName',
					// ])}
					// initialTheme=''
				/>
			</>
		);
	}
	renderDone() {
		return (
			<Row>
				<Col lg={6} md={6} xs={1} />
				<Col lg={12} md={12} xs={22}>
					<Result
						status="success"
						title={I18n.t(_t(translations.partner.register))}
						subTitle={I18n.t(_t(translations.partner.checkMail))}
					/>
				</Col>
				<Col lg={6} md={6} xs={1} />
			</Row>
		);
	}
	renderForm() {
		return (
			<Spin spinning={this.state.isLoading}>
				<Row>
					<Col lg={6} md={6} xs={1} />
					<Col lg={12} md={12} xs={22}>
						<Form
							ref={(ref: any) => (this.formRef = ref)}
							name="normal_login"
							style={styles.nailForm}
							onFinish={(values) => this.onSubmit(values, this)}
						>
							<Row gutter={8}>
								<Col md={24}>
									<Title level={5}>
										{I18n.t(_t(translations.partner.yourInfo))}
									</Title>
								</Col>
								{this.renderName()}
								{this.renderPhoneAndEmail()}
								<Divider />
								<Col md={24}>
									<Title level={5}>
										{I18n.t(_t(translations.partner.yourSalon))}
									</Title>
								</Col>
								{this.renderShopName()}
								<Divider />

								<Col md={24}>
									<Title level={5}>
										{I18n.t(_t(translations.partner.yourAminAccount))}
									</Title>
								</Col>
								{this.renderUser()}
								{/* {this.renderInput()} */}
							</Row>
							<Divider />
							<Col md={24}>
								<Title level={5}>
									{I18n.t(_t(translations.partner.yourAddress))}
								</Title>
							</Col>
							{this.renderAddressInputs()}
							<Divider />
							{this.renderThemeInputs()}
							<Form.Item>
								<Row gutter={8} justify="end" style={{ width: '100%' }}>
									<Col>
										<Button
											type="primary"
											loading={this.state.isLoading}
											htmlType="submit"
											// onClick={() => this.onFormFinish()}
											style={{ height: '50px', width: '100%' }}
										>
											{I18n.t(_t(translations.partner.register))}
										</Button>
									</Col>
									<Col>
										<Button
											type="dashed"
											onClick={() => history.push('/login')}
											style={{ height: '50px' }}
										>
											{I18n.t(_t(translations.back))}
										</Button>
									</Col>
								</Row>
							</Form.Item>
						</Form>
					</Col>
					<Col lg={6} md={6} xs={1} />
				</Row>
			</Spin>
		);
	}

	renderName() {
		return (
			<>
				<Col md={12}>
					<Form.Item
						style={styles.formItem}
						name="firstName"
						rules={[
							{
								message: I18n.t(_t(translations.register.firstNameRequire)),
								required: true,
							},
						]}
					>
						<Input
							prefix={<UserOutlined className="site-form-item-icon" />}
							placeholder={I18n.t(_t(translations.placeHolder.firstName))}
							value={this.state.firstName}
							onChange={(event) =>
								this.setState({ firstName: event.target.value })
							}
						/>
					</Form.Item>
				</Col>
				<Col md={12}>
					<Form.Item
						name="lastName"
						style={styles.formItem}
						rules={[
							{
								message: I18n.t(_t(translations.register.lastNameRequire)),
								required: true,
							},
						]}
					>
						<Input
							prefix={<UserOutlined className="site-form-item-icon" />}
							placeholder={I18n.t(_t(translations.placeHolder.lastName))}
							value={this.state.lastName}
							onChange={(event) =>
								this.setState({ lastName: event.target.value })
							}
						/>
					</Form.Item>
				</Col>
			</>
		);
	}
	renderPhoneAndEmail() {
		return (
			<>
				<Col md={6}>
					<Form.Item
						name="countryCode"
						style={styles.formItem}
						initialValue="US"
						rules={[
							{
								message: I18n.t(_t(translations.register.firstNameRequire)),
								required: true,
							},
						]}
					>
						<Select
							optionFilterProp="name"
							filterOption={(input, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							showSearch
							defaultValue={this.state.country}
							onChange={(value) => this.setState({ country: value })}
						>
							{COUNTRIES.map((c) => {
								return <Select.Option value={c.code}>{c.name}</Select.Option>;
							})}
						</Select>
					</Form.Item>
				</Col>
				<Col md={9}>
					<Form.Item
						name="phone"
						style={styles.formItem}
						rules={[
							{
								message: I18n.t(_t(translations.register.phoneRequire)),
								required: true,
								pattern: /^[0-9]{10}$/g,
							},
						]}
					>
						<Input
							prefix={<PhoneOutlined className="site-form-item-icon" />}
							placeholder={I18n.t(_t(translations.placeHolder.phone))}
							value={this.state.phone}
							onChange={(event) => this.setState({ phone: event.target.value })}
						/>
					</Form.Item>
				</Col>
				<Col md={9}>
					<Form.Item
						name="email"
						style={styles.formItem}
						rules={[
							{
								message: I18n.t(_t(translations.register.emailRequire)),
								type: 'email',
								required: true,
							},
						]}
					>
						<Input
							prefix={<MailOutlined className="site-form-item-icon" />}
							placeholder={I18n.t(_t(translations.placeHolder.email))}
							value={this.state.email}
							onChange={(event) => this.setState({ email: event.target.value })}
						/>
					</Form.Item>
				</Col>
			</>
		);
	}

	renderShopName() {
		return (
			<>
				<Col md={12}>
					<Form.Item
						name="shopName"
						style={styles.formItem}
						rules={[
							{
								message: I18n.t(_t(translations.register.shopName)),
								required: true,
							},
						]}
					>
						<Input
							prefix={<ShopOutlined className="site-form-item-icon" />}
							placeholder={I18n.t(_t(translations.placeHolder.shopName))}
							value={this.state.shopName}
							onBlur={(event) => {
								if (_.isEmpty(this.state.adminUrl)) {
									this.formRef.setFieldsValue({
										adminUrl: event.target.value.toLowerCase().replace(' ', ''),
									});
									this.setState({
										adminUrl: event.target.value.toLowerCase().replace(' ', ''),
									});
								}
							}}
							onChange={(event) => {
								this.setState({ shopName: event.target.value });
							}}
						/>
					</Form.Item>
				</Col>
				<Col md={12}>
					<Form.Item
						name="adminUrl"
						style={styles.formItem}
						initialValue={this.state.adminUrl}
						rules={[
							{
								message: I18n.t(_t(translations.register.shopName)),
								required: true,
							},
						]}
					>
						<Input
							prefix={<ShopOutlined className="site-form-item-icon" />}
							placeholder={I18n.t(_t(translations.placeHolder.shopId))}
							value={this.state.adminUrl}
							onChange={(event) =>
								this.setState({ adminUrl: event.target.value })
							}
						/>
					</Form.Item>
				</Col>
			</>
		);
	}
	renderUser() {
		return (
			<>
				<Col md={8}>
					<Form.Item
						style={styles.formItem}
						name="userName"
						rules={[
							{
								message: I18n.t(_t(translations.register.userName)),
								required: true,
							},
							{
								message: I18n.t(_t(translations.register.userName)),
								min: 6,
							},
						]}
					>
						<Input
							prefix={<UserOutlined className="site-form-item-icon" />}
							placeholder={I18n.t(_t(translations.placeHolder.username))}
							value={this.state.firstName}
							onChange={(event) =>
								this.setState({ firstName: event.target.value })
							}
						/>
					</Form.Item>
				</Col>
				<Col md={8}>
					<Form.Item
						name="password"
						style={styles.formItem}
						rules={[
							{
								message: I18n.t(_t(translations.login.inputPasswordMessage)),
								required: true,
							},
							{
								message: I18n.t(
									_t(translations.register['formatPasswordMessage='])
								),
								pattern:
									/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g,
							},
						]}
					>
						<Input
							prefix={<LockOutlined className="site-form-item-icon" />}
							placeholder={I18n.t(_t(translations.login.loginPassword))}
							type="password"
							value={this.state.lastName}
							onChange={(event) =>
								this.setState({ lastName: event.target.value })
							}
						/>
					</Form.Item>
				</Col>
				<Col md={8}>
					<Form.Item
						name="confirmPassword"
						style={styles.formItem}
						dependencies={['password']}
						rules={[
							{
								message: I18n.t(
									_t(translations.resetPassword.confirmPasswordMessage)
								),
								required: true,
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject(
										new Error(
											I18n.t(_t(translations.resetPassword.confirmNotMatch))
										)
									);
								},
							}),
						]}
					>
						<Input
							prefix={<LockOutlined className="site-form-item-icon" />}
							placeholder={I18n.t(
								_t(translations.resetPassword.confirmPassword)
							)}
							type="password"
							value={this.state.lastName}
							onChange={(event) =>
								this.setState({ lastName: event.target.value })
							}
						/>
					</Form.Item>
				</Col>
			</>
		);
	}

	renderAddressInputs = () => {
		return (
			<Row gutter={8}>
				<Col md={24}>
					<Form.Item
						name="street"
						style={styles.formItem}
						rules={[
							{
								message: I18n.t(_t(translations.partner.streetRequired)),

								required: true,
							},
						]}
					>
						<Input
							prefix={<HomeOutlined className="site-form-item-icon" />}
							placeholder={I18n.t(_t(translations.branchSetting.street))}
						/>
					</Form.Item>
				</Col>
				<Col md={8}>
					<Form.Item
						style={styles.formItem}
						rules={[
							{
								message: I18n.t(_t(translations.partner.cityRequired)),
								required: true,
							},
						]}
					>
						<Select
							placeholder={I18n.t(_t(translations.branchSetting.city))}
							showSearch
							optionFilterProp="children"
							value={this.state.city}
							onSelect={(code) => {
								this.onSelectCity(code);
							}}
						>
							{this.state.cities?.map((x) => {
								return <Select.Option value={x.name}>{x.name}</Select.Option>;
							})}
						</Select>
					</Form.Item>
				</Col>
				<Col md={8}>
					<Form.Item
						style={styles.formItem}
						rules={[
							{
								message: I18n.t(_t(translations.partner.stateRequired)),
								required: true,
							},
						]}
					>
						<Select
							placeholder={I18n.t(_t(translations.branchSetting.state))}
							showSearch
							optionFilterProp="children"
							value={this.state.state?.isoCode}
							onSelect={(code) => {
								this.onSelectState(code);
							}}
						>
							{this.state.states?.map((x) => {
								return (
									<Select.Option value={x.isoCode}>{x.name}</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>
				<Col md={8}>
					<Form.Item
						style={styles.formItem}
						rules={[
							{
								message: I18n.t(_t(translations.partner.zipCodeRequired)),
								required: true,
							},
						]}
					>
						<Input
							placeholder={I18n.t(_t(translations.partner.zipCode))}
							value={this.state.zipCode}
							onChange={(e) =>
								this.setState({ zipCode: e.target.value }, () => {
									this.onChangeDebounce();
								})
							}
							prefix={<CodeOutlined className="site-form-item-icon" />}
						/>
					</Form.Item>
				</Col>
			</Row>
		);
	};

	renderThemeInputs = () => {
		const { isLoading } = this.state;
		return (
			// <Card>
			// 	<Card.Meta
			// 		title={I18n.t(_t(translations.shopSetting.theme))}
			// 		description={I18n.t(_t(translations.shopSetting.subTheme))}
			// 	/>
			// 	<Divider />
			// <Row gutter={16}>
			<>
				<Form.Item name={['themeShopConfig', 'defaultColor']} noStyle />
				{formHelper.renderFormInput(
					['themeShopConfig', 'themeName'],
					[{ required: true }],
					I18n.t(_t(translations.shopSetting.landingPageTheme)),
					// !_.isEmpty(this.state.themes) ? this.state.themes[0].themeName : '',
					'lavi',
					undefined,
					{
						contentEditable: false,
						onClick: () => {
							this.setState({ isShowThemePickerModal: true });
						},
						className: 'uneditable-input-with-icon',
						prefix: <BgColorsOutlined />,
						disabled: isLoading,
					}
				)}
			</>
			// </Row>
			// </Card>
		);
	};
	async onSubmit(values: any, that: any) {
		that.setState({ isLoading: true });
		const newShop: IShopRegister = {
			...(values as IShopRegister),
			shopCategoryId: '0144ff7a-7159-4ac5-9c6b-323bb98a47bd',
			// localTimeZone: moment.tz.guess(),
			localTimeZone: this.state.timeZone,
			street: undefined,
			address: {
				countryCode: values.countryCode,
				street: values.street,
				city: this.state.city,
				state: this.state.state?.name,
				zipCode: this.state.zipCode,
			},
			portalUrl: `${values.adminUrl}.website.smartsalon.live`,
		} as any;
		const response = await AuthenticationApiService.register(newShop);
		let successResponse = response as IApiResponse;
		that.setState({ isLoading: false });
		if (successResponse.succeeded) {
			showAlert(
				I18n.t(_t(translations.partner.registerSuccessfully)),
				I18n.t(_t(translations.partner.checkMail)),
				'success'
			);
			that.setState({ done: true });
			history.push('/confirm-success');
		} else {
			// showAlert(
			// 	response.message[0].Text || 'Error',
			// 	response.message[0].Text ||
			// 		I18n.t(_t(translations.resetPassword.contact)),
			// 	'error'
			// );
			showError(response as IErrorResponse);
		}
	}
	onSelectState = (code: string) => {
		const cities = getAllCity(code);
		const currentState = this.state.states?.find((x) => x.isoCode === code);
		if (currentState) {
			this.setState({
				state: currentState,
				timeZone: tzlookup(
					currentState.latitude as any,
					currentState.longitude as any
				),
			});
		}
		if (cities) {
			this.setState({ cities });
			const currentCity = cities.find((x) => x.name === this.state.city);
			if (!currentCity) {
				this.setState({
					city: '',
				});
			}
		}
	};

	onSelectCity = (name: string) => {
		this.setState({ city: name });
		const currentCity = this.state.cities?.find((x) => x.name === name);
		if (!_.isEmpty(currentCity)) {
			this.setState({
				timeZone: tzlookup(
					currentCity?.latitude as any,
					currentCity?.longitude as any
				),
			});
		}

		const zipCodes = getZipFromLocation(this.state.state?.isoCode, name);
		if (!_.isEmpty(zipCodes)) {
			this.setState({
				zipCode: zipCodes[0].zip,
			});
		}
	};
	getAllThemes = async () => {
		this.setState({ isLoading: true });
		try {
			const result = (await ThemeApiService.getAllThemes({
				pageNumber: 1,
				pageSize: 100,
			})) as IApiResponse<ITheme[]>;
			if (result.succeeded) {
				this.setState({ themes: result.data });
			} else {
				AlertHelper.showError(result as IErrorResponse);
			}
		} catch (error) {
			console.error('Network error', error);
		} finally {
			this.setState({ isLoading: false });
		}
	};

	// async onFormFinish() {
	// 	const response = (await ShopApiService.getShopByAdminUrl(
	// 		this.state.shop!
	// 	)) as IApiResponse<IShop>;
	// 	if (response.succeeded && !_.isEmpty(response.data)) {
	// 		window.location.href = `https://${this.state.shop}.${process.env
	// 			.REACT_APP_PRODUCTION_URL!}/`;
	// 	} else {
	// 		alert('Shop not exist');
	// 	}
	// }
}

export default PartnerRegister;
