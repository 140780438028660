import GlobalStyles, { kStyles } from "GlobalStyles";

const styles: kStyles = {
  layout: {
    minHeight: "100vh",
  },
  formContainer: {
    width: "100%",
    display: "contents",
    marginTop: 10,
    minHeight: "100vh",
  },
  headerContainer: {
    zIndex: 9999,
    width: "100%",
    backgroundColor: "white",
    position: "fixed",
    ...GlobalStyles.boxShadow,
  },
  rowHeader: {
    paddingInline: 16,
    alignItems: "center",
    paddingTop: 16,
    paddingBottom: 16,
  },
  icClose: {
    fontSize: 18,
    zIndex: 1,
  },
  headerTitle: {
    textAlign: "center",
    zIndex: 9999,
    marginBottom: "auto",
  },
  buttonContainer: { textAlign: "right", marginBottom: 10 },
  // rowForm: { paddingTop: "120px" }
  spinLayout: {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  spin: { margin: "0 auto" },
  divider: {
    margin: "16px 0",
  },
  avatar: {
    borderRadius: 10,
  },
  maxWidthSpace: {
    minWidth: "100%",
    marginTop: 16,
  },
  noPaddingCol: {
    paddingInline: 0,
    width: "100%",
  },
  container: {
    display: "flex",
    width: "100%",
    marginBottom:50
  },
  maxWidth: {
    minWidth: "100%",
  },
  loading: {
     margin: "0 auto" 
  },
  colContainer: {
    padding: 16,
    backgroundColor: "white",
  },
  btnNewBooking: {
    width: "100%",
    fontSize: 18,
  },
  boxShadow: {
    width: "100%",
    boxShadow: "0 2px 5px 0 rgb(164 173 186 / 25%)",
  },
  listItem: {
    width: "100%",
  },
  listBooking: {
    height: '300px',
    overflow: "auto",
  },
  isClose: {
    color: "white",
    fontSize: 18,
    cursor: "pointer",
    position: "absolute",
    right: 32,
  }
};

export default styles;
