/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, Row, Select, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { FormHelper, StringHelper } from 'helpers';
import ModalHelper from 'helpers/modalHelper';
import React, { useEffect, useState } from 'react';
import { translations, _t, I18n } from 'utils';
import { ConfirmModal } from 'components';
import { IBranch } from 'models/IBranch';
import {
	getAllCity,
	getAllStates,
	getZipFromLocation,
	ICity,
	IState,
} from 'helpers/geolocation';
import _ from 'lodash';
import tz_lookup from 'tz-lookup';

interface IBranchDetailModal {
	branch?: IBranch;
	onCancel: () => void;
	onSubmit: (branch: FormData) => void;
	visibility: boolean;
	isLoading: boolean;
	onDelete: (id: string) => void;
}
const BranchDetailModal = ({
	onCancel,
	visibility,
	onSubmit,
	branch,
	isLoading,
	onDelete,
}: IBranchDetailModal) => {
	const [form] = useForm<FormData>();
	const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);
	const [cities, setCities] = useState<ICity[]>([]);
	// const [selectedCity, setSelectedCity] = useState<ICity>();
	const [states, setStates] = useState<IState[]>([]);
	const [selectedState, setSelectedState] = useState<IState>();

	useEffect(() => {
		const _states = getAllStates();
		setStates(_states);
	}, []);

	useEffect(() => {
		// setSelectedState(states.find((x) => x.name === branch?.address?.state));
	}, [states]);

	useEffect(() => {
		form.resetFields();
	}, [visibility]);

	const onSelectState = (code: string) => {
		setCities([]);
		const cities = getAllCity(code);
		const currentState = states?.find((x) => x.isoCode === code);
		if (currentState) {
			setSelectedState(currentState);
			const timezone = tz_lookup(
				currentState.latitude as any,
				currentState.longitude as any
			);
			form.setFieldsValue({
				address: { state: currentState.name },
				localTimeZone: timezone,
			});
		}
		if (cities) {
			setCities(cities);
			const currentCity = cities.find(
				(x) => x.name === form.getFieldValue(['address', 'city'])
			);
			if (!currentCity) {
				// setSelectedCity(undefined);
				form.setFieldsValue({ address: { city: '' } });
			}
		}
	};
	const onSelectCity = (name: string) => {
		form.setFieldsValue({ address: { city: name } });
		const zipCodes = getZipFromLocation(selectedState?.isoCode, name);
		if (!_.isEmpty(zipCodes)) {
			form.setFieldsValue({ address: { zipCode: zipCodes[0].zip } });
		}
	};

	return (
		<ModalHelper
			onCancel={onCancel}
			onOk={form.submit}
			title={I18n.t(_t(translations.branches.addNew))}
			visible={visibility}
			isLoading={isLoading}
			onDelete={() => setIsShowConfirmModal(true)}
			disableDelete={!branch}
			btnDelete={branch?.id ? true : false}
		>
			<Form<FormData>
				layout="vertical"
				name="loyalty-detail-modal"
				onFinish={onSubmit}
				form={form}
			>
				{FormHelper.renderFormInput(
					'name',
					[
						{
							required: true,
							message: I18n.t(_t(translations.branchSetting.branchNameMessage)),
						},
					],
					I18n.t(_t(translations.branchSetting.branchName)),
					branch?.name!,
					{},
					{ inputMode: 'text', maxLength: 256 }
				)}
				<Row gutter={16}>
					{FormHelper.renderFormPhoneInput(
						'phone',
						[
							{
								required: true,
								message: I18n.t(_t(translations.branchSetting.phoneMessage)),
							},
							({ getFieldValue }) => ({
								validator(robj, value) {
									if (value) {
										if (StringHelper.checkPhoneNumber(value)) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error(
												I18n.t(_t(translations.staffDetail.messagePhone))
											)
										);
									}
									return Promise.resolve();
								},
							}),
						],
						I18n.t(_t(translations.branchSetting.phone)),
						branch?.phone || '',
						{ span: 12 },
						{}
					)}
					{FormHelper.renderFormInput(
						'fax',
						[
							// {
							// 	required: true,
							// 	message: I18n.t(_t(translations.branchSetting.faxMessage)),
							// },
						],
						I18n.t(_t(translations.branchSetting.fax)),
						branch?.fax || '',
						{ span: 12 },
						{ maxLength: 256 }
					)}
				</Row>
				<Form.Item name={'localTimeZone'} noStyle/>
				<Row gutter={16}>
					{FormHelper.renderFormInput(
						['address', 'street'],
						[
							// {
							// 	required: true,
							// 	message: I18n.t(_t(translations.branchSetting.streetMessage)),
							// },
						],
						I18n.t(_t(translations.branchSetting.street)),
						branch?.address?.street || '',
						{ span: 24 },
						{ inputMode: 'text', maxLength: 256 }
					)}
					<Col span={8}>
						<Form.Item
							label={
								<Typography.Title level={5}>
									{I18n.t(_t(translations.branchSetting.city))}
								</Typography.Title>
							}
							name={['address', 'city']}
							initialValue={branch?.address?.city || ''}
							rules={[
								{
									required: true,
									message: I18n.t(_t(translations.branchSetting.cityMessage)),
								},
							]}
						>
							{/* <Input
								value={this.state.city}
								onChange={(e) => this.setState({ city: e.target.value })}
							/> */}
							<Select
								showSearch
								optionFilterProp="children"
								onSelect={(code: string) => {
									onSelectCity(code);
								}}
							>
								{cities?.map((x) => {
									return <Select.Option value={x.name}>{x.name}</Select.Option>;
								})}
							</Select>
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item
							label={
								<Typography.Title level={5}>
									{I18n.t(_t(translations.branchSetting.state))}
								</Typography.Title>
							}
							name={['address', 'state']}
							initialValue={branch?.address?.state || ''}
							rules={[
								{
									required: true,
									message: I18n.t(_t(translations.branchSetting.stateMessage)),
								},
							]}
						>
							<Select
								showSearch
								optionFilterProp="children"
								onSelect={(code: string) => {
									onSelectState(code);
								}}
							>
								{states.map((x) => {
									return (
										<Select.Option value={x.isoCode}>{x.name}</Select.Option>
									);
								})}
							</Select>
							{/* <Input
								value={this.state.state}
								onChange={(e) => this.setState({ state: e.target.value })}
							/> */}
						</Form.Item>
					</Col>

					{/* {FormHelper.renderFormInput(
						'city',
						[
							// {
							// 	required: true,
							// 	message: I18n.t(_t(translations.branchSetting.cityMessage)),
							// },
						],
						I18n.t(_t(translations.branchSetting.city)),
						this.props.currentBranch?.address?.city || '',
						{ span: 12 },
						{ inputMode: 'text', maxLength: 256 }
					)}
					{FormHelper.renderFormInput(
						'state',
						[
							// {
							// 	required: true,
							// 	message: I18n.t(_t(translations.branchSetting.stateMessage)),
							// },
						],
						I18n.t(_t(translations.branchSetting.state)),
						this.props.currentBranch?.address?.state || '',
						{ span: 12 },
						{ inputMode: 'text', maxLength: 256 }
					)} */}
				</Row>
			</Form>
			<ConfirmModal
				visible={isShowConfirmModal}
				onCancel={() => setIsShowConfirmModal(false)}
				onOk={() => {
					onDelete(branch!.id);
					setIsShowConfirmModal(false);
				}}
			/>
		</ModalHelper>
	);
};

type FormData = Partial<IBranch>;
export default BranchDetailModal;
