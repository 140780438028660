import { IShop } from 'models/IShop';
import { BaseApiService } from 'services/BaseApiService';

const prefix = '/Shop';
class ShopApiService extends BaseApiService {
	public getShopByAdminUrl = (adminUrl: string) =>
		this.get<IShop>(`${prefix}/get-shop-by-admin-url`, {
			params: {
				adminUrl: adminUrl,
			},
		});
	public updateShop = (request: IShop) =>
		this.put<IShop, IShop>(`${prefix}/update-shop`, request);
}

export default new ShopApiService();
