import CalendarFilled from '@ant-design/icons/lib/icons/CalendarFilled';
import CloudFilled from '@ant-design/icons/lib/icons/CloudFilled';
import Icon from '@ant-design/icons';
import { ColorHelper } from 'helpers';
import { EBookingInputFrom, EBookingType } from 'models';

const WalkingIcon = () => (
	<svg viewBox="0 0 1024 1024" width={24} height={24} fill="currentColor">
		<g transform="translate(128 128) scale(0.8 0.8)">
			<g
				style={{
					stroke: 'none',
					strokeWidth: 0,
					strokeDasharray: 'none',
					strokeLinecap: 'butt',
					strokeLinejoin: 'miter',
					strokeMiterlimit: 10,
					fill: 'currentColor',
					fillRule: 'nonzero',
					opacity: 1,
				}}
				transform="translate(-175.05 -175.05000000000004) scale(14 14)"
			>
				<path
					d="M 55.044 90 c -1.217 0 -2.387 -0.415 -3.347 -1.198 c -1.127 -0.918 -1.815 -2.222 -1.939 -3.671 l -1.603 -20.142 c -0.065 -0.907 -0.497 -1.76 -1.184 -2.346 L 36.514 53.72 c -1.866 -1.592 -2.807 -3.996 -2.519 -6.431 l 1.762 -14.868 l -2.105 1.357 c -0.712 0.459 -1.179 1.204 -1.282 2.044 l -1.079 8.801 c -0.317 2.581 -2.677 4.432 -5.257 4.132 c -1.262 -0.147 -2.39 -0.779 -3.176 -1.779 c -0.785 -0.999 -1.133 -2.245 -0.978 -3.506 l 1.52 -12.399 c 0.246 -2.009 1.38 -3.762 3.112 -4.81 l 9.54 -5.771 c 1.987 -1.202 4.347 -1.678 6.643 -1.346 l 3.988 0.581 c 2.193 0.319 3.707 2.294 3.446 4.496 L 47.5 46.412 c -0.086 0.729 0.196 1.449 0.755 1.926 l 8.4 7.168 c 1.252 1.069 2.038 2.624 2.157 4.266 l 1.926 24.425 c 0.24 2.868 -1.815 5.384 -4.68 5.723 h -0.001 l -0.377 0.044 C 55.468 89.987 55.255 90 55.044 90 z M 55.942 88.925 h 0.01 H 55.942 z"
					style={{
						stroke: 'none',
						strokeWidth: 1,
						strokeDasharray: 'none',
						strokeLinecap: 'butt',
						strokeLinejoin: 'miter',
						strokeMiterlimit: 10,
						fill: 'currentColor',
						fillRule: 'nonzero',
						opacity: 1,
					}}
					transform=" matrix(1 0 0 1 0 0) "
					strokeLinecap="round"
				/>
				<path
					d="M 63.423 46.153 c -0.051 0 -0.102 -0.001 -0.152 -0.002 c -1.27 -0.04 -2.448 -0.573 -3.316 -1.502 l -8.351 -8.941 c -0.208 -0.223 -0.304 -0.527 -0.259 -0.829 l 1.398 -9.463 c 0.056 -0.38 0.325 -0.694 0.691 -0.809 c 0.365 -0.115 0.768 -0.009 1.028 0.272 l 12.42 13.297 c 1.775 1.9 1.682 4.897 -0.209 6.683 C 65.786 45.697 64.637 46.153 63.423 46.153 z"
					style={{
						stroke: 'none',
						strokeWidth: 1,
						strokeDasharray: 'none',
						strokeLinecap: 'butt',
						strokeLinejoin: 'miter',
						strokeMiterlimit: 10,
						fill: 'currentColor',
						fillRule: 'nonzero',
						opacity: 1,
					}}
					transform=" matrix(1 0 0 1 0 0) "
					strokeLinecap="round"
				/>
				<path
					d="M 47.295 17.771 c -4.899 0 -8.885 -3.986 -8.885 -8.886 S 42.396 0 47.295 0 s 8.886 3.986 8.886 8.886 S 52.195 17.771 47.295 17.771 z"
					style={{
						stroke: 'none',
						strokeWidth: 1,
						strokeDasharray: 'none',
						strokeLinecap: 'butt',
						strokeLinejoin: 'miter',
						strokeMiterlimit: 10,
						fill: 'currentColor',
						fillRule: 'nonzero',
						opacity: 1,
					}}
					transform=" matrix(1 0 0 1 0 0) "
					strokeLinecap="round"
				/>
				<path
					d="M 32.461 90 c -0.341 0 -0.685 -0.033 -1.027 -0.1 c -2.832 -0.55 -4.727 -3.309 -4.225 -6.149 l 0 0 l 4.979 -28.135 c 0.062 -0.351 0.306 -0.642 0.64 -0.765 c 0.335 -0.122 0.708 -0.059 0.982 0.168 l 7.628 6.304 c 0.277 0.229 0.41 0.589 0.348 0.943 l -4.097 23.332 c -0.249 1.418 -1.039 2.647 -2.226 3.464 C 34.565 89.68 33.526 90 32.461 90 z"
					style={{
						stroke: 'none',
						strokeWidth: 1,
						strokeDasharray: 'none',
						strokeLinecap: 'butt',
						strokeLinejoin: 'miter',
						strokeMiterlimit: 10,
						fill: 'currentColor',
						fillRule: 'nonzero',
						opacity: 1,
					}}
					transform=" matrix(1 0 0 1 0 0) "
					strokeLinecap="round"
				/>
			</g>
		</g>
	</svg>
);

const InputFromTag = (props: {
	inputSource: EBookingInputFrom;
	bookingStatus: number;
	bookingType: EBookingType;
}) => {
	const { inputSource, bookingStatus, bookingType } = props;
	const style = {
		fontSize: 24,
		color: ColorHelper.getStatusColor(bookingStatus),
	};
	if (bookingType === EBookingType.WalkIn) {
		return <Icon component={WalkingIcon} style={style} />;
	}
	switch (inputSource) {
		case EBookingInputFrom.OnlineSite:
			return <CloudFilled style={style} />;
		case (EBookingInputFrom.StylistApp,
		EBookingInputFrom.AdminWebsite,
		EBookingInputFrom.POS):
			return <CalendarFilled style={style} />;
		case EBookingInputFrom.Other:
			return <Icon component={WalkingIcon} style={style} />;
		default:
			return null;
	}
};
export default InputFromTag;
