import { IBooking } from 'models';
import {
	IPromotion,
	IPromotionCouponRequest,
	IPromotionCoupon,
	IPromotionItem,
	IPromotionItemRequest,
	IPromotionItemCategory,
	IPromotionItemCategoryRequest,
	IPromotionType,
	IRequirement,
	IRequirementsRequest,
	ILoyaltyRank,
	IApplyPromotionRequest,
	IGetAvailablePromotionsRequest,
	IGetPromotionByCouponCode,
} from 'models/IPromotion';
import { BaseApiService } from 'services/BaseApiService';

class PromotionApiService extends BaseApiService {
	public getPromotions = (query?: any) =>
		this.get<IPromotion[]>(`/Promotion/get-all-promotions`, {
			params: query,
		});
	public getPromotionById = (id: string) =>
		this.get<IPromotion>(`/Promotion/get-promotion/${id}`);
	public addPromotion = (addPromotionRequest: Partial<IPromotion>) =>
		this.post<Partial<IPromotion>, string>(
			`/Promotion/create-promotion`,
			addPromotionRequest
		);
	public editPromotion = (editPromotionRequest: Partial<IPromotion>) =>
		this.put<Partial<IPromotion>, string>(
			`/Promotion/update-promotion`,
			editPromotionRequest
		);
	public deletePromotion = (id: string) =>
		this.delete<string>(`/Promotion/delete-promotion/${id}`);
	public getPromotionType = (query?: any) =>
		this.get<IPromotionType[]>(`/PromotionType/get-all-promotion-types`, {
			params: query,
		});

	public getPromotionCoupon = (query?: any) =>
		this.get<IPromotionCoupon[]>(`/PromotionCoupon/get-by-promotion-id`, {
			params: query,
		});
	public addPromotionCoupon = (
		addPromotionCouponRequest: Partial<IPromotionCouponRequest>
	) =>
		this.post<Partial<IPromotionCouponRequest>, string>(
			`/PromotionCoupon/add-coupons`,
			addPromotionCouponRequest
		);
	public updatePromotionCoupon = (
		updatePromotionCouponRequest: Partial<IPromotionCouponRequest>
	) =>
		this.post<Partial<IPromotionCouponRequest>, string>(
			`/PromotionCoupon/update-coupons`,
			updatePromotionCouponRequest
		);
	public removePromotionCoupon = (
		removePromotionCouponRequest: Partial<IPromotionCouponRequest>
	) =>
		this.post<Partial<IPromotionCouponRequest>, string>(
			`/PromotionCoupon/remove-coupons`,
			removePromotionCouponRequest
		);

	public getPromotionItem = (query?: any) =>
		this.get<IPromotionItem>(`/PromotionItem/get-by-promotion-id`, {
			params: query,
		});
	public addPromotionItem = (
		addPromotionItemRequest: Partial<IPromotionItemRequest>
	) =>
		this.post<Partial<IPromotionItemRequest>, string>(
			`/PromotionItem/add-items`,
			addPromotionItemRequest
		);
	public updatePromotionItem = (
		updatePromotionItemRequest: Partial<IPromotionItemRequest>
	) =>
		this.post<Partial<IPromotionItemRequest>, string>(
			`/PromotionItem/update-items`,
			updatePromotionItemRequest
		);
	public removePromotionItem = (
		removePromotionItemRequest: Partial<IPromotionItemRequest>
	) =>
		this.post<Partial<IPromotionItemRequest>, string>(
			`/PromotionItem/remove-items`,
			removePromotionItemRequest
		);
	public getPromotionItemCategory = (query?: any) =>
		this.get<IPromotionItemCategory>(
			`/PromotionItemCategory/get-by-promotion-id`,
			{
				params: query,
			}
		);
	public addPromotionItemCategory = (
		addPromotionItemCategoryRequest: Partial<IPromotionItemCategoryRequest>
	) =>
		this.post<Partial<IPromotionItemCategoryRequest>, string>(
			`/PromotionItemCategory/add-item-categories`,
			addPromotionItemCategoryRequest
		);
	public updatePromotionItemCategory = (
		updatePromotionItemCategoryRequest: Partial<IPromotionItemCategoryRequest>
	) =>
		this.post<Partial<IPromotionItemCategoryRequest>, string>(
			`/PromotionItemCategory/update-item-categories`,
			updatePromotionItemCategoryRequest
		);
	public removePromotionItemCategory = (
		removePromotionItemCategoryRequest: Partial<IPromotionItemCategoryRequest>
	) =>
		this.post<Partial<IPromotionItemCategoryRequest>, string>(
			`/PromotionItemCategory/remove-item-categories`,
			removePromotionItemCategoryRequest
		);

	public getPromotionRequirement = (query?: any) =>
		this.get<IRequirement[]>(`/PromotionRequirement/get-by-promotion-id`, {
			params: query,
		});
	public updatePromotionRequirement = (
		updatePromotionRequirementRequest: Partial<IRequirementsRequest>
	) =>
		this.post<Partial<IRequirementsRequest>, string>(
			`/PromotionRequirement/update-requirements`,
			updatePromotionRequirementRequest
		);

	public getLoyaltyRank = (query?: any) =>
		this.get<ILoyaltyRank[]>(`/LoyaltyRank/get-all-loyalty-ranks`, {
			params: query,
		});
	public getAvailablePromotion = (request: IGetAvailablePromotionsRequest) =>
		this.post<IGetAvailablePromotionsRequest, IPromotion[]>(
			'/Promotion/get-available-promotion',
			request
		);
	public applyPromotion = (request: IApplyPromotionRequest) =>
		this.post<IApplyPromotionRequest, Partial<IBooking>>(
			'/Promotion/apply-promotion',
			request
		);
	public getPromotionByCouponCode = (request: IGetPromotionByCouponCode) =>
		this.post<IGetPromotionByCouponCode, unknown>(
			'/Promotion/get-promotion-by-coupon',
			request
		);
}

export default new PromotionApiService();
