import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
	Button,
	Col,
	Input,
	Row,
	Space,
	Table,
	TablePaginationConfig,
	Typography,
} from 'antd';
import { FormInModal } from 'components';
import AppTable, { IColumnProps, ITableRef } from 'components/Table';
import { FormHelper } from 'helpers';
import { showAlert } from 'helpers/alertHelper';
import _ from 'lodash';
import { IApiResponse, IRole } from 'models';
import { IEmployeeType } from 'models/IEmployeeType';
import { IEditEmployeeTypeRequest } from 'models/RequestModels';
import { Component, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { BranchActions, EmployeeTypeActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import EmployeeTypeApiService from 'services/EmployeeTypeApiService';
import { I18n, translations, _t } from 'utils';
import styles from './styles';
interface IListEmployeeTypeStates {
	pagination: TablePaginationConfig;
	showModal: boolean;
	isAdd: boolean;
	isLoading: boolean;
	employeeTypes: IEmployeeType[];
	searchText: string;
}

const PAGE_SIZE = 10;
interface IListEmployeeTypeProps
	extends RouteComponentProps<any, any, any>,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}
// class ListEmployeeType extends Component<
// 	IListEmployeeTypeProps,
// 	IListEmployeeTypeStates
// > {
// 	state = {
// 		pagination: {
// 			current: 1,
// 			pageSize: PAGE_SIZE,
// 			showTotal: (total: any) => `Total ${total} items`,
// 		},
// 		showModal: false,
// 		isAdd: true,
// 		isLoading: false,
// 		employeeTypes: [],
// 		searchText: '',
// 	};

// 	constructor(props: IListEmployeeTypeProps) {
// 		super(props);
// 		this.onChangeDebounce = _.debounce(this.onChangeDebounce, 1000);
// 	}

// 	componentDidMount() {
// 		this.onTableChange(1, 'ascend', '');
// 	}
// 	componentDidUpdate(
// 		prevProps: IListEmployeeTypeProps,
// 		prevState: IListEmployeeTypeStates
// 	) {
// 		if (prevProps.currentAction !== this.props.currentAction) {
// 			if (
// 				this.props.currentAction === BranchActions.getBranchById.requestName
// 			) {
// 				this.onTableChange(1, 'ascend', '');
// 			}
// 		}
// 	}
// 	render() {
// 		return (
// 			<>
// 				<Space direction="vertical" style={styles.container} size="large">
// 					<Row>
// 						<Col style={{ width: '100%' }}>
// 							{this.renderHeader()}
// 							{this.renderEmployeeTypeTable()}
// 						</Col>
// 					</Row>
// 				</Space>
// 				{this.renderFormBody()}
// 			</>
// 		);
// 	}

// 	renderHeader = () => {
// 		return (
// 			<Row
// 				gutter={[16, 16]}
// 				style={{ alignItems: 'center', paddingBottom: 20 }}
// 			>
// 				<Col md={24}>
// 					<Input
// 						allowClear
// 						prefix={<SearchOutlined />}
// 						placeholder={I18n.t(_t(translations.employeeType.searchType))}
// 						style={styles.maxWidth}
// 						onChange={this.onChangeDebounce}
// 					/>
// 				</Col>
// 				<Col md={24}>
// 					<Space
// 						style={{ justifyContent: 'flex-end', width: '100%' }}
// 						size="small"
// 					>
// 						<Button
// 							icon={<PlusOutlined />}
// 							type="primary"
// 							onClick={() => this.handleBtnAddNew()}
// 						>
// 							{I18n.t(_t(translations.addNew))}
// 						</Button>
// 					</Space>
// 				</Col>
// 			</Row>
// 		);
// 	};

// 	renderEmployeeTypeTable() {
// 		return (
// 			<>
// 				<Table
// 					style={{ paddingBottom: 24, cursor: 'pointer' }}
// 					scroll={{ y: '50vh', x: true }}
// 					size="large"
// 					dataSource={this.state.employeeTypes}
// 					loading={this.state.isLoading}
// 					columns={[
// 						{
// 							title: (
// 								<Typography.Title level={5}>
// 									{I18n.t(_t(translations.employeeType.employeeType))}
// 								</Typography.Title>
// 							),
// 							dataIndex: 'name',
// 							render: (text?: string) => (text ? text : ''),
// 						},
// 						{
// 							title: (
// 								<Typography.Title level={5}>
// 									{I18n.t(_t(translations.employeeType.rolePermission))}
// 								</Typography.Title>
// 							),
// 							dataIndex: 'roleName',
// 							render: (text?: string) => (text ? text : ''),
// 						},
// 						{
// 							title: (
// 								<Typography.Title level={5}>
// 									{I18n.t(_t(translations.employeeType.description))}
// 								</Typography.Title>
// 							),
// 							dataIndex: 'description',
// 							render: (text?: string) => (text ? text : ''),
// 						},
// 					]}
// 					pagination={this.state.pagination}
// 					onChange={(pagination, filter, sorter) =>
// 						this.onTableChange(pagination.current!, sorter, '')
// 					}
// 					rowKey={(record: IEmployeeType) => record.id}
// 					onRow={this.onClickRow}
// 				/>
// 			</>
// 		);
// 	}

// 	renderFormBody() {
// 		const description = this.props.currentEmployeeType
// 			? this.props.currentEmployeeType.description
// 			: '';

// 		const employeeType = this.props.currentEmployeeType
// 			? this.props.currentEmployeeType.name
// 			: '';

// 		const roleId = this.props.currentEmployeeType
// 			? this.props.currentEmployeeType.roleId
// 			: this.props.roles && this.props.roles.length > 0
// 			? this.props.roles[0].id
// 			: '';

// 		const status = this.props.currentEmployeeType
// 			? this.props.currentEmployeeType.status === 0
// 				? false
// 				: true
// 			: true;

// 		if (!_.isEmpty(this.props.currentEmployeeType) || this.state.isAdd) {
// 			return (
// 				<>
// 					<FormInModal
// 						visible={this.state.showModal}
// 						title={
// 							this.state.isAdd
// 								? I18n.t(_t(translations.employeeType.addNewEmployeeType))
// 								: I18n.t(_t(translations.employeeType.editEmployeeType))
// 						}
// 						onCancel={() => this.handleCancelModal()}
// 						onOk={this.onFormFinish}
// 						// isAdd={this.state.isAdd}
// 						centered
// 					>
// 						{/* <Row gutter={[16, 0]}> */}
// 						<Col sm={24} md={24} style={styles.noPaddingCol}>
// 							{FormHelper.renderFormInput(
// 								'employeeType',
// 								[
// 									{
// 										required: true,
// 										message: I18n.t(
// 											_t(translations.employeeType.employeeTypeRequired)
// 										),
// 									},
// 								],
// 								I18n.t(_t(translations.employeeType.employeeType)),
// 								employeeType,
// 								{},
// 								{
// 									placeholder: I18n.t(
// 										_t(translations.employeeType.inputEmployeeType)
// 									),
// 									autoFocus: true,
// 								}
// 							)}
// 						</Col>
// 						{/* </Row> */}
// 						{/* <Row gutter={[16, 16]}> */}
// 						<Col sm={24} md={24} style={styles.noPaddingCol}>
// 							{FormHelper.renderFormInput(
// 								'description',
// 								[
// 									{
// 										required: false,
// 										message: I18n.t(
// 											_t(translations.employeeType.descriptionRequired)
// 										),
// 									},
// 								],
// 								I18n.t(_t(translations.employeeType.description)),
// 								description,
// 								{},
// 								{
// 									placeholder: I18n.t(
// 										_t(translations.employeeType.inputDescription)
// 									),
// 								}
// 							)}
// 						</Col>
// 						{/* </Row> */}
// 						{/* <Row gutter={[16, 16]}> */}
// 						<Col sm={24} md={24} style={styles.noPaddingCol}>
// 							{FormHelper.renderFormSelect<IRole>(
// 								'roleId',
// 								I18n.t(_t(translations.employeeType.rolePermission)),
// 								this.props.roles ? this.props.roles : [],
// 								'name',
// 								'id',
// 								roleId
// 							)}
// 						</Col>
// 						{/* </Row> */}
// 						{/* <Row gutter={[16, 16]}> */}
// 						<Col sm={24} md={24} style={styles.noPaddingCol}>
// 							{FormHelper.renderFormSwitch(
// 								'status',
// 								I18n.t(_t(translations.employeeType.status)),
// 								status
// 							)}
// 						</Col>
// 						{/* </Row> */}
// 					</FormInModal>
// 				</>
// 			);
// 		}
// 	}
// 	onClickRow = (record: IEmployeeType) => {
// 		return {
// 			onClick: () => {
// 				this.props.getEmployeeTypeById(record.id);
// 				this.setState({ showModal: true, isAdd: false });
// 			},
// 		};
// 	};

// 	onChangeDebounce = (e: { target: { value: string } }) => {
// 		this.onTableChange(1, 'ascend', e.target.value.toLowerCase());
// 	};

// 	async onTableChange(pageNumber: number, sorter: any, searchText: string) {
// 		const params = {
// 			pageNumber,
// 			pageSize: PAGE_SIZE,
// 			orderBy: _.isEmpty(sorter.order)
// 				? 'code'
// 				: sorter.order === 'ascend'
// 				? 'code'
// 				: 'code desc',
// 			searchText: searchText,
// 		};

// 		try {
// 			this.setState({ isLoading: true });
// 			const result = (await EmployeeTypeApiService.getAllEmployeeTypes(
// 				params
// 			)) as IApiResponse<IEmployeeType[]>;
// 			if (result.succeeded) {
// 				const pagination = {
// 					...this.state.pagination,
// 					total: result.totalRecords,
// 					current: pageNumber,
// 				};
// 				this.setState({ pagination, employeeTypes: result.data! });
// 			} else {
// 				showAlert('Error', '', 'error');
// 			}
// 			this.setState({ isLoading: false });
// 		} catch (error) {
// 			this.setState({ isLoading: false });
// 		}
// 	}

// 	onFormFinish = (values: any) => {
// 		const request: IEditEmployeeTypeRequest = {
// 			id: this.props.currentEmployeeType
// 				? this.props.currentEmployeeType.id
// 				: '',
// 			description: values.description,
// 			name: values.employeeType,
// 			roleId: values.roleId,
// 			status: values.status ? 1 : 0,
// 		};

// 		if (this.state.isAdd) {
// 			this.addEmployeeType(request);
// 		} else {
// 			this.editEmployeeType(request);
// 			this.props.reset();
// 		}

// 		this.setState({ showModal: false });
// 	};

// 	async addEmployeeType(request: IEditEmployeeTypeRequest) {
// 		try {
// 			this.setState({ isLoading: true });
// 			const result = (await EmployeeTypeApiService.addNewEmployeeType(
// 				request
// 			)) as IApiResponse<IEmployeeType>;
// 			this.setState({ isLoading: false });
// 			if (result.succeeded && !_.isEmpty(result.data)) {
// 				this.refreshTable();
// 				this.props.addEmployeeType(result.data!);
// 			} else {
// 				showAlert('Error', result.message[0].Text || 'error', 'error');
// 				// showError(result.errors);
// 			}
// 		} catch (error) {
// 			this.setState({ isLoading: false });
// 		}
// 	}

// 	async editEmployeeType(request: IEditEmployeeTypeRequest) {
// 		try {
// 			this.setState({ isLoading: true });
// 			const result = (await EmployeeTypeApiService.editEmployeeType(
// 				request
// 			)) as IApiResponse<IEmployeeType>;
// 			this.setState({ isLoading: false });
// 			if (result.succeeded) {
// 				this.refreshTable();
// 			} else {
// 				showAlert('Error', result.message[0].Text || 'error', 'error');
// 			}
// 		} catch (error) {
// 			this.setState({ isLoading: false });
// 		}
// 	}

// 	refreshTable() {
// 		const pagination = {
// 			...this.state.pagination,
// 			current: 1,
// 		};
// 		this.setState({ pagination, searchText: '' }, () =>
// 			this.onTableChange(1, 'ascend', '')
// 		);
// 	}

// 	handleCancelModal() {
// 		if (!this.state.isAdd) {
// 			this.props.reset();
// 		}

// 		this.setState({ showModal: false });
// 	}

// 	handleBtnAddNew() {
// 		this.setState({ showModal: true, isAdd: true });
// 	}
// }

const Header = (props: { onAddNew: () => void }) => {
	return (
		<Row gutter={16} style={{ alignItems: 'center' }}>
			<Col md={4}></Col>
			<Col md={20}>
				<Space
					style={{ justifyContent: 'flex-end', width: '100%' }}
					size="small"
				>
					<Button
						onClick={() => props.onAddNew()}
						icon={<PlusOutlined />}
						type="primary"
					>
						{I18n.t(_t(translations.addNew))}
					</Button>
				</Space>
			</Col>
		</Row>
	);
};

interface IFormBodyProps {
	currentEmployeeType?: IEmployeeType;
	roles?: IRole[];
	isAdd: boolean;
	showModal: boolean;
	show?: boolean;
	addEmployeeType: (data: any) => void;
	onSuccess: () => void;
	onCancel: () => void;
	reset: () => void;
}

const FormBody = (props: IFormBodyProps) => {
	const description = props.currentEmployeeType
		? props.currentEmployeeType.description
		: '';

	const employeeType = props.currentEmployeeType
		? props.currentEmployeeType.name
		: '';

	const roleId = props.currentEmployeeType
		? props.currentEmployeeType.roleId
		: props.roles && props.roles.length > 0
		? props.roles[0].id
		: '';

	const status = props.currentEmployeeType
		? props.currentEmployeeType.status === 0
			? false
			: true
		: true;
	const onFormFinish = async (values: any) => {
		const request: IEditEmployeeTypeRequest = {
			id: props.currentEmployeeType ? props.currentEmployeeType.id : '',
			description: values.description,
			name: values.employeeType,
			roleId: values.roleId,
			status: values.status ? 1 : 0,
		};
		if (props.isAdd) {
			addEmployeeType(request);
		} else {
			editEmployeeType(request);
			props.reset();
		}
	};

	const addEmployeeType = async (request: IEditEmployeeTypeRequest) => {
		try {
			const result = (await EmployeeTypeApiService.addNewEmployeeType(
				request
			)) as IApiResponse<IEmployeeType>;
			if (result.succeeded && !_.isEmpty(result.data)) {
				props.addEmployeeType(result.data!);
				props.onSuccess && props.onSuccess();
			} else {
				showAlert('Error', result.message[0].Text || 'error', 'error');
			}
		} catch (error) {}
	};

	const editEmployeeType = async (request: IEditEmployeeTypeRequest) => {
		try {
			const result = (await EmployeeTypeApiService.editEmployeeType(
				request
			)) as IApiResponse<IEmployeeType>;
			if (result.succeeded) {
				props.onSuccess && props.onSuccess();
			} else {
				showAlert('Error', result.message[0].Text || 'error', 'error');
			}
		} catch (error) {}
	};

	if (!_.isEmpty(props.currentEmployeeType) || props.isAdd) {
		return (
			<>
				<FormInModal
					visible={props.showModal}
					title={
						props.isAdd
							? I18n.t(_t(translations.employeeType.addNewEmployeeType))
							: I18n.t(_t(translations.employeeType.editEmployeeType))
					}
					onCancel={() => props.onCancel()}
					onOk={onFormFinish}
					// isAdd={this.state.isAdd}
					centered
				>
					{/* <Row gutter={[16, 0]}> */}
					<Col sm={24} md={24} style={styles.noPaddingCol}>
						{FormHelper.renderFormInput(
							'employeeType',
							[
								{
									required: true,
									message: I18n.t(
										_t(translations.employeeType.employeeTypeRequired)
									),
								},
							],
							I18n.t(_t(translations.employeeType.employeeType)),
							employeeType,
							{},
							{
								placeholder: I18n.t(
									_t(translations.employeeType.inputEmployeeType)
								),
								autoFocus: true,
							}
						)}
					</Col>
					{/* </Row> */}
					{/* <Row gutter={[16, 16]}> */}
					<Col sm={24} md={24} style={styles.noPaddingCol}>
						{FormHelper.renderFormInput(
							'description',
							[
								{
									required: false,
									message: I18n.t(
										_t(translations.employeeType.descriptionRequired)
									),
								},
							],
							I18n.t(_t(translations.employeeType.description)),
							description,
							{},
							{
								placeholder: I18n.t(
									_t(translations.employeeType.inputDescription)
								),
							}
						)}
					</Col>
					{/* </Row> */}
					{/* <Row gutter={[16, 16]}> */}
					<Col sm={24} md={24} style={styles.noPaddingCol}>
						{FormHelper.renderFormSelect<IRole>(
							'roleId',
							I18n.t(_t(translations.employeeType.rolePermission)),
							props.roles ? props.roles : [],
							'name',
							'id',
							roleId
						)}
					</Col>
					{/* </Row> */}
					{/* <Row gutter={[16, 16]}> */}
					<Col sm={24} md={24} style={styles.noPaddingCol}>
						{FormHelper.renderFormSwitch(
							'status',
							I18n.t(_t(translations.employeeType.status)),
							status
						)}
					</Col>
					{/* </Row> */}
				</FormInModal>
			</>
		);
	} else {
		return <></>;
	}
};

const ListEmployeeType = (props: IListEmployeeTypeProps) => {
	const [showModal, setShowModal] = useState(false);
	const [isAdd, setIsAdd] = useState(false);
	const tableRef = useRef<ITableRef>();

	const onOpenModal = (record?: IEmployeeType) => {
		if (record) {
			props.getEmployeeTypeById(record.id);
		}
		setShowModal(true);
		setIsAdd(false);
	};
	const handleBtnAddNew = () => {
		setIsAdd(true);
		setShowModal(true);
	};
	const handleCancelModal = () => {
		if (!isAdd) {
			props.reset();
		}
		setShowModal(false);
	};

	const columns = useMemo<IColumnProps<IEmployeeType>[]>(() => {
		return [
			{
				header: I18n.t(_t(translations.employeeType.employeeType)),
				key: 'name',
				type: 'text',
			},
			{
				header: I18n.t(_t(translations.employeeType.rolePermission)),
				key: 'roleName',
				type: 'text',
			},
			{
				header: I18n.t(_t(translations.employeeType.description)),
				key: 'description',
				type: 'text',
			},
		];
	}, []);
	return (
		<>
			<Space direction="vertical" style={styles.container} size="large">
				<Row>
					<Col style={{ width: '100%' }}>
						<AppTable<IEmployeeType>
							ref={(ref) => {
								if (ref) {
									tableRef.current = ref;
								}
							}}
							columns={columns}
							queryUrl={'/EmployeeType/get-all-employee-types'}
							showSearch
							renderHeader={<Header onAddNew={() => handleBtnAddNew()} />}
							additionalParams={{
								orderBy: 'code',
							}}
							onClickRow={(data) => onOpenModal(data)}
						/>
					</Col>
				</Row>
			</Space>
			{showModal && (
				<FormBody
					currentEmployeeType={props.currentEmployeeType}
					roles={props.roles}
					isAdd={isAdd}
					showModal={showModal}
					addEmployeeType={(data) => props.addEmployeeType(data)}
					onCancel={() => handleCancelModal()}
					onSuccess={() => {
						setShowModal(false);
						tableRef.current?.refresh();
					}}
					reset={() => props.reset()}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	roles: state.RoleReducer.roles,
	currentEmployeeType: state.EmployeeTypeReducer.currentEmployeeType,
	currentAction: state.ReduxActionReducer['BRANCH'],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	addEmployeeType: (employeeType: IEmployeeType) => {
		dispatch(EmployeeTypeActions.addNewEmployeeType.request(employeeType));
	},
	editEmployeeType: (request: IEditEmployeeTypeRequest) => {
		dispatch(EmployeeTypeActions.editEmployeeType.request(request));
	},
	getEmployeeTypeById: (id: string) => {
		dispatch(EmployeeTypeActions.getEmployeeTypeById.request(id));
	},
	reset: () => {
		dispatch(EmployeeTypeActions.reset.request());
	},
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ListEmployeeType)
);
