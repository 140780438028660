import React, { Component } from 'react';
import { AlertHelper, FormHelper } from 'helpers';
import { I18n, translations, _t } from 'utils';
import { IApiResponse, IEmployee, IErrorResponse, IHolidayModel } from 'models';
import { FormInModal, Modal as AppModal, Spin } from 'components';
import styles from './styles';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { HolidayApiService } from 'services';

interface IHolidayDetailModalProps
	extends ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {
	isShopHoliday: boolean;
	onClose(): void;
	onSubmit(data: Holiday, currentHoliday?: IHolidayModel): void;
	id?: string;
	onDelete?(id: string): void;
	isLoading?: boolean;
}

interface IHolidayDetailState {
	chosedDate: Array<Moment>;
	showDeleteConfirm: boolean;
	holiday?: IHolidayModel;
	isLoading?: boolean;
}

interface INiceEmployee extends IEmployee {
	fullName: string;
}

class HolidayDetailModal extends Component<
	IHolidayDetailModalProps,
	IHolidayDetailState
> {
	constructor(props: IHolidayDetailModalProps) {
		super(props);
		this.state = {
			chosedDate: [moment(), moment()],
			showDeleteConfirm: false,
		};
	}

	// formRef = React.createRef<FormInstance>();

	componentDidMount() {
		if (this.props.id) {
			this.getHolidayById(this.props.id!);
		}
	}

	render() {
		return (
			<FormInModal
				visible={true}
				onCancel={this.props.onClose}
				onOk={(info) => this.props.onSubmit(info, this.state.holiday)}
				title={this.getTitle()}
			>
				{this.state.isLoading ? this.renderLoading() : this.renderContainer()}
			</FormInModal>
		);
	}

	renderContainer() {
		return !_.isEmpty(this.props.staffList) ? (
			// <Form.Provider
			// 	onFormFinish={(_: string, info) => {
			// 		console.log('aaaaaaaaaaa',info.values);
			// 		this.props.onSubmit(info.values as Holiday, this.state.holiday);
			// 	}}
			// >
			// 	<Form
			// 		layout="vertical"
			// 		name="add-blocked-time-form"
			// 		// onFinish={(data) => this.props.onSubmit(data, this.state.holiday)}
			// 		// ref={this.formRef}
			// 	>
			<>
				{this.renderDatePicker()}
				{this.renderStaffPicker()}
				{this.renderTimePicker()}
				{this.renderDescription()}
				{/* {this.renderButtons()} */}
				<AppModal
					visible={this.state.showDeleteConfirm}
					title={I18n.t(_t(translations.delete))}
					// description={I18n.t(_t(translations.text.areYouSure))}
					handleOk={() => {
						if (this.props.onDelete) {
							this.setState({ showDeleteConfirm: false });
							this.props.onDelete!(this.props.id!);
						}
					}}
					handleCancel={() => this.setState({ showDeleteConfirm: false })}
					style={{ top: 200 }}
				/>
			</>
		) : // 	</Form>
		// </Form.Provider>
		null;
	}

	renderLoading() {
		return <Spin />;
	}

	renderDatePicker() {
		return this.props.isShopHoliday
			? FormHelper.renderFormDateRangePicker(
					'date',
					I18n.t(_t(translations.staffDetail.dates)),
					this.getDefaultDate(),
					[
						{
							required: true,
							message: I18n.t(_t(translations.staffDetail.closedDateMessage)),
						},
					],
					styles.addHolidayDatePicker,
					{},
					{
						allowClear: false,
						inputReadOnly: true,
						disabledDate: (currentDate: Moment) =>
							currentDate < moment().endOf('date'),
					}
			  )
			: FormHelper.renderFormDateTimePicker(
					'date',
					I18n.t(_t(translations.addHoliday.date)),
					this.getDefaultDate()[0],
					[
						{
							required: true,
							message: I18n.t(_t(translations.addHoliday.dateMessage)),
						},
					],
					styles.addHolidayDatePicker,
					{},
					{
						onChange: (date) =>
							this.setState((prevState) => {
								const newChoseDate = [date] as Moment[];
								return { ...prevState, chosedDate: newChoseDate };
							}),
						allowClear: false,
						inputReadOnly: true,
						disabledDate: (currentDate: Moment) =>
							currentDate < moment().startOf('date'),
					}
			  );
	}

	renderStaffPicker() {
		return this.props.isShopHoliday
			? null
			: FormHelper.renderFormSelect<INiceEmployee>(
					'staff',
					I18n.t(_t(translations.addHoliday.staff)),
					this.niceStaffList,
					'fullName',
					'id',
					this.getDefaultStaff(),
					{},
					{ loading: this.props.isLoading }
			  );
	}

	renderTimePicker() {
		return this.props.isShopHoliday
			? null
			: FormHelper.renderFormTimeRangePicker(
					'time',
					I18n.t(_t(translations.addHoliday.time)),
					undefined,
					[
						{
							required: true,
							message: I18n.t(_t(translations.addHoliday.timeMessage)),
						},
					],
					{},
					{
						picker: 'time',
						disabledHours: () => this.getDisableHours(),
						disabledMinutes: (selectedHour: number) =>
							this.getDisableMinutes(selectedHour),
						allowClear: true,
						format: 'h:mm A',
					}
			  );
	}

	renderDescription() {
		return FormHelper.renderFormTextArea(
			'description',
			[
				// {
				// 	required: true,
				// 	message: I18n.t(_t(translations.addHoliday.descriptionMessage)),
				// },
			],
			I18n.t(_t(translations.addHoliday.description)),
			I18n.t(_t(translations.addHoliday.description)),
			!this.isAdd() ? this.state.holiday?.description : ''
		);
	}

	// renderButtons() {
	// 	return (
	// 		<Row gutter={16}>
	// 			{!_.isEmpty(this.props.id) ? (
	// 				<Col span={12}>
	// 					<Button
	// 						type="primary"
	// 						danger
	// 						loading={this.props.isLoading}
	// 						onClick={() => this.setState({ showDeleteConfirm: true })}
	// 					>
	// 						{I18n.t(_t(translations.delete))}
	// 					</Button>
	// 				</Col>
	// 			) : null}
	// 			<Col span={_.isEmpty(this.props.id) ? 24 : 12}>
	// 				<Button
	// 					type="primary"
	// 					htmlType="submit"
	// 					loading={this.props.isLoading}
	// 				>
	// 					{I18n.t(_t(translations.save))}
	// 				</Button>
	// 			</Col>
	// 		</Row>
	// 	);
	// }

	niceStaffList: INiceEmployee[] = !_.isEmpty(this.props.staffList)
		? this.props.staffList!.map((element) => ({
				...element,
				fullName: `${element.firstName} ${element.lastName}`,
		  }))
		: [];

	getTitle() {
		if (this.props.isShopHoliday) {
			if (this.isAdd()) {
				return I18n.t(_t(translations.staffDetail.newClosedDate));
			} else {
				return I18n.t(_t(translations.staffDetail.editClosedDate));
			}
		} else {
			if (this.isAdd()) {
				return I18n.t(_t(translations.staffDetail.newClosedDate));
			} else {
				return I18n.t(_t(translations.staffDetail.editClosedDate));
			}
		}
	}

	getDefaultDate() {
		if (this.props.isShopHoliday) {
			if (!this.isAdd()) {
				return [
					moment(this.state.holiday?.fromDate),
					moment(this.state.holiday?.toDate),
				];
			} else {
				return [moment().add(1, 'day'), moment().add(1, 'day')];
			}
		} else {
			if (!this.isAdd()) {
				return [moment(this.state.holiday?.fromDate)];
			} else {
				return [moment()];
			}
		}
	}

	getDefaultStaff() {
		if (!this.isAdd()) {
			return this.state.holiday?.employeeId;
		} else {
			return !_.isEmpty(this.niceStaffList) ? this.niceStaffList[0].id : '';
		}
	}

	getDisableHours(): number[] {
		const today = moment();
		const disableHours: number[] = [];
		for (let i = 0; i < 24; ++i) {
			if (
				i < moment().hour() &&
				this.state.chosedDate[0].date() === today.date()
			) {
				disableHours.push(i);
			}
		}
		return disableHours;
	}

	getDisableMinutes(selectedHour: number): number[] {
		const today = moment();
		const minutesBlocks = [0, 15, 30, 45];
		const disableMinutes = [];
		for (let i of minutesBlocks) {
			if (
				i < moment().minute() &&
				selectedHour <= moment().hour() &&
				this.state.chosedDate[0].date() === today.date()
			) {
				disableMinutes.push(i);
			}
		}
		return disableMinutes;
	}

	getHolidayById = async (id: string) => {
		this.setState({ isLoading: true });
		try {
			const result = (await HolidayApiService.getHolidayById(
				id
			)) as IApiResponse<IHolidayModel>;
			if (result.succeeded) {
				this.setState({ isLoading: false, holiday: result.data! });
			} else {
				const error = result as IErrorResponse;
				this.setState({ isLoading: false });
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.error(error);
		}
	};

	// emptyCheck() {
	// 	if (!_.isEmpty(this.props.id) && !_.isEmpty(this.props.selectedHoliday)) {
	// 		this.props.getHolidayById(this.props.id!);
	// 	}
	// }
	isAdd = (): boolean => (this.props.id ? false : true);
}

export type Holiday = {
	date: Moment[] | Moment;
	description: string;
	staff: string;
	time: Moment[];
};

const mapStateToProps = (state: RootState) => ({
	staffList: state.EmployeeReducer.employees,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	// getHolidayById: (id: string) => {
	// 	dispatch(HolidayActions.getHolidayById.request(id));
	// },
});

export default connect(mapStateToProps, mapDispatchToProps)(HolidayDetailModal);
