import _ from 'lodash';
import { IBooking, IBookingDetail } from 'models';
import moment from 'moment';
import { history } from 'routers';
import { BookingStatus } from 'utils/Consts';
import QRCode from 'qrcode';
import { toTimeZone } from './timeHelper';

export const calculateBookingDetailAmount = (
	bookingDetail: Partial<IBookingDetail>
) => {
	const price = bookingDetail.item?.price!;
	const discount = bookingDetail.discount!;
	const promotionDiscount = bookingDetail.promotionDiscount!;
	return _.toNumber(price - discount - promotionDiscount);
};
export const calculateBookingDetailTax = (
	bookingDetail: Partial<IBookingDetail>,
	taxPercent: number = 0
) => {
	const amount =
		(bookingDetail.item?.price || 0) + (bookingDetail.extraAmount || 0);
	const tax = (amount * taxPercent) / 100;
	return _.toNumber(tax);
};

export const getInitialWorkingTime = (time: string) => {
	const splited = time.split('T');
	const spitedTime = splited[1];
	const date = splited[0];

	const niceTime = toTimeZone(`2021-01-01T${spitedTime}`);
	if (date.includes('2')) {
		niceTime.add(1, 'day');
	}
	return niceTime;
};

export const convertDateRequest = (localDate: moment.Moment) => {
	return localDate.format('YYYY-MM-DDT00:00:00');
};

export const convertTimeRequest = (
	localDate: moment.Moment,
	nextDay = false
) => {
	return localDate
		.clone()
		.utc()
		.format(`0001-01-${nextDay ? '02' : '01'}THH:mm:00`);
};

export const openBooking = (booking: IBooking) => {
	// 	if (
	// 		// booking.status === BookingStatus.PROCESSING ||
	// 		// booking.status === BookingStatus.FINISHED
	// ) {
	// 		// history.push(`/checkout?bookingId=${booking.id}`);
	// 		history.push({
	// 			pathname: `/checkout`,
	// 			search: `bookingId=${booking.id}`,
	// 			state: { status: booking.status },
	// 		});
	// 	} else
	if (booking.status === BookingStatus.DONE) {
		history.push({ pathname: '/billing', search: `bookingId=${booking.id}` });
	} else {
		history.push(`/booking/${booking.id}`);
	}
};

export const createQrCodeBookingId = async (
	bookingId: string
): Promise<string> => {
	const bookingQRcode = {
		bookingId,
	};
	const qrCode = await QRCode.toDataURL(JSON.stringify(bookingQRcode), {});
	return qrCode;
};

export const isDateRange = (
	firstDate: Date | string,
	secondDate: Date | string
) => {
	return !moment(firstDate).isSame(moment(secondDate));
};
export const generateQrCode = (bookingId: string) => {
	const data = {
		booking: {
			id: bookingId,
		},
	};
	return JSON.stringify(data);
};

export const convertBookingDetails = (
	bookingDetails: Partial<IBookingDetail>[],
	startDateTime: moment.Moment
): Partial<IBookingDetail>[] => {
	return bookingDetails.reduce(
		(newBookingDetails: Partial<IBookingDetail>[], bookingDetail, index) => {
			if (!_.isEmpty(bookingDetail.itemId)) {
				const lastBookingDetail = _.last(newBookingDetails);
				const startAtExpected =
					index === 0
						? startDateTime
						: moment(lastBookingDetail!.endAtExpected);
				const endAtExpected = moment(startAtExpected).add(
					bookingDetail.item?.duration,
					'minutes'
				);
				const bookingDetailRequest: Partial<IBookingDetail> = {
					...bookingDetail!,
					stylistId:
						_.isEmpty(bookingDetail.stylistId) ||
						bookingDetail.stylistId === 'Anyone'
							? undefined
							: bookingDetail.stylistId,
					stylist: undefined,
					itemId: bookingDetail.itemId,
					item: undefined,
					startAtExpected: startAtExpected.toDate(),
					endAtExpected: endAtExpected.toDate(),
					added: false,
					status: 1,
					price: bookingDetail.item!.price,
					quantity: 1,
					amount: bookingDetail.item!.price,
				};
				newBookingDetails.push(bookingDetailRequest);
			}
			return newBookingDetails;
		},
		[]
	);
};
