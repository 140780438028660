import React, { Component } from 'react';
import { Col, Layout, Row, Avatar, Button, Dropdown, Typography } from 'antd';
import { UserOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { Dispatch } from 'redux';
import styles from './styles';
import { AppConfigActions, BranchActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import { connect, useSelector } from 'react-redux';
import ProfileMenu from './ProfileMenu';
import { RouteComponentProps } from 'react-router-dom';
import { useHistory, withRouter } from 'react-router';
import _ from 'lodash';
import { IBranch } from 'models/IBranch';
import BranchSelect from 'components/BranchSelect';
import { Clock } from 'components';
import { IUser } from 'models';
import { translations } from './../../utils/locales/translations';
import { I18n, _t } from 'utils';
const { Header } = Layout;

interface INailHeaderProps
	extends ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps>,
		RouteComponentProps {
	isAdminPage?: boolean;
	detailPageProps?: {
		title?: string;
		onClosePress?(): void;
		hideCloseButton?: boolean;
	};
	bookingPageProps?: {
		shopName?: string;
		title?: string;
		isCustomer?: boolean;
		onClosePress?(): void;
		hideCloseButton?: boolean;
	};
	isBookingPage?: boolean;
}

class NailHeader extends Component<INailHeaderProps> {
	_handelContent = () => {
		if (this.props.isAdminPage) {
			return <MainContent />;
		} else if (this.props.isBookingPage) {
			return <BookingContent bookingPageProps={this.props.bookingPageProps} />;
		} else {
			return <DetailContent detailPageProps={this.props.detailPageProps} />;
		}
	};

	render() {
		return <Header style={styles.header}>{this._handelContent()}</Header>;
	}
}

const MainContent = () => {
	const user = useSelector<RootState>(
		(state) => state.UserReducer.user
	) as IUser;
	const isAdmin = _.includes(user?.userRoles, 'Admin');
	const history = useHistory();

	const renderLeftContent = () => {
		return (
			<Col lg={8} xs={0}>
				<BranchSelect lg={18} disabled={!isAdmin} />
			</Col>
		);
	};
	const renterCenterContent = () => {
		return (
			<Col lg={8} xs={0}>
				{/* Something on the center*/}
			</Col>
		);
	};
	const renderRightContent = () => {
		return (
			<Col lg={8} xs={0}>
				<Row align="middle" style={{ marginRight: 16 }}>
					<Clock />
					<Button
						onClick={() => history.push('/search')}
						icon={<SearchOutlined />}
						size="middle"
						shape="circle"
						style={{ marginLeft: 10, marginRight: 10 }}
					/>
					<Dropdown overlay={<ProfileMenu />} trigger={['click']}>
						<Button style={styles.avatarBtn} size="middle" shape="circle">
							<Avatar size="default" icon={<UserOutlined />} />
						</Button>
					</Dropdown>
				</Row>
			</Col>
		);
	};
	return (
		<Row align="middle">
			{renderLeftContent()}
			{renterCenterContent()}
			{renderRightContent()}
		</Row>
	);
};

const BookingContent = (props?: {
	bookingPageProps?: {
		title?: string;
		isCustomer?: boolean;
		onClosePress?(): void;
		hideCloseButton?: boolean;
	};
}) => {
	const renderLeftContent = () => {
		return (
			<Col lg={10}>
				<Row align={'middle'} justify={'center'}>
					<Col xs={10} md={4} lg={4} offset={4}>
						<Typography.Title style={{ color: 'white' }} level={4}>
							{props?.bookingPageProps?.title}
						</Typography.Title>
					</Col>
					<Col lg={12}>
						{props?.bookingPageProps?.isCustomer ? (
							<BranchSelect lg={24} />
						) : null}
					</Col>
				</Row>
			</Col>
		);
	};
	const renderRightContent = () => {
		return !props?.bookingPageProps?.isCustomer ? (
			<Col lg={14} style={{ padding: 10 }}>
				{!props?.bookingPageProps?.hideCloseButton && (
					<CloseButton onPress={props?.bookingPageProps?.onClosePress} />
				)}
			</Col>
		) : null;
	};
	return (
		<Row align="middle">
			{renderLeftContent()}
			{renderRightContent()}
		</Row>
	);
};
const DetailContent = (props?: {
	detailPageProps?: {
		title?: string;
		onClosePress?(): void;
		hideCloseButton?: boolean;
	};
}) => {
	const renderLeftContent = () => {
		return <Col lg={8}> {/* Something on the left*/}</Col>;
	};
	const renderCenterContent = () => {
		return (
			<Col lg={8} xs={16} md={16}>
				<Typography.Title style={styles.headerTitle} level={2}>
					{props?.detailPageProps?.title}
				</Typography.Title>
			</Col>
		);
	};
	const renderRightContent = () => {
		return (
			<Col lg={8}>
				{!props?.detailPageProps?.hideCloseButton && (
					<CloseButton onPress={props?.detailPageProps?.onClosePress} />
				)}
			</Col>
		);
	};
	return (
		<Row align="middle" style={{ padding: 10 }}>
			{renderLeftContent()}
			{renderCenterContent()}
			{renderRightContent()}
		</Row>
	);
};

const CloseButton = (props: { onPress?(): void }) => {
	return (
		<Row align={'middle'} style={{ marginRight: 80 }}>
			<Clock />
			<Button
				danger
				icon={<CloseOutlined />}
				size={'middle'}
				onClick={props?.onPress}
				style={styles.icClose}
			>
				{I18n.t(_t(translations.close))}
			</Button>
		</Row>
	);
};

const mapStateToProps = (state: RootState) => ({
	currentLanguage: state.AppConfigReducer.language,
	branchAction: state.ReduxActionReducer['BRANCH'],
	branches: state.BranchReducer.branches,
	currentBranch: state.BranchReducer.currentBranch,
	user: state.UserReducer.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setShowNotification: (visible: boolean) =>
		dispatch(AppConfigActions.showNotification.request(visible)),
	setShowLanguage: (visible: boolean) =>
		dispatch(AppConfigActions.showLanguage.request(visible)),
	selectBranch: (branch: IBranch) =>
		dispatch(BranchActions.selectBranch.request(branch)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(NailHeader)
);
