import { ReduxHelper } from 'helpers';
import { IBranch } from 'models/IBranch';

export const prefix = 'BRANCH';

const getBranchesByShopId = ReduxHelper.generateActions<string, IBranch[]>(
	`${prefix}/GET_BRANCHES_BY_SHOP_ID`
);
const selectBranch = ReduxHelper.generateActions<Partial<IBranch>>(
	`${prefix}/SELECT_BRANCH`
);
const updateBranch = ReduxHelper.generateActions<Partial<IBranch>, IBranch>(
	`${prefix}/UPDATE_BRANCH`
);
const getBranchById = ReduxHelper.generateActions<string, IBranch>(
	`${prefix}/GET_BRANCH_BY_ID`
);
const setBranchId = ReduxHelper.generateLocalAction<string>(
	`${prefix}/SET_BRANCH_ID`
);
// const setPaymentReferenceCodeRequiring =
// 	ReduxHelper.generateLocalAction<boolean>(
// 		`${prefix}/SET_PAYMENT_REFERENCE_CODE_REQUIRING`
// 	);

export {
	getBranchesByShopId,
	selectBranch,
	updateBranch,
	getBranchById,
	setBranchId,
	// setPaymentReferenceCodeRequiring,
};
