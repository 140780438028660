import GlobalStyles, { kStyles } from 'GlobalStyles';

const styles: kStyles = {
	container: {
		display: 'flex',
		width: '100%',
	},
	spinLayout: {
		height: '100vh',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	spin: { margin: '0 auto' },
	loading: {
		margin: '0 auto',
	},
	buttonContainer: {
		textAlign: 'right',
		marginBottom: 10,
	},
	maxWidth: {
		minWidth: '100%',
	},
	divider: {
		margin: '16px 0',
	},
};

export default styles;
