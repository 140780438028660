import React from "react";
import { Layout, Row, Col, Typography, Image } from "antd";
import { I18n, _t, translations } from "utils";
import styles from "./styles";
import { Link } from "react-router-dom";
const { Header } = Layout;
const { Text } = Typography;

const AuthHeader = () => (
  <Header style={styles.nailHeader}>
    <Row>
      <Col lg={1} xs={1} />
      <Col lg={22} xs={22}>
        <Link to="/">
          <Row justify="center" style={styles.row}>
            <Image style={styles.nailHeaderLogo} src="./logo-square.png" />
          </Row>
        </Link>
        <Row>
          <Text style={styles.nailHeaderSlogan} strong>
            {I18n.t(_t(translations.login.slogan))}
          </Text>
        </Row>
      </Col>
      <Col lg={1} xs={1} />
    </Row>
  </Header>
);

export default AuthHeader;
