import {
	DeleteOutlined,
	EditOutlined,
	SearchOutlined,
	UploadOutlined,
} from '@ant-design/icons';
import {
	Button,
	Card,
	Checkbox,
	Col,
	Collapse,
	Divider,
	Form,
	Input,
	List,
	Modal,
	Row,
	Space,
	Table,
	Typography,
	Upload,
} from 'antd';
import { DetailPageLayout_V2 } from 'components';
import { AlertHelper, FormHelper, TimeHelper } from 'helpers';
import ModalHeper from 'helpers/modalHelper';
import { getBase64Image } from 'helpers/StringHelper';
import { IErrorResponse } from 'models';
import Image from 'rc-image';
import React, { useEffect, useState } from 'react';
import { history } from 'routers';
import { CategoryApiService } from 'services';
import GalleryApiService from 'services/GalleryApiService';
import ServiceApi from 'services/ServiceApi';
import UploadBaseService from 'services/UploadBaseService';
import { I18n, translations, _t } from 'utils';
import { ThemeType } from 'utils/Consts';
import './style.css';
import styles from './styles';

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Panel } = Collapse;

interface Image {
	id: string;
	imageUrl: string;
}
const debounceTime = 1000;
function MiniCMSSetting() {
	const [form] = Form.useForm();
	const [reloadPage, setReloadPage] = useState(false);
	const [formVisible, setFormVisible] = useState<any>({
		mdlFormVisibleSlider: false,
		mdlFormVisibleAboutUs: false,
		mdlFormVisibleWelcomeText: false,
		mdlFormVisibleWhyChoose: false,
		mdlFormVisibleServices: false,
		edfWrapper: {},
		id: '',
	});
	const [isLoading, setIsLoading] = useState(false);
	const [dataThemes, setDataThemes] = useState<any[]>([]);
	const [services, setServices] = useState<any>([]);
	const [categories, setCategories] = useState([]);
	const [reloadService, setReloadService] = useState(false);
	const [selectedServices, setSelectedServices] = useState<any>([]);
	const [preview, setPreview] = useState({
		previewVisible: false,
		previewImage: '',
		previewTitle: '',
	});

	useEffect(() => {
		(async () => {
			await getThemeShop();
		})();
	}, [reloadPage]);

	const getThemeShop = async () => {
		setIsLoading(true);
		await GalleryApiService.getThemeShop()
			.then((res: any) => {
				const { data } = res;
				setDataThemes(
					data.sort(
						(n1: { priority: number }, n2: { priority: number }) =>
							n1.priority - n2.priority
					)
				);
			})
			.catch((err) => {
				console.log(err);
			});
		setIsLoading(false);
	};

	const getAllCategory = async () => {
		await CategoryApiService.getAllCategory()
			.then((res: any) => {
				setCategories(res.data);
			})
			.catch((err: any) => {
				console.log(err);
			});
	};

	const getAllServices = async () => {
		await ServiceApi.getAllService()
			.then((res: any) => {
				setServices(res.data);
			})
			.catch((err: any) => {
				console.log(err);
			});
	};

	useEffect(() => {
		(async () => {
			await getAllCategory();
			await getAllServices();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			await getThemeShopService();
		})();
	}, [reloadService]);

	const getThemeShopService = async () => {
		await GalleryApiService.getThemeShopService()
			.then((res: any) => {
				const { data } = res;
				let dataSelectedServices: any = [];
				data.map((service: any) => {
					dataSelectedServices.push(service.item);
				});
				setSelectedServices(dataSelectedServices);
			})
			.catch((err: any) => {
				console.log(err);
			});
	};

	const handleOpenByType = (type: any, record: any) => {
		switch (type) {
			case ThemeType.SLIDER:
				let pictures: any = [];
				const dataImage: any = JSON.parse(record.url) as Image;
				dataImage?.map((x: any) =>
					pictures.push({ id: x.id, thumbUrl: x.imageUrl, checked: true })
				);
				setFormVisible({
					mdlFormVisibleSlider: true,
					edfWrapper: { pictures: pictures },
					id: record.id,
				});
				break;
			case ThemeType.ABOUT_US:
				form.setFieldsValue({
					title: record.title,
					subTitle: record.subTitle,
					description: record.description,
				});
				setFormVisible({ mdlFormVisibleAboutUs: true, id: record.id });
				break;
			case ThemeType.WELCOME_TEXT:
				form.setFieldsValue({
					title: record.title,
					subTitle: record.subTitle,
				});
				setFormVisible({ mdlFormVisibleWelcomeText: true, id: record.id });
				break;
			case ThemeType.WHY_CHOOSE:
				form.setFieldsValue({
					title: record.title,
					subTitle: record.subTitle,
					description: record.description,
				});
				setFormVisible({ mdlFormVisibleWhyChoose: true, id: record.id });
				break;
			case ThemeType.GALLERY:
				history.push({
					pathname: `/setting/gallery-setup`,
				});
				break;
			case ThemeType.SERVICES:
				setServicesChecked();
				const filteredArr = searchTextServices('');
				setFormVisible({
					mdlFormVisibleServices: true,
					edfWrapper: { filteredArr: filteredArr || [] },
				});
				break;
		}
	};

	const setServicesChecked = () => {
		services.map((service: any) => {
			let index: number = selectedServices.findIndex(
				(a: any) => a.id == service.id
			);
			if (index != -1) {
				service.checked = true;
			} else {
				service.checked = false;
			}
		});
		setServices(services);
	};

	const columns = [
		{
			title: I18n.t(_t(translations.miniCMSSetting.columnType)),
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: I18n.t(_t(translations.action)),
			dataIndex: 'type',
			align: 'right' as const,
			render: (type: any, record: any) => (
				<Button
					type="primary"
					icon={<EditOutlined />}
					onClick={() => handleOpenByType(type, record)}
				/>
			),
		},
	];

	const goBack = () => {
		window.history.back();
	};

	const sliderUploadAndRemoveImage = async (type: any) => {
		let url = null;
		let error: any = null;
		if (type == ThemeType.SLIDER) {
			const dataImageNeedUpload = formVisible.edfWrapper.pictures.filter(
				(x: any) => !x.id && x.checked == true
			);
			const dataPost = formVisible.edfWrapper.pictures.filter(
				(x: any) => x.id && x.checked == true
			);
			if (dataImageNeedUpload.length > 0) {
				const files = dataImageNeedUpload.map((x: any) => x.file);
				await UploadBaseService.uploadImage(files)
					.then((res: any) => {
						if (res.succeeded) {
							const { data } = res;
							data.map((x: any) => {
								dataPost.push({
									id: x.id,
									thumbUrl: x.imageUrl,
								});
							});
						} else {
							error = res as IErrorResponse;
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
			const jsonStringUrl = dataPost.map((x: any) => ({
				id: x.id,
				imageUrl: x.thumbUrl,
			}));
			url = jsonStringUrl.length > 0 ? JSON.stringify(jsonStringUrl) : null;
		}
		return { url, error };
	};

	// 1. Slider setup
	const handleOk = async (type: any, number: any) => {
		const { url, error } = await sliderUploadAndRemoveImage(type);
		if (error) {
			AlertHelper.showError(error);
			return;
		}
		form
			.validateFields()
			.then(async (values) => {
				const params: any = {
					id: formVisible.id,
					type,
					title: values.title || null,
					subTitle: values.subTitle || null,
					description: values.description || null,
					url: url || null,
					priority: number,
				};
				await GalleryApiService.updateThemeShopContent(params)
					.then((res: any) => {
						if (res.succeeded) {
							const { data } = res;
							AlertHelper.showSuccess(
								`${
									data[0].type +
									' ' +
									I18n.t(_t(translations.miniCMSSetting.updateThemeSuccess))
								}`
							);
							setFormVisible({
								mdlFormVisibleSlider: false,
								mdlFormVisibleAboutUs: false,
								mdlFormVisibleWelcomeText: false,
								mdlFormVisibleWhyChoose: false,
								edfWrapper: {},
							});
							form.resetFields();
							setTimeout(() => setReloadPage(!reloadPage), debounceTime);
						} else {
							const error = res as IErrorResponse;
							AlertHelper.showError(error);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			})
			.catch((info) => {
				console.log(info);
			});
	};

	const handleCancelSlider = () => {
		form.resetFields();
		setFormVisible({ mdlFormVisibleSlider: false, edfWrapper: {} });
	};

	const uploadImage = (options: any) => {
		const { file } = options;
		getBase64Image(file, (imageUrl) => {
			formVisible.edfWrapper?.pictures.push({
				thumbUrl: imageUrl,
				checked: true,
				file,
			});
			setFormVisible({
				...formVisible,
				edfWrapper: formVisible.edfWrapper,
			});
		});
	};

	const handleRemoveImage = (thumbUrl: any) => {
		let index: number = formVisible.edfWrapper?.pictures.findIndex(
			(a: any) => a.thumbUrl == thumbUrl
		);
		if (index != -1) {
			formVisible.edfWrapper?.pictures.splice(index, 1);
		}
		setFormVisible({
			...formVisible,
			edfWrapper: formVisible.edfWrapper,
		});
	};

	const handlePreview = (image: any) => {
		setPreview({
			previewVisible: true,
			previewImage: image,
			previewTitle: image.substring(image.lastIndexOf('/') + 1),
		});
	};

	const renderSliderSetup = () => {
		return (
			<ModalHeper
				title={I18n.t(_t(translations.miniCMSSetting.headerSlider))}
				visible={formVisible.mdlFormVisibleSlider}
				onCancel={handleCancelSlider}
				onOk={() => handleOk(ThemeType.SLIDER, 1)}
			>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Text strong>
							{I18n.t(_t(translations.miniCMSSetting.titleSlider))}
						</Text>
					</Col>
					<Col span={8}>
						<Upload
							multiple
							name="avatar"
							className="avatar-uploader upload-custom"
							customRequest={uploadImage}
						>
							<Button icon={<UploadOutlined />}>
								{I18n.t(_t(translations.upload))}
							</Button>
						</Upload>
					</Col>
					<Col span={16}>
						<Text strong>
							{I18n.t(_t(translations.miniCMSSetting.bestViewImageSlider))}
						</Text>
						<br />
						<Text strong>
							{I18n.t(_t(translations.miniCMSSetting.allowImage))}
						</Text>
					</Col>
					{formVisible.edfWrapper?.pictures?.map((x: any) => (
						<Col span={8}>
							<Row>
								<Col span={24}>
									<img
										alt="example"
										style={{ width: '100%' }}
										src={x.thumbUrl}
										onClick={() => handlePreview(x.thumbUrl)}
									/>
									<DeleteOutlined
										className="close-icon"
										onClick={() => handleRemoveImage(x.thumbUrl)}
									/>
								</Col>
							</Row>
						</Col>
					))}
					<Modal
						visible={preview.previewVisible}
						title={preview.previewTitle}
						onCancel={() => setPreview({ ...preview, previewVisible: false })}
						footer={null}
					>
						<img
							alt="example"
							style={{ width: '100%' }}
							src={preview.previewImage}
						/>
					</Modal>
				</Row>
			</ModalHeper>
		);
	};

	// 2. About us information
	const handleCancelAboutUs = () => {
		form.resetFields();
		setFormVisible({ mdlFormVisibleAboutUs: false });
	};

	const renderAboutUs = () => {
		return (
			<ModalHeper
				title={I18n.t(_t(translations.miniCMSSetting.headerAboutUs))}
				visible={formVisible.mdlFormVisibleAboutUs}
				onCancel={handleCancelAboutUs}
				onOk={() => handleOk(ThemeType.ABOUT_US, 2)}
			>
				<Form form={form} layout="vertical">
					{FormHelper.renderFormInput(
						'title',
						[
							{
								required: true,
								message: I18n.t(
									_t(translations.miniCMSSetting.messageTitleRequired)
								),
							},
						],
						I18n.t(_t(translations.miniCMSSetting.titleAboutUs)),
						'',
						{},
						{
							placeholder: I18n.t(
								_t(translations.miniCMSSetting.titleAboutUsPlaceholder)
							),
							maxLength: 256,
						}
					)}
					{FormHelper.renderFormTextArea(
						'subTitle',
						[],
						I18n.t(_t(translations.miniCMSSetting.subTitleAboutUs)),
						I18n.t(_t(translations.miniCMSSetting.subTitleAboutUsPlaceholder)),
						''
					)}
					{FormHelper.renderFormTextArea(
						'description',
						[],
						I18n.t(_t(translations.miniCMSSetting.descriptionAboutUs)),
						I18n.t(
							_t(translations.miniCMSSetting.descriptionAboutUsPlacehoder)
						),
						''
					)}
				</Form>
			</ModalHeper>
		);
	};

	// 4. Welcome information
	const handleCancelWelcomeText = () => {
		form.resetFields();
		setFormVisible({ mdlFormVisibleWelcomeText: false });
	};

	const renderWelcomeText = () => {
		return (
			<ModalHeper
				title={I18n.t(_t(translations.miniCMSSetting.headerWelcomeText))}
				visible={formVisible.mdlFormVisibleWelcomeText}
				onCancel={handleCancelWelcomeText}
				onOk={() => handleOk(ThemeType.WELCOME_TEXT, 3)}
			>
				<Form form={form} layout="vertical">
					{FormHelper.renderFormInput(
						'title',
						[
							{
								required: true,
								message: I18n.t(
									_t(translations.miniCMSSetting.messageTitleRequired)
								),
							},
						],
						I18n.t(_t(translations.miniCMSSetting.titleWelcomeText)),
						'',
						{},
						{
							placeholder: I18n.t(
								_t(translations.miniCMSSetting.titleWelcomeTextPlaceholder)
							),
							maxLength: 256,
						}
					)}
					{FormHelper.renderFormTextArea(
						'subTitle',
						[],
						I18n.t(_t(translations.miniCMSSetting.subTitleWelcomeText)),
						I18n.t(
							_t(translations.miniCMSSetting.subTitleWelcomeTextPlaceholder)
						),
						''
					)}
				</Form>
			</ModalHeper>
		);
	};

	// 5. Why client choose us information
	const handleCancelWhyChoose = () => {
		form.resetFields();
		setFormVisible({ mdlFormVisibleWhyChoose: false });
	};

	const renderWhyChoose = () => {
		return (
			<ModalHeper
				title={I18n.t(_t(translations.miniCMSSetting.headerWhyChoose))}
				visible={formVisible.mdlFormVisibleWhyChoose}
				onCancel={handleCancelWhyChoose}
				onOk={() => handleOk(ThemeType.WHY_CHOOSE, 4)}
			>
				<Form form={form} layout="vertical">
					{FormHelper.renderFormInput(
						'title',
						[
							{
								required: true,
								message: I18n.t(
									_t(translations.miniCMSSetting.messageTitleRequired)
								),
							},
						],
						I18n.t(_t(translations.miniCMSSetting.titleWhyChoose)),
						'',
						{},
						{
							placeholder: I18n.t(
								_t(translations.miniCMSSetting.titleWhyChoosePlaceholder)
							),
							maxLength: 256,
						}
					)}
					{FormHelper.renderFormTextArea(
						'subTitle',
						[],
						I18n.t(_t(translations.miniCMSSetting.subTitlWhyChoose)),
						I18n.t(
							_t(translations.miniCMSSetting.subTitleWhyChoosePlaceholder)
						),
						''
					)}
					{FormHelper.renderFormTextArea(
						'description',
						[],
						I18n.t(_t(translations.miniCMSSetting.descriptionWhyChoose)),
						I18n.t(
							_t(translations.miniCMSSetting.descriptionWhyChoosePlacehoder)
						),
						''
					)}
				</Form>
			</ModalHeper>
		);
	};

	// 6. Services
	const handleSearchServices = (value: any) => {
		const filteredArr = searchTextServices(value);
		setFormVisible({
			mdlFormVisibleServices: true,
			edfWrapper: { filteredArr: filteredArr || [] },
		});
	};

	const searchTextServices = (value: any) => {
		const dataServices = services.filter((service: any) => {
			return service.name.toLowerCase().includes(value.toLowerCase());
		});

		const filteredArr: any = [];
		categories.map((category: any) => {
			const filterService = dataServices.filter(
				(x: any) => x.itemCategoryId == category.id
			);

			filteredArr.push({
				id: category.id,
				name: category.name,
				services: filterService,
			});
		});

		return filteredArr;
	};

	const handleChangeServices = (
		checked: any,
		indexService: number,
		indexCategory: number,
		id: any
	) => {
		services.map((x: any) => {
			if (x.id === id) {
				x.checked = checked;
			}
		});
		formVisible.edfWrapper.filteredArr[indexCategory].services[
			indexService
		].checked = checked;

		setFormVisible({
			...formVisible,
			edfWrapper: formVisible.edfWrapper,
		});
		setServices(services);
	};

	const handleCancelServices = () => {
		setFormVisible({ mdlFormVisibleServices: false, edfWrapper: {} });
	};

	const handleOkServices = async () => {
		let postData = services.filter((a: any) => a.checked);
		let params: any = [];
		postData.map((a: any) => {
			params.push({ itemId: a.id, itemCategoryId: a.itemCategoryId });
		});
		await GalleryApiService.updateThemeShopService(params)
			.then((res: any) => {
				if (res.succeeded) {
					AlertHelper.showSuccess(
						`${I18n.t(_t(translations.miniCMSSetting.updateServiceSuccess))}`
					);
					handleCancelServices();
					setReloadService(!reloadService);
				} else {
					const error = res as IErrorResponse;
					AlertHelper.showError(error);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const renderServices = () => {
		return (
			<ModalHeper
				title={I18n.t(_t(translations.miniCMSSetting.headerServices))}
				visible={formVisible.mdlFormVisibleServices}
				onCancel={handleCancelServices}
				onOk={() => handleOkServices()}
			>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Input
							allowClear
							prefix={<SearchOutlined />}
							placeholder={I18n.t(_t(translations.placeHolder.searchService))}
							style={styles.maxWidth}
							onChange={(e) => handleSearchServices(e.target.value)}
						/>
					</Col>
					<Col span={24}>
						<Collapse>
							{formVisible.edfWrapper?.filteredArr?.map(
								(x: any, indexCategory: number) => (
									<Panel header={x.name} key={x.id}>
										<List
											itemLayout="horizontal"
											dataSource={x.services}
											renderItem={(item: any, indexService: number) => (
												<>
													<Row gutter={[16, 16]}>
														<Col span={2}>
															<Checkbox
																checked={item.checked}
																onChange={(e) =>
																	handleChangeServices(
																		e.target.checked,
																		indexService,
																		indexCategory,
																		item.id
																	)
																}
															/>
														</Col>
														<Col span={16}>
															<Text strong>{item.name}</Text>
														</Col>
														<Col span={6}>
															<Space direction="horizontal" size="large">
																<Text>
																	{TimeHelper.convertDurationToStringDetail(
																		item.duration
																	)}
																</Text>
																<Text>${item.price}</Text>
															</Space>
														</Col>
													</Row>
													<Divider style={styles.divider} />
												</>
											)}
											rowKey={(item) => `key ${item.id}`}
											locale={{
												emptyText: I18n.t(_t(translations.services.noService)),
											}}
										/>
									</Panel>
								)
							)}
						</Collapse>
					</Col>
				</Row>
			</ModalHeper>
		);
	};

	return (
		<DetailPageLayout_V2
			title={I18n.t(_t(translations.miniCMSSetting.title))}
			positiveButtonName={I18n.t(_t(translations.save))}
			showDelete={true}
			isLoading={false}
			goBack={() => goBack()}
		>
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.miniCMSSetting.theme))}
					description={I18n.t(_t(translations.miniCMSSetting.subTheme))}
				/>
				<Divider style={styles.divider} />
				<Table
					rowKey={(record) => record.type}
					loading={isLoading}
					columns={columns}
					pagination={false}
					dataSource={dataThemes}
				/>
			</Card>
			{renderSliderSetup()}
			{renderAboutUs()}
			{renderWelcomeText()}
			{renderWhyChoose()}
			{renderServices()}
		</DetailPageLayout_V2>
	);
}

export default MiniCMSSetting;
