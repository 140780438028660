import GlobalStyles, { kStyles } from 'GlobalStyles';

const styles: kStyles = {
	hoverBoxShadow: {
		boxShadow: 'rgb(164 173 186 / 25%) 0px 2px 5px 3px',
	},
	cardLink: { width: '100%', margin: '8px 0 8px 0' },
	cardOption: {
		height: '100%',
		display: 'block',
		...GlobalStyles.rounded,
		...GlobalStyles.boxShadow,
	},
	rowOfMainColunn: {
		minHeight: 100,
	},
	icClose: {
		cursor: 'pointer',
		position: 'absolute',
		right: 32,
	} as React.CSSProperties,
};

export default styles;
