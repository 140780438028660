/* eslint-disable react-hooks/exhaustive-deps */
import { Table, Typography } from 'antd';
import { EmployeePickerModal } from 'components';
import { AlertHelper, BookingHelper } from 'helpers';
import _ from 'lodash';
import { IEmployee, IErrorResponse, IHolidayModel } from 'models';
import {
	IAddHolidayRequest,
	IGetAllHolidayRequest,
} from 'models/RequestModels';
import { IPaginateResponse } from 'models/ResponseModels';
import React, { useEffect, useState } from 'react';
import { HolidayApiService } from 'services';
import { I18n, translations, _t } from 'utils';
import momentTz from 'moment-timezone';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import './styles.css';
import { HolidayType } from 'utils/Consts';
import {
	createHoliday,
	deleteHoliday,
	updateHoliday,
	convertStartEndTime,
} from './helpers';
import HeaderController from './HeaderController';
import HolidayDetailModal, { IHoliday } from './HolidayDetailModal';
import styles from './styles';
import { TDateRange } from 'components/DatePicker/CalendarModal';

const StaffHoliday = () => {
	const timeZone = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch?.localTimeZone
	);
	const employees = useSelector(
		(state: RootState) => state.EmployeeReducer.employees
	);
	const currentBranch = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch
	);
	const [holiday, setHoliday] = useState<IPaginateResponse<IHolidayModel[]>>();
	const [selectedEmployee, setSelectedEmployee] =
		useState<Partial<IEmployee>>();
	const [isShowEmployeePickerModal, setIsShowEmployeePickerModal] =
		useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedHoliday, setSelectedHoliday] = useState<IHolidayModel>();
	const [isShowHolidayDetailModal, setIsShowHolidayDetailModal] =
		useState<boolean>();
	const [selectedDateRage, setSelectedDateRage] = useState<TDateRange>({
		fromDate: momentTz.tz(timeZone!),
		toDate: momentTz.tz(timeZone!).add(3, 'month'),
	});
	const { fromDate, toDate } = selectedDateRage;
	// const [fromDate, setFromDate] = useState<moment.Moment>(
	// 	momentTz.tz(timeZone!)
	// );
	// const [toDate, setToDate] = useState<moment.Moment>(
	// 	momentTz.tz(timeZone!).add(3, 'month')
	// );

	const baseRequest = {
		orderBy: 'fromDate asc',
		pageSize: 9,
		employeeHoliday: true,
	};

	useEffect(() => {
		setIsLoading(false);
	}, [holiday]);

	useEffect(() => {
		setIsShowEmployeePickerModal(false);
		getHoliday({
			pageNumber: 1,
			employeeId: selectedEmployee?.id,
			fromDate: BookingHelper.convertDateRequest(fromDate!),
			toDate: BookingHelper.convertDateRequest(toDate!),
			...baseRequest,
		});
	}, [selectedEmployee, selectedDateRage]);

	useEffect(() => {
		if (!_.isEmpty(selectedHoliday)) {
			setIsShowHolidayDetailModal(true);
		}
	}, [selectedHoliday]);

	const renderHeader = () => (
		<HeaderController
			selectedDateRage={selectedDateRage}
			loading={isLoading}
			onAddNewClick={() => setIsShowHolidayDetailModal(true)}
			onSelectDateRange={(dateRage) => setSelectedDateRage(dateRage)}
			onEmployeePickerClick={() => setIsShowEmployeePickerModal(true)}
			selectedEmployee={selectedEmployee}
		/>
	);

	const renderEmployeeCol = () => (
		<Table.Column<IHolidayModel>
			render={(_, record, index) => (
				<Typography.Text>
					{`${
						employees?.find((element) => element.id === record.employeeId)
							?.firstName || ''
					} ${
						employees?.find((element) => element.id === record.employeeId)
							?.lastName || ''
					}`}
				</Typography.Text>
			)}
			title={
				<Typography.Text>
					{I18n.t(_t(translations.holiday.staff))}
				</Typography.Text>
			}
		/>
	);

	const renderStartTimeCol = () => (
		<Table.Column<IHolidayModel>
			render={(_, recod, index) => (
				<>
					<Typography.Text className="d-block">
						{momentTz.utc(recod.startTime).tz(timeZone!).format('MMM DD, YYYY')}
					</Typography.Text>
					<Typography.Text className="d-block">
						{momentTz.utc(recod.startTime).tz(timeZone!).format('h:mmA')}
					</Typography.Text>
				</>
			)}
			title={
				<Typography.Text>
					{I18n.t(_t(translations.holiday.startAt))}
				</Typography.Text>
			}
		/>
	);

	const renderEndTimeCol = () => (
		<Table.Column<IHolidayModel>
			render={(_, recod, index) => (
				<>
					<Typography.Text className="d-block">
						{momentTz.utc(recod.endTime).tz(timeZone!).format('MMM DD, YYYY')}
					</Typography.Text>
					<Typography.Text className="d-block">
						{momentTz.utc(recod.endTime).tz(timeZone!).format('h:mmA')}
					</Typography.Text>
				</>
			)}
			title={
				<Typography.Text>
					{I18n.t(_t(translations.holiday.endAt))}
				</Typography.Text>
			}
		/>
	);

	const renderDescriptionCol = () => (
		<Table.Column<IHolidayModel>
			render={(_, record, index) => (
				<Typography.Text>{record.description}</Typography.Text>
			)}
			title={
				<Typography.Text>
					{I18n.t(_t(translations.description))}
				</Typography.Text>
			}
		/>
	);

	const renderEmployeeModal = () => {
		return (
			<EmployeePickerModal
				selectedEmployeeId={selectedEmployee?.id}
				onCancel={() => setIsShowEmployeePickerModal(false)}
				onSelectEmployee={(employeee) => {
					_.isEmpty(employeee)
						? setSelectedEmployee({
								lastName: I18n.t(_t(translations.all)),
								firstName: '',
						  })
						: setSelectedEmployee(employeee);
				}}
				allOption
			/>
		);
	};

	const renderAddHolidayModal = () => {
		return (
			<HolidayDetailModal
				isLoading={isLoading}
				isShowModal={true}
				onCancel={() => {
					setSelectedHoliday(undefined);
					setIsShowHolidayDetailModal(false);
				}}
				onDelete={_deleteHoliday}
				onSubmit={onSubmitHoliday}
				selectedHoliday={selectedHoliday}
			/>
		);
	};

	const onPageTableChange = (pageNumber: number) => {
		getHoliday({
			pageNumber: pageNumber,
			pageSize: holiday?.pageSize!,
			employeeId: selectedEmployee?.id,
			fromDate: momentTz.tz(timeZone!).format('YYYY-MM-DD'),
			toDate: momentTz
				.tz(timeZone!)
				.clone()
				.add(1, 'month')
				.format('YYYY-MM-DD'),
			orderBy: 'fromDate asc',
		});
	};

	const onSubmitHoliday = (values: IHoliday) => {
		if (selectedHoliday) {
			const request: IHolidayModel = {
				...selectedHoliday,
				startTime: values.time
					? convertStartEndTime(values.time[0], values.fromDate)
					: undefined,
				endTime: values.time
					? convertStartEndTime(values.time[1], values.toDate)
					: undefined,
				fromDate: BookingHelper.convertDateRequest(values.fromDate),
				toDate: BookingHelper.convertDateRequest(values.toDate),
				employeeId: values.staff.id,
				description: values.description,
			};
			_updateHoliday(request);
		} else {
			const request: IAddHolidayRequest = {
				frequencyType: HolidayType.ONCE,
				fromDate: BookingHelper.convertDateRequest(values.fromDate),
				toDate: BookingHelper.convertDateRequest(values.toDate),
				startTime: values.time
					? momentTz(
							`${momentTz(values.fromDate).format('YYYY-MM-DD')}T${momentTz(
								values.time[0],
								timeZone
							).format('HH:mm')}`
					  )
							.utc()
							.format('YYYY-MM-DDTHH:mm')
					: undefined,
				endTime: values.time
					? momentTz(
							`${momentTz(values.toDate).format('YYYY-MM-DD')}T${momentTz(
								values.time[1],
								timeZone
							).format('HH:mm')}`
					  )
							.utc()
							.format('YYYY-MM-DDTHH:mm')
					: undefined,
				holidayName: `${values.description}_${momentTz
					.tz(timeZone!)
					.format('YYYY-MM-DDTHH:mm:SSS')}`,
				description: values.description,
				employeeId: values.staff.id,
				status: 1,
				branchId: currentBranch?.id!,
			};
			_createHoliday(request);
		}
	};

	const getHoliday = async (request: IGetAllHolidayRequest) => {
		setIsLoading(true);

		try {
			const result = (await HolidayApiService.getAllHoliday(
				request
			)) as IPaginateResponse<IHolidayModel[]>;
			if (result.succeeded) {
				setHoliday(result);
			} else {
				AlertHelper.showError(result as IErrorResponse);
			}
		} catch (error) {
			console.error('Network Error', error);
		}
	};

	const _createHoliday = async (request: IAddHolidayRequest) => {
		setIsLoading(true);
		createHoliday(
			request,
			() => {
				getHoliday({
					pageNumber: holiday?.pageNumber!,
					employeeId: selectedEmployee?.id,
					fromDate: BookingHelper.convertDateRequest(fromDate!),
					toDate: BookingHelper.convertDateRequest(toDate!),
					...baseRequest,
				});
				setIsShowHolidayDetailModal(false);
			},
			() => setIsLoading(true)
		);
	};

	const _updateHoliday = async (request: IHolidayModel) => {
		setIsLoading(true);
		updateHoliday(
			request,
			() => {
				getHoliday({
					pageNumber: holiday?.pageNumber!,
					employeeId: selectedEmployee?.id,
					fromDate: BookingHelper.convertDateRequest(fromDate!),
					toDate: BookingHelper.convertDateRequest(toDate!),
					...baseRequest,
				});
				setIsShowHolidayDetailModal(false);
			},
			() => setIsLoading(true)
		);
	};

	const _deleteHoliday = async (id: string) => {
		setIsLoading(true);
		deleteHoliday(
			id,
			() => {
				setHoliday({
					...holiday!,
					data: holiday?.data?.filter((element) => element.id !== id),
				});
				setIsShowHolidayDetailModal(false);
			},
			() => setIsLoading(true)
		);
	};

	return (
		<>
			{renderHeader()}
			<Table<IHolidayModel>
				dataSource={holiday?.data}
				pagination={{
					pageSize: holiday?.pageSize,
					total: holiday?.totalRecords,
					onChange: onPageTableChange,
				}}
				loading={isLoading}
				onRow={(data) => ({
					onClick: () => {
						setSelectedHoliday(data);
					},
					className: 'expense-row',
				})}
				style={styles.table}
			>
				{renderEmployeeCol()}
				{renderStartTimeCol()}
				{renderEndTimeCol()}
				{renderDescriptionCol()}
			</Table>
			{isShowEmployeePickerModal && renderEmployeeModal()}
			{isShowHolidayDetailModal && renderAddHolidayModal()}
		</>
	);
};

// const useHoliday = (
// 	initial?: IPaginateResponse<IHolidayModel[]>
// ): [
// 	IPaginateResponse<IHolidayModel[]> | undefined,
// 	(holiday?: IPaginateResponse<IHolidayModel[]>) => void
// ] => {
// 	const [rootHoliday, setRootHoliday] = useState<
// 		IPaginateResponse<IHolidayModel[]> | undefined
// 	>(initial);
// 	const customSetHoliday = (holiday?: IPaginateResponse<IHolidayModel[]>) => {
// 		const cleanedHoliday: IPaginateResponse<IHolidayModel[]> = {
// 			...holiday!,
// 			data: holiday?.data,
// 		};
// 		// if (_.isEmpty(rootHoliday)) {
// 		// 	setRootHoliday(cleanedHoliday);
// 		// } else {
// 		// 	setRootHoliday({
// 		// 		...cleanedHoliday,
// 		// 		data: rootHoliday!.data!.slice().concat(cleanedHoliday.data || []),
// 		// 	});
// 		// }
// 		setRootHoliday(cleanedHoliday);
// 	};

// 	return [rootHoliday, customSetHoliday];
// };

export default StaffHoliday;
