import React from 'react';
import { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { IBooking } from 'models';
import { StatusTag } from 'components';
import { Col, Row, Typography, List, Divider } from 'antd';
import { BookingHelper, CurrencyHelper, TimeHelper } from 'helpers';
import { history } from 'routers';
import { I18n, _t, translations } from 'utils';
import { toTimeZone } from 'helpers/timeHelper';

interface IBookingItemProps {
	booking: IBooking;
	showStylist?: boolean;
	hideDate?: boolean;
	showDivider?: boolean;
	showColor?: boolean;
	onClick?(booking: IBooking): void;
}
class BookingItem extends Component<IBookingItemProps> {
	render() {
		const booking = this.props.booking;
		const customerName = `${booking.customer.firstName} ${booking.customer.lastName}`;

		return (
			// <List.Item style={{ cursor: "pointer" }} key={booking.id}>
			<>
				<Row
					onClick={() => this.onClick(booking)}
					style={{
						width: '100%',
						cursor: 'pointer',
						backgroundColor: this.props.showColor
							? booking.stylist?.color
							: 'white',
					}}
				>
					<Col
						span={this.props.hideDate ? 0 : 3}
						style={{ textAlign: 'center' }}
					>
						<Typography.Title style={{ marginBottom: 0 }} level={5}>
							{toTimeZone(booking.startTimeExpected).date()}
						</Typography.Title>
						<Typography.Text style={{ fontSize: 16 }}>
							{toTimeZone(booking.startTimeExpected).format('MMM')}
						</Typography.Text>
					</Col>
					<Col span={this.props.hideDate ? 18 : 17}>
						<Row>
							<Typography.Text type="secondary">
								{toTimeZone(booking.startTimeExpected).format(
									this.props.hideDate ? 'MM/DD h:mmA' : 'ddd h:mmA'
								)}
							</Typography.Text>
							<StatusTag status={booking.status} />
						</Row>
						{booking.bookingDetails.map((bookingDetail) => {
							const stylistName = bookingDetail.stylist
								? `${bookingDetail.stylist.firstName} ${
										bookingDetail.stylist.lastName || ''
								  }`
								: undefined;

							return (
								<>
									<Typography.Title
										style={{ marginBottom: 0, marginTop: 6 }}
										level={5}
									>
										{bookingDetail.item?.name}
									</Typography.Title>
									{this.props.showStylist ? (
										_.isEmpty(bookingDetail.stylist) ? (
											<Typography.Text>{`${bookingDetail.item?.duration} minutes`}</Typography.Text>
										) : (
											<Typography.Text>
												{`${bookingDetail.item?.duration} minutes with `}{' '}
												<strong>{stylistName} </strong>
											</Typography.Text>
										)
									) : (
										<Typography.Text>{`${customerName}, ${bookingDetail.item?.duration}min`}</Typography.Text>
									)}
								</>
							);
						})}
					</Col>
					<Col
						span={this.props.hideDate ? 5 : 4}
						style={{ textAlign: 'right' }}
					>
						<Typography.Title level={5}>
							{CurrencyHelper.formatPrice(booking.totalAmount)}
						</Typography.Title>
					</Col>
				</Row>
				{this.props.showDivider ? <Divider /> : null}
			</>
		);
	}

	onClick = (booking: IBooking) => {
		if (this.props.onClick) {
			this.props.onClick(booking);
			return;
		}
		BookingHelper.openBooking(booking);
	};
}
export default BookingItem;
