import GlobalStyles, { kStyles } from 'GlobalStyles';

const styles: any = {
	noPaddingCol: {
		paddingInline: 0,
		width: '100%',
	},
	container: {
		display: 'flex',
		width: '100%',
		marginBottom: 50,
	},
	header: {
		position: 'fixed',
		zIndex: 9999,
		width: '100%',
		alignItems: 'center',
		padding: 0,
		...GlobalStyles.boxShadow,
	} as React.CSSProperties,
	iconContainer: { textAlign: 'end', paddingRight: 35 } as React.CSSProperties,
	title: {
		color: 'white',
		textAlign: 'center',
		marginBottom: 0,
		justifyContent: 'center',
	} as React.CSSProperties,
	contentContainer: { marginTop: 100 },
	space: { width: '100%' },
};

export default styles;
