import { Button, Modal } from 'antd';
import { I18n, _t, translations } from 'utils';
import React, { useRef } from 'react';

interface PreviewModalProps {
	isModalVisible: boolean;
	handleCancel(): void;
	htmlText?: string;
}

const PreviewModal = (props: PreviewModalProps) => {
	let htmlText = () => ({ __html: props.htmlText! });
	return (
		<Modal
			title="Preview"
			visible={props.isModalVisible}
			onCancel={props.handleCancel}
			footer={[
				<Button key="back" onClick={props.handleCancel}>
					{I18n.t(_t(translations.cancel))}
				</Button>,
			]}
		>
			<div dangerouslySetInnerHTML={htmlText()}></div>
		</Modal>
	);
};

export default PreviewModal;
