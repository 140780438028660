import {
	Col,
	Form,
	FormInstance,
	Layout,
	Row,
	Space,
	Typography,
	Button,
} from 'antd';
import { Component, CSSProperties } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import styles from './styles';
import { Header } from 'components';

const { Title } = Typography;

interface IDetailPageLayoutProps {
	positiveButtonName: string;
	negativeButtonName?: string;
	title: string;
	onFormFinish?(name: any, info: any): void;
	goBack(): void;
	showDelete?: boolean;
	isLoading?: boolean;
	onDeleteClick?(): void;
	formName?: string;
	formRef?(from: FormInstance): void;
}
interface IDetailPageLayoutState {}
class DetailPageLayout extends Component<
	IDetailPageLayoutProps,
	IDetailPageLayoutState
> {
	render() {
		return (
			<Layout style={styles.layout}>
				{this.renderHeader()}
				<Form.Provider
					onFormFinish={(name, info) =>
						this.props.onFormFinish ? this.props.onFormFinish(name, info) : {}
					}
				>
					<Form
						ref={(ref) => {
							if (this.props.formRef) {
								this.props.formRef(ref!);
							}
						}}
						scrollToFirstError={true}
						layout="vertical"
						name={this.props.formName || 'form'}
						style={styles.formContainer}
					>
						<Row style={styles.contentContainer}>
							<Col lg={4} />
							<Col lg={16} xs={24}>
								<Space direction="vertical" size="small" style={styles.space}>
									{this.props.children}
									{this.renderButton()}
								</Space>
							</Col>
							<Col lg={4} />
						</Row>
					</Form>
				</Form.Provider>
			</Layout>
		);
	}

	renderHeader = () => {
		return (
			<Header
				detailPageProps={{
					title: this.props.title,
					onClosePress: () => this.props.goBack(),
				}}
			/>
		);
	};

	renderMobileHeader() {
		return (
			<Col span={24} style={styles.headerContainer}>
				<Row justify="space-between" style={styles.rowHeader}>
					<Col span={2}>
						<CloseOutlined
							onClick={() => {
								this.props.goBack();
							}}
							style={{ ...styles.icClose, fontSize: 22 }}
						/>
					</Col>
					<Col span={18}>
						<Title level={3} style={styles.headerTitle}>
							{this.props.title}
						</Title>
					</Col>
					<Col span={2}></Col>
				</Row>
			</Col>
		);
	}

	renderHeaderTitle() {
		const headerTitle: any = styles.headerTitle;
		return <Title style={headerTitle}>{this.props.title}</Title>;
	}

	renderButton() {
		const btnStyle: CSSProperties =
			window.innerWidth <= 768
				? { ...styles.buttonContainer, paddingRight: 16 }
				: styles.buttonContainer;
		return (
			<Form.Item style={btnStyle}>
				<Space direction="horizontal" size="middle">
					{this.props.showDelete ? null : (
						<Button
							danger
							size="large"
							onClick={() =>
								this.props.onDeleteClick ? this.props.onDeleteClick() : {}
							}
						>
							{this.props.negativeButtonName}
						</Button>
					)}
					<Button
						type="primary"
						size="large"
						htmlType="submit"
						loading={this.props.isLoading}
					>
						{this.props.positiveButtonName}
					</Button>
				</Space>
			</Form.Item>
		);
	}
}

export default DetailPageLayout;
