import { IHolidayModel } from "models";
import { IAddHolidayRequest, IGetAllHolidayRequest } from "models/RequestModels";
import { IPaginateResponse } from "models/ResponseModels";
import { BaseApiService } from "services/BaseApiService";

const url = "/Holiday";

class HolidayServices extends BaseApiService {
  public addHoliday = (request: IAddHolidayRequest) =>
    this.post<IAddHolidayRequest, any>(`${url}/create-holiday`, request);
  public getAllHoliday = (request: IGetAllHolidayRequest) =>
    this.get<IPaginateResponse<IHolidayModel[]>>(`${url}/get-all-holidays`, {
      params: request,
    });
  public getHolidayById = (id: string) => this.get<IHolidayModel>(`${url}/get-holiday/${id}`);
  public updateHoliday = (request: Partial<IHolidayModel>) =>
    this.put<Partial<IHolidayModel>>(`${url}/update-holiday`, request);
  public deleteHoliday = (id: string) => this.delete<string>(`${url}/delete-holiday/${id}`);
}

export default new HolidayServices(true);
