/* eslint-disable react-hooks/exhaustive-deps */
import { Tabs } from 'antd';
import { ContentAdminContainer } from 'components';
import React from 'react';
import { I18n, translations, _t } from 'utils';
import { StaffHoliday, ShopHoliday } from './component';

const Holiday = () => {
	return (
		<ContentAdminContainer
			customPageName={I18n.t(_t(translations.holiday.header))}
		>
			<Tabs type="card">
				<Tabs.TabPane
					tab={I18n.t(_t(translations.holiday.staffHeader))}
					key="staff"
				>
					<StaffHoliday />
				</Tabs.TabPane>
				<Tabs.TabPane
					tab={I18n.t(_t(translations.holiday.shopHeader))}
					key="shop"
				>
					<ShopHoliday />
				</Tabs.TabPane>
			</Tabs>
		</ContentAdminContainer>
	);
};

export default Holiday;
