import { IApiResponse, IEmployee } from 'models';
import { put, takeEvery, call } from 'redux-saga/effects';
import { EmployeeActions } from 'redux/actions';
import EmployeeApiService from 'services/EmployeeApiService';
import { IErrorResponse } from '../../models/IErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';
import {
	IAddEmployeeRequest,
	IEditEmployeeRequest,
} from 'models/RequestModels';
import UploadBaseService from 'services/UploadBaseService';
import { IFileUploadResponse } from 'models/ResponseModels';
import { AlertHelper } from 'helpers';
import { I18n, _t, translations } from 'utils';
function* getAllEmployees(action: PayloadAction<string>) {
	try {
		const result: IApiResponse<IEmployee[]> = yield call(
			EmployeeApiService.getAllStylist,
			action.payload
		);
		if (result.succeeded) {
			yield put(EmployeeActions.getAllEmployees.success(result.data!));
		} else {
			const error = result as IErrorResponse;
			yield put(EmployeeActions.getAllEmployees.failed(error));
		}
	} catch (error) {
		yield put(EmployeeActions.getAllEmployees.failed(error));
	}
}
function* getEmployeeById(action: PayloadAction<string>) {
	try {
		const result: IApiResponse<IEmployee> = yield call(
			EmployeeApiService.getEmployeeById,
			action.payload
		);
		if (result.succeeded) {
			yield put(EmployeeActions.getEmployeeById.success(result.data!));
		} else {
			const errorResult = result as IErrorResponse;
			yield put(EmployeeActions.getEmployeeById.failed(errorResult));
		}
	} catch (error) {
		yield put(EmployeeActions.getEmployeeById.failed(error));
	}
}
function* addEmployee(action: PayloadAction<IAddEmployeeRequest>) {
	try {
		const file = action.payload.file;
		if (file) {
			const fileResult: IApiResponse<IFileUploadResponse[]> = yield call(
				UploadBaseService.uploadImage,
				[file]
			);
			if (fileResult.succeeded) {
				action.payload.imageUrl = (
					fileResult.data as IFileUploadResponse[]
				)[0].imageUrl;
			}
		}
		const result: IApiResponse<IEmployee> = yield call(
			EmployeeApiService.addEmployee,
			action.payload
		);
		if (result.succeeded) {
			yield put(EmployeeActions.addEmployee.success(result.data!));
			// AlertHelper.showAlert(
			// 	'Success',
			// 	I18n.t(_t(translations.staffDetail.addNewSuccess)),
			// 	'success'
			// );
		} else {
			const error = result as IErrorResponse;
			yield put(EmployeeActions.addEmployee.failed(error));
			AlertHelper.showError(error);
		}
	} catch (error) {
		yield put(EmployeeActions.addEmployee.failed(error));
		AlertHelper.showError(error as IErrorResponse);
	}
}

function* editEmployee(action: PayloadAction<IEditEmployeeRequest>) {
	try {
		const file = action.payload.file;
		if (file) {
			const fileResult: IApiResponse<IFileUploadResponse[]> = yield call(
				UploadBaseService.uploadImage,
				[file]
			);
			if (fileResult.succeeded) {
				action.payload.imageUrl = (
					fileResult.data as IFileUploadResponse[]
				)[0].imageUrl;
			}
		}
		if (file) {
			delete action.payload.file;
		}
		const result: IApiResponse<IEmployee> = yield call(
			EmployeeApiService.editEmployee,
			action.payload
		);
		if (result.succeeded) {
			yield put(EmployeeActions.editEmployee.success(result.data!));
			AlertHelper.showAlert(
				'Success',
				I18n.t(_t(translations.staffDetail.editSuccess)),
				'success'
			);
		} else {
			const error = result as IErrorResponse;
			yield put(EmployeeActions.editEmployee.failed(error));
			AlertHelper.showError(error);
		}
	} catch (error) {
		yield put(EmployeeActions.editEmployee.failed(error));
		AlertHelper.showError(error as IErrorResponse);
	}
}

function* deleteEmployee(action: PayloadAction<string>) {
	try {
		const result: IApiResponse<string> = yield call(
			EmployeeApiService.deleteEmployee,
			action.payload
		);
		if (result.succeeded) {
			yield put(EmployeeActions.deleteEmployee.success(result.data!));
			AlertHelper.showAlert(
				'Success',
				I18n.t(_t(translations.staffDetail.deleteSuccess)),
				'success'
			);
		} else {
			const error = result as IErrorResponse;
			yield put(EmployeeActions.deleteEmployee.failed(error));
			AlertHelper.showError(error);
		}
	} catch (error) {
		yield put(EmployeeActions.deleteEmployee.failed(error));
		AlertHelper.showError(error as IErrorResponse);
	}
}
function* getEmployeesByBranch() {
	try {
		const result: IApiResponse<IEmployee[]> = yield call(
			EmployeeApiService.getAllEmployees
		);
		yield put(EmployeeActions.getEmployeesByBranch.success(result.data!));
	} catch (error) {
		yield put(EmployeeActions.getEmployeesByBranch.failed(error));
	}
}
export function* EmployeeWatcher() {
	yield takeEvery(EmployeeActions.getAllEmployees.requestName, getAllEmployees);
	yield takeEvery(EmployeeActions.getEmployeeById.requestName, getEmployeeById);
	yield takeEvery(EmployeeActions.addEmployee.requestName, addEmployee);
	yield takeEvery(EmployeeActions.editEmployee.requestName, editEmployee);
	yield takeEvery(EmployeeActions.deleteEmployee.requestName, deleteEmployee);
	yield takeEvery(
		EmployeeActions.getEmployeesByBranch.requestName,
		getEmployeesByBranch
	);
}
