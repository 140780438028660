import { Component, CSSProperties } from 'react';
import {
	SearchOutlined,
	SaveOutlined,
	ImportOutlined,
	DollarCircleOutlined,
	EditOutlined,
	CloseOutlined,
	UserSwitchOutlined,
	PlusOutlined,
	DeleteOutlined,
} from '@ant-design/icons';
import {
	Input,
	Button,
	Divider,
	Typography,
	List,
	Row,
	Col,
	Tabs,
	Menu,
	Spin,
	Tag,
	Space,
	Switch,
	Radio,
} from 'antd';
import { _t, I18n, translations, Colors } from 'utils';
import { Avatar, Modal, StatusTag } from 'components';
import InfiniteScroll from 'react-infinite-scroller';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { Dispatch } from 'redux';
import { IComponentProps, CustomerInfo } from 'components';
import { BookingActions, CustomerActions, ErrorActions } from 'redux/actions';
import { searchCustomer } from 'redux/selectors/customer';
import { EBookingType, IApiResponse, ICustomer } from 'models';
import styles from '../styles';
import { ColorHelper, CurrencyHelper, TimeHelper } from 'helpers';
import _ from 'lodash';
import { FormInModal } from 'components';
import { AlertHelper, FormHelper, StringHelper } from 'helpers';
import moment from 'moment';
import { BookingStatus } from 'utils/Consts';
import { convertToRawPhone, formatPhoneNumber } from 'helpers/StringHelper';
import { IGetAllCustomerRequest } from 'models/RequestModels';
import CustomerApiService from 'services/CustomerApiService';
import InputFromTag from 'components/InputFromTag';
import { history } from 'routers';
import { showAlert, showError } from 'helpers/alertHelper';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const ListGender = [
	{
		id: '0',
		type: I18n.t(_t(translations.bookingCustomerInfo.male)),
	},
	{
		id: '1',
		type: I18n.t(_t(translations.bookingCustomerInfo.female)),
	},
	{
		id: '-1',
		type: I18n.t(_t(translations.bookingCustomerInfo.noInfo)),
	},
];
interface IStates {
	listContainer: any;
	searchTerm: string;
	showModal: boolean;
	isAdd: boolean;
	showCancelModal: boolean;
	isLoading: boolean;
	customers: ICustomer[];
}
interface ICustomerProps
	extends IComponentProps<any>,
		ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps> {
	onSubmitBooking?(): void;
	onCheckInOut?(): void;
	onChangeBookingType?(bookingType: EBookingType): void;
	isEdit?: boolean;
	checkedIn?: boolean;
	checkedOut?: boolean;
	canCheckIn?: boolean;
	canCheckOut?: boolean;
	isDone?: boolean;
	isCheckout?: boolean;
	isSaleGiftCard?: boolean;
	bookingType?: EBookingType;
	canWalkIn?: boolean;
}

const onlyNumberPattern = /^[0-9]*$/;

class CustomerBooking extends Component<ICustomerProps, IStates> {
	constructor(props: ICustomerProps) {
		super(props);
		this.state = {
			listContainer: null,
			searchTerm: '',
			showModal: false,
			showCancelModal: false,
			isAdd: true,
			isLoading: false,
			customers: [],
		};
		this.onChangeDebounce = _.debounce(this.onChangeDebounce, 1000);
	}

	componentDidMount() {
		this.onTableChange('ascend');
		if (!_.isEmpty(this.props.selectedCustomer)) {
			// this.props.getCustomerById(this.props.selectedCustomer!.id);
		}
	}
	componentDidUpdate(prevProps: ICustomerProps) {
		const { currentAction, clearError } = this.props;
		if (prevProps.currentAction !== this.props.currentAction) {
			if (currentAction === CustomerActions.addCustomer.successName) {
				AlertHelper.showAlert(
					'Success',
					I18n.t(
						_t(
							this.state.isAdd
								? translations.bookingCustomerInfo.addNewSuccess
								: translations.bookingCustomerInfo.editSuccess
						)
					),
					'success'
				);
				this.onTableChange('ascend');
				return;
			}

			if (
				currentAction === CustomerActions.addCustomer.failedName ||
				currentAction === CustomerActions.editCustomer.failedName
			) {
				AlertHelper.showAlert('Error', '', 'error', clearError);
				return;
			}
		}
	}
	componentWillUnmount() {
		this.props.removeCustomer();
	}

	render() {
		return (
			<>
				{!_.isEmpty(this.props.selectedCustomer) ? (
					<>
						<CustomerInfo
							customer={this.props.selectedCustomer}
							showMenu
							overlay={this.renderMenu}
						/>
						{!_.isEmpty(this.props.currentBooking)
							? this.renderStatus()
							: this.renderBookingType()}

						{this.renderMoreInfo()}
					</>
				) : (
					this.renderListCustomer()
				)}
				<Divider style={styles.divider} />
				{this.renderFooter()}
				{this.renderFormInModal()}
				{this.renderCancelModal()}
			</>
		);
	}
	renderBookingType() {
		return (
			<Row>
				<Divider />

				<Col style={styles.col} span={12}>
					<Space size={'large'}>
						<Typography.Text strong>Booking Type: </Typography.Text>
						<Radio.Group
							defaultValue={this.props.bookingType || EBookingType.WalkIn}
							buttonStyle="solid"
							onChange={(e) => {
								if (this.props.onChangeBookingType) {
									this.props.onChangeBookingType(
										e.target.value as EBookingType
									);
								}
							}}
						>
							<Radio.Button
								disabled={!this.props.canWalkIn}
								value={EBookingType.WalkIn}
							>
								WALK IN
							</Radio.Button>
							<Radio.Button value={EBookingType.Appointment}>
								APPOINTMENT
							</Radio.Button>
						</Radio.Group>
					</Space>
				</Col>
			</Row>
		);
	}
	renderCancelModal() {
		return (
			<Modal
				visible={this.state.showCancelModal}
				title={I18n.t(_t(translations.cancel))}
				OKString={`${I18n.t(_t(translations.yes))}, ${I18n.t(
					_t(translations.cancel)
				)}`}
				handleOk={() => {
					this.setState({ showCancelModal: false }, () => {
						this.props.cancelBooking(this.props.currentBooking?.id!);
					});
				}}
				handleCancel={() => this.setState({ showCancelModal: false })}
			>
				{I18n.t(_t(translations.cancelBookingConfirm))}
			</Modal>
		);
	}
	renderMoreInfo = () => {
		if (this.props.isCheckout) {
			return null;
		}
		const selectedCustomer = this.props.selectedCustomer!;
		const stylistName = selectedCustomer.lastStylist
			? `${selectedCustomer.lastStylist.firstName} ${selectedCustomer.lastStylist.lastName}`
			: undefined;
		return (
			<Tabs defaultActiveKey="1">
				<TabPane tab="" key="1">
					<Row gutter={[16, 16]}>
						<Col span={12}>
							{!_.isEmpty(selectedCustomer.phone) ? (
								<>
									<Title style={styles.noMargin} level={5}>
										{I18n.t(_t(translations.bookingCustomerInfo.mobile))}
									</Title>
									<Text style={styles.textSize}>
										{' '}
										{StringHelper.formatPhoneNumber(selectedCustomer!.phone)}
									</Text>
								</>
							) : null}
							{!_.isEmpty(selectedCustomer.email) ? (
								<>
									<Title style={styles.noMargin} level={5}>
										{I18n.t(_t(translations.email))}
									</Title>
									<Text style={styles.textSize}>{selectedCustomer!.email}</Text>
								</>
							) : null}
							{!_.isEmpty(selectedCustomer.dateOfBirth) ? (
								<>
									<Title style={styles.noMargin} level={5}>
										{I18n.t(_t(translations.bookingCustomerInfo.birthDate))}
									</Title>
									<Text style={styles.textSize}>
										{moment(selectedCustomer!.dateOfBirth).format('LL')}
									</Text>
								</>
							) : null}
						</Col>
						<Col span={12}>
							{!_.isEmpty(selectedCustomer.numberOfVisit) ? (
								<>
									<Title style={styles.noMargin} level={5}>
										{I18n.t(_t(translations.customerList.numberOfVisit))}
									</Title>
									<Text style={styles.textSize}>
										{selectedCustomer!.numberOfVisit}
									</Text>{' '}
								</>
							) : null}
							{!_.isEmpty(selectedCustomer.lastVisit) &&
							!(selectedCustomer!.lastVisit === '0001-01-01T00:00:00') ? (
								<>
									<Title style={styles.noMargin} level={5}>
										{I18n.t(_t(translations.bookingCustomerInfo.lastVisit))}
									</Title>
									<Text style={styles.textSize}>
										{moment(selectedCustomer!.lastVisit).format('LL')}
									</Text>
								</>
							) : null}
							{!_.isEmpty(selectedCustomer.lastStylist) ? (
								<>
									<Title style={styles.noMargin} level={5}>
										{I18n.t(_t(translations.bookingCustomerInfo.lastStylist))}
									</Title>
									<Text style={styles.textSize}>{stylistName}</Text>
								</>
							) : null}
						</Col>
					</Row>
				</TabPane>
			</Tabs>
		);
	};

	// List Customers
	renderListCustomer() {
		const customers = this.state.customers.filter(
			(item) => item.id !== 'c466cb0d-d90f-4a62-80f8-dfc5e285d77a'
		);
		return (
			<>
				<Input
					allowClear
					prefix={<SearchOutlined />}
					placeholder={I18n.t(
						_t(translations.bookingCustomerInfo.searchCustomer)
					)}
					value={this.state.searchTerm}
					onChange={(e) => this.onSearch(e)}
				/>
				<Divider style={styles.divider} />
				{this.props.isSaleGiftCard ? (
					<div style={styles.saleGiftCardChooseCustomer as CSSProperties}>
						<Button
							style={styles.btnWalkin}
							onClick={() => this.handleBtnAddNew()}
						>
							{I18n.t(_t(translations.bookingCustomerInfo.addNewCustomerPlus))}
						</Button>
						<Button
							style={styles.btnWalkin}
							onClick={this.onSelectWalkinCustomer}
							icon={<UserSwitchOutlined color={Colors.PRIMARY_COLOR} />}
						>
							{I18n.t(_t(translations.checkout.walkInCustomer))}
						</Button>
					</div>
				) : (
					<Button
						style={styles.btnAddNew}
						type="link"
						onClick={() => this.handleBtnAddNew()}
					>
						{I18n.t(_t(translations.bookingCustomerInfo.addNewCustomerPlus))}
					</Button>
				)}
				<div
					className={
						this.props.isCheckout ? 'customerListMaxHeight' : 'customerList'
					}
				>
					<InfiniteScroll
						initialLoad={false}
						pageStart={0}
						loadMore={this.handleInfiniteOnLoad}
						useWindow={false}
					>
						<List
							style={styles.list}
							dataSource={customers}
							rowKey={(e) => e.id!.toString()}
							renderItem={(customer) => this.renderCustomer(customer)}
							loading={this.state.isLoading}
						></List>
					</InfiniteScroll>
				</div>
			</>
		);
	}

	renderCustomer(customer: ICustomer) {
		return (
			<List.Item
				style={styles.listItem}
				onClick={() => this.handleClickCustomer(customer)}
			>
				<List.Item.Meta
					avatar={
						<Avatar
							size="large"
							name={`${customer?.firstName} ${customer?.lastName}`}
						></Avatar>
					}
					title={
						<Typography.Text>{`${customer?.firstName} ${customer?.lastName}`}</Typography.Text>
					}
					description={
						<Typography.Text>
							{formatPhoneNumber(customer?.phone)}
						</Typography.Text>
					}
				></List.Item.Meta>
			</List.Item>
		);
	}

	renderStatus() {
		return (
			<Row>
				<Divider />

				<Col style={styles.col} span={12}>
					<Space size={'middle'}>
						<StatusTag status={this.props.currentBooking?.status!} />
						<Row
							style={{
								backgroundColor: '#F7F7F8',
								padding: 8,
								borderRadius: 10,
							}}
						>
							<InputFromTag
								bookingStatus={this.props.currentBooking?.status!}
								inputSource={this.props.currentBooking?.inputFrom!}
								bookingType={this.props.bookingType!}
							/>
							<Tag
								// color={ColorHelper.getStatusColor(
								// 	this.props.currentBooking?.status!
								// )}

								style={{ marginLeft: 8, marginRight: 0 }}
							>
								{this.props.currentBooking?.bookingType === EBookingType.WalkIn
									? 'WALK IN'
									: 'APPOINTMENT'}
							</Tag>
						</Row>
					</Space>
				</Col>
			</Row>
		);
	}
	renderFooter() {
		if (this.props.isCheckout) {
			return null;
		}
		const totalPrice = CurrencyHelper.formatPrice(
			_.sumBy(
				this.props.bookingDetails,
				(bookingDetail) =>
					bookingDetail?.price! || bookingDetail.item?.price || 0
			)
		);
		const totalDuration = TimeHelper.convertDurationToStringDetail(
			_.sumBy(
				this.props.bookingDetails,
				(bookingDetail) => bookingDetail.item?.duration!
			)
		);
		return (
			<Row gutter={[16, 16]} style={{ textAlign: 'center' }}>
				<Col span={24}>
					<Title level={4}>{`${I18n.t(
						_t(translations.bookingCustomerInfo.total)
					)} ${totalPrice} (${totalDuration})`}</Title>
				</Col>
				<Col span={24}>
					{!this.props.isDone && !this.checkIsCancel() ? (
						<Row gutter={[16, 16]} justify="end">
							{this.props.isEdit ? this.renderCheckButton() : null}
							{this.renderSubmitButton()}
						</Row>
					) : null}
				</Col>
			</Row>
		);
	}
	renderCheckButton() {
		var tittle = this.props.checkedIn
			? translations.bookingCustomerInfo.expressCheckIn
			: translations.bookingCustomerInfo.expressCheckout;
		return (
			<Col xs={24} sm={24} md={24} lg={24} xl={12}>
				<Button
					disabled={
						this.props.editing ||
						(this.props.checkedIn && !this.props.canCheckIn) ||
						(this.props.checkedOut && !this.props.canCheckOut) ||
						this.props.currentBooking?.status === BookingStatus.SUSPENDED
					}
					onClick={() => this.props.onCheckInOut!()}
					style={styles.btnConfirm}
					icon={
						this.props.checkedIn ? <ImportOutlined /> : <DollarCircleOutlined />
					}
					size="large"
					type="primary"
				>
					{I18n.t(_t(tittle))}
				</Button>
			</Col>
		);
	}
	renderSubmitButton() {
		return (
			<Col xs={24} sm={24} md={24} lg={24} xl={12}>
				<Button
					disabled={
						_.isEmpty(this.props.bookingDetails[0].item) ||
						_.isEmpty(this.props.selectedCustomer) ||
						_.isEmpty(this.props.activeTimeId)
					}
					icon={<SaveOutlined />}
					style={styles.btnConfirm}
					onClick={() => this.props.onSubmitBooking!()}
					size="large"
					type="default"
				>
					{I18n.t(_t(translations.bookingCustomerInfo.saveBooking))}
				</Button>
			</Col>
		);
	}
	renderFormInModal() {
		return (
			<>
				{!_.isEmpty(this.props.selectedCustomer) || this.state.isAdd ? (
					<FormInModal
						visible={this.state.showModal}
						title={
							this.state.isAdd
								? I18n.t(_t(translations.bookingCustomerInfo.addNewCustomer))
								: I18n.t(_t(translations.bookingCustomerInfo.editCustomer))
						}
						onCancel={() => this.handleCancelModal()}
						onOk={(values: any) => this.onFormFinish(values)}
						// isAdd={this.state.isAdd}
					>
						{this.renderFormName()}
						{/* <Divider style={styles.divider} /> */}
						{this.renderFormContact()}
						{/* <Divider style={styles.divider} /> */}
						{this.renderFormAdditionalInfo()}
					</FormInModal>
				) : (
					<Spin spinning={this.props.loading} />
				)}
			</>
		);
	}
	renderMenu = () => (
		<Menu>
			<Menu.ItemGroup
				title={I18n.t(_t(translations.bookingCustomerInfo.customer))}
			>
				<Menu.Item
					key="1"
					icon={<EditOutlined />}
					onClick={() => this.setState({ isAdd: false, showModal: true })}
				>
					{I18n.t(_t(translations.bookingCustomerInfo.editCustomer))}
				</Menu.Item>
				{this.props.location.pathname.includes('new') && (
					<Menu.Item
						icon={<DeleteOutlined />}
						key="2"
						onClick={() => this.onRemoveCustomer()}
					>
						<Text>
							{I18n.t(_t(translations.bookingCustomerInfo.removeFromBooking))}
						</Text>
					</Menu.Item>
				)}
				{!this.props.location.pathname.includes('new') && (
					<Menu.Item
						icon={<PlusOutlined />}
						key="3"
						onClick={() => this.onAdditionalBooking()}
					>
						<Text>
							{I18n.t(_t(translations.bookingCustomerInfo.additionalBooking))}
						</Text>
					</Menu.Item>
				)}
			</Menu.ItemGroup>
			{this.props.isEdit ? (
				<Menu.ItemGroup
					title={I18n.t(_t(translations.bookingCustomerInfo.actions))}
				>
					<Menu.Item
						icon={<CloseOutlined style={{ color: 'red' }} />}
						key="4"
						onClick={() => this.onCancelBooking()}
					>
						<Text strong type="danger">
							{I18n.t(_t(translations.mainBooking.cancelAppointment))}
						</Text>
					</Menu.Item>
				</Menu.ItemGroup>
			) : null}
		</Menu>
	);

	renderFormName() {
		const isAdd = this.state.isAdd;
		const selectedCustomer = this.props.selectedCustomer;

		return (
			<Row gutter={[16, 16]}>
				<Col sm={24} md={12} style={styles.noPaddingCol}>
					{FormHelper.renderFormInput(
						'firstName',
						[
							{
								required: true,
								message: I18n.t(_t(translations.staffDetail.messageFirstName)),
							},
						],
						I18n.t(_t(translations.staffDetail.firstName)),
						isAdd
							? onlyNumberPattern.test(this.state.searchTerm)
								? ''
								: this.state.searchTerm
							: selectedCustomer?.firstName,
						{},
						{
							placeholder: I18n.t(_t(translations.placeHolder.firstName)),
							value: selectedCustomer?.firstName,
							maxLength: 256,
						}
					)}
				</Col>
				<Col sm={24} md={12} style={styles.noPaddingCol}>
					{FormHelper.renderFormInput(
						'lastName',
						[
							{
								required: false,
								message: I18n.t(_t(translations.staffDetail.messageLastName)),
							},
						],
						I18n.t(_t(translations.staffDetail.lastName)),
						isAdd ? '' : selectedCustomer?.lastName,
						{},
						{
							placeholder: I18n.t(_t(translations.placeHolder.lastName)),
							maxLength: 256,
						}
					)}
				</Col>
			</Row>
		);
	}

	renderFormContact() {
		const isAdd = this.state.isAdd;
		const selectedCustomer = this.props.selectedCustomer;
		return (
			<Row gutter={[16, 16]}>
				<Col sm={24} md={12} style={styles.noPaddingCol}>
					{FormHelper.renderFormInput(
						'email',
						[
							{
								required: false,
								message: I18n.t(_t(translations.staffDetail.messageEmail)),
								type: 'email',
							},
						],
						I18n.t(_t(translations.email)),
						isAdd ? '' : selectedCustomer?.email,
						{},
						{
							placeholder: I18n.t(_t(translations.placeHolder.email)),
							maxLength: 256,
						}
					)}
				</Col>
				<Col sm={24} md={12} style={styles.noPaddingCol}>
					{FormHelper.renderFormPhoneInput(
						'phone',
						[
							({ getFieldValue }) => ({
								validator(robj, value) {
									if (value) {
										if (convertToRawPhone(value)!.length === 10) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error(
												I18n.t(
													_t(translations.staffDetail.messageCustomerPhone)
												)
											)
										);
									}
									return Promise.reject(
										new Error(
											I18n.t(_t(translations.staffDetail.messageCustomerPhone))
										)
									);
								},
							}),
						],
						I18n.t(_t(translations.staffDetail.phone)),
						isAdd
							? onlyNumberPattern.test(this.state.searchTerm)
								? this.state.searchTerm.slice(0, 10)
								: ''
							: selectedCustomer?.phone,
						{},
						{
							placeholder: I18n.t(_t(translations.placeHolder.phone)),
						}
					)}
				</Col>
			</Row>
		);
	}

	renderFormAdditionalInfo() {
		const isAdd = this.state.isAdd;
		const selectedCustomer = this.props.selectedCustomer;
		return (
			<Row gutter={[16, 16]}>
				<Col sm={24} md={12} style={styles.noPaddingCol}>
					{FormHelper.renderFormDateTimePicker(
						'birthDay',
						I18n.t(_t(translations.bookingCustomerInfo.birthDate)),
						isAdd
							? ''
							: !_.isEmpty(selectedCustomer!.dateOfBirth)
							? moment(selectedCustomer!.dateOfBirth)
							: '',
						[
							{
								required: false,
								message: I18n.t(
									_t(translations.bookingCustomerInfo.messageBirthDate)
								),
							},
						],
						{ width: '100%' }
					)}
				</Col>
				<Col sm={24} md={12} style={styles.noPaddingCol}>
					{FormHelper.renderFormSelect<any>(
						'gender',
						I18n.t(_t(translations.bookingCustomerInfo.gender)),
						ListGender,
						'type',
						'id',
						isAdd
							? ListGender[0].id
							: selectedCustomer
							? selectedCustomer.gender!.toString()
							: ListGender[0].id
					)}
				</Col>
			</Row>
		);
	}
	onRemoveCustomer() {
		this.props.removeCustomer();
		this.onChangeDebounce();
		this.setState({ searchTerm: '' });
	}
	onCancelBooking() {
		this.setState({ showCancelModal: true });
	}
	onAdditionalBooking = () => {
		const additionalCustomer = this.props.currentBooking?.customer;
		history.replace('/booking/new');
		this.props.resetBooking();
		if (!_.isEmpty(additionalCustomer)) {
			this.props.selectCurrentCustomer(additionalCustomer!);
		} else {
			showAlert(
				'Error',
				'Please choose customer before additional booking',
				'error'
			);
		}
	};

	onFormFinish = (values: any) => {
		const data: Partial<ICustomer> = {
			code: `${moment().unix()}`,
			firstName: values.firstName,
			lastName: values.lastName,
			additionalName: '',
			color: '',
			gender: parseInt(values.gender),
			userId: '',
			phone: convertToRawPhone(values.phone),
			phoneNumberConfirmed: !_.isEmpty(values.phone) ? true : false,
			email: values.email,
			emailConfirmed: false,
			shopId: this.props.currentBranch!.shopId!,
			address: {
				street: '',
				city: '',
				state: '',
				zipCode: '',
				countryCode: '',
			},
			status: 0,
			dateOfBirth: !_.isEmpty(values.birthDay) ? values.birthDay : null,
		};
		const dataEdit: Partial<ICustomer> = {
			id: this.props.selectedCustomer?.id!,
			code: `${moment().unix()}`,
			...data,
		};
		this.state.isAdd
			? this.props.addCustomer(data)
			: this.props.editCustomer(dataEdit);
		this.setState({ showModal: false });
	};

	onSelectWalkinCustomer = () => {
		this.props.selectCustomer({
			id: 'c466cb0d-d90f-4a62-80f8-dfc5e285d77a',
			additionalName: '',
			address: {},
			avarageExpensePerVisit: 0,
			code: 'WALKIN',
			color: '',
			email: '',
			emailConfirmed: true,
			firstName: 'Walk In Customer',
			firstVisit: '',
			gender: 0,
			lastExpense: 0,
			lastName: '',
			lastService: {
				id: '',
				branchIds: [],
				code: 0,
				description: '',
				duration: 0,
				employeeIds: [],
				imageUrl: '',
				itemCategoryId: '',
				imageUrls: [],
				itemPrice: 0,
				name: '',
				price: 0,
				shopId: '',
				slugName: '',
				supplyPrice: 0,
				tags: '',
				taxId: '',
			},
			lastServiceId: '',
			lastStylist: {
				branchId: '',
				code: '',
				departmentId: '',
				employeeTypeId: '',
				firstName: '',
				hireDate: new Date(),
				id: '',
				lastName: '',
				userId: '',
			},
			lastStylistId: '',
			lastVisit: '',
			newCustomer: true,
			notCome: 0,
			numberOfVisit: 0,
			phone: '',
			phoneNumberConfirmed: true,
			shopId: '',
			status: 1,
			totalExpense: 0,
			userId: '',
		});
	};

	handleCancelModal() {
		this.setState({ showModal: false, isAdd: false });
	}

	handleInfiniteOnLoad() {}

	handleBtnAddNew() {
		this.setState({ showModal: true, isAdd: true });
	}

	handleClickCustomer = (customer: ICustomer) => {
		this.props.selectCurrentCustomer(customer);
	};
	checkIsCancel() {
		return this.props.currentBooking?.status === BookingStatus.CANCELED;
	}
	onChangeDebounce() {
		this.onTableChange('ascend');
	}

	onSearch = (e: any) => {
		let searchText = e.target.value;
		this.setState({ searchTerm: searchText });
		this.onChangeDebounce();
	};
	async onTableChange(sorter: any) {
		this.setState({ isLoading: true });
		const params = {
			orderBy:
				sorter.order === 'ascend'
					? 'firstname, lastname'
					: 'firstname desc, lastname desc',
			searchText: this.state.searchTerm.toLowerCase(),
		};
		try {
			const result = (await CustomerApiService.getCustomers(
				params
			)) as IApiResponse<ICustomer[]>;
			if (result.succeeded && !_.isEmpty(result.data)) {
				this.setState({ customers: result.data! });
			} else {
				this.setState({
					isAdd: true,
					customers: [],
					// showModal: true
				});
			}
			this.setState({ isLoading: false });
		} catch (error) {}
	}
}
const mapStateToProps = (state: RootState) => ({
	currentBranch: state.BranchReducer.currentBranch,
	currentBooking: state.BookingReducer.currentBooking,
	selectedCustomer: state.BookingReducer.selectedCustomer,
	bookingDetails: state.BookingReducer.bookingDetails,
	currentAction: state.ReduxActionReducer['CUSTOMER'],
	error: state.ErrorReducer.error?.errors,
	loading: state.AppConfigReducer.showLoading,
	editing: state.BookingReducer.editing,
	activeTimeId: state.BookingReducer.timeActiveId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	selectCurrentCustomer: (customer: ICustomer) => {
		dispatch(BookingActions.selectCustomer.request(customer));
	},
	removeCustomer: () => dispatch(BookingActions.removeCustomer.request()),
	addCustomer: (newCustomer: Partial<ICustomer>) => {
		dispatch(BookingActions.addCustomer.request(newCustomer));
	},
	editCustomer: (customer: Partial<ICustomer>) => {
		dispatch(BookingActions.editCustomer.request(customer));
	},
	clearError: () => {
		dispatch(ErrorActions.clearError.request());
	},
	cancelBooking: (bookingId: string) =>
		dispatch(
			BookingActions.updateStatus.request({
				id: bookingId,
				status: BookingStatus.CANCELED,
			})
		),
	selectCustomer: (customer: ICustomer) => {
		dispatch(BookingActions.selectCustomer.request(customer));
	},
	resetBooking: () => {
		dispatch(BookingActions.resetBooking.request());
	},
});
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(CustomerBooking)
);
