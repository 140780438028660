import { PlusOutlined } from '@ant-design/icons';
import { Col, Row, Modal, Button } from 'antd';
import { IApiResponse, IService } from 'models';
import React, { useEffect, useState } from 'react';
import ServiceApi from 'services/ServiceApi';
import { Modal as ModalDelete } from 'components';
import { I18n, _t, translations } from 'utils';
import { PromotionServiceTable, ServicesModal } from './AppliedToServices';

export const ListServices = (props: {
	isAdd: boolean;
	setServices: (value: string[]) => void;
	data?: string;
}) => {
	const [showModal, setShowModal] = useState(false);
	const [promotionServices, setPromotionServices] = useState<IService[]>([]);
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [serviceToDelete, setServiceToDelete] = useState<IService>();

	const onSave = (selectedRows: IService[]) => {
		setPromotionServices([...selectedRows]);
		setShowModal(false);
	};
	const onDelete = async (value: IService) => {
		const newServices = promotionServices.filter(
			(service) => service.id !== value.id
		);
		setPromotionServices(newServices);
	};

	const getServiceById = async (id: string) => {
		const result = (await ServiceApi.getServiceById(
			id
		)) as IApiResponse<IService>;
		if (result.succeeded) {
			if (result.data) {
				return result.data;
			}
		}
		return undefined;
	};
	const fetchAllServicesSelected = async () => {
		if (props.data) {
			const servicesId = props.data.split(',');
			const services = await Promise.all(
				servicesId.map(async (id) => await getServiceById(id))
			);
			const rejectUn = services.filter(
				(service) => service !== undefined
			) as IService[];
			setPromotionServices(rejectUn);
		}
	};

	useEffect(() => {
		fetchAllServicesSelected();
	}, []);
	useEffect(() => {
		const serviceKeys = promotionServices.map((item) => item.id);
		props.setServices(serviceKeys);
	}, [promotionServices]);

	return (
		<Row gutter={[16, 16]}>
			<Col span={4}>
				<Button
					onClick={(e) => {
						setShowModal(true);
					}}
					icon={<PlusOutlined />}
					type="primary"
				>
					{I18n.t(_t(translations.addNew))}
				</Button>
			</Col>
			<Col span={24}>
				<PromotionServiceTable
					data={promotionServices}
					setServiceToDelete={(value) => setServiceToDelete(value)}
					setShowModalDelete={(value) => setShowModalDelete(value)}
				/>
				<Modal
					visible={showModal}
					title={I18n.t(_t(translations.promotionDetail.modalTitle))}
					onCancel={() => {
						setShowModal(false);
					}}
					footer={null}
					width={1000}
				>
					<ServicesModal
						onSave={(selectedRows) => onSave(selectedRows)}
						selectedRows={promotionServices}
					/>
				</Modal>
			</Col>
			<ModalDelete
				visible={showModalDelete}
				title={I18n.t(_t(translations.delete))}
				handleOk={() => {
					onDelete(serviceToDelete!);
					setShowModalDelete(false);
				}}
				handleCancel={() => {
					setShowModalDelete(false);
				}}
			>
				{I18n.t(_t(translations.servicesDetail.deleteConfirmContent))}
			</ModalDelete>
		</Row>
	);
};
