import {
	Button,
	Col,
	Row,
	Typography,
	List,
	Divider,
	Input,
	Descriptions,
} from 'antd';
import React from 'react';
import { Colors, I18n, translations, _t } from 'utils';
import _ from 'lodash';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import GlobalStyles from 'GlobalStyles';
import { IBooking, IBookingDetail, IEmployee } from 'models';
import { BookingHelper, CurrencyHelper } from 'helpers';
import { price } from 'redux/selectors/checkout';
import { ITip } from 'models/ITip';
import { IEmployeeTip } from 'models/IEmployeeTip';
import {
	DiscounterType,
	ModalType,
	PaymentType,
	PriceType,
	PromotionCode,
} from 'utils/Consts';
import { IGiftCard } from 'models/IGiftCard';
import { CheckoutActions } from 'redux/actions';
import { Dispatch } from '@reduxjs/toolkit';
import { IUpdateChildBookingAndBill } from 'models/RequestModels';
import {
	IdcardTwoTone,
	CloseOutlined,
	PlusOutlined,
	PercentageOutlined,
	CreditCardTwoTone,
	DollarCircleTwoTone,
	FileAddOutlined,
	GiftTwoTone,
	MobileTwoTone,
	MergeCellsOutlined,
} from '@ant-design/icons';
import { IPromotion } from 'models/IPromotion';
const { Text, Title } = Typography;

interface ICheckoutInformationProps
	extends ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {
	currentBooking?: IBooking;
	getModalByType(
		modalType: number,
		bookingIndex?: number,
		billIndex?: number
	): void;
	getPaymentModalByType(modalType: number): void;

	onDeleteGiftCard(id: string): void;
	paymentType: number[];

	isDone: boolean;
	onAddExtraAmount(index: number, amount: number, note: string): void;
	onDeleteBookingCombine(bookingId: string): void;
	canUpdate: boolean;
	isSaleGiftCard?: boolean;
	cardCharge: number;
	listAllBookingDetails: Partial<IBookingDetail>[];
	removePromotion: () => void;
}
interface ICheckoutInformationStates {}
class CheckoutInformation extends React.Component<
	ICheckoutInformationProps,
	ICheckoutInformationStates
> {
	state: ICheckoutInformationStates = {};

	discountByServices = this.props.listAllBookingDetails.map(
		(bookingDetail) => bookingDetail.discount === 0
	);

	PAYMENT_BUTTON = [
		{
			key: 'cash',
			title: I18n.t(_t(translations.checkout.cash)),
			icon: <DollarCircleTwoTone twoToneColor="#52c41a" />,
			callBack: () => this.props.getPaymentModalByType(PaymentType.CASH),
			type: PaymentType.CASH,
		},
		{
			key: 'giftCard',
			title: I18n.t(_t(translations.checkout.giftCard)),
			icon: <GiftTwoTone twoToneColor="#eb2f96" />,
			callBack: () => this.props.getPaymentModalByType(PaymentType.GIFT_CARD),
			type: PaymentType.GIFT_CARD,
		},
		{
			key: 'creditCard',
			title: I18n.t(_t(translations.checkout.creditCard)),
			icon: <CreditCardTwoTone />,
			callBack: () => this.props.getPaymentModalByType(PaymentType.CREDIT_CARD),
			type: PaymentType.CREDIT_CARD,
		},
		{
			key: 'payWithApp',
			title: I18n.t(_t(translations.checkout.payWithApp)),
			icon: <MobileTwoTone twoToneColor="#e57a44" />,
			callBack: () => this.props.getPaymentModalByType(PaymentType.APP),
			type: PaymentType.APP,
		},
		{
			key: 'otherMethods',
			title: I18n.t(_t(translations.checkout.otherPaymentMethods)),
			icon: <FileAddOutlined twoToneColor={Colors.PRIMARY_COLOR} />,
			callBack: () => this.props.getPaymentModalByType(PaymentType.OTHER),
			type: PaymentType.OTHER,
		},
	];

	TIP_DISCOUNT_BUTTON = [
		{
			id: ModalType.TIP,
			key: 'tip',
			title: I18n.t(_t(translations.checkout.addTip)),
			changeTitle: I18n.t(_t(translations.checkout.changeTip)),
			callBack: () => {
				this.props.getModalByType(ModalType.TIP);
			},
			icon: <PlusOutlined />,
			disable: false,
		},
		{
			id: ModalType.DISCOUNT,
			key: 'discount',
			title: I18n.t(_t(translations.checkout.addDiscount)),
			changeTitle: I18n.t(_t(translations.checkout.changeDiscount)),
			callBack: () => {
				this.props.getModalByType(ModalType.DISCOUNT);
			},
			icon: <PercentageOutlined />,
			disable: this.discountByServices?.includes(false),
		},
	];

	render() {
		console.log('this.props.promotion', this.props.promotion);

		return (
			<Col md={18} style={styles.colContainer}>
				<Col
					style={{
						padding: '16px 16px 0px 16px',
						marginBottom: 120,
					}}
				>
					<Col>{this.renderTopButton()}</Col>
					<Col style={{ height: '100%', overflowY: 'auto', paddingBottom: 10 }}>
						{!_.isEmpty(this.props.listBookingCombine) &&
							this.renderCustomerInfo(this.props.currentBooking!)}
						{this.renderListBookingDetails(
							this.props.currentBooking?.bookingDetails || []
						)}
						{this.renderListGiftCard()}
						{this.renderListBookingCombine()}
					</Col>
				</Col>
				<div
					style={{
						background: 'white',
						padding: 16,
						position: 'absolute',
						bottom: 0,
						left: 0,
						right: 0,
					}}
				>
					{this.renderPaymentButton()}
				</div>
			</Col>
		);
	}

	renderPaymentButton = () => {
		const totalAmountPayment = _.sumBy(
			this.props.listPaymentType,
			(x) => x.amount || x.cardCharge || 0
		);
		return (
			<>
				<Row gutter={[16, 16]} align="bottom">
					{this.PAYMENT_BUTTON.map((button) => {
						return (
							<Col key={button.key} md={6}>
								<Button
									icon={button.icon}
									disabled={
										this.props.isDone ||
										(totalAmountPayment + this.props.cardCharge! >
											this.props.price(PriceType.BALANCE)! &&
											!this.props.paymentType.includes(button.type))
									}
									style={{ ...styles.maxWidth, minWidth: 0 }}
									onClick={button.callBack}
									type={
										this.props.paymentType.includes(button.type)
											? 'primary'
											: 'default'
									}
								>
									{this.getButtonTitle(button)}
								</Button>
							</Col>
						);
					})}
				</Row>
			</>
		);
	};
	getButtonTitle = (button: any) => {
		let isGiftCard = false;
		if (
			!_.isEmpty(
				this.props.listPaymentType.filter(
					(x) => x.paymentType === PaymentType.GIFT_CARD
				)
			)
		) {
			isGiftCard = true;
		}
		return button.type === PaymentType.GIFT_CARD && isGiftCard
			? 'Add More'
			: button.title;
	};
	renderCombineButton = () => {
		if (this.props.isSaleGiftCard || this.props.isDone) {
			return null;
		}
		return (
			<>
				{/* {!this.props.isDone && (
					<Row gutter={[16, 16]}>
						<Col sm={24} md={12} style={{ alignSelf: 'center' }}>
							{this.props.canUpdate && (
								<Typography.Text
									style={{ fontSize: 12, alignSelf: 'end', color: 'red' }}
								>
									You must update all booking before pay them.
								</Typography.Text>
							)}
						</Col> */}
				<Col>
					<Button
						icon={<MergeCellsOutlined />}
						onClick={() => this.props.getModalByType(ModalType.COMBINE_BILl)}
						style={{
							minWidth: 150,
							backgroundColor: '#01A7C2',
							color: 'white',
						}}
					>
						{I18n.t(_t(translations.checkout.combineBill))}
					</Button>
				</Col>
				{/* </Row>
				)} */}
			</>
		);
	};
	renderTopButton = () => {
		return (
			<>
				<Row gutter={[16, 16]} align="top" justify="space-between">
					<Row gutter={[16, 16]} style={{ padding: '0 8px' }}>
						<Col>
							<Button
								disabled={this.props.isDone}
								icon={<IdcardTwoTone />}
								onClick={() =>
									this.props.getModalByType(ModalType.SALE_GIFT_CARD)
								}
								style={{ minWidth: 150 }}
							>
								{I18n.t(
									_t(
										this.props.isSaleGiftCard
											? translations.checkout.addNewGiftCard
											: translations.checkout.saleGiftCard
									)
								)}
							</Button>
						</Col>
						{!this.props.isSaleGiftCard &&
							this.TIP_DISCOUNT_BUTTON.map((button) => {
								return (
									<Col key={button.key}>
										<Button
											key={button.key}
											id={`${button.id}`}
											icon={button.icon}
											style={{ minWidth: 150 }}
											disabled={this.checkDisable(button)}
											onClick={button.callBack}
										>
											{this.getTitle(button)}
										</Button>
									</Col>
								);
							})}
						{!this.props.isSaleGiftCard && (
							<>
								<Col>
									<Button
										disabled={
											this.props.isDone ||
											// this.discountByServices?.includes(false) ||
											this.isExistPromotion()
											// ||
											// (this.props.discount !== 0 &&
											// 	!_.isNumber(this.props.discount))
										}
										icon={<IdcardTwoTone />}
										onClick={() =>
											this.props.getModalByType(ModalType.APPLIED_PROMOTION)
										}
										style={{ minWidth: 150 }}
									>
										{I18n.t(_t(translations.checkout.promotion))}
									</Button>
								</Col>
								<Col>
									<Button
										disabled={
											this.props.isDone ||
											// this.discountByServices?.includes(false) ||
											this.isExistPromotion()
											// ||
											// (this.props.discount !== 0 &&
											// 	!_.isNumber(this.props.discount))
										}
										icon={<IdcardTwoTone />}
										onClick={() =>
											this.props.getModalByType(ModalType.INPUT_COUPON)
										}
										style={{ minWidth: 150 }}
									>
										{I18n.t(_t(translations.checkout.couponCode))}
									</Button>
								</Col>
							</>
						)}
					</Row>
					{this.renderCombineButton()}
				</Row>
				<Divider />
			</>
		);
	};

	renderListBookingCombine = () => {
		const { listBookingCombine } = this.props;
		if (_.isEmpty(listBookingCombine)) {
			return null;
		}
		return (
			<>
				{listBookingCombine?.map((booking: Partial<IBooking>, index) => {
					return (
						<Col key={booking.id}>
							<Divider />
							{this.renderCustomerInfo(booking, true, index)}
							{this.renderListBookingDetails(booking.bookingDetails!, index)}
						</Col>
					);
				})}
			</>
		);
	};

	renderListBookingDetails = (
		bookingDetail: Partial<IBookingDetail>[],
		billIndex?: number
	) => {
		if (this.props.isSaleGiftCard) {
			return null;
		}
		return (
			<List
				dataSource={bookingDetail}
				renderItem={(item: Partial<IBookingDetail>, index: number) =>
					this.renderItemBookingDetail(item, index, billIndex)
				}
			/>
		);
	};

	renderListGiftCard = () => {
		if (_.isEmpty(this.props.listGiftCard)) {
			return null;
		}
		return (
			<List
				dataSource={this.props.listGiftCard}
				renderItem={this.renderItemGiftCard}
			/>
		);
	};

	renderItemGiftCard = (giftCard: Partial<IGiftCard>) => {
		return (
			<Row gutter={[16, 2]} key={giftCard.id} style={{ width: '100%' }}>
				<Col xs={24} sm={24} md={21} lg={20}>
					<Row style={{ ...styles.itemBookingDetail }}>
						<Col span={1} style={styles.count}>
							<Title level={5} style={styles.text14}>
								1
							</Title>
						</Col>
						<Col span={23}>
							<Row justify="space-between">
								<Col flex="auto">
									<Title style={styles.titleNoMargin} level={5}>
										{I18n.t(_t(translations.checkout.giftCard))}/{' '}
										<Text type="secondary">
											{`${I18n.t(
												_t(translations.checkout.balance)
											)}: ${CurrencyHelper.formatPrice(giftCard.balance)}`}
										</Text>
									</Title>
								</Col>
								<Col style={styles.priceContainer}>
									<Title style={styles.titleNoMargin} level={5}>
										{CurrencyHelper.formatPrice(giftCard.balance)}
									</Title>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
				<Col
					xs={24}
					sm={24}
					md={3}
					lg={4}
					style={{ ...styles.itemBookingDetail }}
				>
					<Button
						type="text"
						disabled={this.props.isDone}
						icon={<CloseOutlined />}
						style={{ ...styles.buttonExtra, color: 'red' }}
						onClick={() => {
							this.props.onDeleteGiftCard(giftCard.id!);
							this.props.removePromotion();
						}}
					>
						{I18n.t(_t(translations.remove))}
					</Button>
				</Col>
			</Row>
			// <Row key={giftCard.id} style={styles.itemBookingDetail}>
			// 		<Col span={1} style={styles.count}>
			// 			<Title level={4}>1</Title>
			// 		</Col>
			// 		<Col span={23}>
			// 			<Row justify="space-between">
			// 				<Col flex="auto">
			// 					<Title style={styles.titleNoMargin} level={5}>
			// 						{I18n.t(_t(translations.checkout.giftCard))}
			// 					</Title>
			// 					<Text type="secondary">
			// 						{`${I18n.t(_t(translations.checkout.Balance))}: ${
			// 							giftCard.balance
			// 						}`}
			// 					</Text>
			// 				</Col>
			// 				<Col style={styles.priceContainer}>
			// 					<Title style={styles.titleNoMargin} level={5}>
			// 						${giftCard.balance}
			// 					</Title>
			// 				</Col>
			// 				<CloseOutlined
			// 					onClick={() => {
			// 						this.props.onDeleteGiftCard(giftCard.id!);
			// 					}}
			// 					style={{ ...styles.icClose, right: -10, top: -10 }}
			// 				/>
			// 			</Row>
			// 		</Col>
			// 	</Row>
		);
	};

	renderCustomerInfo = (
		booking: Partial<IBooking>,
		showButton?: boolean,
		index?: number
	) => {
		return (
			<Row gutter={[16, 2]} style={{ width: '100%' }}>
				<Col xs={24} sm={24} md={21} lg={20}>
					<Row justify="space-between">
						{showButton && (
							<Col
								md={3}
								lg={4}
								style={{ ...styles.itemBookingDetail, padding: 0 }}
							>
								<Button
									type="primary"
									disabled={
										this.props.listBookingCombine[index!].totalExtraAmount ===
										_.sumBy(
											this.props.listBookingCombine[index!].bookingDetails.map(
												(e) => e.extraAmount
											)
										)
										// 	&&
										// this.props.listBookingCombine[index!].promotionDiscount ===
										// 	_.sumBy(
										// 		this.props.listBookingCombine[
										// 			index!
										// 		].bookingDetails.map((x) => x.promotionDiscount)
										// 	)
									}
									// icon={<CloseOutlined />}
									style={{
										...styles.buttonExtra,
									}}
									onClick={() => this.updateChildBookingAndBill(booking)}
								>
									Update
								</Button>
							</Col>
						)}
					</Row>
				</Col>
				{showButton && (
					<Col md={3} lg={4} style={{ ...styles.itemBookingDetail }}>
						<Button
							type="text"
							disabled={this.props.isDone}
							icon={<CloseOutlined />}
							style={{ ...styles.buttonExtra, color: 'red' }}
							onClick={() => this.props.onDeleteBookingCombine(booking.id!)}
						>
							{I18n.t(_t(translations.checkout.deleteBill))}
						</Button>
					</Col>
				)}
			</Row>
		);
	};

	renderItemBookingDetail = (
		item: Partial<IBookingDetail>,
		index: number,
		billIndex?: number
	) => {
		return (
			<Row
				gutter={[16, 2]}
				key={item.id}
				style={{ width: '100%', alignItems: 'center' }}
			>
				<Col xs={24} sm={24} md={21} lg={20}>
					<Row style={styles.itemBookingDetail}>
						<Col span={1} style={styles.count}>
							<Title level={5} style={styles.text14}>
								{item?.quantity}
							</Title>
						</Col>
						<Col span={23} style={{ alignSelf: 'center' }}>
							{this.renderClassicInfo(item)}
							{/* {this.renderInput(item)} */}
						</Col>
					</Row>
				</Col>
				{item.extraAmount && item.extraAmount !== 0 ? null : (
					<Col
						xs={24}
						sm={24}
						md={3}
						lg={4}
						style={{ ...styles.itemBookingDetail, padding: 0 }}
					>
						<Button
							type="text"
							disabled={this.props.isDone}
							icon={<PlusOutlined />}
							style={styles.buttonExtra}
							onClick={() =>
								this.props.getModalByType(
									ModalType.EXTRA_AMOUNT,
									index,
									billIndex
								)
							}
						>
							{I18n.t(_t(translations.checkout.addExtra))}
						</Button>
					</Col>
				)}
				{item.extraAmount && item.extraAmount !== 0 ? (
					<>
						<Col offset={2} xs={22} sm={22} md={19} lg={18}>
							<Row style={styles.itemBookingDetail}>
								<Col span={1}></Col>
								<Col span={23}>{this.renderExtraAmount(item)}</Col>
							</Row>
						</Col>
						<Col
							xs={24}
							sm={24}
							md={3}
							lg={4}
							style={{ ...styles.itemBookingDetail, padding: 0 }}
						>
							<Button
								type="text"
								disabled={this.props.isDone}
								icon={<CloseOutlined />}
								style={{ ...styles.buttonExtra, color: 'red' }}
								onClick={() => {
									typeof billIndex === 'number'
										? this.props.deleteExtraAmountBookingCombined(
												billIndex,
												index
										  )
										: this.props.onAddExtraAmount(index, 0, '');
									this.props.removePromotion();
								}}
							>
								{I18n.t(_t(translations.remove))}
							</Button>
						</Col>
					</>
				) : null}
			</Row>
		);
	};
	renderClassicInfo = (bookingDetail: Partial<IBookingDetail>) => {
		const stylistName = `${bookingDetail.stylist?.firstName || ''} ${
			bookingDetail.stylist?.lastName || ''
		}`;

		return (
			<Row justify="space-between" align="middle">
				<Col flex="auto">
					<Row>
						<Title style={styles.titleNoMargin} level={5}>
							{bookingDetail.item?.name}
						</Title>
						<Text
							strong
							style={{ marginLeft: 16, color: 'grey', fontWeight: 'lighter' }}
						>
							( with {stylistName})
						</Text>
					</Row>

					{/* <Text type="secondary">{stylistName}</Text> */}
				</Col>

				<Col style={styles.priceContainer}>
					<Title style={styles.titleNoMargin} level={5}>
						{CurrencyHelper.formatPrice(
							(this.props.promotion?.promotionTypeCode !==
								PromotionCode.TOTAL_BOOKING &&
								bookingDetail.promotionDiscount &&
								bookingDetail.promotionDiscount !== 0) ||
								bookingDetail.discount
								? BookingHelper.calculateBookingDetailAmount(bookingDetail) < 0
									? 0
									: BookingHelper.calculateBookingDetailAmount(bookingDetail)
								: bookingDetail.price
						)}{' '}
						{(bookingDetail.discount && bookingDetail.discount !== 0) ||
						(this.props.promotion?.promotionTypeCode !==
							PromotionCode.TOTAL_BOOKING &&
							bookingDetail.promotionDiscount &&
							bookingDetail.promotionDiscount !== 0) ? (
							<Text type="secondary" style={styles.textLineThrough}>
								{CurrencyHelper.formatPrice(bookingDetail.price)}
							</Text>
						) : null}
					</Title>
				</Col>
			</Row>
		);
	};
	renderExtraAmount = (bookingDetail: Partial<IBookingDetail>) => {
		return (
			<Row justify="space-between">
				<Col flex="auto">
					<Title style={styles.titleNoMargin} level={5}>
						{bookingDetail.note}
					</Title>
				</Col>
				<Col style={styles.priceContainer}>
					<Title style={styles.titleNoMargin} level={5}>
						{CurrencyHelper.formatPrice(bookingDetail.extraAmount)}
					</Title>
				</Col>
			</Row>
		);
	};
	renderInput = (bookingDetail: IBookingDetail) => {
		return (
			<Row gutter={[16, 16]}>
				<Col sm={24} md={6}>
					<Title level={5}>{I18n.t(_t(translations.price))}</Title>
					<Input
						prefix={<Text disabled>$</Text>}
						value={bookingDetail?.price}
						disabled
						style={styles.maxWidth}
					/>
				</Col>
				<Col sm={24} md={14}>
					<Title level={5}>{I18n.t(_t(translations.checkout.stylist))}</Title>
					{/* {this.renderStylist()} */}
				</Col>
			</Row>
		);
	};

	// renderTotal() {
	// 	return (
	// 		<>
	// 			<Row justify="space-between" align="middle">
	// 				<Title level={5}>{I18n.t(_t(translations.checkout.total))}</Title>
	// 				<Title level={5} style={styles.textInline}>
	// 					{CurrencyHelper.formatBalance(this.props.price(PriceType.TOTAL))}
	// 				</Title>
	// 			</Row>
	// 			<Divider style={styles.divider} />
	// 		</>
	// 	);
	// }
	updateChildBookingAndBill = (booking: Partial<IBooking>) => {
		let newBooking = Object.assign({}, booking);
		let billing = Object.assign(
			{},
			_.find(
				this.props.listBilling,
				(billing) => billing.bookingId === booking.id
			)!
		);
		// if (!_.isEmpty(this.props.promotion)) {
		// 	// newBooking.bookingDetails?.forEach(x => x.promotionDiscount = 0)
		// 	newBooking.promotionIds = this.props.promotion?.id
		// 	newBooking.couponCodes = this.props.promotion?.couponCodes
		// 	billing.promotionIds = this.props.promotion?.id
		// 	billing.couponCodes = this.props.promotion?.couponCodes
		// }

		// newBooking.promotionIds = '';
		// newBooking.couponCodes = '';
		// newBooking.promotionDiscount = 0;
		// newBooking.bookingDetails = newBooking.bookingDetails?.map((x) => {
		// 	return { ...x, promotionDiscount: 0 };
		// });

		// billing.promotionIds = '';
		// billing.couponCodes = '';
		// billing.promotionDiscount = 0;
		// billing.billDetails = billing.billDetails?.map((x) => {
		// 	return { ...x, promotionDiscount: 0 };
		// });

		this.props.updateChildBookingAndBill({ booking: newBooking, billing });
	};
	checkDisable = (button: any) => {
		const disable = button.disable || this.props.isDone;
		if (button.id === ModalType.DISCOUNT) {
			return !_.isEmpty(this.props.listBookingCombine)
				? this.discountByServices.includes(false)
				: disable;
		}
		return (
			(this.props.isDone &&
				(this.props.tip?.totalTip || 0) +
					_.sumBy(this.props.tip?.tips, (x) => x.tipAmount) >
					0) ||
			(this.props.isDone && _.isEmpty(this.props.billId))
		);
	};
	getTitle = (item: any) => {
		let isChange = false;
		if (item.id === ModalType.DISCOUNT) {
			isChange =
				this.props.discountPercent !== 0 ||
				!_.isEmpty(this.props.discountPercent);
		}
		if (item.id === ModalType.TIP) {
			if (this.props.tip?.totalTip) {
				isChange = this.props.tip?.totalTip !== 0;
			} else {
				isChange = _.sumBy(this.props.tip?.tips, (tip) => tip.tipAmount) !== 0;
			}
		}
		return isChange ? item.changeTitle : item.title;
	};
	isExistPromotion = () => {
		return this.props.currentBooking?.bookingDetails
			?.map((x) => x.promotionDiscount === 0)
			.includes(false)
			? true
			: false;
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
	addExtraAmountBookingCombined: (
		billIndex: number,
		bookingItemIndex: number,
		amount: number,
		note: string
	) =>
		dispatch(
			CheckoutActions.addExtraAmount.request({
				billIndex,
				bookingItemIndex,
				amount,
				note,
			})
		),
	deleteExtraAmountBookingCombined: (
		billIndex: number,
		bookingItemIndex: number
	) =>
		dispatch(
			CheckoutActions.deleteExtraAmount.request({
				billIndex,
				bookingItemIndex,
			})
		),
	updateChildBookingAndBill: (request: IUpdateChildBookingAndBill) =>
		dispatch(CheckoutActions.updateChildBookingAndBill.request(request)),
});
const mapStateToProps = (state: RootState) => ({
	discountPercent: state.CheckoutReducer.discountPercent,
	discount: state.CheckoutReducer.discount,
	tip: state.CheckoutReducer.tip,
	price: (priceType: PriceType) => price(state, priceType),
	listGiftCard: state.CheckoutReducer.listGiftCard,
	listBookingCombine: state.CheckoutReducer.listBookingCombine,
	listBilling: state.CheckoutReducer.listBilling,
	listPaymentType: state.CheckoutReducer.listPaymentType,
	promotion: state.CheckoutReducer.promotion,
	billId: state.CheckoutReducer.billId,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CheckoutInformation);

const styles = {
	colContainer: {
		backgroundColor: '#F7F7F8',
		borderWidth: 0,
		borderRightWidth: 0.1,
		borderStyle: 'solid',
		borderColor: '#cdcdcd',
		height: 'calc(100vh - 64px)',
	} as React.CSSProperties,

	count: {
		textAlign: 'center',
		borderWidth: 0,
		borderLeftWidth: 1.5,
		borderStyle: 'solid',
		minHeight: '100%',
		fontSize: 14,
	} as React.CSSProperties,
	itemBookingDetail: {
		...GlobalStyles.boxShadow,
		backgroundColor: 'white',
		borderRadius: 2,
		margin: '4px 0px',
		alignSelf: 'center',
		padding: 5,
	} as React.CSSProperties,
	priceContainer: { textAlign: 'end', marginRight: 16 } as React.CSSProperties,
	titleNoMargin: { marginBottom: 0, fontSize: 14 },
	textLineThrough: { textDecorationLine: 'line-through' },
	maxWidth: { width: '100%' },
	divider: { width: '100%', margin: '8px 0' },

	buttonExtra: {
		width: '100%',
		height: '100%',
		margin: 0,
		fontWeight: 600,
		padding: 4,
	},
	text14: {
		marginBottom: 0,
		fontSize: 14,
	} as React.CSSProperties,
	text12: {
		fontSize: 12,
	} as React.CSSProperties,
};
