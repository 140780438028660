import { Country, State, City } from 'country-state-city';
var zipcodes = require('zipcodes');

interface IZipCodeLocation {
	zip: string;
	latitude: number;
	longitude: number;
	city: string;
	state: string;
	country: string;
}
export interface IState {
	name: string;
	isoCode: string;
	latitude?: string | null;
	longitude?: string | null;
}
export interface ICity {
	name: string;
	stateCode: string;
	latitude?: string | null;
	longitude?: string | null;
}

export const getAllStates = (): IState[] => {
	return State.getStatesOfCountry('US');
};

export const getAllCity = (state: string): ICity[] => {
	return City.getCitiesOfState('US', state);
};

export const getLocationFromZip = (
	zipCode: string
): IZipCodeLocation | undefined => {
	const location = zipcodes.lookup(zipCode);
	if (location) {
		return location as IZipCodeLocation;
	}
	return undefined;
};
export const getZipFromLocation = (stateCode?: string, city?: string) => {
	if (!stateCode || !city) {
		return null;
	}
	const zipCode = zipcodes.lookupByName(city, stateCode);
	return zipCode;
};
