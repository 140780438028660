import React, { Component, ReactElement } from 'react';
import {
	notification,
	Card,
	Row,
	Col,
	Typography,
	Input,
	Divider,
	Radio,
	Switch,
	Button,
} from 'antd';
import { IComponentProps } from 'components';
import { I18n, _t, translations, Const } from 'utils';
import { Dispatch } from 'redux';
import { BranchActions, ErrorActions, SettingActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import { IBranch } from 'models/IBranch';
import GlobalStyles from 'GlobalStyles';
import styles from './styles';
import {
	SlidersOutlined,
	PrinterOutlined,
	ShopOutlined,
	ReloadOutlined,
	IssuesCloseOutlined,
} from '@ant-design/icons';
import { AlertHelper } from 'helpers';
import _ from 'lodash';
import ModalHelper from 'helpers/modalHelper';
import { electron } from 'ipc';
import AppModal from 'components/Modal';
const { Title, Link } = Typography;

interface ISettingRouteState {
	fromOurWeb: boolean;
}

interface ISettingProps
	extends IComponentProps<void, ISettingRouteState>,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}
interface ISettingState {}

class SettingPage extends Component<ISettingProps, ISettingState> {
	state = {
		showResetModal: false,
		showSettingModal: false,
		showReceiptSettingModal: false,
		printerIp: this.props.printerIp,
		printerPort: this.props.printerPort,
		printerConnectionType: this.props.printerConnectionType,
		receiptType: this.props.receiptType,
		isEnableTicketPrinting: this.props.isEnableTicketPrinting,
	};
	componentDidMount() {}

	componentDidUpdate(prevProps: ISettingProps) {
		if (prevProps.branchAction !== this.props.branchAction) {
			if (this.props.branchAction?.includes('SUCCESS')) {
				this.onSuccess();
			} else {
				if (this.props.branchAction?.includes('FAILED')) {
					this.onError();
				}
			}
		}
	}

	componentWillUnmount() {}

	render() {
		const iconSize = 30;
		return (
			<>
				<Row style={{ padding: 16 }}>
					{/* <Col lg={0} xl={6} /> */}
					<Col span={24}>
						<Row style={{ width: '100%' }}>
							<Col style={{ padding: 8 }} span={24} lg={6}>
								{this.renderItem(
									I18n.t(_t(translations.setting.branchSetting)),
									<SlidersOutlined style={{ fontSize: iconSize }} />,
									() => {
										this.props.history.push(
											`${this.props.location.pathname}/branch`,
											{
												fromOurWeb: true,
											}
										);
									}
								)}
							</Col>
							<Col style={{ padding: 8 }} span={24} lg={6}>
								{this.renderItem(
									I18n.t(_t(translations.setting.printerSetting)),
									<PrinterOutlined style={{ fontSize: iconSize }} />,
									() => {
										this.onOpenPrinterSetting();
									}
								)}
							</Col>
							<Col style={{ padding: 8 }} span={24} lg={6}>
								{this.renderItem(
									I18n.t(_t(translations.setting.receiptTicketPrint)),
									<PrinterOutlined style={{ fontSize: iconSize }} />,
									() => {
										this.onOpenReceiptSetting();
									}
								)}
							</Col>
							<Col style={{ padding: 8 }} span={24} lg={6}>
								{this.renderItem(
									I18n.t(_t(translations.setting.miniCMSSetting)),
									<SlidersOutlined style={{ fontSize: iconSize }} />,
									() => {
										this.props.history.push(
											`${this.props.location.pathname}/mini-cms`,
											{
												fromOurWeb: true,
											}
										);
									}
								)}
							</Col>
							<Col style={{ padding: 8 }} span={24} lg={6}>
								{this.renderItem(
									I18n.t(_t(translations.setting.shopSetting)),
									<ShopOutlined style={{ fontSize: iconSize }} />,
									() => {
										this.props.history.push(
											`${this.props.location.pathname}/shop`,
											{
												fromOurWeb: true,
											}
										);
									}
								)}
							</Col>
							<Col style={{ padding: 8 }} span={24} lg={6}>
								{this.renderItem(
									'Close Online Booking',
									<IssuesCloseOutlined style={{ fontSize: iconSize }} />,
									() => {
										this.props.history.push(`/close-online-booking`, {
											fromOurWeb: true,
										});
									}
								)}
							</Col>
							{electron && (
								<Col style={{ padding: 8 }} span={24} lg={6}>
									{this.renderItem(
										'Reset System',
										<ReloadOutlined style={{ fontSize: iconSize }} />,
										() => {
											this.setState({ showResetModal: true });
										}
									)}
								</Col>
							)}
						</Row>
					</Col>
					<Col lg={0} xl={6} />
				</Row>
				{this.renderPrinterModal()}
				{this.renderReceiptModal()}
				<AppModal
					visible={this.state.showResetModal}
					title={'Reset System'}
					handleOk={() => {
						if (electron) {
							electron.ipcRenderer.resetSystem();
						}
					}}
					OKString="Reset"
					handleCancel={() => this.setState({ showResetModal: false })}
				>
					Are you sure want to reset system?
				</AppModal>
			</>
		);
	}

	renderPrinterModal() {
		const options = [
			{ label: 'Network Connection', value: Const.PRINTER_CONNECTION.NETWORK },
			{ label: 'USB Connection', value: Const.PRINTER_CONNECTION.USB },
		];
		return (
			<ModalHelper
				title={I18n.t(_t(translations.setting.printer))}
				visible={this.state.showSettingModal}
				onOk={() => this.onSavePrinter()}
				onCancel={() => this.setState({ showSettingModal: false })}
			>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Radio.Group
						options={options}
						onChange={(e) => this.onChangePrinterConnectionType(e.target.value)}
						value={this.state.printerConnectionType}
						optionType="button"
						buttonStyle="solid"
						style={{ marginBottom: 8 }}
					/>
					{this.state.printerConnectionType ===
					Const.PRINTER_CONNECTION.NETWORK ? (
						<>
							<Typography.Title level={5}>
								{I18n.t(_t(translations.setting.printerIP))}
							</Typography.Title>
							<Input
								value={this.state.printerIp}
								onChange={(e) => this.setState({ printerIp: e.target.value })}
								placeholder="Eg 192.168.123.100"
							/>
							<Divider />
							<Typography.Title level={5}>
								{I18n.t(_t(translations.setting.printerPort))}
							</Typography.Title>
							<Input
								value={this.state.printerPort}
								onChange={(e) => this.setState({ printerPort: e.target.value })}
							/>
						</>
					) : null}
					{electron && (
						<Button
							onClick={() => {
								if (electron) {
									electron.ipcRenderer.configurePrinter();
								}
							}}
							type="primary"
							size="large"
							style={{ alignSelf: 'flex-end', marginTop: 16 }}
						>
							Configure Printer
						</Button>
					)}
				</div>
			</ModalHelper>
		);
	}

	renderReceiptModal() {
		const options = [
			{ label: '1 Receipt(Salon)', value: Const.RECEIPT_PRINT_TYPE.ONE },
			{
				label: '2 Receipts(Salon - Customer)',
				value: Const.RECEIPT_PRINT_TYPE.TWO,
			},
			{ label: 'No Receipt', value: Const.RECEIPT_PRINT_TYPE.NONE },
		];
		const { isEnableTicketPrinting } = this.props;
		return (
			<ModalHelper
				title={I18n.t(_t(translations.setting.receiptTicketPrint))}
				visible={this.state.showReceiptSettingModal}
				onOk={() => this.onSaveReceipt()}
				onCancel={() => this.setState({ showReceiptSettingModal: false })}
			>
				<div style={styles.ticketPrintingContainer}>
					<Typography.Title level={5}>
						{I18n.t(_t(translations.setting.enableTicketPrinting))}
					</Typography.Title>
					<Switch
						defaultChecked={isEnableTicketPrinting}
						onChange={(checked) =>
							this.setState({ isEnableTicketPrinting: checked })
						}
					/>
				</div>
				<Radio.Group
					options={options}
					onChange={(e) => this.onChangeReceiptType(e.target.value)}
					value={this.state.receiptType}
					optionType="button"
					buttonStyle="solid"
					style={{ marginBottom: 8 }}
				/>
			</ModalHelper>
		);
	}

	renderItem(title: string, icon: ReactElement, onClick: () => void) {
		return (
			<Link onClick={onClick} style={styles.cardLink}>
				<Card
					style={styles.cardOption}
					onMouseEnter={(e) => {
						e.currentTarget.style.boxShadow = styles.hoverBoxShadow.boxShadow!;
					}}
					onMouseLeave={(e) => {
						e.currentTarget.style.boxShadow = GlobalStyles.boxShadow.boxShadow!;
					}}
					title={<Title level={5}>{title}</Title>}
					extra={icon}
				/>
			</Link>
		);
	}

	onOpenPrinterSetting() {
		this.setState({ showSettingModal: true });
	}
	onOpenReceiptSetting() {
		this.setState({ showReceiptSettingModal: true });
	}

	onSavePrinter() {
		this.props.savePrinterIP({
			printerIp: this.state.printerIp || '',
			printerPort: !_.isElement(this.state.printerPort)
				? this.state.printerPort
				: '9100',
			type: this.state.printerConnectionType,
		});
		this.setState({ showSettingModal: false });
		AlertHelper.showSuccess(I18n.t(_t(translations.setting.saveSuccess)));
	}

	onSaveReceipt() {
		this.props.saveReceiptType(this.state.receiptType);
		// this.props.setEnableTicketPrinting(this.state.isEnableTicketPrinting || false);
		this.props.updateBranch({
			...this.props.currentBranch,
			isEnableTicketPrinting: this.state.isEnableTicketPrinting,
		});
	}

	onSuccess() {
		if (this.props.branchAction === BranchActions.updateBranch.successName) {
			notification['success']({
				message: 'Success',
				description: I18n.t(_t(translations.branchSetting.updateBranchSuccess)),
			});
			this.props.getBranchesByShopId(this.props.shopId!);
			this.setState({ showReceiptSettingModal: false });
			// AlertHelper.showSuccess(I18n.t(_t(translations.setting.saveSuccess)));
		}
	}

	onChangePrinterConnectionType(type: Const.PRINTER_CONNECTION) {
		this.setState({ printerConnectionType: type });
	}
	onChangeReceiptType(type: Const.RECEIPT_PRINT_TYPE) {
		this.setState({ receiptType: type });
	}

	onError() {
		notification['error']({
			message: 'Error',
			description:
				this.props.error?.Text ||
				I18n.t(_t(translations.setting.updateBranchFail)),
			onClose: this.props.clearError,
		});
	}
}

const mapStateToProps = (state: RootState) => ({
	isLoading: state.AppConfigReducer.showLoading,
	error: state.ErrorReducer.error?.message[0],
	currentBranch: state.BranchReducer.currentBranch,
	branchAction: state.ReduxActionReducer.BRANCH,
	shopId: state.UserReducer.user?.shopId,
	printerIp: state.SettingReducer.printerIp,
	printerPort: state.SettingReducer.printerPort,
	printerConnectionType: state.SettingReducer.printerConnectionType,
	receiptType: state.SettingReducer.receiptPrintType,
	isEnableTicketPrinting:
		state.BranchReducer.currentBranch?.isEnableTicketPrinting,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	clearError: () => {
		dispatch(ErrorActions.clearError.request());
	},
	updateBranch: (request: Partial<IBranch>) => {
		dispatch(BranchActions.updateBranch.request(request));
	},
	getBranchesByShopId: (shopId: string) => {
		dispatch(BranchActions.getBranchesByShopId.request(shopId));
	},
	saveReceiptType: (type: Const.RECEIPT_PRINT_TYPE) =>
		dispatch(SettingActions.saveReceipt.request({ type })),
	savePrinterIP: (printer: {
		printerIp: string;
		printerPort: string;
		type?: Const.PRINTER_CONNECTION;
	}) => dispatch(SettingActions.savePrinterIP.request(printer)),
	setEnableTicketPrinting: (enable: boolean) => {
		dispatch(SettingActions.setEnableTicketPrinting.request(enable));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingPage);
