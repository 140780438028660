import { Table, Typography } from 'antd';
import {
	IPayrollDetail,
	IPayrollDetailDetail,
	IPayrollOverview,
	IPayrollOverviewDetail,
} from 'models';
import React, { Component } from 'react';
import moment from 'moment';
import { I18n, translations, _t } from 'utils';
import { CurrencyHelper } from 'helpers';
const { Title, Text } = Typography;

interface IDetailTableProps {
	isDetail: boolean;
	payrollDetailData: IPayrollDetail | IPayrollOverview;
	selectedBookingIndex?: number;
}

interface IDetailTableState {}

class DetailTable extends Component<IDetailTableProps, IDetailTableState> {
	render() {
		return (
			<Table<IPayrollDetailDetail | IPayrollOverviewDetail>
				bordered
				dataSource={this.getMappedData()}
				size="large"
				pagination={false}
				summary={() => this.renderSummary()}
				scroll={{ x: true }}
			>
				{this.renderDateCol()}
				{this.props.isDetail ? this.renderTurnCol() : this.renderWeekdayCol()}
				{this.props.isDetail ? this.renderWeekdayCol() : this.renderTurnCol()}
				{this.renderIncomeCol()}
				{this.renderSalaryCol()}
				{this.renderCommisionCol()}
				{this.renderTipsCol()}
				{this.renderDiscountCol()}
				{this.renderChargeFeeCol()}
				{this.renderRefundCol()}
				{this.renderSalaryPayCol()}
			</Table>
		);
	}

	renderDateCol() {
		return this.props.isDetail ? (
			<Table.Column<IPayrollDetailDetail | IPayrollOverviewDetail>
				render={(_, __, index: number) => {
					return index + 1;
				}}
				title={<Text>{I18n.t(_t(translations.payrollDetailReport.no))}</Text>}
			/>
		) : (
			<Table.Column<IPayrollDetailDetail | IPayrollOverviewDetail>
				dataIndex={'date'}
				render={(value: string) => {
					return moment(value).format('DD');
				}}
				title={<Text>{I18n.t(_t(translations.payrollDetailReport.date))}</Text>}
			/>
		);
	}

	renderWeekdayCol() {
		return (
			<Table.Column<IPayrollDetailDetail | IPayrollOverviewDetail>
				dataIndex={'date'}
				render={(value: string) => {
					return this.props.isDetail
						? moment.utc(value).format('MM/DD/YYYY')
						: moment(value).format('dddd');
				}}
				title={
					<Text>
						{this.props.isDetail
							? I18n.t(_t(translations.payrollDetailReport.date))
							: I18n.t(_t(translations.payrollDetailReport.weekday))}
					</Text>
				}
			/>
		);
	}

	renderTurnCol() {
		return (
			<Table.Column<IPayrollDetailDetail | IPayrollOverviewDetail>
				dataIndex={'turn'}
				render={(value: number) => {
					return value;
				}}
				title={<Text>{I18n.t(_t(translations.payrollDetailReport.turn))}</Text>}
			/>
		);
	}

	renderSalaryCol() {
		return (
			<Table.Column<IPayrollDetailDetail | IPayrollOverviewDetail>
				dataIndex={'salary'}
				render={(value: number) => {
					return CurrencyHelper.formatPrice(value);
				}}
				title={
					<Text>{I18n.t(_t(translations.payrollDetailReport.salary))}</Text>
				}
			/>
		);
	}

	renderIncomeCol() {
		return (
			<Table.Column<IPayrollDetailDetail | IPayrollOverviewDetail>
				dataIndex={'income'}
				render={(value: number) => {
					return CurrencyHelper.formatPrice(value);
				}}
				title={
					<Text>{I18n.t(_t(translations.payrollDetailReport.income))}</Text>
				}
			/>
		);
	}

	renderCommisionCol() {
		return (
			<Table.Column<IPayrollDetailDetail | IPayrollOverviewDetail>
				dataIndex={'commission'}
				render={(value: number) => {
					return CurrencyHelper.formatPrice(value);
				}}
				title={
					<Text>{I18n.t(_t(translations.payrollDetailReport.commision))}</Text>
				}
			/>
		);
	}

	renderTipsCol() {
		return (
			<Table.Column<IPayrollDetailDetail | IPayrollOverviewDetail>
				dataIndex={'tip'}
				render={(value: number) => {
					return CurrencyHelper.formatPrice(value);
				}}
				title={<Text>{I18n.t(_t(translations.payrollDetailReport.tips))}</Text>}
			/>
		);
	}

	renderDiscountCol() {
		return (
			<Table.Column<IPayrollDetailDetail | IPayrollOverviewDetail>
				dataIndex={'discount'}
				render={(value: number) => {
					return CurrencyHelper.formatPrice(value);
				}}
				title={
					<Text>{I18n.t(_t(translations.payrollDetailReport.discount))}</Text>
				}
			/>
		);
	}

	renderChargeFeeCol() {
		return (
			<Table.Column<IPayrollDetailDetail | IPayrollOverviewDetail>
				dataIndex={'chargeFee'}
				render={(value: number) => {
					return CurrencyHelper.formatPrice(value);
				}}
				title={
					<Text>{I18n.t(_t(translations.payrollDetailReport.chargeFee))}</Text>
				}
			/>
		);
	}

	renderSalaryPayCol() {
		return (
			<Table.Column<IPayrollDetailDetail | IPayrollOverviewDetail>
				dataIndex={'salaryPay'}
				render={(value: number) => {
					return CurrencyHelper.formatPrice(value);
				}}
				title={
					<Text>
						{I18n.t(_t(translations.payrollDetailReport.totalCommSalary))}
					</Text>
				}
			/>
		);
	}

	renderRefundCol() {
		return (
			<Table.Column<IPayrollDetailDetail | IPayrollOverviewDetail>
				dataIndex={'refund'}
				render={(value: number) => {
					return CurrencyHelper.formatPrice(value);
				}}
				title={
					<Text>{I18n.t(_t(translations.payrollDetailReport.refund))}</Text>
				}
			/>
		);
	}

	renderSummary() {
		return (
			<>
				<Table.Summary.Row>
					<Table.Summary.Cell index={0}></Table.Summary.Cell>
					<Table.Summary.Cell index={1}>
						<Title level={5}>
							{I18n.t(_t(translations.payrollDetailReport.total))}
						</Title>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={2}>
						<Title level={5}>
							{this.props.isDetail
								? ''
								: this.props.payrollDetailData.totalTurn}
						</Title>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={3}>
						<Title level={5}>
							{CurrencyHelper.formatPrice(
								this.props.payrollDetailData.totalIncome
							)}
						</Title>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={4}>
						<Title level={5}>
							{CurrencyHelper.formatPrice(
								this.props.payrollDetailData.totalSalary
							)}
						</Title>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={5}>
						<Title level={5}>
							{CurrencyHelper.formatPrice(
								this.props.payrollDetailData.totalCommission
							)}
						</Title>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={6}>
						<Title level={5}>
							{CurrencyHelper.formatPrice(
								this.props.payrollDetailData.totalTip
							)}
						</Title>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={7}>
						<Title level={5}>
							{CurrencyHelper.formatPrice(
								this.props.payrollDetailData.totalDiscount
							)}
						</Title>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={8}>
						<Title level={5}>
							{CurrencyHelper.formatPrice(
								this.props.payrollDetailData.totalChargeFee
							)}
						</Title>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={8}>
						<Title level={5}>
							{CurrencyHelper.formatPrice(
								this.props.payrollDetailData.totalRefund
							)}
						</Title>
					</Table.Summary.Cell>
					<Table.Summary.Cell index={9}>
						<Title level={5}>
							{CurrencyHelper.formatPrice(
								this.props.payrollDetailData.totalSalaryPay
							)}
						</Title>
					</Table.Summary.Cell>
				</Table.Summary.Row>
			</>
		);
	}

	getMappedData = () => {
		return this.props.isDetail
			? (this.props.payrollDetailData as IPayrollDetail)!.payrollDetailDetails!
			: (this.props.payrollDetailData as IPayrollOverview)!
					.payrollOverviewDetails!;
	};
}

export default DetailTable;
