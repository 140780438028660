import React, { Component } from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import {
	IBillDetail,
	IPayrollDetail,
	IPayrollDetailDetail,
	IPayrollOverview,
	IPayrollOverviewDetail,
} from 'models';
import moment from 'moment';
import { Colors, I18n, translations, _t } from 'utils';
import { BookingHelper, CurrencyHelper } from 'helpers';
import { kStyles } from 'GlobalStyles';
import _ from 'lodash';
const { Text, Title } = Typography;

interface ISideViewProps {
	payrollOverviewData: IPayrollOverview;
	payrollDetailData: IPayrollDetail;
	isDetail: boolean;
	isAllTime: boolean;
}
interface ISideViewStates {}

export class SideView extends Component<ISideViewProps, ISideViewStates> {
	constructor(props: ISideViewProps) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<>
				{this.renderTitle()}
				{this.renderDetails()}
				<Divider style={{ borderColor: Colors.GREY }} />
				{/* {this.renderPaymentTypes()} */}
				{/* <Divider style={{ borderColor: Colors.GREY }} /> */}
				{/* {this.renderTotalPayment()}
				{this.renderTips()} */}
				{this.renderPayroll()}
				{this.renderFooter()}
			</>
		);
	}

	renderTitle() {
		return (
			<>
				<Row>
					<Title level={3} style={styles.employeeName}>
						{this.props.isDetail
							? this.props.payrollDetailData?.employeeName
							: this.props.payrollOverviewData?.employeeName}
					</Title>
				</Row>
				<Row>
					<Title level={4}>
						{BookingHelper.isDateRange(
							this.getMappedData()!.fromDate!,
							this.getMappedData()!.toDate!
						)
							? `${moment(this.getMappedData()!.fromDate).format(
									'MMM DD, YYYY'
							  )} - ${moment(this.getMappedData()!.toDate).format(
									'MMM DD, YYYY'
							  )}`
							: moment(this.getMappedData()!.fromDate).format('MMM DD, YYYY')}
					</Title>
				</Row>
			</>
		);
	}

	renderDetails() {
		const { isDetail } = this.props;
		const data = isDetail
			? this.props.payrollDetailData.payrollDetailDetails!.filter(
					(element) => element.salaryPay! > 0
			  )
			: this.props.payrollOverviewData.payrollOverviewDetails!.filter(
					(element) => element.salaryPay! > 0
			  );

		return (
			<>
				{isDetail
					? this.renderDetailDetail(data as IPayrollDetailDetail[])
					: this.renderOverviewDetail(data as IPayrollOverviewDetail[])}
				{this.renderTotalService()}
			</>
		);
	}

	renderDetailDetail(data: IPayrollDetailDetail[]) {
		return data?.map((element, index) => (
			<Row key={index} style={styles.row}>
				<Col span={3}>
					<Title level={5}>{(element as IPayrollDetailDetail).turn}</Title>
				</Col>
				<Col span={12}>
					<Title level={5}>
						{`${moment((element as IPayrollDetailDetail).date).format(
							'MM/DD/YYYY'
						)} ${
							(element as IPayrollDetailDetail).customerName
								? `- ${(element as IPayrollDetailDetail).customerName}`
								: ''
						}`}
					</Title>
				</Col>
				<Col span={9}>
					<Title level={5}>
						{CurrencyHelper.formatPrice(element.salaryPay)}
					</Title>
				</Col>
				{this.renderServices(element as IPayrollDetailDetail)}
			</Row>
		));
	}

	renderOverviewDetail(data: IPayrollOverviewDetail[]) {
		return (
			<>
				<Row style={styles.row}>
					<Col span={3}>
						<Title level={5}>
							{I18n.t(_t(translations.payrollDetailReport.date))}
						</Title>
					</Col>
					<Col span={5}>
						<Title level={5}>
							{I18n.t(_t(translations.payrollDetailReport.weekday))}
						</Title>
					</Col>
					<Col span={5}>
						<Title level={5}>
							{I18n.t(_t(translations.payrollDetailReport.income))}
						</Title>
					</Col>
					<Col span={6}>
						<Title level={5}>
							{I18n.t(_t(translations.payrollDetailReport.commSalary))}
						</Title>
					</Col>
					<Col span={5}>
						<Title level={5}>
							{I18n.t(_t(translations.payrollDetailReport.tips))}
						</Title>
					</Col>
				</Row>
				{data?.map((element, index) => (
					<Row key={index} style={styles.row}>
						<Col span={3}>
							<Text>
								{moment((element as IPayrollOverviewDetail).date).format('DD')}
							</Text>
						</Col>
						<Col span={5}>
							<Text>
								{moment((element as IPayrollOverviewDetail).date).format(
									'dddd'
								)}
							</Text>
						</Col>
						<Col span={5}>
							<Text>{CurrencyHelper.formatPrice(element.income)}</Text>
						</Col>
						<Col span={6}>
							<Text>{CurrencyHelper.formatPrice(element.commission)}</Text>
						</Col>
						<Col span={5}>
							<Text>{CurrencyHelper.formatPrice(element.tip)}</Text>
						</Col>
					</Row>
				))}
			</>
		);
	}

	renderTotalService() {
		const { payrollOverviewData } = this.props;
		return (
			<Row style={styles.totalService}>
				<Col span={3}>
					<Title level={5}>
						{I18n.t(_t(translations.payrollDetailReport.total))}
					</Title>
				</Col>
				<Col span={5}>
					{/* <Title level={5}>
						{I18n.t(_t(translations.payrollDetailReport.weekday))}
					</Title> */}
				</Col>
				<Col span={5}>
					<Title level={5}>
						{CurrencyHelper.formatPrice(payrollOverviewData.totalIncome)}
					</Title>
				</Col>
				<Col span={6}>
					<Title level={5}>
						{CurrencyHelper.formatPrice(payrollOverviewData.totalCommission)}
					</Title>
				</Col>
				<Col span={5}>
					<Title level={5}>
						{CurrencyHelper.formatPrice(payrollOverviewData.totalTip)}
					</Title>
				</Col>
				{/* <Col span={3}>
					<Title level={5}>
						{I18n.t(_t(translations.payrollDetailReport.total))}
					</Title>
				</Col>
				<Col span={12}></Col>
				<Col span={9}>
					<Title level={5}>
						{CurrencyHelper.formatPrice(this.getMappedData().totalSalaryPay)}
					</Title>
				</Col> */}
			</Row>
		);
	}

	renderServices(payrollDetail: IPayrollDetailDetail) {
		return payrollDetail.billDetails!.map((element, index) => {
			return (
				<Row key={index} style={styles.maxWidth}>
					<Col span={3}></Col>
					<Col span={12}>
						<Text>{element.serviceName}</Text>
					</Col>
					<Col span={9}>
						<Text>{`${CurrencyHelper.formatPrice(element.amount)} ${
							element.supplyFee && element.supplyFee! > 0
								? `(-${CurrencyHelper.formatPrice(element.supplyFee!)} ${I18n.t(
										_t(translations.payrollDetailReport.supplyFee)
								  )})`
								: ''
						}`}</Text>
					</Col>
				</Row>
			);
		});
	}

	renderOtherInformation(
		title: string,
		value?: number,
		isBold?: boolean,
		isNegative?: boolean
	) {
		return (
			<Row>
				<Col span={12}>
					{isBold ? <Title level={5}>{title}</Title> : <Text>{title}</Text>}
				</Col>
				<Col span={12}>
					{isBold ? (
						<Title level={5}>
							{isNegative ? '-' : '\xa0'}
							{value !== undefined && CurrencyHelper.formatPrice(value)}
						</Title>
					) : (
						<Text>
							{isNegative ? '-' : '\xa0'}
							{value !== undefined && CurrencyHelper.formatPrice(value)}
						</Text>
					)}
				</Col>
			</Row>
		);
	}

	renderPaymentTypes() {
		return (
			<>
				{this.renderOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.paymentTypes)),
					undefined,
					true
				)}
				{this.renderOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.totalCash)),
					this.getMappedData().billPaymentTypes?.cash,
					false
				)}
				{this.renderOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.totalCreditCard)),
					this.getMappedData().billPaymentTypes?.card,
					false
				)}
				{this.renderOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.totalByApp)),
					this.getMappedData()?.billPaymentTypes?.payByApps,
					false
				)}
				{this.renderOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.totalGiftcard)),
					this.getMappedData()?.billPaymentTypes?.giftCard,
					false
				)}
				{this.renderOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.total)),
					this.getMappedData()?.billPaymentTypes?.total,
					true
				)}
			</>
		);
	}

	renderTips() {
		return (
			<>
				{this.renderOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.tipCollected)),
					this.getMappedData()?.totalTip
				)}
				<Row>
					<Col span={12}>
						<Text>{I18n.t(_t(translations.payrollDetailReport.tipTimes))}</Text>
					</Col>
					<Col span={12}>
						<Text>
							{/* {`\xa0${_.sumBy(
								this.props.isDetail
									? (this.getMappedData() as IPayrollDetail)
											.payrollDetailDetails
									: this.getMappedData().payrollOverviewDetails,
								(element) => (element.tip ? 1 : 0)
							)}`} */}
							{this.props.isDetail
								? (this.getMappedData() as IPayrollDetail).totalTipCount
								: this.getMappedData().totalTipCount}
						</Text>
					</Col>
				</Row>
				{this.renderOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.tipCardCharge)),
					this.getMappedData()?.totalTipCardCharge!,
					false,
					true
				)}
				{/* {this.renderOtherInformation(
          I18n.t(_t(translations.payrollDetailReport.totalTips)),
          this.getMappedData()?.totalTip! -
            this.getMappedData()?.totalTipCardCharge!,
          true
        )} */}
			</>
		);
	}

	renderCommission() {
		return (
			<>
				{this.renderOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.commision)),
					this.getMappedData()?.totalCommission!,
					true
				)}
				{this.renderOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.salary)),
					this.getMappedData()?.totalSalary!,
					true
				)}
			</>
		);
	}

	renderPayroll() {
		return (
			<>
				{this.renderOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.payroll)),
					undefined,
					true
				)}
				{this.renderOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.check)),
					this.getMappedData()?.subtotalPayByCheck
				)}
				{this.renderOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.cash)),
					this.getMappedData()?.subtotalPayByCash
				)}
				{this.renderOtherInformation(
					I18n.t(_t(translations.payrollDetailReport.totalTips)),
					this.getMappedData()?.totalTipPay
				)}
			</>
		);
	}

	renderFooter() {
		const data = this.getMappedData();
		return (
			<>
				<Row style={{ marginTop: 16 }}>
					<Col span={16}>
						<Title level={4}>
							{I18n.t(_t(translations.payrollDetailReport.totalCheckAmount))}
						</Title>
					</Col>
					<Col span={8}>
						<Title level={4}>
							{CurrencyHelper.formatPrice(data.totalSalaryPayByCheck!)}
						</Title>
					</Col>
				</Row>
				<Row>
					<Col span={16}>
						<Title level={4}>
							{I18n.t(_t(translations.payrollDetailReport.totalCashAmount))}
						</Title>
					</Col>
					<Col span={8}>
						<Title level={4}>
							{CurrencyHelper.formatPrice(data.totalSalaryPayByCash)}
						</Title>
					</Col>
				</Row>
			</>
		);
	}

	getMappedData = () => {
		return this.props.isDetail
			? this.props.payrollDetailData
			: this.props.payrollOverviewData;
	};
}

const styles: kStyles = {
	maxWidth: { width: '100%' },
	employeeName: { color: Colors.RED_BERRY },
	spin: { margin: '0 auto' },
	row: {
		marginBottom: 16,
	},
	totalService: {
		marginTop: 16,
	},
};

export default SideView;
