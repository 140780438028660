import _ from 'lodash';
import { IService } from 'models';
import { RootState } from 'redux/configuration/rootReducer';
import { createSelector } from 'reselect';
const getAllServices = (state: RootState) => state.ServiceReducer.services;
// const getListStaff = (state: RootState) => state.ServiceReducer.staff;
const getCurrentService = (state: RootState) =>
	state.ServiceReducer.currentService;
export const getServicesByCategory = createSelector(
	[
		(categoryId: string, services: IService[]) => services,
		(categoryId: string, services: IService[]) => categoryId,
	],
	(services, categoryId) => {
		return _.filter(
			services,
			(service) => service.itemCategoryId === categoryId
		);
	}
);

export const isEditingService = createSelector(
	[getCurrentService],
	(service) => {
		return service != null;
	}
);

export const searchServices = createSelector(
	[getAllServices, (state: RootState, searchInput: string) => searchInput],
	(services, searchInput) => {
		return _.filter(
			services.slice().sort(
				(serviceA, serviceB) => serviceA.indexNumber! - serviceB.indexNumber!
			),
			(service) =>
				service.name.toLowerCase().includes(searchInput.toLowerCase()) &&
				service.status === 1
		);
	}
);
