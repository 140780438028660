import { Dispatch } from '@reduxjs/toolkit';
import { Tabs, Button, Space, Row } from 'antd';
import { ContentAdminContainer, IComponentProps } from 'components';
import { PlusOutlined, IdcardTwoTone } from '@ant-design/icons';
import { IGetBookingsRequest } from 'models/RequestModels';
import React from 'react';
import { connect } from 'react-redux';
import { BookingActions, EmployeeActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import qs from 'qs';
import moment from 'moment';
import TurnChart from 'pages/TodayBooking/TurnChart';
import RealTimeBooking from 'pages/TodayBooking/RealTimeBooking';
import './styles.scss';
import SocketService from 'services/SocketService';
const { TabPane } = Tabs;

interface IHomeBookingProps
	extends IComponentProps,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}

class TodayBooking extends React.Component<IHomeBookingProps> {
	socketService: SocketService | null = null;
	componentDidMount() {
		this.props.getTodayBookings();
		this.socketService = new SocketService();
		this.socketService.onListenBooking();
		this.socketService.runService();
		this.socketService.onClose();
		// this.props.getEmployee();
	}
	componentWillUnmount() {
		if (this.socketService !== null) {
			this.socketService.remove();
			this.socketService = null;
		}
	}
	render() {
		return (
			<ContentAdminContainer
				className="home-booking-container"
				hidePathName
				style={{ padding: 16, paddingBottom: 0, height: '100%' }}
				// extraHeader={this.renderHeader()}
			>
				{this.renderContent()}
			</ContentAdminContainer>
		);
	}
	renderContent() {
		return (
			<Tabs type="card" tabBarExtraContent={this.renderAddNewButton()}>
				<TabPane tab="Bookings" key="1">
					<RealTimeBooking />
				</TabPane>
				<TabPane tab="Turn" key="2">
					<TurnChart />
				</TabPane>
			</Tabs>
		);
	}

	renderAddNewButton() {
		return (
			<Row>
				<Button
					icon={<IdcardTwoTone />}
					onClick={this.onSaleGiftCard}
					style={{ marginBottom: 16, flex: 1 }}
				>
					Sale Gift Card
				</Button>
				<Button
					icon={<PlusOutlined />}
					onClick={this.onCreateNewBooking}
					type="primary"
					style={{ marginBottom: 16, marginLeft: 16, flex: 1 }}
				>
					Add New
				</Button>
			</Row>
		);
	}

	onCreateNewBooking = () => {
		let date = moment().format('MM-DD-YYYY');
		let time;

		const query = qs.stringify({
			date,
			time,
		});

		this.props.history.push(`/booking/new?${query}`);
	};
	onSaleGiftCard = () => {
		this.props.history.push(`/checkout/sale-gift-card`);
	};
}

const mapStateToProps = (state: RootState) => ({
	// listEmployees: state.EmployeeReducer.employees,
	currentAction: state.ReduxActionReducer['BOOKING'],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	getEmployee: () => dispatch(EmployeeActions.getAllEmployees.request()),
	getTodayBookings: () => {
		dispatch(BookingActions.getTodayBookings.request());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(TodayBooking);
