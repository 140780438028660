import React, { Component } from "react";
import { Radio, RadioProps } from "antd";
import { ColorHelper } from "helpers";
import { BookingStatus } from "utils/Consts";
import { _t, I18n, translations } from "utils";
const options = [
  {
    label: ` ${I18n.t(_t(translations.bookingStatus[`status_${BookingStatus.PENDING}`]))}`,
    value: BookingStatus.PENDING,
  },
  {
    label: ` ${I18n.t(_t(translations.bookingStatus[`status_${BookingStatus.CHECKED_IN}`]))}`,
    value: BookingStatus.CHECKED_IN,
  },
  {
    label: ` ${I18n.t(_t(translations.bookingStatus[`status_${BookingStatus.PROCESSING}`]))}`,
    value: BookingStatus.PROCESSING,
  },

  {
    label: ` ${I18n.t(_t(translations.bookingStatus[`status_${BookingStatus.FINISHED}`]))}`,
    value: BookingStatus.FINISHED,
  },
  {
    label: ` ${I18n.t(_t(translations.bookingStatus[`status_${BookingStatus.DONE}`]))}`,
    value: BookingStatus.DONE,
  },
];

interface IStatusGroupButton extends RadioProps {
  status: number;
  count?: number[];
  defaultValue?: number;
  onClickRadioButton: (e: any) => void;
  hideDone?: boolean;
}

export default class StatusGroupButton extends React.Component<IStatusGroupButton> {
  render() {
    return (
      <div className="flex-display flex-justify-right">
        <Radio.Group
          onChange={this.props.onClickRadioButton}
          defaultValue={this.props.defaultValue}
          // value={this.state.type}
          optionType="button"
          buttonStyle="solid"
        >
          <Radio.Button value={-1}>{`All ${this.props.count ? `(${this.props.count[0]})` : ""}`}</Radio.Button>
          {options.map((type, index) => {
            if (this.props.hideDone && type.value === BookingStatus.DONE) {
              return null;
            }
            return (
              <Radio.Button
                style={{
                  color: this.props.status === type.value ? undefined : ColorHelper.getStatusColor(type.value),
                  backgroundColor:
                    this.props.status === type.value ? ColorHelper.getStatusColor(type.value) : undefined,
                  // borderColor: this.state.status === type.value ? ColorHelper.getStatusColor(type.value) : undefined,
                }}
                value={type.value}
              >
                {`${type.label} ${this.props.count ? `(${this.props.count[index + 1]})` : ""}`}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      </div>
    );
  }
}
