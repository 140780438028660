import { Button, Calendar, Modal, Typography } from 'antd';
import I18n, { t } from 'i18n-js';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { _t, translations } from 'utils';
export type TDateRange = {
	fromDate?: Moment;
	toDate?: Moment;
};
interface ICalendarModalProps {
	rangeSelect?: boolean;
	selectedDate?: Moment;
	selectedDateRange?: TDateRange;
	selectedTo?: Moment;
	onSelectDateRange?: (dateRange: TDateRange) => void;
	onSelectDate?: (date: Moment) => void;
	onCancel: () => void;
	disabledBefore?: boolean;
}

interface IDateCellProps {
	isSelected?: boolean;
	isInRage?: boolean;
	sameMonth?: boolean;
	onClick?: () => void;
	date: Moment;
	disabled?: boolean;
}

const DateCell = (props: IDateCellProps) => {
	const { date, isSelected, isInRage, sameMonth } = props;
	const isToday = date.isSame(moment(), 'date');
	return (
		<div
			onClick={() => props.onClick && props.onClick()}
			style={{ padding: 0 }}
			title={date.format('MM-DD-YYYY')}
			className="ant-picker-cell ant-picker-cell-in-view"
		>
			<div
				style={{
					display: 'block',
					width: 'auto',
					height: 'auto',
					marginRight: 4,
					padding: 4,
					// border: isToday ? '1px solid #004cc4' : '2px 0 0 0  solid #f0f0f0',
					borderTop: '2px solid #f0f0f0',
					transition: 'background 0.3s',
					backgroundColor: isSelected
						? '#004cc4'
						: isInRage
						? '#d0edfb'
						: isToday
						? 'rgba(0,76,196,0.1)'
						: 'white',
				}}
			>
				<div
					style={{
						height: 50,
						color: isSelected
							? 'white'
							: !sameMonth || props.disabled
							? 'rgba(0, 0, 0, 0.25)'
							: 'black',
						border: isToday ? '1px solid #004cc4' : undefined,
						padding: 4,
						paddingTop: 0,
						fontWeight: isSelected ? 'bold' : 'inherit',
					}}
					className="ant-picker-calendar-date-value"
				>
					{date.format('DD')}
				</div>
			</div>
		</div>
	);
};
const checkSameMonth = (date: Moment, current: Moment) => {
	return date.isSame(current, 'month') && date.isSame(current, 'year');
};
const checkSameDate = (date: Moment, current?: Moment) => {
	if (!current) {
		return false;
	}
	return date.isSame(current, 'date');
};
const checkInRage = (date: Moment, dateRange?: TDateRange) => {
	if (!dateRange || !dateRange.fromDate || !dateRange.toDate) {
		return false;
	}
	return (
		date.isAfter(dateRange.fromDate, 'date') &&
		date.isBefore(dateRange.toDate, 'date')
	);
};

export const CalendarModal = (props: ICalendarModalProps) => {
	const [current, setCurrent] = useState(
		props.selectedDate || props.selectedDateRange?.fromDate || moment()
	);
	const [dateRage, setDateRage] = useState(props.selectedDateRange);
	const onSelectDate = (date: Moment) => {
		if (props.disabledBefore && date.isBefore(moment())) {
			return;
		}
		if (props.rangeSelect) {
			if (
				!dateRage?.fromDate ||
				dateRage?.fromDate.isAfter(date) ||
				dateRage.toDate
			) {
				setDateRage({ fromDate: date });
			} else {
				setDateRage({ fromDate: dateRage?.fromDate!, toDate: date });
				setTimeout(() => {
					props.onSelectDateRange &&
						props.onSelectDateRange({
							fromDate: dateRage?.fromDate!,
							toDate: date,
						});
				}, 500);
			}
		} else {
			props.onSelectDate && props.onSelectDate(date);
		}
	};
	const checkSelectedDate = (date: Moment) => {
		return (
			checkSameDate(date, props.selectedDate) ||
			checkSameDate(date, dateRage?.fromDate) ||
			checkSameDate(date, dateRage?.toDate)
		);
	};
	return (
		<Modal
			style={{}}
			className="date-picker-modal"
			visible={true}
			title={
				<Typography.Title style={{ color: 'white' }} level={3}>
					{I18n.t(_t(translations.mainBooking.selectDate))}
				</Typography.Title>
			}
			width={1000}
			onCancel={() => props.onCancel()}
			footer={
				<Button onClick={() => props.onCancel()}>
					{I18n.t(_t(translations.cancel))}
				</Button>
			}
		>
			<Calendar
				onChange={(date) => setCurrent(date)}
				defaultValue={current}
				dateFullCellRender={(date) => (
					<DateCell
						isInRage={checkInRage(date, dateRage)}
						sameMonth={checkSameMonth(date, current)}
						isSelected={checkSelectedDate(date)}
						date={date}
						disabled={props.disabledBefore && date.isBefore(moment(), 'date')}
						onClick={() => onSelectDate(date)}
					/>
				)}
			/>
		</Modal>
	);
};
