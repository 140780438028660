import React, { useEffect, useState } from 'react';
import {
	ContentAdminContainer,
	DatePickerModal,
	DateRangeSelect,
} from 'components';
import { Col, Row, Select } from 'antd';
import {
	EPeriodType,
	IApiResponse,
	IErrorResponse,
	IOwnerFinancialSummaryReport,
} from 'models';
import { CalendarOutlined } from '@ant-design/icons';
import { EDatePickerType, PERIOD_TYPES } from 'utils/Consts';
import './styles.css';
import { ReportApiService } from 'services';
import moment from 'moment';
import { AlertHelper, BookingHelper } from 'helpers';
import { IOwnerReportRequest } from 'models/RequestModels';
import { Report } from './components';
import { TDateRange } from 'components/DatePicker/CalendarModal';
import { DateRangePicker } from 'components/DatePicker/DateRangePicker';

const OwnerFinancialSummaryReport = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [report, setReport] = useState<
		IOwnerFinancialSummaryReport | undefined
	>();
	const [selectedPeriodType, setSelectedPeriodType] = useState<EPeriodType>(
		EPeriodType.DATE_RANGE
	);

	const [selectedDateRange, setSelectedDateRange] = useState<TDateRange>({
		fromDate: moment(),
		toDate: moment(),
	});

	const [isShowDateRangePicker, setIsShowDateRangePicker] =
		useState<boolean>(true);
	useEffect(() => {
		getReport({
			periodType: EPeriodType.DATE_RANGE,
			fromDate: BookingHelper.convertDateRequest(selectedDateRange.fromDate!),
			toDate: BookingHelper.convertDateRequest(selectedDateRange.toDate!),
		});
	}, [selectedDateRange, selectedPeriodType]);

	const renderHeader = () => {
		return (
			<Row className="max-width header" gutter={16}>
				<PeriodTypeSelect onChange={onPeriodTypeSelected} />
				{isShowDateRangePicker && (
					<DateRangePicker
						selectedDateRange={selectedDateRange}
						onSelectDateRange={(dateRange) => {
							setSelectedDateRange(dateRange);
						}}
					/>
					// <DateRangeSelect
					// 	fromDate={fromDate}
					// 	toDate={toDate}
					// 	onDateRangeButtonClick={onDateRangeButtonClicked}
					// />
				)}
			</Row>
		);
	};

	const onPeriodTypeSelected = (type: EPeriodType) => {
		setSelectedPeriodType(type);
		switch (type) {
			case EPeriodType.WEEK:
				setSelectedDateRange({
					fromDate: moment().add(-7, 'day'),
					toDate: moment(),
				});
				setIsShowDateRangePicker(false);
				break;
			case EPeriodType.SEMI_MONTH:
				setSelectedDateRange({
					fromDate: moment().add(-15, 'day'),
					toDate: moment(),
				});
				setIsShowDateRangePicker(false);
				break;
			case EPeriodType.MONTH:
				setSelectedDateRange({
					fromDate: moment().add(-30, 'day'),
					toDate: moment(),
				});
				setIsShowDateRangePicker(false);
				break;
			case EPeriodType.QUARTER:
				setSelectedDateRange({
					fromDate: moment().add(-3, 'month'),
					toDate: moment(),
				});
				setIsShowDateRangePicker(false);
				break;
			case EPeriodType.SEMI_YEAR:
				setSelectedDateRange({
					fromDate: moment().add(-6, 'month'),
					toDate: moment(),
				});
				setIsShowDateRangePicker(false);
				break;
			case EPeriodType.YEAR:
				setSelectedDateRange({
					fromDate: moment().startOf('year'),
					toDate: moment(),
				});
				setIsShowDateRangePicker(false);
				break;
			case EPeriodType.DATE_RANGE:
				setSelectedDateRange({
					fromDate: moment(),
					toDate: moment(),
				});
				setIsShowDateRangePicker(true);
				break;
			default:
				setIsShowDateRangePicker(false);
				break;
		}
	};

	const getReport = async (request: IOwnerReportRequest) => {
		setIsLoading(true);
		try {
			const result = (await ReportApiService.getOwnerFinancialSummaryReport(
				request
			)) as IApiResponse<IOwnerFinancialSummaryReport>;
			if (result.succeeded) {
				setReport(result.data);
			} else {
				AlertHelper.showError(result as IErrorResponse);
			}
		} catch (error) {
			console.error('Network error', error);
		} finally {
			setIsLoading(false);
		}
	};
	return (
		<ContentAdminContainer hidePathName>
			{renderHeader()}
			<Row>
				<Report isLoading={isLoading} report={report} />
			</Row>
		</ContentAdminContainer>
	);
};

interface IPeriodTypeSelectProps {
	onChange?: (type: EPeriodType) => void;
}
const PeriodTypeSelect = ({ onChange }: IPeriodTypeSelectProps) => {
	const [selectedPeriodType, setSelectedPeriodType] = useState<EPeriodType>(
		EPeriodType.DATE_RANGE
	);
	const dropdownStyle = { borderWidth: 1, borderColor: 'red' };
	return (
		<Col xs={12} sm={12} md={8} lg={5}>
			<Select
				defaultValue={selectedPeriodType}
				className="max-width"
				onChange={(type) => {
					setSelectedPeriodType(type);
					onChange && onChange(type);
				}}
				allowClear={false}
				suffixIcon={<CalendarOutlined />}
				dropdownStyle={dropdownStyle}
			>
				{PERIOD_TYPES.map((dateRange) => {
					return (
						<Select.Option key={dateRange.id} value={dateRange.value}>
							{dateRange.title}
						</Select.Option>
					);
				})}
			</Select>
		</Col>
	);
};

export default OwnerFinancialSummaryReport;
