import { Button, Col, Input, Row, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import CheckOutBaseModal from './CheckOutBaseModal';
import styles from './styles';
import { translations, _t, I18n } from 'utils';
import { CurrencyHelper, GenerateHelper } from 'helpers';
import { IBranch } from 'models/IBranch';
import { priceFixed } from 'helpers/currencyHelper';

interface IPayCreditCardModalProps {
	onOk(referenceNumber: string, money: number, cardCharge: number): void;
	onCancel(): void;
	amount: number;
	currentBranch: IBranch;
	paymentReferenceCodeRequiring?: boolean;
}

interface IPayCreditCardModalState {
	creditCard?: string;
	money?: string;
}

export default class PayCreditCardModal extends React.Component<
	IPayCreditCardModalProps,
	IPayCreditCardModalState
> {
	constructor(props: IPayCreditCardModalProps) {
		super(props);
		this.state = {};
	}
	inputRef: any = React.createRef();
	moneyRef: any = React.createRef();
	render() {
		return (
			<CheckOutBaseModal
				title={I18n.t(_t(translations.checkout.creditCard))}
				onOk={() => this.onOk()}
				onCancel={this.props.onCancel}
				disableOk={
					(this.props.paymentReferenceCodeRequiring
						? !this.state.creditCard
						: false) || !this.state.money
				}
			>
				{this.renderInputCode()}
				{this.renderInputMoney()}
				<Col span={24} style={styles.textRight}>
					<Typography.Text>
						{`${I18n.t(
							_t(translations.payrollReport.cardCharge)
						)} ${CurrencyHelper.formatBalance(this.getCardCharge())}`}
					</Typography.Text>
				</Col>
				{/* <Col span={24} style={styles.textRight}>
					<Typography.Text>
						{`Amount need to pay ${CurrencyHelper.formatBalance(
							this.props.balance
						)}`}
					</Typography.Text>
				</Col> */}
			</CheckOutBaseModal>
		);
	}

	renderInputCode = () => {
		if (!this.props.paymentReferenceCodeRequiring) {
			return null;
		}
		return (
			<Col>
				<Typography.Title level={4}>
					{I18n.t(_t(translations.checkout.last4Digits))}
				</Typography.Title>
				<Row gutter={8}>
					<Col md={20}>
						<Input
							ref={this.inputRef}
							style={styles.inputNumber}
							autoFocus={true}
							value={this.state.creditCard}
							onChange={(event) =>
								this.setState({ creditCard: event.target.value })
							}
						/>
					</Col>
					<Col md={4}>
						<Button
							onClick={() => {
								this.inputRef.current?.focus();
								this.setState({ creditCard: undefined });
							}}
							danger
						>
							{I18n.t(_t(translations.clear))}
						</Button>
					</Col>
				</Row>
			</Col>
		);
	};
	renderInputMoney = () => {
		return (
			<Col>
				<Typography.Title level={4}>Input Money</Typography.Title>
				<Row gutter={8}>
					<Col md={16}>
						<Input
							ref={this.moneyRef}
							style={styles.inputNumber}
							onPressEnter={this.onOk}
							value={this.state.money}
							onChange={(event) =>
								this.setState({
									money:
										Number(event.target.value) > this.props.amount
											? `${this.props.amount}`
											: event.target.value,
								})
							}
						/>
					</Col>
					<Col md={4}>
						<Button
							onClick={() => {
								this.moneyRef.current?.focus();
								this.setState({
									money: `${this.props.amount}`,
								});
							}}
						>
							Exact
						</Button>
					</Col>
					<Col md={4}>
						<Button
							onClick={() => {
								this.moneyRef.current?.focus();
								this.setState({ money: undefined });
							}}
							danger
						>
							{I18n.t(_t(translations.clear))}
						</Button>
					</Col>
				</Row>
			</Col>
		);
	};
	getCardCharge = () => {
		const cardCharge =
			(this.props.currentBranch.cardChargeFlatRate || 0) +
			((this.props.currentBranch.cardChargePercent || 0) / 100) *
				priceFixed(Number(this.state.money || 0));
		return cardCharge;
	};
	onOk = () => {
		this.props.onOk(
			this.props.paymentReferenceCodeRequiring
				? this.state.creditCard!
				: GenerateHelper.generateFourDigitsNumber().toString(),
			priceFixed(Number(this.state.money)),
			this.getCardCharge()
		);
	};
}
