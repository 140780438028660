import React, { Component } from 'react';
import { Layout, Spin, Card, Divider } from 'antd';
import styles from './styles';
import { IComponentProps, DetailPageLayout, Modal } from 'components';
import { I18n, _t, translations } from 'utils';
import { Dispatch } from 'redux';
import {
	IAddCategoryRequest,
	IEditCategoryRequest,
	IAddGalleryRequest,
} from 'models/RequestModels';
import { CategoryActions, ErrorActions, GalleryActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import _ from 'lodash';
import { AlertHelper, FormHelper } from 'helpers';
import { CategoryApiService } from 'services';
import { IApiResponse, IErrorResponse, IItemCategory, IGallery } from 'models';
import { addGallery } from 'redux/actions/gallery';
interface ILocationState {
	fromService: boolean;
}
interface IAddCategoryRouteParams {
	id: string;
}
interface ICategoryDetailProps
	extends IComponentProps<IAddCategoryRouteParams, ILocationState>,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}
interface ICategoryDetailState {
	showDeleteConfirm: boolean;
	isLoading?: boolean;
}

class CategoryDetailPage extends Component<
	ICategoryDetailProps,
	ICategoryDetailState
> {
	constructor(props: ICategoryDetailProps) {
		super(props);
		this.state = {
			showDeleteConfirm: false,
		};
	}
	componentDidMount() {
		//check if there's id but no data, get the data
		this.initialCheck();
	}

	componentDidUpdate(prevProps: ICategoryDetailProps) {
		if (prevProps.currentAction !== this.props.currentAction) {
			const { currentCategory, currentAction } = this.props;
			if (
				currentAction === CategoryActions.getCategoryById.successName &&
				_.isEmpty(currentCategory)
			) {
				this.props.history.push('/services');
				return;
			}
		}
	}

	componentWillUnmount() {
		this.props.removeData(); //clear the state for next operation
	}

	render() {
		return (
			<>
				{this.props.match.params.id && !this.props.currentCategory
					? this.renderLoading()
					: this.renderContainer()}
			</>
		);
	}

	renderLoading() {
		return (
			<Layout style={styles.spinLayout}>
				<Spin style={styles.spin} size="large" />
			</Layout>
		);
	}

	renderContainer() {
		const { id } = this.props.match.params;
		return (
			<DetailPageLayout
				title={I18n.t(
					_t(
						this.isAdd()
							? translations.categoryDetail.titleAddNew
							: translations.categoryDetail.titleEdit
					)
				)}
				positiveButtonName={I18n.t(_t(translations.save))}
				negativeButtonName={I18n.t(_t(translations.delete))}
				showDelete={this.isAdd()}
				isLoading={this.state.isLoading}
				goBack={() => this.goBack()}
				onDeleteClick={() => this.setState({ showDeleteConfirm: true })}
				onFormFinish={(name, info) => this.onFormFinish(name, info)}
			>
				{this.renderInput()}
				<Modal
					visible={this.state.showDeleteConfirm}
					title={I18n.t(_t(translations.delete))}
					handleOk={() => this.deleteCategory(id)}
					handleCancel={() => this.setState({ showDeleteConfirm: false })}
				>
					{I18n.t(_t(translations.categoryDetail.deleteWaringContent))}
				</Modal>
			</DetailPageLayout>
		);
	}

	renderInput() {
		const currentCategory = this.props.currentCategory;
		return (
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.categoryDetail.title))}
					description={I18n.t(_t(translations.categoryDetail.subTitle))}
				/>
				<Divider style={styles.divider} />
				{FormHelper.renderFormInput(
					'name',
					[
						{
							required: true,
							message: I18n.t(_t(translations.categoryDetail.messageName)),
						},
					],
					I18n.t(_t(translations.categoryDetail.labelName)),
					this.isAdd() ? '' : currentCategory?.name,
					{},
					{
						placeholder: I18n.t(_t(translations.placeHolder.categoryName)),
						maxLength: 256
					}
				)}
				{/* {FormHelper.renderFormInput(
          "code",
          [
            {
              required: true,
              message: I18n.t(_t(translations.categoryDetail.messageCode)),
            },
          ],
          I18n.t(_t(translations.categoryDetail.labelCode)),
          this.isAdd() ? "" : currentCategory?.code,
          {},
          {
            placeholder: I18n.t(_t(translations.placeHolder.egCode)),
          }
        )} */}
				{FormHelper.renderFormTextArea(
					'description',
					[
						{
							required: false,
						},
					],
					I18n.t(_t(translations.categoryDetail.labelDescription)),
					I18n.t(_t(translations.placeHolder.description)),
					this.isAdd() ? '' : currentCategory?.description
				)}
				{FormHelper.renderFormSwitch(
					'status',
					I18n.t(_t(translations.categoryDetail.labelStatus)),
					this.isAdd() ? true : currentCategory?.status === 0 ? false : true
				)}
				{/* {window.innerWidth < 768 ? this.renderButton() : null} */}
			</Card>
		);
	}

	initialCheck() {
		if (this.props.match.params.id && !this.props.currentCategory) {
			this.props.getCategoryById(this.props.match.params.id);
		}
	}

	isAdd() {
		return this.props.location.pathname.includes('add');
	}

	onFormFinish(formName: string, form: any) {
		const id = this.props.match.params.id || '';
		const name = form.values.name as string;
		const description = form.values.description as string;
		// const code = form.values.code as string;
		const status = (form.values.status as boolean) ? 1 : 0;
		const shopId = this.props.shopId;

		const galleryData = {
			title: name,
			description: description,
			status: status,
			shopId: shopId,
			pictureIds: [],
		};
		this.isAdd()
			? this.createCategory({ name, description, status, shopId }, galleryData)
			: this.updateCategory({
					id,
					name,
					description,
					status,
					shopId,
			  });
	}

	onDeleteClick() {}

	goBack() {
		const { state } = this.props.location;
		this.props.removeData();
		if (state && state.fromService) {
			this.props.history.goBack();
		} else {
			this.props.history.push('/services');
		}
	}

	createCategory = async (
		request: IAddCategoryRequest,
		galleryData: IAddGalleryRequest
	) => {
		this.setState({ isLoading: true });
		try {
			const result = (await CategoryApiService.addCategory(
				request
			)) as IApiResponse<IItemCategory>;
			if (result.succeeded) {
				this.setState(
					{
						isLoading: false,
					},
					() => {
						AlertHelper.showSuccess(
							I18n.t(_t(translations.categoryDetail.messageSuccess))
						);
						this.props.addNewCategory(result.data!);
						// this.props.addGallery(galleryData)
						this.goBack();
					}
				);
			} else {
				this.setState({ isLoading: false });
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.error(error);
		}
	};

	updateCategory = async (category: IEditCategoryRequest) => {
		this.setState({ isLoading: true });
		try {
			const result = (await CategoryApiService.editCategory(
				category
			)) as IApiResponse<IItemCategory>;
			if (result.succeeded) {
				this.setState(
					{
						isLoading: false,
					},
					() => {
						AlertHelper.showSuccess(
							I18n.t(_t(translations.categoryDetail.messageEditSuccess))
						);
						this.props.updateCategorySuccess(result.data!);
						this.goBack();
					}
				);
			} else {
				this.setState({ isLoading: false });
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.error(error);
		}
	};

	deleteCategory = async (id: string) => {
		this.setState({ isLoading: true });
		try {
			const result = (await CategoryApiService.deleteCategory(
				id
			)) as IApiResponse<string>;
			if (result.succeeded) {
				this.setState(
					{
						isLoading: false,
						showDeleteConfirm: false,
					},
					() => {
						AlertHelper.showSuccess(
							I18n.t(_t(translations.categoryDetail.messageDeleteSuccess))
						);
						this.props.deleteCategorySuccess(result.data!);
						this.goBack();
					}
				);
			} else {
				this.setState({ isLoading: false, showDeleteConfirm: false });
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.error(error);
		}
	};
}

const mapStateToProps = (state: RootState) => ({
	currentAction: state.ReduxActionReducer['CATEGORY'],
	isLoading: state.AppConfigReducer.showLoading,
	error: state.ErrorReducer.error?.message[0],
	currentCategory: state.CategoryReducer.selectedCategory,
	shopId: state.UserReducer.user!.shopId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	addNewCategory: (category: IItemCategory) => {
		dispatch(CategoryActions.addCategory.success(category));
	},
	removeData: () => {
		dispatch(CategoryActions.removeCurrentCategory.request());
		dispatch(ErrorActions.clearError.request());
	},
	updateCategorySuccess: (category: IItemCategory) => {
		dispatch(CategoryActions.editCategory.success(category));
	},
	clearError: () => {
		dispatch(ErrorActions.clearError.request());
	},
	getCategoryById: (id: string) => {
		dispatch(CategoryActions.getCategoryById.request(id));
	},
	deleteCategorySuccess: (id: string) => {
		dispatch(CategoryActions.deleteCategory.success(id));
	},
	addGallery: (gallery: IAddGalleryRequest) => {
		dispatch(GalleryActions.addGallery.request(gallery));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetailPage);
