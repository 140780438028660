import { TimeHelper } from 'helpers';
import _ from 'lodash';
import {
	EBookingInputFrom,
	IBooking,
	IBookingDetail,
	ICustomer,
	IEmployee,
	IHolidayDetail,
	IHolidayModel,
	IService,
} from 'models';
import moment from 'moment';
import { Event } from 'react-big-calendar';
import { BookingStatus } from 'utils/Consts';
import { toTimeZone } from './timeHelper';

export enum EventType {
	Booking,
	Holiday,
}
export interface IEventCalendar
	extends Event,
		Partial<IBookingDetail>,
		Partial<IHolidayDetail> {
	resource?: IEmployee;
	resourceId: string;
	description: string;
	services?: IService[];
	status?: number;
	ids?: string[];
	type: number;
	booking?: IBooking;
}

// function getTimeBooking(booking: IBooking) : {start: Date; end:Date} {
//   const start = !_.isEmpty(booking.startTime)
// }

export function convertHolidayToEvent(holidays: IHolidayModel[] = []) {
	let events: IEventCalendar[] = [];
	for (const holiday of holidays) {
		holiday.holidayDetails.forEach((holidayDetail) => {
			if (!_.isEmpty(holidayDetail.employeeId)) {
				const event: IEventCalendar = {
					...holidayDetail,
					resourceId: holidayDetail.employeeId!,
					description: holiday.holidayName!,
					type: EventType.Holiday,
					start: toTimeZone(holidayDetail.startTime).toDate(),
					end: toTimeZone(holidayDetail.endTime).toDate(),
				};
				events.push(event);
			}
		});
	}
	return events;
}

export function convertBookingToEvent(
	initialBookings: IBooking[]
): IEventCalendar[] {
	let events: IEventCalendar[] = [];
	const bookings = _.clone(initialBookings);
	for (const booking of bookings) {
		if (booking.status === BookingStatus.CANCELED) {
			continue;
		}
		booking.bookingDetails.forEach((bookingDetail, index) => {
			let lastBookingDetail =
				index > 0 ? booking.bookingDetails[index - 1] : null;
			if (
				lastBookingDetail &&
				lastBookingDetail.stylistId === bookingDetail.stylistId
			) {
				const eventIndex = _.findIndex(
					events,
					(event) => event.id === lastBookingDetail?.id
				);
				let event = Object.assign({}, events[eventIndex]);
				event!.end = toTimeZone(events[eventIndex].end)
					.add(bookingDetail.item?.duration, 'minutes')
					.toDate();
				event!.services?.push(bookingDetail.item!);
				event!.id = bookingDetail.id;
				event.ids?.push(bookingDetail.id!);
				events[eventIndex] = event;
				return;
			}
			const event: IEventCalendar = {
				...bookingDetail,
				title: `${booking.customer.firstName} ${booking.customer.lastName}`,
				start: toTimeZone(
					bookingDetail.startAt || bookingDetail.startAtExpected
				).toDate(),
				end: toTimeZone(
					bookingDetail.endAt || bookingDetail.endAtExpected
				).toDate(),
				description: bookingDetail.item?.name!,
				resourceId: _.isEmpty(bookingDetail.stylistId)
					? 'Anyone'
					: bookingDetail.stylistId!,
				resource: bookingDetail.stylist!,
				services: [bookingDetail.item!],
				status: booking.status,
				ids: [bookingDetail.id!],
				type: EventType.Booking,
				booking: booking,
			};
			events.push(event);
		});
	}
	return events;
}
