import React from 'react';
import { Upload, Modal, UploadProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import ImgCrop from 'antd-img-crop';
import _ from 'lodash';

interface IListImageUploadProps extends UploadProps {
	onGetImages(fileList: (File | undefined)[]): void;
	defaultImage?: string;
	imageCount?: number;
}
interface IListImageUploadState {
	previewVisible: boolean;
	previewImage: string;
	previewTitle: string;
	fileList: UploadFile[];
}
export default class ListImageUpload extends React.Component<
	IListImageUploadProps,
	IListImageUploadState
> {
	constructor(props: IListImageUploadProps) {
		super(props);
		this.state = {
			previewVisible: false,
			previewImage: '',
			previewTitle: '',
			fileList: !_.isEmpty(props.defaultImage)
				? [
						{
							uid: '-1',
							url: props.defaultImage,
							name: 'default.png',
						},
				  ]
				: [],
		};
	}

	render() {
		const { previewVisible, fileList, previewTitle } = this.state;
		const uploadButton = (
			<div>
				<PlusOutlined />
				<div style={{ marginTop: 8 }}>Upload</div>
			</div>
		);
		return (
			<ImgCrop rotate>
				<Upload
					name="avatar"
					listType="picture-card"
					className="avatar-uploader"
					onPreview={this.handlePreview}
					beforeUpload={() => false}
					fileList={this.state.fileList}
					onChange={(f) => this.handleChange(f.fileList)}
				>
					{fileList.length >=
					(this.props.imageCount ? this.props.imageCount : 1)
						? null
						: uploadButton}
				</Upload>
				<Modal
					visible={previewVisible}
					title={previewTitle}
					footer={null}
					onCancel={this.handleCancel}
				>
					{/* <img alt="example" style={{ width: '100%' }} src={previewImage} /> */}
				</Modal>
			</ImgCrop>
		);
	}
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = async (file: any) => {
		if (!file.url && !file.preview) {
			file.preview = await this.getBase64(file.originFileObj);
		}

		this.setState({
			previewImage: file.url || file.preview,
			previewVisible: true,
			previewTitle:
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
		});
	};

	handleChange = (fileList: UploadFile[]) => {
		this.setState({ fileList }, () => {
			if (this.props.onGetImages) {
				this.props.onGetImages(
					fileList.map((file) => (file.originFileObj as File) || undefined)
				);
			}
		});
	};

	getBase64(file: any) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}
}
