import { CalendarOutlined, RightOutlined } from '@ant-design/icons';
import { Col, ColProps, Input, Row } from 'antd';
import { EDatePickerType } from 'utils/Consts';

/**
 * This Components is use for pages that its header have an date type picker to selected date type like today, yesterday, monthly,...
 * This components should be wrapped in a <Row /> component
 */

interface IDateRangeSelectProps extends ColProps {
	fromDate: moment.Moment;
	toDate: moment.Moment;
	onDateRangeButtonClick: (type: EDatePickerType) => void;
	isLoading?: boolean;
}
const DateRangeSelect = (props: IDateRangeSelectProps) => {
	const { fromDate, toDate, onDateRangeButtonClick, isLoading } = props;
	const arrowStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	};
	return (
		<Col xs={24} sm={24} md={12} lg={8} {...props}>
			<Row gutter={16}>
				<Col span={11}>
					<Input
						className="max-width uneditable-input-with-icon"
						suffix={<CalendarOutlined />}
						allowClear={false}
						value={fromDate.format('MM/DD/YYYY')}
						onClick={() => onDateRangeButtonClick(EDatePickerType.FROM_DATE)}
						disabled={isLoading}
					/>
				</Col>
				<Col span={2} style={arrowStyle}>
					<RightOutlined />
				</Col>
				<Col span={11}>
					<Input
						className="max-width uneditable-input-with-icon"
						suffix={<CalendarOutlined />}
						allowClear={false}
						value={toDate.format('MM/DD/YYYY')}
						onClick={() => onDateRangeButtonClick(EDatePickerType.TO_DATE)}
						disabled={isLoading}
					/>
				</Col>
			</Row>
		</Col>
	);
};

export default DateRangeSelect;
