import {
	Button,
	Card,
	Col,
	Descriptions,
	Image,
	Result,
	Row,
	Space,
	Spin,
	Typography,
} from 'antd';
import {
	Header,
	BranchSelect,
	Clock,
	IComponentProps,
	Modal,
	PrintComponent,
} from 'components';
import styles from './styles';
import React from 'react';
import { goBackHistory, history } from 'routers';
import { Const, I18n, translations, _t } from 'utils';
import qs from 'qs';
import DynamicAntdTheme, { changeAntdTheme } from 'dynamic-antd-theme';

import {
	IAddCustomerRequest,
	IBookingSearchQuery,
	IUpdateBookingStatusRequest,
} from 'models/RequestModels';
import _ from 'lodash';
import { RootState } from 'redux/configuration/rootReducer';
import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { BookingActions, BranchActions, CustomerActions } from 'redux/actions';
import moment, { Moment } from 'moment';
import './style.css';
import Layout, { Content, Footer } from 'antd/lib/layout/layout';
import { ClockCircleOutlined, CloseOutlined } from '@ant-design/icons';
import QRCode from 'react-qr-code';
import {
	EBookingInputFrom,
	EBookingType,
	IApiResponse,
	IBooking,
	IBookingDetail,
	ICustomer,
	IErrorResponse,
} from 'models';
import { AlertHelper, BookingHelper, TimeHelper } from 'helpers';
import { IBranch, ILocation } from 'models/IBranch';
import ReactToPrint from 'react-to-print';
import { BookingStatus, BOOKING_RULES } from 'utils/Consts';
import { createESCCheckIn, print } from 'helpers/printHelper';
import CustomerInfo from './CustomerInfo';
import MainBooking from './MainBooking';
import CustomerBooking from './CustomerBooking';
import { getCoordsDistance } from 'helpers/mapHelpers';
import { StringHelper } from 'helpers';
import { toTimeZone } from 'helpers/timeHelper';
import { generateQrCode } from 'helpers/bookingHelper';
interface IBookingRouteParams {
	id: string;
}
interface IBookingProps
	extends IComponentProps<IBookingRouteParams>,
		ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps> {}
interface IBookingState {
	showModal: boolean;
	note?: string;
	loading: boolean;
	bookingType: EBookingType;
	date?: Moment;
}
class Booking extends React.Component<IBookingProps, IBookingState> {
	private printer?: ReactToPrint | null;
	constructor(props: IBookingProps) {
		super(props);
		const queries = this.getQueries();
		this.state = {
			showModal: false,
			note: props.currentBooking?.note,
			loading: false,
			bookingType: EBookingType.Appointment,
			date: this.checkIsEdit()
				? moment(
						this.props.bookingDetails[0].startAt ||
							this.props.bookingDetails[0].startAtExpected
				  )
				: queries?.date
				? moment(queries.date, 'MM-DD-YYYY')
				: TimeHelper.toTimeZone(new Date()),
		};
	}

	componentDidMount() {
		this.props.resetBooking();
		if (!_.isEmpty(this.props.location.state)) {
			this.props.setSelectedCustomer(
				this.props.location.state.customer as ICustomer
			);
		}

		const queries = this.getQueries();
		if (queries.color) {
			changeAntdTheme(`#${queries.color}`);
		}

		if (this.checkIsEdit()) {
			const bookingId = this.props.match.params.id;
			this.props.getBookingByID(bookingId);
		}
		// if (_.isEmpty(this.props.currentBranch) && !_.isEmpty(queries?.branchId)) {
		//   this.props.selectBranch({
		//     id: queries?.branchId!,
		//     shopId: queries?.shopId!,
		//   });
		// }
		if (this.checkIsCustomer()) {
			this.props.getBranchesByShopId(queries.shopId!);
		}
	}
	componentWillUnmount() {
		this.props.resetBooking();
	}
	async componentDidUpdate(prev: IBookingProps) {
		if (prev.currentBooking !== this.props.currentBooking) {
			if (
				!this.checkIsCustomer() &&
				this.props.currentAction === BookingActions.createBooking.successName
			) {
				this.props.history.replace(`/booking/${this.props.currentBooking?.id}`);
				return;
			}
			if (
				this.props.currentAction === BookingActions.getBookingById.successName
			) {
				this.setState({ note: this.props.currentBooking?.note });
				return;
			}
		}
		if (prev.currentBranchAction !== this.props.currentBranchAction) {
			if (
				this.checkIsCustomer() &&
				this.props.currentBranchAction ===
					BranchActions.getBranchesByShopId.successName
			) {
				const queries = this.getQueries();
				if (queries.branchId) {
					this.props.selectBranch({
						id: queries?.branchId,
					});
				} else {
					if (this.props.branches.length > 1) {
						const closestBranch = await this.getBranchByLocation();
						this.props.selectBranch({
							id: closestBranch?.id,
						});
					} else {
						this.props.selectBranch({
							id: this.props.branches[0].id,
						});
					}
				}
				return;
			}
		}
	}
	render() {
		const customerName = !_.isEmpty(this.props.selectedCustomer)
			? `${this.props.selectedCustomer?.firstName} ${this.props.selectedCustomer?.lastName}`
			: '';
		// if (
		// 	this.checkIsCustomer() &&
		// 	this.props.shop?.adminUrl?.includes('squarenails')
		// ) {
		// 	return (
		// 		<Result
		// 			icon={<ClockCircleOutlined />}
		// 			title={I18n.t(_t(translations.bookingCustomerInfo.comingSoon))}
		// 			extra={
		// 				<Button
		// 					onClick={() => {
		// 						if (!_.isEmpty(this.props.shop?.portalUrl)) {
		// 							window.parent.location.href = 'https://squarenailstudio.com';
		// 						} else {
		// 							history.goBack();
		// 						}
		// 					}}
		// 					type="primary"
		// 				>
		// 					{I18n.t(_t(translations.goBackHome))}
		// 				</Button>
		// 			}
		// 		/>
		// 	);
		// }

		return (
			<Spin
				spinning={
					this.props.currentAction ===
						BookingActions.getBookingById.requestName ||
					this.props.currentAction ===
						BookingActions.createBooking.requestName ||
					(this.props.location.pathname.includes('new')
						? false
						: _.isEmpty(this.props.currentBooking))
				}
			>
				<Layout style={{ minHeight: '100vh', backgroundColor: 'white' }}>
					{this.renderHeader()}
					{this.renderContent()}
					{this.renderQRPrint()}
					{this.checkIsCustomer() ? this.renderFooter() : null}
					<Modal
						visible={this.state.showModal}
						title={I18n.t(
							_t(translations.bookingCustomerInfo.alertEarlyCheckInTitle)
						)}
						handleOk={() => this.onCheckIn()}
						danger={false}
						OKString={I18n.t(
							_t(translations.bookingCustomerInfo.expressCheckIn)
						)}
						handleCancel={() => this.setState({ showModal: false })}
					>
						{I18n.t(
							_t(translations.bookingCustomerInfo.alertEarlyCheckInMessage),
							{
								customerName: customerName,
							}
						)}
					</Modal>
					{/* <DynamicAntdTheme primaryColor="red" /> */}
				</Layout>
			</Spin>
		);
	}
	renderHeader() {
		return (
			<Col>
				<Header
					isBookingPage
					bookingPageProps={{
						title: I18n.t(_t(translations.bookingPage.booking)),
						isCustomer: this.checkIsCustomer(),
						onClosePress: () => this.onGoBack(),
					}}
				/>
			</Col>
		);
	}
	renderContent() {
		return (
			<Content style={{ marginTop: 64, height: '100%' }}>
				<Row style={{ height: '100%' }}>
					{!_.isEmpty(this.props.currentBranch)
						? this.renderMainBooking()
						: this.renderLoading()}
					{this.checkIsCustomer() ? null : this.renderCustomerBooking()}
				</Row>
			</Content>
		);
	}
	renderMainBooking() {
		const queries = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		}) as IBookingSearchQuery;

		return (
			<Col style={styles.colContainer} md={this.checkIsCustomer() ? 24 : 16}>
				{this.checkIsCustomer() &&
				this.props.currentAction ===
					BookingActions.createBooking.successName ? (
					this.renderBookingSuccess()
				) : this.checkIsCustomer() && _.isEmpty(this.props.selectedCustomer) ? (
					<CustomerInfo />
				) : (
					<MainBooking
						isEdit={this.checkIsEdit()}
						isCustomer={this.checkIsCustomer()}
						canEdit={this.checkCanEdit()}
						queries={queries}
						note={this.state.note}
						onChangeDate={(date) => this.setState({ date })}
						onChangeNote={(note) => this.setState({ note })}
						isNewCustomer={this.props.isNewCustomer}
					/>
				)}
			</Col>
		);
	}
	renderLoading() {
		return (
			<Col style={styles.colContainer} md={this.checkIsCustomer() ? 24 : 16}>
				<Spin
					size={'large'}
					style={{ width: '100%', backgroundColor: 'white', padding: 24 }}
				/>
			</Col>
		);
	}
	renderCustomerBooking() {
		const isCustomer = this.checkIsCustomer();
		if (isCustomer) {
			return null;
		}
		return (
			<Col className="bookingCustomer" style={styles.colContainer} md={8}>
				<CustomerBooking
					canCheckIn={this.checkCanCheckIn()}
					canCheckOut={this.checkCanCheckOut()}
					checkedIn={this.checkIsCheckIn()}
					checkedOut={this.checkIsCheckOut()}
					isEdit={this.checkIsEdit()}
					canWalkIn={this.checkCanWalkIn()}
					onSubmitBooking={() => this.onSubmit()}
					onCheckInOut={() => this.onCheckInOut()}
					bookingType={this.state.bookingType}
					onChangeBookingType={(bookingType) => this.setState({ bookingType })}
					isDone={this.props.currentBooking?.status === BookingStatus.DONE}
				/>
			</Col>
		);
	}

	renderFooter() {
		return (
			<Footer
				style={{
					position: 'sticky',
					bottom: 0,
					backgroundColor: 'white',
					boxShadow: '5px 8px 24px 5px rgba(0, 0, 0, 0.4)',
				}}
			>
				<Row gutter={[16, 16]} justify="space-between" align="middle">
					<Col style={{ textAlign: 'center' }}>
						<Typography.Text>
							{I18n.t(_t(translations.bookingPage.poweredBy))}
							<strong>Smart Salon - SCS Solutions</strong>
						</Typography.Text>
					</Col>
					<Col>
						<Row gutter={[8, 8]}>
							{!_.isEmpty(this.props.bookingDetails[0]) &&
							this.props.currentAction !==
								BookingActions.createBooking.successName
								? this.renderButtonConfirm()
								: null}
							{this.renderCancel()}
						</Row>
					</Col>
				</Row>
			</Footer>
		);
	}

	renderButtonConfirm() {
		return (
			<Col>
				<Button
					disabled={
						_.isEmpty(this.props.bookingDetails[0].item) ||
						_.isEmpty(this.props.activeTimeId)
					}
					style={styles.btnConfirm}
					onClick={() => this.onSubmit()}
					size="middle"
					type="primary"
				>
					{I18n.t(_t(translations.bookingCustomerInfo.saveBooking))}
				</Button>
			</Col>
		);
	}
	renderCancel() {
		return (
			<Col>
				<Button
					style={styles.btnConfirm}
					onClick={() => {
						if (!_.isEmpty(this.props.shop?.portalUrl)) {
							window.parent.location.href = 'https://squarenailstudio.com';
						} else {
							history.goBack();
						}
					}}
					size="middle"
					type="ghost"
				>
					{I18n.t(_t(translations.goBackHome))}
				</Button>
			</Col>
		);
	}
	renderBookingSuccess() {
		return (
			<Row align={'middle'} justify={'center'}>
				<Col>
					<Result
						status="success"
						title={
							<Typography.Text strong>
								{I18n.t(_t(translations.bookingPage.successTitle))}
							</Typography.Text>
						}
						subTitle={
							<Typography.Text strong style={{ fontSize: 16 }}>
								{I18n.t(_t(translations.bookingPage.successSubTitle))}
							</Typography.Text>
						}
						style={{ textAlign: 'center' }}
						extra={[
							<Button
								onClick={() => this.onBookOther()}
								type="primary"
								key="console"
							>
								{I18n.t(_t(translations.bookingPage.bookOther))}
							</Button>,
							this.renderQRCodeButton(),
						]}
					/>
					{this.renderBookingInfo()}
					<Row justify={'center'}>
						<QRCode
							value={generateQrCode(this.props.currentBooking?.id!)}
							size={200}
						/>
					</Row>
				</Col>
			</Row>
		);
	}

	renderBookingInfo() {
		const { currentBooking, currentBranch, currentShop } = this.props;
		return (
			<Col style={{ marginBottom: 10, marginTop: 20 }}>
				{this.renderBookingInfoItem({
					title: I18n.t(_t(translations.bookingPage.shop)),
					value: currentShop?.name!,
				})}
				{this.renderBookingInfoItem({
					title: I18n.t(_t(translations.bookingPage.startTimeAt)),
					value: TimeHelper.toTimeZone(
						currentBooking?.startTimeExpected
					).format('LT'),
				})}
				{this.renderBookingInfoItem({
					title: I18n.t(_t(translations.address)),
					value: `${currentBranch?.address?.street}, ${currentBranch?.address?.state}, ${currentBranch?.address?.city}`,
				})}
			</Col>
		);
	}

	renderBookingInfoItem({ title, value }: { title: string; value: string }) {
		return (
			<Row style={{ justifyContent: 'space-between' }}>
				<Typography.Text strong style={{ color: '#9F9F9F' }}>
					{title}
				</Typography.Text>
				<Typography.Text>{value}</Typography.Text>
			</Row>
		);
	}

	renderQRCodeButton() {
		if (this.checkIsCustomer()) {
			return (
				<Button onClick={() => this.onSaveQRCode()} key="buy">
					{I18n.t(_t(translations.bookingPage.saveQRCode))}
				</Button>
			);
		} else {
			return (
				<Button onClick={() => this.onPrintQr()} key="buy">
					{I18n.t(_t(translations.bookingPage.printQRCode))}
				</Button>
			);
		}
	}

	renderQRPrint() {
		const { selectedCustomer, currentBooking, currentBranch, shop } =
			this.props;
		const imageSrc = `data:image/jpeg;base64,${currentBooking?.qrCode}`;
		return (
			<PrintComponent refPrinter={(ref) => (this.printer = ref!)}>
				<Card
					title={`${shop?.name} -  ${currentBranch?.name}`}
					style={{ width: '80mm', textAlign: 'center', borderBottomWidth: 0 }}
				>
					<Typography.Text style={{ fontSize: '.9em' }}>
						{I18n.t(_t(translations.bookingPage.showQRCode))}
					</Typography.Text>
					<Descriptions
						layout="horizontal"
						column={1}
						labelStyle={{ fontSize: '.4em' }}
						size={'small'}
						bordered
						contentStyle={{ fontWeight: 'bold', fontSize: '.4em' }}
						style={{ marginTop: 10 }}
					>
						<Descriptions.Item
							label={I18n.t(_t(translations.mainBooking.name))}
						>
							{`${selectedCustomer?.firstName} ${selectedCustomer?.lastName}`}
						</Descriptions.Item>
						<Descriptions.Item
							label={I18n.t(_t(translations.mainBooking.phone))}
						>
							{StringHelper.formatPhoneNumber(selectedCustomer?.phone)}
						</Descriptions.Item>
					</Descriptions>
					<Image style={{ width: '70mm' }} src={imageSrc} />
				</Card>
			</PrintComponent>
		);
	}

	b64toBlob = (dataURI: any) => {
		var byteString = atob(dataURI.split(',')[1]);
		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);

		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], { type: 'image/jpeg' });
	};
	checkCanEdit = (): boolean => {
		if (!this.checkIsEdit()) {
			return true;
		}
		const status = this.props.currentBooking?.status;
		if (
			status === Const.BookingStatus.CONFIRMED ||
			status === Const.BookingStatus.PENDING
		) {
			return toTimeZone(this.props.currentBooking?.startTimeExpected)
				.add(30, 'minutes')
				.isAfter(moment());
		}
		if (status === Const.BookingStatus.CHECKED_IN) {
			return true;
		}
		return false;
	};
	checkIsEdit = (): boolean => {
		return !_.isEmpty(this.props.match.params.id);
	};
	checkCanCheckIn = (): boolean => {
		let canCheckIn = true;
		const { currentBranch } = this.props;
		// return true;
		const diffTime = Math.abs(
			moment()
				.utc()
				.diff(
					moment.utc(this.props.currentBooking?.startTimeExpected),
					'minute'
				)
		);
		if (
			moment()
				.utc()
				.isBefore(moment.utc(this.props.currentBooking?.startTimeExpected)) &&
			diffTime > BOOKING_RULES.defaultEarly
		) {
			return false;
		}
		if (
			moment()
				.utc()
				.isAfter(moment.utc(this.props.currentBooking?.startTimeExpected)) &&
			diffTime >
				(currentBranch?.lateArrivalTimeAllowed || BOOKING_RULES.defaultLateTime)
		) {
			return false;
		}

		return canCheckIn;
	};

	checkCanCheckOut = () => {
		//can't checkout if existing a booking detail hasn't stylist
		return _.isEmpty(
			_.find(
				this.props.currentBooking?.bookingDetails,
				(detail) => _.isEmpty(detail.stylist) || _.isEmpty(detail.stylistId)
			)
		);
	};
	checkIsCustomer = (): boolean => {
		const queries = this.getQueries();
		return !_.isEmpty(queries.isCustomer);
	};

	checkIsCheckIn = (): boolean => {
		return (
			this.props.currentBooking?.status === Const.BookingStatus.PENDING ||
			this.props.currentBooking?.status === Const.BookingStatus.CONFIRMED
		);
	};
	checkIsCheckOut = (): boolean => {
		return (
			this.props.currentBooking?.status !== Const.BookingStatus.PENDING &&
			this.props.currentBooking?.status !== Const.BookingStatus.ERROR
		);
	};
	checkCanWalkIn = (): boolean => {
		return moment(moment(this.state.date).format('MM-DD-YYYY')).isSame(
			moment().format('MM-DD-YYYY')
		);
	};

	getQueries = (): IBookingSearchQuery => {
		return qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		}) as IBookingSearchQuery;
	};
	onCheckInOut = () => {
		if (this.checkIsCheckIn()) {
			if (
				Math.abs(
					moment
						.utc(this.props.currentBooking?.startTimeExpected)
						.diff(moment(), 'minute')
				) > 30
			) {
				this.setState({ showModal: true });
			} else {
				this.onCheckIn();
			}
			return;
		}
		if (this.checkIsCheckOut()) {
			this.props.history.replace(
				`/checkout?bookingId=${this.props.currentBooking?.id}`
			);
			return;
		}
	};
	onCheckIn = async () => {
		this.setState({ showModal: false });

		if (this.props.currentBranch?.isEnableTicketPrinting) {
			const checkInCommands = await createESCCheckIn(
				this.props.selectedCustomer!,
				this.props.currentBooking?.id!,
				this.props.currentBooking?.bookingNumber
			);
			await print(checkInCommands, this.printer!, true);
		}

		this.updateCheckIn();
	};
	updateCheckIn = () => {
		this.props.updateBookingStatus({
			id: this.props.currentBooking?.id!,
			status: Const.BookingStatus.CHECKED_IN,
		});
	};

	onBookOther = () => {
		if (window.self !== window.top) {
			if (!_.isEmpty(this.props.shop?.portalUrl)) {
				window.parent.location.href = `https://squarenailstudio.com/booking`;
			}
		} else {
			this.props.history.go(0);
		}
	};
	onPrintQr = () => {
		this.printer?.handlePrint();
	};
	onSaveQRCode = () => {
		const { currentBooking } = this.props;
		const imageSrc = `data:image/jpeg;base64,${currentBooking?.qrCode}`;
		const blob = this.b64toBlob(imageSrc);
		const url = URL.createObjectURL(blob);
		window.open(url);
		// DownloadHelper.downloadFile(
		//   imageSrc,
		//   `booking_${moment(currentBooking?.startTimeExpected).format("MM/DD/YYYY-HHmm")}.png`
		// );
	};
	getLocation = () => {
		return new Promise((res, rej) => {
			navigator.geolocation.getCurrentPosition(res, rej);
		});
	};

	getBranchByLocation = async () => {
		const currentLocation = (await this.getLocation()) as GeolocationPosition;
		if (!currentLocation.coords) {
			return this.props.branches[0];
		}
		const location: ILocation = {
			latitude: currentLocation.coords.latitude,
			longitude: currentLocation.coords.longitude,
		};
		return _.minBy(this.props.branches, (branch) => {
			return getCoordsDistance(location, branch.location!);
		});
	};
	onGoBack = () => {
		goBackHistory();
	};

	onSubmit = () => {
		const { bookingDetails } = this.props;
		const duration = _.sumBy(
			this.props.bookingDetails,
			(bookingDetail) => bookingDetail.item?.duration!
		);
		const startTimeId = this.props.activeTimeId;
		const startTimeString = TimeHelper.getTimesFromBlock(startTimeId!);

		const startDateTime = moment.utc(`${startTimeString}`);
		const endDateTime = moment.utc(startDateTime).add(duration, 'minutes');
		const convertedBookingDetails = BookingHelper.convertBookingDetails(
			bookingDetails,
			startDateTime
		);
		const booking: Partial<IBooking> = {
			date: TimeHelper.toTimeZone(moment.utc(startDateTime).toDate()).format(
				'YYYY-MM-DDT00:00:00'
			),
			inputFrom: EBookingInputFrom.POS,
			startTimeExpected: startDateTime.toDate(),
			endTimeExpected: endDateTime.toDate(),
			stylistId:
				_.isEmpty(bookingDetails[0].stylistId) ||
				bookingDetails[0].stylistId === 'Anyone'
					? undefined
					: bookingDetails[0].stylistId,
			note: this.state.note,
			discount: 0,
			branchId: this.props.currentBranch?.id,
			shopId: this.props.currentBranch?.shopId,
			bookingDetails: convertedBookingDetails,
			totalAmount: _.sumBy(
				convertedBookingDetails,
				(bookingDetail) => bookingDetail.amount!
			),
			realAmount: _.sumBy(
				convertedBookingDetails,
				(bookingDetail) => bookingDetail.amount! - (bookingDetail.discount || 0)
			),
			totalQuantity: convertedBookingDetails.length,
			bookingType: this.state.bookingType,
		};

		if (this.checkIsEdit()) {
			var updateBooking: Partial<IBooking> = {
				...this.props.currentBooking,
				...booking,
				bookingType: this.props.currentBooking?.bookingType,
				customer: undefined,
				stylist: undefined,
				customerId: this.props.selectedCustomer?.id!,
				totalTax: _.sumBy(booking.bookingDetails, (x) =>
					BookingHelper.calculateBookingDetailTax(
						x,
						this.props.currentBranch?.taxPercent
					)
				),
			};
			this.props.editBooking(updateBooking);
			return;
		}
		if (this.props.isNewCustomer) {
			this.props.createBookingWithNewCustomer({
				customer: this.props.selectedCustomer!,
				booking,
			});
			return;
		} else {
			const newBooking: Partial<IBooking> = {
				...booking,
				customerId: this.props.selectedCustomer?.id,
			};
			this.props.createBooking(newBooking);
			return;
		}
	};
}

const mapStateToProps = (state: RootState) => ({
	currentAction: state.ReduxActionReducer['BOOKING'],
	currentBranchAction: state.ReduxActionReducer['BRANCH'],
	currentBranch: state.BranchReducer.currentBranch,
	currentShop: state.ShopReducer.shop,
	currentBooking: state.BookingReducer.currentBooking,
	user: state.UserReducer.user,
	bookingDetails: state.BookingReducer.bookingDetails,
	selectedCustomer: state.BookingReducer.selectedCustomer,
	activeTimeId: state.BookingReducer.timeActiveId,
	shop: state.ShopReducer.shop,
	branches: state.BranchReducer.branches,
	isNewCustomer: state.BookingReducer.isNewCustomer,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	getBookingByID: (bookingId: string) =>
		dispatch(BookingActions.getBookingById.request(bookingId)),
	createBooking: (booking: Partial<IBooking>) =>
		dispatch(BookingActions.createBooking.request(booking)),
	editBooking: (booking: Partial<IBooking>) =>
		dispatch(BookingActions.editBooking.request(booking)),
	updateBookingStatus: (params: IUpdateBookingStatusRequest) =>
		dispatch(BookingActions.updateStatus.request(params)),
	resetBooking: () => dispatch(BookingActions.resetBooking.request()),
	getBranchesByShopId: (shopId: string) =>
		dispatch(BranchActions.getBranchesByShopId.request(shopId)),
	selectBranch: (branch: Partial<IBranch>) => {
		dispatch(BranchActions.selectBranch.request(branch));
	},
	setSelectedCustomer: (customer: ICustomer) => {
		dispatch(BookingActions.selectCustomer.request(customer));
	},
	addNewCustomer: (request: IAddCustomerRequest) => {
		dispatch(BookingActions.addCustomer.request(request));
	},
	createBookingWithNewCustomer: (request: {
		customer: Partial<ICustomer>;
		booking: Partial<IBooking>;
	}) => {
		dispatch(BookingActions.createBookingWithNewCustomer.request(request));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
