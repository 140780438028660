import { kStyles } from "GlobalStyles";

const styles: kStyles = {
  nailForm: {
    backgroundColor: "white",
    display: "block",
    padding: "24px",
    marginTop: "24px",
  },
  alert: {
    margin: "16px 0",
  },
};

export default styles;
