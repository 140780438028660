import React, { useEffect } from 'react';
import { Alert, Button, Space } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import { useAppSelector } from 'helpers/hookHelpers';

export const CallProcess = () => {
	const calling = useAppSelector((state) => state.AppConfigReducer.calling);

	// useEffect(() => {
	//     let onCallListener = TwilioServiceInstance.device.addListener("")
	// }, [])
	const TwilioServiceInstance = require('services/TwilioServices');

	if (!calling) {
		return null;
	}
	return (
		<Alert
			message="Calling in Process"
			icon={<PhoneOutlined />}
			description="You are now calling with Support"
			type="success"
			action={
				<Button
					onClick={() => TwilioServiceInstance.endCall()}
					size="small"
					danger
				>
					End Call
				</Button>
			}
		/>
	);
};
