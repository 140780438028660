import { PayloadAction } from '@reduxjs/toolkit';
import { AlertHelper } from 'helpers';
import { IApiResponse } from 'models/IApiResponse';
import { IErrorResponse } from 'models/IErrorResponse';
import { IShop } from 'models/IShop';
import { put, takeEvery, call } from 'redux-saga/effects';
import { ShopActions } from 'redux/actions';
import ShopApiService from 'services/ShopApiService';

function* getShopId(action: PayloadAction) {
	try {
		// const shopDomain = "smart-salons";
		const domain = window.location.hostname;
		const shopDomain = domain.includes('www.')
			? domain.split('.')[1]
			: domain.split('.')[0];
		// const shopDomain = 'lavi-local'
		const result: IApiResponse<IShop> = yield call(
			ShopApiService.getShopByAdminUrl,
			shopDomain
		);
		// yield put(ShopActions.getShopId.success(shopId));
		if (result.succeeded) {
			yield put(ShopActions.getShop.success(result.data!));
		} else {
			const error = result as IErrorResponse;
			console.log('ASSAASASAS', window.location.hostname);
			if (
				!window.location.hostname.includes('partners.smartsalon') &&
				!window.location.hostname.includes('partners.localhost')
			) {
				// window.location.href = 'https://smartsalon.live/';
				yield put(ShopActions.getShop.failed(error));
			}
		}
	} catch (error) {
		yield put(ShopActions.getShop.failed(error));
	}
}

function* updateShop(action: PayloadAction<IShop>) {
	try {
		const result: IApiResponse<IShop> = yield call(
			ShopApiService.updateShop,
			action.payload
		);
		if (result.succeeded) {
			yield put(ShopActions.updateShop.success(result.data!));
		} else {
			AlertHelper.showError(result as IErrorResponse);
			yield put(ShopActions.updateShop.failed(result as IErrorResponse));
		}
	} catch (error) {
		yield put(ShopActions.updateShop.failed(error));
	}
}

export function* ShopWatcher() {
	yield takeEvery(ShopActions.getShop.requestName, getShopId);
	yield takeEvery(ShopActions.updateShop.requestName, updateShop);
}
