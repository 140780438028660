import _ from 'lodash';
import { IApiResponse, IBooking } from 'models';
import { IShortly } from 'models/IShortly';
import React from 'react';
import { history } from 'routers';
import ShortlyApiService from 'services/ShortlyApiService';
import '../../loading.css';

export class Shortly extends React.Component {
	state = {
		isFalse: false,
	};
	async componentDidMount() {
		const path = history.location.pathname;
		const shortlyId = path.split('/')[2];
		if (shortlyId) {
			const shortlyResponse = (await ShortlyApiService.getById(
				shortlyId
			)) as IApiResponse<IShortly>;
			if (_.isEmpty(shortlyResponse.errors) && shortlyResponse.succeeded) {
				const shortly = shortlyResponse.data;
				if (shortly?.controller === 'Rating') {
					const booking = JSON.parse(shortly.objectData) as any;
					if (booking.Id && booking.BranchId) {
						history.push(`/rating/${booking.BranchId}/${booking.Id}`);
					}
				}
			} else {
				window.location.href = 'https://smartsalon.live/';
			}
		} else {
			window.location.href = 'https://smartsalon.live/';
		}
	}

	render() {
		return this.renderLoading();
	}

	renderLoading() {
		return (
			<div className="loading-body">
				<div className="sk-cube-grid">
					<div className="sk-cube sk-cube1" />
					<div className="sk-cube sk-cube2" />
					<div className="sk-cube sk-cube3" />
					<div className="sk-cube sk-cube4" />
					<div className="sk-cube sk-cube5" />
					<div className="sk-cube sk-cube6" />
					<div className="sk-cube sk-cube7" />
					<div className="sk-cube sk-cube8" />
					<div className="sk-cube sk-cube9" />
				</div>
			</div>
		);
	}
}
