import React, { useEffect, useState } from 'react';
import CheckOutBaseModal from './CheckOutBaseModal';
import { translations, _t, I18n } from 'utils';
import _ from 'lodash';
import {
	Col,
	Input,
	Typography,
	Button,
	Row,
	Space,
	Descriptions,
	Spin,
} from 'antd';
import styles from './styles';
import { IGetAvailablePromotionsRequest, IPromotion } from 'models/IPromotion';
import moment from 'moment';
import PromotionApiService from 'services/PromotionApiService';
import { IApiResponse, IBooking } from 'models';
import { showError } from 'helpers/alertHelper';
import { formatBalance } from 'helpers/currencyHelper';
import { PRIMARY_COLOR } from 'utils/Colors';

interface IPromotionListModalProps {
	onOk: (promotion: IPromotion) => void;
	onCancel: () => void;
	currentBooking: IBooking;
	listBookingCombined: IBooking[];
}

const RowItem = (props: {
	promotion: IPromotion;
	onChoosePromotion: (promotion: IPromotion) => void;
}) => {
	const { promotion } = props;

	const endDate = !_.isEmpty(promotion.endDateUtc)
		? `- ${moment(promotion.endDateUtc).format('MM/DD/YYYY')}`
		: `- Infinity`;
	return (
		<Col md={12} xl={8} onClick={() => props.onChoosePromotion(promotion)}>
			<Col
				style={{
					borderStyle: 'solid',
					borderWidth: 0.5,
					color: '#cdcd',
					borderRadius: 8,
					paddingInline: 12,
					paddingBlock: 8,
				}}
			>
				<DescriptionsItem
					title="Promotion name"
					value={promotion.name}
					valueColor={PRIMARY_COLOR}
				/>
				<DescriptionsItem
					title="Promotion Duration"
					value={`${moment(promotion.startDateUtc).format(
						'MM/DD/YYYY'
					)} ${endDate}`}
				/>
				<DescriptionsItem
					title="Promotion Type"
					value={promotion.promotionTypeCode}
				/>
				<DescriptionsItem
					title="Discount Amount"
					value={
						!promotion.usePercentage
							? formatBalance(promotion.discountAmount)
							: `${promotion.discountPercentage}%`
					}
				/>
				<DescriptionsItem title="Description" value={promotion.note} />
			</Col>
		</Col>
	);
};

const DescriptionsItem = (props: {
	title: string;
	value: string;
	valueColor?: string;
}) => {
	return (
		<Descriptions
			style={{
				width: '100%',
				alignContent: 'baseline',
				alignItems: 'baseline',
			}}
			labelStyle={{ fontSize: 14 }}
			contentStyle={{
				justifyContent: 'flex-end',
				alignContent: 'baseline',
				fontSize: 16,
				fontWeight: 'bold',
				color: props.valueColor,
			}}
			column={1}
		>
			<Descriptions.Item style={{ paddingBottom: 1 }} label={props.title}>
				{props.value}
			</Descriptions.Item>
		</Descriptions>
	);
};

const PromotionListModal = (props: IPromotionListModalProps) => {
	const { currentBooking, listBookingCombined } = props;

	const [loading, setLoading] = useState(false);
	const [promotions, setPromotions] = useState<IPromotion[]>([]);

	useEffect(() => {
		getAvailablePromotions();
	}, []);

	const getAvailablePromotions = async () => {
		setLoading(true);
		let listAllBookingDetails = Array.from(currentBooking.bookingDetails || []);
		if (!_.isEmpty(listBookingCombined)) {
			listBookingCombined.forEach((booking) => {
				booking.bookingDetails.map((bookingDetail) =>
					listAllBookingDetails.push(bookingDetail)
				);
			});
		}
		const request: IGetAvailablePromotionsRequest = {
			date: moment.utc(moment()).format('MM-DD-YYYY HH:mm'),
			customerId: currentBooking.customerId!,
			branchId: currentBooking.branchId!,
			bookingInfos: listAllBookingDetails.map((x) => {
				return {
					itemId: x.itemId || '',
					price: _.sum([x.item?.price, x.extraAmount]),
				};
			}),
		};
		const res = await PromotionApiService.getAvailablePromotion(request);
		setLoading(false);
		if (!_.isEmpty(res.errors)) {
			showError(res.errors);
		}
		const resData = res as IApiResponse<IPromotion[]>;
		if (resData.succeeded && !_.isEmpty(resData.data)) {
			setPromotions(resData.data!);
		}
	};
	const onOk = (promotion: IPromotion) => {
		props.onOk(promotion);
	};
	const onCancel = () => {
		props.onCancel();
	};
	return (
		<CheckOutBaseModal
			title={'Applied Promotion List'}
			onOk={() => {}}
			onCancel={onCancel}
			disableOk={false}
			width={'100%'}
			centered
			btnOkHidden
		>
			<Spin spinning={loading}>
				<Col style={{ minHeight: '60vh' }}>
					<Row gutter={[16, 16]} align="middle">
						{promotions.map((x) => (
							<RowItem
								key={x.id}
								promotion={x}
								onChoosePromotion={(promotion) => onOk(promotion)}
							/>
						))}
					</Row>
				</Col>
			</Spin>
		</CheckOutBaseModal>
	);
};

export default PromotionListModal;
