import { Col, Modal, ModalProps, Typography, Button, Calendar } from "antd";
import React from "react";
import { translations, _t, I18n } from "utils";
import moment, { Moment } from "moment";
import { kStyles } from "GlobalStyles";
import ModalHelper from "helpers/modalHelper";

interface IDatePickerModalProps extends ModalProps {
  onCancel(): void;
  onPickedDate(date: moment.Moment): void;
  selectedDate: moment.Moment;
}

interface IDatePickerModalState {}

class DatePickerModal extends React.Component<IDatePickerModalProps, IDatePickerModalState> {
  render() {
    return (
      <ModalHelper
        style={styles.modalStyle}
        className="date-picker-modal"
        {...this.props}
        visible={true}
        title={I18n.t(_t(translations.mainBooking.selectDate))}
        width={1000}
        onCancel={() => this.props.onCancel()}
        onOk={() => null}
        btnOkHidden={true}
        // footer={<Button onClick={() => this.props.onCancel()}>{I18n.t(_t(translations.cancel))}</Button>}
      >
        {this.renderCalendar()}
      </ModalHelper>
    );
  }

  renderCalendar = () => {
    return (
      <Col style={styles.colCalendar}>
        <Calendar
          value={this.props.selectedDate}
          onSelect={(date) => this.props.onPickedDate(date)}
          disabledDate={(date) => date.endOf("day").isBefore(moment())}
        />
      </Col>
    );
  };
}

const styles: kStyles = {
  modalStyle: { top: 50 },
  title: { textAlign: "center" },
  colCalendar: { height: 500, overflowY: "auto", overflowX: "hidden" },
};

export default DatePickerModal;
