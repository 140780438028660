import { IEmployeeType } from 'models';
import { IBaseModel } from 'models/Base.model';
import { IAddress } from 'models/IAddress';
import { Moment } from 'moment';
import { WorkingHour } from './IBranch';

export interface IEmployee extends IBaseModel {
	id: string;
	userId: string;
	hireDate: Date | Moment;
	employeeTypeId: string;
	employeeTypes?: IEmployeeType[];
	departmentId: string;
	dateOfBirth?: Date | Moment;
	commissionPercent?: number;
	imageUrl?: string;
	branchId: string;
	code: string;
	firstName: string;
	lastName: string;
	additionalName?: string;
	phone?: string;
	email?: string;
	color?: string;
	gender?: number;
	status?: number;
	address?: IAddress;
	userName?: string;
	incomeType?: number;
	employeeTypeCode?: EEmployeeTypeCode;
	salaryType?: number;
	salary?: number;
	servicesCommissionPercent?: number;
	productCommissionPercent?: number;
	giftCardCommissionPercent?: number;
	checkPercent?: number;
	cardChargePercent?: number;
	tipCardChargePercent?: number;
	salaryEffectiveStartDate?: Date | Moment;
	turn?: number;
	serviceIds?: string[];
	employeeWorkingTimes?: WorkingHour[];
	canBookedOnline?: boolean;
}
export enum EEmployeeTypeCode {
	RECEPTIONIST = 'receptionist',
	STYLIST = 'stylist',
	MANAGER = 'Manager',
}

export enum EUserRoles {
	TECHNICIAN = 'Technician',
	MANAGER = 'Manager',
}
