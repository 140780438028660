import React from 'react';
import './App.less';
import configureStore from 'redux/configuration/configureStore';
import { connect } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import { AdminRouter, AuthenticationRouter, history } from 'routers';
import { RootState } from 'redux/configuration/rootReducer';
import _ from 'lodash';
import { AuthRoute } from 'routers/routes';
import {
	Booking,
	ConfirmBookingPage,
	CustomerBooking,
	RatingPage,
} from 'pages';
import './loading.css';
import NotFound from 'pages/NotFound';
import Partner from 'pages/Partner';
import { Shortly } from 'pages/Shortly';
import PartnerRegister from 'pages/Partner/Register';
import { ConfirmModal } from 'components';
import PartnerConfirmSuccess from 'pages/Partner/ConfirmSuccess';
import HelpCenterPage from 'pages/HelpCenter';
import UserGuide from 'pages/HelpCenter/components/UserGuide';
import SocketService from 'services/SocketService';
import { BranchActions, AppConfigActions } from 'redux/actions';
import { Dispatch } from 'redux';
import RemoteSocketService from 'services/RemoteSocketService';
import { CallProcess } from 'components/CallProcess';
interface IAppProps
	extends ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}

class App extends React.Component<IAppProps> {
	socketService: SocketService | null = null;
	remoteSocketService: RemoteSocketService | null = null;

	state = {
		reload: false,
		remoteAsk: false,
	};
	componentDidMount() {
		if (!_.isEmpty(this.props.currentBranch)) {
			this.socketInit();
			if (this.props.uuid) {
				this.remoteSocketInit(this.props.uuid);
			}
		}
	}
	componentWillUnmount() {
		this.removeSocket();
	}
	componentDidUpdate(prev: IAppProps) {
		if (
			!_.isEmpty(this.props.currentBranch) &&
			prev.currentBranch?.id !== this.props.currentBranch?.id
		) {
			if (this.props.uuid) {
				this.removeRemoteSocket();
				setTimeout(() => {
					this.remoteSocketInit(this.props.uuid!);
				}, 500);
			}
			this.removeSocket();
			setTimeout(() => {
				this.socketInit();
			}, 500);
		}
	}
	socketInit() {
		if (_.isEmpty(this.socketService)) {
			this.socketService = new SocketService();
			this.socketService.onListenCheckedIn();
			this.socketService.runService();
			this.socketService.onClose();
		}
	}
	removeSocket() {
		if (this.socketService !== null) {
			this.socketService.remove();
			this.socketService = null;
		}
	}
	remoteSocketInit(uuid: string) {
		if (_.isEmpty(this.remoteSocketService)) {
			this.remoteSocketService = new RemoteSocketService(uuid);
			this.remoteSocketService.setCallBack(() => this.onRemote());
			this.remoteSocketService.onListenRemote();
			this.remoteSocketService.runService();
			this.remoteSocketService.onClose();
		}
	}
	removeRemoteSocket() {
		if (this.remoteSocketService !== null) {
			this.remoteSocketService.remove();
			this.remoteSocketService = null;
		}
	}
	onRemote = () => {
		this.setState({ remoteAsk: true });
	};
	render() {
		if (window.location.href.includes('partners.')) {
			const shopUrl = window.localStorage.getItem('shopUrl');
			if (
				!_.isEmpty(shopUrl) &&
				shopUrl?.includes(process.env.REACT_APP_PRODUCTION_URL!)
			) {
				window.location.href = shopUrl;
				return null;
			}
			return (
				<Router history={history}>
					<Route exact path={['/', '/login']} component={Partner} />
					<Route exact path={['/register']} component={PartnerRegister} />
					<Route
						exact
						path={['/confirm-success']}
						component={PartnerConfirmSuccess}
					/>
				</Router>
			);
		}
		return (
			<>
				<Router history={history}>
					<HelmetProvider>
						<Helmet
							titleTemplate="%s - SmartSalon - SCSSolutions"
							defaultTitle="SmartSalon - SCSSolutions"
						>
							<meta name="description" content="Powered by SCSSolution" />
						</Helmet>
					</HelmetProvider>
					{!_.isEmpty(this.props.shop)
						? this.renderMain()
						: this.renderLoading()}
				</Router>
				<ConfirmModal
					onOk={() => this.setState({ remoteAsk: false })}
					onCancel={() => this.setState({ remoteAsk: false })}
					visible={this.state.remoteAsk}
					title={'Remote Support'}
					description={
						'Allow Smart Salon Staff can remote to your POS and support'
					}
					notDanger
				/>
			</>
		);
	}
	renderMain() {
		const { isAuthenticated } = this.props;
		return (
			<>
				{isAuthenticated && <CallProcess />}
				<Switch>
					<Redirect exact from="/" to="/today" />
					{this.renderAuthRoute()}
					{this.renderCustomerBookingRoute()}
					{this.renderBookingRoute()}
					{this.renderShortlyRoute()}
					{this.renderRatingRoute()}
					{this.renderConfirmBookingRoute()}
					{this.renderHelpCenterRoute()}
					{this.renderUserGuide()}
					<AdminRouter isAuthenticated={isAuthenticated} />
					<Route component={NotFound} />
					{/* {this.renderDetailPage()} */}
				</Switch>
			</>
		);
	}
	renderLoading() {
		return (
			<div className="loading-body">
				<div className="sk-cube-grid">
					<div className="sk-cube sk-cube1" />
					<div className="sk-cube sk-cube2" />
					<div className="sk-cube sk-cube3" />
					<div className="sk-cube sk-cube4" />
					<div className="sk-cube sk-cube5" />
					<div className="sk-cube sk-cube6" />
					<div className="sk-cube sk-cube7" />
					<div className="sk-cube sk-cube8" />
					<div className="sk-cube sk-cube9" />
				</div>
			</div>
		);
	}

	renderAuthRoute() {
		return (
			<Route
				exact
				path={AuthRoute.map((route) => route.path)}
				component={AuthenticationRouter}
			/>
		);
	}
	renderBookingRoute() {
		return (
			<Route
				exact
				path={['/booking/new', '/booking/:id']}
				component={Booking}
			/>
		);
	}
	renderCustomerBookingRoute() {
		return <Route path={['/customer-booking']} component={CustomerBooking} />;
	}
	renderRatingRoute() {
		return (
			<Route
				exact
				path={['/rating', '/rating/:branchId/:bookingId']}
				component={RatingPage}
			/>
		);
	}
	renderShortlyRoute() {
		return (
			<Route exact path={['/shortly', '/shortly/:id']} component={Shortly} />
		);
	}
	renderConfirmBookingRoute() {
		return (
			<Route
				exact
				path={['/confirm-booking', '/confirm-booking/:branchId/:bookingId']}
				component={ConfirmBookingPage}
			/>
		);
	}
	renderHelpCenterRoute() {
		return <Route exact path={'/help-center'} component={HelpCenterPage} />;
	}
	renderUserGuide() {
		return <Route exact path={'/user-guide'} component={UserGuide} />;
	}
}
const mapStateToProps = (state: RootState) => ({
	user: state.UserReducer.user,
	isAuthenticated: !_.isEmpty(state.AuthenticationReducer.token),
	shop: state.ShopReducer.shop,
	currentBranch: state.BranchReducer.currentBranch,
	uuid: state.AppConfigReducer.uuid,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setBranchId: (branchId: string) =>
		dispatch(BranchActions.setBranchId.request(branchId)),
	setUuid: (uuid: string) => dispatch(AppConfigActions.setUuid.request(uuid)),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
