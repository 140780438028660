import { Button, Card, Col, Form, Layout, Row, Space, Spin } from 'antd';
import { DetailPageLayout_V2, Modal } from 'components';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { I18n, _t, translations } from 'utils';
import _ from 'lodash';
import { AlertHelper, FormHelper } from 'helpers';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ITemplate } from 'models/ITemplate';
import TemplateApiService from 'services/TemplateApiService';
import { IApiResponse, IErrorResponse } from 'models';
import styles from '../style';
import './style.css';
import PreviewModal from './PreviewModal';

const TemplateDetailPage = () => {
	const location = useLocation<any>();
	const history = useHistory<any>();
	const { id } = useParams() as { id: string };

	const [currentTemplate, setCurrentTemplate] = useState<
		ITemplate | undefined
	>();
	const [valueContent, setValueContent] = useState<string | undefined>('');

	const [isLoading, setLoading] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [preview, setPreview] = useState<boolean>(false);

	const reactQuillRef = useRef(null) as any;
	let quillRef = null;

	const isAdd = () => {
		return location.pathname.includes('add-new');
	};
	const goBack = () => {
		history.push('/email-blast');
	};
	const fetchTemplateDetail = async (id: string) => {
		setLoading(true);
		try {
			const result = (await TemplateApiService.getTemplateById(
				id
			)) as IApiResponse<ITemplate>;
			if (result.succeeded) {
				setCurrentTemplate(result.data);
				setValueContent(result.data?.formatedHtmlBody);
			}
			setLoading(false);
		} catch (error) {}
	};
	const onDeleteTemplate = async () => {
		setLoading(true);
		try {
			const result = (await TemplateApiService.deleteTemplate(
				id
			)) as IApiResponse;
			if (result.succeeded) {
				setLoading(false);
				AlertHelper.showSuccess(
					I18n.t(_t(translations.templateDetail.messageDeleteSuccess))
				);
				goBack();
			} else {
				setLoading(false);
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const handleSubmit = async (name: string, { values, forms }: any) => {
		quillRef = reactQuillRef.current.getEditor();
		const data: Partial<ITemplate> = {
			id: isAdd() ? undefined : currentTemplate?.id,
			subject: values.templateName,
			bodyContent: quillRef.getText(),
			formatedHtmlBody: valueContent,
		};

		let response = isAdd()
			? ((await TemplateApiService.addTemplate(data)) as IApiResponse)
			: ((await TemplateApiService.editTemplate(data)) as IApiResponse);

		if (response?.succeeded) {
			setLoading(false);
			AlertHelper.showSuccess(
				isAdd()
					? I18n.t(_t(translations.templateDetail.messageAddTemplateSuccess))
					: I18n.t(_t(translations.templateDetail.messageUpdateTemplateSuccess))
			);
			goBack();
		} else {
			setLoading(false);
			const error = response as IErrorResponse;
			AlertHelper.showError(error);
		}
	};
	useEffect(() => {
		const ac = new AbortController();
		if (!isAdd()) {
			fetchTemplateDetail(id);
		}
		return () => {
			ac.abort();
		};
	}, []);
	const btnStyle: CSSProperties =
		window.innerWidth <= 768
			? { ...styles.buttonContainer, paddingRight: 16 }
			: styles.buttonContainer;
	return isAdd() || !_.isEmpty(currentTemplate) ? (
		<DetailPageLayout_V2
			title={I18n.t(
				_t(
					isAdd()
						? translations.templateDetail.addNew
						: translations.templateDetail.editTemplate
				)
			)}
			positiveButtonName={I18n.t(_t(translations.save))}
			negativeButtonName={I18n.t(_t(translations.templateDetail.preview))}
			isLoading={isLoading}
			goBack={() => goBack()}
			onFormFinish={handleSubmit}
		>
			<Card>
				<Row>
					<Col span={24}>
						{FormHelper.renderFormInput(
							'templateName',
							[
								{
									required: true,
									message: I18n.t(
										_t(translations.templateDetail.messageTemplateName)
									),
								},
							],
							I18n.t(_t(translations.templateDetail.templateName)),
							isAdd() ? '' : currentTemplate!.subject,
							{},
							{
								placeholder: I18n.t(
									_t(translations.templateDetail.inputTemplateName)
								),
								maxLength: 256,
							}
						)}
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<ReactQuill
							ref={reactQuillRef}
							theme="snow"
							value={valueContent}
							onChange={setValueContent}
						/>
					</Col>
				</Row>
			</Card>
			<Form.Item style={btnStyle}>
				<Space direction="horizontal" size="middle">
					{isAdd() ? null : (
						<Button
							danger
							size="large"
							onClick={() => {
								setShowModal(true);
							}}
						>
							{I18n.t(_t(translations.delete))}
						</Button>
					)}
					<Button size="large" onClick={() => setPreview(true)}>
						{I18n.t(_t(translations.templateDetail.preview))}
					</Button>
					<Button
						type="primary"
						size="large"
						htmlType="submit"
						loading={isLoading}
					>
						{I18n.t(_t(translations.save))}
					</Button>
				</Space>
			</Form.Item>
			<Modal
				visible={showModal}
				title={I18n.t(_t(translations.delete))}
				handleOk={() => {
					onDeleteTemplate();
					setShowModal(false);
				}}
				handleCancel={() => {
					setShowModal(false);
				}}
			>
				{I18n.t(_t(translations.templateDetail.deleteConfirmContent))}
			</Modal>
			<PreviewModal
				isModalVisible={preview}
				handleCancel={() => {
					setPreview(false);
				}}
				htmlText={valueContent}
			></PreviewModal>
		</DetailPageLayout_V2>
	) : (
		<Layout style={styles.spinLayout}>
			<Spin tip="Loading..." spinning={isLoading} style={styles.loading} />
		</Layout>
	);
};

export default TemplateDetailPage;
