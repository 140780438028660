import { Col, Row } from 'antd';
import { Button, FormInModal } from 'components';
import styles from 'components/styles';
import { FormHelper } from 'helpers';
import React, { Component } from 'react';
import { I18n, translations, _t } from 'utils';

interface IAddPaymentMethodModalProps {
	onCancel?(): void;
	onDelete?(id: string): void;
	onFormFinish?(value: PaymentMethodFormDataType): void;
	isLoading?: boolean;
	isAdd: boolean;
	dataPaymentMethod?: PaymentMethodFormDataType;
}

export default class AddPaymentMethodModal extends Component<IAddPaymentMethodModalProps> {
	render() {
		return (
			<FormInModal
				visible={true}
				centered
				title={
					this.props.isAdd
						? I18n.t(_t(translations.branchSetting.addPaymentMethod))
						: I18n.t(_t(translations.branchSetting.editPaymentMethod))
				}
				onCancel={() => this.props.onCancel && this.props.onCancel()}
				onOk={(info: PaymentMethodFormDataType) =>
					this.props.onFormFinish && this.props.onFormFinish(info)
				}
				btnDelete={!this.props.isAdd ? true : false}
				onDelete={() =>
					this.props.onDelete &&
					this.props.onDelete(this.props.dataPaymentMethod?.id!)
				}
			>
				{this.renderInputs()}
			</FormInModal>
		);
	}

	renderInputs() {
		return (
			<Row style={styles.contentContainer}>
				<Col lg={24} xs={24} style={{ backgroundColor: 'white' }}>
					{FormHelper.renderFormInput(
						'name',
						[
							{
								required: true,
								message: I18n.t(
									_t(translations.branchSetting.paymentMethodNameMessage)
								),
							},
						],
						I18n.t(_t(translations.branchSetting.paymentMethodName)),
						this.props.dataPaymentMethod?.name,
						{},
						{
							placeholder: I18n.t(_t(translations.placeHolder.paymentMethod)),
							maxLength: 256,
						}
					)}
					{FormHelper.renderFormInput(
						'code',
						[
							{
								required: true,
								message: I18n.t(
									_t(translations.branchSetting.paymentMethodCodeMessage)
								),
							},
						],
						I18n.t(_t(translations.branchSetting.paymentMethodCode)),
						this.props.dataPaymentMethod?.code,
						{},
						{
							placeholder: I18n.t(
								_t(translations.branchSetting.paymentMethodCodePlaceHolder)
							),
							maxLength: 256,
						}
					)}
					{FormHelper.renderFormSwitch(
						'status',
						I18n.t(_t(translations.categoryDetail.labelStatus)),
						this.props.dataPaymentMethod?.status! ?? true
					)}
					{FormHelper.renderFormTextArea(
						'description',
						[],
						I18n.t(_t(translations.description)),
						I18n.t(_t(translations.placeHolder.paymentMethodDescription)),
						this.props.dataPaymentMethod?.description
					)}
				</Col>
				<Col style={{ display: 'none' }}>
					{FormHelper.renderFormTextArea(
						'id',
						[],
						'',
						'',
						this.props.dataPaymentMethod?.id
					)}
				</Col>
				<Col lg={4} />
			</Row>
		);
	}

	renderFooterButtons() {
		return (
			<Row style={styles.contentContainer} gutter={16}>
				<Col span={12} style={{ backgroundColor: 'white' }}>
					<Button
						onClick={() => this.props.onCancel && this.props.onCancel()}
						disabled={this.props.isLoading}
					>
						{I18n.t(_t(translations.cancel))}
					</Button>
				</Col>
				<Col span={12} style={{ backgroundColor: 'white' }}>
					<Button
						type={'primary'}
						htmlType="submit"
						loading={this.props.isLoading}
					>
						{I18n.t(_t(translations.save))}
					</Button>
				</Col>
			</Row>
		);
	}
}

export type PaymentMethodFormDataType = {
	id: string;
	name: string;
	status: boolean;
	description: string;
	code: string;
};
