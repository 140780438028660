import {
	Button,
	Card,
	Col,
	Form,
	Layout,
	Row,
	Space,
	Spin,
	Typography,
} from 'antd';
import { DetailPageLayout_V2, Modal } from 'components';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { I18n, _t, translations } from 'utils';
import _, { values } from 'lodash';
import { AlertHelper, FormHelper } from 'helpers';
import { ITextTemplate } from 'models/ITextTemplate';
import TextTemplateApiService from 'services/TextTemplateApiService';
import { IApiResponse, IErrorResponse } from 'models';
import styles from '../../EmailBlast/style';
import TextArea from 'antd/lib/input/TextArea';
import PreviewContentModal from '../components/PreviewContentModal';

const FormTemplate = (props: {
	isAdd: boolean;
	currentTemplate?: ITextTemplate;
	setCurrentTemplate: (value: string) => void;
}) => {
	const [characterCount, setCharacterCount] = useState(
		props.isAdd ? 0 : props.currentTemplate!.content.length
	);
	return (
		<Card>
			<Row>
				<Col span={24}>
					{FormHelper.renderFormInput(
						'templateName',
						[
							{
								required: true,
								message: I18n.t(
									_t(translations.templateDetail.messageTemplateName)
								),
							},
						],
						I18n.t(_t(translations.templateDetail.templateName)),
						props.isAdd ? '' : props.currentTemplate!.name,
						{},
						{
							placeholder: I18n.t(
								_t(translations.templateDetail.inputTemplateName)
							),
							maxLength: 256,
						}
					)}
				</Col>
				<Col span={24}>
					<Form.Item
						label={
							<Typography.Title level={5}>
								{I18n.t(_t(translations.templateDetail.templateContent))}
								<div
									style={{
										color: 'red',
										display: 'inline-block',
										marginLeft: '1rem',
									}}
								>
									Character count: {characterCount}/
									{Math.ceil(characterCount / 160)} SMS
								</div>
							</Typography.Title>
						}
						name={'templateContent'}
						rules={[
							{
								required: true,
								message: I18n.t(
									_t(translations.templateDetail.messageTemplateContent)
								),
							},
						]}
						initialValue={props.isAdd ? '' : props.currentTemplate!.content}
					>
						<TextArea
							placeholder={I18n.t(
								_t(translations.templateDetail.inputTemplateContent)
							)}
							onChange={(e) => {
								setCharacterCount(e.target.value.length);
								props.setCurrentTemplate(e.target.value);
							}}
							style={{ height: '30vh' }}
						/>
					</Form.Item>
				</Col>
			</Row>
		</Card>
	);
};

const TextTemplateDetailPage = () => {
	const location = useLocation<any>();
	const history = useHistory<any>();
	const { id } = useParams() as { id: string };

	const [currentTemplate, setCurrentTemplate] = useState<
		ITextTemplate | undefined
	>();

	const [isLoading, setLoading] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [preview, setPreview] = useState<boolean>(false);

	const isAdd = () => {
		return location.pathname.includes('add-new');
	};
	const goBack = () => {
		history.push('/text-message');
	};
	const fetchTemplateDetail = async (id: string) => {
		setLoading(true);
		try {
			const result = (await TextTemplateApiService.getTemplateById(
				id
			)) as IApiResponse<ITextTemplate>;
			if (result.succeeded) {
				setCurrentTemplate(result.data);
			}
			setLoading(false);
		} catch (error) {}
	};
	const onDeleteTemplate = async () => {
		setLoading(true);
		try {
			const result = (await TextTemplateApiService.deleteTemplate(
				id
			)) as IApiResponse;
			if (result.succeeded) {
				setLoading(false);
				AlertHelper.showSuccess(
					I18n.t(_t(translations.templateDetail.messageDeleteSuccess))
				);
				goBack();
			} else {
				setLoading(false);
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const handleSubmit = async (name: string, { values, forms }: any) => {
		const data: Partial<ITextTemplate> = {
			id: isAdd() ? undefined : currentTemplate?.id,
			name: values.templateName,
			content: values.templateContent,
		};
		let response = isAdd()
			? ((await TextTemplateApiService.addTemplate(data)) as IApiResponse)
			: ((await TextTemplateApiService.editTemplate(data)) as IApiResponse);
		if (response?.succeeded) {
			setLoading(false);
			AlertHelper.showSuccess(
				isAdd()
					? I18n.t(_t(translations.templateDetail.messageAddTemplateSuccess))
					: I18n.t(_t(translations.templateDetail.messageUpdateTemplateSuccess))
			);
			goBack();
		} else {
			setLoading(false);
			const error = response as IErrorResponse;
			AlertHelper.showError(error);
		}
	};
	useEffect(() => {
		const ac = new AbortController();
		if (!isAdd()) {
			fetchTemplateDetail(id);
		}
		return () => {
			ac.abort();
		};
	}, []);
	const btnStyle: CSSProperties =
		window.innerWidth <= 768
			? { ...styles.buttonContainer, paddingRight: 16 }
			: styles.buttonContainer;
	return isAdd() || !_.isEmpty(currentTemplate) ? (
		<DetailPageLayout_V2
			title={I18n.t(
				_t(
					isAdd()
						? translations.templateDetail.addNew
						: translations.templateDetail.editTemplate
				)
			)}
			positiveButtonName={I18n.t(_t(translations.save))}
			negativeButtonName={I18n.t(_t(translations.templateDetail.preview))}
			isLoading={isLoading}
			goBack={() => goBack()}
			onFormFinish={handleSubmit}
		>
			<FormTemplate
				isAdd={isAdd()}
				currentTemplate={currentTemplate}
				setCurrentTemplate={(value) => {
					setCurrentTemplate({
						...currentTemplate!,
						content: value,
					});
				}}
			/>
			<Form.Item style={btnStyle}>
				<Space direction="horizontal" size="middle">
					{isAdd() ? null : (
						<Button
							danger
							size="large"
							onClick={() => {
								setShowModal(true);
							}}
						>
							{I18n.t(_t(translations.delete))}
						</Button>
					)}
					<Button size="large" onClick={() => setPreview(true)}>
						{I18n.t(_t(translations.templateDetail.preview))}
					</Button>
					<Button
						type="primary"
						size="large"
						htmlType="submit"
						loading={isLoading}
					>
						{I18n.t(_t(translations.save))}
					</Button>
				</Space>
			</Form.Item>

			<Modal
				visible={showModal}
				title={I18n.t(_t(translations.delete))}
				handleOk={() => {
					onDeleteTemplate();
					setShowModal(false);
				}}
				handleCancel={() => {
					setShowModal(false);
				}}
			>
				{I18n.t(_t(translations.templateDetail.deleteConfirmContent))}
			</Modal>

			<PreviewContentModal
				isModalVisible={preview}
				handleCancel={() => {
					setPreview(false);
				}}
				content={currentTemplate?.content}
			/>
		</DetailPageLayout_V2>
	) : (
		<Layout style={styles.spinLayout}>
			<Spin tip="Loading..." spinning={isLoading} style={styles.loading} />
		</Layout>
	);
};

export default TextTemplateDetailPage;
