import React, { Component, createRef } from "react";
import { Form, Typography, Alert } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { ValidationHelper } from "helpers";
import styles from "./styles";
import { I18n, _t, translations } from "utils";
import { Input, Button, IComponentProps } from "components";
import { Link } from "react-router-dom";
import { RootState } from "redux/configuration/rootReducer";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AuthenticationActions, ErrorActions } from "redux/actions";
import { IErrorResponse } from "models";
import { IForgotPasswordRequest } from "models/RequestModels";
const { Title, Text } = Typography;

interface IForgotPageProps
  extends IComponentProps,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}
interface IState {}

class ForgotPage extends Component<IForgotPageProps, IState> {
  render() {
    return (
      <Form
        initialValues={{ remember: true }}
        style={styles.form}
        onFinish={(values) => this.onFinish(values)}
      >
        <Title style={styles.title} level={3}>
          {I18n.t(_t(translations.forgot.forgotTitle))}
        </Title>
        <Text style={styles.text}>
          {I18n.t(_t(translations.forgot.forgotSub))}
        </Text>
        {this.renderAlert()}
        {this.renderSuccess()}
        {this.renderInput()}
        {this.renderGoBack()}
      </Form>
    );
  }

  state = {
    email: "",
  };
  emailRef = createRef<any>();

  renderInput() {
    return (
      <>
        <Form.Item
          name="email"
          rules={[
            {
              validator: (_, value: string) => {
                if (!value) {
                  return Promise.reject(
                    new Error(
                      I18n.t(_t(translations.forgot.forgotEmailWarning))
                    )
                  );
                } else if (ValidationHelper.validateEmail(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(I18n.t(_t(translations.forgot.forgotEmailFormat)))
                );
              },
            },
          ]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder={I18n.t(_t(translations.forgot.forgotEmail))}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            loading={this.props.isLoading}
            htmlType="submit"
          >
            {I18n.t(_t(translations.forgot.forgotButton))}
          </Button>
        </Form.Item>
      </>
    );
  }

  renderGoBack() {
    return (
      <>
        <div style={styles.goBack}>
          <Text style={styles.text}>
            {I18n.t(_t(translations.forgot.forgotGoBackText))}
          </Text>
          <Link to="login" style={styles.backLink}>
            {I18n.t(_t(translations.forgot.forgotGoBackLink))}
          </Link>
        </div>
      </>
    );
  }

  renderSuccess() {
    return this.props.currentAction ===
      AuthenticationActions.forgotPassword.successName ? (
      <Alert
        style={styles.alert}
        message={I18n.t(_t(translations.forgot.forgotSuccess))}
        type="success"
        showIcon
        closable
      />
    ) : null;
  }

  renderAlert() {
    return this.props.currentAction ===
      AuthenticationActions.forgotPassword.failedName && this.props.error ? (
      <Alert
        style={styles.alert}
        message={this.props.error.Text}
        type="error"
        showIcon
        closable
        onClose={this.props.clearError}
      />
    ) : null;
  }

  onFinish(values: IForgotPasswordRequest) {
    console.info("submit");
    this.props.clearError();
    this.props.forgotPassword({ email: values.email });
  }
}

const mapStateToProps = (state: RootState) => ({
  // apiResponse: state.AuthenticationReducer.apiResponse,
  error: state.ErrorReducer.error?.message[0],
  isLoading: state.AppConfigReducer.showLoading,
  currentAction: state.ReduxActionReducer["AUTHENTICATION"],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchError: (error: IErrorResponse) => {
    dispatch(AuthenticationActions.forgotPassword.failed(error));
  },
  forgotPassword: (request: IForgotPasswordRequest) => {
    dispatch(AuthenticationActions.forgotPassword.request(request));
  },
  clearError: () => {
    dispatch(ErrorActions.clearError.request());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPage);
