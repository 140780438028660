import React, { ReactNode } from 'react';
import {
	StaffDetailPage,
	ServicesPage,
	DashBoard,
	Calendar,
	LoginPage,
	ForgotPage,
	ResetPassword,
	ServicesDetailPage,
	CategoryDetailPage,
	StaffPage,
	Search,
	CheckoutPage,
	Customers,
	CustomerDetailPage,
	CustomerInfoPage,
	SettingPage,
	BranchSettingPage,
	TodayBooking,
	Sales,
	PayrollReport,
	PayrollDetailReport,
	Billing,
	CustomerBooking,
	ShopSetting,
	OwnerFinancialSummaryReport,
	Expenses,
	Holiday,
	ReviewManagement,
	EmailBlast,
	TemplateDetailPage,
	TextMessage,
	TextTemplateDetailPage,
	TextMessageDetailPage,
	CloseOnlineBooking,
	Promotion,
	PromotionDetailPage,
	Loyalty,
	Branches,
} from 'pages';
import {
	DashboardOutlined,
	DatabaseOutlined,
	CalendarOutlined,
	ContactsOutlined,
	UserOutlined,
	SettingOutlined,
	AppstoreAddOutlined,
	StrikethroughOutlined,
	CopyOutlined,
	FundOutlined,
	MenuOutlined,
	ReconciliationOutlined,
	CarOutlined,
	BarChartOutlined,
	ProjectOutlined,
	StarOutlined,
	MailOutlined,
	NotificationOutlined,
	MessageOutlined,
	TagOutlined,
	PayCircleOutlined,
	ClusterOutlined,
} from '@ant-design/icons';
import AppMenu from 'pages/AppMenu';
import { EEmployeeTypeCode } from 'models';
import MiniCMSSetting from 'pages/CMSSetting';
import GallerySetup from 'pages/GallerySetup';
import EmployeeTypePage from 'pages/EmployeeType';

// const iconStyle = { fontSize: "20px" };
interface IRoute {
	name: string;
	path: string;
	component?: React.ComponentType<any>;
	exact?: boolean;
	feature?: string;
	key: string;
	icon?: ReactNode;
	roles?: string[];
	showInMenu?: boolean;
	subMenu?: IRoute[];
	mainMobile?: boolean;
}
const HomeRoute: Partial<IRoute>[] = [
	{
		key: 'Dashboard',
		name: 'Dashboard',
		path: '/dashboard',
		showInMenu: true,
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
		icon: <DashboardOutlined style={{ fontSize: 30, marginRight: 0 }} />,
		component: DashBoard,
		mainMobile: true,
	},
	{
		key: 'today-booking',
		name: 'Today',
		path: '/today',
		showInMenu: true,
		icon: <AppstoreAddOutlined style={{ fontSize: 30, marginRight: 0 }} />,
		component: TodayBooking,
		mainMobile: true,
	},
	{
		key: 'Staff',
		name: 'Staff',
		path: '/staff',
		showInMenu: true,
		icon: <ContactsOutlined style={{ fontSize: 30, marginRight: 0 }} />,
		feature: 'Employee',
		subMenu: [
			{
				key: 'Staff-list',
				name: 'Staff List',
				path: '/staffs',
				component: StaffPage,
				icon: <ContactsOutlined style={{ fontSize: 30, marginRight: 0 }} />,
			},
			{
				key: 'employeeType',
				name: 'Employee Type',
				path: '/employee-type',
				component: EmployeeTypePage,
				showInMenu: true,
				icon: <ContactsOutlined style={{ fontSize: 30, marginRight: 0 }} />,
				feature: 'EmployeeType',
				roles: ['Admin', EEmployeeTypeCode.MANAGER],
			},
		],
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
	},
	{
		key: 'Services',
		name: 'Services',
		feature: 'Services',
		path: '/services',
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
		showInMenu: true,

		icon: <DatabaseOutlined style={{ fontSize: 30, marginRight: 0 }} />,
		component: ServicesPage,
	},
	{
		key: 'calendar',
		name: 'Calendar',
		path: '/calendar',
		showInMenu: true,
		icon: <CalendarOutlined style={{ fontSize: 30, marginRight: 0 }} />,
		component: Calendar,
		mainMobile: true,
	},
	{
		key: 'customer',
		name: 'Customer',
		path: '/customer',
		showInMenu: true,
		mainMobile: true,
		icon: <UserOutlined style={{ fontSize: 30, marginRight: 0 }} />,
		component: Customers,
	},
	// {
	// 	key: 'Loyalty',
	// 	name: 'Loyalty',
	// 	feature: 'Loyalty',
	// 	path: '/loyalty',
	// 	component: Loyalty,
	// 	icon: <PayCircleOutlined style={{ fontSize: 30, marginRight: 0 }} />,
	// 	roles: ['Admin', EEmployeeTypeCode.MANAGER],
	// 	mainMobile: true,
	// },
	{
		key: 'sales',
		name: 'Sales',
		path: '/sales',
		showInMenu: true,
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
		icon: <FundOutlined style={{ fontSize: 30, marginRight: 0 }} />,
		component: Sales,
		mainMobile: true,
	},
	{
		key: 'report',
		name: 'Report',
		path: '/report',
		showInMenu: true,
		roles: ['Admin'],
		icon: <CopyOutlined style={{ fontSize: 30, marginRight: 0 }} />,
		subMenu: [
			{
				key: 'payroll-report',
				name: 'Payroll',
				path: '/report/payroll',
				showInMenu: true,
				roles: ['Admin'],
				component: PayrollReport,
				icon: (
					<StrikethroughOutlined style={{ fontSize: 30, marginRight: 0 }} />
				),
			},
			{
				key: 'payroll-detail-report',
				name: 'Payroll Detail',
				path: '/report/payroll-detail',
				showInMenu: true,
				roles: ['Admin'],
				component: PayrollDetailReport,
				icon: (
					<ReconciliationOutlined style={{ fontSize: 30, marginRight: 0 }} />
				),
			},
			{
				key: 'owner-financial-summary-report',
				name: 'Owner Financial Summary Report',
				path: '/report/owner-financial-summary',
				showInMenu: true,
				roles: ['Admin'],
				component: OwnerFinancialSummaryReport,
				icon: <BarChartOutlined style={{ fontSize: 30, marginRight: 0 }} />,
			},
			{
				key: 'expenses',
				name: 'Expenses',
				path: '/report/expenses',
				showInMenu: true,
				roles: ['Admin'],
				component: Expenses,
				icon: <ProjectOutlined style={{ fontSize: 30, marginRight: 0 }} />,
			},
		],
	},
	{
		key: 'Marketing',
		name: 'Marketing',
		path: '/marketing',
		showInMenu: true,
		icon: <NotificationOutlined style={{ fontSize: 30, marginRight: 0 }} />,
		feature: 'Marketing',
		subMenu: [
			{
				key: 'Email',
				name: 'Email',
				path: '/email-blast',
				component: EmailBlast,
				icon: <MailOutlined style={{ fontSize: 30, marginRight: 0 }} />,
			},
			{
				key: 'TextMessage',
				name: 'Text Message',
				path: '/text-message',
				component: TextMessage,
				icon: <MessageOutlined style={{ fontSize: 30, marginRight: 0 }} />,
			},
			{
				key: 'Promotions',
				name: 'Promotions',
				path: '/promotions',
				component: Promotion,
				icon: <TagOutlined style={{ fontSize: 30, marginRight: 0 }} />,
			},
		],
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
	},
	{
		key: 'holiday',
		name: 'Holiday',
		path: '/holiday',
		showInMenu: true,
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
		icon: <CarOutlined style={{ fontSize: 30, marginRight: 0 }} />,
		component: Holiday,
	},
	{
		key: 'ReviewManagement',
		name: 'Review Management',
		feature: 'Review',
		path: '/review-management',
		component: ReviewManagement,
		showInMenu: true,
		icon: <StarOutlined style={{ fontSize: 30, marginRight: 0 }} />,
		mainMobile: false,
	},
	{
		key: 'Branches',
		name: 'Branches',
		feature: 'Review',
		path: '/branches',
		component: Branches,
		showInMenu: true,
		icon: <ClusterOutlined style={{ fontSize: 30, marginRight: 0 }} />,
		mainMobile: false,
		roles: ['Admin'],
	},
	{
		key: 'setting',
		name: 'Setting',
		path: '/setting',
		showInMenu: true,
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
		icon: <SettingOutlined style={{ fontSize: 30, marginRight: 0 }} />,
		component: SettingPage,
	},
	{
		key: 'close-online-booking',
		name: 'Close Online Booking',
		path: '/close-online-booking',
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
		component: CloseOnlineBooking,
	},
	{
		key: 'Menu',
		name: 'Menu',
		path: '/menu',
		showInMenu: false,
		icon: <MenuOutlined style={{ fontSize: 30, marginRight: 0 }} />,
		component: AppMenu,
		roles: ['Admin', EEmployeeTypeCode.MANAGER],
		mainMobile: true,
	},
];

export function getSubRoutes() {
	const subRoutes: IRoute[] = [];
	for (const route of HomeRoute) {
		if (route.subMenu) {
			for (const subRoute of route.subMenu) {
				subRoutes.push(subRoute);
			}
		}
	}
	return subRoutes;
}

const DetailRoute: IRoute[] = [
	{
		key: 'Search',
		name: 'Search',
		feature: 'Search',
		path: '/search',
		component: Search,
	},
	{
		key: 'CategoryDetailAddNew',
		name: 'CategoryDetail',
		feature: 'Category',
		path: '/services/category/add-new',
		component: CategoryDetailPage,
	},
	{
		key: 'CategoryDetailEdit',
		name: 'CategoryDetail',
		feature: 'Category',
		path: '/services/category/edit/:id',
		component: CategoryDetailPage,
	},
	{
		key: 'ServiceDetailEdit',
		name: 'ServiceDetail',
		feature: 'Services',
		path: '/services/edit/:id',
		component: ServicesDetailPage,
	},
	{
		key: 'ServiceDetailAddNew',
		name: 'ServiceDetail',
		feature: 'Services',
		path: '/services/add-new',
		component: ServicesDetailPage,
	},
	{
		key: 'StaffDetailAddNew',
		name: 'StaffDetail',
		feature: 'Staffs',
		path: '/staffs/add-new',
		component: StaffDetailPage,
	},
	{
		key: 'StaffDetailEdit',
		name: 'StaffDetail',
		feature: 'Staffs',
		path: '/staffs/edit/:id',
		component: StaffDetailPage,
	},
	{
		key: 'CheckOut',
		name: 'CheckOut',
		feature: 'CheckOut',
		path: '/checkout',
		component: CheckoutPage,
	},
	{
		key: 'CheckOut',
		name: 'CheckOut',
		feature: 'CheckOut',
		path: '/checkout/sale-gift-card',
		component: CheckoutPage,
	},
	{
		key: 'CustomerDetailAddNew',
		name: 'CustomerDetail',
		feature: 'Customer',
		path: '/customer/add-new',
		component: CustomerDetailPage,
	},
	{
		key: 'CustomerDetailEdit',
		name: 'CustomerDetail',
		feature: 'Customer',
		path: '/customer/edit/:id',
		component: CustomerDetailPage,
	},
	{
		key: 'CustomerInformationDetail',
		name: 'CustomerDetail',
		feature: 'Customer',
		path: '/customer/details/:id',
		component: CustomerInfoPage,
	},

	{
		key: 'setting',
		name: 'Setting',
		path: '/setting/branch',
		roles: ['Admin'],
		component: BranchSettingPage,
	},
	{
		key: 'billing',
		name: 'Billing',
		path: '/billing',
		component: Billing,
	},
	{
		key: 'setting',
		name: 'Setting',
		path: '/setting/mini-cms',
		roles: ['Admin'],
		component: MiniCMSSetting,
	},
	{
		key: 'setting',
		name: 'Setting',
		path: '/setting/gallery-setup',
		roles: ['Admin'],
		component: GallerySetup,
	},
	{
		key: 'customerBooking',
		name: 'Customer Booking',
		path: '/customer-booking',
		roles: ['Admin'],
		component: CustomerBooking,
	},
	{
		key: 'shop-setting',
		name: 'Shop Setting',
		path: '/setting/shop',
		roles: ['Admin'],
		component: ShopSetting,
	},
	{
		key: 'TemplateDetailAddNew',
		name: 'TemplateDetail',
		feature: 'Template',
		path: '/template/add-new',
		component: TemplateDetailPage,
	},
	{
		key: 'TemplateDetailEdit',
		name: 'TemplateDetail',
		feature: 'Template',
		path: '/template/edit/:id',
		component: TemplateDetailPage,
	},
	{
		key: 'TextTemplateDetailAddNew',
		name: 'TextTemplateDetail',
		feature: 'Template',
		path: '/text-template/add-new',
		component: TextTemplateDetailPage,
	},
	{
		key: 'TextTemplateDetailEdit',
		name: 'TextTemplateDetail',
		feature: 'Template',
		path: '/text-template/edit/:id',
		component: TextTemplateDetailPage,
	},
	{
		key: 'TextMessageDetailAddNew',
		name: 'TextMessageDetail',
		feature: 'Message',
		path: '/text-message/add-new',
		component: TextMessageDetailPage,
	},
	{
		key: 'TextMessageDetailEdit',
		name: 'TextMessageDetail',
		feature: 'Message',
		path: '/text-message/edit/:id',
		component: TextMessageDetailPage,
	},
	{
		key: 'PromotionDetailAddNew',
		name: 'PromotionDetail',
		feature: 'Promotion',
		path: '/promotions/add-new',
		component: PromotionDetailPage,
	},
	{
		key: 'PromotionDetailEdit',
		name: 'PromotionDetail',
		feature: 'Promotion',
		path: '/promotions/edit/:id',
		component: PromotionDetailPage,
	},
];
const AuthRoute: IRoute[] = [
	{
		key: 'Login',
		name: 'Login',
		path: '/login',
		component: LoginPage,
	},
	{ key: 'Forgot', name: 'Forgot', path: '/forgot', component: ForgotPage },
	{
		key: 'Reset',
		name: 'Rest',
		path: '/reset-password',
		component: ResetPassword,
	},
];

export { HomeRoute, AuthRoute, DetailRoute };
