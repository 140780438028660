import { Col, Radio, Row, Typography } from 'antd';
import React from 'react';
import BaseModal from './BaseModal';
import styles from './styles';
import { translations, _t, I18n } from 'utils';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import { DiscounterType, DiscountType } from 'utils/Consts';
import { Button, Input, ConfirmModal as AFirmModal } from 'components';

interface IConfirmModalProps extends ReturnType<typeof mapStateToProps> {
	onOk(
		refundSharingType: DiscounterType,
		description: string,
		employeeCode: string
	): void;
	onCancel(): void;
}

interface IConfirmModalState {
	employeeCode?: string;
	error?: string;
	refundSharingType: DiscounterType;
	description?: string;
	showEmployeeCodeInput?: boolean;
	showConfirmModal?: boolean;
}

class ConfirmModal extends React.Component<
	IConfirmModalProps,
	IConfirmModalState
> {
	constructor(props: IConfirmModalProps) {
		super(props);
		this.state = {
			refundSharingType: DiscounterType.BOTH,
		};
	}
	render() {
		return (
			<BaseModal
				title={I18n.t(_t(translations.checkout.confirm))}
				onOk={() => this.onOk()}
				onCancel={this.props.onCancel}
				disableOk={this.state.showEmployeeCodeInput && !this.state.employeeCode}
			>
				{this.state.showEmployeeCodeInput ? (
					<Col>
						<Typography.Title level={4}>
							{I18n.t(_t(translations.checkout.employeeCode))}
						</Typography.Title>
						<Row gutter={8}>
							<Col md={20}>
								<Input
									style={styles.inputNumber}
									onPressEnter={this.onOk}
									autoFocus={true}
									type="number"
									value={this.state.employeeCode}
									onChange={(event) =>
										this.setState({
											employeeCode: event.target.value.toUpperCase(),
										})
									}
								/>
							</Col>
							<Col md={4}>
								<Button
									onClick={() => this.setState({ employeeCode: undefined })}
									danger
								>
									{I18n.t(_t(translations.clear))}
								</Button>
							</Col>
						</Row>

						{this.state.error ? (
							<Typography.Text type="danger">
								{this.state.error}
							</Typography.Text>
						) : null}
					</Col>
				) : (
					<Col style={{ marginTop: 10 }}>
						<Typography.Title level={5}>
							{I18n.t(_t(translations.checkout.sharingMethod))}
						</Typography.Title>
						<Row style={{ marginTop: 10 }}>
							<Radio.Group
								defaultValue={DiscounterType.BOTH}
								buttonStyle="solid"
								style={styles.maxWidth}
								onChange={(event) =>
									this.setState({ refundSharingType: event.target.value })
								}
								value={this.state.refundSharingType}
							>
								{this.renderSharingMethodButton({
									value: DiscounterType.BOTH,
									title: I18n.t(_t(translations.checkout.both)),
								})}
								{this.renderSharingMethodButton({
									value: DiscounterType.OWNER_ONLY,
									title: I18n.t(_t(translations.checkout.ownerOnly)),
								})}
								{this.renderSharingMethodButton({
									value: DiscounterType.EMPLOYEE_ONLY,
									title: I18n.t(_t(translations.checkout.employeeOnly)),
								})}
							</Radio.Group>
						</Row>
						{this.renderDescription()}
					</Col>
				)}
				{this.state.showConfirmModal && (
					<AFirmModal
						onCancel={() => this.setState({ showConfirmModal: false })}
						onOk={() =>
							this.props.onOk(
								this.state.refundSharingType!,
								this.state.description!,
								this.state.employeeCode!
							)
						}
						title={I18n.t(_t(translations.text.areYouSure))}
						description={I18n.t(_t(translations.billing.refundCantBeUndo))}
						visible
					/>
				)}
			</BaseModal>
		);
	}

	renderSharingMethodButton({
		value,
		title,
	}: {
		value: DiscounterType;
		title: string;
	}) {
		return (
			<>
				{this.renderRadioButton({
					value: value,
					children: title,
					style: { width: '33.3%', textAlign: 'center' },
				})}
			</>
		);
	}

	renderRadioButton({
		value,
		children,
		style,
	}: {
		value: DiscountType | DiscounterType;
		children: JSX.Element | string;
		style: React.CSSProperties;
	}) {
		return (
			<Radio.Button style={style} value={value}>
				{children}
			</Radio.Button>
		);
	}

	renderDescription() {
		return (
			<>
				<Typography.Title level={5}>
					{I18n.t(_t(translations.billing.refundReason))}
				</Typography.Title>
				<Input.TextArea
					placeholder={I18n.t(_t(translations.billing.refundReasonPlaceholder))}
					onChange={(e) => this.setState({ description: e.target.value })}
				/>
			</>
		);
	}

	onOk = () => {
		if (!this.state.employeeCode) {
			this.setState({ showEmployeeCodeInput: true });
		} else {
			const listEmployeeCode = this.props.employees?.map(
				(employee) => employee.code
			);
			// const { employeeCode, refundSharingType, description } = this.state;
			if (this.state.employeeCode) {
				if (listEmployeeCode?.includes(this.state.employeeCode)) {
					// this.props.onOk(refundSharingType!, description!, employeeCode!);
					this.setState({ showConfirmModal: true });
				} else {
					this.setState({
						error: I18n.t(_t(translations.checkout.employeeCodeNotFound)),
					});
				}
				return;
			}
		}
	};
}

const mapStateToProps = (state: RootState) => ({
	employees: state.EmployeeReducer.employees,
});

export default connect(mapStateToProps)(ConfirmModal);
