import { Dispatch } from '@reduxjs/toolkit';
import { List, Row, Col, Typography, Divider, Badge } from 'antd';
import { BookingCard, Input, StatusGroupButton } from 'components';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { BookingStatus } from 'utils/Consts';
import {
	getTodayBookingByStatus,
	searchTodayBooking,
} from 'redux/selectors/employee';
import { _t, I18n, translations } from 'utils';
import { SearchOutlined } from '@ant-design/icons';
import './styles.scss';
import _ from 'lodash';
import { EBookingType, IBooking } from 'models';
import { ColorHelper } from 'helpers';
import moment from 'moment';

const getStatusOrder = (status: number) => {
	switch (status) {
		case BookingStatus.CHECKED_IN:
			return 0;
		case BookingStatus.PENDING:
			return 1;
		default:
			return status;
	}
};

interface IRealTimeBookingProps
	extends ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}

class RealTimeBooking extends React.Component<IRealTimeBookingProps> {
	state = {
		status: -1,
		searchText: '',
	};
	render() {
		const dataSource = _.isEmpty(this.state.searchText)
			? this.props.todayBookings(this.state.status)
			: this.props.searchTodayBookings(
					this.props.todayBookings(this.state.status),
					this.state.searchText
			  );
		return (
			<>
				{this.renderHeader()}
				<Row gutter={24} style={{ width: '100%', backgroundColor: '#f0f2f5' }}>
					<Col span={12}>{this.renderList(EBookingType.Appointment)}</Col>
					<Col style={{ paddingRight: -24 }} span={12}>
						{this.renderList(EBookingType.WalkIn)}
					</Col>
				</Row>
			</>
		);
	}
	renderList(type: EBookingType) {
		const dataSource = _.isEmpty(this.state.searchText)
			? this.props.todayBookings(this.state.status)
			: this.props.searchTodayBookings(
					this.props.todayBookings(this.state.status),
					this.state.searchText
			  );

		return (
			<List
				header={
					<Typography.Title style={{ marginLeft: 16 }} level={3}>
						{type === EBookingType.WalkIn ? 'WALK IN' : 'APPOINTMENT'}
					</Typography.Title>
				}
				grid={{ gutter: 8, column: 3 }}
				dataSource={_.orderBy(
					dataSource.filter((booking) => {
						if (booking.status === BookingStatus.DONE) {
							return false;
						}
						if (type === EBookingType.WalkIn) {
							return booking.bookingType === type;
						}
						if (type === EBookingType.Appointment) {
							return booking.bookingType !== EBookingType.WalkIn;
						}
					}),
					[(x) => getStatusOrder(x.status), (x) => x.startTimeExpected],
					['asc', 'asc']
				)}
				style={{
					backgroundColor: 'white',
					height: '100%',
					paddingLeft: 8,
					paddingRight: 8,
				}}
				renderItem={(booking) => (
					<List.Item style={{ cursor: 'pointer' }} className="item-booking">
						{type === EBookingType.WalkIn ? (
							<Badge.Ribbon
								text={
									type === EBookingType.WalkIn ? booking.bookingNumber : null
								}
								style={{
									backgroundColor: ColorHelper.getStatusColor(booking.status),
									fontWeight: 'bold',
								}}
							>
								<BookingCard
									showBlinking
									showBookingNumber={true}
									short
									booking={booking}
								/>
							</Badge.Ribbon>
						) : (
							<BookingCard
								showBookingNumber={true}
								short
								showBlinking
								booking={booking}
							/>
						)}
					</List.Item>
				)}
			/>
		);
	}

	renderHeader = () => {
		return (
			<Row
				gutter={[16, 16]}
				style={{ alignItems: 'center', paddingBottom: 20 }}
			>
				<Col md={12}>
					<Input
						allowClear
						prefix={<SearchOutlined />}
						style={{ width: '100%' }}
						placeholder={I18n.t(_t(translations.customerList.search))}
						onChange={(e) => this.setState({ searchText: e.target.value })}
					/>
				</Col>
				<Col md={12}>{this.renderButtonGroup()}</Col>
			</Row>
		);
	};

	renderButtonGroup() {
		return (
			<div className="flex-display flex-justify-right type-container margin-bottom-16 margin-top-16">
				<StatusGroupButton
					count={[
						this.props
							.todayBookings()
							.filter((booking) => booking.status !== BookingStatus.DONE)
							.length,
						this.props.todayBookings(BookingStatus.PENDING).length,
						this.props.todayBookings(BookingStatus.CHECKED_IN).length,
						this.props.todayBookings(BookingStatus.PROCESSING).length,
						this.props.todayBookings(BookingStatus.FINISHED).length,
					]}
					status={this.state.status}
					hideDone
					defaultValue={-1}
					onClickRadioButton={(e) => this.onChooseStatus(e.target.value)}
				/>
			</div>
		);
	}

	onChooseStatus(status: number) {
		this.setState({ status });
	}
}

const mapStateToProps = (state: RootState) => ({
	todayBookings: (status: number = -1) =>
		getTodayBookingByStatus(state, status),
	searchTodayBookings: (bookings: IBooking[], searchText: string = '') =>
		searchTodayBooking(bookings, searchText),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RealTimeBooking);
