import { EEmployeeTypeCode, IEmployee } from 'models';
import { BaseApiService } from 'services/BaseApiService';
import {
	IAddEmployeeRequest,
	IEditEmployeeRequest,
	IEmployeeRequest,
} from 'models/RequestModels';
class EmployeeApiService extends BaseApiService {
	public getAllEmployees = (query?: IEmployeeRequest) =>
		this.get<IEmployee[]>(`/Employee/get-all-employees`, {
			params: query,
		});

	public getAllStylist = (branchId?: string) =>
		this.get<IEmployee[]>(`/Employee/get-all-employees`, {
			params: {
				// employeeTypeCode: EEmployeeTypeCode.STYLIST,
				branchId,
			},
		});
	public getEmployeeById = (id: string) =>
		this.get<IEmployee>(`/Employee/get-employee/${id}`);

	public addEmployee = (addEmployeeRequest: IAddEmployeeRequest) =>
		this.post<IAddEmployeeRequest, string>(
			`/Employee/create-employee-with-user`,
			addEmployeeRequest
		);

	public editEmployee = (editEmployeeRequest: IEditEmployeeRequest) =>
		this.put<IEditEmployeeRequest, string>(
			`/Employee/update-employee`,
			editEmployeeRequest
		);

	public deleteEmployee = (id: string) =>
		this.delete<string>(`/Employee/delete-employee/${id}`);

	public getEmployeesByBranch = () =>
		this.get<IEmployee[]>(`/Employee/get-employees-by-branch`);

	public getAllStylists = () =>
		this.get<IEmployee[]>(`/Employee/get-all-stylists`);

	public updateEmployeeStatus = (updateStatusRequest: Partial<IEmployee>) =>
		this.put<Partial<IEmployee>, string>(
			`/Employee/update-employee-status`,
			updateStatusRequest
		);
	public updateBookedOnlineStatus = (
		updateBookedOnlineStatusRequest: Partial<IEmployee>
	) =>
		this.put<Partial<IEmployee>, string>(
			`/Employee/update-booked-online-status`,
			updateBookedOnlineStatusRequest
		);
}

export default new EmployeeApiService();
