import { kStyles } from 'GlobalStyles';

const styles: kStyles = {
  addHolidayCol: {
    padding: 0,
  },
  addHolidayDatePicker: { width: "100%" },
  addHolidayTitle: { textAlign: "center", margin: 0 },
  modalTitle: { textAlign: 'center', margin: 0, color: '#fff', textTransform: "uppercase" },
  maxWidth: { width: '100%' }
};

export default styles;
