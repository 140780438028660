import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { SettingActions } from 'redux/actions';
import { Const } from 'utils';

export interface ISettingsReducer {
	printerIp?: string;
	printerPort: string;
	printerConnectionType?: Const.PRINTER_CONNECTION;
	receiptPrintType: Const.RECEIPT_PRINT_TYPE;
	isEnableTicketPrinting: boolean
}

const initialState: ISettingsReducer = {
	printerPort: '9100',
	printerConnectionType: Const.PRINTER_CONNECTION.USB,
	receiptPrintType: Const.RECEIPT_PRINT_TYPE.ONE,
	isEnableTicketPrinting: false
};

const setEnableTicketPrinting = (
	state: ISettingsReducer,
	action: PayloadAction<boolean>
) => {
	state.isEnableTicketPrinting = action.payload;
};

const SettingReducer = createReducer(initialState, (builder) =>
	builder
		.addCase(
			SettingActions.savePrinterIP.request,
			(
				state: ISettingsReducer,
				action: PayloadAction<{
					printerIp: string;
					printerPort: string;
					type?: Const.PRINTER_CONNECTION;
				}>
			) => {
				state.printerIp = action.payload.printerIp;
				state.printerPort = action.payload.printerPort;
				state.printerConnectionType = action.payload.type;
			}
		)
		.addCase(
			SettingActions.saveReceipt.request,
			(
				state: ISettingsReducer,
				action: PayloadAction<{
					type: Const.RECEIPT_PRINT_TYPE;
				}>
			) => {
				state.receiptPrintType = action.payload.type;
			}
		)
		.addCase(SettingActions.setEnableTicketPrinting.request, setEnableTicketPrinting)
);

export default SettingReducer;
