import { Col, Form, Row, TimePicker, FormInstance } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React from 'react';
import { I18n, _t, translations, Const } from 'utils';
import _ from 'lodash';
import styles from './styles';
import { getDayOfWeek } from 'helpers/StringHelper';
import { TimeHelper } from 'helpers';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { FieldData } from 'rc-field-form/lib/interface';
const { toTimeZone } = TimeHelper;

interface IWorkingTimeOfDayProps extends ReturnType<typeof mapStateToProps> {
	day: number;
	wholeWeekChecked: boolean;
	initialValue?: string[];
	onValueChange?(isCheck: boolean): void;
	defaultChecked: boolean;
	formRef?: FormInstance;
}

interface IWorkingTimeOfDayState {
	checked: boolean;
}

class WorkingTimeOfDay extends React.Component<
	IWorkingTimeOfDayProps,
	IWorkingTimeOfDayState
> {
	constructor(props: IWorkingTimeOfDayProps) {
		super(props);
		this.state = {
			checked: this.props.wholeWeekChecked || this.props.defaultChecked,
		};
	}

	// componentDidMount() {
	// 	this.props.onValueChange && this.props.onValueChange!(this.state.checked);
	// }

	componentDidUpdate(prevProps: IWorkingTimeOfDayProps) {
		if (
			this.props.day !== 7 &&
			isWholeWeekDifference(
				prevProps.wholeWeekChecked,
				this.props.wholeWeekChecked
			)
		) {
			this.setState({ checked: this.props.wholeWeekChecked });
		}
	}
	render() {
		return (
			<Row gutter={16} style={styles.container}>
				<Col span={9} style={styles.checkBoxCol}>
					<Form.Item
						initialValue={this.state.checked}
						name={`${getDayOfWeek(this.props.day)
							.replaceAll(' ', '')
							.toLowerCase()}Check`}
						valuePropName={'checked'}
						noStyle={true}
					>
						<Checkbox
							onChange={(e) => {
								this.setState({ checked: e.target.checked }, () => {
									this.props.day !== Const.WeekDays.WHOLEWEEK &&
										this.notWholeWeekChange();
									this.props.onValueChange &&
										this.props.onValueChange!(e.target.checked);
								});
							}}
						>
							{getDayOfWeek(this.props.day)}
						</Checkbox>
					</Form.Item>
				</Col>
				<Col span={15}>
					<Form.Item
						initialValue={
							this.props.initialValue
								? [
										this.getInitialWorkingTime(this.props.initialValue![0]),
										this.getInitialWorkingTime(this.props.initialValue![1]),
								  ]
								: [
										momentTz().tz(this.props.timezone!).startOf('date').hour(8),
										momentTz()
											.tz(this.props.timezone!)
											.startOf('date')
											.hour(22),
								  ]
						}
						name={getDayOfWeek(this.props.day)
							.replaceAll(' ', '')
							.toLowerCase()}
						rules={[
							{
								required: this.state.checked,
								message: `${getDayOfWeek(this.props.day)} ${I18n.t(
									_t(translations.branchSetting.workingHoursMessage)
								)}`,
							},
						]}
						// noStyle={true}
					>
						<TimePicker.RangePicker
							disabled={!this.state.checked}
							style={{ width: '100%' }}
							minuteStep={15}
							format="h:mm a"
							showNow={false}
							onChange={(value) => {
								this.props.day === 7 &&
									this.onWholeWeekChange(value as moment.Moment[]);
							}}
						/>
					</Form.Item>
				</Col>
			</Row>
		);
	}

	getInitialWorkingTime(time: string) {
		const splited = time.split('T');
		const spitedTime = splited[1];
		const date = splited[0];
		const today = moment();
		if (date.includes('2')) {
			today.add(1, 'day');
		}
		const niceTime = toTimeZone(`${today.format('YYYY-MM-DD')}T${spitedTime}`);
		return niceTime;
	}

	getInitalCheck = () => {
		if (_.isEmpty(this.props.initialValue)) {
			this.setState({
				checked: false,
			});
		} else {
			this.setState({
				checked: true,
			});
		}
	};

	onWholeWeekChange = (value: Array<moment.Moment>) => {
		const { formRef } = this.props;
		const fieldsKeys = Object.keys(formRef?.getFieldsValue());
		const timeKeys = fieldsKeys.filter((element) => {
			if (
				element !== 'wholeweek' &&
				element.includes('day') &&
				!element.includes('Check')
			) {
				return true;
			}
			return false;
		});
		const fieldData = timeKeys.map<FieldData>((element) => ({
			name: element,
			value: value,
		}));
		formRef?.setFields(fieldData);
	};

	notWholeWeekChange = () => {
		const { formRef } = this.props;
		const fieldValues = formRef?.getFieldsValue();
		fieldValues['wholeweekCheck'] = false;
		formRef?.setFieldsValue(fieldValues);
	};

	// shouldDisableCheckBox = () => {
	// 	if (this.props.wholeWeekChecked) {
	// 		if (this.props.day === WeekDays.WHOLEWEEK) {
	// 			return false;
	// 		}
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// };
}

//This function returns an array with object element. Each element is an object which has name of form's field, and value of it
//The returned array will be use in setFields() function of formRef
export const getTimeFieldData = (
	fieldsKeys: string[],
	checkValue: boolean
): FieldData[] => {
	const timeKeys = fieldsKeys.filter((element) => {
		if (
			element !== 'wholeweekCheck' &&
			element.includes('Check') &&
			!element.includes('Online')
		) {
			return true;
		}
		return false;
	});
	return timeKeys.map<FieldData>((element) => ({
		name: element,
		value: checkValue,
	}));
};

export const isWholeWeekDifference = (
	prevBoolean: boolean,
	thisBoolean: boolean
) => {
	if (!prevBoolean && thisBoolean) {
		return true;
	}
	if (prevBoolean && !thisBoolean) {
		return true;
	}
	return false;
};

const mapStateToProps = (state: RootState) => ({
	timezone: state.BranchReducer.currentBranch?.localTimeZone,
});

export default connect(mapStateToProps)(WorkingTimeOfDay);
