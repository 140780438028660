import { Device, Call } from '@twilio/voice-sdk';
import { AppConfigActions } from 'redux/actions';
import configureStore from 'redux/configuration/configureStore';
import Twilio from 'twilio';
const AccessToken = Twilio.jwt.AccessToken;
const VoiceGrant = AccessToken.VoiceGrant;

class TwilioService {
	public device: Device;
	private currentCall?: Call;
	constructor() {
		const accessToken = new AccessToken(
			'AC75a5b411c322388e8d7997c95860c706',
			'SK38074447b0ae28862fe7f4045cd70a5f',
			'jVl28sUfu2yc32GX8fMSGK7WwrfwGdVO'
		);
		const grant = new VoiceGrant({
			outgoingApplicationSid: 'AP2e0a0cea1da15fb34a41d4830a881d00',
			incomingAllow: true,
		});
		accessToken.addGrant(grant);
		const response = accessToken.toJwt();
		const newDevice = new Device(response);
		this.device = newDevice;
	}

	private updateStore = (calling: boolean) => {
		const store = configureStore().store;
		store.dispatch(AppConfigActions.setCalling.request(calling));
	};

	public makeCall = async (clientName: string) => {
		if (this.device) {
			this.currentCall = await this.device.connect({
				params: {
					To: '+12027336544',
					From: `client:${clientName}`,
				},
			});
			this.currentCall.on('disconnect', () => {
				console.log('disconnected');
				this.updateStore(false);
			});
			this.updateStore(true);
		}
	};
	public endCall = () => {
		if (this.device) {
			this.device.disconnectAll();
		}
	};
}
const TwilioServiceInstance = new TwilioService();
export default TwilioServiceInstance;
