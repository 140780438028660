/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Card,
	Col,
	Divider,
	Form,
	Input,
	Layout,
	Row,
	Spin,
	Typography,
	Upload,
} from 'antd';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { IComponentProps, DetailPageLayout } from 'components';
import I18n from 'i18n-js';
import { translations, _t } from 'utils';
import './styles.css';
import { ShopActions, ThemeActions } from 'redux/actions';
import { FormFinishInfo } from 'rc-field-form/es/FormContext';
import { AlertHelper, FormHelper } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import Avatar from 'antd/lib/avatar/avatar';
import { FormInstance } from 'rc-field-form';
import formHelper from 'helpers/formHelper';
import {
	BgColorsOutlined,
	FacebookOutlined,
	GoogleOutlined,
	InstagramOutlined,
	LoadingOutlined,
	TwitterOutlined,
} from '@ant-design/icons';
import { ShopApiService } from 'services';
import { IApiResponse, IErrorResponse } from 'models';
import UploadBaseService from 'services/UploadBaseService';
import { IFileUploadResponse } from 'models/ResponseModels';
import { IShop } from 'models/IShop';
import { ColorPickerModal, ThemePickerModal } from 'components';
import styles from './styles';
import QRCode from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';
import { electron } from 'ipc';
import { createMenuQr, print } from 'helpers/printHelper';
import ImgCrop from 'antd-img-crop';

interface IShopSettingProps extends IComponentProps {}
const ShopQrMenu = () => {
	const shop = useSelector((state: RootState) => state.ShopReducer.shop);
	const componentRef = useRef<any>();
	const pageStyle = `
	@page { 
	  size: auto;  margin: 0mm ; } @media print { body { -webkit-print-color-adjust: exact; } }
	@media print {
	  div.page-footer {
	  position: fixed;
	  bottom:0mm;
	  width: 100%;
	  height: 50px;
	  font-size: 15px;
	  color: #fff;
	  /* For testing */
	  background: red; 
	  opacity: 0.5;
	  
	  page-break-after: always;
	  }
	  .page-number:before {
		/* counter-increment: page; */
		content: "Pagina "counter(page);
	  }
  
  
	}
	body {
	  marginBottom:50px;
	  display:flex
	}
	`;
	const handlePrint = useReactToPrint({
		content: () => componentRef.current!,
		pageStyle: pageStyle,
	});

	const onPrint = () => {
		if (!electron) {
			handlePrint!();
		} else {
			const commands = createMenuQr(
				`https://${shop?.adminUrl}.website.smartsalon.live/menu`
			);
			print(commands);
		}
		// const content = document.getElementById('divcontents');
		// const priElement = document.getElementById('ifmcontentstoprint')
		// if (content && priElement){
		// 	const pri = priElement.contentWindow
		// 	pri.document.open();
		// 	pri.document.write(content.innerHTML);
		// 	pri.document.close();
		// 	pri.focus();
		// 	pri.print();
		// }
	};
	return (
		<Card>
			<Card.Meta
				title={'Shop Menu QRCode'}
				// description={I18n.t(_t(translations.shopSetting.subTheme))}
			/>
			<Divider />
			<Row gutter={[16, 16]} justify="center" align="middle">
				<Col
					ref={(ref) => {
						if (ref) {
							componentRef.current = ref;
						}
					}}
					style={{
						justifyContent: 'center',
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'column',
						padding: 40,
					}}
					span={24}
					id="qrCodeMenu"
				>
					<Typography.Title level={4} style={{ marginBottom: 18 }}>
						Scan QRCode to view our services{' '}
					</Typography.Title>
					<QRCode
						value={`https://${shop?.adminUrl}.website.smartsalon.live/menu`}
						size={200}
					/>
				</Col>
				<Col>
					<Button onClick={() => onPrint()}>Print QR Code</Button>
				</Col>
			</Row>
		</Card>
	);
};
const ShopSetting = ({ history, location }: IShopSettingProps) => {
	const shopAction = useSelector(
		(state: RootState) => state.ReduxActionReducer['SHOP']
	);
	const shop = useSelector((state: RootState) => state.ShopReducer.shop);
	const themes = useSelector((state: RootState) => state.ThemeReducer.themes);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedImage, setSelectedImage] = useState<File | undefined>();
	const [selectedColor, setSelectedColor] = useState<string>(
		shop?.themeShopConfig?.defaultColor || ''
	);
	const formRef = useRef<FormInstance>();
	const [adminUrlLoading, setAdminUrlLoading] = useState<boolean>(false);
	const [isShowThemePicker, setIsShowThemePicker] = useState<boolean>(false);
	const [isShowColorPicker, setIsShowColorPicker] = useState<boolean>(false);
	const [isInitial, setIsInitial] = useState<boolean>(true);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(ShopActions.getShop.request());
		dispatch(
			ThemeActions.getAllThemes.request({ pageNumber: 1, pageSize: 1000 })
		);
		setIsInitial(false);
	}, [dispatch]);

	useEffect(() => {
		if (isInitial) {
			return;
		}
		if (shopAction === ShopActions.updateShop.requestName) {
			setIsLoading(true);
		} else if (shopAction === ShopActions.updateShop.successName) {
			AlertHelper.showSuccess(
				I18n.t(_t(translations.shopSetting.updateShopSuccess))
			);
			history.goBack();
		} else {
			setIsLoading(false);
		}
	}, [shopAction, isInitial]);

	const renderLoading = () => {
		return (
			<Layout style={styles.spinLayout}>
				<Spin style={styles.spin} size="large" />
			</Layout>
		);
	};

	const renderContainer = () => {
		return (
			<>
				<DetailPageLayout
					title={I18n.t(_t(translations.shopSetting.title))}
					positiveButtonName={I18n.t(_t(translations.save))}
					showDelete={false}
					isLoading={isLoading}
					goBack={goBack}
					onFormFinish={onFormFinish}
					formName="updateShopForm"
					formRef={(ref) => {
						formRef.current = ref;
					}}
				>
					{renderShopInputs()}
					{renderSocialNetworkUrlInputs()}
					<ShopQrMenu />
					{renderThemeInputs()}
					{renderStaffCodeRequireSetting()}
				</DetailPageLayout>
				<ThemePickerModal
					isShowModal={isShowThemePicker}
					onCancel={() => setIsShowThemePicker(false)}
					onChooseTheme={(theme) => {
						formRef.current?.setFields([
							{
								name: ['themeShopConfig', 'themeName'],
								value: theme.themeName,
							},
						]);
						setIsShowThemePicker(false);
					}}
					themes={themes}
					initialTheme={shop?.themeShopConfig?.themeName}
				/>
				<ColorPickerModal
					onChooseColor={(color) => {
						formRef.current?.setFields([
							{ name: ['themeShopConfig', 'defaultColor'], value: color },
						]);
						setIsShowColorPicker(false);
						setSelectedColor(color);
					}}
					isShowModal={isShowColorPicker}
					onCancel={() => setIsShowColorPicker(false)}
					initialColor={shop?.themeShopConfig?.defaultColor}
				/>
			</>
		);
	};

	//Shop Basic Info ============================================================================================>

	const renderShopInputs = () => {
		return (
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.shopSetting.shop))}
					description={I18n.t(_t(translations.shopSetting.subShop))}
				/>
				<Divider />
				<Row gutter={16} justify="start">
					<Col>
						<Row className="logo-title-container">
							<Col>
								<Typography.Title level={5}>
									{I18n.t(_t(translations.shopSetting.logo))}
								</Typography.Title>
								<Typography.Text className="logo-description">
									{I18n.t(_t(translations.shopSetting.subLogo))}
								</Typography.Text>
							</Col>
						</Row>
						<ImgCrop>
							<Upload
								name="logo"
								className="logo-uploader"
								showUploadList={false}
								beforeUpload={(file) => {
									setSelectedImage(file);
								}} //Set this to false => make the Upload not request to any where after select image
								accept={'.png,.jpg,.jpeg'}
								multiple={false}
								customRequest={(options) => {}}
								disabled={isLoading}
							>
								{selectedImage ? (
									<Avatar
										className="alt-logo"
										shape="square"
										size={200}
										src={URL.createObjectURL(selectedImage!)}
										alt="logo"
									/>
								) : shop?.logoUrl ? (
									<Avatar
										src={shop?.logoUrl}
										className="alt-logo"
										shape="square"
										size={200}
									/>
								) : (
									<Avatar className="alt-logo" shape="square" size={200}>
										{I18n.t(_t(translations.shopSetting.noLogo))}
									</Avatar>
								)}
								{/* <Button type="primary">{I18n.t(_t(translations.upload))}</Button> */}
							</Upload>
						</ImgCrop>
					</Col>
				</Row>
				{FormHelper.renderFormInput(
					'name',
					[
						{
							required: true,
							message: I18n.t(_t(translations.shopSetting.nameRequired)),
						},
					],
					I18n.t(_t(translations.shopSetting.name)),
					shop?.name,
					{},
					{ inputMode: 'text', maxLength: 256, disabled: isLoading }
				)}
				<Row gutter={16}>
					{renderAdminUrlInput()}
					{FormHelper.renderFormInput(
						'portalUrl',
						[
							// {
							// 	required: true,
							// 	message: I18n.t(_t(translations.branchSetting.faxMessage)),
							// },
						],
						I18n.t(_t(translations.shopSetting.portalUrl)),
						shop?.portalUrl || '',
						{ span: 12 },
						{ maxLength: 256, disabled: isLoading }
					)}
				</Row>
			</Card>
		);
	};

	const renderAdminUrlInput = () => {
		return (
			<Col span={12}>
				<Form.Item
					label={
						<Typography.Title level={5}>
							{I18n.t(_t(translations.shopSetting.adminUrl))}
						</Typography.Title>
					}
					initialValue={shop?.adminUrl || ''}
					name={'adminUrl'}
					rules={[
						{
							required: true,
							message: I18n.t(_t(translations.shopSetting.adminUrlRequired)),
						},
						({ isFieldTouched }) => ({
							async validator(robj, value) {
								if (isFieldTouched('adminUrl')) {
									return;
								}
								if (value === shop?.adminUrl) {
									return;
								}
								setAdminUrlLoading(true);
								const result = (await ShopApiService.getShopByAdminUrl(
									value
								)) as IApiResponse;
								setAdminUrlLoading(false);
								if (result.succeeded) {
									throw new Error(
										I18n.t(_t(translations.shopSetting.adminUrlExisted))
									);
								}
								return;
							},
						}),
					]}
				>
					<Input
						onBlur={() => {
							if (formRef.current) {
								formRef.current.setFields([
									{ name: 'adminUrl', touched: false },
								]);
								formRef.current.validateFields(['adminUrl']);
							}
						}}
						maxLength={256}
						suffix={adminUrlLoading ? <LoadingOutlined /> : undefined}
						disabled={adminUrlLoading || isLoading}
					/>
				</Form.Item>
			</Col>
		);
	};

	//============================================================================================>
	//Social URL===========================================================================================

	const renderSocialNetworkUrlInputs = () => {
		return (
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.shopSetting.socialNetwork))}
					description={I18n.t(_t(translations.shopSetting.subSocialNetwork))}
				/>
				<Divider />
				<Row gutter={16}>
					{FormHelper.renderFormInput(
						'googleUrl',
						[],
						I18n.t(_t(translations.shopSetting.googleUrl)),
						shop?.googleUrl || '',
						{ span: 12 },
						{ maxLength: 256, disabled: isLoading, prefix: <GoogleOutlined /> }
					)}
					{FormHelper.renderFormInput(
						'twitterUrl',
						[],
						I18n.t(_t(translations.shopSetting.twitterUrl)),
						shop?.twitterUrl || '',
						{ span: 12 },
						{ maxLength: 256, disabled: isLoading, prefix: <TwitterOutlined /> }
					)}
				</Row>
				<Row gutter={16}>
					{FormHelper.renderFormInput(
						'facebookUrl',
						[],
						I18n.t(_t(translations.shopSetting.facebookUrl)),
						shop?.facebookUrl || '',
						{ span: 12 },
						{
							maxLength: 256,
							disabled: isLoading,
							prefix: <FacebookOutlined />,
						}
					)}
					{FormHelper.renderFormInput(
						'instagramUrl',
						[],
						I18n.t(_t(translations.shopSetting.instagramUrl)),
						shop?.instagramUrl || '',
						{ span: 12 },
						{
							maxLength: 256,
							disabled: isLoading,
							prefix: <InstagramOutlined />,
						}
					)}
				</Row>
			</Card>
		);
	};

	//Theme =====================================================================================>

	const renderThemeInputs = () => {
		const cursorPointer = {
			color: selectedColor,
		};
		return (
			<Card>
				<Card.Meta
					title={I18n.t(_t(translations.shopSetting.theme))}
					description={I18n.t(_t(translations.shopSetting.subTheme))}
				/>
				<Divider />
				<Row gutter={16}>
					{formHelper.renderFormInput(
						['themeShopConfig', 'defaultColor'],
						[
							{
								required: true,
								message: I18n.t(_t(translations.shopSetting.mainColorRequired)),
							},
						],
						I18n.t(_t(translations.shopSetting.mainColor)),
						shop?.themeShopConfig?.defaultColor,
						{ span: 12 },
						{
							contentEditable: false,
							onClick: () => {
								setIsShowColorPicker(true);
							},
							className: 'uneditable-input',
							disabled: isLoading,
							style: cursorPointer as CSSProperties,
						}
					)}
					{formHelper.renderFormInput(
						['themeShopConfig', 'themeName'],
						[{ required: true }],
						I18n.t(_t(translations.shopSetting.landingPageTheme)),
						shop?.themeShopConfig?.themeName || '',
						{ span: 12 },
						{
							contentEditable: false,
							onClick: () => {
								setIsShowThemePicker(true);
							},
							className: 'uneditable-input-with-icon',
							prefix: <BgColorsOutlined />,
							disabled: isLoading,
							style: { textTransform: 'none' },
						}
					)}
				</Row>
			</Card>
		);
	};

	//Staff Code===========================================================================>
	const renderStaffCodeRequireSetting = () => {
		return (
			<Card>
				<Card.Meta
					title={'Checkout Setting'}
					// description={I18n.t(_t(translations.shopSetting.subTheme))}
				/>
				<Divider />
				<Row gutter={16}>
					{FormHelper.renderFormSwitch(
						'checkEmployeeCodeWhenCheckout',
						'Require staff code when checkout',
						shop?.checkEmployeeCodeWhenCheckout ?? true,
						{ span: 12 }
					)}
				</Row>
			</Card>
		);
	};

	//====================================================================================>
	const onFormFinish = async (_: string, { values, forms }: FormFinishInfo) => {
		setIsLoading(true);
		let logoUrl = shop?.logoUrl;
		if (selectedImage) {
			const uploadResult = (await UploadBaseService.uploadImage([
				selectedImage,
			])) as IApiResponse<IFileUploadResponse[]>; //Dont know why to keep it in array
			if (uploadResult.succeeded) {
				logoUrl = uploadResult.data![0].imageUrl;
			} else {
				AlertHelper.showError(uploadResult as IErrorResponse);
			}
		}

		const formData: FormData = { ...(values as FormData) };
		const shopData: IShop = {
			...shop!,
			...formData,
			themeShopConfig: {
				...shop!.themeShopConfig!,
				defaultColor: formData.themeShopConfig.defaultColor,
				themeName: formData.themeShopConfig.themeName,
			},
			logoUrl,
		};
		dispatch(ShopActions.updateShop.request(shopData));
	};

	const goBack = () => {
		const { state } = location;
		if (state && state.fromOurWeb) {
			history.goBack();
		} else {
			history.push('/today');
		}
	};

	return (
		<>
			{shopAction === ShopActions.getShop.requestName
				? renderLoading()
				: renderContainer()}
		</>
	);
};

type FormData = {
	name: string;
	adminUrl: string;
	portalUrl: string;
	themeShopConfig: {
		defaultColor: string;
		themeName: string;
	};
};

export default ShopSetting;
