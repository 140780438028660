import { Table, Typography } from 'antd';
import { IServedService } from 'models';
import React, { Component } from 'react';
import { I18n, translations, _t } from 'utils';
const { Text } = Typography;
const { Column } = Table;

interface IServedServicesProps {
	servedServices: IServedService[];
}

interface IServedServicesState {}

class ServedServices extends Component<
	IServedServicesProps,
	IServedServicesState
> {
	render() {
		return (
			<Table<IServedService>
				dataSource={this.props.servedServices}
				size="large"
				pagination={false}
				scroll={{ y: 255 }}
			>
				<Column<IServedService>
					dataIndex={'serviceName'}
					render={(value: string) => {
						return value;
					}}
					title={
						<Text>{I18n.t(_t(translations.payrollDetailReport.name))}</Text>
					}
				/>
				<Column<IServedService>
					dataIndex={'quantity'}
					render={(value: number) => {
						return value;
					}}
					title={
						<Text>{I18n.t(_t(translations.payrollDetailReport.times))}</Text>
					}
				/>
			</Table>
		);
	}
}
export default ServedServices;
