import { Typography } from 'antd';
import { Modal } from 'components';
import React from 'react';
import { Colors, I18n, translations, _t } from 'utils';

interface IConfirmModalProps {
	onOk: () => void;
	onCancel: () => void;
	visible?: boolean;
	title?: string;
	description?: string;
	notDanger?: boolean;
}
const ConfirmModal = ({
	onCancel,
	onOk,
	visible,
	description,
	title,
	notDanger = false,
}: IConfirmModalProps) => {
	return (
		<Modal
			handleCancel={onCancel}
			handleOk={onOk}
			visible={visible || false}
			danger={!notDanger}
			title={
				title ? (
					<Typography.Text style={{ color: Colors.WHITE }}>
						{title}
					</Typography.Text>
				) : (
					<Typography.Text style={{ color: Colors.WHITE }}>
						{I18n.t(_t(translations.confirmation))}
					</Typography.Text>
				)
			}
			OKString={I18n.t(_t(translations.ok))}
		>
			{description ? (
				<Typography.Text>{description}</Typography.Text>
			) : (
				<Typography.Text>
					{I18n.t(_t(translations.text.areYouSure))}
				</Typography.Text>
			)}
		</Modal>
	);
};

export default ConfirmModal;
