import { ILoyaltyRank } from 'models';
import { IPaginateRequest } from 'models/RequestModels';
import { IPaginateResponse } from 'models/ResponseModels';
import { BaseApiService } from './BaseApiService';

const URL = '/LoyaltyRank';

class LoyaltyRankApiService extends BaseApiService {
	public getAllLoyaltyRank = (request: IPaginateRequest) =>
		this.get<IPaginateResponse<ILoyaltyRank[]>>(
			`${URL}/get-all-loyalty-ranks`,
			{ params: request }
		);
	public createLoyaltyRank = (request: Partial<ILoyaltyRank>) =>
		this.post<Partial<ILoyaltyRank>, ILoyaltyRank>(
			`${URL}/create-loyalty-rank`,
			request
		);
	public updateLoyaltyRank = (request: ILoyaltyRank) =>
		this.put<ILoyaltyRank, ILoyaltyRank>(`${URL}/update-loyalty-rank`, request);
	public deleteLoyaltyRank = (id: string) =>
		this.delete<string>(`${URL}/delete-loyalty-rank/${id}`);
}

export default new LoyaltyRankApiService(true);
