import { Component } from 'react';
import { Row, Col, Space, Button, Input, Layout, Typography } from 'antd';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { I18n, _t, translations } from 'utils';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import styles from '../styles';
import { history } from 'routers';
import { Header } from 'components';
const { Title } = Typography;

interface IUserGuideState {
	pageNumber: number;
	numPages: number;
}

class UserGuide extends Component<any, IUserGuideState> {
	state = {
		pageNumber: 1,
		numPages: 0,
	};

	render() {
		return (
			<>
				<Layout style={{ minHeight: '100vh', backgroundColor: 'white' }}>
					{this.renderHeader()}
					{this.renderContent()}
				</Layout>
			</>
		);
	}

	renderHeader = () => {
		return (
			<Header
				detailPageProps={{
					title: I18n.t(_t(translations.helpCenter.userGuide)),
					onClosePress: () => history.goBack(),
				}}
			/>
		);
	};

	renderContent = () => {
		return (
			<Content style={{ marginTop: 64, height: '100%' }}>
				<Space
					style={{ justifyContent: 'center', width: '100%', paddingTop: 16 }}
					direction="vertical"
				>
					<Row style={{ padding: 16 }}>
						<Col span={24}>
							<Row style={{ width: '100%', justifyContent: 'center' }}>
								<Col style={{ padding: 8 }} span={24} lg={8}>
									<Document
										file="/assets/SmartSalon_UserManual_0.3.pdf"
										onLoadSuccess={this.onDocumentLoadSuccess}
										options={{
											cMapUrl: 'cmaps/',
											cMapPacked: true,
										}}
									>
										<Page pageNumber={this.state.pageNumber} />
										<Space
											size={0}
											direction="horizontal"
											className="page-controls"
										>
											<Button
												disabled={this.state.pageNumber <= 1}
												onClick={this.previousPage}
											>
												<LeftOutlined />
											</Button>
											<Input
												style={{ width: 80 }}
												onChange={this.onChangePageNumber}
												value={this.state.pageNumber}
												type="number"
											/>
											<Button>
												{' '}
												{I18n.t(_t(translations.userGuide.of))}{' '}
												{this.state.numPages || '--'}{' '}
											</Button>
											<Button
												disabled={this.state.pageNumber >= this.state.numPages}
												onClick={this.nextPage}
											>
												<RightOutlined />
											</Button>
										</Space>
									</Document>
								</Col>
							</Row>
						</Col>
					</Row>
				</Space>
			</Content>
		);
	};

	onChangePageNumber = (event: { target: { value: string } }) => {
		const pageNumber = parseInt(event.target.value);
		if (pageNumber && pageNumber > 0 && pageNumber <= this.state.numPages) {
			this.setState({ pageNumber: parseInt(event.target.value) });
		}
	};

	previousPage = () => this.setState({ pageNumber: this.state.pageNumber - 1 });
	nextPage = () => this.setState({ pageNumber: this.state.pageNumber + 1 });

	onDocumentLoadSuccess = (value: { numPages: number }) => {
		this.setState({ numPages: value.numPages });
	};
}

export default UserGuide;
