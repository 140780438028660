import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Table, Typography } from 'antd';
import { ContentAdminContainer } from 'components';
import { TimeHelper } from 'helpers';
import I18n from 'i18n-js';
import { ICloseOnlineBooking } from 'models/ICloseOnlinebooking';
import { useRef, useState } from 'react';
import momentTz from 'moment-timezone';
import { _t, translations } from 'utils';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { DetailModal } from './components/DetailModal';
import moment from 'moment';
import AppTable, { IColumnProps } from 'components/Table';
import { useMemo } from 'react';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { DateRangePicker } from 'components/DatePicker/DateRangePicker';
import { TDateRange } from 'components/DatePicker/CalendarModal';

const Header = (props: { onAddNew: () => void }) => {
	return (
		<Row gutter={16} style={{ alignItems: 'center' }}>
			<Col md={4}></Col>
			<Col md={20}>
				<Space
					style={{ justifyContent: 'flex-end', width: '100%' }}
					size="small"
				>
					<Button
						onClick={() => props.onAddNew()}
						icon={<PlusOutlined />}
						type="primary"
					>
						{I18n.t(_t(translations.addNew))}
					</Button>
				</Space>
			</Col>
		</Row>
	);
};
export interface ITableRef {
	refresh: () => void;
}

export const CloseOnlineBooking = () => {
	const [showModal, setShowModal] = useState(false);
	const [selectData, setSelectData] = useState<ICloseOnlineBooking>();
	const tableRef = useRef<ITableRef>();
	const timeZone = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch?.localTimeZone
	);
	const onOpenModal = (closeOnlineBooking?: ICloseOnlineBooking) => {
		if (closeOnlineBooking) {
			setSelectData(closeOnlineBooking);
		} else {
			setSelectData(undefined);
		}
		setShowModal(true);
	};
	const columns = useMemo<IColumnProps<ICloseOnlineBooking>[]>(() => {
		return [
			{
				header: 'Description',
				key: 'description',
				type: 'text',
			},
			{
				header: 'Date',
				key: 'date',
				type: 'date',
				width: 200,
			},
			{
				header: 'From',
				key: 'startTime',
				type: 'time',
				width: 100,
			},
			{
				header: 'To',
				key: 'endTime',
				type: 'time',
				width: 100,
			},
		];
	}, []);
	return (
		<ContentAdminContainer customPageName={'Close Online Booking'}>
			<AppTable<ICloseOnlineBooking>
				ref={(ref) => {
					if (ref) {
						tableRef.current = ref;
					}
				}}
				columns={columns}
				queryUrl={'/CloseOnlineBookingTime/get-all-close-online-booking-times'}
				onClickRow={(data) => onOpenModal(data)}
				renderHeader={<Header onAddNew={() => onOpenModal()} />}
				additionalParams={{
					fromDate: momentTz.tz(timeZone!).format('YYYY-MM-DD'),
					toDate: momentTz
						.tz(timeZone!)
						.clone()
						.add(1, 'month')
						.format('YYYY-MM-DD'),
					orderBy: 'date asc',
				}}
			/>
			{showModal && (
				<DetailModal
					onSuccess={() => {
						setShowModal(false);
						tableRef.current?.refresh();
					}}
					closeOnlineBooking={selectData}
					onCancel={() => setShowModal(false)}
					show={true}
				/>
			)}
		</ContentAdminContainer>
	);
};
