import { Button, Modal, ModalProps, Row, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { I18n, translations, _t } from 'utils';
const { Title } = Typography;

interface IProps extends ModalProps {
	visible: boolean;
	width?: any;
	onOk: () => void;
	onCancel: () => void;
	onDelete?: () => void;
	title: string;
	centered?: boolean;
	isConfirming?: boolean;
	disableOk?: boolean;
	disableDelete?: boolean;
	btnDelete?: boolean;
	OKString?: string;
	btnOkHidden?: boolean;
	isLoading?: boolean;
	mediumFooter?: boolean;
}

const ModalHelper: React.FC<IProps> = ({
	visible,
	width,
	onOk,
	onCancel,
	onDelete,
	children,
	title,
	centered,
	isConfirming,
	disableOk,
	disableDelete,
	btnDelete,
	OKString,
	btnOkHidden,
	isLoading,
	mediumFooter,
}) => {
	const renderModalFooter = () => {
		return (
			<Row gutter={8} justify={'end'}>
				{!btnDelete ? (
					<Button
						onClick={onCancel}
						type="ghost"
						size={mediumFooter ? 'middle' : 'large'}
						// style={{ width: '20%' }}
					>
						{I18n.t(_t(translations.cancel))}
					</Button>
				) : (
					<Button
						onClick={onDelete}
						type="ghost"
						size="large"
						// style={{ width: '20%' }}
						disabled={disableDelete}
						loading={isLoading}
					>
						{I18n.t(_t(translations.delete))}
					</Button>
				)}
				{!btnOkHidden ? (
					<Button
						loading={isLoading}
						onClick={onOk}
						type="primary"
						size="large"
						// style={{ width: '20%' }}
						disabled={disableOk}
					>
						{!_.isEmpty(OKString) ? OKString : I18n.t(_t(translations.save))}
					</Button>
				) : (
					''
				)}
			</Row>
		);
	};

	return (
		<Modal
			{...ModalHelper}
			visible={visible}
			width={width}
			destroyOnClose={true}
			title={
				<Title
					style={{
						textAlign: 'center',
						margin: 0,
						color: '#fff',
						textTransform: 'uppercase',
					}}
					level={3}
				>
					{title}
				</Title>
			}
			confirmLoading={isConfirming}
			onCancel={onCancel}
			centered={centered}
			footer={renderModalFooter()}
		>
			{children}
		</Modal>
	);
};

export default ModalHelper;
