import { Col, Row, Spin, Layout } from 'antd';
import React from 'react';
import { history } from 'routers';
import { Const, I18n, translations, _t } from 'utils';
import _ from 'lodash';
import { RootState } from 'redux/configuration/rootReducer';
import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import styles from './styles';
import CheckoutInformation from './CheckoutInformation';
import Payment from './Payment';
import {
	BookingActions,
	CheckoutActions,
	CurrencyActions,
	EmployeeActions,
	ErrorActions,
} from 'redux/actions';
import { Header, PrintComponent } from 'components';
import qs from 'qs';
import { IPaymentType, ITransaction } from 'models/ITransaction';
import {
	BookingStatus,
	ModalType,
	PriceType,
	PaymentType,
	TipType,
	DiscounterType,
	DiscountType,
} from 'utils/Consts';
import {
	IApiResponse,
	IBooking,
	IBookingDetail,
	ICustomer,
	IPaymentMethod,
} from 'models';
import { price } from 'redux/selectors/checkout';
import { ITip } from 'models/ITip';
import { ICheckOutRequest } from 'models/RequestModels';
import { IGiftCard } from 'models/IGiftCard';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import BillTemplate from 'components/BillTemplate';
import { createESCCheckOut, print } from 'helpers/printHelper';
import AddTipModal from './components/AddTipModal';
import AddDiscountModal from './components/AddDiscountModal';
import SalesGiftCardModal from './components/SalesGiftCardModal';
import AddExtraModal from './components/AddExtraModal';
import PayCashModal from 'pages/Checkout/components/PayCashModal';
import PayCreditCardModal from 'pages/Checkout/components/PayCreditCardModal';
import PayByAppModal from 'pages/Checkout/components/PayByAppModal';
import ConfirmModal from './components/ConfirmModal';
import {
	BookingHelper,
	CurrencyHelper,
	GenerateHelper,
	TimeHelper,
} from 'helpers';
import { IBilling } from 'models/IBilling';
import { IBillingDetail } from 'models/IBillingDetail';
import { RouteComponentProps } from 'react-router-dom';
import { priceFixed } from 'helpers/currencyHelper';
import OtherPaymentMethodsModal from './components/OtherPaymentModal';
import CombineBillModal from 'pages/Checkout/components/CombineBillModal';
import PayGiftCardModal from 'pages/Checkout/components/PayGiftCardModal';
import PromotionListModal from './components/PromotionListModal';
import InputCouponModal from './components/InputCouponModal';
import { IApplyPromotionRequest, IPromotion } from 'models/IPromotion';
import PromotionApiService from 'services/PromotionApiService';
import { showAlert, showError, showSuccess } from 'helpers/alertHelper';
import BookingApiService from 'services/BookingApiService';
import { IBranch } from 'models/IBranch';
import TipApiService from 'services/TipApiService';
import { IEmployeeTip } from 'models/IEmployeeTip';

const { Content } = Layout;
interface StateProps {
	status?: number;
}
interface ICheckoutProps
	extends RouteComponentProps<any, any, StateProps>,
		ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps> {}
interface ICheckoutStates {
	paymentType: number[];
	modalType?: number;
	tipType: number;
	listStylistAddedTip: string[];
	canPrintBill: boolean;
	canComplete: boolean;
	isDone: boolean;
	showModalTip: boolean;
	showModalDiscount: boolean;
	showModalPayGiftCard: boolean;
	showModalPayCash: boolean;
	showModalPayCreditCard: boolean;
	showModalPayByApp: boolean;
	showModalSalesGiftCard: boolean;
	showModalExtraAmount: boolean;
	showModalConfirm: boolean;
	showModalAppliedPromotion: boolean;
	showModalInputCoupon: boolean;
	employeeCode?: string;
	bookingIndex?: number;
	billIndex?: number;
	showModalCombineBill: boolean;
	booking?: IBooking;
	canUpdate: boolean;
	selectedOtherPaymentMethodId?: string;
	showOtherPaymentMethodsModal: boolean;
	cashReceived?: number;
	loading: boolean;
	promotion?: IPromotion;
}

const getPromotionById = async (ids?: string) => {
	if (!_.isEmpty(ids)) {
		const arrId = ids?.split(/,(.*)/s);
		const res = await PromotionApiService.getPromotionById(arrId![0]);
		if (!_.isEmpty(res.errors)) {
			showError(res.errors);
			return undefined;
		}
		const resData = res as IApiResponse<IPromotion>;
		if (!_.isEmpty(resData.data) && resData.succeeded) {
			return resData.data;
		}
	} else {
		return undefined;
	}
};

class Checkout extends React.Component<ICheckoutProps, ICheckoutStates> {
	constructor(props: ICheckoutProps) {
		super(props);
		this.state = {
			paymentType: !_.isEmpty(this.props.listPaymentType)
				? this.props.listPaymentType.map((x) => x.paymentType!)
				: [],
			modalType: undefined,
			tipType: TipType.AUTO,
			listStylistAddedTip: [] as string[],
			canPrintBill: false,
			canComplete: true,
			canUpdate: false,
			isDone: this.props.currentBooking?.status === BookingStatus.DONE,
			// ---------//
			showModalTip: false,
			showModalDiscount: false,
			showModalPayGiftCard: false,
			showModalPayCash: false,
			showModalPayCreditCard: false,
			showModalPayByApp: false,
			showModalSalesGiftCard: this.isSaleGiftCard() ? true : false,
			showModalExtraAmount: false,
			showModalConfirm: false,
			showModalCombineBill: false,
			showOtherPaymentMethodsModal: false,
			showModalAppliedPromotion: false,
			showModalInputCoupon: false,
			loading: false,
		};
	}

	private printer?: ReactToPrint | null;

	componentDidMount() {
		if (!_.isEmpty(this.props.listCurrency)) {
			this.props.getAllCurrency();
		}
		if (this.isSaleGiftCard()) {
			return;
		}
		const bookingId = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		}).bookingId;
		this.props.getBookingById(bookingId as string);
		if (this.props.currentBooking?.status === BookingStatus.DONE) {
			this.setState({ isDone: true, canComplete: false });
		}
		if (!_.isEmpty(this.props.currentBooking?.promotionIds)) {
			const getPromotion = async () => {
				// this.setState({
				// 	promotion: await getPromotionById(
				// 		this.props.currentBooking?.promotionIds
				// 	),
				// });
				this.props.applyPromotion(
					await getPromotionById(this.props.currentBooking?.promotionIds)
				);
			};
			getPromotion();
		}
	}

	componentDidUpdate(prevProps: ICheckoutProps, prevState: ICheckoutStates) {
		const listExtraAmount: number[] = [];
		this.props.listBookingCombined.forEach((x) =>
			x.bookingDetails.forEach((y) => listExtraAmount.push(y.extraAmount || 0))
		);

		const listPromotion: number[] = [];
		this.props.listBookingCombined.forEach((x) =>
			x.bookingDetails.forEach((y) =>
				listPromotion.push(y.promotionDiscount || 0)
			)
		);

		if (
			prevState.promotion !== this.state.promotion &&
			!_.isEmpty(this.state.promotion)
		) {
			this.applyPromotion();
		}
		if (prevProps.listPaymentType !== this.props.listPaymentType) {
			this.setState({
				paymentType: !_.isEmpty(this.props.listPaymentType)
					? this.props.listPaymentType.map((x) => x.paymentType!)
					: [],
			});
		}

		if (
			prevProps.checkoutAction !== this.props.checkoutAction ||
			prevProps.bookingAction !== this.props.bookingAction
		) {
			if (
				_.sumBy(this.props.listBookingCombined, (x) => x.totalExtraAmount) !==
				_.sumBy(listExtraAmount)
				// ||
				// _.sumBy(this.props.listBookingCombined, (x) => x.promotionDiscount) !==
				// 	_.sumBy(listPromotion)
			) {
				this.setState({ canUpdate: true });
			}
			if (
				_.sumBy(this.props.listBookingCombined, (x) => x.totalExtraAmount) ===
				_.sumBy(listExtraAmount)
				// &&
				// _.sumBy(this.props.listBookingCombined, (x) => x.promotionDiscount) ===
				// 	_.sumBy(listPromotion)
			) {
				this.setState({ canUpdate: false });
			}

			if (this.props.checkoutAction === CheckoutActions.checkOut.successName) {
				this.setState({ isDone: true, canComplete: false });
				this.onPrint(false);
				return;
			}
			if (this.props.currentBooking?.status === BookingStatus.DONE) {
				this.setState({ isDone: true, canComplete: false });
			}
			if (
				this.props.checkoutAction === CheckoutActions.createBill.successName
			) {
				this.props.addBookingCombine(this.state.booking!);
			}

			if (
				this.props.checkoutAction ===
				CheckoutActions.updateChildBookingAndBill.successName
			) {
				this.setState({ canComplete: true });
			}
		}
	}

	componentWillUnmount() {
		this.resetAll();
		this.props.applyPromotion();
		this.props.setBillId();
	}

	render() {
		return (
			<Spin
				spinning={
					_.includes(this.props.checkoutAction, '_REQUEST') ||
					_.includes(this.props.bookingAction, '_REQUEST') ||
					this.state.loading
				}
			>
				<Layout style={styles.layoutContainer}>
					<Col>
						{this.renderHeader()}
						{this.renderContent()}
						{this.renderModals()}
						{this.renderPaymentModals()}
						{this.renderPrint()}
					</Col>
				</Layout>
			</Spin>
		);
	}
	renderModals() {
		const listBookingDetail = [
			...(this.props.currentBooking?.bookingDetails || []),
		];
		this.props.listBookingCombined.forEach((booking) => {
			listBookingDetail.push(...booking.bookingDetails);
		});

		return (
			<>
				{this.state.showModalTip === true && (
					<AddTipModal
						tip={this.props.tip!}
						stylists={_.uniqBy(
							listBookingDetail.map((bookingDetail) => bookingDetail.stylist),
							'id'
						)}
						onOk={this.onAddTip}
						onCancel={() => this.setState({ showModalTip: false })}
						allBookingDetails={listBookingDetail}
					/>
				)}
				{this.state.showModalDiscount === true && (
					<AddDiscountModal
						listBookingCombined={this.props.listBookingCombined}
						onOk={(discount, discountPercent, sharingType, discountType) => {
							this.onRemovePromotion();
							this.props.addDiscount(
								discount,
								discountPercent,
								sharingType,
								discountType
							);
							this.setState({
								showModalDiscount: false,
								canPrintBill: false,
							});
						}}
						allBookingDetails={listBookingDetail}
						onCancel={() => this.setState({ showModalDiscount: false })}
					/>
				)}
				{this.state.showModalSalesGiftCard === true && (
					<SalesGiftCardModal
						canUpdate
						currentBranchId={this.props.currentBranch?.id!}
						shopId={this.props.shop!.id!}
						onOk={(saleGiftCard) => {
							if (!this.isSaleGiftCard()) {
								this.onRemovePromotion();
							}
							this.props.addToListGiftCard(saleGiftCard);
							this.setState({
								showModalSalesGiftCard: false,
							});
						}}
						onCancel={() =>
							this.setState({
								showModalSalesGiftCard: false,
								canPrintBill: false,
							})
						}
					/>
				)}
				{this.state.showModalExtraAmount === true && (
					<AddExtraModal
						onOk={(extraAmount, extraReason) => {
							this.onAddExtra(extraAmount, extraReason);
							this.setState({
								showModalExtraAmount: false,
							});
						}}
						onCancel={() =>
							this.setState({
								showModalExtraAmount: false,
								canPrintBill: false,
							})
						}
					/>
				)}
				{this.state.showModalConfirm === true &&
					(this.props.shop?.checkEmployeeCodeWhenCheckout === null ||
						this.props.shop?.checkEmployeeCodeWhenCheckout) && (
						<ConfirmModal
							onOk={this.onConfirmOk}
							onCancel={() =>
								this.setState({ showModalConfirm: false, canPrintBill: false })
							}
						/>
					)}
				{this.state.showModalCombineBill === true && (
					<CombineBillModal
						onOk={(bookingItem) => this.onSelectBookingToCombine(bookingItem)}
						onCancel={() => this.setState({ showModalCombineBill: false })}
						currentBookingId={this.props.currentBooking?.id!}
					/>
				)}
				{this.state.showModalAppliedPromotion && (
					<PromotionListModal
						onOk={(promotion) => {
							this.setState({ showModalAppliedPromotion: false, promotion });
						}}
						onCancel={() => {
							this.setState({ showModalAppliedPromotion: false });
						}}
						currentBooking={this.props.currentBooking!}
						listBookingCombined={this.props.listBookingCombined}
					/>
				)}
				{this.state.showModalInputCoupon && (
					<InputCouponModal
						onOk={(promotion) => {
							this.setState({ showModalInputCoupon: false, promotion });
						}}
						onCancel={() => this.setState({ showModalInputCoupon: false })}
						currentBooking={this.props.currentBooking!}
						listBookingCombined={this.props.listBookingCombined}
					/>
				)}
			</>
		);
	}

	renderPaymentModals() {
		return (
			<>
				{this.state.showModalPayGiftCard === true && (
					<PayGiftCardModal
						onCancel={() => this.setState({ showModalPayGiftCard: false })}
						onOk={(giftCode, giftCardBalance, money) => {
							this.setState({
								canPrintBill: false,
								showModalPayGiftCard: false,
								selectedOtherPaymentMethodId: undefined,
							});
							this.props.addPaymentType({
								paymentType: PaymentType.GIFT_CARD,
								giftCode,
								// amount:
								// 	giftCardBalance >
								// 	this.amountNeedToPay(PaymentType.GIFT_CARD, false)
								// 		? this.amountNeedToPay(PaymentType.GIFT_CARD, false)
								// 		: giftCardBalance,
								amount: money,
							});
						}}
						amountNeedToPay={this.amountNeedToPay(
							PaymentType.CASH,
							this.state.paymentType.includes(PaymentType.GIFT_CARD)
						)}
					/>
				)}
				{this.state.showModalPayCash === true && (
					<PayCashModal
						amount={this.amountNeedToPay(
							PaymentType.CASH,
							this.state.paymentType.includes(PaymentType.CASH)
						)}
						currencies={this.props.listCurrency}
						onCancel={() => this.setState({ showModalPayCash: false })}
						onOk={(cash, currencyCode) => {
							this.setState({
								showModalPayCash: false,
								canPrintBill: false,
								cashReceived: cash,
								selectedOtherPaymentMethodId: undefined,
							});
							this.props.addPaymentType({
								paymentType: PaymentType.CASH,
								amount:
									cash >
									this.amountNeedToPay(
										PaymentType.CASH,
										this.state.paymentType.includes(PaymentType.CASH)
									)
										? this.amountNeedToPay(
												PaymentType.CASH,
												this.state.paymentType.includes(PaymentType.CASH)
										  )
										: cash,
								currencyCode,
							});
						}}
					/>
				)}
				{this.state.showModalPayCreditCard === true && (
					<PayCreditCardModal
						onOk={(referenceNumber, money, cardCharge) => {
							this.setState({
								showModalPayCreditCard: false,
								canPrintBill: false,
								selectedOtherPaymentMethodId: undefined,
							});
							this.props.addPaymentType({
								paymentType: PaymentType.CREDIT_CARD,
								transactionReferenceNumber: referenceNumber,
								cardCharge: priceFixed(cardCharge),
								amount: priceFixed(money + cardCharge),
							});
						}}
						amount={this.amountNeedToPay(
							PaymentType.CREDIT_CARD,
							this.state.paymentType.includes(PaymentType.CREDIT_CARD)
						)}
						onCancel={() => this.setState({ showModalPayCreditCard: false })}
						currentBranch={this.props.currentBranch!}
						paymentReferenceCodeRequiring={
							this.props.paymentReferenceCodeRequiring
						}
					/>
				)}
				{this.state.showModalPayByApp === true && (
					<PayByAppModal
						onOk={(referenceNumber: string, appName: string) => {
							this.setState({
								showModalPayByApp: false,
								canPrintBill: false,
								selectedOtherPaymentMethodId: undefined,
							});
							this.props.addPaymentType({
								paymentType: PaymentType.APP,
								appName,
								transactionReferenceNumber: referenceNumber,
								amount: this.amountNeedToPay(
									PaymentType.APP,
									this.state.paymentType.includes(PaymentType.APP)
								),
							});
						}}
						onCancel={() => this.setState({ showModalPayByApp: false })}
					/>
				)}
				{this.state.showOtherPaymentMethodsModal === true && (
					<OtherPaymentMethodsModal
						onCancel={() =>
							this.setState({ showOtherPaymentMethodsModal: false })
						}
						onSelectPaymentMethod={(
							paymentMethod: IPaymentMethod,
							reference: string
						) => {
							this.setState(
								{
									showOtherPaymentMethodsModal: false,
									canPrintBill: false,
									selectedOtherPaymentMethodId: paymentMethod.id,
								},
								() => {
									this.props.addPaymentType({
										paymentType: PaymentType.OTHER,
										appName: paymentMethod.name,
										transactionReferenceNumber: reference,
										paymentMethodId: paymentMethod.id,
										amount: this.amountNeedToPay(
											PaymentType.OTHER,
											this.state.paymentType.includes(PaymentType.OTHER)
										),
									});
								}
							);
						}}
						selectedPaymentMethodId={this.state.selectedOtherPaymentMethodId}
					/>
				)}
			</>
		);
	}

	renderHeader() {
		return (
			<Header
				detailPageProps={{
					title: I18n.t(_t(translations.checkout.title)),
					onClosePress: () => this.onGoBack(),
				}}
			/>
			// <Header style={styles.header}>
			// 	<Row style={styles.noPaddingCol} align="middle" justify="space-between">
			// 		<Col md={4} lg={6} />
			// 		<Col xs={16} md={4} lg={12}>
			// 			<Typography.Title style={styles.title} level={2}>
			// 				{I18n.t(_t(translations.checkout.title))}
			// 			</Typography.Title>
			// 		</Col>
			// 		<Col md={4} lg={6} style={styles.iconContainer}>
			// 			<Space>
			// 				<Clock />
			// 				<Button
			// 					danger
			// 					size={'middle'}
			// 					onClick={() => this.onGoBack()}
			// 					style={styles.icClose}
			// 				>
			// 					{I18n.t(_t(translations.close))}
			// 				</Button>
			// 			</Space>
			// 		</Col>
			// 	</Row>
			// </Header>
		);
	}
	renderPrint() {
		const customerName = `${this.props.currentBooking?.customer?.firstName} ${this.props.currentBooking?.customer?.lastName}`;

		return (
			<PrintComponent refPrinter={(ref) => (this.printer = ref)}>
				{/* <BillTemplate
					shop={this.props.shop!}
					branch={this.props.currentBranch!}
					giftCard={
						!_.isEmpty(this.props.listGiftCard) ? this.props.listGiftCard : []
					}
					bookingDetails={this.props.currentBooking?.bookingDetails}
					subTotal={this.props.price(PriceType.SUB_TOTAL)}
					tax={this.props.price(PriceType.TAX)}
					tips={this.props.price(PriceType.TIP)}
					discountPercent={this.props.discountPercent}
					giftCardTotal={this.props.price(PriceType.GIFT_CARD)}
					balance={this.props.price(PriceType.BALANCE)!}
					discount={this.props.discount}
					paymentType={this.props.paymentType!}
					appName={this.props.appName}
					customerName={customerName}
					customerPhone={this.props.currentBooking?.customer.phone}
					cardCharge={cardCharge}
					cardChargePercent={this.props.currentBranch?.cardChargePercent!}
				/> */}
			</PrintComponent>
		);
	}
	renderContent() {
		const cardCharge = this.props.listPaymentType.find(
			(x) => x.paymentType === PaymentType.CREDIT_CARD
		)?.cardCharge;
		const totalAmountPayment = _.sumBy(
			this.props.listPaymentType.map((x) => x?.amount || 0)
		);

		const amountNeedToPay = priceFixed(
			this.props.price(PriceType.BALANCE)! - totalAmountPayment
		);
		let listAllBookingDetails = Array.from(
			this.props.currentBooking?.bookingDetails || []
		);
		if (!_.isEmpty(this.props.listBookingCombined)) {
			this.props.listBookingCombined.forEach((booking) => {
				booking.bookingDetails.map((bookingDetail) =>
					listAllBookingDetails.push(bookingDetail)
				);
			});
		}

		return (!_.isEmpty(this.props.currentBooking) &&
			!_.isEmpty(this.props.employees)) ||
			this.isSaleGiftCard() ? (
			<Content style={styles.contentContainer}>
				<Row style={{ height: '100%' }}>
					<CheckoutInformation
						currentBooking={this.props.currentBooking}
						getModalByType={(modalType, bookingIndex, billIndex) =>
							this.getModalByType(modalType, bookingIndex, billIndex)
						}
						onDeleteGiftCard={(id) => {
							this.props.deleteGiftCard(id);
						}}
						isDone={this.state.isDone}
						canUpdate={this.state.canUpdate}
						onAddExtraAmount={(index, amount, note) => {
							this.props.addExtraAmount(index, amount, note);
						}}
						onDeleteBookingCombine={(bookingId) => {
							this.props.deleteBookingCombine(bookingId);
							this.onRemovePromotion();
						}}
						isSaleGiftCard={this.isSaleGiftCard()}
						cardCharge={cardCharge || 0}
						getPaymentModalByType={this.getPaymentModalByType!}
						paymentType={this.state.paymentType!}
						listAllBookingDetails={listAllBookingDetails}
						removePromotion={this.onRemovePromotion}
					/>
					<Payment
						employees={this.props.employees}
						onAddDiscount={(
							discount,
							discountPercent,
							discounterType,
							discountType
						) => {
							this.props.addDiscount(
								discount,
								discountPercent,
								discounterType,
								discountType
							);
						}}
						onChangeCashReceived={(tipAmount: number) =>
							this.setState({
								cashReceived: (this.state.cashReceived || 0) - tipAmount,
							})
						}
						listStylistAddedTip={this.state.listStylistAddedTip}
						isDone={this.state.isDone}
						currentBooking={this.props.currentBooking!}
						selectedCustomer={this.props.selectedCustomer!}
						getModalByType={this.getModalByType!}
						tip={this.props.tip!}
						discountPercent={this.props.discountPercent!}
						paymentType={this.state.paymentType!}
						canComplete={
							!_.isEmpty(this.state.paymentType) && amountNeedToPay <= 0
								? this.state.canComplete
								: false
						}
						canPrintBill={this.state.canPrintBill}
						onPrint={(isDraft) => this.onPrint(isDraft)}
						onComplete={() => {
							if (
								this.props.shop?.checkEmployeeCodeWhenCheckout === null ||
								this.props.shop?.checkEmployeeCodeWhenCheckout
							) {
								this.setState({ showModalConfirm: true });
							} else {
								this.onConfirmOk();
							}
						}}
						cardCharge={cardCharge}
						canUpdate={this.state.canUpdate}
						isSaleGiftCard={this.isSaleGiftCard()}
						moneyChangeBack={this.moneyChangeBack()}
						onRemovePayment={this.onRemovePayment}
						listPaymentType={this.props.listPaymentType}
						amountNeedToPay={amountNeedToPay < 0 ? 0 : amountNeedToPay}
						cashReceived={
							this.state.paymentType.includes(PaymentType.CASH)
								? this.state.cashReceived || 0
								: 0
						}
						onRemovePromotion={this.onRemovePromotion}
						listAllBookingDetails={listAllBookingDetails}
					/>
				</Row>
			</Content>
		) : (
			<Spin spinning={true}></Spin>
		);
	}

	onRemovePromotion = () => {
		let listAllBookingDetails = Array.from(
			this.props.currentBooking?.bookingDetails || []
		);
		if (!_.isEmpty(this.props.listBookingCombined)) {
			this.props.listBookingCombined.forEach((booking) => {
				booking.bookingDetails.map((bookingDetail) =>
					listAllBookingDetails.push(bookingDetail)
				);
			});
		}
		this.props.updatePromotionDiscount(
			listAllBookingDetails?.map((x) => {
				return { id: x?.id!, promotionDiscount: 0 };
			})!
		);
		if (!_.isEmpty(this.props.listBookingCombined)) {
			this.props.updatePromotionDiscountForBookingCombined(
				listAllBookingDetails?.map((x) => {
					return { id: x?.id!, promotionDiscount: 0 };
				})!
			);
		}
		this.props.applyPromotion();
	};
	onRemovePayment = () => {
		this.props.removeAllPaymentType();
	};
	moneyChangeBack = () => {
		const paid =
			_.sumBy(this.props.listPaymentType, (x) =>
				x.paymentType !== PaymentType.CASH ? x.amount || 0 : 0
			) + (this.state.cashReceived || 0);
		const moneyChangeBack = priceFixed(
			paid - priceFixed(this.props.price(PriceType.BALANCE)!)
		);
		return moneyChangeBack;
	};

	getPaymentModalByType = (paymentType: PaymentType) => {
		switch (paymentType) {
			case PaymentType.GIFT_CARD:
				this.setState({ showModalPayGiftCard: true });
				break;
			case PaymentType.CASH:
				this.setState({ showModalPayCash: true });
				break;
			case PaymentType.CREDIT_CARD:
				this.setState({ showModalPayCreditCard: true });

				// if (this.props.paymentReferenceCodeRequiring) {
				// 	this.setState({ showModalPayCreditCard: true });
				// } else {
				// 	const cardCharge = priceFixed(
				// 		this.props.currentBranch?.cardChargeFlatRate! +
				// 			(this.props.currentBranch?.cardChargePercent! *
				// 				this.amountNeedToPay(
				// 					PaymentType.CREDIT_CARD,
				// 					this.state.paymentType.includes(PaymentType.CREDIT_CARD)
				// 				)) /
				// 				100
				// 	);

				// 	this.setState({
				// 		showModalPayCreditCard: false,
				// 		canPrintBill: false,
				// 		selectedOtherPaymentMethodId: undefined,
				// 	});
				// 	this.props.addPaymentType({
				// 		paymentType: PaymentType.CREDIT_CARD,
				// 		transactionReferenceNumber:
				// 			GenerateHelper.generateFourDigitsNumber().toString(),
				// 		cardCharge: this.cardCharge(),
				// 		amount:
				// 			this.amountNeedToPay(
				// 				PaymentType.CREDIT_CARD,
				// 				this.state.paymentType.includes(PaymentType.CREDIT_CARD)
				// 			) +
				// 			(!this.state.paymentType.includes(PaymentType.CREDIT_CARD)
				// 				? this.cardCharge()
				// 				: 0),
				// 	});
				// }
				break;
			case PaymentType.APP:
				this.setState({ showModalPayByApp: true });
				break;
			case PaymentType.OTHER:
				this.setState({ showOtherPaymentMethodsModal: true });
				break;
			default:
				break;
		}
	};

	getModalByType = (
		modalType: number,
		bookingIndex?: number,
		billIndex?: number
	) => {
		switch (modalType) {
			case ModalType.TIP:
				this.setState({ showModalTip: true });
				break;
			case ModalType.DISCOUNT:
				this.setState({ showModalDiscount: true });
				break;
			case ModalType.SALE_GIFT_CARD:
				this.setState({ showModalSalesGiftCard: true });
				break;
			case ModalType.EXTRA_AMOUNT:
				this.setState({ showModalExtraAmount: true, bookingIndex, billIndex });
				break;
			case ModalType.COMBINE_BILl:
				this.setState({ showModalCombineBill: true });
				break;
			case ModalType.APPLIED_PROMOTION:
				this.setState({ showModalAppliedPromotion: true });
				break;
			case ModalType.INPUT_COUPON:
				this.setState({ showModalInputCoupon: true });
				break;
			default:
				break;
		}
	};

	onCheckOutSuccess() {
		this.onGoBack();
	}
	onChangeTipType = (event: any) => {
		this.setState({ tipType: event.target.value });
	};
	onGoBack = () => {
		history.replace('/today');
	};

	getModalTitle = () => {
		switch (this.state.modalType!) {
			case ModalType.TIP:
				return this.props.tip?.totalTip
					? I18n.t(_t(translations.checkout.changeTip))
					: I18n.t(_t(translations.checkout.addTip));
			case ModalType.DISCOUNT:
				return this.props.discountPercent
					? I18n.t(_t(translations.checkout.changeDiscount))
					: I18n.t(_t(translations.checkout.addDiscount));
			case ModalType.SALE_GIFT_CARD:
				return I18n.t(_t(translations.checkout.saleGiftCard));
			default:
				return '';
		}
	};

	async onPrint(isDraft = false) {
		if (
			this.props.settingReducer.receiptPrintType ===
			Const.RECEIPT_PRINT_TYPE.NONE
		) {
			return;
		}
		// this.props.currentBooking?.id!,
		// 	this.props.currentBooking?.bookingDetails!,
		// 	this.props.listBookingCombined,
		// 	this.props.listGiftCard!,
		// 	this.props.price(PriceType.SUB_TOTAL)!,
		// 	this.props.price(PriceType.TAX)!,
		// 	this.props.price(PriceType.TOTAL)!,
		// 	this.props.price(PriceType.TIP)!,
		// 	this.props.discount,
		// 	this.props.discountPercent,
		// 	this.props.price(PriceType.GIFT_CARD)!,
		// 	this.props.price(PriceType.BALANCE)!,
		// 	this.props.selectedCustomer! as ICustomer,
		// 	this.state.paymentType!,
		// 	this.state.payByGiftCard ? this.state.payByGiftCard.code : undefined,
		// 	this.state.appName,
		// 	this.props.employees?.find((x) => x.code === this.state.employeeCode)!,
		// 	1,
		// 	this.state.moneyChangeBack,
		// 	isDraft
		const paid =
			_.sumBy(this.props.listPaymentType, (x) =>
				x.paymentType !== PaymentType.CASH ? x.amount || 0 : 0
			) + (this.state.cashReceived || 0);
		const checkInCommandsJoint1 = await createESCCheckOut({
			bookingId: this.props.currentBooking?.id!,
			bookingDetails: this.props.currentBooking?.bookingDetails!,
			listBookingCombine: this.props.listBookingCombined,
			giftCards: this.props.listGiftCard!,
			subTotalService: this.props.price(PriceType.SUB_TOTAL),
			tax: this.props.price(PriceType.TAX),
			balance: this.props.price(PriceType.BALANCE)!,
			customerPaid: paid,
			tip: this.props.price(PriceType.TIP),
			discount: this.props.discount,
			discountPer: this.props.discountPercent,
			paymentTypes: this.props.listPaymentType,
			employee: this.props.employees?.find(
				(x) => x.code === this.state.employeeCode
			)!,
			customer: this.props.selectedCustomer! as ICustomer,
			joint: 1,
			change: this.moneyChangeBack(),
			isDraft,
		});
		const printCheckOut = await print(checkInCommandsJoint1, this.printer!);
		if (
			this.props.settingReducer.receiptPrintType ===
				Const.RECEIPT_PRINT_TYPE.TWO &&
			!isDraft
		) {
			const checkInCommandsJoint2 = await createESCCheckOut({
				bookingId: this.props.currentBooking?.id!,
				bookingDetails: this.props.currentBooking?.bookingDetails!,
				listBookingCombine: this.props.listBookingCombined,
				giftCards: this.props.listGiftCard!,
				customerPaid: paid,
				subTotalService: this.props.price(PriceType.SUB_TOTAL),
				tax: this.props.price(PriceType.TAX),
				balance: this.props.price(PriceType.BALANCE)!,
				tip: this.props.price(PriceType.TIP),
				discount: this.props.discount,
				discountPer: this.props.discountPercent,
				paymentTypes: this.props.listPaymentType,
				employee: this.props.employees?.find(
					(x) => x.code === this.state.employeeCode
				)!,
				customer: this.props.selectedCustomer! as ICustomer,
				joint: 2,
				change: this.moneyChangeBack(),
				isDraft,
			});
			await print(checkInCommandsJoint2, this.printer!);
		}
		if (!isDraft) {
			this.setState({
				canPrintBill: true,
				paymentType: [],
				isDone: true,
			});
			if (printCheckOut) {
				setTimeout(() => {
					// this.onCheckOutSuccess();
				}, 5000);
			}
		}
	}
	// cardCharge = () => {
	// 	const cardCharge = priceFixed(
	// 		this.props.currentBranch?.cardChargeFlatRate! +
	// 			(this.props.currentBranch?.cardChargePercent! *
	// 				(this.amountNeedToPay(
	// 					PaymentType.CREDIT_CARD,
	// 					this.state.paymentType.includes(PaymentType.CREDIT_CARD)
	// 				) -
	// 					(this.props.listPaymentType.find(
	// 						(x) => x.paymentType === PaymentType.CREDIT_CARD
	// 					)?.cardCharge || 0))) /
	// 				100
	// 	);
	// 	return cardCharge;
	// };
	amountNeedToPay = (paymentType?: PaymentType, isInclude?: boolean) => {
		const totalAmountPayment = _.sumBy(
			this.props.listPaymentType
				.filter((x) => (isInclude ? x.paymentType !== paymentType : x))
				.map((x) => x?.amount || 0)
		);
		return priceFixed(
			this.props.price(PriceType.BALANCE)! - totalAmountPayment
		);
	};

	onAddTip = async (tip: ITip, tipValue: number) => {
		this.props.addTipLocal(
			tip,
			tipValue,
			this.props.currentBranch!,
			this.amountNeedToPay() <= 0
		);
		this.setState({
			showModalTip: false,
			canPrintBill: false,
		});
		if (
			!this.state.paymentType.includes(PaymentType.CREDIT_CARD) &&
			!this.state.paymentType.includes(PaymentType.APP) &&
			this.amountNeedToPay() <= 0
		) {
			this.setState({
				cashReceived: (this.state.cashReceived || 0) + tipValue,
			});
		}

		if (this.state.isDone && !_.isEmpty(this.props.billId) && tip) {
			const tipParams = {
				...tip,
				billId: this.props.billId,
			};
			let res;
			switch (this.state.tipType) {
				case TipType.MANUALLY:
					res = await TipApiService.addBillTipsManually(tipParams);
					break;
				case TipType.AUTO:
					res = await TipApiService.addTips(tipParams);
					break;
				case TipType.SPLIT_EVEN:
					res = await TipApiService.addBillTipsBalance(tipParams);
					break;
			}
			const resData = res as IApiResponse<IEmployeeTip[]>;
			if (resData.succeeded && resData.data) {
				showSuccess('Add Tip Successfully');
				this.props.setTipIds(
					resData?.data?.map((x) => {
						return {
							employeeId: x.employeeId || '',
							id: x.id || '',
						};
					})
				);
			} else {
				showError(resData.errors);
			}
		}
	};

	onAddExtra = (extraAmount: number, extraReason: string) => {
		this.onRemovePromotion();

		if (typeof this.state.billIndex === 'number') {
			this.props.addExtraAmountBookingCombined(
				this.state.billIndex,
				this.state.bookingIndex!,
				extraAmount,
				extraReason
			);
			return;
		}

		this.props.addExtraAmount(
			this.state.bookingIndex!,
			extraAmount,
			extraReason
		);
	};
	onConfirmOk = (employeeCode?: string) => {
		this.setState(
			{
				showModalConfirm: false,
				employeeCode: employeeCode,
			},
			() => this.onComplete()
		);
	};
	onComplete = () => {
		const checkCurrentBookingDiscount =
			this.props.currentBooking?.discount &&
			this.props.currentBooking?.discount !== 0;
		const discount = checkCurrentBookingDiscount
			? this.props.currentBooking?.discount
			: this.props.discount || 0;
		const discountPercent = checkCurrentBookingDiscount
			? this.props.currentBooking?.discountPercent
			: this.props.discountPercent || 0;
		const discounterType = checkCurrentBookingDiscount
			? this.props.currentBooking?.discounterType
			: this.props.discounterType;
		const booking: IBooking = {
			...this.props.currentBooking!,
			bookingDetails: this.props.currentBooking?.bookingDetails.map((x) => {
				return {
					...x,
					tax: undefined,
					promotionDiscount: !_.isEmpty(this.props.listBookingCombined)
						? 0
						: x.promotionDiscount,
				};
			})!,
			discount: discount!,
			discountPercent: discountPercent!,
			discounterType: discounterType,
			// realAmount:
			// 	this.props.currentBooking?.totalAmount! -
			// 	(this.props.currentBooking?.totalAmount! * discount!) / 100,
			promotionIds: !_.isEmpty(this.props.listBookingCombined)
				? undefined
				: !_.isEmpty(this.props.currentBooking?.promotionIds)
				? this.props.currentBooking?.promotionIds
				: this.props.promotion?.id,
			couponCodes: !_.isEmpty(this.props.listBookingCombined)
				? undefined
				: !_.isEmpty(this.props.currentBooking?.couponCodes)
				? this.props.currentBooking?.couponCodes
				: !_.isEmpty(this.props.promotion?.coupons)
				? this.props.promotion?.coupons![0].couponCode
				: undefined,
			promotionDiscount: !_.isEmpty(this.props.listBookingCombined)
				? 0
				: this.props.currentBooking?.promotionDiscount || 0,
			// totalAmount: _.sumBy(
			// 	this.props.currentBooking?.bookingDetails,
			// 	(x) => x.amount || 0
			// ),
		};
		const bill = this.isSaleGiftCard()
			? this.createSaleGiftCardBill()
			: this.createBill(booking, true);
		const transactions = this.createTransaction();
		const tip =
			this.props.price(PriceType.TIP) !== 0
				? {
						...this.props.tip,
						date: BookingHelper.convertDateRequest(moment()),
				  }
				: undefined;
		const checkoutRequest: ICheckOutRequest = {
			tip,
			booking,
			bill,
			transactions,
			isSaleGiftCardOnly: this.isSaleGiftCard(),
		};

		this.props.checkOut(checkoutRequest);
	};

	applyPromotion = async () => {
		if (_.isEmpty(this.state.promotion)) {
			return;
		}
		this.setState({ loading: true });
		let listAllBookingDetails = Array.from(
			this.props.currentBooking?.bookingDetails || []
		);
		if (!_.isEmpty(this.props.listBookingCombined)) {
			this.props.listBookingCombined.forEach((booking) => {
				booking.bookingDetails.map((bookingDetail) =>
					listAllBookingDetails.push(bookingDetail)
				);
			});
		}
		const totalAmount =
			_.sumBy(listAllBookingDetails, (x) => x.amount || 0) +
			_.sumBy(listAllBookingDetails, (x) => x.extraAmount || 0) +
			_.sumBy(listAllBookingDetails, (x) => x.discount || 0) +
			(!this.props.currentBranch?.discountBeforeTax
				? this.props.price(PriceType.TAX)!
				: 0);
		const request: IApplyPromotionRequest = {
			...this.props.currentBooking,
			totalAmount,
			bookingDetails: undefined,
			customer: undefined,
			stylist: undefined,
			bookingHistories: undefined,
			promotionIds: !_.isEmpty(this.state.promotion?.coupons)
				? undefined
				: this.state.promotion?.id!,
			couponCodes: !_.isEmpty(this.state.promotion?.coupons)
				? this.state.promotion?.coupons![0].couponCode
				: undefined,
			applyPromotionDetails: listAllBookingDetails as any,
		};

		const res = await PromotionApiService.applyPromotion(request);
		this.setState({ loading: false });

		if (!_.isEmpty(res.errors)) {
			showAlert(
				'Error',
				!_.isEmpty(res.message) ? res.message[0].Text || '' : 'Error',
				'error'
			);
			return;
		}
		const resData = res as IApiResponse<any>;
		if (!_.isEmpty(resData.message)) {
			showAlert(
				(resData.message[0]?.code || 'Error') as string,
				resData.message[0]?.text || 'Error',
				'error'
			);
			return;
		}
		if (resData.succeeded && !_.isEmpty(resData.data)) {
			// this.props.updatePromotionDiscount(
			// 	resData.data!.applyPromotionDetails.map(
			// 		(x: IBookingDetail) => x.promotionDiscount || 0
			// 	)
			// );
			const params = resData.data!.applyPromotionDetails.map(
				(x: IBookingDetail) => {
					return {
						id: x.id,
						promotionDiscount: x.promotionDiscount || 0,
					};
				}
			);
			this.props.updatePromotionDiscount(params);
			if (!_.isEmpty(this.props.listBookingCombined)) {
				this.props.updatePromotionDiscountForBookingCombined(params);
			}

			this.props.applyPromotion(this.state.promotion);
		}
	};

	onSelectBookingToCombine = async (booking: IBooking) => {
		this.setState({
			showModalCombineBill: false,
		});
		// if (
		// 	!_.isEmpty(this.props.promotion) ||
		// 	!_.isEmpty(this.props.currentBooking?.couponCodes) ||
		// 	!_.isEmpty(this.props.currentBooking?.promotionIds)
		// ) {
		this.onRemovePromotion();
		// }

		let newBooking = Object.assign({}, booking);

		// if (!_.isEmpty(booking.couponCodes) || !_.isEmpty(booking.promotionIds)) {
		// newBooking.promotionIds = '';
		// newBooking.couponCodes = '';
		newBooking.promotionDiscount = 0;
		newBooking.bookingDetails = newBooking.bookingDetails.map((x) => {
			return { ...x, promotionDiscount: 0 };
		});
		// newBooking.bookingHistories = undefined;
		// newBooking.stylist = undefined;

		// const res = await BookingApiService.editBooking(newBooking);

		// if (!_.isEmpty(res.errors)) {
		// 	showError(res.errors);
		// }
		// const resData = res as IApiResponse<IBooking>;
		// if (!_.isEmpty(resData.data)) {
		// 	this.setState({
		// 		booking: resData.data,
		// 	});
		// 	// this.props.updatePromotionDiscountForBookingCombined(
		// 	// 	resData.data?.bookingDetails?.map((x) => {
		// 	// 		return { id: x?.id!, promotionDiscount: 0 }
		// 	// 	})!
		// 	// );
		// 	let bill = this.createBill(resData.data!);
		// 	this.props.createBill(bill);
		// }
		this.setState({
			booking: newBooking,
		});
		console.log('booking', booking);

		let bill = this.createBill(booking);
		console.log('BILL', bill);

		this.props.createBill(bill);
	};
	createSaleGiftCardBill = (): Partial<IBilling> => {
		let bill: Partial<IBilling> = {
			date: TimeHelper.toTimeZone(moment().toDate()).format('YYYY-MM-DD'),
			customerId: this.props.selectedCustomer.id,
			contactPhone: this.props.selectedCustomer.phone,
			branchId: this.props.currentBranch?.id,
			shopId: this.props.currentBranch?.shopId,
			discount: this.props.discount,
			discounterType: this.props.discounterType || 0,
			billDetails: this.createBillGiftCardDetails(),
		};
		return bill;
	};
	createBill = (
		booking: Partial<IBooking>,
		hasGiftCard?: boolean
	): Partial<IBilling> => {
		let bill: Partial<IBilling> = {
			bookingId: booking.id,
			date: booking.date,
			customerId: booking.customerId,
			contactPhone: booking?.customer?.phone,
			branchId: booking.branchId,
			shopId: booking.shopId,
			note: booking.note,
			// totalTip: this.props.price(PriceType.TIP),
			// totalAmount: _.sumBy(booking.bookingDetails, (x) =>
			// 	BookingHelper.calculateBookingDetailAmount(x)
			// ),
			// totalAmount: _.sumBy(
			// 	booking.bookingDetails,
			// 	(x) => x.amount || 0
			// ),
			discount: booking.discount,
			discounterType: booking.discounterType || 0,
			billDetails: booking?.bookingDetails!.map((bookingDetail) =>
				this.createBillBookingDetail(bookingDetail, booking.discounterType)
			),
			promotionIds:
				// !_.isEmpty(this.props.listBookingCombined)
				// 	? undefined
				// 	:
				!_.isEmpty(booking?.promotionIds)
					? booking?.promotionIds
					: this.props.promotion?.id,
			couponCodes:
				//  !_.isEmpty(this.props.listBookingCombined)
				// 	? undefined
				// 	:
				!_.isEmpty(booking?.couponCodes)
					? booking?.couponCodes
					: !_.isEmpty(this.props.promotion?.coupons)
					? this.props.promotion?.coupons![0].couponCode
					: undefined,
		};
		bill.billDetails = hasGiftCard
			? bill.billDetails?.concat(this.createBillGiftCardDetails())
			: bill.billDetails;

		return bill;
	};

	createBillBookingDetail = (
		bookingDetail: Partial<IBookingDetail>,
		discounterType?: DiscounterType
	) => {
		const billDetail: Partial<IBillingDetail> = {
			bookingDetailId: bookingDetail.id,
			serviceId: bookingDetail.itemId,
			itemId: bookingDetail.itemId,
			quantity: 1,
			// promotionDiscount: bookingDetail.promotionDiscount || 0,
			discount: bookingDetail.discount,
			discounterType:
				bookingDetail.discount && bookingDetail.discount !== 0
					? discounterType
					: undefined,
			stylistId: bookingDetail.stylistId,
			price: bookingDetail.item?.price,
			amount:
				BookingHelper.calculateBookingDetailAmount(bookingDetail) +
				(bookingDetail?.promotionDiscount || 0),
			// tax: CurrencyHelper.priceFixed(
			// 	BookingHelper.calculateBookingDetailTax(
			// 		bookingDetail,
			// 		this.props.currentBranch?.taxPercent
			// 	)
			// ),
			tax: undefined,
			add: bookingDetail.added,
			extraAmount: bookingDetail.extraAmount,
			note: bookingDetail.note,
		};
		return billDetail;
	};

	createBillGiftCardDetails = (): Partial<IBillingDetail>[] => {
		return this.props.listGiftCard.map((giftCard) => {
			const billDetail: Partial<IBillingDetail> = {
				giftCardId: giftCard.id,
				price: giftCard.amount,
				amount: giftCard.amount,
				quantity: 1,
				tax: 0,
				stylistId: _.find(
					this.props.employees,
					(employee) => employee.code === this.state.employeeCode
				)?.id,
			};
			return billDetail;
		});
	};

	createTransaction(): Partial<ITransaction> {
		let transaction: Partial<ITransaction> = {
			customerId: this.props.selectedCustomer.id!,
			bookingId: this.props.currentBooking?.id,
			branchId: this.props.currentBranch?.id,
			employeeCode: _.isEmpty(this.state.employeeCode)
				? this.props.currentUser?.code
				: this.state.employeeCode,
			date: BookingHelper.convertDateRequest(moment()),
			paymentTypes: this.props.listPaymentType as IPaymentType[],
		};
		return transaction;
	}
	resetAll = () => {
		this.props.resetCheckout();
		this.props.resetCurrentBooking()!;
	};
	isSaleGiftCard = () => {
		return this.props.location.pathname.includes('sale');
	};
}

const mapStateToProps = (state: RootState) => ({
	currentUser: state.UserReducer.employee,
	currentBooking: state.BookingReducer.currentBooking,
	employees: state.EmployeeReducer.employees,
	bookingAction: state.ReduxActionReducer['BOOKING'],
	checkoutAction: state.ReduxActionReducer['CHECKOUT'],
	tip: state.CheckoutReducer.tip,
	discountPercent: state.CheckoutReducer.discountPercent,
	discount: state.CheckoutReducer.discount,
	discounterType: state.CheckoutReducer.discounterType,
	listGiftCard: state.CheckoutReducer.listGiftCard,
	error: state.ErrorReducer.error?.message
		? state.ErrorReducer.error?.message[0]
		: { text: 'Error' },
	selectedCustomer: state.BookingReducer.selectedCustomer!,
	currentBranch: state.BranchReducer.currentBranch,
	shop: state.ShopReducer.shop,
	price: (priceType: PriceType) => price(state, priceType),
	listPaymentType: state.CheckoutReducer.listPaymentType,
	settingReducer: state.SettingReducer,
	listBookingCombined: state.CheckoutReducer.listBookingCombine,
	listCurrency: state.CurrencyReducer.currency,
	paymentReferenceCodeRequiring:
		state.BranchReducer.currentBranch?.paymentReferenceCodeRequiring,
	promotion: state.CheckoutReducer.promotion,
	billId: state.CheckoutReducer.billId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	updatePromotionDiscount: (
		params: { id: string; promotionDiscount: number }[]
	) => {
		dispatch(BookingActions.updatePromotionDiscount.request(params));
	},
	updatePromotionDiscountForBookingCombined: (
		params: { id: string; promotionDiscount: number }[]
	) => {
		dispatch(CheckoutActions.updatePromotionDiscount.request(params));
	},
	applyPromotion: (request?: Partial<IPromotion>) => {
		dispatch(CheckoutActions.applyPromotion.request(request));
	},
	getBookingById: (id: string) => {
		dispatch(BookingActions.getBookingById.request(id));
	},
	getAllEmployees: () => {
		dispatch(EmployeeActions.getAllEmployees.request());
	},
	resetCheckout: () => {
		dispatch(CheckoutActions.resetAll.request());
	},
	resetCurrentBooking: () => dispatch(BookingActions.resetBooking.request()),

	clearError: () => {
		dispatch(ErrorActions.clearError.request());
	},
	addDiscount: (
		discount: number,
		discountPercent: number,
		discounterType: DiscounterType,
		discountType: DiscountType
	) => {
		dispatch(
			CheckoutActions.addDiscount.request({
				discount,
				discountPercent,
				discounterType,
				discountType,
			})
		);
	},
	removeAllPaymentType: () => {
		dispatch(CheckoutActions.removeAllPaymentType.request());
	},
	addTipLocal: (
		tip: ITip,
		tipValue: number,
		branch: IBranch,
		isChangePayment: boolean
	) => {
		dispatch(
			CheckoutActions.addTipLocal.request({
				tip,
				tipValue,
				branch,
				isChangePayment,
			})
		);
	},
	checkOut: (checkout: ICheckOutRequest) =>
		dispatch(CheckoutActions.checkOut.request(checkout)),
	addToListGiftCard: (request: Partial<IGiftCard>) =>
		dispatch(CheckoutActions.addToListGiftCard.request(request)),
	deleteGiftCard: (id: string) => {
		dispatch(CheckoutActions.deleteGiftCard.request(id));
	},
	addPaymentType: (params: Partial<IPaymentType>) =>
		dispatch(CheckoutActions.addPaymentType.request(params)),
	editBooking: (booking: Partial<IBooking>) =>
		dispatch(BookingActions.editBooking.request(booking)),
	addExtraAmount: (index: number, amount: number, note: string) =>
		dispatch(BookingActions.addExtraAmount.request({ index, amount, note })),
	addBookingCombine: (booking: IBooking) => {
		dispatch(CheckoutActions.addBookingCombine.request(booking));
	},
	createBill: (bill: Partial<IBilling>) => {
		dispatch(CheckoutActions.createBill.request(bill));
	},
	deleteBookingCombine: (bookingId: string) => {
		dispatch(CheckoutActions.deleteBookingCombine.request(bookingId));
	},
	addExtraAmountBookingCombined: (
		billIndex: number,
		bookingItemIndex: number,
		amount: number,
		note: string
	) =>
		dispatch(
			CheckoutActions.addExtraAmount.request({
				billIndex,
				bookingItemIndex,
				amount,
				note,
			})
		),
	getAllCurrency: () => dispatch(CurrencyActions.getAllCurrency.request()),
	setBillId: () => dispatch(CheckoutActions.setBillId.request('')),
	setTipIds: (params: { employeeId: string; id: string }[]) =>
		dispatch(CheckoutActions.setTipIds.request(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
