import { AlertHelper } from 'helpers';
import I18n from 'i18n-js';
import { IApiResponse, IErrorResponse, IHolidayModel } from 'models';
import { IAddHolidayRequest } from 'models/RequestModels';
import moment from 'moment';
import { HolidayApiService } from 'services';
import { translations, _t } from 'utils';
import { EDatePickerType } from 'utils/Consts';
import momentTz from 'moment-timezone';
import configureStore from 'redux/configuration/configureStore';

export const createHoliday = async (
	request: IAddHolidayRequest,
	onCreateSuccess: (holiday: IHolidayModel) => void,
	onError?: () => void
) => {
	try {
		const result = (await HolidayApiService.addHoliday(
			request
		)) as IApiResponse<IHolidayModel>;
		if (result.succeeded) {
			AlertHelper.showSuccess(I18n.t(_t(translations.addHoliday.addSuccess)));
			onCreateSuccess(result.data!);
		} else {
			AlertHelper.showError(result as IErrorResponse);
			onError && onError();
		}
	} catch (error) {
		console.error('Network Error', error);
	}
};

export const updateHoliday = async (
	request: IHolidayModel,
	onUpdateSuccess: (holiday: IHolidayModel) => void,
	onError?: () => void
) => {
	try {
		const result = (await HolidayApiService.updateHoliday(
			request
		)) as IApiResponse<IHolidayModel>;
		if (result.succeeded) {
			AlertHelper.showSuccess(
				I18n.t(_t(translations.holiday.updateSuccess))
			);
			onUpdateSuccess(result.data!);
		} else {
			AlertHelper.showError(result as IErrorResponse);
			onError && onError();
		}
	} catch (error) {
		console.error('Network Error', error);
	}
};

export const deleteHoliday = async (
	id: string,
	onUpdateSuccess: () => void,
	onError?: () => void
) => {
	try {
		const result = (await HolidayApiService.deleteHoliday(id)) as IApiResponse;
		if (result.succeeded) {
			AlertHelper.showSuccess(
				I18n.t(_t(translations.holiday.deleteSuccess))
			);
			onUpdateSuccess();
		} else {
			AlertHelper.showError(result as IErrorResponse);
			onError && onError();
		}
	} catch (error) {
		console.error('Network Error', error);
	}
};

export const canRequest = (
	fromDate: moment.Moment,
	toDate: moment.Moment,
	type: EDatePickerType
) => {
	if (type === EDatePickerType.FROM_DATE) {
		if (fromDate.isSameOrBefore(toDate)) {
			return true;
		} else {
			AlertHelper.showError({
				message: [
					{
						Text: I18n.t(
							_t(translations.payrollDetailReport.datePickerModalMessage)
						),
					},
				],
			});
			return false;
		}
	} else {
		if (toDate.isSameOrAfter(fromDate)) {
			return true;
		} else {
			AlertHelper.showError({
				message: [
					{
						Text: I18n.t(
							_t(translations.payrollDetailReport.datePickerModalMessage)
						),
					},
				],
			});
			return false;
		}
	}
};

export const convertStartEndTime = (
	time: moment.Moment,
	date: moment.Moment
) => {
	const localTimeZone =
		configureStore().store.getState().BranchReducer.currentBranch.localTimeZone;
	return momentTz(
		`${momentTz(date).format('YYYY-MM-DD')}T${momentTz(
			time,
			localTimeZone
		).format('HH:mm')}`
	)
		.utc()
		.format('YYYY-MM-DDTHH:mm');
};
