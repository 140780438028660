import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Layout } from 'antd';
import { DetailRoute, getSubRoutes, HomeRoute } from './routes';
import { MenuSider, Header, NotificationDrawer, MenuTab } from 'components';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import PrivateRoute from 'routers/ProtectedRoute';
import { BranchActions } from 'redux/actions';
import _ from 'lodash';
import ContentAdmin from 'routers/ContentAmin';

interface IRouteComponentProps
	extends RouteComponentProps,
		ReturnType<typeof mapStateToProps> {}
class AdminRouter extends React.Component<IRouteComponentProps> {
	state = {
		collapsed: false,
		reload: false,
	};

	componentDidUpdate(prev: IRouteComponentProps) {
		if (!_.isEmpty(this.props.currentBranch)) {
			if (
				!_.isEmpty(prev.currentBranch) &&
				prev.currentBranch?.id !== this.props.currentBranch?.id
			) {
				if (
					this.props.branchAction?.includes(
						BranchActions.getBranchById.successName
					)
				) {
					this.setState({ reload: true }, () => {
						setTimeout(() => {
							this.setState({ reload: false });
						}, 200);
					});
				}
			}
		}
	}
	onCollapse = (collapsed: boolean) => {
		this.setState({ collapsed });
	};
	toggle = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};

	render() {
		if (this.checkIsLoading()) {
			return this.renderLoading();
		}
		return (
			<Layout style={{ minHeight: '100vh' }}>
				{/* {this.renderSiderMenu()} */}
				<Layout
					style={{
						maxHeight: `calc(100vh - ${this.checkIsMobile() ? '80px' : '0px'})`,
						overflow: 'auto',
					}}
				>
					<Header isAdminPage />
					{!this.state.reload && !_.isEmpty(this.props.currentBranch) ? (
						<ContentAdmin user={this.props.user!} />
					) : null}
				</Layout>
				<NotificationDrawer />
				{this.renderMobileTab()}
			</Layout>
		);
	}
	renderSiderMenu() {
		return !this.checkIsMobile() ? (
			<MenuSider
				roles={this.props.user?.userRoles}
				collapsed={this.state.collapsed}
				onCollapse={this.onCollapse}
				location={this.props.location}
			/>
		) : null;
	}
	renderMobileTab() {
		return this.checkIsMobile() ? (
			<MenuTab
				roles={this.props.user?.userRoles}
				role={this.props.employee?.employeeTypeCode}
				location={this.props.location}
			/>
		) : null;
	}
	renderLoading() {
		return (
			<div className="loading-body">
				<div className="sk-cube-grid">
					<div className="sk-cube sk-cube1" />
					<div className="sk-cube sk-cube2" />
					<div className="sk-cube sk-cube3" />
					<div className="sk-cube sk-cube4" />
					<div className="sk-cube sk-cube5" />
					<div className="sk-cube sk-cube6" />
					<div className="sk-cube sk-cube7" />
					<div className="sk-cube sk-cube8" />
					<div className="sk-cube sk-cube9" />
				</div>
			</div>
		);
	}
	checkIsMobile() {
		return true;
		// return window.innerWidth <= 1441;
	}
	checkIsLoading() {
		if (_.isEmpty(this.props.currentBranch)) {
			return true;
		}
		return false;
	}
}

const mapStateToProps = (state: RootState) => ({
	loading: state.AppConfigReducer.showLoading,
	user: state.UserReducer.user,
	employee: state.UserReducer.employee,
	currentBranch: state.BranchReducer.currentBranch,
	branchAction: state.ReduxActionReducer['BRANCH'],
});
const Admin = connect(mapStateToProps)(AdminRouter);

const AdminRoute: FC<{ isAuthenticated: boolean; userRoles?: string[] }> = ({
	isAuthenticated = false,
}) => {
	return (
		<>
			{DetailRoute.map((route) => (
				<PrivateRoute
					isAuthenticated={isAuthenticated}
					exact
					path={route.path}
					component={route.component}
				/>
			))}
			<PrivateRoute
				isAuthenticated={isAuthenticated}
				exact
				path={HomeRoute.concat(getSubRoutes()).map((route) => route.path!)}
				component={Admin}
			/>
		</>
	);
};

export default AdminRoute;
