import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';
import { Bar } from '@ant-design/charts';

import { connect } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import { visualizeTurn, getStylists } from 'redux/selectors/employee';
import { ColorHelper, CurrencyHelper } from 'helpers';
import { BookingStatus } from 'utils/Consts';
import { Radio } from 'antd';
import './styles.scss';
import _ from 'lodash';

interface ITurnChartProps
	extends ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}

class TurnChart extends React.Component<ITurnChartProps> {
	state = {
		type: 1,
	};
	render() {
		return (
			<>
				<Bar
					xAxis={{
						max: this.state.type === 1 ? 25 : undefined,
						tickInterval: this.state.type === 1 ? 10 : undefined,
						label: {
							style: {
								fontWeight: 'bold',
								fontSize: 14,
							},
						},
					}}
					height={window.innerHeight * 0.6}
					data={this.props.turnData}
					isStack={true}
					xField={this.state.type === 1 ? 'turn' : 'amount'}
					yField="label"
					seriesField="type"
					color={[
						ColorHelper.getStatusColor(BookingStatus.CONFIRMED)!,
						ColorHelper.getStatusColor(BookingStatus.PENDING)!,
					]}
					label={{
						layout: [
							{ type: 'interval-adjust-position' },
							{ type: 'interval-hide-overlap' },
							{ type: 'adjust-color' },
						],
						style: { fontWeight: 'bold' },
						formatter: (label) => {
							return `${
								this.state.type === 2
									? !label.amount || label.amount === 0
										? ''
										: CurrencyHelper.formatPrice(label.amount!)
									: label.turn
							}`;
						},
					}}
					barWidthRatio={this.props.employees?.length! * 0.05}
				/>
				<div className="flex-display flex-justify-right type-container">
					<Radio.Group
						options={[
							{ label: 'Turn', value: 1 },
							{ label: 'Money', value: 2 },
						]}
						onChange={(e) => this.setState({ type: e.target.value })}
						value={this.state.type}
						optionType="button"
						buttonStyle="solid"
					/>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state: RootState) => ({
	turnData: visualizeTurn(state),
	employees: getStylists(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TurnChart);
