import { Component, CSSProperties } from 'react';
import {
	Row,
	Col,
	Typography,
	Space,
	Spin,
	Tabs,
	Divider,
	Button,
	Card,
	List,
	Empty,
	Progress,
} from 'antd';
import styles from '../styles';
import {
	IComponentProps,
	Label,
	CustomerInfo,
	BookingItem,
	Header,
} from 'components';
import { I18n, _t, translations } from 'utils';
import { Dispatch } from 'redux';
import { CustomerActions, ErrorActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import _ from 'lodash';
import Layout, { Content } from 'antd/lib/layout/layout';
import InfiniteScroll from 'react-infinite-scroller';
import BookingApiService from 'services/BookingApiService';
import { IApiResponse, IBooking } from 'models';
import moment from 'moment';
import { history } from 'routers';
import { BookingStatus } from 'utils/Consts';
import { CurrencyHelper, StringHelper } from 'helpers';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Grid } = Card;
interface ICustomerInfoParams {
	id: string;
}
interface ICustomerInfoProps
	extends IComponentProps<ICustomerInfoParams>,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}
interface ICustomerInfoState {
	tabKey: any;
	booking: IBooking[];
}
const tabListNoTitle = [
	{
		key: 'tab1',
		tab: I18n.t(_t(translations.customerProfile.appointments)),
	},
	{
		key: 'tab2',
		tab: I18n.t(_t(translations.customerProfile.products)),
	},
	{
		key: 'tab3',
		tab: I18n.t(_t(translations.customerProfile.invoices)),
	},
];

class CustomerInfoPage extends Component<
	ICustomerInfoProps,
	ICustomerInfoState
> {
	state = {
		tabKey: 'tab1',
		booking: [],
	};

	componentDidMount() {
		this.props.getCustomersById(this.props.match.params.id);
		this.initialData();
	}

	componentWillUnmount() {}

	componentDidUpdate() {}

	render() {
		return !_.isEmpty(this.props.currentCustomers) ? (
			<Layout style={{ minHeight: '100vh', backgroundColor: 'white' }}>
				{this.renderHeader()}
				{this.renderContent()}
			</Layout>
		) : (
			<Layout style={styles.spinLayout}>
				<Spin
					tip="Loading..."
					spinning={this.props.isLoading}
					style={styles.loading}
				/>
			</Layout>
		);
	}
	renderHeader() {
		return (
			<Header
				detailPageProps={{
					title: I18n.t(_t(translations.customerProfile.profile)),
					onClosePress: () => history.goBack(),
				}}
			/>
		);
	}

	renderLoyaltyInfo() {
		const _styles = {
			textStyle: { display: 'flex', justifyContent: 'center' },
			textContainer: { flexDirection: 'column' },
		};
		return (
			<Card style={styles.boxShadow}>
				<Row justify="space-around">
					<Col lg={8} md={10} sm={12} style={_styles.textContainer as CSSProperties}>
						<Typography.Title level={4} style={_styles.textStyle}>
							1300
						</Typography.Title>
						<Typography.Title level={5} style={_styles.textStyle}>
							Total point
						</Typography.Title>
					</Col>
					<Col lg={8} md={10} sm={12} style={_styles.textContainer as CSSProperties}>
						<Typography.Title level={4} style={_styles.textStyle}>
							Bronze
						</Typography.Title>
						<Typography.Title level={5} style={_styles.textStyle}>
							Current loyalty rank
						</Typography.Title>
					</Col>
				</Row>
			</Card>
		);
	}

	renderLoyaltyProgress() {
		return (
			<Card style={styles.boxShadow}>
				<Row justify="space-between">
					<Typography.Text>1000</Typography.Text>
					<Typography.Text>5000</Typography.Text>
				</Row>
				<Progress
					percent={20}
					showInfo={false}
					strokeColor={{
						'0%': '#323dad',
						'100%': '#2d73d2',
					}}
				/>
				<Row justify="space-between">
					<Typography.Text strong>Bronze</Typography.Text>
					<Typography.Text strong>Silver</Typography.Text>
				</Row>
			</Card>
		);
	}

	renderContent() {
		const selectedCustomer = this.props.currentCustomers!;
		return (
			<Content style={{ marginTop: 64, height: '100%' }}>
				<Row style={{ height: '100%' }}>
					<Col span={16} style={styles.colContainer}>
						<Space direction="vertical" size="large" style={styles.maxWidth}>
							<Card style={styles.boxShadow}>
								<Grid style={{ width: '100%' }} hoverable={false}>
									<Space
										style={{
											justifyContent: 'center',
											width: '100%',
											textAlign: 'center',
										}}
										direction="vertical"
										size="small"
									>
										<Title style={styles.noPadding} level={4}>
											{CurrencyHelper.formatPrice(
												selectedCustomer.totalExpense
											)}
										</Title>
										<Title style={styles.noPadding} level={5}>
											{I18n.t(_t(translations.customerProfile.totalExpense))}
										</Title>
									</Space>
								</Grid>
								<Grid style={{ width: '100%' }} hoverable={false}>
									<Space
										style={{ justifyContent: 'space-around', width: '100%' }}
									>
										<Label
											title={I18n.t(
												_t(translations.customerProfile.allBooking)
											)}
											value={`${this.state.booking.length}`}
										/>
										<Label
											title={I18n.t(_t(translations.customerProfile.completed))}
											value={`${
												this.state.booking.filter(
													(booking: IBooking) =>
														booking.status === BookingStatus.DONE
												).length
											}`}
										/>
										<Label
											title={I18n.t(_t(translations.customerProfile.noShow))}
											value={selectedCustomer.notCome}
										/>
										<Label
											title={I18n.t(_t(translations.customerProfile.upComing))}
											value={`${
												this.state.booking.filter(
													(booking: IBooking) =>
														booking.status === BookingStatus.PENDING ||
														booking.status === BookingStatus.CHECKED_IN ||
														booking.status === BookingStatus.PROCESSING ||
														booking.status === BookingStatus.FINISHED
												).length
											}`}
										/>
									</Space>
								</Grid>
							</Card>
							{/* {this.renderLoyaltyInfo()}
							{this.renderLoyaltyProgress()} */}
							<Card
								style={styles.boxShadow}
								tabList={tabListNoTitle}
								tabProps={{ type: 'card' }}
								activeTabKey={this.state.tabKey}
								onTabChange={(key) => {
									this.onTabChange(key);
								}}
							>
								{/* {this.contentList[this.state.tabKey]} */}
								{this.state.tabKey === 'tab1' ? (
									this.renderListBooking(this.state.booking)
								) : (
									<Empty />
								)}
							</Card>
						</Space>
					</Col>
					<Col className="bookingCustomer" span={8} style={styles.colContainer}>
						<CustomerInfo customer={this.props.currentCustomers} />
						{this.renderMoreInfo()}
						<Divider style={styles.divider} />
						{this.renderFooter()}
					</Col>
				</Row>
			</Content>
		);
	}
	renderMoreInfo = () => {
		const selectedCustomer = this.props.currentCustomers!;
		const stylistName = selectedCustomer.lastStylist
			? `${selectedCustomer.lastStylist.firstName} ${selectedCustomer.lastStylist.lastName}`
			: undefined;
		return (
			<Tabs type="card" defaultActiveKey="1">
				<TabPane tab="Info" key="1">
					<Row gutter={[16, 16]}>
						<Col span={12}>
							{!_.isEmpty(selectedCustomer.phone) ? (
								<>
									<Title style={styles.noMargin} level={5}>
										{I18n.t(_t(translations.bookingCustomerInfo.mobile))}
									</Title>
									<Text style={styles.textSize}>
										{StringHelper.formatPhoneNumber(selectedCustomer!.phone)}
									</Text>
								</>
							) : null}
							{!_.isEmpty(selectedCustomer.email) ? (
								<>
									<Title style={styles.noMargin} level={5}>
										{I18n.t(_t(translations.email))}
									</Title>
									<Text style={styles.textSize}>{selectedCustomer!.email}</Text>
								</>
							) : null}
							{!_.isEmpty(selectedCustomer.dateOfBirth) ? (
								<>
									<Title style={styles.noMargin} level={5}>
										{I18n.t(_t(translations.bookingCustomerInfo.birthDate))}
									</Title>
									<Text style={styles.textSize}>
										{moment(selectedCustomer!.dateOfBirth).format('LL')}
									</Text>
								</>
							) : null}
						</Col>
						<Col span={12}>
							{!_.isEmpty(selectedCustomer.numberOfVisit) ? (
								<>
									<Title style={styles.noMargin} level={5}>
										{I18n.t(_t(translations.customerList.numberOfVisit))}
									</Title>
									<Text style={styles.textSize}>
										{selectedCustomer!.numberOfVisit}
									</Text>{' '}
								</>
							) : null}
							{!_.isEmpty(selectedCustomer.lastVisit) &&
							!(selectedCustomer!.lastVisit === '0001-01-01T00:00:00') ? (
								<>
									<Title style={styles.noMargin} level={5}>
										{I18n.t(_t(translations.bookingCustomerInfo.lastVisit))}
									</Title>
									<Text style={styles.textSize}>
										{moment(selectedCustomer!.lastVisit).format('LL')}
									</Text>
								</>
							) : null}
							{!_.isEmpty(selectedCustomer.lastStylist) ? (
								<>
									<Title style={styles.noMargin} level={5}>
										{I18n.t(_t(translations.bookingCustomerInfo.lastStylist))}
									</Title>
									<Text style={styles.textSize}>{stylistName}</Text>
								</>
							) : null}
						</Col>
					</Row>
				</TabPane>
			</Tabs>
		);
	};
	renderFooter() {
		return (
			<>
				<Button
					style={styles.btnNewBooking}
					onClick={() => {
						this.props.history.push(`/booking/new`, {
							customer: this.props.currentCustomers,
						});
					}}
					size="large"
					type="primary"
				>
					{I18n.t(_t(translations.customerProfile.newBooking))}
				</Button>
			</>
		);
	}
	renderListBooking = (booking: IBooking[]) => {
		return (
			<>
				<div style={styles.listBooking}>
					<InfiniteScroll
						initialLoad={false}
						pageStart={0}
						loadMore={this.handleInfiniteOnLoad}
						useWindow={false}
					>
						<List
							dataSource={booking}
							rowKey={(e) => e.id!.toString()}
							renderItem={(booking: IBooking) => {
								return (
									<BookingItem booking={booking} showStylist showDivider />
								);
							}}
						></List>
					</InfiniteScroll>
				</div>
			</>
		);
	};

	handleInfiniteOnLoad() {}

	onTabChange = (key: any) => {
		this.setState({ tabKey: key });
	};
	contentList: any = [
		{ key: 'tab1', content: this.renderListBooking(this.state.booking) },
		{ key: 'tab2', content: this.renderListBooking(this.state.booking) },
		{ key: 'tab3', content: this.renderListBooking(this.state.booking) },
	];
	async initialData() {
		const params = {
			customerId: this.props.match.params.id,
			fromDate: moment().subtract(6, 'month').toDate(),
			toDate: moment().add(6, 'month').toDate(),
			orderBy: 'date desc',
		};
		try {
			const bookingResult = (await BookingApiService.getBookingByBranch(
				params
			)) as IApiResponse<IBooking[]>;
			if (bookingResult.succeeded) {
				this.setState({ booking: bookingResult.data! });
			}
		} catch (error) {}
	}
}
const mapStateToProps = (state: RootState) => ({
	error: state.ErrorReducer.error?.errors,
	currentCustomers: state.CustomerReducer.selectedCustomer,
	isLoading: state.AppConfigReducer.showLoading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	getCustomersById: (id: string) => {
		dispatch(CustomerActions.getCustomerById.request(id));
	},
	resetCustomer: () => {
		dispatch(CustomerActions.removeCurrentCustomer.request());
		dispatch(ErrorActions.clearError.request());
	},
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfoPage);
