import { Col, Row, Typography, List, Divider, Descriptions } from 'antd';
import React from 'react';
import { Colors, I18n, translations, _t } from 'utils';
import _ from 'lodash';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import GlobalStyles, { kStyles } from 'GlobalStyles';
import { IEmployee, IService } from 'models';
import { BookingHelper, CurrencyHelper } from 'helpers';
import { price } from 'redux/selectors/checkout';
import { IEmployeeTip } from 'models/IEmployeeTip';
import { EBillType, PriceType } from 'utils/Consts';
import { IBilling } from 'models/IBilling';
import { IBillingDetail } from 'models/IBillingDetail';
import { Button } from 'components';
import { CloseOutlined, SyncOutlined } from '@ant-design/icons';
import AddRefundModal from '../Payment/AddRefundModal';
import { Dispatch } from 'redux';
import { BillingActions } from 'redux/actions';

const { Text, Title } = Typography;

interface IBillDetailProps
	extends ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {
	bill: IBilling;
	refund: IBilling | null;
}
interface IBillDetailStates {
	services: IService[];
	employees: IEmployee[];
	isShowAddRefundModal?: boolean;
	selectedBillDetail?: IBillingDetail;
}

class BillDetail extends React.Component<IBillDetailProps, IBillDetailStates> {
	totalServiceAmount: number;
	constructor(props: IBillDetailProps) {
		super(props);
		this.state = {
			services: this.getServices(),
			employees: this.getEmployees(),
		};
		this.totalServiceAmount = _.sumBy(
			this.props.bill.billDetails,
			(element) => {
				if (element.giftCardId) {
					return 0;
				}
				return (element.amount || 0) + (element.extraAmount || 0);
			}
		);
	}

	componentDidMount() {
		this.props.setRefund(this.props.refund?.billDetails || []);
	}

	render() {
		return (
			<div style={styles.container}>
				<div style={styles.billDetailContainer}>{this.renderBillDetails()}</div>
				<div style={styles.invoiceDetailContainer}>{this.renderInvoiced()}</div>
				{this.state.isShowAddRefundModal && this.state.selectedBillDetail && (
					<AddRefundModal
						billDetail={this.state.selectedBillDetail}
						onOk={this.onSaveRefund}
						onCancel={() => {
							this.setState({
								isShowAddRefundModal: false,
								selectedBillDetail: undefined,
							});
						}}
					/>
				)}
			</div>
		);
	}

	renderBillDetails = () => {
		return (
			<List<IBillingDetail>
				dataSource={this.props.bill.billDetails as IBillingDetail[]}
				renderItem={(item: IBillingDetail) => this.renderItemBillDetail(item)}
			/>
		);
	};

	renderItemBillDetail = (item: IBillingDetail) => {
		const { localRefundDetails } = this.props;
		const refundDetail = !_.isEmpty(localRefundDetails)
			? localRefundDetails.find((e) => e.billDetailId === item.id)
			: undefined;
		return (
			<Row gutter={[16, 2]} key={item.id} style={{ width: '100%' }}>
				<Col
					span={
						refundDetail
							? 24
							: this.alreadyRefunded() || this.isRefundBill()
							? 24
							: 20
					}
				>
					<Row style={styles.itemBookingDetail}>
						<Col span={1} style={styles.count}>
							<Title level={4}>{item?.quantity}</Title>
						</Col>
						<Col span={23}>{this.renderService(item)}</Col>
					</Row>
				</Col>
				{item.extraAmount && item.extraAmount !== 0 ? (
					<Col span={24}>
						<Row>
							<Col span={2}></Col>
							<Col span={22} style={styles.itemBookingDetail}>
								{this.renderExtraAmount(item)}
							</Col>
						</Row>
					</Col>
				) : null}
				{this.renderRefundDetail(item, refundDetail)}
			</Row>
		);
	};

	renderService = (billDetail: Partial<IBillingDetail>) => {
		const { services, employees } = this.state;
		const isService = !billDetail.giftCardId ? true : false;
		const service = isService
			? services.find((element) => element.id === billDetail.itemId)
			: ({ price: billDetail.price } as IService);
		const employee = employees.find(
			(element) => element.id === billDetail.stylistId
		);
		return (
			<Row justify="space-between">
				<Col flex="auto">
					<Title style={styles.titleNoMargin} level={5}>
						{isService
							? service?.name
							: I18n.t(_t(translations.checkout.giftCard))}
					</Title>
					<Text type="secondary">
						{isService
							? employee?.firstName
							: `${I18n.t(_t(translations.checkout.balance))}: ${
									Math.abs(billDetail.amount || 0)
							  }`}
					</Text>
				</Col>
				<Col style={styles.priceContainer}>
					<Title style={styles.titleNoMargin} level={5}>
						{this.getServiceItemPrice(billDetail, service)}
					</Title>
					{(!_.isEmpty(billDetail.discount) ||
						billDetail.discount !== 0 ||
						billDetail.promotionDiscount !== 0) &&
						this.props.currentBranch?.discountBeforeTax && (
							<Text type="secondary" style={styles.textLineThrough}>
								{CurrencyHelper.formatPrice(billDetail.price)}
							</Text>
						)}
				</Col>
			</Row>
		);
	};
	renderExtraAmount = (billDetail: IBillingDetail) => {
		return (
			<Row justify="space-between">
				<Col flex="auto">
					<Title style={styles.titleNoMargin} level={5}>
						{billDetail.note}
					</Title>
				</Col>
				<Col style={styles.priceContainer}>
					<Title style={styles.titleNoMargin} level={5}>
						{CurrencyHelper.formatPrice(billDetail.extraAmount)}
					</Title>
				</Col>
			</Row>
		);
	};

	renderRefundDetail = (
		billDetail: IBillingDetail,
		refundDetail?: Partial<IBillingDetail>
	) => {
		return refundDetail ? (
			<Col span={24}>
				<Row>
					<Col span={2}></Col>
					<Col
						span={this.alreadyRefunded() ? 22 : 18}
						style={styles.itemBookingDetail}
					>
						<Row justify="space-between">
							<Col flex="auto">
								<Title
									style={{ ...styles.titleNoMargin, ...styles.refundText }}
									level={5}
								>
									{I18n.t(_t(translations.billing.refund))} Service
								</Title>
							</Col>
							<Col style={styles.priceContainer}>
								<Title
									style={{ ...styles.titleNoMargin, ...styles.refundText }}
									level={5}
								>
									{'-' +
										CurrencyHelper.formatPrice(
											Math.abs(refundDetail.amount || 0)
										)}
								</Title>
							</Col>
						</Row>
						<Row justify="space-between">
							<Col flex="auto">
								<Title
									style={{ ...styles.titleNoMargin, ...styles.refundText }}
									level={5}
								>
									{I18n.t(_t(translations.billing.refund))}{' '}
									{I18n.t(_t(translations.billing.tax))}
								</Title>
							</Col>
							<Col style={styles.priceContainer}>
								<Title
									style={{ ...styles.titleNoMargin, ...styles.refundText }}
									level={5}
								>
									{'-' +
										CurrencyHelper.formatPrice(Math.abs(refundDetail.tax || 0))}
								</Title>
							</Col>
						</Row>
					</Col>
					{!this.alreadyRefunded() && (
						<Col span={4} style={{ ...styles.itemBookingDetail, padding: 0 }}>
							<Button
								type="text"
								icon={<CloseOutlined />}
								style={{ ...styles.buttonExtra, color: 'red' }}
								onClick={() => {
									this.props.removeRefund(billDetail.id);
								}}
							>
								{I18n.t(_t(translations.remove))}
							</Button>
						</Col>
					)}
				</Row>
			</Col>
		) : this.alreadyRefunded() || this.isRefundBill() ? null : (
			<Col
				xs={24}
				sm={24}
				md={3}
				lg={4}
				style={{ ...styles.itemBookingDetail, padding: 0 }}
			>
				<Button
					type="text"
					icon={<SyncOutlined />}
					style={styles.buttonExtra}
					onClick={() =>
						this.setState({
							isShowAddRefundModal: true,
							selectedBillDetail: billDetail,
						})
					}
				>
					{I18n.t(_t(translations.billing.refund))}
				</Button>
			</Col>
		);
	};

	renderInvoiced = () => {
		return (
			<Row justify="end" style={styles.invoicedContainer}>
				<Col md={16} lg={12}>
					{this.renderTotalService()}
					{this.props.currentBranch?.discountBeforeTax && this.renderDiscount()}
					{this.props.currentBranch?.discountBeforeTax &&
						this.renderPromotionDiscount()}
					{this.renderTax()}
					{this.renderTip()}
					{!this.props.currentBranch?.discountBeforeTax &&
						this.renderDiscount()}
					{!this.props.currentBranch?.discountBeforeTax &&
						this.renderPromotionDiscount()}
					{this.renderGiftCards()}
					{this.renderCardCharge()}
					{this.renderBalance()}
					{!_.isEmpty(this.props.localRefundDetails) && this.renderRefund()}
				</Col>
			</Row>
		);
	};

	renderTax() {
		return (
			<>
				{this.renderMoney(
					`${I18n.t(_t(translations.checkout.tax))} (${
						this.props.taxPercent
					}%)`,
					CurrencyHelper.formatPrice(this.props.bill.totalTax)
				)}
				<Divider style={styles.divider} />
			</>
		);
	}

	renderMoney(title: string, value: string) {
		return (
			<Descriptions
				style={styles.moneyDescription}
				labelStyle={{ fontSize: 14 }}
				contentStyle={styles.moneyContent}
				column={1}
			>
				<Descriptions.Item style={styles.moneyItem} label={title}>
					{value}
				</Descriptions.Item>
			</Descriptions>
		);
	}

	renderTotalService() {
		// const totalServiceAmount = _.sumBy(
		// 	this.props.bill.billDetails,
		// 	(element) => {
		// 		if (element.giftCardId) {
		// 			return 0;
		// 		}
		// 		return (element.amount || 0) + (element.extraAmount || 0);
		// 	}
		// );
		return (
			<>
				{this.renderMoney(
					I18n.t(_t(translations.billing.subtotalService)),
					CurrencyHelper.formatPrice(this.totalServiceAmount)
				)}
			</>
		);
	}

	renderGiftCards() {
		const giftcards = this.props.bill.billDetails.filter(
			(element) => element.giftCardId
		);
		const totalGiftCardValue = _.sumBy(
			giftcards,
			(element) => element.amount || 0
		);
		return (
			<>
				<Row justify="space-between" align="middle">
					<Title level={5}>
						{I18n.t(_t(translations.checkout.totalGiftCard))}
					</Title>
					<Title level={5} style={styles.textInline}>
						{CurrencyHelper.formatPrice(totalGiftCardValue)}
					</Title>
				</Row>
			</>
		);
	}

	// renderListGiftCard = () => {
	// 	if (_.isEmpty(this.props.listGiftCard)) {
	// 		return null;
	// 	}
	// 	return (
	// 		<List
	// 			dataSource={this.props.listGiftCard}
	// 			renderItem={this.renderItemGiftCard}
	// 		/>
	// 	);
	// };

	// renderItemGiftCard = (giftCard: Partial<IGiftCard>) => {
	// 	return (
	// 		<Row gutter={[16, 2]} key={giftCard.id} style={{ width: '100%' }}>
	// 			<Col xs={24} sm={24} md={21} lg={20}>
	// 				<Row style={styles.itemBookingDetail}>
	// 					<Col span={1} style={styles.count}>
	// 						<Title level={4}>1</Title>
	// 					</Col>
	// 					<Col span={23}>
	// 						<Row justify="space-between">
	// 							<Col flex="auto">
	// 								<Title style={styles.titleNoMargin} level={5}>
	// 									{I18n.t(_t(translations.checkout.giftCard))}
	// 								</Title>
	// 								<Text type="secondary">
	// 									{`${I18n.t(_t(translations.checkout.balance))}: ${
	// 										giftCard.balance
	// 									}`}
	// 								</Text>
	// 							</Col>
	// 							<Col style={styles.priceContainer}>
	// 								<Title style={styles.titleNoMargin} level={5}>
	// 									${giftCard.balance}
	// 								</Title>
	// 							</Col>
	// 						</Row>
	// 					</Col>
	// 				</Row>
	// 			</Col>
	// 			<Col
	// 				xs={24}
	// 				sm={24}
	// 				md={3}
	// 				lg={4}
	// 				style={{ ...styles.itemBookingDetail, padding: 0 }}
	// 			>
	// 				<Button
	// 					type="text"
	// 					disabled={this.props.isDone}
	// 					icon={<CloseOutlined />}
	// 					style={{ ...styles.buttonExtra, color: 'red' }}
	// 					onClick={() => this.props.onDeleteGiftCard(giftCard.id!)}
	// 				>
	// 					{I18n.t(_t(translations.remove))}
	// 				</Button>
	// 			</Col>
	// 		</Row>
	// 		// <Row key={giftCard.id} style={styles.itemBookingDetail}>
	// 		// 		<Col span={1} style={styles.count}>
	// 		// 			<Title level={4}>1</Title>
	// 		// 		</Col>
	// 		// 		<Col span={23}>
	// 		// 			<Row justify="space-between">
	// 		// 				<Col flex="auto">
	// 		// 					<Title style={styles.titleNoMargin} level={5}>
	// 		// 						{I18n.t(_t(translations.checkout.giftCard))}
	// 		// 					</Title>
	// 		// 					<Text type="secondary">
	// 		// 						{`${I18n.t(_t(translations.checkout.Balance))}: ${
	// 		// 							giftCard.balance
	// 		// 						}`}
	// 		// 					</Text>
	// 		// 				</Col>
	// 		// 				<Col style={styles.priceContainer}>
	// 		// 					<Title style={styles.titleNoMargin} level={5}>
	// 		// 						${giftCard.balance}
	// 		// 					</Title>
	// 		// 				</Col>
	// 		// 				<CloseOutlined
	// 		// 					onClick={() => {
	// 		// 						this.props.onDeleteGiftCard(giftCard.id!);
	// 		// 					}}
	// 		// 					style={{ ...styles.icClose, right: -10, top: -10 }}
	// 		// 				/>
	// 		// 			</Row>
	// 		// 		</Col>
	// 		// 	</Row>
	// 	);
	// };

	renderTip() {
		const { employees, bill } = this.props;
		const employeesInBill =
			employees?.filter((element) => {
				const billDetailIndex = bill.billDetails.findIndex(
					(billDetail) => billDetail.stylistId === element.id
				);
				if (billDetailIndex !== -1) {
					return true;
				}
				return false;
			}) || [];
		const sumTip = employeesInBill?.map((element) =>
			_.sumBy(bill.billDetails, (billDetail) => {
				console.log(billDetail);
				return billDetail.stylistId === element.id ? billDetail.tip! : 0;
			})
		);
		return (
			<Row>
				<Col style={styles.width100}>
					<Row justify="space-between" align="middle">
						<Title level={5}>{I18n.t(_t(translations.checkout.tip))}</Title>
						<Title level={5} style={styles.textInline}>
							{CurrencyHelper.formatPrice(this.props.bill?.totalTip)}
						</Title>
					</Row>
					{employeesInBill.map((element, index) => {
						return sumTip[index] ? (
							<Row justify="space-between" align="middle">
								<Text>
									{`${I18n.t(_t(translations.checkout.tipFor))} ${
										element.firstName || ''
									}`}
								</Text>
								<Text style={styles.textInline}>
									{CurrencyHelper.formatPrice(sumTip[index])}
								</Text>
							</Row>
						) : null;
					})}
				</Col>
			</Row>
		);
	}

	renderDiscount() {
		// const discountPercentage =
		// 	((this.props.bill.discount || 0) /
		// 		(this.totalServiceAmount + this.props.bill.totalTax)) *
		// 	100;
		const { discountBeforeTax } = this.props.currentBranch!;
		return (
			<>
				<Row justify="space-between" align="middle">
					<Col span={12}>
						{discountBeforeTax ? (
							<Text>{I18n.t(_t(translations.checkout.discount))}</Text>
						) : (
							<Title level={5}>
								{I18n.t(_t(translations.checkout.discount))}
							</Title>
						)}
					</Col>
					<Col span={6}>
						{/* <Title style={styles.textCenter} level={5}>
							{CurrencyHelper.priceFixed(discountPercentage, 2)}%
						</Title> */}
					</Col>
					<Col span={6}>
						<Title style={styles.textRight} level={5}>
							{CurrencyHelper.formatBalance(this.props.bill.discount)}
						</Title>
					</Col>
				</Row>
			</>
		);
	}

	renderPromotionDiscount() {
		const { discountBeforeTax } = this.props.currentBranch!;
		return (
			<>
				<Row justify="space-between" align="middle">
					<Col span={12}>
						{discountBeforeTax ? (
							<Text>{I18n.t(_t(translations.checkout.promotionDiscount))}</Text>
						) : (
							<Title level={5}>
								{I18n.t(_t(translations.checkout.promotionDiscount))}
							</Title>
						)}
					</Col>
					<Col span={6} />
					<Col span={6}>
						<Title style={styles.textRight} level={5}>
							{CurrencyHelper.formatBalance(
								_.sumBy(
									this.props.bill.billDetails,
									(element) => element.promotionDiscount || 0
								)
							)}
						</Title>
					</Col>
				</Row>
				<Divider style={styles.divider} />
			</>
		);
	}

	renderRefund() {
		return (
			<>
				<Row justify="space-between" align="middle">
					<Title level={4}>{I18n.t(_t(translations.billing.refund))}</Title>
					<Title level={4} style={styles.textInline}>
						{'-' +
							CurrencyHelper.formatPrice(
								Math.abs(
									_.sumBy(
										this.props.localRefundDetails,
										(e) => (e.amount || 0) + (e.tax || 0)
									)
								)
							)}
					</Title>
				</Row>
				<Divider style={styles.divider} />
			</>
		);
	}

	renderCardCharge() {
		return (
			<>
				<Row justify="space-between" align="middle">
					<Title level={5}>{I18n.t(_t(translations.billing.cardCharge))}</Title>
					<Title level={5} style={styles.textInline}>
						{CurrencyHelper.formatPrice(this.props.bill.cardCharge)}
					</Title>
				</Row>
				<Divider style={styles.divider} />
			</>
		);
	}

	renderBalance() {
		// const totalServiceAmount = _.sumBy(
		// 	this.props.bill.billDetails,
		// 	(element) => {
		// 		if (element.giftCardId) {
		// 			return 0;
		// 		}
		// 		return (
		// 			(element.amount || 0) +
		// 			(element.extraAmount || 0) -
		// 			(element.discount || 0)
		// 		);
		// 	}
		// );
		// const giftcards = this.props.bill.billDetails.filter(
		// 	(element) => element.giftCardId
		// );
		// const totalGiftCardValue = _.sumBy(
		// 	giftcards,
		// 	(element) => element.amount || 0
		// );
		// const totalTip = this.props.bill?.totalTip || 0;
		// const cardCharge = this.props.bill.cardCharge || 0;
		// const totalTax = this.props.bill.totalTax;
		return (
			<>
				<Row justify="space-between" align="middle">
					<Title level={4}>{I18n.t(_t(translations.checkout.totalPay))}</Title>
					<Title level={4} style={styles.textInline}>
						{CurrencyHelper.formatBalance(
							this.props.bill.realAmount + (this.props.bill.cardCharge || 0)
						)}
					</Title>
				</Row>
			</>
		);
	}

	// renderInput = (bookingDetail: IBookingDetail) => {
	// 	return (
	// 		<Row gutter={[16, 16]}>
	// 			<Col sm={24} md={6}>
	// 				<Title level={5}>{I18n.t(_t(translations.price))}</Title>
	// 				<Input
	// 					prefix={<Text disabled>$</Text>}
	// 					value={bookingDetail?.price}
	// 					disabled
	// 					style={styles.maxWidth}
	// 				/>
	// 			</Col>
	// 			<Col sm={24} md={14}>
	// 				<Title level={5}>{I18n.t(_t(translations.checkout.stylist))}</Title>
	// 				{/* {this.renderStylist()} */}
	// 			</Col>
	// 		</Row>
	// 	);
	// };

	renderItemTip = (employeeTip: IEmployeeTip) => {
		const employee = this.props.employees?.find(
			(e) => e.id === employeeTip.employeeId
		);
		return (
			<Row key={employeeTip.employeeId} justify="space-between" align="middle">
				<Title level={5}>
					{I18n.t(_t(translations.checkout.tipFor))}{' '}
					{`${employee?.firstName} ${employee?.lastName}`}
				</Title>
				<Title level={5} style={styles.textInline}>
					{CurrencyHelper.formatPrice(employeeTip.tipAmount)}
				</Title>
			</Row>
		);
	};

	getServices = () => {
		return (
			this.props.services.filter((element) => {
				const billDetails = this.props.bill.billDetails;
				const serviceIndex = billDetails?.findIndex(
					(billDetail) => billDetail.itemId === element.id
				);
				if (serviceIndex !== -1) {
					return true;
				}
				return false;
			}) || []
		);
	};

	getEmployees = () => {
		return (
			this.props.employees?.filter((element) => {
				const billDetails = this.props.bill.billDetails;
				const employeeIndex = billDetails?.findIndex(
					(billDetail) => billDetail.stylistId === element.id
				);
				if (employeeIndex !== -1) {
					return true;
				}
				return false;
			}) || []
		);
	};

	alreadyRefunded = () =>
		this.props.localRefundDetails &&
		this.props.localRefundDetails.some((e) => e.id);

	onSaveRefund = (amount: number) => {
		const { taxPercent } = this.props;
		const isService = this.state.selectedBillDetail?.giftCardId ? false : true;
		const originalAmount = isService
			? CurrencyHelper.priceFixed((amount / ((taxPercent || 0) + 100)) * 100)
			: amount;
		const tax = isService
			? CurrencyHelper.priceFixed(amount - originalAmount)
			: 0;
		this.props.addRefund({
			...this.state.selectedBillDetail!,
			amount: -originalAmount,
			tax: -tax,
		});
		this.setState({
			isShowAddRefundModal: false,
			selectedBillDetail: undefined,
		});
	};

	isRefundBill = () => this.props.bill.billType === EBillType.REFUND;

	getServiceItemPrice = (
		billDetail: Partial<IBillingDetail>,
		service?: IService
	) =>
		this.isRefundBill()
			? CurrencyHelper.formatPrice(
					(billDetail.amount || 0) + (billDetail.tax || 0)
			  )
			: CurrencyHelper.formatPrice(
					this.props.currentBranch?.discountBeforeTax
						? BookingHelper.calculateBookingDetailAmount({
								item: service,
								discount: billDetail.discount || 0,
								promotionDiscount: billDetail.promotionDiscount || 0,
						  }) < 0
							? 0
							: BookingHelper.calculateBookingDetailAmount({
									item: service,
									discount: billDetail.discount || 0,
									promotionDiscount: billDetail.promotionDiscount || 0,
							  })
						: billDetail.amount
			  );

	// getTax = () => {
	// 	return _.sumBy(
	// 		this.props.bill.billDetails,
	// 		(billDetail) =>
	// 			(((billDetail.price || 0) + (billDetail.extraAmount || 0)) *
	// 				(this.props.taxPercent || 0)) /
	// 			100
	// 	);
	// };
}

const mapStateToProps = (state: RootState) => ({
	taxPercent: state.BranchReducer.currentBranch?.taxPercent,
	// discountPercent: state.CheckoutReducer.discountPercent,
	// discount: state.CheckoutReducer.discount,
	// tip: state.CheckoutReducer.tip,
	price: (priceType: PriceType) => price(state, priceType),
	// listGiftCard: state.CheckoutReducer.listGiftCard,
	employees: state.EmployeeReducer.employees,
	services: state.ServiceReducer.services,
	currentBranch: state.BranchReducer.currentBranch,
	localRefundDetails: state.BillingReducer.localRefundDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	addRefund: (request: Partial<IBillingDetail>) =>
		dispatch(BillingActions.addRefundLocal.request(request)),
	removeRefund: (billDetailId: string) =>
		dispatch(BillingActions.removeRefundLocal.request(billDetailId)),
	setRefund: (request: Partial<IBillingDetail>[]) =>
		dispatch(BillingActions.setRefundLocal.request(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillDetail);

const styles: kStyles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		backgroundColor: '#F7F7F8',
	},
	billDetailContainer: {
		flex: 2,
		width: '100%',
		overflow: 'auto',
		padding: 0,
	},
	invoiceDetailContainer: {
		flex: 1,
		width: '100%',
		margin: 0,
		paddingBottom: 16,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	listContainer: {
		height: '50vh',
		overflowY: 'auto',
		marginTop: 16,
	} as React.CSSProperties,
	icClose: {
		color: 'red',
		fontSize: 20,
		cursor: 'pointer',
		right: -25,
		position: 'absolute',
	} as React.CSSProperties,
	count: {
		textAlign: 'center',
		borderWidth: 0,
		borderLeftWidth: 1.5,
		borderStyle: 'solid',
		minHeight: '100%',
	} as React.CSSProperties,
	itemBookingDetail: {
		...GlobalStyles.boxShadow,
		backgroundColor: 'white',
		padding: 8,
		borderRadius: 2,
		margin: '4px 0px',
	},
	priceContainer: { textAlign: 'end', marginRight: 16 } as React.CSSProperties,
	titleNoMargin: { marginBottom: 0 },
	textLineThrough: { textDecorationLine: 'line-through' },
	maxWidth: { width: '100%' },
	textCenter: { textAlign: 'center' } as React.CSSProperties,
	textInline: { display: 'contents' },
	divider: { width: '100%', margin: '8px 0' },
	textRight: { textAlign: 'right' } as React.CSSProperties,
	invoicedContainer: {
		padding: '16px 40px 0 0',
		// background: '#F7F7F8',
	} as React.CSSProperties,
	buttonExtra: {
		width: '100%',
		height: '100%',
		margin: 0,
		fontWeight: 600,
		padding: 0,
	},
	moneyDescription: {
		width: '100%',
		alignContent: 'baseline',
		alignItems: 'baseline',
	},
	moneyContent: {
		justifyContent: 'flex-end',
		alignContent: 'baseline',
		fontSize: 18,
		fontWeight: 'bold',
	},
	moneyItem: { paddingBottom: 1 },
	width100: { width: '100%' },
	refundText: { color: Colors.RED_BERRY },
};
