import React, { Component } from 'react';
import { Space, Table, Typography, TablePaginationConfig } from 'antd';
import { translations, _t, I18n, Const } from 'utils';
import moment from 'moment';
import {
	IAddHolidayRequest,
	IGetAllHolidayRequest,
} from 'models/RequestModels';
import { RootState } from 'redux/configuration/rootReducer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AlertHelper } from 'helpers';
import { IErrorResponse, IHolidayModel } from 'models';
import _ from 'lodash';
import { HolidayApiService } from 'services';
import { IPaginateResponse } from 'models/ResponseModels';
import { toTimeZone } from 'helpers/timeHelper';
import { convertDateRequest } from 'helpers/bookingHelper';
import { BranchActions } from 'redux/actions';
import { kStyles } from 'GlobalStyles';
import {
	createHoliday,
	deleteHoliday,
	updateHoliday,
	convertStartEndTime,
} from './helpers';
import HeaderController from './HeaderController';
import HolidayDetailModal, { IHoliday } from './HolidayDetailModal';
import { EDatePickerType } from 'utils/Consts';
import { TDateRange } from 'components/DatePicker/CalendarModal';

interface IShopHolidaysProps
	extends ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}

interface IShopHolidaysState {
	isShowAddClosedDate: boolean;
	selectedHoliday?: IHolidayModel;
	shopHolidayPaginate?: IPaginateResponse<IHolidayModel[]>;
	isLoading?: boolean;
	dateRangePickerType: EDatePickerType;
	selectedDateRage: TDateRange;
}

class ShopHoliday extends Component<IShopHolidaysProps, IShopHolidaysState> {
	constructor(props: IShopHolidaysProps) {
		super(props);
		this.state = {
			isShowAddClosedDate: false,
			isLoading: false,
			selectedDateRage: {
				fromDate: moment(),
				toDate: moment().add(3, 'month'),
			},
			dateRangePickerType: EDatePickerType.FROM_DATE,
		};
	}

	componentDidUpdate(
		prevProps: IShopHolidaysProps,
		prevState: IShopHolidaysState
	) {
		if (prevProps.currentAction !== this.props.currentAction) {
			if (
				this.props.currentAction === BranchActions.getBranchById.requestName
			) {
				this.getNiceClosedDates();
			}
		}
	}

	componentDidMount() {
		this.getShopHoliday();
	}

	render() {
		return (
			<>
				<Space
					direction="vertical"
					style={styles.closedDateContainer}
					size="small"
				>
					{this.renderControllers()}
					{this.renderHolidayTable()}
				</Space>
				{this.state.isShowAddClosedDate && (
					<HolidayDetailModal
						isShowModal
						isShop
						selectedHoliday={this.state.selectedHoliday}
						onCancel={() => {
							this.setState({
								isShowAddClosedDate: false,
								selectedHoliday: undefined,
							});
						}}
						onSubmit={this.onSubmitClosedDate}
						onDelete={this.deleteHoliday}
						isLoading={this.state.isLoading}
					/>
				)}
			</>
		);
	}

	renderControllers() {
		return (
			<HeaderController
				selectedDateRage={this.state.selectedDateRage}
				loading={this.state.isLoading}
				onAddNewClick={() => this.setState({ isShowAddClosedDate: true })}
				onSelectDateRange={(dateRage) =>
					this.setState({ selectedDateRage: dateRage }, () => {
						this.getShopHoliday();
					})
				}
			/>
		);
	}

	renderHolidayTable() {
		return (
			<Table
				dataSource={this.getNiceClosedDates()}
				scroll={{ y: '50vh', x: true }}
				onRow={(record, rowIndex) => {
					return {
						onClick: () => {
							this.setState({
								isShowAddClosedDate: true,
								selectedHoliday: record,
							});
						},
						className: 'expense-row',
					};
				}}
				loading={this.state.isLoading}
				pagination={paginationConfig(this.state.shopHolidayPaginate)}
			>
				{this.renderDatesCol()}
				{this.renderNoOfDaysCol()}
				{this.renderNoOfDaysCol()}
				{this.renderDescriptionCol()}
			</Table>
		);
	}

	renderDatesCol() {
		return (
			<Table.Column
				title={
					<Typography.Title level={5}>
						{I18n.t(_t(translations.staffDetail.closedDateDateCol))}
					</Typography.Title>
				}
				dataIndex="dateRange"
				key="dateRange"
			/>
		);
	}

	renderNoOfDaysCol() {
		return (
			<Table.Column
				title={
					<Typography.Title level={5}>
						{I18n.t(_t(translations.staffDetail.closedDateCountCol))}
					</Typography.Title>
				}
				dataIndex="dayCount"
				key="dayCount"
			/>
		);
	}

	renderLocationCol() {
		return (
			<Table.Column
				title={
					<Typography.Title level={5}>
						{I18n.t(_t(translations.staffDetail.closedDateLocationCol))}
					</Typography.Title>
				}
				dataIndex="location"
				key="location"
			/>
		);
	}

	renderDescriptionCol() {
		return (
			<Table.Column
				title={
					<Typography.Title level={5}>
						{I18n.t(_t(translations.staffDetail.closedDateDescriptionCol))}
					</Typography.Title>
				}
				dataIndex="description"
				key="description"
			/>
		);
	}

	onSubmitClosedDate = (data: IHoliday) => {
		if (!_.isEmpty(this.state.selectedHoliday)) {
			const request: Partial<IHolidayModel> = {
				...this.state.selectedHoliday,
				startTime: data.time
					? convertStartEndTime(data.time[0], data.fromDate)
					: undefined,
				endTime: data.time
					? convertStartEndTime(data.time[1], data.toDate)
					: undefined,
				daysOfMonth: [],
				daysOfWeek: [],
				weekOfMonth: undefined,
				fromDate: convertDateRequest(data.fromDate),
				toDate: convertDateRequest(data.toDate),
				description: data.description,
			};
			this.updateHoliday(request);
		} else {
			const request: IAddHolidayRequest = {
				frequencyType: Const.HolidayType.ONCE,
				fromDate: convertDateRequest(data.fromDate),
				toDate: convertDateRequest(data.toDate),
				holidayName: `${data.description}_${moment().format(
					'YYYY-MM-DDTHH:mm:SSS'
				)}`,
				startTime: data.time
					? convertStartEndTime(data.time[0], data.fromDate)
					: undefined,
				endTime: data.time
					? convertStartEndTime(data.time[1], data.toDate)
					: undefined,
				status: 1,
				branchId: this.props.currentBranch!.id,
				description: data.description || '',
			};
			this.addHoliday(request);
		}
	};

	getNiceClosedDates() {
		const branchHoliday = !_.isEmpty(this.state.shopHolidayPaginate)
			? this.state.shopHolidayPaginate!.data!.filter((element) =>
					_.isEmpty(element.employeeId)
			  )
			: [];
		return branchHoliday.map((element) => ({
			...element,
			dateRange: `${moment(element.fromDate).format(
				'ddd, DD MMM YYYY'
			)} - ${moment(element.toDate).format('ddd, DD MMM YYYY')}`,
			dayCount:
				moment
					.duration(moment(element.toDate).diff(moment(element.fromDate)))
					.asDays() + 1,
			location: this.props.branches.find(
				(branch) => branch.id === element.branchId
			)?.name,
			fromDate: moment(element.fromDate).toDate(),
			toDate: moment(element.toDate).toDate(),
			startTime: toTimeZone(element.startTime).toDate(),
			endTime: toTimeZone(element.endTime).toDate(),
			// holidayName: element.holidayName?.split('_')[0],
		}));
	}

	getShopHoliday = async () => {
		const request: IGetAllHolidayRequest = {
			pageNumber: 1,
			pageSize: 1000,
			fromDate: this.state.selectedDateRage.fromDate!.toDate(),
			toDate: this.state.selectedDateRage.toDate!.toDate(),
			employeeHoliday: false,
		};
		try {
			this.setState({ isLoading: true });
			const result = (await HolidayApiService.getAllHoliday(
				request
			)) as IPaginateResponse<IHolidayModel[]>;
			if (result.succeeded) {
				this.setState({ shopHolidayPaginate: result, isLoading: false });
			} else {
				this.setState({ isLoading: false });
				const error = result as IErrorResponse;
				AlertHelper.showError(error);
			}
		} catch (error) {
			this.setState({ isLoading: false });
			AlertHelper.showError(error as IErrorResponse);
		}
	};

	addHoliday = async (request: IAddHolidayRequest) => {
		// try {
		this.setState({
			isLoading: true,
		});
		createHoliday(
			request,
			(holiday) => {
				const newData = this.state.shopHolidayPaginate!.data!.slice();
				newData.push(holiday);
				this.setState({
					isLoading: false,
					isShowAddClosedDate: false,
					shopHolidayPaginate: {
						...this.state.shopHolidayPaginate!,
						data: newData,
					},
				});
			},
			() =>
				this.setState({
					isLoading: false,
				})
		);
	};

	updateHoliday = async (request: Partial<IHolidayModel>) => {
		this.setState({
			isLoading: true,
		});
		updateHoliday(
			request as IHolidayModel,
			(holiday) => {
				this.setState({
					isLoading: false,
					isShowAddClosedDate: false,
					selectedHoliday: undefined,
				});
				const newShopHolidayData = this.state.shopHolidayPaginate?.data?.map(
					(element) => (element.id === holiday.id ? holiday : element)
				);
				this.setState((prevState) => ({
					...prevState,
					shopHolidayPaginate: {
						...prevState.shopHolidayPaginate!,
						data: newShopHolidayData,
					},
				}));
			},
			() =>
				this.setState({
					isLoading: false,
				})
		);
	};
	deleteHoliday = async (id: string) => {
		this.setState({
			isLoading: true,
		});
		deleteHoliday(
			id,
			() => {
				this.setState({
					isLoading: false,
					isShowAddClosedDate: false,
					selectedHoliday: undefined,
					shopHolidayPaginate: {
						...this.state.shopHolidayPaginate!,
						data: this.state.shopHolidayPaginate?.data!.filter(
							(element) => element.id !== id
						),
					},
				});
			},
			() =>
				this.setState({
					isLoading: false,
				})
		);
	};
}

const paginationConfig = (
	paginate?: IPaginateResponse<any>
): TablePaginationConfig => ({
	defaultCurrent: 1,
	pageSize: 5,
	total: paginate?.totalRecords || 0,
});

const mapStateToProps = (state: RootState) => ({
	currentBranch: state.BranchReducer.currentBranch,
	branches: state.BranchReducer.branches,
	currentAction: state.ReduxActionReducer['BRANCH'],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

const styles: kStyles = {
	closedDateContainer: {
		display: 'flex',
		width: '100%',
	},
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopHoliday);
