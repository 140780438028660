import { Tabs } from 'antd';
import { ContentAdminContainer } from 'components';
import ListTextMessage from './components/ListTextMessage';
import ListTextTemplate from './components/ListTextTemplate';

function TextMessage() {
	return (
		<ContentAdminContainer customPageName="Text Message">
			<Tabs type="card">
				<Tabs.TabPane tab={'Text Message'} key="textMessage">
					<ListTextMessage />
				</Tabs.TabPane>
				<Tabs.TabPane tab={'Template'} key="template">
					<ListTextTemplate />
				</Tabs.TabPane>
			</Tabs>
		</ContentAdminContainer>
	);
}

export default TextMessage;
