import {
	Col,
	ColProps,
	Input,
	InputNumber,
	InputNumberProps,
	InputProps,
	Select,
	SelectProps,
	Typography,
	TimePicker,
	SwitchProps,
	Switch,
	DatePicker,
	Radio,
	RadioProps,
	TimePickerProps,
	DatePickerProps,
} from 'antd';
import Form, { FormItemProps, Rule } from 'antd/lib/form';
import TextArea, { TextAreaProps } from 'antd/lib/input/TextArea';
import styles from 'pages/Services/ServicesDetailPage/styles';
import { formatPrice, parserPrice } from 'helpers/currencyHelper';
import { staffColors } from 'helpers/colorHelper';
import { RangePickerTimeProps } from 'antd/lib/date-picker/generatePicker';
import { Moment } from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';
import MaskedInput from 'antd-mask-input';
import { SelectValue } from 'antd/lib/select';

class FormHelper {
	renderFormInput(
		name: string | string[],
		rules: Rule[],
		title: string,
		defaultValue?: string,
		..._rest: [ColProps?, InputProps?, FormItemProps?]
	) {
		return (
			<Col {..._rest[0]}>
				<Form.Item
					label={<Typography.Title level={5}>{title}</Typography.Title>}
					initialValue={defaultValue}
					name={name}
					rules={rules}
					{..._rest[2]}
				>
					<Input {..._rest[1]} value={defaultValue} />
				</Form.Item>
			</Col>
		);
	}
	renderFormPhoneInput(
		name: string,
		rules: Rule[],
		title: string,
		defaultValue?: string,
		..._rest: [ColProps?, InputProps?, FormItemProps?]
	) {
		return (
			<Col {..._rest[0]}>
				<Form.Item
					label={<Typography.Title level={5}>{title}</Typography.Title>}
					initialValue={defaultValue}
					name={name}
					rules={rules}
					{..._rest[2]}
				>
					<MaskedInput mask="111-111-1111" {..._rest[1]} value={defaultValue} />
				</Form.Item>
			</Col>
		);
	}
	renderFormInputNumber(
		name: string,
		rules: Rule[],
		title: string,
		defaultValue: number,
		..._rest: [ColProps?, InputProps?]
	) {
		return (
			<Col {..._rest[0]}>
				<Form.Item
					label={<Typography.Title level={5}>{title}</Typography.Title>}
					name={name}
					initialValue={defaultValue}
					rules={rules}
				>
					<Input type="number" addonBefore={'$'} style={styles.maxWidth} />
				</Form.Item>
			</Col>
		);
	}
	renderFormSelect<T>(
		name: string,
		title: string,
		options: T[],
		nameAccessor: keyof T,
		idAccessor: keyof T,
		defaultValue: string | number | undefined,
		..._rest: [ColProps?, SelectProps<any>?, FormItemProps?]
	) {
		return (
			<Col {..._rest[0]}>
				<Form.Item
					{..._rest[2]}
					label={<Typography.Title level={5}>{title}</Typography.Title>}
					initialValue={defaultValue}
					name={name}
				>
					<Select style={styles.maxWidth} onChange={() => {}} {..._rest[1]}>
						{options.map((option) => (
							<Select.Option
								key={`${option[idAccessor]}`}
								value={`${option[idAccessor]}`}
								title={`${option[nameAccessor]}`}
							>
								{`${option[nameAccessor]}`}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</Col>
		);
	}

	renderFormTimePicker(
		name: string,
		title: string,
		defaultValue: any,
		rules: Rule[],
		..._rest: [ColProps?, TimePickerProps?, FormItemProps?]
	) {
		return (
			<Col {..._rest[0]}>
				<Form.Item
					initialValue={defaultValue}
					rules={rules}
					label={<Typography.Title level={5}>{title}</Typography.Title>}
					name={name}
					{..._rest[2]}
				>
					<TimePicker
						getPopupContainer={(trigger) => trigger.parentElement!}
						style={{ width: '100%' }}
						minuteStep={15}
						format="HH:mm"
						showNow={false}
						{..._rest[1]}
					/>
				</Form.Item>
			</Col>
		);
	}
	renderFormTextArea(
		name: string,
		rules: Rule[],
		title: string,
		placeHolder: string,
		defaultValue?: string,
		..._rest: [ColProps?, TextAreaProps?]
	) {
		return (
			<Col>
				<Form.Item
					label={<Typography.Title level={5}>{title}</Typography.Title>}
					name={name}
					rules={rules}
					initialValue={defaultValue}
				>
					<TextArea placeholder={placeHolder} {..._rest[1]} />
				</Form.Item>
			</Col>
		);
	}
	renderFormSwitch(
		name: string,
		title: string,
		defaultChecked: boolean,
		..._rest: [ColProps?, SwitchProps?, FormItemProps?]
	) {
		return (
			<Col {..._rest[0]}>
				<Form.Item
					label={<Typography.Title level={5}>{title}</Typography.Title>}
					name={name}
					valuePropName="checked"
					initialValue={defaultChecked}
					{..._rest[2]}
				>
					<Switch {..._rest[1]} />
				</Form.Item>
			</Col>
		);
	}
	renderFormDateTimePicker(
		name: string,
		title: string,
		defaultValue: any,
		rules: Rule[],
		DatePickerStyle: any,
		..._rest: [ColProps?, DatePickerProps?]
	) {
		return (
			<Col {..._rest[0]}>
				<Form.Item
					initialValue={defaultValue}
					rules={rules}
					label={<Typography.Title level={5}>{title}</Typography.Title>}
					name={name}
				>
					<DatePicker
						style={DatePickerStyle}
						defaultPickerValue={defaultValue}
						format="MM/DD/YYYY"
						{..._rest[1]}
					/>
				</Form.Item>
			</Col>
		);
	}
	renderFormColour(
		name: string,
		title: string,
		defaultValue: string,
		..._rest: [ColProps?, RadioProps?]
	) {
		return (
			<Col {..._rest[0]}>
				<Form.Item
					label={<Typography.Title level={5}>{title}</Typography.Title>}
					initialValue={defaultValue}
					name={name}
				>
					<Radio.Group buttonStyle="outline" size="large" value={defaultValue}>
						{staffColors.map((color) => (
							<Radio.Button
								style={{
									// backgroundColor: color,
									width: 40,
									height: 40,
									display: 'inline-flex',
									margin: 8,
									justifyContent: 'center',
									alignItems: 'center',
									borderWidth: 1,
								}}
								value={color}
							>
								<div
									style={{
										height: 30,
										width: 30,
										backgroundColor: color,
										borderRadius: 10,
										margin: 0,
									}}
								></div>
							</Radio.Button>
						))}
					</Radio.Group>
				</Form.Item>
			</Col>
		);
	}
	renderFormCommission(
		name: string,
		rules: Rule[],
		title: string,
		defaultValue: number,
		..._rest: [ColProps?, InputNumberProps?]
	) {
		return (
			<Col {..._rest[0]}>
				<Form.Item
					label={<Typography.Title level={5}>{title}</Typography.Title>}
					name={name}
					initialValue={defaultValue}
					rules={rules}
				>
					<InputNumber
						defaultValue={defaultValue}
						min={0}
						max={100}
						// formatter={formatPercent}
						// parser={parserCommission}
						{..._rest[1]}
					/>
				</Form.Item>
			</Col>
		);
	}
	renderFormTimeRangePicker(
		name: string,
		title: string,
		defaultValue: Array<Moment | null> | undefined,
		rules: Rule[],
		..._rest: [ColProps?, RangePickerTimeProps<Moment>?]
	) {
		return (
			<Col {..._rest[0]}>
				<Form.Item
					initialValue={defaultValue}
					rules={rules}
					label={<Typography.Title level={5}>{title}</Typography.Title>}
					name={name}
				>
					<TimePicker.RangePicker
						style={{ width: '100%' }}
						minuteStep={15}
						format="HH:mm"
						showNow={false}
						{..._rest[1]}
					/>
				</Form.Item>
			</Col>
		);
	}
	renderFormDateRangePicker(
		name: string,
		title: string,
		defaultValue: Array<Moment | null>,
		rules: Rule[],
		DatePickerStyle: any,
		..._rest: [ColProps?, RangePickerProps?]
	) {
		return (
			<Col {..._rest[0]}>
				<Form.Item
					initialValue={defaultValue}
					rules={rules}
					label={<Typography.Title level={5}>{title}</Typography.Title>}
					name={name}
				>
					<DatePicker.RangePicker
						style={{ widows: '100%', ...DatePickerStyle }}
						format="MM/DD/YYYY"
						{..._rest[1]}
					/>
				</Form.Item>
			</Col>
		);
	}
}

export default new FormHelper();
