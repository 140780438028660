import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery, call } from 'redux-saga/effects';
import { ServiceActions } from '../actions';
import { IService } from 'models/IServices';
import ServiceApi from 'services/ServiceApi';
import { IApiResponse, IErrorResponse } from 'models';
import { IEditServiceRequest, IServiceRequest } from 'models/RequestModels';
import { AlertHelper } from 'helpers';
import { IFileUploadResponse } from 'models/ResponseModels';
import UploadBaseService from 'services/UploadBaseService';

function* getAllServices() {
	try {
		const result: IApiResponse<IService[]> = yield call(
			ServiceApi.getAllService
		);
		if (result.succeeded) {
			yield put(ServiceActions.getAllServices.success(result.data!));
		} else {
			const error = result as IErrorResponse;
			yield put(ServiceActions.getAllServices.failed(error));
		}
	} catch (error) {
		yield put(ServiceActions.getAllServices.failed(error));
	}
}

function* getServiceById(action: PayloadAction<string>) {
	try {
		const result: IApiResponse<IService> = yield call(
			ServiceApi.getServiceById,
			action.payload
		);
		if (result.succeeded) {
			yield put(ServiceActions.getServiceById.success(result.data!));
		} else {
			const error = result as IErrorResponse;
			AlertHelper.showError(error);
			yield put(ServiceActions.getServiceById.failed(error));
		}
	} catch (error) {
		yield put(ServiceActions.getAllServices.failed(error));
	}
}

function* addNewService(action: PayloadAction<IServiceRequest>) {
	try {
		const file = action.payload.file;
		if (file) {
			const fileResult: IApiResponse<IFileUploadResponse[]> = yield call(
				UploadBaseService.uploadImage,
				[file]
			);
			if (fileResult.succeeded) {
				action.payload.imageUrl = (
					fileResult.data as IFileUploadResponse[]
				)[0].imageUrl;
			}
		}
		const result: IApiResponse<IService> = yield call(
			ServiceApi.addNewService,
			action.payload
		);
		if (result.succeeded) {
			yield put(ServiceActions.addNewService.success(result.data!));
		} else {
			const error = result as IErrorResponse;
			AlertHelper.showError(error);
			yield put(ServiceActions.addNewService.failed(error));
		}
	} catch (error) {
		yield put(ServiceActions.addNewService.failed(error));
	}
}

function* editService(action: PayloadAction<IEditServiceRequest>) {
	const file = action.payload.file;
	console.log(file, "file");
	if (file) {
		const fileResult: IApiResponse<IFileUploadResponse[]> = yield call(
			UploadBaseService.uploadImage,
			[file]
		);
		if (fileResult.succeeded) {
			action.payload.imageUrl = (
				fileResult.data as IFileUploadResponse[]
			)[0].imageUrl;
		}
	}
	if (file) {
		delete action.payload.file;
	}
	try {
		const result: IApiResponse<IService> = yield call(
			ServiceApi.editService,
			action.payload
		);
		if (result.succeeded) {
			yield put(ServiceActions.editService.success(result.data!));
		} else {
			const error = result as IErrorResponse;
			AlertHelper.showError(error);
			yield put(ServiceActions.editService.failed(error));
		}
	} catch (error) {
		yield put(ServiceActions.editService.failed(error));
	}
}

function* deleteService(action: PayloadAction<string>) {
	try {
		const result: IApiResponse<string> = yield call(
			ServiceApi.deleteService,
			action.payload
		);
		if (result.succeeded) {
			yield put(ServiceActions.deleteService.success(result.data!));
		} else {
			const error = result as IErrorResponse;
			AlertHelper.showError(error);
			yield put(ServiceActions.deleteService.failed(error));
		}
	} catch (error) {
		yield put(ServiceActions.deleteService.failed(error));
	}
}

export function* ServiceWatcher() {
	yield takeEvery(ServiceActions.getAllServices.requestName, getAllServices);
	yield takeEvery(ServiceActions.addNewService.requestName, addNewService);
	yield takeEvery(ServiceActions.editService.requestName, editService);
	yield takeEvery(ServiceActions.deleteService.requestName, deleteService);
	yield takeEvery(ServiceActions.getServiceById.requestName, getServiceById);
}
