import { notification } from 'antd';
import { IErrorResponse } from 'models';
import { ErrorActions } from 'redux/actions';
import configureStore from 'redux/configuration/configureStore';
import { I18n, translations, _t } from 'utils';
import _ from 'lodash';
notification.config({
	duration: 3,
});

export function showAlert(
	title: string,
	description: string,
	type: 'error' | 'success' | 'info' | 'warning' | 'warn' | 'open',
	onClose?: () => void,
	duration?: number
) {
	notification[type]({
		message: title,
		description: description,
		onClose: onClose,
		duration,
	});
}

export function showError(
	error: IErrorResponse,
	duration?: number,
	onClose = () => {
		const store = configureStore().store;
		store.dispatch(ErrorActions.clearError.request());
	}
) {
	showAlert(
		I18n.t(_t(translations.text.error)),
		!_.isEmpty(error)
			? error.message[0]?.Text! || ''
			: I18n.t(_t(translations.text.criticalError)),
		'error',
		() => onclose,
		duration
	);
}

export function showSuccess(message: string) {
	showAlert(I18n.t(_t(translations.text.success)), message, 'success');
}
