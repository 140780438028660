/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Typography, Layout, Table } from "antd";
import styles from "./styles";
import { I18n, _t, translations } from "utils";
import GlobalStyles from "GlobalStyles";
import { IApiResponse, IErrorResponse, ITopServices } from "models";
import { topServiceTableConfig } from "utils/configurations";
import { ITopServicesRequest } from "models/RequestModels";
import { TopServiceRequestPeriodType } from "utils/Consts";
import { AdminDashBoardApiService } from "services";
import { AlertHelper } from "helpers";
const { Title } = Typography;
const { Header } = Layout;

interface ITopServicesProps {}

const TopServices = (props: ITopServicesProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [topServices, setTopServices] = useState<ITopServices[]>();

  useEffect(() => {
    getTopServices({
      date: new Date(),
      pageNumber: 1,
      pageSize: 5,
      periodType: TopServiceRequestPeriodType.MONTH,
    });
  }, []);

  const getTopServices = async (request: ITopServicesRequest) => {
    setIsLoading(true);
    const result = (await AdminDashBoardApiService.getTopServices(
      request
    )) as IApiResponse<ITopServices[]>;
    setIsLoading(false);
    if (result.succeeded) {
      setTopServices(result.data);
    } else {
      const error = result as IErrorResponse;
      AlertHelper.showError(error);
    }
  };

  return (
    <div
      style={isHover ? styles.listContainerHover : styles.listContainer}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Header style={styles.listHeader}>
        <Title level={4} style={GlobalStyles.textBold}>
          {I18n.t(_t(translations.dashboard.tableTopServicesTitle))}
        </Title>
      </Header>
      <div style={styles.tableContainer}>
        <Table
          columns={topServiceTableConfig}
          dataSource={topServices}
          pagination={false}
          loading={isLoading}
          rowKey={(record: ITopServices) => record.serviceId!}
        />
      </div>
    </div>
  );
};

export default TopServices;
