import React, { Component } from 'react';
import {
	Form,
	// Button,
	Image,
	Typography,
	Layout,
	Row,
	Col,
	// Input
} from 'antd';
import { ShopOutlined } from '@ant-design/icons';
import { Input, Button, AuthFooter } from 'components';
import styles from './styles';
import { I18n, _t, translations } from 'utils';
import { IApiResponse } from 'models';
import _ from 'lodash';
import ShopApiService from 'services/ShopApiService';
import { IShop } from 'models/IShop';
import { Content } from 'antd/lib/layout/layout';
import { Grid } from '@antv/g6-pc';
import { history } from 'routers';
const { Title } = Typography;

class Partner extends Component {
	state = {
		shop: undefined,
		isLoading: false,
	};

	render() {
		return (
			<Layout style={styles.layout}>
				<Layout.Header style={styles.nailHeader}>
					<Row>
						<Col lg={1} xs={1} />
						<Col lg={22} xs={22}>
							<Row justify="center" style={styles.row}>
								<Image style={styles.nailHeaderLogo} src="./logo-square.png" />
							</Row>
							<Row>
								<Typography.Text style={styles.nailHeaderSlogan} strong>
									{I18n.t(_t(translations.login.slogan))}
								</Typography.Text>
							</Row>
						</Col>
						<Col lg={1} xs={1} />
					</Row>
				</Layout.Header>
				<Content>
					<Row>
						<Col lg={9} md={6} xs={1} />
						<Col lg={6} md={12} xs={22}>
							<Form name="normal_login" style={styles.nailForm}>
								<Title level={3}>
									{I18n.t(_t(translations.partner.title))}
								</Title>
								{this.renderInput()}
								<Form.Item>
									<Row gutter={8} justify="end" style={{ width: '100%' }}>
										<Col>
											<Button
												type="dashed"
												loading={this.state.isLoading}
												onClick={() => history.push('/register')}
												style={{ height: '50px', width: '100%' }}
											>
												Register new shop
											</Button>
										</Col>
										<Col>
											<Button
												type="primary"
												loading={this.state.isLoading}
												disabled={_.isEmpty(this.state.shop)}
												onClick={() => this.onFormFinish()}
												style={{ height: '50px', width: '100%' }}
											>
												{I18n.t(_t(translations.partner.goToShop))}
											</Button>
										</Col>
									</Row>
								</Form.Item>
							</Form>
						</Col>
						<Col lg={9} md={6} xs={1} />
					</Row>
				</Content>
				<AuthFooter />
			</Layout>
		);
	}

	renderInput() {
		return (
			<>
				<Form.Item
					name="userNameOrEmail"
					rules={[
						{
							message: I18n.t(_t(translations.register.shopName)),
							required: true,
						},
					]}
				>
					<Input
						prefix={<ShopOutlined className="site-form-item-icon" />}
						placeholder={I18n.t(_t(translations.partner.placeHolder))}
						value={this.state.shop}
						onChange={(event) => this.setState({ shop: event.target.value })}
					/>
				</Form.Item>
			</>
		);
	}

	async onFormFinish() {
		const response = (await ShopApiService.getShopByAdminUrl(
			this.state.shop!
		)) as IApiResponse<IShop>;
		if (response.succeeded && !_.isEmpty(response.data)) {
			window.location.href = `http://${this.state.shop}.${process.env
				.REACT_APP_PRODUCTION_URL!}/`;
		} else {
			alert('Shop not exist');
		}
	}
}

export default Partner;
