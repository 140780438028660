import { Space, Typography, Row, Col, Input, Radio } from 'antd';
import _ from 'lodash';
import React from 'react';
import { translations, _t, I18n } from 'utils';
import { DefaultPassword, WageType } from 'utils/Consts';

interface IRadioWageProps {
	getWage(wage: number | undefined): void;
	onSelectWageType(wageType: WageType): void;
	defaultWageType?: number;
	defaultWage?: number;
}
interface IRadioWageState {
	isShowInput?: boolean;
}
const { Title, Text } = Typography;

export default class RadioWage extends React.Component<
	IRadioWageProps,
	IRadioWageState
> {
	constructor(props: IRadioWageProps) {
		super(props);
		this.state = {
			isShowInput: false,
		};
	}
	componentDidMount() {
		if (this.props.defaultWageType) {
			this.setState({ isShowInput: true });
		}
	}
	render() {
		return (
			<Space direction="vertical" size="large" style={{ width: '100%' }}>
				<Typography.Title level={5}>Salary</Typography.Title>
				<Radio.Group
					defaultValue={this.props.defaultWageType}
					onChange={(e) => (
						this.setState({ isShowInput: true }),
						this.props.onSelectWageType(e.target.value)
					)}
				>
					<Radio value={WageType.HOURLY}>
						{I18n.t(_t(translations.staffDetail.hourlyWages))}
					</Radio>
					<Radio value={WageType.WEEKLY}>
						{I18n.t(_t(translations.staffDetail.weeklyWages))}
					</Radio>
					<Radio value={WageType.SEMIMONTHLY}>
						{I18n.t(_t(translations.staffDetail.semimonthlyWages))}
					</Radio>
					<Radio value={WageType.MONTHLY}>
						{I18n.t(_t(translations.staffDetail.monthlyWages))}
					</Radio>
				</Radio.Group>
				{this.state.isShowInput ? (
					<Input
						type="number"
						style={{ width: '30%', textAlign: 'right' }}
						autoFocus={true}
						// value={this.state.wage}
						onChange={(event) =>
							this.props.getWage(_.toNumber(event.target.value))
						}
						defaultValue={this.props.defaultWage}
					/>
				) : null}
			</Space>
		);
	}
}
