import { RightOutlined } from '@ant-design/icons';
import { Col, List, Row, Typography } from 'antd';
import { getCoordsDistance } from 'helpers/mapHelpers';
import _ from 'lodash';
import { IBranch, ILocation } from 'models/IBranch';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BranchActions, CategoryActions, ServiceActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';

const getLocation = () => {
	return new Promise((res, rej) => {
		navigator.geolocation.getCurrentPosition(res, rej);
	});
};

const orderByLocation = async (branches: IBranch[]) => {
	try {
		const currentLocation = (await getLocation()) as any;
		if (!currentLocation.coords) {
			return branches;
		}
		const location: ILocation = {
			latitude: currentLocation.coords.latitude,
			longitude: currentLocation.coords.longitude,
		};
		return _.orderBy(
			_.map(branches, (branch) => {
				const distance = getCoordsDistance(location, branch.location!);
				return {
					...branch,
					distance,
				};
			}),
			(x) => x.distance
		);
	} catch (error) {
		return branches;
	}
};
const useSelectBranch = () => {
	const branchAction = useSelector(
		(state: RootState) => state.ReduxActionReducer['BRANCH']
	);
	const branches = useSelector(
		(state: RootState) => state.BranchReducer.branches
	);
	const dispatch = useDispatch();
	useEffect(() => {
		if (
			branchAction === BranchActions.getBranchesByShopId.successName &&
			!_.isEmpty(branches)
		) {
			selectBranch();
		}
	}, [branchAction, branches]);
	const selectBranch = async () => {
		if (branches.length === 1) {
			dispatch(BranchActions.selectBranch.request(branches[0]));
		}
		setTimeout(() => {
			dispatch(CategoryActions.getAllCategory.request());
			dispatch(ServiceActions.getAllServices.request());
		});
	};
};

export const SelectBranch = () => {
	const branches = useSelector(
		(state: RootState) => state.BranchReducer.branches
	);
	const history = useHistory();
	const dispatch = useDispatch();
	const [orderedBranches, setOrderedBranches] = useState(branches);
	const updateOrderedBranches = async () => {
		const list = await orderByLocation(branches);
		setOrderedBranches(list);
	};
	useEffect(() => {
		if (!_.isEmpty(branches)) {
			if (branches.length > 1) {
				updateOrderedBranches();
			} else {
				onSelectBranch(branches[0]);
			}
		}
	}, [branches]);
	const onSelectBranch = (branch: IBranch) => {
		dispatch(BranchActions.selectBranch.request(branch));
		setTimeout(() => {
			dispatch(CategoryActions.getAllCategory.request());
			dispatch(ServiceActions.getAllServices.request());
		});
		history.push({
			pathname: `/customer-booking`,
			search: `?step=${2}`,
		});
	};
	return (
		<div className="background-select-service-item">
			<div className="background-item">
				<div>
					<InfiniteScroll
						initialLoad={true}
						pageStart={0}
						loadMore={() => {}}
						useWindow={false}
					>
						<List
							dataSource={orderedBranches}
							rowKey={(e: any) => e.id!.toString()}
							renderItem={(branch: IBranch) => (
								<List.Item
									style={{ cursor: 'pointer' }}
									onClick={() => {
										onSelectBranch(branch);
									}}
									key={branch.id}
								>
									<Row gutter={16} className="list-item-service full-width">
										<Col span={16}>
											<Typography.Title
												className="select-service-item-title"
												level={5}
											>
												{branch.name}
											</Typography.Title>
											<div>
												<Typography.Text className="select-service-item-time">
													{/* {TimeHelper.convertDurationToStringDetail(
														service.duration
													)} */}
												</Typography.Text>
											</div>
											<Row>
												<Typography.Text type="secondary">{`${branch?.address?.street}, ${branch?.address?.city}, ${branch?.address?.state}`}</Typography.Text>
											</Row>
										</Col>
										<Col
											style={{ justifyContent: 'flex-end', display: 'flex' }}
											span={6}
										>
											<Typography.Text type="secondary">
												{`${(branch.distance || 0) / 1000} km`}
											</Typography.Text>
										</Col>
										<Col span={2}>
											<RightOutlined />
										</Col>
									</Row>
								</List.Item>
							)}
						></List>
					</InfiniteScroll>
				</div>
			</div>
		</div>
	);
};
function getBranchByLocation(branches: IBranch[]) {
	throw new Error('Function not implemented.');
}
