import React, { useMemo, useRef, useState } from 'react';
import { Col, Row, Space, Button, Typography } from 'antd';
import { I18n, _t, translations } from 'utils';
import _ from 'lodash';
import styles from './styles';
import { Avatar, StatusGroupButton } from 'components';
import { IBooking } from 'models';
import { BookingHelper, CurrencyHelper } from 'helpers';
import { StatusTag } from 'components';
import { toTimeZone } from 'helpers/timeHelper';
import { RootState } from 'redux/configuration/rootReducer';
import { connect, useSelector } from 'react-redux';
import { history } from 'routers';
import momentTz from 'moment-timezone';
import AppTable, { IColumnProps, ITableRef } from 'components/Table';

const Header = (props: {
	status: number;
	onClickRadioButton: (e: any) => void;
}) => {
	return (
		<Row gutter={16} style={{ alignItems: 'center' }}>
			<Col md={4}></Col>
			<Col md={20}>
				<Space
					style={{ justifyContent: 'flex-end', width: '100%' }}
					size="small"
				>
					<StatusGroupButton
						status={props.status}
						onClickRadioButton={props.onClickRadioButton}
					/>
				</Space>
			</Col>
		</Row>
	);
};

export const ListBooking = () => {
	const tableRef = useRef<ITableRef>();
	const [status, setStatus] = useState(-1);
	const timeZone = useSelector(
		(state: RootState) => state.BranchReducer.currentBranch?.localTimeZone
	);

	const columns = useMemo<IColumnProps<IBooking>[]>(() => {
		return [
			{
				header: I18n.t(_t(translations.listBooking.customerName)),
				key: 'id',
				type: 'text',
				renderCell: (data) => {
					const booking = data as IBooking;
					const customerName = `${booking.customer.firstName} ${booking.customer.lastName}`;
					return (
						<Button
							type="link"
							onClick={(e) => {
								e.stopPropagation();
								history.push(`customer/details/${booking.customerId}`);
							}}
						>
							<Space>
								<Avatar name={customerName}></Avatar>
								<Typography.Text
									style={{ color: '#037aff', cursor: 'pointer' }}
								>
									{customerName}
								</Typography.Text>
							</Space>
						</Button>
					);
				},
			},
			{
				header: I18n.t(_t(translations.listBooking.date)),
				key: 'startTimeExpected',
				renderCell: (data) => {
					return (
						<Typography.Text>
							{toTimeZone(data!.startTimeExpected).format('LL')}
						</Typography.Text>
					);
				},
			},
			{
				header: I18n.t(_t(translations.listBooking.time)),
				key: 'startTimeExpected',
				renderCell: (data) => {
					return (
						<Typography.Text>
							{toTimeZone(data!.startTimeExpected).format('ddd hh:mmA')}
						</Typography.Text>
					);
				},
			},
			{
				header: I18n.t(_t(translations.listBooking.createdDate)),
				key: 'createdDate',
				type: 'date',
			},
			{
				header: I18n.t(_t(translations.listBooking.services)),
				key: 'id',
				renderCell: (data) => {
					const booking = data as IBooking;
					return (
						<>
							{booking.bookingDetails.map((bookingDetail) => (
								<>
									<Typography.Title
										style={{ marginBottom: 0, marginTop: 6 }}
										level={5}
									>
										{bookingDetail.item?.name}
									</Typography.Title>
									{_.isEmpty(bookingDetail.stylist) ? (
										<Typography.Text>{`${bookingDetail.item?.duration} minutes`}</Typography.Text>
									) : (
										<Typography.Text>{`${
											bookingDetail.item?.duration
										} minutes with ${bookingDetail.stylist!.firstName} ${
											bookingDetail.stylist!.lastName
										}`}</Typography.Text>
									)}
								</>
							))}
						</>
					);
				},
			},
			{
				header: I18n.t(_t(translations.listBooking.technician)),
				key: 'startTimeExpected',
				renderCell: (data) => {
					const technician = _.uniqBy(
						data!.bookingDetails,
						(element) => element.stylist?.id
					).map(
						(techincian) =>
							`${techincian.stylist?.firstName || ''} ${
								techincian.stylist?.lastName || ''
							}`
					);
					return <Typography.Text>{technician.join(', ')}</Typography.Text>;
				},
				width: 200,
			},
			{
				header: `${I18n.t(_t(translations.price))}($)`,
				key: 'totalAmount',
				renderCell: (data) => {
					return (
						<Typography.Title style={{ minWidth: 50 }} level={5}>
							{CurrencyHelper.formatPrice(
								data!.totalAmount + data!.totalExtraAmount,
								false
							)}
						</Typography.Title>
					);
				},
			},
			{
				header: I18n.t(_t(translations.listBooking.status)),
				key: 'status',
				renderCell: (data) => {
					return <StatusTag status={data!.status} />;
				},
			},
		];
	}, []);
	return (
		<Space direction="vertical" style={styles.container} size="large">
			<Row>
				<Col style={{ width: '100%' }}>
					<AppTable<IBooking>
						ref={(ref) => {
							if (ref) {
								tableRef.current = ref;
							}
						}}
						columns={columns}
						queryUrl={'/Booking/get-all-bookings'}
						showSearch
						onClickRow={(data) => BookingHelper.openBooking(data)}
						renderHeader={
							<Header
								status={status}
								onClickRadioButton={(e) => setStatus(e.target.value)}
							/>
						}
						additionalParams={{
							fromDate: momentTz
								.tz(timeZone!)
								.clone()
								.subtract(1, 'month')
								.format('YYYY-MM-DD'),
							toDate: momentTz
								.tz(timeZone!)
								.clone()
								.add(1, 'month')
								.format('YYYY-MM-DD'),
							orderBy: 'date desc, startTimeExpected desc',
							bookingStatus: `${status === -1 ? null : status}`,
						}}
					/>
				</Col>
			</Row>
		</Space>
	);
};

const mapStateToProps = (state: RootState) => ({
	currentAction: state.ReduxActionReducer['BRANCH'],
});

export default connect(mapStateToProps)(ListBooking);
