import { Button, Modal } from 'antd';
import { I18n, _t, translations } from 'utils';

interface PreviewModalProps {
	isModalVisible: boolean;
	handleCancel(): void;
	content?: string;
}

const PreviewContentModal = (props: PreviewModalProps) => {
	return (
		<Modal
			title="Preview"
			visible={props.isModalVisible}
			onCancel={props.handleCancel}
			footer={[
				<Button key="back" onClick={props.handleCancel}>
					{I18n.t(_t(translations.cancel))}
				</Button>,
			]}
		>
			{props.content}
		</Modal>
	);
};

export default PreviewContentModal;
