import React from 'react';
import { Modal, Button, ModalProps, Typography } from 'antd';
import { I18n, _t, translations } from 'utils';
import _ from 'lodash';

interface IProps extends ModalProps {
	visible: boolean;
	handleOk(): void;
	handleCancel(): void;
	danger?: boolean;
	OKString?: string;
	isLoading?: boolean;
}

interface IStates {}

export default class AppModal extends React.Component<IProps, IStates> {
	render() {
		const { visible, handleOk, handleCancel } = this.props;

		return (
			<Modal
				{...this.props}
				visible={visible}
				width={400}
				onCancel={handleCancel}
				footer={[
					<Button key="back" onClick={handleCancel}>
						{I18n.t(_t(translations.cancel))}
					</Button>,
					<Button
						type="primary"
						danger={this.props.danger === undefined ? true : false}
						key="submit"
						onClick={handleOk}
						loading={this.props.isLoading}
					>
						{!_.isEmpty(this.props.OKString)
							? this.props.OKString
							: I18n.t(_t(translations.delete))}
					</Button>,
				]}
			>
				{this.props.children}
			</Modal>
		);
	}
}
