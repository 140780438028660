import React, { Component } from 'react';
import { Col, Layout, Row, Tabs } from 'antd';
import { I18n, translations, _t } from 'utils';
import { history } from 'routers';
import styles from './styles';
import { Content } from 'antd/lib/layout/layout';
import { IBilling } from 'models/IBilling';
import { BillDetail, Payment } from './component';
import { Header, IComponentProps, Spin } from 'components';
import qs from 'qs';
import BillingApiService from 'services/BillingApiService';
import { IApiResponse, IBillingDetail, IBooking, IErrorResponse } from 'models';
import { AlertHelper } from 'helpers';
// import { createESCCheckOut, print } from 'helpers/printHelper';
import BookingApiService from 'services/BookingApiService';
import _ from 'lodash';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { BillingActions } from 'redux/actions';
import { EBillType } from 'utils/Consts';

interface IBillingProps
	extends IComponentProps<any>,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}
interface IBillingState {
	isLoading?: boolean;
	bills?: IBilling[];
	refunds?: (IBilling | null)[];
	booking?: IBooking;
}
interface IBillingQuery {
	bookingId?: string;
}

class Billing extends Component<IBillingProps, IBillingState> {
	constructor(props: IBillingProps) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		const query = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		}) as IBillingQuery;
		this.getBookingAndBill(query.bookingId);
	}

	render() {
		return (
			<Layout style={styles.container}>
				{this.renderHeader()}
				{this.state.isLoading ? (
					<Spin />
				) : (
					<Layout style={styles.contentContainer}>
						{this.renderContent()}
					</Layout>
				)}
			</Layout>
		);
	}

	renderHeader() {
		return (
			<Header
				detailPageProps={{
					title: I18n.t(_t(translations.billing.title)),
					onClosePress: () => this.onGoBack(),
				}}
			/>
		);
	}

	renderContent() {
		return (
			<Tabs
				defaultActiveKey={'0'}
				type="card"
				style={styles.fullHeight}
				onChange={(activeKey) => {
					return (
						this.state.refunds &&
						this.props.setCurrentRefund(
							this.state.refunds[Number.parseInt(activeKey)]
								? (this.state.refunds[Number.parseInt(activeKey)]
										?.billDetails as IBillingDetail[])
								: []
						)
					);
				}}
			>
				{this.state.bills?.map((element, index) => {
					return (
						<Tabs.TabPane
							tab={`${I18n.t(_t(translations.billing.bill))} ${index + 1} ${
								element.billType === EBillType.REFUND
									? '(' + I18n.t(_t(translations.billing.refund)) + ')'
									: ''
							}`}
							key={index.toString()}
						>
							{this.renderBill(element, this.state.refunds![index])}
						</Tabs.TabPane>
					);
				})}
			</Tabs>
		);
	}

	renderBill(bill: IBilling, refund: IBilling | null) {
		return (
			<Content style={styles.fullHeight}>
				<Row style={styles.rowContainer}>
					<Col style={styles.colContainer} sm={24} md={16}>
						<BillDetail bill={bill} refund={refund} />
					</Col>
					<Col
						style={{ ...styles.colContainer, backgroundColor: 'white' }}
						sm={24}
						md={8}
					>
						<Payment
							bill={bill}
							onCreateRefundSuccess={() =>
								this.getBookingAndBill(this.state.booking?.id)
							}
							// refund={refund}
							onPrint={() => this.onPrint(bill)}
						/>
					</Col>
				</Row>
			</Content>
		);
	}
	onPrint = async (bill: IBilling) => {
		// try {
		// 	const listGiftCard = bill.billDetails
		// 		.filter((x) => x.giftCardId)
		// 		.map((x) => x.giftCard!);
		// 	const checkInCommandsJoint1 = await createESCCheckOut(
		// 		this.state.booking?.id!,
		// 		this.state.booking?.bookingDetails!,
		// 		[],
		// 		listGiftCard!,
		// 		bill.totalAmount,
		// 		bill.totalTax,
		// 		0,
		// 		bill.totalTip,
		// 		bill.discount,
		// 		_.toNumber(
		// 			(
		// 				(bill.discount || 0 / (bill.totalAmount + bill.totalTax)) * 100
		// 			).toFixed(2)
		// 		),
		// 		_.sumBy(listGiftCard, (x) => x.balance),
		// 		bill.realAmount,
		// 		this.state.booking?.customer!,
		// 		bill.transactions![0].paymentType,
		// 		bill.transactions![0].giftCode,
		// 		bill.transactions![0].transactionReferenceNumber,
		// 		this.props.employees?.find(
		// 			(x) => x.code === bill.transactions![0].employeeCode
		// 		)!,
		// 		1,
		// 		0,
		// 		false
		// 	);
		// 	const printCheckOut = await print(checkInCommandsJoint1);
		// } catch (error) {}
	};

	onGoBack = () => {
		history.replace('/today');
	};

	getBookingAndBill = async (bookingId = '') => {
		this.setState({ isLoading: true });
		try {
			// const bills = (await BillingApiService.getBillByBookingId(
			// 	bookingId || ''
			// )) as IApiResponse<IBilling[]>;
			const [bills, booking] = await Promise.all([
				BillingApiService.getBillByBookingId(bookingId) as Promise<
					IApiResponse<IBilling[]>
				>,
				BookingApiService.getBookingById(bookingId) as Promise<
					IApiResponse<IBooking>
				>,
			]);
			if (bills.succeeded && booking.succeeded && !_.isEmpty(bills.data)) {
				// const refunds = (await Promise.all(
				// 	bills.data!.map((element) =>
				// 		element.refundId
				// 			? RefundApiService.getRefundById(element.refundId)
				// 			: Promise.resolve(null)
				// 	)
				// )) as (IApiResponse<IRefund> | null)[];
				const refunds = (await Promise.all(
					bills.data!.map((element) =>
						element.refundBillId
							? BillingApiService.getBillById(element.refundBillId)
							: Promise.resolve(null)
					)
				)) as (IApiResponse<IBilling> | null)[];
				this.setState({
					bills: bills.data!,
					refunds: refunds.map((element) => element?.data || null),
					isLoading: false,
					booking: booking.data,
				});
			} else {
				const error = bills as IErrorResponse;
				this.setState({ isLoading: false });
				AlertHelper.showError(error);
			}
		} catch (error) {}
	};
}

const mapStateToProps = (state: RootState) => ({
	employees: state.EmployeeReducer.employees,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setCurrentRefund: (refund: IBillingDetail[]) =>
		dispatch(BillingActions.setRefundLocal.request(refund)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
