import GlobalStyles, { kStyles } from 'GlobalStyles';

const styles: kStyles = {
	container: { height: '100vh' },
	header: {
		position: 'fixed',
		zIndex: 1,
		width: '100%',
		alignItems: 'center',
		padding: 0,
		...GlobalStyles.boxShadow,
	},
	rowContainer: { height: '100%'},
	noPaddingCol: {
		paddingInline: 0,
		width: '100%',
	},
	title: {
		color: 'white',
		textAlign: 'center',
		marginBottom: 0,
		justifyContent: 'center',
	},
	iconContainer: { textAlign: 'end', paddingRight: 35 },
	icClose: {
		cursor: 'pointer',
		right: 32,
	},
	spinStyle: {
		backgroundColor: 'red',
		height: '100%',
		marginTop: 64,
	},
	colContainer: {
		paddingTop: 20,
		paddingInline: 16,
		borderWidth: 0,
		borderRightWidth: 0.1,
		borderStyle: 'solid',
		borderColor: '#cdcdcd',
	},
	contentContainer: {
		padding: '80px 16px 0px 16px',
		backgroundColor: '#F7F7F8'
	},
	fullHeight: { height: '100%' },
};

export default styles;
