import { Space, Typography, Row, Col, Input, Radio } from 'antd';
import _ from 'lodash';
import React from 'react';
import { translations, _t, I18n } from 'utils';

interface ICommissionRateProps {
	title?: string;
	onSelect(commissionPercent: number | undefined): void;
	defaultValue?: number;
	isAdd: boolean;
	canChoose100?: boolean;
}
interface ICommissionRateState {
	isShowInput?: boolean;
	commissionPercent?: number
}
const { Title, Text } = Typography;

export default class CommissionRate extends React.Component<
	ICommissionRateProps,
	ICommissionRateState
> {
	constructor(props: ICommissionRateProps) {
		super(props);
		this.state = {
			isShowInput: false,
			commissionPercent: this.props.defaultValue
		};
	}
	componentDidMount() {
		if (this.isOtherRate(this.props.defaultValue!) && !this.props.isAdd) {
			this.setState({ isShowInput: true });
		}
	}
	COMMISSION_RATE = [
		{
			key: 'zero',
			title: '100-0',
			value: 100,
			callBack: () => this.chooseCommissionPercent('zero'),
		},
		{
			key: 'seventy',
			title: '70-30',
			value: 70,
			callBack: () => this.chooseCommissionPercent('seventy'),
		},
		{
			key: 'sixty',
			title: '60-40',
			value: 60,
			callBack: () => this.chooseCommissionPercent('sixty'),
		},
		{
			key: 'fifty',
			title: '50-50',
			value: 50,
			callBack: () => this.chooseCommissionPercent('fifty'),
		},
		{
			key: 'forty',
			title: '40-60',
			value: 40,
			callBack: () => this.chooseCommissionPercent('forty'),
		},
		{
			key: 'thirty',
			title: '30-70',
			value: 30,
			callBack: () => this.chooseCommissionPercent('thirty'),
		},
		{
			key: 'hundred',
			title: '0-100',
			value: 0,
			callBack: () => this.chooseCommissionPercent('hundred'),
		},
		{
			key: 'other',
			title: 'Other',
			value: -1,
			callBack: () => this.chooseCommissionPercent('other'),
		},
	];

	render() {
		const ARRAY = this.props.canChoose100
			? this.COMMISSION_RATE
			: this.COMMISSION_RATE.slice(1);
		return (
			<Space size="middle" direction="vertical" style={styles.maxWidth}>
				<Typography.Title level={5}>{this.props.title}</Typography.Title>
				<Radio.Group
					buttonStyle="solid"
					style={{ width: '100%' }}
					defaultValue={
						this.props.isAdd
							? this.props.defaultValue
							: this.isOtherRate(this.props.defaultValue!)
							? -1
							: this.props.defaultValue
					}
				>
					<Row gutter={[16, 16]}>
						{ARRAY.map((rate) => (
							<Col>
								<Radio.Button
									key={rate.key}
									value={rate.value}
									style={{ minWidth: 60, textAlign: 'center' }}
									onClick={rate.callBack}
								>
									{rate.title}
								</Radio.Button>
							</Col>
						))}
					</Row>
				</Radio.Group>
				{this.state.isShowInput ? (
					<>
						<Typography.Title level={5}>
							{I18n.t(_t(translations.staffDetail.inputCommissionRate))}
						</Typography.Title>
						<Col xs={8} sm={8} md={4} lg={4} xl={4} xxl={4}>
							<Input
								addonAfter="%"
								type="number"
								maxLength={3}
								autoFocus={true}
								style={{ width: '100%', textAlign: 'right' }}
								onChange={(event) =>
									this.onChange(_.toNumber(event.target.value))
								}
								value={this.state.commissionPercent}
							/>
						</Col>
					</>
				) : null}
			</Space>
		);
	}
	chooseCommissionPercent = (amountType: string) => {
		switch (amountType) {
			case 'hundred':
				return this.setState({ isShowInput: false }), this.props.onSelect(0);
			case 'seventy':
				return this.setState({ isShowInput: false }), this.props.onSelect(70);
			case 'sixty':
				return this.setState({ isShowInput: false }), this.props.onSelect(60);
			case 'fifty':
				return this.setState({ isShowInput: false }), this.props.onSelect(50);
			case 'forty':
				return this.setState({ isShowInput: false }), this.props.onSelect(40);
			case 'thirty':
				return this.setState({ isShowInput: false }), this.props.onSelect(30);
			case 'zero':
				return this.setState({ isShowInput: false }), this.props.onSelect(100);
			case 'other':
				return (
					this.setState({ isShowInput: true }), this.props.onSelect(undefined)
				);
			default:
				return;
		}
	};
	onChange = (value: number) => {
		let commissionPercent = value;
		if (value > 100) {
			commissionPercent = 100;
		}
		if (value < 0) {
			commissionPercent = 0;
		}
		this.setState({ commissionPercent });
		if (commissionPercent === 0) {
			this.props.onSelect(undefined);
		} else {
			this.props.onSelect(commissionPercent);
		}
	};
	isOtherRate = (value: number) => {
		return _.isEmpty(
			this.COMMISSION_RATE.filter((rate) => value === rate.value)
		);
	};
}

const styles = {
	maxWidth: { width: '100%' },
};
