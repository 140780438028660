import { Result, Button } from "antd";
import React from "react";
import { history } from "routers";

export default class NotFound extends React.Component {
  render() {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button onClick={() => history.replace("/")} type="primary">
            Back Home
          </Button>
        }
      />
    );
  }
}
