import { IEscCommand } from 'models/PrintModel/IEscCommand';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import QrcodeDecoder from 'qrcode-decoder';
import _ from 'lodash';
import configureStore from 'redux/configuration/configureStore';
import { IPrintRequest } from 'models/PrintModel/IPrintResquest';
import { EEscCommandType } from 'models/PrintModel/EEscCommandType';
import { translations, _t, I18n, Const } from 'utils';
import moment from 'moment';
import {
	ICustomer,
	IBookingDetail,
	IEmployee,
	IPayrollSummaryDetail,
	IBooking,
	IPayrollOverview,
} from 'models';
import { IGiftCard } from 'models/IGiftCard';
import { IEscTableRow } from 'models/PrintModel/IEscTableRow';
import { BookingHelper, CurrencyHelper, StringHelper } from 'helpers';
import { PaymentType } from 'utils/Consts';
import { electron } from 'ipc';
import { generateQrCode } from 'helpers/bookingHelper';
import { IPaymentType } from 'models/ITransaction';

export default class PrintManager {}
enum RowType {
	BookingDetail,
	GiftCard,
}

export const print = async (
	command?: IEscCommand[],
	printerRef?: ReactToPrint,
	hideFooter = false
): Promise<boolean> => {
	const store = configureStore().store;
	if (electron) {
		const printEsc = await printESCPOS(command!, hideFooter, true);
		return true;
	}
	const printEsc = await printESCPOS(command!, hideFooter);
	return true;
	// if (test) {
	// 	if (!_.isEmpty(test.data) && test.data.success) {
	// 		const printEsc = await printESCPOS(command!, hideFooter);
	// 		if (printEsc) {
	// 			return true;
	// 		} else {
	// 			// printerRef?.handlePrint();
	// 			return true;
	// 		}
	// 	} else {
	// 		// printerRef!.handlePrint();
	// 		return true;
	// 	}
	// } else {
	// 	// printerRef!.handlePrint();
	// 	return true;
	// }
};

const createESCPOSTitle = (title: string, isDraft = false): IEscCommand[] => {
	const commands = [];

	commands.push({
		type: EEscCommandType.align,
		data: 'ct',
	});
	commands.push({
		type: EEscCommandType.size,
		data: 1,
	});
	if (isDraft) {
		commands.push({
			type: EEscCommandType.text,
			data: 'DRAFT',
		});
		commands.push(drawLine());
	}
	commands.push({
		type: EEscCommandType.text,
		data: title,
	});
	commands.push({
		type: EEscCommandType.size,
		data: 0.01,
	});
	commands.push(feed());
	return commands;
};

const printESCPOS = async (
	commands: IEscCommand[],
	hideFooter = false,
	ipcPrint = false
): Promise<boolean> => {
	const store = configureStore().store;
	const ip = !_.isEmpty(store.getState().SettingReducer.printerIp)
		? store.getState().SettingReducer.printerIp!
		: '192.168.123.100';
	const port = store.getState().SettingReducer.printerPort!;
	const headerCommands = hideFooter ? [] : createESCPOSHeader();
	const footerCommands = hideFooter ? [] : createESCPOSFooter();
	const isUsb =
		store.getState().SettingReducer.printerConnectionType ===
		Const.PRINTER_CONNECTION.USB;
	const request: IPrintRequest = {
		ip,
		port,
		command: _.concat(headerCommands, commands, footerCommands),
		isUsb,
	};
	if (ipcPrint) {
		electron.ipcRenderer.printCommand(request);
		return true;
	}
	await axios.post('http://localhost:3001/print/command', request);
	return true;
};

const createESCPOSFooter = (): IEscCommand[] => {
	const commands: IEscCommand[] = [];

	commands.push({
		type: EEscCommandType.size,
		data: 0.01,
	});
	commands.push({
		type: EEscCommandType.table,
		data: {
			headers: [
				{ text: `Date: ${moment().local().format('MM-DD-YYYY')}` },
				{ text: `Time: ${moment().local().format('HH:mm:ss')}` },
			],
			data: [],
		},
	});
	commands.push(drawLine());
	commands.push({
		type: EEscCommandType.align,
		data: 'ct',
	});
	commands.push({
		type: EEscCommandType.text,
		data: `${I18n.t(_t(translations.thank))}`,
	});
	return commands;
};

export const createMenuQr = (url: string) => {
	const headerCommands = createESCPOSHeader();
	const commands: IEscCommand[] = createESCPOSTitle(
		`Scan QRCode to view our services`
	);
	commands.push({
		type: EEscCommandType.qrCode,
		data: {
			content: url,
			size: 4,
		},
	});
	return _.concat(headerCommands, commands);
};

const createESCPOSHeader = (): IEscCommand[] => {
	const commands: IEscCommand[] = [];
	const store = configureStore().store;
	const shop = store.getState().ShopReducer.shop!;
	const branch = store.getState().BranchReducer.currentBranch!;
	commands.push({
		type: EEscCommandType.font,
		data: 'A',
	});
	commands.push({
		type: EEscCommandType.align,
		data: 'ct',
	});
	commands.push({
		type: EEscCommandType.size,
		data: 0.001,
	});
	commands.push({
		type: EEscCommandType.text,
		data: `${shop.name} --- ${branch.name}`,
	});
	if (!_.isEmpty(branch.address)) {
		commands.push({
			type: EEscCommandType.text,
			data: `${branch.address!.street}, ${branch.address?.city}, ${
				branch.address?.state
			}`,
		});
	}
	commands.push({
		type: EEscCommandType.text,
		data: `${I18n.t(_t(translations.phone))}: ${branch.phone} - ${shop.phone}`,
	});
	commands.push({
		type: EEscCommandType.text,
		data: `${I18n.t(_t(translations.email))}: ${branch.email} - ${shop.email}`,
	});
	commands.push(drawLine());
	commands.push(feed());

	return commands;
};

export const createESCPayrollAll = (
	payrollDetails: IPayrollSummaryDetail[],
	period: string
) => {
	let commands: IEscCommand[] = [];
	for (const payrollDetail of payrollDetails) {
		commands = _.concat(
			commands,
			createESCPOSHeader(),
			createESCPayroll(payrollDetail, period),
			createESCPOSFooter()
		);
		commands.push(feed());
		commands.push(feed());
		commands.push({
			type: EEscCommandType.cut,
		});
	}
	return commands;
};

export const createESCPayrollDetail = (payrollDetail: IPayrollOverview) => {
	const commands: IEscCommand[] = createESCPOSTitle(`Pay Slip Detail`);
	const period = `${moment(
		payrollDetail.fromDate ? payrollDetail.fromDate! : ''
	).format('MM/DD/YYYY')} - ${moment(
		payrollDetail.toDate ? payrollDetail.toDate! : ''
	).format('MM/DD/YYYY')}`;
	commands.push({
		type: EEscCommandType.table,
		data: {
			headers: [],
			data: [
				createDescription(
					'Name',
					`${payrollDetail.employeeName}`,
					'normal',
					'b'
				),
				createDescription('Period', period, 'normal', 'b'),
			],
		},
	});

	commands.push(drawLine());
	commands.push(feed());

	const rows: IEscTableRow[][] = [];

	for (const pr of payrollDetail.payrollOverviewDetails!) {
		rows.push([
			{
				text: `${moment(pr.date).format('MM/DD')}`,
			},
		]);
		rows.push([
			{ text: '', width: 0.1 },
			{
				text: `${pr.turn}   ${CurrencyHelper.formatPrice(
					pr.income
				)}    ${CurrencyHelper.formatPrice(
					pr.tip
				)}    ${CurrencyHelper.formatPrice(pr.salaryPay)}`,
				width: 0.9,
			},
		]);
	}
	commands.push({
		type: EEscCommandType.table,
		data: {
			headers: [
				{
					text: 'Turn   Income   Tip   Total',
					width: 0.9,
					style: 'bu',
				},
			],
			data: rows,
		},
	});
	commands.push(drawLine());

	commands.push({
		type: EEscCommandType.table,
		data: {
			headers: [
				{ text: '', width: 0.1 },
				{
					text: `${payrollDetail.totalTurn}   ${CurrencyHelper.formatPrice(
						payrollDetail.totalIncome
					)}    ${CurrencyHelper.formatPrice(
						payrollDetail.totalTip
					)}    ${CurrencyHelper.formatPrice(payrollDetail.totalSalaryPay)}`,
					width: 0.9,
					style: 'bu',
				},
			],
			data: [],
		},
	});
	commands.push(feed());
	return commands;
};

export const createESCPayroll = (
	payrollDetail: IPayrollSummaryDetail,
	period: string
) => {
	const commands: IEscCommand[] = createESCPOSTitle(`Pay Slip`);

	commands.push({
		type: EEscCommandType.table,
		data: {
			headers: [],
			data: [
				createDescription(
					'Name',
					`${payrollDetail.employeeName}`,
					'normal',
					'b'
				),
				createDescription(
					'Phone  Number',
					`${payrollDetail.phone}`,
					'normal',
					'b'
				),
				createDescription('Email', `${payrollDetail.email}`, 'normal', 'b'),
				createDescription('Period', period, 'normal', 'b'),
			],
		},
	});
	commands.push(drawLine());
	commands.push({
		type: EEscCommandType.table,
		data: {
			headers: createDescription(
				'Salary',
				CurrencyHelper.formatBalance(payrollDetail.salary),
				'b'
			),
			data: [],
		},
	});
	commands.push({
		type: EEscCommandType.table,
		data: {
			headers: createDescription(
				'Revenue',
				CurrencyHelper.formatBalance(payrollDetail.income),
				'b'
			),
			data: [
				createDescription(
					'Service Revenue',
					CurrencyHelper.formatBalance(payrollDetail.serviceIncome)
				),
				createDescription(
					'Product Revenue',
					CurrencyHelper.formatBalance(payrollDetail.productIncome)
				),
				createDescription(
					'GiftCard Revenue',
					CurrencyHelper.formatBalance(payrollDetail.giftCardIncome)
				),
			],
		},
	});
	commands.push(drawLine());
	commands.push({
		type: EEscCommandType.table,
		data: {
			headers: createDescription(
				'Commission',
				CurrencyHelper.formatBalance(payrollDetail.commission),
				'b'
			),
			data: [
				createDescription(
					'Service Com',
					CurrencyHelper.formatBalance(payrollDetail.serviceCommission)
				),
				createDescription(
					'Product Com',
					CurrencyHelper.formatBalance(payrollDetail.productCommission)
				),
				createDescription(
					'GiftCard Com',
					CurrencyHelper.formatBalance(payrollDetail.giftCardCommission)
				),
			],
		},
	});
	commands.push({
		type: EEscCommandType.table,
		data: {
			headers: [],
			data: [
				createDescription(
					'Tips',
					CurrencyHelper.formatBalance(payrollDetail.tip),
					'b'
				),
				createDescription(
					`Card Charge`,
					CurrencyHelper.formatBalance(payrollDetail.cardCharge),
					'b'
				),
				createDescription(
					`Tips Card Charge`,
					CurrencyHelper.formatBalance(payrollDetail.tipCardCharge),
					'b'
				),
			],
		},
	});

	commands.push({
		type: EEscCommandType.table,
		data: {
			headers: [],
			data: [
				createDescription(
					'Total Salary',
					CurrencyHelper.formatBalance(payrollDetail.salaryPay),
					'bu'
				),
				createDescription(
					'Cash Income',
					CurrencyHelper.formatBalance(payrollDetail.salaryPayByCash),
					'bu'
				),
				createDescription(
					'Check Income',
					CurrencyHelper.formatBalance(payrollDetail.salaryPayByCheck),
					'bu'
				),
			],
		},
	});

	commands.push(drawLine());
	commands.push(feed());

	return commands;
};

export const createESCCheckIn = async (
	customer: Partial<ICustomer>,
	bookingId: string,
	bookingNumber = 0
): Promise<IEscCommand[]> => {
	const commands: IEscCommand[] = createESCPOSTitle(
		`Check In Ticket \n \n ${bookingNumber}`
	);
	// commands.push(createTableCustomer(customer));
	// commands.push({
	// 	type: EEscCommandType.align,
	// 	data: 'ct',
	// });
	// commands.push({
	// 	type: EEscCommandType.size,
	// 	data: 1,
	// });
	// commands.push({
	// 	type: EEscCommandType.text,
	// 	data: bookingNumber,
	// });
	// commands.push({
	// 	type: EEscCommandType.size,
	// 	data: 0.01,
	// });

	commands.push(feed());
	commands.push({
		type: EEscCommandType.text,
		data: 'Please show your QRCode or phone number \nto our staff',
	});
	commands.push({
		type: EEscCommandType.qrCode,
		data: {
			content: generateQrCode(bookingId),
			size: 3,
		},
	});

	return commands;
};
const getPaymentTypeName = (type: PaymentType): string => {
	switch (type) {
		case PaymentType.CASH:
			return I18n.t(_t(translations.checkout.cash));
		case PaymentType.GIFT_CARD:
			return I18n.t(_t(translations.checkout.giftCard));
		case PaymentType.CREDIT_CARD:
			return I18n.t(_t(translations.checkout.creditCard));
		case PaymentType.APP:
			return I18n.t(_t(translations.checkout.payWithApp));

		default:
			return '';
	}
};

const createESCPaymentTypes = (paymentTypes: Partial<IPaymentType>[]) => {
	const rows: IEscTableRow[][] = [];
	for (const payment of paymentTypes) {
		const cardCharge =
			payment.paymentType === PaymentType.CREDIT_CARD
				? payment.cardCharge || 0
				: 0;
		rows.push([
			{
				text:
					payment.paymentType === 6
						? `${payment.appName}`
						: getPaymentTypeName(payment.paymentType!),
				width: 0.5,
				align: 'LEFT',
			},
			{
				text: CurrencyHelper.formatBalance((payment.amount || 0) - cardCharge),
				width: 0.5,
				align: 'RIGHT',
			},
		]);
		if (payment.paymentType === PaymentType.CREDIT_CARD) {
			rows.push(createPriceTableRow('Card Charge', payment.cardCharge || 0));
		}
	}
	return rows;
};

const calculateCardCharge = (paymentTypes: Partial<IPaymentType>[]) => {
	return _.sumBy(paymentTypes, (payment) => {
		return payment.cardCharge || 0;
	});
};

interface IESCCheckout {
	bookingId: string;
	bookingDetails: Partial<IBookingDetail>[];
	listBookingCombine: IBooking[];
	giftCards: Partial<IGiftCard>[];
	subTotalService?: number;
	tax?: number;
	tip?: number;
	discount?: number;
	discountPer?: number;
	balance: number;
	customerPaid: number;
	customer: ICustomer;
	paymentTypes: Partial<IPaymentType>[];
	employee?: IEmployee;
	joint?: number;
	change?: number;
	isDraft?: boolean;
}

export const createESCCheckOut = (data: IESCCheckout): IEscCommand[] => {
	const {
		bookingId,
		bookingDetails = [],
		listBookingCombine = [],
		giftCards = [],
		subTotalService = 0,
		tax = 0,
		tip = 0,
		discount = 0,
		discountPer = 0,
		balance = 0,
		customer,
		paymentTypes,
		employee,
		joint = 1,
		change = 0,
		isDraft = false,
		customerPaid,
	} = data;
	const commands: IEscCommand[] = createESCPOSTitle(
		I18n.t(_t(translations.receipt)),
		isDraft
	);
	const store = configureStore().store;
	const shop = store.getState().ShopReducer.shop!;
	const branch = store.getState().BranchReducer.currentBranch!;
	let combineBookingDetails: Partial<IBookingDetail>[] = [];
	for (const b of listBookingCombine) {
		combineBookingDetails = _.concat(combineBookingDetails, b.bookingDetails);
	}
	const bookingDetailsRow = _.concat(
		bookingDetails,
		combineBookingDetails
	).reduce<IEscTableRow[][]>((arr, bookingDetail, index) => {
		const row = createReceiptTableRow(bookingDetail!, RowType.BookingDetail);
		arr.push([createItemColumn(`${bookingDetail.item?.name}`, 'bu', 1)]);
		if (bookingDetail.extraAmount && bookingDetail.extraAmount !== 0) {
			const extraRow = [
				createItemColumn(`--${bookingDetail.note}`, 'i'),
				createNormalColumn(`${discount}`, undefined, 0.1),
				createNormalColumn(
					`${CurrencyHelper.formatBalance(bookingDetail.extraAmount, false)}`
				),
				createNormalColumn(
					`${CurrencyHelper.formatBalance(bookingDetail.extraAmount, false)}`
				),
			];
			arr.push(extraRow);
		}
		arr.push(row);

		return arr;
	}, []);
	commands.push({
		type: EEscCommandType.table,
		data: {
			headers: createReceiptTableHeader(),
			data: _.concat(
				bookingDetailsRow,
				giftCards.map((giftCard) =>
					createReceiptTableRow(giftCard, RowType.GiftCard)
				)
			),
		},
	});
	commands.push({
		type: EEscCommandType.table,
		data: {
			headers: [],
			data: [
				createPriceTableRow('Sub Total', subTotalService),
				createPriceTableRow('Tax', tax),
				createPriceTableRow('Discount', discountPer, false),
				createPriceTableRow('', discount),
				createPriceTableRow('Tip', tip),
			],
		},
	});
	commands.push(drawLine());
	commands.push({
		type: EEscCommandType.table,
		data: {
			headers: [{ text: 'PAYMENT', width: 0.5, align: 'LEFT', style: 'b' }],
			data: createESCPaymentTypes(paymentTypes),
		},
	});
	commands.push(feed());
	commands.push({
		type: EEscCommandType.size,
		data: 1,
	});
	commands.push({
		type: EEscCommandType.align,
		data: 'RT',
	});
	commands.push({
		type: EEscCommandType.text,
		data: `  TOTAL:${CurrencyHelper.formatBalance(balance)} `,
	});
	commands.push({
		type: EEscCommandType.size,
		data: 0.01,
	});
	commands.push({
		type: EEscCommandType.align,
		data: 'CT',
	});
	commands.push({
		type: EEscCommandType.table,
		data: {
			headers: [],
			data: [
				[
					{ text: 'Customer Pay', width: 0.5, align: 'LEFT' },
					{
						text: CurrencyHelper.formatBalance(customerPaid),
						width: 0.5,
						align: 'RIGHT',
					},
				],
				[
					{ text: 'Change', width: 0.5, align: 'LEFT' },
					{
						text: CurrencyHelper.formatBalance(change),
						width: 0.5,
						align: 'RIGHT',
					},
				],
			],
		},
	});
	if (joint === 1) {
		commands.push(drawLine());
		commands.push({
			type: EEscCommandType.table,
			data: {
				headers: [
					{ text: 'Receptionist', width: 0.5, align: 'LEFT' },
					{
						text: employee ? `${employee.firstName} ${employee.lastName}` : '',
						width: 0.5,
						style: 'bu',
						align: 'RIGHT',
					},
				],
				data: [],
			},
		});
		commands.push(feed());
		commands.push(createTableCustomer(customer));
		commands.push(feed());
	} else if (bookingId !== '') {
		commands.push(drawLine());
		commands.push(feed());
		commands.push({
			type: EEscCommandType.text,
			data: 'Please scan QR Code to rating your services \n and tell us your feels',
		});

		commands.push({
			type: EEscCommandType.qrCode,
			data: {
				content: `https://${shop.adminUrl}.${process.env
					.REACT_APP_PRODUCTION_URL!}/rating/${branch.id}/${bookingId}`,
				size: 2,
			},
		});
		commands.push(feed());
	}
	return commands;
};

const createPriceTableRow = (
	title: string,
	price: number,
	isPrice = true
): IEscTableRow[] => {
	return [
		createNormalColumn(title, undefined, 0.5),
		createNormalColumn(
			` ${
				isPrice === true
					? CurrencyHelper.formatBalance(price)
					: StringHelper.formatPercent(price)
			}`,
			undefined,
			0.4
		),
	];
};

const createReceiptTableHeader = (): IEscTableRow[] => {
	return [
		createItemColumn(I18n.t(_t(translations.staff.staffTitle)), 'b'),
		createNormalColumn(I18n.t(_t(translations.dct)), 'b', 0.1),
		createNormalColumn(I18n.t(_t(translations.price)), 'b'),
		createNormalColumn(I18n.t(_t(translations.amount)), 'b'),
	];
};
const createReceiptTableRow = (
	data: Partial<IBookingDetail> | IGiftCard,
	type: RowType
): IEscTableRow[] => {
	switch (type) {
		case RowType.BookingDetail:
			const bookingDetail = data as IBookingDetail;
			let discount = 0;
			if (
				!_.isEmpty(bookingDetail.discount) &&
				bookingDetail.discount &&
				bookingDetail.discount > 0
			) {
				discount = bookingDetail.discount;
			}
			if (
				bookingDetail.promotionDiscount &&
				bookingDetail.promotionDiscount > 0
			) {
				discount = bookingDetail.promotionDiscount;
			}
			const rows = [
				createItemColumn(bookingDetail.stylist?.firstName || ''),
				createNormalColumn(`${discount}`, undefined, 0.1),
				createNormalColumn(
					`${CurrencyHelper.formatBalance(bookingDetail.price, false)}`
				),
				createNormalColumn(
					`${CurrencyHelper.formatBalance(
						BookingHelper.calculateBookingDetailAmount(bookingDetail),
						false
					)}`
				),
			];
			return rows;
		case RowType.GiftCard:
			const giftCard = data as IGiftCard;
			return [
				createItemColumn(`${I18n.t(_t(translations.checkout.giftCard))}`),
				createNormalColumn(`0`, undefined, 0.1),
				createNormalColumn(
					`${CurrencyHelper.formatBalance(giftCard.balance, false)}`
				),
				createNormalColumn(
					`${CurrencyHelper.formatBalance(giftCard.balance, false)}`
				),
			];
		default:
			return [];
	}
};
const createTableCustomer = (customer: Partial<ICustomer>): IEscCommand => {
	return {
		type: EEscCommandType.table,
		data: {
			headers: [
				{
					text: I18n.t(_t(translations.mainBooking.name)),
					width: 0.5,
					align: 'CENTER',
				},
				{
					text: I18n.t(_t(translations.mainBooking.phone)),
					width: 0.5,
					align: 'CENTER',
				},
			],
			data: [
				[
					{
						text: `${customer.firstName} ${customer.lastName}`,
						width: 0.5,
						align: 'CENTER',
						style: 'bu',
					},
					{ text: customer.phone!, width: 0.5, align: 'CENTER', style: 'bu' },
				],
			],
		},
	};
};
const createTableEmployee = (employee: Partial<IEmployee>): IEscCommand => {
	return {
		type: EEscCommandType.table,
		data: {
			headers: [
				{
					text: "Staff's Name",
					width: 0.5,
					align: 'CENTER',
				},
			],
			data: [
				[
					{
						text: `${employee.firstName} ${employee.lastName}`,
						width: 0.5,
						align: 'CENTER',
						style: 'bu',
					},
				],
			],
		},
	};
};
const createItemColumn = (
	title: string,
	style = 'normal',
	width = 0.4
): IEscTableRow => {
	return { text: title, width: width, style: style, align: 'LEFT' };
};

const createNormalColumn = (
	title: string,
	style = 'normal',
	width = 0.2
): IEscTableRow => {
	return { text: title, width: width, style: style, align: 'RIGHT' };
};
const createDescription = (
	title: string,
	value: string,
	style = 'normal',
	valueStyle = style,
	single = false
): IEscTableRow[] => {
	return [
		{
			text: `${title}:`,
			width: single ? 1 : 0.45,
			style: style,
			align: 'LEFT',
		},
		{ text: value, width: 0.55, style: valueStyle, align: 'RIGHT' },
	];
};

const drawLine = (): IEscCommand => {
	return {
		type: EEscCommandType.drawLine,
	};
};
const feed = (): IEscCommand => {
	return {
		type: EEscCommandType.feed,
	};
};
