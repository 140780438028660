import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import PrivateRoute from "routers/ProtectedRoute";

export type PermissionRouteProps = {
  hasPermission: boolean;
} & RouteProps;

function PermissionRoute({ hasPermission, children, ...rest }: PermissionRouteProps) {
  return hasPermission ? (
    <PrivateRoute isAuthenticated={true} {...rest}>
      {children}
    </PrivateRoute>
  ) : (
    <Redirect to="/today" />
  );
}

export default PermissionRoute;
