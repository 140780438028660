import { I18n, translations, _t } from 'utils';
import { WeekDays } from 'utils/Consts';
import _ from 'lodash';

export function getShortName(text: string) {
	var matches = text.match(/\b(\w)/g); // ['J','S','O','N']
	return matches?.join('');
}

export const removeSpecialCharacter = (text?: string) => {
	if (text) {
		return text.replace(/[^\w\s]/gi, '');
	}
	return '';
};

export const formatPercent = (value: number | undefined) => `${value}%`;

export const parserCommission = (value: any) => value.replace('%', '');

export const formatPhoneNumber = (str?: string) => {
	//Filter only numbers from the input
	let cleaned = ('' + str).replace(/\D/g, '');

	//Check if the input is of correct length
	let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		return match[1] + '-' + match[2] + '-' + match[3];
	}

	return null;
};

export const convertToRawPhone = (value: string) => {
	if (value) {
		return value.match(/\d/g)?.join('');
	}
	return '';
};

export const checkPhoneNumber = (value: string) => {
	// if (value.match(/\d/g)?.length === 10) {
	//   return true;
	// }
	// return false
	return value.match(/\d/g)?.length === 10;
};

export const getDayOfWeek = (day: number) => {
	switch (day) {
		case WeekDays.SUNDAY:
			return I18n.t(_t(translations.daysOfWeek.sunday));
		case WeekDays.MONDAY:
			return I18n.t(_t(translations.daysOfWeek.monday));
		case WeekDays.TUESDAY:
			return I18n.t(_t(translations.daysOfWeek.tuesday));
		case WeekDays.WEDNESDAY:
			return I18n.t(_t(translations.daysOfWeek.wednesday));
		case WeekDays.THURSDAY:
			return I18n.t(_t(translations.daysOfWeek.thursday));
		case WeekDays.FRIDAY:
			return I18n.t(_t(translations.daysOfWeek.friday));
		case WeekDays.SATURDAY:
			return I18n.t(_t(translations.daysOfWeek.saturday));
		default:
			return I18n.t(_t(translations.daysOfWeek.wholeWeek));
	}
};

export const convertStringToUpperCaseFirstChar = (str: string) => {
	const arr = str.toLowerCase().split(' ');
	for (let i = 0; i < arr.length; i++) {
		arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
	}
	const str2 = arr.join(' ');
	return str2;
};

export const getBase64Image = (
	img: Blob,
	callback: (arg0: string | ArrayBuffer | null) => any
) => {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
};
