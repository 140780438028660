import GlobalStyles, { kStyles } from "GlobalStyles";

const styles: kStyles = {
  layout: {
    minHeight: "100vh",
  },
  formContainer: { width: "100%" },
  headerContainer: {
    zIndex: 9999,
    width: "100%",
    backgroundColor: "white",
    position: "fixed",
    ...GlobalStyles.boxShadow,
  },
  rowHeader: {
    paddingInline: 16,
    alignItems: "center",
    paddingTop: 16,
    paddingBottom: 16,
  },
  icClose: {
    fontSize: 18,
    zIndex: 1,
  },
  headerTitle: {
    textAlign: "center",
    zIndex: 9999,
    marginBottom: "auto",
  },
  buttonContainer: { textAlign: "right", marginBottom: 10 },
  // rowForm: { paddingTop: "120px" }
  spinLayout: {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  spin: { margin: "0 auto" },
};

export default styles;
