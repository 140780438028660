import { Button, Col, Input, Row, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import CheckOutBaseModal from './CheckOutBaseModal';
import styles from './styles';
import { translations, _t, I18n } from 'utils';
interface IAddExtraModalProps {
	onOk(extraAmount: number, extraReason: string): void;
	onCancel(): void;
}

interface IAddExtraModalState {
	extraAmount?: number;
	extraReason?: string;
}

export default class PayCreditCardModal extends React.Component<
	IAddExtraModalProps,
	IAddExtraModalState
> {
	constructor(props: IAddExtraModalProps) {
		super(props);
		this.state = {};
	}
	inputRef: any = React.createRef();

	render() {
		return (
			<CheckOutBaseModal
				title={I18n.t(_t(translations.checkout.addServiceExtra))}
				onOk={() => this.onOk()}
				onCancel={this.props.onCancel}
				disableOk={
					!this.state.extraAmount ||
					this.state.extraAmount <= 0 ||
					!this.state.extraReason
				}
			>
				<Col>
					<Typography.Title level={5}>
						{I18n.t(_t(translations.checkout.extraAmount))}
					</Typography.Title>
					<Row gutter={8}>
						<Col md={20}>
							<Input
								ref={this.inputRef}
								addonAfter="$"
								type="number"
								style={styles.inputNumber}
								onPressEnter={this.onOk}
								autoFocus={true}
								value={this.state.extraAmount}
								onChange={(event) =>
									this.onChange(_.toNumber(event.target.value))
								}
							/>
						</Col>
						<Col md={4}>
							<Button
								onClick={() => {
									this.setState({ extraAmount: undefined });
									this.inputRef.current?.focus();
								}}
								danger
							>
								{I18n.t(_t(translations.clear))}
							</Button>
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<Typography.Title level={5}>
						{I18n.t(_t(translations.checkout.addExtraReason))}
					</Typography.Title>
					<Input.TextArea
						rows={4}
						placeholder={I18n.t(
							_t(translations.checkout.addExtraReasonPlaceholder)
						)}
						value={this.state.extraReason}
						onChange={(event) =>
							this.setState({ extraReason: event.target.value })
						}
					/>
				</Col>
			</CheckOutBaseModal>
		);
	}
	onChange = (extraAmount: any) => {
		if (isNaN(extraAmount)) {
			this.setState({ extraAmount: undefined });
			return;
		}
		this.setState({
			extraAmount:
				_.toNumber(extraAmount) === 0 ? undefined : _.toNumber(extraAmount),
		});
	};
	onOk = () => {
		if (this.state.extraAmount && this.state.extraReason) {
			this.props.onOk(this.state.extraAmount!, this.state.extraReason!);
		}
	};
}
