import React, { Component } from 'react';
import { Col, Row } from 'antd';
import {
	ContentAdminContainer,
	IComponentProps,
	PrintComponent,
} from 'components';
import Payroll from './component/Payroll';
import PayrollPreview from './component/PayrollPreview';
import { connect } from 'react-redux';
import { RootState } from 'redux/configuration/rootReducer';
import GlobalStyles from 'GlobalStyles';
import ReactToPrint from 'react-to-print';
import {
	print,
	createESCPayroll,
	createESCPayrollAll,
} from 'helpers/printHelper';
import { Dispatch } from '@reduxjs/toolkit';
import { IPayrollSummaryDetail, IPayrollSummary } from 'models';
import PayrollPrintPreview from './component/PayrollPrintPreview';
import moment from 'moment';
interface IPayrollReportProps
	extends IComponentProps,
		ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps> {}
interface IPayrollReportStates {
	showPreview: boolean;
	payrollItem?: IPayrollSummaryDetail;
	payroll?: IPayrollSummary;
	printType?: PrintType;
}

enum PrintType {
	ONE,
	ALL,
}

export class PayrollReport extends Component<
	IPayrollReportProps,
	IPayrollReportStates
> {
	private printer?: ReactToPrint | null;

	state = {
		showPreview: false,
		payrollItem: undefined,
		payroll: undefined,
		printType: undefined,
	};

	render() {
		const payroll: IPayrollSummary = this.state.payroll!;
		const period = payroll
			? `${moment(payroll.fromDate ? payroll.fromDate! : '').format(
					'MM/DD/YYYY'
			  )} - ${moment(payroll.toDate ? payroll.toDate! : '').format(
					'MM/DD/YYYY'
			  )}`
			: ``;
		return (
			<ContentAdminContainer>
				<Row gutter={[0, 8]}>
					<Col
						sm={24}
						md={!this.state.showPreview ? 24 : 16}
						style={{
							width: '100%',
							paddingInline: 8,
							marginBottom: 64,
							overflow: 'hidden',
						}}
					>
						<Payroll
							onPrintAll={(dataPayroll) => this.onPrintAll(dataPayroll)}
							onCellClick={this.onCellClick}
							onPrint={this.onPrint}
						/>
					</Col>
					{this.state.showPreview ? (
						<Col
							sm={24}
							md={8}
							style={{
								width: '100%',
								paddingInline: 8,
								marginBottom: 64,
								overflowY: 'auto',
								height: '80vh',
								...GlobalStyles.boxShadow,
							}}
						>
							<PayrollPreview
								onClose={() => this.setState({ showPreview: false })}
								data={this.state.payrollItem}
								period={period}
							/>
						</Col>
					) : null}
				</Row>
				<PrintComponent refPrinter={(ref) => (this.printer = ref!)}>
					{this.state.printType === PrintType.ONE ? (
						<PayrollPrintPreview
							data={this.state.payrollItem}
							period={period}
						/>
					) : (
						this.renderPrintAll()
					)}
				</PrintComponent>
			</ContentAdminContainer>
		);
	}
	renderPrintAll = () => {
		const payroll: IPayrollSummary = this.state.payroll!;
		const period = payroll
			? `${moment(payroll.fromDate ? payroll.fromDate! : '').format(
					'MM/DD/YYYY'
			  )} - ${moment(payroll.toDate ? payroll.toDate! : '').format(
					'MM/DD/YYYY'
			  )}`
			: ``;
		const listPayroll = payroll ? payroll.details!.data : [];
		return (
			<>
				{listPayroll.map((payrollItem) => (
					<PayrollPrintPreview data={payrollItem} period={period} />
				))}
			</>
		);
	};
	onCellClick = async (
		payrollItem: IPayrollSummaryDetail,
		payroll: IPayrollSummary,
		index: number
	) => {
		this.setState({ showPreview: true, payrollItem, payroll });
	};

	onPrintAll = async (dataPayroll: IPayrollSummary) => {
		this.setState({ payroll: dataPayroll, printType: PrintType.ALL });
		const period = dataPayroll
			? `${moment(dataPayroll.fromDate ? dataPayroll.fromDate! : '').format(
					'MM/DD/YYYY'
			  )} - ${moment(dataPayroll.toDate ? dataPayroll.toDate! : '').format(
					'MM/DD/YYYY'
			  )}`
			: ``;
		const payrollAllESCCommands = createESCPayrollAll(
			dataPayroll.details.data,
			period
		);
		const printPayroll = await print(
			payrollAllESCCommands,
			this.printer!,
			true
		);
	};

	onPrint = async (payrollItem: IPayrollSummaryDetail) => {
		this.setState({ printType: PrintType.ONE });
		const payroll: IPayrollSummary = this.state.payroll!;
		const period = payroll
			? `${moment(payroll.fromDate ? payroll.fromDate! : '').format(
					'MM/DD/YYYY'
			  )} - ${moment(payroll.toDate ? payroll.toDate! : '').format(
					'MM/DD/YYYY'
			  )}`
			: ``;
		const payrollESCCommands = createESCPayroll(payrollItem, period);
		const printReport = await print(payrollESCCommands, this.printer!);
		// if (printReport) {
		//     alert('123')
		// }
	};
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollReport);
