import { Component } from 'react';
import { Typography, Space, Result, Spin, Button, Col, Card } from 'antd';
import styles from './styles';
import { Header, IComponentProps } from 'components';
import { I18n, _t, translations } from 'utils';
import { Dispatch } from 'redux';
import { BranchActions } from 'redux/actions';
import { RootState } from 'redux/configuration/rootReducer';
import { connect } from 'react-redux';
import _ from 'lodash';
import Layout, { Content } from 'antd/lib/layout/layout';
import { IApiResponse, IBooking } from 'models';
import { withRouter } from 'react-router';
import qs from 'qs';
import { StringHelper } from 'helpers';
import { BookingStatus } from 'utils/Consts';
import { IUpdateBookingStatusRequest } from 'models/RequestModels';
import BookingApiService from 'services/BookingApiService';
import { history } from 'routers';
import GlobalStyles from 'GlobalStyles';
import { convertStringToUpperCaseFirstChar } from 'helpers/StringHelper';

const { Title } = Typography;

interface IConfirmBookingProps
	extends IComponentProps<any>,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {}
interface IConfirmBookingState {
	isLoading: boolean;
	subTitle: string;
	title: string;
}

class ConfirmBookingPage extends Component<
	IConfirmBookingProps,
	IConfirmBookingState
> {
	state = {
		isLoading: true,
		subTitle: '',
		title: '',
	};
	componentDidMount() {
		let path = window.location.pathname;
		let str = path.split('/');
		let branchId = str[2];
		let bookingId = str[3];
		this.checkBookingStatus(bookingId);
		this.props.getBranchById(branchId);
	}

	render() {
		return (
			<Layout style={styles.layout}>
				{this.renderHeader()}
				{this.renderContent()}
			</Layout>
		);
	}

	renderResult = () => {
		if (this.state.isLoading) {
			return (
				<Layout style={styles.spinContainer}>
					<Spin
						style={styles.spin}
						tip="Loading..."
						spinning={this.props.isLoading}
					/>
				</Layout>
			);
		}
		return (
			<Result
				style={{ marginTop: 0 }}
				status={this.state.title.includes('success') ? 'success' : 'error'}
				title={<Title level={4}>{this.state.title}</Title>}
				subTitle={<Title level={5}>{this.state.subTitle}</Title>}
			/>
		);
	};
	renderHeader() {
		return (
			<Header
				detailPageProps={{
					title: I18n.t(_t(translations.confirmBooking.confirmBooking)),
					hideCloseButton: true,
				}}
			/>
		);
	}
	renderContent() {
		const { currentBranch } = this.props;
		if (!currentBranch) {
			return null;
		}
		const phone = currentBranch.phone;
		const email = currentBranch.email;

		let address = '';
		!_.isEmpty(currentBranch.address)
			? !_.isEmpty(currentBranch.address!.street) ||
			  !_.isEmpty(currentBranch.address!.city) ||
			  !_.isEmpty(currentBranch.address!.state)
				? (address = `${currentBranch.address!.street} - ${
						currentBranch.address!.city
				  }`)
				: (address = '')
			: (address = '');
		return (
			<Content style={styles.contentContainer}>
				<Card
					style={styles.cardContainer}
					title={
						<Space
							style={styles.spaceContentContainer}
							direction="vertical"
							size="small"
						>
							<Title level={3} style={styles.branchTextInfo}>
								{`${currentBranch.name}`}
							</Title>
							<Title level={5} style={styles.branchTextInfo}>
								{convertStringToUpperCaseFirstChar(address)}
							</Title>
							<Title level={5} style={styles.branchTextInfo}>
								{convertStringToUpperCaseFirstChar(
									currentBranch.address?.state!
								)}
							</Title>
						</Space>
					}
				>
					{this.renderResult()}
					<Col style={{ textAlign: 'center' }}>
						<Button
							type="default"
							onClick={() =>
								(window.location.href = `${this.props.shop?.portalUrl}`)
							}
						>
							Go to Home Page
						</Button>
					</Col>
				</Card>
			</Content>
		);
	}

	checkBookingStatus = async (id: string) => {
		this.setState({ isLoading: true });
		const statusCheck = await BookingApiService.getBookingById(id);
		if (!_.isEmpty(statusCheck.errors)) {
			this.updateBooking(id);
			return;
		}
		const checkStatusResult = statusCheck as IApiResponse<IBooking>;
		if (!_.isEmpty(checkStatusResult.data)) {
			if (checkStatusResult.data?.status === BookingStatus.CONFIRMED) {
				this.setState({
					subTitle: I18n.t(_t(translations.confirmBooking.seeYouSoon)),
					isLoading: false,
					title: I18n.t(_t(translations.confirmBooking.confirmSuccess)),
				});
				return;
			} else if (checkStatusResult.data?.status === BookingStatus.PENDING) {
				this.updateBooking(id);
			} else if (
				checkStatusResult.data?.status === BookingStatus.CANCELED ||
				checkStatusResult.data?.status === BookingStatus.EXPIRED
			) {
				this.setState({
					subTitle: I18n.t(_t(translations.confirmBooking.bookingCancel)),
					isLoading: false,
					title: I18n.t(_t(translations.confirmBooking.confirmFail)),
				});
			} else {
				this.setState({
					subTitle: I18n.t(_t(translations.confirmBooking.tryAgain)),
					title: I18n.t(_t(translations.confirmBooking.confirmFail)),
					isLoading: false,
				});
			}
		}
	};

	updateBooking = async (id: string) => {
		const request: IUpdateBookingStatusRequest = {
			id,
			status: BookingStatus.CONFIRMED,
		};

		const statusResponse = await BookingApiService.updateBookingStatus(request);
		this.setState({ isLoading: false });
		if (!_.isEmpty(statusResponse.errors)) {
			this.setState({
				subTitle: I18n.t(_t(translations.confirmBooking.tryAgain)),
				title: I18n.t(_t(translations.confirmBooking.confirmFail)),
			});
			return;
		}
		const updateStatusSuccess = statusResponse as IApiResponse<IBooking>;
		if (updateStatusSuccess.succeeded) {
			this.setState({
				subTitle: I18n.t(_t(translations.confirmBooking.seeYouSoon)),
				title: I18n.t(_t(translations.confirmBooking.confirmSuccess)),
			});
			return;
		}
	};
}

const mapStateToProps = (state: RootState) => ({
	isLoading: state.AppConfigReducer.showLoading,
	currentBranch: state.BranchReducer.currentBranch,
	shop: state.ShopReducer.shop,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	getBranchById: (id: string) => {
		dispatch(BranchActions.getBranchById.request(id));
	},
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ConfirmBookingPage)
);
