import {
	Button,
	Card,
	Col,
	Form,
	InputNumber,
	Radio,
	Space,
	Typography,
} from 'antd';
import { FormHelper } from 'helpers';
import _ from 'lodash';
import { IApiResponse, IService } from 'models';

import { ILoyaltyRank, IRequirement } from 'models/IPromotion';
import React, { ReactNode, useEffect, useState } from 'react';
import PromotionApiService from 'services/PromotionApiService';
import ServiceApi from 'services/ServiceApi';
import { I18n, _t, translations } from 'utils';
import { ListServices } from './ListServices';
import styles from './styles';

const HaveNotVisited = (props: { data?: string }) => {
	const numberDays = [14, 21, 30, 60, 90, 180, 365];

	return (
		<Col span={24}>
			<Form.Item
				label={
					<Typography.Title level={5}>
						{I18n.t(_t(translations.textMessageDetail.haveNotVisited))}
					</Typography.Title>
				}
				name="haveNotVisitedIn"
				initialValue={props.data ? parseInt(props.data) : undefined}
			>
				<Radio.Group buttonStyle="solid" style={{ width: '100%' }}>
					<Space size={'middle'}>
						{numberDays.map((item, index) => {
							return (
								<Radio.Button value={item} key={index}>
									{`${item} ` + I18n.t(_t(translations.textMessageDetail.days))}
								</Radio.Button>
							);
						})}
					</Space>
				</Radio.Group>
			</Form.Item>
		</Col>
	);
};

const BirthdayMonth = (props: {
	data?: string;
	setRequirementHaveBirthdayIn: (value: string) => void;
}) => {
	const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
	const [selected, setSelected] = useState<string[]>([]);

	const onClickButton = (value: string) => {
		let selectedValue = selected;
		let index = selected.findIndex((x: string) => x === value);

		if (index !== -1) {
			selectedValue.splice(index, 1);
		} else {
			selectedValue.push(value);
		}
		setSelected([...selectedValue]);
		props.setRequirementHaveBirthdayIn(selected.toString());
	};

	const renderMonthLabel = (value: number) => {
		switch (value) {
			case 1:
				return I18n.t(_t(translations.month.january));
			case 2:
				return I18n.t(_t(translations.month.february));
			case 3:
				return I18n.t(_t(translations.month.march));
			case 4:
				return I18n.t(_t(translations.month.april));
			case 5:
				return I18n.t(_t(translations.month.may));
			case 6:
				return I18n.t(_t(translations.month.june));
			case 7:
				return I18n.t(_t(translations.month.july));
			case 8:
				return I18n.t(_t(translations.month.august));
			case 9:
				return I18n.t(_t(translations.month.september));
			case 10:
				return I18n.t(_t(translations.month.october));
			case 11:
				return I18n.t(_t(translations.month.november));
			default:
				return I18n.t(_t(translations.month.december));
		}
	};
	useEffect(() => {
		if (props.data) {
			setSelected(props.data?.split(','));
			props.setRequirementHaveBirthdayIn(props.data);
		}
	}, []);
	return (
		<Col span={24}>
			<Typography.Title level={5}>
				{I18n.t(_t(translations.textMessageDetail.birthdayMonth))}
			</Typography.Title>

			<Space size={'middle'} wrap>
				{month.map((x) => (
					<Button
						key={x}
						onClick={() => onClickButton(x.toString())}
						value={x.toString()}
						style={
							selected.includes(x.toString())
								? styles.checkBoxButtonActive
								: undefined
						}
					>
						{renderMonthLabel(x)}
					</Button>
				))}
			</Space>
		</Col>
	);
};

export const ReviewGroup = (props: {
	data?: string;
	setRequirementReviewGroup: (value: string) => void;
}) => {
	const [reviewGroup, setReviewGroup] = useState<string[]>([]);
	const onClickButton = (value: string) => {
		let reviewGroupValue = reviewGroup;
		let index = reviewGroup.findIndex((x: string) => x === value);

		if (index !== -1) {
			reviewGroupValue.splice(index, 1);
		} else {
			reviewGroupValue.push(value);
		}
		setReviewGroup([...reviewGroupValue]);
		props.setRequirementReviewGroup(reviewGroup.toString());
	};
	useEffect(() => {
		if (props.data) {
			setReviewGroup(props.data?.split(','));
			props.setRequirementReviewGroup(props.data);
		}
	}, []);
	return (
		<Col span={24}>
			<Typography.Title level={5}>
				{I18n.t(_t(translations.textMessageDetail.reviewGroup))}
			</Typography.Title>
			<Space size={'middle'}>
				<Button
					key={1}
					onClick={() => onClickButton('0')}
					value={'0'}
					style={
						reviewGroup.includes('0') ? styles.checkBoxButtonActive : undefined
					}
				>
					1 - 3 star
				</Button>
				<Button
					key={2}
					onClick={() => onClickButton('1')}
					value={'1'}
					style={
						reviewGroup.includes('1') ? styles.checkBoxButtonActive : undefined
					}
				>
					4 - 5 star
				</Button>
			</Space>
		</Col>
	);
};
const CustomerRank = (props: {
	data?: string;
	setRequirementCustomerRank: (value: string) => void;
}) => {
	const [ranks, setRanks] = useState<ILoyaltyRank[]>([]);
	const [selected, setSelected] = useState<string[]>([]);

	const onClickButton = (value: string) => {
		let selectedValue = selected;
		let index = selected.findIndex((x: string) => x === value);

		if (index !== -1) {
			selectedValue.splice(index, 1);
		} else {
			selectedValue.push(value);
		}
		setSelected([...selectedValue]);
		props.setRequirementCustomerRank(selected.toString());
	};

	const fetchLoyaltyRank = async () => {
		try {
			const result =
				(await PromotionApiService.getLoyaltyRank()) as IApiResponse<
					ILoyaltyRank[]
				>;
			if (result.succeeded) {
				if (result.data) setRanks([...result.data]);
			}
		} catch (error) {}
	};
	useEffect(() => {
		if (props.data) {
			setSelected(props.data?.split(','));
			props.setRequirementCustomerRank(props.data);
		}
		fetchLoyaltyRank();
	}, []);
	return !_.isEmpty(ranks) ? (
		<Col span={24}>
			<Typography.Title level={5}>
				{I18n.t(_t(translations.textMessageDetail.customerLoyaltyRank))}
			</Typography.Title>

			<Space size={'middle'}>
				{ranks.map((x, index) => (
					<Button
						key={x.id}
						onClick={() => onClickButton(x.id)}
						value={x.id}
						style={
							selected.includes(x.id) ? styles.checkBoxButtonActive : undefined
						}
					>
						{x.name ? x.name : ''}
					</Button>
				))}
			</Space>
		</Col>
	) : (
		<></>
	);
};

const Requirement = (props: {
	requireName: string;
	requireTitle: string;
	children?: ReactNode;
	isCheck: boolean;
}) => {
	const [isCheck, setIsCheck] = useState(props.isCheck);
	return (
		<Card style={{ marginBottom: '1rem' }}>
			{FormHelper.renderFormSwitch(
				props.requireName,
				props.requireTitle,
				props.isCheck,
				{ span: 24 },
				{
					onChange: () => setIsCheck(!isCheck),
					style: { marginLeft: '1rem' },
				}
			)}
			{isCheck && <>{props.children}</>}
		</Card>
	);
};
enum PromotionRequirementType {
	CustomerNotVisitedIn = 1,
	CustomerBirthdayIn = 2,
	CustomerGiveReview = 3,
	CustomerLoyaltyRank = 4,
	CustomerSpentAmount = 5,
	CustomerReturnedTimes = 6,
	BookingHasAllOfServices = 10,
	BookingHasOneOfServices = 11,
}
export const Requirements = (props: {
	isAdd: boolean;
	setRequirementAllOfServices: (value: string[]) => void;
	setRequirementOneOfServices: (value: string[]) => void;
	setRequirementHaveBirthdayIn: (value: string) => void;
	setRequirementReviewGroup: (value: string) => void;
	setRequirementCustomerRank: (value: string) => void;
	setIsVisitRequirement: (value: boolean) => void;
	requirements?: IRequirement[];
}) => {
	const [requirements, setRequirements] = useState<IRequirement[]>(
		props.requirements || []
	);

	const isCheckRequirement = (value: number[]) => {
		let flag = false;
		for (let i = 0; i < value.length; i++) {
			let index = requirements.findIndex(
				(requirement) => requirement.type === value[i]
			);
			if (index !== -1) return true;
		}
		return flag;
	};

	const getValueFromRequirement = (type: number) => {
		for (let i = 0; i < requirements.length; i++) {
			if (requirements[i].type === type) return requirements[i].value;
		}
		return undefined;
	};

	useEffect(() => {
		props.setIsVisitRequirement(true);
	}, []);

	return (
		<>
			<Requirement
				requireName="AssignedToCustomerGroup"
				requireTitle={I18n.t(
					_t(translations.promotionDetail.assignedToCustomerGroup)
				)}
				isCheck={
					props.isAdd
						? false
						: isCheckRequirement([
								PromotionRequirementType.CustomerBirthdayIn,
								PromotionRequirementType.CustomerNotVisitedIn,
								PromotionRequirementType.CustomerGiveReview,
								PromotionRequirementType.CustomerLoyaltyRank,
						  ])
				}
			>
				<Typography.Title level={5}>Customer Group</Typography.Title>
				<HaveNotVisited
					data={getValueFromRequirement(
						PromotionRequirementType.CustomerNotVisitedIn
					)}
				/>
				<BirthdayMonth
					data={getValueFromRequirement(
						PromotionRequirementType.CustomerBirthdayIn
					)}
					setRequirementHaveBirthdayIn={(value) =>
						props.setRequirementHaveBirthdayIn(value)
					}
				/>
				<ReviewGroup
					data={getValueFromRequirement(
						PromotionRequirementType.CustomerGiveReview
					)}
					setRequirementReviewGroup={(value) =>
						props.setRequirementReviewGroup(value)
					}
				/>
				<CustomerRank
					data={getValueFromRequirement(
						PromotionRequirementType.CustomerLoyaltyRank
					)}
					setRequirementCustomerRank={(value) =>
						props.setRequirementCustomerRank(value)
					}
				/>
			</Requirement>

			<Requirement
				requireName="HadSpentAnAmount"
				requireTitle={I18n.t(_t(translations.promotionDetail.hadSpentAnAmount))}
				isCheck={
					props.isAdd
						? false
						: isCheckRequirement([PromotionRequirementType.CustomerSpentAmount])
				}
			>
				{FormHelper.renderFormInput(
					'requiredSpentAmount',
					[
						{
							required: true,
						},
					],
					I18n.t(_t(translations.promotionDetail.requiredSpent)),
					isCheckRequirement([PromotionRequirementType.CustomerSpentAmount])
						? getValueFromRequirement(
								PromotionRequirementType.CustomerSpentAmount
						  )
						: '0',
					{ xs: 8, md: 4 },
					{
						addonAfter: <Typography.Title level={5}>$</Typography.Title>,
						placeholder: I18n.t(
							_t(translations.promotionDetail.placeholderDiscountAmount)
						),
						type: 'number',
						style: { textAlign: 'right' },
					}
				)}
			</Requirement>

			<Requirement
				requireName="isAllOfTheseServices"
				requireTitle={I18n.t(
					_t(translations.promotionDetail.titleAllOfTheseServices)
				)}
				isCheck={
					props.isAdd
						? false
						: isCheckRequirement([
								PromotionRequirementType.BookingHasAllOfServices,
						  ])
				}
			>
				<ListServices
					isAdd={props.isAdd}
					setServices={(value) => props.setRequirementAllOfServices(value)}
					data={getValueFromRequirement(
						PromotionRequirementType.BookingHasAllOfServices
					)}
				/>
			</Requirement>

			<Requirement
				requireName="isOneOfTheseServices"
				requireTitle={I18n.t(
					_t(translations.promotionDetail.titleOneOfTheseServices)
				)}
				isCheck={
					props.isAdd
						? false
						: isCheckRequirement([
								PromotionRequirementType.BookingHasOneOfServices,
						  ])
				}
			>
				<ListServices
					isAdd={props.isAdd}
					setServices={(value) => props.setRequirementOneOfServices(value)}
					data={getValueFromRequirement(
						PromotionRequirementType.BookingHasOneOfServices
					)}
				/>
			</Requirement>

			<Requirement
				requireName="isReturnMoreThan"
				requireTitle={I18n.t(
					_t(translations.promotionDetail.titleHasReturnTimes)
				)}
				isCheck={
					props.isAdd
						? false
						: isCheckRequirement([
								PromotionRequirementType.CustomerReturnedTimes,
						  ])
				}
			>
				<Col>
					<Form.Item
						label={
							<Typography.Title level={5}>
								{I18n.t(
									_t(translations.promotionDetail.descriptionReturnTimes)
								)}
							</Typography.Title>
						}
						name={'nTimesReturn'}
						initialValue={
							isCheckRequirement([
								PromotionRequirementType.CustomerReturnedTimes,
							])
								? getValueFromRequirement(
										PromotionRequirementType.CustomerReturnedTimes
								  )
								: 0
						}
					>
						<InputNumber min={0} style={{ width: '20%' }} />
					</Form.Item>
				</Col>
			</Requirement>
		</>
	);
};
